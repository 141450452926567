import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Table, Progress, Label, Input } from 'semantic-ui-react'
import { parse, format, addSeconds } from "date-fns"

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus, socketSystemHeartbeat, socketSystemOccupancy, socketSystemMonitoring } from "../../actions/socket/socketAction"
import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketSystemMonitoring:(objParam) => dispatch(socketSystemMonitoring(objParam)),
  }
}


class MonitoringWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      isExpand: true,
      leftExpand: false,
      rightExpand: false,

      showOnline: true,
      showOncall: false,
      showLocked: false,

      lastChange: null,
    };
  }
  
  setExpand() {
    this.setState({
      isExpand: !this.state.isExpand
    })
  }

  tableExpand(isRight) {
    if (isRight) {
      this.setState({
        rightExpand: !this.state.rightExpand
      })
    } else {
      this.setState({
        leftExpand: !this.state.leftExpand
      })
    }
  }
  
  formatSeconds(seconds) {
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log(date)
    return format(date, 'HH:mm:ss');
  }

  showSpy(item) {
    this.props.showSpy(item);
  }

  showAgent(v) {
    if (v=='oncall') {
      this.setState({
        showOncall: !this.state.showOncall
      })
    } else if (v=='online') {
      this.setState({
        showOnline: !this.state.showOnline
      })
    } else {
      this.setState({
        showLocked: !this.state.showLocked
      })
    }
  }

  onChangeSearch(e, v) {
    const parent= this
    console.log(v.value);

    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.setState({
          keyword: v.value
        })
      }
    }, 500)
  }

  render() {
    const { isExpand, leftExpand, rightExpand, showOncall, showOnline, showLocked }= this.state
    const { mode, screenWidth,  screenHeight}= this.props
    const { data }= this.props.socketStore.socketSystemMonitoring!=undefined ? this.props.socketStore.socketSystemMonitoring: {}
    let seq= 0, online= 0, engaged= 0, locked= 0, agent= 0

    data && data.payload && data.payload.data && data.payload.data.providers && data.payload.data.providers.map((item,index)=>{
      if (index<data.payload.data.providers.length) {
        online+= item.online
        engaged+= item.engaged
        locked+= item.locked
        agent+= item.agent
      }
    })

    // console.log('!!! DATA', data);
    if (mode=='admin') {
      return (
        <>
        <Segment attached='top' style={{padding: '1em .5em'}}>
          <Header as='h5'>
            <Icon name='chart bar' style={{fontSize: '1em'}} />
            <Header.Content>
              Real Time Monitoring
              <Header.Subheader></Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
        <Segment attached={isExpand ? true : 'bottom'} style={{padding: '1em'}}>
          <div style={{display: 'flex', textAlign: 'center'}}>
            <div style={{flex: '1'}}>
              <Header as='h2'>
                <Header.Content>
                  {online}
                  <Header.Subheader><Icon color='blue' name='plug' style={{margin: '0'}} />ONLINE</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{flex: '1'}}>
              <Header as='h2'>
                <Header.Content>
                  {engaged}
                  <Header.Subheader><Icon color='red' name='microphone' style={{margin: '0'}} />BUSY</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{flex: '1'}}>
              <Header as='h2'>
                <Header.Content>
                  {locked}
                  <Header.Subheader><Icon color='brown' name='lock' style={{margin: '0'}} />LOCKED</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{flex: '1'}}>
              <Header as='h2'>
                <Header.Content>
                  {agent}
                  <Header.Subheader><Icon color='black' name='spy' style={{margin: '0'}} />AGENT</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
          </div>
          <div style={{margin: '1.5em 0'}}>
            <Progress value={engaged} total={online} indicating active progress size='medium' style={{margin:'0 0 0 0', border: '1px solid rgba(100,100,100,.3)'}} precision={2}/>
          </div>
          <div style={{textAlign: 'center', marginBottom: '-2em', position: 'absolute', zIndex: '999', left: '49%'}}>
            <Icon name={ isExpand ? 'angle double up' : 'angle double down'} circular inverted color='red' style={{cursor: 'pointer'}} onClick={this.setExpand.bind(this)} />
          </div>
        </Segment>
        {isExpand && <Segment attached='bottom'>
          <div style={{marginTop: '1em'}}>
            <div style={{display: 'flex', flexDirection: screenWidth<800 ? 'column' : 'row'}}>
              <div style={{flex: '1', paddingRight: '.75em'}}>
                <Table unstackable selectable columns='16' celled striped basic='very' style={{border: 'none'}}>
                  <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                      <Table.HeaderCell width='12'>
                        <Header as='h5' color='blue'>
                          Provider
                        </Header>
                      </Table.HeaderCell>
                      <Table.HeaderCell width='1'>
                        <Popup content='Online' trigger={<Icon color='blue' name='plug' style={{margin: '0'}} circular inverted />} />
                      </Table.HeaderCell>
                      <Table.HeaderCell width='1'>
                        <Popup content='Busy !' trigger={<Icon color='red' name='microphone' style={{margin: '0'}} circular inverted />} />
                      </Table.HeaderCell>
                      <Table.HeaderCell width='1'>
                        <Popup content='Locked' trigger={<Icon color='brown' name='lock' style={{margin: '0'}} circular inverted />} />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign='center' width='1'>
                        <Popup content='Agent' trigger={<Icon color='grey' name='spy' style={{margin: '0'}} circular inverted />} />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {
                    data && data.payload && data.payload.data && data.payload.data.providers.map((item,index)=>{
                      if (index<=data.payload.data.providers.length/2) {
                        return(
                          <Table.Row key={++seq} >
                            <Table.Cell><Label>{seq}</Label></Table.Cell>
                            <Table.Cell><b>{item.name}</b></Table.Cell>
                            <Table.Cell textAlign='center' style={{background: '#daefff'}}>{item.online}</Table.Cell>
                            <Table.Cell textAlign='center' style={{background: '#ffe4e4'}}>{item.engaged}</Table.Cell>
                            <Table.Cell textAlign='center' style={{background: '#ffe6cc'}}>{item.locked}</Table.Cell>
                            <Table.Cell textAlign='center' style={{background: '#eeeeee'}}>{item.agent}</Table.Cell>
                          </Table.Row>
                        )
                      }
                    })
                  }
                  </Table.Body>
                </Table>
              </div>
              <div style={{flex: '1', paddingLeft: '.75em'}}>
                {data && data.payload.data.providers.length/2>1 &&
                  <Table unstackable selectable columns='16' celled striped basic='very' style={{border: 'none'}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell width='12'>
                          <Header as='h5' color='blue'>
                            Provider
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell width='1'>
                          <Popup content='Online' trigger={<Icon color='blue' name='plug' style={{margin: '0'}} circular inverted />} />
                        </Table.HeaderCell>
                        <Table.HeaderCell width='1'>
                          <Popup content='Busy !' trigger={<Icon color='red' name='microphone' style={{margin: '0'}} circular inverted />} />
                        </Table.HeaderCell>
                        <Table.HeaderCell width='1'>
                          <Popup content='Locked' trigger={<Icon color='brown' name='lock' style={{margin: '0'}} circular inverted />} />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign='center' width='1'>
                          <Popup content='Agent' trigger={<Icon color='grey' name='spy' style={{margin: '0'}} circular inverted />} />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {
                      data && data.payload && data.payload.data && data.payload.data.providers.map((item,index)=>{
                        if (index>data.payload.data.providers.length/2) {
                          return(
                            <Table.Row key={++seq} >
                              <Table.Cell><Label>{seq}</Label></Table.Cell>
                              <Table.Cell><b>{item.name}</b></Table.Cell>
                              <Table.Cell textAlign='center' style={{background: '#daefff'}}>{item.online}</Table.Cell>
                              <Table.Cell textAlign='center' style={{background: '#ffe4e4'}}>{item.engaged}</Table.Cell>
                              <Table.Cell textAlign='center' style={{background: '#ffe6cc'}}>{item.locked}</Table.Cell>
                              <Table.Cell textAlign='center' style={{background: '#eeeeee'}}>{item.agent}</Table.Cell>
                            </Table.Row>
                          )
                        }
                      })
                    }
                    </Table.Body>
                  </Table>
                }
              </div>
            </div>
          </div>
        </Segment>}
        </>
      );
    } else {
      const filteredAgents= new Map();

      data && data.payload && data.payload.data && data.payload.data.agents && data.payload.data.agents.map((item,index)=>{
        // console.log(item.account, item)
        if (this.state.keyword && this.state.keyword!='' && (item.account.includes(this.state.keyword) || item.name.includes(this.state.keyword))) {
          if (showOncall & item.occupied) {
            filteredAgents.set(item.account, item);
          } 
          if (showOnline & item.online) {
            filteredAgents.set(item.account, item);
          } 
          if (showLocked & item.locked) {
            filteredAgents.set(item.account, item);
          }
          if (!showOncall && !showOnline && !showLocked) {
            filteredAgents.set(item.account, item);
          }
        } else if (!this.state.keyword || this.state.keyword==''){
          if (showOncall & item.occupied) {
            filteredAgents.set(item.account, item);
          } 
          if (showOnline & item.online) {
            filteredAgents.set(item.account, item);
          } 
          if (showLocked & item.locked) {
            filteredAgents.set(item.account, item);
          }
          if (!showOncall && !showOnline && !showLocked) {
            filteredAgents.set(item.account, item);
          }
        }
      })

      // console.log(filteredAgents.size, [...filteredAgents.keys()])

      return(
        <>
        <Segment attached='top' style={{padding: '1em .5em'}}>
          <Header as='h5'>
            <Icon name='chart bar' style={{fontSize: '1em'}} />
            <Header.Content>
              Real Time Monitoring
              <Header.Subheader></Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
        <Segment attached={isExpand ? true : 'bottom'} style={{padding: '1em'}}>
          <div style={{display: 'flex', textAlign: 'center'}}>
            <div style={{flex: '1'}}>
              <Header as='h2'>
                <Header.Content>
                  {online - locked - engaged}
                  <Header.Subheader><Icon color='blue' name='plug' style={{margin: '0'}} />ONLINE</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{flex: '1'}}>
              <Header as='h2'>
                <Header.Content>
                  {engaged}
                  <Header.Subheader><Icon color='red' name='microphone' style={{margin: '0'}} />BUSY</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{flex: '1'}}>
              <Header as='h2'>
                <Header.Content>
                  {locked}
                  <Header.Subheader><Icon color='brown' name='lock' style={{margin: '0'}} />LOCKED</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{flex: '1'}}>
              <Header as='h2'>
                <Header.Content>
                  {agent}
                  <Header.Subheader><Icon color='black' name='spy' style={{margin: '0'}} />AGENT</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
          </div>
          <div style={{margin: '1.5em 0'}}>
            <Progress value={engaged} total={online} indicating active progress size='medium' style={{margin:'0 0 0 0', border: '1px solid rgba(100,100,100,.3)'}} precision={2}/>
          </div>
          <div style={{textAlign: 'center', marginBottom: '-2em', position: 'absolute', zIndex: '999', left: '49%'}}>
            <Icon name={ isExpand ? 'angle double up' : 'angle double down'} circular inverted color='black' style={{cursor: 'pointer'}} onClick={this.setExpand.bind(this)} />
          </div>
          </Segment>
          {isExpand && <Segment attached='bottom'><div>
            <div style={{display: 'flex', margin: '0 -.5em 2em -.5em', padding: '1em', background: '#0e5589', borderRadius: '.5em'}}>
              <div style={{flex: '1'}}>
                <Input  size='small' placeholder='Account or name ...' onChange={this.onChangeSearch.bind(this)} style={{width: '30em'}}/>
              </div>
              <div style={{margin: '0 1em', cursor: 'pointer', paddingTop: '.8em'}}>
                <Header as='h5' onClick={this.showAgent.bind(this, 'online')} inverted>
                  <Icon name={showOnline ? 'check square' : 'square outline'} style={{fontSize: '1.2em'}} />
                  <Header.Content>
                    <b>Online</b>
                  </Header.Content>
                </Header>
              </div>
              <div style={{margin: '0 1em', cursor: 'pointer', paddingTop: '.8em'}}>
                <Header as='h5' onClick={this.showAgent.bind(this, 'oncall')} inverted>
                  <Icon name={showOncall ? 'check square' : 'square outline'} style={{fontSize: '1.2em'}} />
                  <Header.Content>
                    <b>On Call</b>
                  </Header.Content>
                </Header>
              </div>
              <div style={{margin: '0 1em', cursor: 'pointer', paddingTop: '.8em'}}>
                <Header as='h5' onClick={this.showAgent.bind(this, 'locked')} inverted>
                  <Icon name={showLocked ? 'check square' : 'square outline'} style={{fontSize: '1.2em'}} />
                  <Header.Content>
                    <b>Locked</b>
                  </Header.Content>
                </Header>
              </div>
            </div>
            <div style={screenWidth>1400 ? {display: 'flex'} : {}}>
              <div style={screenWidth>1400 ? {flex: '1', paddingRight: '.75em'} : {padding: '0'}}>
                <Table unstackable selectable columns='16' celled basic='very' style={{border: 'none'}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>#</Table.HeaderCell>
                      <Table.HeaderCell width={leftExpand ? '6' : '10'}>
                        <Header as='h5' color='blue'>
                          Agent
                        </Header>
                      </Table.HeaderCell>
                      {leftExpand && <>
                      <Table.HeaderCell width='2'>
                        <Header as='h5' color='red'>
                          Signin
                        </Header>
                      </Table.HeaderCell>
                      <Table.HeaderCell width='2'>
                        <Header as='h5' color='red'>
                          Signout
                        </Header>
                      </Table.HeaderCell>
                      </>}
                      <Table.HeaderCell width='2'>
                        <Header as='h5' color='blue'>
                          <Header.Content style={{whiteSpace: 'nowrap'}}>
                            <Icon name= {leftExpand ? 'angle double right' : 'angle double left'} onClick={this.tableExpand.bind(this, false)} style={{cursor: 'pointer'}} color= {leftExpand ? 'red' : 'red'}/>
                            Oncall
                          </Header.Content>
                        </Header>
                      </Table.HeaderCell>
                      <Table.HeaderCell width='2'>
                        <Header as='h5' color='blue'>
                          Online
                        </Header>
                      </Table.HeaderCell>
                      <Table.HeaderCell width='2'>
                        <Header as='h5' color='blue'>
                          Locked
                        </Header>
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {
                    [...filteredAgents.keys()].map((key, index)=>{
                      const item= filteredAgents.get(key);
                      
                      if (index<filteredAgents.size/2) {
                        return(
                          <Table.Row key={++seq} style={{background: (item.locked && '#fcffda') || (item.occupied && '#ffefef') || (item.originated && '#dafff7') || (item.online && '#f7fff6') || (item.registered && '#ffffff') || '#fefefe', height: '5em'}}>
                            <Table.Cell><Label>{seq}</Label></Table.Cell>
                            <Table.Cell>
                              <Header as='h5' color={(item.locked && 'red') || (item.occupied && 'red') || (item.originated && 'blue') || (item.online && 'blue') || (item.registered && 'black') || 'grey'}>
                                <Icon name={(item.locked && 'lock') || (item.occupied && 'microphone') || (item.originated && 'microphone') || (item.registered && 'plug') || 'times circle'} color={(item.locked && 'red') || (item.occupied && 'red') || (item.originated && 'blue') || (item.online && 'blue') || (item.registered && 'black') || 'grey'}/>
                                <Header.Content>
                                  <b>{item.name}</b>
                                  <Header.Subheader>{!leftExpand &&<span>id: {item.account} / tag: <b>{item.tagger || '-'}</b></span>} {<mark className='blink'><b><i>({(item.locked && 'locked'+ (item.actionReason ? (' - '+ item.actionReason) : 'no reason')) || (item.occupied && 'occupied') || (item.originated && 'online') || (item.online && 'starting') || (item.registered && 'signin') || ('offline'+ (item.actionReason ? (' - '+ item.actionReason) : ''))})</i></b></mark>}</Header.Subheader>
                                </Header.Content>
                              </Header>
                            </Table.Cell>
                            {leftExpand && <>
                              <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap'}}>{item.signinStamp && item.signinStamp.substring(11,16)} / <b>{item.totSignin || '-'}x</b></Table.Cell>
                              <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap'}}>{item.signoutStamp && item.signoutStamp.substring(11,16)} / <b>{item.totSignout || '-'}x</b></Table.Cell>
                            </>}
                            <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap'}}>{this.formatSeconds(item.secondOncall || 0)}</Table.Cell>
                            <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap'}}>{this.formatSeconds(item.secondOnline || 0)}</Table.Cell>
                            <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap'}}>{this.formatSeconds(item.secondLocked || 0)}</Table.Cell>
                            <Table.Cell style={{paddingRight: '1em'}}>
                              <Button 
                                onClick={this.showSpy.bind(this, item)}
                                disabled={!item.occupied}
                                icon color='red'>
                                <Icon name='microphone'  style={{fontSize: '1.1em'}} />
                              </Button>
                            </Table.Cell>
                          </Table.Row>
                        )
                      }
                    })
                  }
                  </Table.Body>
                </Table>
              </div>
              {data && data.payload && data.payload.data && data.payload.data.agents && data.payload.data.agents.length>=2 && <>
                {screenWidth<1400 && <Divider />}
                <div style={screenWidth>1400 ? {flex: '1', paddingLeft: '.75em'} : {padding: '0'}}>
                  <Table unstackable selectable columns='16' celled basic='very' style={{border: 'none'}}>
                  <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell width={rightExpand ? '6' : '10'}>
                          <Header as='h5' color='blue'>
                            Agent
                          </Header>
                        </Table.HeaderCell>
                        {rightExpand && <>
                        <Table.HeaderCell width='2'>
                          <Header as='h5' color='red'>
                            Signin
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell width='2'>
                          <Header as='h5' color='red'>
                            Signout
                          </Header>
                        </Table.HeaderCell>
                        </>}
                        <Table.HeaderCell width='2'>
                          <Header as='h5' color='blue'>
                            <Header.Content style={{whiteSpace: 'nowrap'}}>
                              <Icon name= {rightExpand ? 'angle double right' : 'angle double left'} onClick={this.tableExpand.bind(this, true)} style={{cursor: 'pointer'}} color= {rightExpand ? 'red' : 'red'}/>
                              Oncall
                            </Header.Content>
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell width='2'>
                          <Header as='h5' color='blue'>
                            Online
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell width='2'>
                          <Header as='h5' color='blue'>
                            Locked
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {
                      [...filteredAgents.keys()].map((key, index)=>{
                        const item= filteredAgents.get(key);

                        if (index>=filteredAgents.size/2) {
                          return(
                            <Table.Row key={++seq} style={{background: (item.locked && '#fcffda') || (item.occupied && '#ffefef') || (item.originated && '#dafff7') || (item.online && '#f7fff6') || (item.registered && '#ffffff') || '#fefefe', height: '5em'}}>
                              <Table.Cell><Label>{seq}</Label></Table.Cell>
                              <Table.Cell>
                                <Header as='h5' color={(item.locked && 'red') || (item.occupied && 'red') || (item.originated && 'blue') || (item.online && 'blue') || (item.registered && 'black') || 'grey'}>
                                  <Icon name={(item.locked && 'lock') || (item.occupied && 'microphone') || (item.originated && 'microphone') || (item.registered && 'plug') || 'times circle'} color={(item.locked && 'red') || (item.occupied && 'red') || (item.originated && 'blue') || (item.online && 'blue') || (item.registered && 'black') || 'grey'}/>
                                  <Header.Content>
                                    <b>{item.name}</b>
                                    <Header.Subheader>{!leftExpand &&<span>id: {item.account} / tag: {item.tagger || 'none'}</span>} {<mark className='blink'><b><i>({(item.locked && 'locked'+ (item.actionReason ? (' - '+ item.actionReason) : 'no reason')) || (item.occupied && 'occupied') || (item.originated && 'online') || (item.online && 'starting') || (item.registered && 'signin') || ('offline'+ (item.actionReason ? (' - '+ item.actionReason) : ''))})</i></b></mark>}</Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                              {rightExpand && <>
                                <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap'}}>{item.signinStamp && item.signinStamp.substring(11,16)} / <b>{item.totSignin || '-'}x</b></Table.Cell>
                                <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap'}}>{item.signoutStamp && item.signoutStamp.substring(11,16)} / <b>{item.totSignout || '-'}x</b></Table.Cell>
                              </>}
                              <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap'}}>{this.formatSeconds(item.secondOncall || 0)}</Table.Cell>
                              <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap'}}>{this.formatSeconds(item.secondOnline || 0)}</Table.Cell>
                              <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap'}}>{this.formatSeconds(item.secondLocked || 0)}</Table.Cell>
                              <Table.Cell style={{paddingRight: '1em'}}>
                                <Button 
                                  onClick={this.showSpy.bind(this, item)}
                                  disabled={!item.occupied}
                                  icon color='red'>
                                  <Icon name='microphone'  style={{fontSize: '1.1em'}} />
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          )
                        }
                      })
                    }
                    </Table.Body>
                  </Table>
                </div>
              </>}
            </div>
          </div></Segment>}
        </>
      )
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MonitoringWidget)