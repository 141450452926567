import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label, Dropdown } from 'semantic-ui-react'

const signoutOptions = [
  {
    key: '1',
    text: 'After Hours',
    value: 'After Hours',
  },
  {
    key: '2',
    text: 'No Campaign',
    value: 'No Campaign',
  },
]

class CampaignConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state={
    }
  }
    
  render() {
    return (
      <div style={{padding: '2em'}}>
        <Header as='h1' color='red'>
          <Header.Content>
            <Icon name='warning sign' style={{marginLeft: '-.2em'}}/> Empty !
          </Header.Content>
        </Header>
        
        <Divider />
        <Header as='h5'>
          <Header.Content>
            Dear {this.props.user || ''}, 
            <br />
            Please contact your Admin or Supervisor for direct confirmation.
            <br />
            <Divider />
            <Label color='red' style={{fontSize: '14pt', margin: '1em -1em 0 -1em', padding: '1.5em 1.3em', lineHeight: '1.5em'}}>
              Your outbound target campaign is not available ! 
            </Label>
            <Header.Subheader></Header.Subheader>
          </Header.Content>
        </Header>
      </div>
    )
  }
}

export default CampaignConfirmation;