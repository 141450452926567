import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Label, Modal, Form, Input, Grid, Card, Icon, Table, Select } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { knowledgeOmniphone } from "../../actions/stream/omniphoneAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    knowledgeOmniphone:(objParam) => dispatch(knowledgeOmniphone(objParam)),
  }
}

class KnowledgeBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      filter: null,
      products: new Map(),
      productOptions: [
        { key: 'af', value: 'af', text: 'Afghanistan' },
      ]
    }
  }

  componentDidMount() {
    if (this.props.omniphoneStore.knowledgeOmniphone) {
      this.buildKnowledge()
    }
  }

  componentDidUpdate() {
    this.buildKnowledge();
  }

  buildKnowledge() {
    const { products, productOptions }= this.state;

    const { data }= (this.props.omniphoneStore.knowledgeOmniphone!=undefined && this.props.omniphoneStore.knowledgeOmniphone.progress=='1' ? this.props.omniphoneStore.knowledgeOmniphone : {})
    if (products.size==0 && data && data.length>0) {
      let articles;
      
      data.map(product=>{
        productOptions.push({
          key: product.productId, 
          value: product.productId, 
          text: product.name,
        })
        articles= new Map();
        product.questions.length>0 && product.questions.map(item=>{
          articles.set(product.name +' '+ item.keyword, {
            question: item.question,
            answer: item.answer,
          });
        })

        product.questions.length>0 && products.set(product.name, articles)
      })

      this.setState({
        products: products,
        productOptions: productOptions,
      })
    } 
  }

  setContent(x, v) {
    this.setState({
      productId: x,
      keyword: v,
    })
  }

  changeInput(e, v) {
    this.setState({
      filter: v.value,
    })
  }

  render() {
    const { screenHeight, style }= this.props
    const { products, productOptions, filter, productId, keyword }= this.state
    const { data }= (this.props.omniphoneStore.knowledgeOmniphone!=undefined && this.props.omniphoneStore.knowledgeOmniphone.progress=='1' ? this.props.omniphoneStore.knowledgeOmniphone : {})
    
    return (
      <div style={{...style}}>
        <div style={{display: 'flex', minHeight: screenHeight/3}}>
          <div style={{flex: '1', minHeight: screenHeight/3}}>
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
              <div>
              <Header as='h5' inverted style={{padding: '.25em .4em .2em .15em',margin: '0 0 0 -.9em', background: '#006dbd', display: 'inline-block'}}>
                <Icon name='angle double down' style={{fontSize: '1em'}}/>
                <Header.Content>
                  READ HERE !
                </Header.Content>
              </Header>
                {/* <Label color='red' style={{marginLeft: '-1.05em'}}>READ HERE !</Label> */}
              </div>
              <Segment style={{marginRight: '1em'}}>
                <div className='noscroll' style={{flex: 1, overflowY: 'scroll', height: '250px'}}>
                  <div>
                    <Header as='h3'>
                      {products.get(productId) && products.get(productId).size>0 && products.get(productId).get(keyword).question}
                    </Header>

                    {products.get(productId) && <Divider />}
                    <pre style={{fontFamily: 'Lato', fontSize: '1.1em'}}>
                      {products.get(productId) && products.get(productId).size>0 && products.get(productId).get(keyword).answer}
                    </pre>
                  </div>
                </div>
              </Segment>
            </div>
          </div>
          <div style={{width: '300px', alignItems: 'stretch', background: '#fff', borderLeft: '1px solid #ccc'}}>
            <div style={{display: 'flex', padding: '1em 1em 0 1em'}}>
              <div style={{flex: '1', paddingRight: '0'}}>
                <Input placeholder='Keyword ...' onChange={this.changeInput.bind(this)}/>
              </div>
              {/* <div style={{width: '8em'}}>
                <Select fluid placeholder='Product' options={productOptions} />
              </div> */}
            </div>
            <Divider />
            <div style={{padding: '.1em .5em 1em .5em'}}>
              {
                [...products.keys()].map((name, i)=>{
                  return(<div key={i}>
                    <Header as='h3' style={{margin: '0 .5em 1em .3em'}} color='red'>
                      <Icon name='cube' style={{fontSize: '1em'}}/>
                      <Header.Content>
                        <b>{name.substring(0,15)}...</b>
                      </Header.Content>
                    </Header>
                    <Divider />
                    {
                      [...((products.get(name) && products.get(name).size>0 && products.get(name).keys()) || [])].map((keyword,j)=>{
                        if (filter && filter!='' && filter!=' ' && keyword.toLowerCase().indexOf(filter.toLowerCase())>=0) {
                          return(
                            <Header key={j} as='h5' style={{margin: '.5em 0 .5em -.5em'}}>
                              <Label as='a' style={{padding: '.4em .5em .3em .5em', borderRadius: '1em'}} color='orange' onClick={this.setContent.bind(this, name, keyword)}>
                                {products.get(name).get(keyword).question}
                              </Label>
                            </Header>
                          )
                        } else {
                          return(
                            <Header key={j} as='h5' style={{margin: '.5em 0 .5em .5em'}}>
                              <Label as='a'  style={{padding: '.4em .5em .3em .5em', borderRadius: '1em'}} color='yellow' onClick={this.setContent.bind(this, name, keyword)}>
                                {products.get(name).get(keyword).question}
                              </Label>
                            </Header>
                          )
                        } 
                      })
                    }
                  </div>)
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeBrowse)