import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Progress } from 'semantic-ui-react'

import AchievementWidget from '../../libraries/widget/AchievementWidget'
import OccupancyWidget from '../../libraries/widget/OccupancyWidget'

class StatisticBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { screenWidth, screenHeight, style }= this.props

    return (
      <Container fluid style={{...style}}>
        <div style={{padding: '1em', marginTop: '.5em'}}>
          <OccupancyWidget responsive screenWidth={screenWidth} screenHeight={screenHeight}/>
        </div>
        
        <div style={{padding: '1em', marginTop: '.5em'}}>
          <AchievementWidget responsive screenWidth={screenWidth} screenHeight={screenHeight}/>
        </div>
      </Container>
    )
  }
}

export default StatisticBody