export default function appointmentReducer (state = {}, action) {
  switch (action.type) {
    case 'APPOINTMENT_RESET': {
        return({
            ...state,
            getAppointment: {},
            updateAppointment: {},
            createAppointment: {},
        })
    }

    case 'APPOINTMENT_BROWSE_PENDING': {
      return({...state, browseAppointment: {progress: 0}})
    }
    case 'APPOINTMENT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseAppointment: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseAppointment: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'APPOINTMENT_BROWSE_REJECTED': {
        return({...state, browseAppointment: {progress: 2, payload: action.payload}})
    }
    
    case 'APPOINTMENT_GET_PENDING': {
        return({...state, getAppointment: {progress: 0}})
    }
    case 'APPOINTMENT_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getAppointment: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getAppointment: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'APPOINTMENT_GET_REJECTED': {
        return({...state, getAppointment: {progress: 2, payload: action.payload}})
    }

    case 'APPOINTMENT_STATUS_PENDING': {
      return({...state, statusAppointment: {progress: 0}})
    }
    case 'APPOINTMENT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusAppointment: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusAppointment: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'APPOINTMENT_STATUS_REJECTED': {
        return({...state, statusAppointment: {progress: 2, payload: action.payload}})
    }

    case 'APPOINTMENT_CREATE_PENDING': {
      return({...state, createAppointment: {progress: 0}})
    }
    case 'APPOINTMENT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createAppointment: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createAppointment: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'APPOINTMENT_CREATE_REJECTED': {
        return({...state, createAppointment: {progress: 2, payload: action.payload}})
    }

    case 'APPOINTMENT_UPDATE_PENDING': {
      return({...state, updateAppointment: {progress: 0}})
    }
    case 'APPOINTMENT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateAppointment: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateAppointment: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'APPOINTMENT_UPDATE_REJECTED': {
        return({...state, updateAppointment: {progress: 2, payload: action.payload}})
    }

    case 'APPOINTMENT_REMOVE_PENDING': {
      return({...state, removeAppointment: {progress: 0}})
    }
    case 'APPOINTMENT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeAppointment: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeAppointment: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'APPOINTMENT_REMOVE_REJECTED': {
        return({...state, removeAppointment: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}