export default function whitelistReducer (state = {}, action) {
  switch (action.type) {
    case 'WHITELIST_RESET': {
        return({
            ...state,
            getWhitelist: {},
            updateWhitelist: {},
            createWhitelist: {},
        })
    }

    case 'WHITELIST_GET_PENDING': {
        return({...state, getWhitelist: {progress: 0}})
    }
    case 'WHITELIST_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getWhitelist: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getWhitelist: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WHITELIST_GET_REJECTED': {
        return({...state, getWhitelist: {progress: 2, payload: action.payload}})
    }

    case 'WHITELIST_STATUS_PENDING': {
      return({...state, statusWhitelist: {progress: 0}})
    }
    case 'WHITELIST_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusWhitelist: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusWhitelist: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WHITELIST_STATUS_REJECTED': {
        return({...state, statusWhitelist: {progress: 2, payload: action.payload}})
    }

    case 'WHITELIST_BROWSE_PENDING': {
      return({...state, browseWhitelist: {progress: 0}})
    }
    case 'WHITELIST_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseWhitelist: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseWhitelist: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WHITELIST_BROWSE_REJECTED': {
        return({...state, browseWhitelist: {progress: 2, payload: action.payload}})
    }

    case 'WHITELIST_CREATE_PENDING': {
      return({...state, browseWhitelist: {progress: 0}})
    }
    case 'WHITELIST_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createWhitelist: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createWhitelist: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WHITELIST_CREATE_REJECTED': {
        return({...state, createWhitelist: {progress: 2, payload: action.payload}})
    }

    case 'WHITELIST_UPDATE_PENDING': {
      return({...state, updateWhitelist: {progress: 0}})
    }
    case 'WHITELIST_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateWhitelist: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateWhitelist: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WHITELIST_UPDATE_REJECTED': {
        return({...state, updateWhitelist: {progress: 2, payload: action.payload}})
    }

    case 'WHITELIST_REMOVE_PENDING': {
      return({...state, removeWhitelist: {progress: 0}})
    }
    case 'WHITELIST_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeWhitelist: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeWhitelist: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WHITELIST_REMOVE_REJECTED': {
        return({...state, removeWhitelist: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}