import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetDialer(objParam) {
  return {
      type: 'DIALER_RESET',
      payload: objParam
  }
}

export function getDialer(objParam) {
  return {
      type: 'DIALER_GET',
      payload:
        axios.post(
          urlAPI + 'dialer/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusDialer(objParam) {
  return {
      type: 'DIALER_STATUS',
      payload:
        axios.post(
          urlAPI + 'dialer/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseDialer(objParam) {
  return {
      type: 'DIALER_BROWSE',
      payload:
        axios.post(
          urlAPI + 'dialer/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createDialer(objParam) {
  return {
      type: 'DIALER_CREATE',
      payload:
        axios.post(
          urlAPI + 'dialer/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateDialer(objParam) {
  return {
      type: 'DIALER_UPDATE',
      payload:
        axios.post(
          urlAPI + 'dialer/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeDialer(objParam) {
  return {
      type: 'DIALER_REMOVE',
      payload:
        axios.post(
          urlAPI + 'dialer/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
