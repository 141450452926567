import React from 'react'
import { Progress, Container, Segment, Header, Divider, Card, Grid, Icon } from 'semantic-ui-react'

// import TopupBrowse from '../../libraries/realm/TopupBrowse'
// import TopupForm from '../../libraries/realm/TopupForm'
// import TopupSearch from '../../libraries/realm/TopupSearch'


class TopupBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        topupId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, topupId: id})
    }

    render() {
      const {mode, topupId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
            {/* <TopupSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} /> */}
            <div>
              {/* {mode==1 && <TopupBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
              {mode==2 && <TopupForm mode={mode} topupId={topupId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <TopupForm mode={mode} topupId={0} setMode={this.setMode.bind(this)} />} */}
            </div>
            <div style={{padding: '1.5em'}}>
              <Header as='h2' color='red'>
                <Header.Content>
                  TOPUP DEPOSIT
                  <Header.Subheader>Please let your supervisor knows ....</Header.Subheader>
                </Header.Content>
              </Header>

              <Divider />
              <Header as='h3'>
                <Header.Content>
                  Your deposit is less than zero, your corporate account requires topup deposit to run service.
                  <Divider />
                  <Header.Subheader>Confirmation only required when you need faster response and no update after 24 hours.</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
        </Container>
      )
    }
}

export default TopupBody
