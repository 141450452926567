import React from 'react'
import { Container, Segment, Label, Icon, List, Header, Divider } from 'semantic-ui-react'

import ModalMessage from '../libraries/common/ModalMessage'

class AuthenticateFooter extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        content: undefined,
      }
    }
  
    closeModal() {
      this.setState({open: false, content: undefined })
    }
  
    openModal(key) {
      this.setState({open: true, content: key,})
    }

    render() {
      const { open, provider }= this.state
      
      return(<div style={{padding: '1em'}}>
        <div style={{textAlign: 'center', margin: 'auto'}}>
          <Divider hidden/>
          <Header as='h4'>
            <b>Halo Pagi!</b> Digital Communication 2.3.01
            <Header.Subheader style={{color: '#222'}}>
              Trademarks and Copyrights by <b>@Halo Pagi!</b> 2023
            </Header.Subheader>
          </Header>
        </div>
      </div>)
    }
} export default AuthenticateFooter
