import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Table, Progress, Label } from 'semantic-ui-react'
import { parse, format, addSeconds } from "date-fns"

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus, socketSystemHeartbeat, socketSystemOccupancy, socketSystemMonitoring } from "../../actions/socket/socketAction"
import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketSystemMonitoring:(objParam) => dispatch(socketSystemMonitoring(objParam)),
  }
}


class AgentStatisticWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
    };
  }
  
  formatSeconds(seconds) {
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log(date)
    return format(date, 'HH:mm:ss');
  }

  render() {
    const { isExpand, leftExpand, rightExpand }= this.state
    const { mode, dark, screenWidth,  screenHeight}= this.props
    const { data }= this.props.socketStore.socketSystemMonitoring!=undefined ? this.props.socketStore.socketSystemMonitoring: {}
    let seq= 0, online= 0, engaged= 0, locked= 0, agent= 0, briefing= 0, praying= 0, toilet= 0, fu= 0, acw= 0, aux= 0, secondOncall=0 , secondOnline= 0, secondLocked=0 ;

    data && data.payload && data.payload.data && data.payload.data.providers && data.payload.data.providers.map((item,index)=>{
      online+= item.online;
      engaged+= item.engaged;
      locked+= item.locked;
      agent+= item.agent;
    })

    data && data.payload && data.payload.data && data.payload.data.agents && data.payload.data.agents.map((item,index)=>{
      briefing+= item.locked && item.actionReason=='Briefing' ? 1 : 0;
      praying+= item.locked && item.actionReason=='Praying' ? 1 : 0;
      toilet+= item.locked && item.actionReason=='Toilet' ? 1 : 0;
      fu+= item.locked && item.actionReason=='FU' ? 1 : 0;
      acw+= item.locked && item.actionReason=='ACW' ? 1 : 0;
      aux+= item.locked && item.actionReason=='AUX' ? 1 : 0;

      secondOncall+= item.secondOncall; 
      secondOnline+= item.secondOnline; 
      secondLocked+= item.secondLocked;
    })

    // console.log('!!! DATA', data);
    
    return(<>
        <div>
          <Segment style={{background: dark ? '#2a2a2a' : '#fff'}}>
            <Header as='h3' inverted={dark}>
              <Icon name='spy' style={{fontSize: '1em'}} />
              <Header.Content>
                AGENTS
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>

            <Divider hidden />
            <div style={{display: 'flex', textAlign: 'center'}}>
              <div style={{flex: '1'}}>
                <Header as='h2' inverted={dark}>
                  <Header.Content>
                    <b>{engaged}</b>
                    <Header.Subheader><Icon color='red' name='microphone'/>BUSY</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                <Header as='h2' inverted={dark}>
                  <Header.Content>
                    <b>{locked}</b>
                    <Header.Subheader><Icon color='orange' name='lock'/>LOCKED</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                <Header as='h2' inverted={dark}>
                  <Header.Content>
                    <b>{online - locked - engaged}</b>
                    <Header.Subheader><Icon color='blue' name='plug'/>ONLINE</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                <Header as='h2' inverted={dark}>
                  <Header.Content>
                    <b>{agent}</b>
                    <Header.Subheader><Icon color='grey' name='spy'/>AGENT</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
            <div style={{margin: '2em .5em'}}>
              <Progress value={engaged} total={online} indicating active progress size='medium' style={{margin:'0 0 0 0', border: '1px solid rgba(100,100,100,.3)'}} precision={2} inverted={dark} />
            </div>
          </Segment>

          <Segment style={{background: dark ? '#252525' : '#fff', textAlign: 'center'}}>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h2' inverted={dark}>
                  <Header.Content>
                    <b>{acw || 0}</b>
                    <Header.Subheader>ACW</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                <Header as='h2' inverted={dark}>
                  <Header.Content>
                    <b>{aux || 0}</b>
                    <Header.Subheader>AUX</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                <Header as='h2' inverted={dark}>
                  <Header.Content>
                    <b>{briefing || 0}</b>
                    <Header.Subheader>BRIEF</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                <Header as='h2' inverted={dark}>
                  <Header.Content>
                    <b>{praying || 0}</b>
                    <Header.Subheader>PRAY</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                <Header as='h2' inverted={dark}>
                  <Header.Content>
                    <b>{toilet || 0}</b>
                    <Header.Subheader>TOILET</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                <Header as='h2' inverted={dark}>
                  <Header.Content>
                    <b>{fu || 0}</b>
                    <Header.Subheader>FUP</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </Segment>

          <Segment style={{background: dark ? '#252525' : '#fff', textAlign: 'center'}}>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h3' inverted={dark}>
                  {/* <Icon color='black' name='clock outline' /> */}
                  <Header.Content>
                    <b>{this.formatSeconds(secondOncall || 0)}</b>
                    <Header.Subheader>ENGAGED</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                <Header as='h3' inverted={dark}>
                  {/* <Icon color='black' name='clock outline' /> */}
                  <Header.Content>
                    <b>{this.formatSeconds(secondLocked || 0)}</b>
                    <Header.Subheader>LOCKED</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                <Header as='h3' inverted={dark}>
                  {/* <Icon color='black' name='clock outline' /> */}
                  <Header.Content>
                    <b>{this.formatSeconds(secondOnline || 0)}</b>
                    <Header.Subheader>ONLINE</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </Segment>
        </div>
      </>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AgentStatisticWidget)