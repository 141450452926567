import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Item,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Progress,
  Pagination,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import RadialRechart from '../chart/RadialRechart';
import PieRechart from '../chart/PieRechart';

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus, socketSystemHeartbeat, socketSystemOccupancy, socketCampaignMonitoring } from "../../actions/socket/socketAction"
import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps= (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    socketCampaignMonitoring:(objParam) => dispatch(socketCampaignMonitoring(objParam)),
  }
}


class CampaignStatisticWidget extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        isUnique: false,
        stamp: '',
      };
    }
    
    componentDidUpdate() {
      const { data }= this.props.socketStore.socketCampaignMonitoring!=undefined ? this.props.socketStore.socketCampaignMonitoring: {}

      if (data && data.payload.data.stamp!=this.state.stamp) {
        this.setState({
          stamp: data.payload.data.stamp,
        })
      }
    }

    setUnique() {
      this.setState({
        isUnique: !this.state.isUnique,
      })
    }

    render() {
      const {dark, screenWidth, screenHeight}= this.props
      const {isUnique}= this.state
      const { data }= this.props.socketStore.socketCampaignMonitoring!=undefined ? this.props.socketStore.socketCampaignMonitoring: {}

      let item, pieAccuDelivery= [], pieUqDelivery= [], pieAccuResponse= [], pieUqResponse= [];
      
      if (data && data.payload && data.payload.data && data.payload.data.campaign) {
        item= data.payload.data.campaign;
        pieAccuDelivery= [
          { name: 'Target', value: item.totTarget },
          { name: 'Attempt', value: item.totAttempt }, 
          { name: 'Delivered', value: item.totDelivered },
          { name: 'Contacted', value: item.totEngaged },
          { name: 'Achieved', value: item.totAchieved },
        ];
        pieUqDelivery= [
          { name: 'Target', value: item.totTarget },
          { name: 'Attempt', value: item.uqAttempt }, 
          { name: 'Delivered', value: item.uqDelivered },
          { name: 'Contacted', value: item.uqEngaged },
          { name: 'Achieved', value: item.uqAchieved },
        ];
  
        pieAccuResponse= [
          { name: 'Paid', value: item.totPaid },
          { name: 'Promised', value: item.totPromised },
          { name: 'Reschedule', value: item.totRescheduled },
          { name: 'Verified', value: item.totVerified },
          { name: 'Ordered', value: item.totOrdered },
          { name: 'Interested', value: item.totInterested },
          
          { name: 'Reject', value: item.totReject },
          { name: 'Ignored', value: item.totIgnored }, 
          { name: 'Invalid', value: item.totInvalid }, 
          { name: 'Mailbox', value: item.totMailbox },
        ];
        pieUqResponse= [
          { name: 'Paid', value: item.uqPaid },
          { name: 'Promised', value: item.uqPromised },
          { name: 'Reschedule', value: item.uqRescheduled },
          { name: 'Verified', value: item.uqVerified },
          { name: 'Ordered', value: item.uqOrdered },
          { name: 'Interested', value: item.uqInterested },
  
          { name: 'Reject', value: item.uqReject },
          { name: 'Ignored', value: item.uqIgnored }, 
          { name: 'Invalid', value: item.uqInvalid }, 
          { name: 'Mailbox', value: item.uqMailbox },
        ];
      }
      
      if (item && item.stamp!=this.state.stamp) {
        return(<>
          <Segment style={{height: '100%', background: dark ? '#232323' : '#fff'}}>
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
              <div style={{flex: '1'}}>
                <div style={{display: 'flex'}}>
                  <div style={{flex: '1', padding: '0 1em .5em 0'}}>
                    <Header as='h3' inverted={dark}>
                      <Icon name='calendar alternate outline' style={{fontSize: '1em'}} />
                      <Header.Content>
                        CAMPAIGN
                        <Header.Subheader></Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{padding: '0 1em .5em 0'}}>
                    <Header as='h4' inverted={dark} style={{cursor: 'pointer', fontWeight: 'bold'}} color='red' onClick={this.setUnique.bind(this)} >
                      <Icon name={isUnique ? 'toggle on' : 'toggle off'} style={{transform: 'rotate(90deg)'}}/>
                      <Header.Content>
                        {isUnique ? 'Unique Progress' : 'Accumulative'}
                        <Header.Subheader>Data presentation</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                </div>
                <div style={{paddingTop: '.5em'}}>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1', padding: '.7em 1em'}}>
                      <Header as='h2' inverted={dark} style={{fontWeight: 'bold'}}>
                        <Header.Content>
                          <b>{numeral(item.totTarget).format('0,0')}</b>
                          <Header.Subheader><sup>TARGET</sup></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>
                    <div style={{flex: '1', padding: '.7em 1em'}}>
                      <Header as='h2' inverted={dark} style={{fontWeight: 'bold'}}>
                        <Header.Content>
                          <b>{isUnique && numeral(item.uqAttempt).format('0,0')}{!isUnique && numeral(item.totAttempt).format('0,0')}</b>
                          <Header.Subheader><sup>ATTEMPTS</sup></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>
                    <div style={{flex: '1', padding: '.7em 1em'}}>
                      <Header as='h2' inverted={dark} style={{fontWeight: 'bold'}}>
                        <Header.Content>
                          <b>{isUnique && numeral(item.uqDelivered).format('0,0')}{!isUnique && numeral(item.totDelivered).format('0,0')}</b>
                          <Header.Subheader><sup>DELIVERED</sup></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>
                    <div style={{flex: '1', padding: '.7em 1em'}}>
                      <Header as='h2' inverted={dark} style={{fontWeight: 'bold'}}>
                        <Header.Content>
                          <b>{isUnique && numeral(item.uqEngaged).format('0,0')}{!isUnique && numeral(item.totEngaged).format('0,0')}</b>
                          <Header.Subheader><sup>CONTACTED</sup></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>
                    <div style={{flex: '1', padding: '.7em 2em'}}>
                      <Header as='h2' inverted={dark} style={{fontWeight: 'bold'}}>
                        <Header.Content>
                          <b>{isUnique && numeral(item.uqAchieved).format('0,0')}{!isUnique && numeral(item.totAchieved).format('0,0')}</b>
                          <Header.Subheader><sup>ACHIEVED</sup></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style={{display: 'flex', marginTop: '1em'}}>
                  <div style={{flex: '1'}}>
                    <Header as='h5' inverted={dark}>
                      <Label color='yellow' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>
                        {!isUnique ? item.totTarget ? Math.ceil(item.totAttempt/item.totTarget * 100) : 0 : item.totTarget ? Math.ceil(item.uqAttempt/item.totTarget * 100) : 0}%
                      </Label>
                      <Header.Content>
                        ATTEMPTED
                        <Header.Subheader>Attempt  of target.</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Header as='h5' inverted={dark}>
                      <Label color='olive' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>
                        {!isUnique ? item.totTarget!=0 ? Math.ceil(item.totDelivered/item.totTarget * 100) : 0 : item.totTarget!=0 ? Math.ceil(item.uqDelivered/item.totTarget * 100) : 0}%
                        </Label>
                      <Header.Content>
                        DELIVERED
                        <Header.Subheader>Delivery of target.</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Header as='h5' inverted={dark}>
                      <Label color='green' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>
                        {!isUnique ? item.totTarget!=0 ? Math.ceil(item.totEngaged/item.totTarget * 100) : 0 : item.totTarget!=0 ? Math.ceil(item.uqEngaged/item.totTarget * 100) : 0}%
                      </Label>
                      <Header.Content>
                        CONTACTED
                        <Header.Subheader>Engagement of target.</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Header as='h5' inverted={dark}>
                      <Label color='blue' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>
                        {!isUnique ? item.totTarget!=0 ? Math.ceil(item.totAchieved/item.totTarget * 100) : 0 : item.totTarget!=0 ? Math.ceil(item.uqAchieved/item.totTarget * 100) : 0}%
                      </Label>
                      <Header.Content>
                        ACHIEVED
                        <Header.Subheader>Completed of target.</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{flex: '1', textAlign: 'center'}}>
                    {item && <PieRechart data={isUnique ? pieUqDelivery : pieAccuDelivery} width={300} height={230} fill='#555'/>}
                    {/* <RadialRechart data={radialDelivery} legend={legendDelivery} width={300} height={250}/> */}
                    <Header as='h5' inverted={dark} style={{margin: '0'}}>
                      <Header.Content>
                        Campaign Delivery
                      </Header.Content>
                    </Header>
                  </div>
                </div>   
              </div>
            </div>
          </Segment>
        </>)
      } else {
        return(<>
          <Segment style={{height: '100%', background: dark ? '#232323' : '#fff'}}></Segment>
        </>)
      }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignStatisticWidget)