import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
  Item,
} from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format, addSeconds } from "date-fns"


import { connect } from "react-redux"
import { checkToken} from "../../../actions/auth/authAction"
import { getConversation, predialTarget, inappTarget, chatTarget, emailTarget, smsTarget, whatsappTarget, twitterTarget, instagramTarget, facebookTarget } from "../../../actions/flow/omnichannelAction"
import { socketAgentStatus } from "../../../actions/socket/socketAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,

    omnichannelStore: state.omnichannelStore,
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
    getConversation:(objParam) => dispatch(getConversation(objParam)),

    predialTarget:(objParam) => dispatch(predialTarget(objParam)),
    inappTarget:(objParam) => dispatch(inappTarget(objParam)),
    chatTarget:(objParam) => dispatch(chatTarget(objParam)),
    emailTarget:(objParam) => dispatch(emailTarget(objParam)),
    smsTarget:(objParam) => dispatch(smsTarget(objParam)),

    whatsappTarget:(objParam) => dispatch(whatsappTarget(objParam)),
    twitterTarget:(objParam) => dispatch(twitterTarget(objParam)),
    facebookTarget:(objParam) => dispatch(facebookTarget(objParam)),
    instagramTarget:(objParam) => dispatch(instagramTarget(objParam)),

    checkToken:(objParam) => dispatch(checkToken(objParam)),
  }
}

class InteractionSubject extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remark: false,
      expand: false,
    }
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.omnichannelStore.getConversation!==undefined ? this.props.omnichannelStore.getConversation : {})
    if (this.props.params.targetId || this.props.params.customerId || this.props.params.visitorId) {
      this.props.getConversation({
        targetId: this.props.params.targetId, customerId: this.props.params.customerId, visitorId: this.props.params.visitorId, 
        search: {...search, transactDate: format(new Date(), 'yyyy-MM-dd 00:00:00'), flow: 0 }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.params && prevProps.params.targetId!=this.props.params.targetId) || (prevProps.params && prevProps.params.customerId!=this.props.params.customerId) || (prevProps.params && prevProps.params.visitorId!=this.props.params.visitorId)) {
      const { search }= (this.props.omnichannelStore.getConversation!==undefined ? this.props.omnichannelStore.getConversation : {});
      this.props.getConversation({
        targetId: this.props.params.targetId, customerId: this.props.params.customerId, visitorId: this.props.params.visitorId, 
        search: {...search, transactDate: format(new Date(), 'yyyy-MM-dd 00:00:00'), flow: 0 }}
      );
    }
  }

  interactTarget(v) {
    const objTarget= (this.props.params.customerId && {customerId: this.props.params.customerId}) || (this.props.params.targetId && {targetId: this.props.params.targetId}) || (this.props.params.visitorId && {visitorId: this.props.params.visitorId}) || {};

    switch (v) {
      //INTERACTIVE
      case 'mobile':
        this.props.predialTarget({contactType: 'mobile', ...objTarget})
        break;
      case 'officePhone':
        this.props.predialTarget({contactType: 'officePhone', ...objTarget})
        break;
      case 'inappAudio':
        this.props.inappTarget({contactType: 'inappAudio', ...objTarget})
        break;
      case 'inappVideo':
        this.props.inappTarget({contactType: 'inappVideo', ...objTarget})
        break;
      case 'chat':
        this.props.chatTarget({contactType: 'chat', ...objTarget})
        break;
      
      //NON-INTERACTIVE
      case 'sms':
        this.props.smsTarget({contactType: 'sms', ...objTarget})
        break;
      case 'email':
        this.props.emailTarget({contactType: 'email', ...objTarget})
        break;
      case 'whatsapp':
        this.props.whatsappTarget({contactType: 'whatsapp', ...objTarget})
        break;
      default:
    }
  }

  render() {
    //console.log(this.props.omnichannelStore.getConversation)
    const { expand, remark }= this.state
    const { screenWidth, screenHeight, params }= this.props
    const { data, search, paging }= (this.props.omnichannelStore.getConversation!==undefined ? this.props.omnichannelStore.getConversation : {})
    const token= this.props.authStore.checkToken && this.props.authStore.checkToken.data ? this.props.authStore.checkToken.data : {};

    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data : undefined)
    const { dialings, status }= (agentStatus && agentStatus.payload.data) || []
    
    // console.log(params)
    return (
      <div style={{background: 'none', margin: '0 -.5em'}}>
        <div style={{padding: '0 .5em 1em .5em'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <Header as='h3' inverted style={{
                margin: 0,
                display: 'inline-block',
                background: '#f2711c',
                padding: '0.3em 1em',
                borderRadius: '3em',
              }}>
                {/* <Icon name='user outline' /> */}
                <Header.Content>
                  {params.name || 'Guest'}
                  <Header.Subheader style={{fontSize: '.6em'}}>
                    <b>{(params.customerId && 'Customer') || (params.targetId && 'Campaigns target') || (params.visitorId && 'Visitor')}</b>
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{margin: '0 .5em', paddingTop: '.5em'}}>
              <Button.Group size='large'>
                <Button icon style={{background: 'none'}}>
                  <Icon name='clone outline' color='black' />
                </Button>
                <Dropdown
                  className='teal button icon'
                  floating>
                    <Dropdown.Menu>
                      <Popup content="Cost will be charged based on providers rate." inverted
                        trigger={
                          <Dropdown.Item onClick={!token.isVoice || status!='online' || !params.mobile ? null : this.interactTarget.bind(this, 'mobile')} icon='mobile alternate' text='Call Mobile' style={{color: (!token.isVoice || status!='online' || !params.mobile ? '#999' : '#1b5cab')}} />
                        } position='left center'
                      />
                      <Popup content="Cost will be charged based on providers rate." inverted
                        trigger={
                          <Dropdown.Item onClick={!token.isVoice || status!='online' || !params.officePhone ? null : this.interactTarget.bind(this, 'officePhone')} icon='fax' text='Call Office' style={{color: (!token.isVoice || status!='online' || !params.officePhone ? '#999' : '#1b5cab')}} />
                        } position='left center'
                      />
                      <Dropdown.Divider />
                      <Popup content="No cost applied for this service." inverted
                        trigger={
                          <Dropdown.Item onClick={!token.isVoice || status!='online' || true  ? null : this.interactTarget.bind(this, 'inappAudio')} icon='tablet alternate' text='In-App Call' style={{color: (!token.isVoice || status!='online' || true ? '#999' : '#1b5cab')}} />
                        } position='left center'
                      />
                      <Popup content="No cost applied for this service." inverted
                        trigger={
                          <Dropdown.Item onClick={!token.isVoice || status!='online' || true  ? null : this.interactTarget.bind(this, 'inappVideo')} icon='video camera' text='In-App Video' style={{color: (!token.isVoice || status!='online' || true ? '#999' : '#1b5cab')}} />
                        } position='left center'
                      />
                    </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
            </div>
            <div style={{paddingTop: '.5em'}}>
              <Button.Group size='large'>
                <Button icon style={{background: 'none'}}>
                  <Icon name='comment alternate outline' color='black' />
                </Button>
                <Dropdown
                  className='teal button icon'
                  floating>
                    <Dropdown.Menu>
                      <Popup content="No cost applied for this service." inverted
                        trigger={
                          <Dropdown.Item onClick={!token.isChat || status!='online' || true ? null : this.interactTarget.bind(this, 'chat')} icon='comment alternate outline' text='Chat' style={{color: (!token.isChat || status!='online' ? '#999' : '#1b5cab')}} />
                        } position='left center'
                      />
                      <Dropdown.Divider />
                      <Popup content="Cost will be charged based on providers rate." inverted
                        trigger={
                          <Dropdown.Item onClick={!token.isSMS || status!='online' || !params.mobile ? null : this.interactTarget.bind(this, 'sms')} icon='sticky note outline' text='SMS' style={{color: (!token.isSMS || status!='online' || !params.mobile ? '#999' : '#1b5cab')}} />
                        } position='left center'
                      />
                      <Popup content="No cost applied for this service." inverted
                        trigger={
                          <Dropdown.Item onClick={!token.isEmail || status!='online' || !params.email ? null : this.interactTarget.bind(this, 'email')} icon='envelope open outline' text='Email' style={{color: (!token.isEmail || status!='online' || !params.email ? '#999' : '#1b5cab')}} />
                        } position='left center'
                      />
                      <Popup content="Cost will be charged based on providers rate." inverted
                        trigger={
                          <Dropdown.Item onClick={!token.isWhatsapp || status!='online' || !params.whatsapp ? null : this.interactTarget.bind(this, 'whatsapp')} icon='whatsapp' text='Whatsapp' style={{color: (!token.isWhatsapp || status!='online' || !params.whatsapp ? '#999' : '#1b5cab')}} />
                        } position='left center'
                      />
                      <Dropdown.Divider />
                      <Popup content="No cost applied for this service." inverted
                        trigger={
                          <Dropdown.Item onClick={!token.isTwitter || status!='online' || !params.twitter ? null : this.interactTarget.bind(this, 'twitter')} icon='twitter' text='Twitter' style={{color: (!token.isTwitter || status!='online' || !params.twitter ? '#999' : '#1b5cab')}} />
                        } position='left center'
                      />
                      <Popup content="No cost applied for this service." inverted
                        trigger={
                          <Dropdown.Item onClick={!token.isFacebook || status!='online' || !params.facebook ? null : this.interactTarget.bind(this, 'facebook')} icon='facebook' text='Facebook' style={{color: (!token.isFacebook || status!='online' || !params.facebook ? '#999' : '#1b5cab')}} />
                        } position='left center'
                      />
                      <Popup content="No cost applied for this service." inverted
                        trigger={
                          <Dropdown.Item onClick={!token.isInstagram || status!='online' || !params.instagram ? null : this.interactTarget.bind(this, 'instagram')} icon='instagram' text='Instagram' style={{color: (!token.isInstagram || status!='online' || !params.instagram ? '#999' : '#1b5cab')}} />
                        } position='left center'
                      />
                    </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
            </div>
          </div>
        </div>
        {/* <Divider /> */}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InteractionSubject)
