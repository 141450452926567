import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid, Label, Progress } from 'semantic-ui-react'

import numeral from "numeral"

import IconToggle from '../common/IconToggle'
import PieRechart from '../chart/PieRechart';

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus } from "../../actions/socket/socketAction"

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
  }
}

class AchievementWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      isPiechart: false,
    };
  }

  setMode() {
    this.setState({
      isPiechart: !this.state.isPiechart
    })
  }

  render() {
    const { mode, screenWidth,  screenHeight, responsive }= this.props
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data : undefined)
    const { performance }= (agentStatus && agentStatus.payload.data) || {status: 'connect'}
    const data= performance && [
      { name: 'Contacted', value: performance && performance.totEngaged }, 
      { name: 'Achieved', value: performance && performance.totAchieved },
    ];

    const pieResponse = [
      { name: 'Contacted', value: performance && performance.totEngaged },
      { name: 'Achieved', value: performance && performance.totAchieved },
      { name: 'Dropped', value: performance && performance.totDropped }, 
      { name: 'Invalid', value: performance && performance.totInvalid }, 
      { name: 'Mailbox', value: performance && performance.totMailbox },
    ];

    return (
      <div style={{padding: '0'}}>
      <Segment style={{padding: '1em 1.5em .7em 1.5em'}}>
        <div style={{display: 'flex', marginBottom: '1em'}}>
          <div style={{flex: '1'}}>
            <Label color='orange' style={{borderRadius: '2em', padding: '.1em 1em .5em 1em'}}>
              <Header as='h4' style={{marginTop: '.25em'}} inverted>
                <Icon name='chart bar' style={{fontSize: '1em'}} />
                <Header.Content style={{fontWeight: 'bold'}}>
                  <i>Today!</i> Performance
                </Header.Content>
              </Header>
            </Label>
          </div>
          <div style={{marginLeft: '1em', width: '12em'}}>
            <Header as='h5' style={{fontWeight: 'bold'}} color='red'>
              <IconToggle active={this.state.isPiechart} onClick={this.setMode.bind(this)} />
              <Header.Content>
                {this.state.isPiechart ? 'RESULTS' : 'REMARKS'}
                <Header.Subheader>Interaction Summary</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
        </div>
        
        <Grid columns={screenWidth<1024 || responsive ? 2 : 4} style={{margin: '0'}}>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h2' : 'h1'} style={{fontWeight: '100', margin: '0'}}>
              <b>{performance && numeral(performance.totSignin).format('0,0')}</b>
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              <b>x Sign In</b>
              {/* <Header.Subheader>Tot. Sign In</Header.Subheader> */}
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h2' : 'h1'} style={{fontWeight: '100', margin: '0'}}>
            <b>{performance && numeral(performance.totSignout).format('0,0')}</b>
            </Header>
            <Header as='h5' style={{margin: '0'}}>
            <b>x Sign Out</b>
              {/* <Header.Subheader>Tot. Sign Out</Header.Subheader> */}
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h2' : 'h1'} style={{fontWeight: '100', margin: '0'}}>
            <b>{performance && numeral(performance.totStarted).format('0,0')}</b>
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              <b>x Online</b>
              {/* <Header.Subheader>Tot. Online</Header.Subheader> */}
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h2' : 'h1'} style={{fontWeight: '100', margin: '0'}}>
              <b>{performance && numeral(performance.totLocked).format('0,0')}</b>
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              <b>x Locked</b>
              {/* <Header.Subheader>Tot. Locked</Header.Subheader> */}
            </Header>
          </Grid.Column>
          {/* <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h2' : 'h1'} style={{fontWeight: '100', margin: '0'}}>
              {performance && numeral(performance.totUnlocked).format('0,0')}
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              Unlocked
              <Header.Subheader>Tot. Unlocked</Header.Subheader>
            </Header>
          </Grid.Column> */}
        </Grid>
      </Segment>
      {!this.state.isPiechart && <Segment style={{marginTop: '-.5em', padding: '1em'}}>
        <div style={{margin: '0 1em 2em 1em'}}>
          <Header as='h5'>
            REMARKS / FEEDBACK
            <Header.Subheader><b style={{color: '#c31'}}>Today!</b> Interaction, Yes Recently !</Header.Subheader>
          </Header>
        </div>

        <div style={{display: 'flex', margin: '.5em 1em'}}>
          <div style={{paddingRight: '.5em', textAlign: 'right'}}>
            <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='olive'><b>CONTACTED</b></Label>
          </div>
          <div style={{flex: '1'}}>
            <Progress color='olive' size='medium' progress='value' value={performance && performance.totEngaged} total={performance && (performance.totEngaged + performance.totRescheduled + performance.totPromised + performance.totPaid + performance.totInterested + performance.totOrdered + performance.totVerified)} style={{margin: '0'}} />
          </div>
        </div>
        <div style={{display: 'flex', margin: '.5em 1em'}}>
          <div style={{paddingRight: '.5em', textAlign: 'right'}}>
            <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='yellow'>RESCHEDULED</Label>
          </div>
          <div style={{flex: '1'}}>
            <Progress color='yellow' size='medium' progress='value' value={performance && performance.totRescheduled} total={performance && (performance.totEngaged + performance.totRescheduled + performance.totPromised + performance.totPaid + performance.totInterested + performance.totOrdered + performance.totVerified)} style={{margin: '0'}} />
          </div>
        </div>

        {/* {performance && performance.totPaid>0 && <> */}
        {true && <>
          <Divider />
          <Header as='h5' style={{marginLeft: '1em'}}>
            Collection
          </Header>
          <div style={{display: 'flex', margin: '.5em 1em'}}>
            <div style={{paddingRight: '.5em', textAlign: 'right'}}>
              <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='teal'>PAID</Label>
            </div>
            <div style={{flex: '1'}}>
              <Progress color='teal' size='medium' progress='value' value={performance && performance.totPaid} total={performance && (performance.totEngaged + performance.totRescheduled + performance.totPromised + performance.totPaid + performance.totInterested + performance.totOrdered + performance.totVerified)} style={{margin: '0'}} />
            </div>
          </div>
        </>}
        {/* {performance && performance.totPromised>0 && <> */}
        {true && <>
          <div style={{display: 'flex', margin: '.5em 1em'}}>
            <div style={{paddingRight: '.5em', textAlign: 'right'}}>
              <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='orange'>PROMISED</Label>
            </div>
            <div style={{flex: '1'}}>
              <Progress color='orange' size='medium' progress='value' value={performance && performance.totPromised} total={performance && (performance.totEngaged + performance.totRescheduled + performance.totPromised + performance.totPaid + performance.totInterested + performance.totOrdered + performance.totVerified)} style={{margin: '0'}} />
            </div>
          </div>
        </>}

        {/* {performance && performance.totInterested>0 && <> */}
        {true && <>
          <Divider />
          <Header as='h5' style={{marginLeft: '1em'}}>
            Telesales / Telemarketing
          </Header>
          <div style={{display: 'flex', margin: '.5em 1em'}}>
            <div style={{paddingRight: '.5em', textAlign: 'right'}}>
              <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='yellow'>INTERESTED</Label>
            </div>
            <div style={{flex: '1'}}>
              <Progress color='yellow' size='medium' progress='value' value={performance && performance.totInterested} total={performance && (performance.totEngaged + performance.totRescheduled + performance.totPromised + performance.totPaid + performance.totInterested + performance.totOrdered + performance.totVerified)} style={{margin: '0'}} />
            </div>
          </div>
        </>}
        {/* {performance && performance.totOrdered>0 && <> */}
        {true && <>
          <div style={{display: 'flex', margin: '.5em 1em'}}>
            <div style={{paddingRight: '.5em', textAlign: 'right'}}>
              <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='green'>ORDERED</Label>
            </div>
            <div style={{flex: '1'}}>
              <Progress color='green' size='medium' progress='value' value={performance && performance.totOrdered} total={performance && (performance.totEngaged + performance.totRescheduled + performance.totPromised + performance.totPaid + performance.totInterested + performance.totOrdered + performance.totVerified)} style={{margin: '0'}} />
            </div>
          </div>
        </>}
        
        {/* {performance && performance.totVerified>0 && <> */}
        {true && <>
        <Divider />
          <Header as='h5' style={{marginLeft: '1em'}}>
            Survey / Verification
          </Header>
          <div style={{display: 'flex', margin: '.5em 1em'}}>
            <div style={{paddingRight: '.5em', textAlign: 'right'}}>
              <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.49em 1em', width: '9em'}} size='medium' color='teal'>VERIFIED</Label>
            </div>
            <div style={{flex: '1'}}>
              <Progress color='teal' size='medium' progress='value' value={performance && performance.totVerified} total={performance && (performance.totEngaged + performance.totRescheduled + performance.totPromised + performance.totPaid + performance.totInterested + performance.totOrdered + performance.totVerified)} style={{margin: '0'}} />
            </div>
          </div>
        </>}
        {/* <Grid columns={screenWidth<1024 || responsive ? 2 : 3} style={{margin: '0 0 2em 0'}}>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h3' : 'h2'} style={{fontWeight: 'bold', margin: '0'}}>
              {performance && numeral(performance.totContacted).format('0,0')}
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              CONTACTED
              <Header.Subheader>Contacted and engaged customer</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h3' : 'h2'} style={{fontWeight: 'bold', margin: '0'}} color='red'>
              {performance && numeral(performance.totNofeedback).format('0,0')}
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              NO FEEDBACK
              <Header.Subheader>No remarks / feedback from customer</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h2' : 'h1'} style={{fontWeight: 'bold', margin: '0'}} color='brown'>
              {performance && numeral(performance.totRescheduled).format('0,0')}
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              RESCHEDULE
              <Header.Subheader>Make another appointment</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h2' : 'h1'} style={{fontWeight: 'bold', margin: '0'}} color='teal'>
              {performance && numeral(performance.totPromised).format('0,0')}
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              PROMISED
              <Header.Subheader>Promised to pay</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h2' : 'h1'} style={{fontWeight: 'bold', margin: '0'}} color='blue'>
              {performance && numeral(performance.totPaid).format('0,0')}
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              PAID
              <Header.Subheader>Payment is received</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h3' : 'h2'} style={{fontWeight: 'bold', margin: '0'}}>
              {performance && numeral(performance.totRestructured).format('0,0')}
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              RESTRUCTURED
              <Header.Subheader>Restructuring request of bills</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h3' : 'h2'} style={{fontWeight: 'bold', margin: '0'}}>
              {performance && numeral(performance.totInterested).format('0,0')}
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              INTERESTED
              <Header.Subheader>Interested to buy</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h2' : 'h1'} style={{fontWeight: 'bold', margin: '0'}} color='blue'>
              {performance && numeral(performance.totOrdered).format('0,0')}
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              ORDERED
              <Header.Subheader>Make transaction order</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as={responsive ? 'h2' : 'h1'} style={{fontWeight: 'bold', margin: '0'}} color='blue'>
              {performance && numeral(performance.totVerified).format('0,0')}
            </Header>
            <Header as='h5' style={{margin: '0'}}>
              VERIFIED
              <Header.Subheader>Customer is verified by QC</Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid> */}
      </Segment>}

      {this.state.isPiechart && <Segment style={{marginTop: '-.5em', padding: '1em'}}>
        {mode!=='Agent' && <>
        <Grid columns={16} stackable>
          <Grid.Column width={8}>
            {performance &&
              // <PieRechart data={data} width={300} height={300} mode='circle' fill='#555'/> 
              <PieRechart data={pieResponse} width={300} height={230} mode='active' fill='#555'/>
            }

            <div style={{textAlign: responsive ? 'left' : 'center', margin: responsive ? '0 .5em 1em .5em' : '0'}}>
              <Header as='h5'>
                PERFORMANCE
                <Header.Subheader><b style={{color: '#c31'}}>Today!</b> Interaction, Yes Recently !</Header.Subheader>
              </Header>
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%'}}>
              <div style={{flex: '1'}}>
                
              </div>
              <div>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Label color='teal' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{performance && performance.totEngaged}</Label>
                  <Header.Content>
                    CONTACTED
                    <Header.Subheader>Contacted customers.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Label color='blue' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{performance && performance.totAchieved}</Label>
                  <Header.Content>
                    ACHIEVED
                    <Header.Subheader>Achieved or completed target.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Label color='red' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{performance && performance.totInvalid}</Label>
                  <Header.Content>
                    INVALID
                    <Header.Subheader>Identified as invalid by agent.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Label color='orange' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{performance && performance.totMailbox}</Label>
                  <Header.Content>
                    MAILBOX
                    <Header.Subheader>Identified as mailbox by agent.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Label color='orange' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{performance && performance.totDropped}</Label>
                  <Header.Content>
                    DROPPED
                    <Header.Subheader>Dropped as overtime talks.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                
              </div>
            </div>
            <Divider hidden />
          </Grid.Column>
        </Grid>
        </>}
      </Segment>}
      </div>
    );
  }
}

AchievementWidget.contextType= WebSocketContext;

export default connect(mapStateToProps, mapDispatchToProps)(AchievementWidget)
