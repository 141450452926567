export default function statisticReducer (state = {}, action) {
  switch (action.type) {
    case 'STAT_RESET': {
        return({
            ...state,
            statCustomer: {},
            updateStatistic: {},
            statAsset: {},
        })
    }
    
    case 'CAMPAIGN_STAT_PENDING': {
      return({...state, statCampaign: {progress: 0}})
    }
    case 'CAMPAIGN_STAT_FULFILLED': {
      if (action.payload==undefined) {
          //return({...state, statCampaign: {...api.statCampaign.response, progress: 1, payload: action.payload}})
          return({...state, statCampaign: {progress: 2, payload: action.payload}})
      } else {
          return({...state, statCampaign: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'CAMPAIGN_STAT_REJECTED': {
      return({...state, statCampaign: {progress: 2, payload: action.payload}})
    }
    
    case 'CUSTOMER_STAT_PENDING': {
        return({...state, statCustomer: {progress: 0}})
    }
    case 'CUSTOMER_STAT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statCustomer: {...api.statCustomer.response, progress: 1, payload: action.payload}})
            return({...state, statCustomer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statCustomer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CUSTOMER_STAT_REJECTED': {
        return({...state, statCustomer: {progress: 2, payload: action.payload}})
    }

    case 'PRODUCT_STAT_PENDING': {
      return({...state, statProduct: {progress: 0}})
    }
    case 'PRODUCT_STAT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statProduct: {...api.statProduct.response, progress: 1, payload: action.payload}})
            return({...state, statProduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statProduct: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PRODUCT_STAT_REJECTED': {
        return({...state, statProduct: {progress: 2, payload: action.payload}})
    }

    case 'ASSET_STAT_PENDING': {
      return({...state, statAsset: {progress: 0}})
    }
    case 'ASSET_STAT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statAsset: {...api.statAsset.response, progress: 1, payload: action.payload}})
            return({...state, statAsset: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statAsset: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ASSET_STAT_REJECTED': {
        return({...state, statAsset: {progress: 2, payload: action.payload}})
    }

    case 'ASSIGNMENT_STAT_PENDING': {
      return({...state, statAssignment: {progress: 0}})
    }
    case 'ASSIGNMENT_STAT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statAssignment: {...api.statAssignment.response, progress: 1, payload: action.payload}})
            return({...state, statAssignment: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statAssignment: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ASSIGNMENT_STAT_REJECTED': {
        return({...state, statAssignment: {progress: 2, payload: action.payload}})
    }

    case 'ENGAGEMENT_STAT_PENDING': {
      return({...state, statEngagement: {progress: 0}})
    }
    case 'ENGAGEMENT_STAT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statEngagement: {...api.statEngagement.response, progress: 1, payload: action.payload}})
            return({...state, statEngagement: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statEngagement: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ENGAGEMENT_STAT_REJECTED': {
        return({...state, statEngagement: {progress: 2, payload: action.payload}})
    }

    case 'CONVERSATION_STAT_PENDING': {
      return({...state, statConversation: {progress: 0}})
    }
    case 'CONVERSATION_STAT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statConversation: {...api.statConversation.response, progress: 1, payload: action.payload}})
            return({...state, statConversation: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statConversation: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONVERSATION_STAT_REJECTED': {
        return({...state, statConversation: {progress: 2, payload: action.payload}})
    }

    case 'MIDDLEWARE_STAT_PENDING': {
      return({...state, statMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_STAT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statMiddleware: {...api.statMiddleware.response, progress: 1, payload: action.payload}})
            return({...state, statMiddleware: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MIDDLEWARE_STAT_REJECTED': {
        return({...state, statMiddleware: {progress: 2, payload: action.payload}})
    }

    case 'WEBHOOK_STAT_PENDING': {
      return({...state, statWebhook: {progress: 0}})
    }
    case 'WEBHOOK_STAT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statWebhook: {...api.statWebhook.response, progress: 1, payload: action.payload}})
            return({...state, statWebhook: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statWebhook: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WEBHOOK_STAT_REJECTED': {
        return({...state, statWebhook: {progress: 2, payload: action.payload}})
    }

    case 'API_STAT_PENDING': {
      return({...state, statApi: {progress: 0}})
    }
    case 'API_STAT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statApi: {...api.statApi.response, progress: 1, payload: action.payload}})
            return({...state, statApi: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statApi: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'API_STAT_REJECTED': {
        return({...state, statApi: {progress: 2, payload: action.payload}})
    }

    case 'REMARK_STAT_PENDING': {
      return({...state, statRemark: {progress: 0}})
    }
    case 'REMARK_STAT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statRemark: {...api.statRemark.response, progress: 1, payload: action.payload}})
            return({...state, statRemark: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statRemark: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REMARK_STAT_REJECTED': {
        return({...state, statRemark: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}