import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetSocial(objParam) {
  return {
      type: 'SOCIAL_RESET',
      payload: objParam
  }
}

export function getSocial(objParam) {
  return {
      type: 'SOCIAL_GET',
      payload:
        axios.post(
          urlAPI + 'social/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusSocial(objParam) {
  return {
      type: 'SOCIAL_STATUS',
      payload:
        axios.post(
          urlAPI + 'social/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseSocial(objParam) {
  return {
      type: 'SOCIAL_BROWSE',
      payload:
        axios.post(
          urlAPI + 'social/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createSocial(objParam) {
  return {
      type: 'SOCIAL_CREATE',
      payload:
        axios.post(
          urlAPI + 'social/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateSocial(objParam) {
  return {
      type: 'SOCIAL_UPDATE',
      payload:
        axios.post(
          urlAPI + 'social/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeSocial(objParam) {
  return {
      type: 'SOCIAL_REMOVE',
      payload:
        axios.post(
          urlAPI + 'social/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
