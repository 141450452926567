import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format, addSeconds } from "date-fns"
import { recordPath } from "../../../libraries/common/_lib"
import { voiceStatus } from "../../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { getConversation } from "../../../actions/flow/omnichannelAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omnichannelStore: state.omnichannelStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConversation:(objParam) => dispatch(getConversation(objParam)),
  }
}

class InteractionVoice extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remark: false,
      expand: false,
    }
  }

  onChangeStatus(id, status, e) {
    // console.lo g(id, status, e)
    this.props.statusVoice({voiceId:id, isEnabled: status})
  }
  setExpand() {
    const { expand }= this.state
    this.setState({
      expand: !expand
    })
  }
  setRemark() {
    const { remark }= this.state
    this.setState({
      remark: !remark
    })
  }

  formatSeconds(seconds) {
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log(date)
    return format(date, 'HH:mm:ss');
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.omnichannelStore.getConversation!==undefined ? this.props.omnichannelStore.getConversation : {})
    this.props.getConversation(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }

  render() {
    //console.log(this.props.omnichannelStore.getConversation)
    const { expand, remark }= this.state
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.omnichannelStore.getConversation!==undefined ? this.props.omnichannelStore.getConversation : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    if (data && data.calls && data.calls.length>0 || true) {
      return (
        <div style={{padding: '1em .5em'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' color='blue'>
                {/* <Icon name='phone square' style={{fontSize: '1.7em'}}/> */}
                <Header.Content>
                  Phone Call
                  <Header.Subheader>
                    Voice or video call interaction.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div>
              {!this.props.limit && <Button disabled={data && data.calls && data.calls.length<3} onClick={this.props.openModal.bind(this, 'historical_voice')} size='small' icon><Icon name='ellipsis vertical' /></Button>}
            </div>
          </div>
          { data && data.calls && data.calls.length==0 && <div style={{padding: '.5em 2.5em .5em 0'}}>
            <Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label>
          </div>}
          { data && data.calls && data.calls.length>0 && <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width={8}>
                  <Header as='h5' color='blue'>CONTACT</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={8}>
                  <Header as='h5' color='blue'>REMARK</Header>
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
            { data.calls.map((item,i)=>{
              return(
                <Table.Row>
                  <Table.Cell>{i+1}</Table.Cell>
                  <Table.Cell>
                    <Icon name={item.flow==0 ? 'chevron circle right' : 'chevron circle left'} color={(item.flow!=1 && 'black') || (item.dropStamp && 'orange') || (item.talkStamp && 'blue') || (item.callStamp && 'teal') || (item.dialStamp && 'red')} style={{fontSize: '1em'}} />
                    <b>{item.channelType}</b> at {item.dialStamp!=null && format(parse(item.dialStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss, dd/MMM yyyy')}
                  </Table.Cell>
                  <Table.Cell>
                    {item.remark || 'No remark'}
                  </Table.Cell>
                  <Table.Cell>
                    {item.talkStamp && <div onClick={this.props.openModal.bind(this, 'recording', {url: item.recordingUri && item.recordingUri!='' ? recordPath + item.recordingUri : recordPath + item.agentId +'_'+ item.voiceId +'_'+ item.channelId +'_'+ item.parking +'.mp3'})}>
                      <Popup content="Play Recording" inverted
                        trigger={
                          <Label as='a' color='red' style={{padding: '.2em .6em .3em .7em', borderRadius: '.3em'}}><Icon name='play' style={{fontSize: '.7em', margin: '0'}} /></Label>
                        } position='left center'
                      />
                    </div>}
                    {!item.talkStamp && <Label disabled style={{padding: '.2em .6em .3em .7em', borderRadius: '.3em'}}><Icon name='play' style={{fontSize: '.7em', margin: '0'}} /></Label>}
                  </Table.Cell>
                </Table.Row>
              )
            })}
            </Table.Body>
          </Table>}
          { false && data && data.calls && <Card.Group itemsPerRow={this.props.limit ? 4 : 3} style={{padding: '1em 0'}}>
            { data.calls.map((item,i)=>{
                if (i<(this.props.limit || 3)) {
                  return(
                    <Card key={i} style={{padding: '.3em .5em', borderRadius: '.2em', background: '#efefde'}}>
                      <Card.Content style={{padding: '.5em .5em'}}>
                        <Card.Meta>
                          <Icon name={item.flow==0 ? 'chevron circle right' : 'chevron circle left'} color={(item.flow!=1 && 'black') || (item.dropStamp && 'orange') || (item.talkStamp && 'blue') || (item.callStamp && 'teal') || (item.dialStamp && 'red')} style={{fontSize: '1em'}} />
                          <b>{item.channelType}</b> at
                          <br />{item.dialStamp!=null && format(parse(item.dialStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss, dd/MMM')}
                        </Card.Meta>
                        {/* <Card.Description>
                          {item.remark || 'No remark'}
                        </Card.Description> */}
                      </Card.Content>
                      <Card.Content extra style={{padding: '.5em .5em'}}>
                        <div style={{display: 'flex'}}>
                          <div style={{flex: '1'}}>
                            {/* <b>Dur. </b>{(item.callDuration && (this.formatSeconds(item.callDuration || 0)) || '--:--:--')} */}
                            {item.remark || 'No remark'}
                          </div>
                          <div>
                            {item.talkStamp && <div onClick={this.props.openModal.bind(this, 'recording', {url: item.recordingUri && item.recordingUri!='' ? recordPath + item.recordingUri : recordPath + item.agentId +'_'+ item.voiceId +'_'+ item.channelId +'_'+ item.parking +'.mp3'})}>
                              <Popup content="Play Recording" inverted
                                trigger={
                                  <Label as='a' color='red' style={{padding: '.2em .6em .3em .7em', borderRadius: '.3em'}}><Icon name='play' style={{fontSize: '.7em', margin: '0'}} /></Label>
                                } position='left center'
                              />
                            </div>}
                            {!item.talkStamp && <Label disabled style={{padding: '.2em .6em .3em .7em', borderRadius: '.3em'}}><Icon name='play' style={{fontSize: '.7em', margin: '0'}} /></Label>}
                          </div>
                        </div>
                      </Card.Content>
                    </Card>
                  )
                }
              })
            }
          </Card.Group>}
        </div>
      )
    } else {
      return<></>
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InteractionVoice)
