import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'


import Phonewidget from '../../../libraries/phonewidget/Phonewidget';

class WidgetBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        // widgetId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, widgetId: id})
    }

    render() {
      const {mode, widgetId}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <Container fluid>
          <div style={{display: 'flex', padding: '1.5em 1em'}}>
            <div style={{paddingRight: '2.5em'}}>
              <Phonewidget />
            </div>
            <div style={{flex: '1'}}>
              <Header as='h3'>
                <Header.Content>
                  Available Enterprise Features
                  <Header.Subheader>Office phone exclusive enterprise features</Header.Subheader>
                </Header.Content>
              </Header>

              <Divider />
              <Header as='h4'>
                <Icon name='microphone slash' />
                <Header.Content>
                  Mute
                  <Header.Subheader style={{fontSize: '1em'}}>Mute or unmute conversation on connected call.</Header.Subheader>
                </Header.Content>
              </Header>
              <Header as='h4'>
                <Icon name='user plus' />
                <Header.Content>
                  Call Invites
                  <Header.Subheader style={{fontSize: '1em'}}>Invites spesific number to current call conference.</Header.Subheader>
                </Header.Content>
              </Header>
              <Header as='h4'>
                <Icon name='clock outline' />
                <Header.Content>
                  Wait / Hold
                  <Header.Subheader style={{fontSize: '1em'}}>Wait or holding incoming call, background music is availble.</Header.Subheader>
                </Header.Content>
              </Header>
              <Header as='h4'>
                <Icon name='arrow alternate circle right outline' />
                <Header.Content>
                  Forwarding
                  <Header.Subheader style={{fontSize: '1em'}}>Forwarding current call to spesific number.</Header.Subheader>
                </Header.Content>
              </Header>
              <Header as='h4'>
                <Icon name='users' />
                <Header.Content>
                  Group Dial
                  <Header.Subheader style={{fontSize: '1em'}}>Group dial to spesific area, unit or department as required.</Header.Subheader>
                </Header.Content>
              </Header>
              <Header as='h4'>
                <Icon name='clone outline' />
                <Header.Content>
                  Call Conference
                  <Header.Subheader style={{fontSize: '1em'}}>Available conference call through audio or video channel, certain device is required.</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
          </div>
        </Container>
      )
    }
}

export default WidgetBody
