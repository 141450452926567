import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import WorkgroupBrowse from '../../../libraries/realm/halo/WorkgroupBrowse'
import WorkgroupForm from '../../../libraries/realm/halo/WorkgroupForm'
import WorkgroupSearch from '../../../libraries/realm/halo/WorkgroupSearch'


class WorkgroupBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        workgroupId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, workgroupId: id})
    }

    render() {
      const {mode, workgroupId}= this.state
      const {screenWidth}= this.props
      
      return(
        <Container fluid style={{}}>
          <div>
            <WorkgroupSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <WorkgroupBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} />}
              {mode==2 && <WorkgroupForm mode={mode} workgroupId={workgroupId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <WorkgroupForm mode={mode} workgroupId={0} setMode={this.setMode.bind(this)} />}
            </div>
          </div>
        </Container>
      )
    }
}

export default WorkgroupBody
