import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label, Popup, Feed } from 'semantic-ui-react'

class TwitterMessage extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      return(
        <div>
          <Grid>
            <Grid.Row width={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    Twitter <b>Recent</b>
                    <Header.Subheader>Recent direct message, commentary.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment style={{minHeight: screenHeight}}>
                  <Card.Group itemsPerRow={1}>
                    <Card as='a'>
                      <Card.Content style={{padding: '1em'}}>
                        <Image
                          floated='right'
                          size='mini'
                          src='https://react.semantic-ui.com/images/avatar/large/steve.jpg'
                        />
                        <Card.Header>Steve Sanders</Card.Header>
                        <Card.Meta>Friends of Elliot</Card.Meta>
                        <Card.Description>
                          Steve wants to add you to the group <strong>best friends</strong>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                    <Card as='a'>
                      <Card.Content style={{padding: '1em'}}>
                        <Image
                          floated='right'
                          size='mini'
                          src='https://react.semantic-ui.com/images/avatar/large/steve.jpg'
                        />
                        <Card.Header>Steve Sanders</Card.Header>
                        <Card.Meta>Friends of Elliot</Card.Meta>
                        <Card.Description>
                          Steve wants to add you to the group <strong>best friends</strong>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Card.Group>
                </Segment>
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    Twitter <b>Message List</b>
                    <Header.Subheader>Detail conversation message / chat.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment style={{minHeight: screenHeight}}>
                  <p>
                    <b>https://developer.twitter.com/en/docs/twitter-api/v1/direct-messages/sending-and-receiving/api-reference/list-events</b>
                  </p>
                  <Card.Group itemsPerRow={1}>
                    <Card as='a'>
                      <Card.Content style={{padding: '1em'}}>
                        <div style={{display: 'flex'}}>
                          <div>
                            <Image
                              size='mini'
                              src='https://react.semantic-ui.com/images/avatar/large/steve.jpg'
                            />
                          </div>
                          <div style={{flex: 1, padding: '0 .5em'}}>
                            Steve wants to add you to the group <strong>best friends</strong>
                          </div>
                          <div style={{flex: 1, textAlign: 'right', padding: '0 .5em'}}>
                            Steve wants to add you to the group <strong>best friends</strong>
                          </div>
                          <div>
                            <Image
                              size='mini'
                              src='https://react.semantic-ui.com/images/avatar/large/steve.jpg'
                            />
                          </div>
                        </div>
                      </Card.Content>
                    </Card>
                  </Card.Group>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      )
    }
}

export default TwitterMessage
