import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function updateBridging(objParam) {
  return {
      type: 'BRIDGING_UPDATE',
      payload:
        axios.post(
          urlAPI + 'bridging/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}