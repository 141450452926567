import React from 'react'
import { Dropdown, Image, Header } from 'semantic-ui-react'

const trigger = (
  <span>
    <Image avatar src='https://react.semantic-ui.com/logo.png' /> Qolbun
  </span>
)

const options = [
  { key: 'user', text: 'Mobile App', icon: 'user' },
  { key: 'settings', text: 'Lembaga Tahfidz', icon: 'settings' },
]

const BrandLogo = (props) => (
    <div style={{display: 'inline-flex',padding: '.35em', _background: '#f00'}}>
      <div>
        <Image avatar src='https://react.semantic-ui.com/logo.png' />
      </div>
      <div style={{padding: '.4em .5em'}}>
        <b>Smart Stadium</b>
      </div>
    </div>
)

export default BrandLogo
