export default function visitorReducer (state = {}, action) {
  switch (action.type) {
    case 'VISITOR_RESET': {
        return({
            ...state,
            getVisitor: {},
            updateVisitor: {},
            createVisitor: {},
        })
    }

    case 'VISITOR_BROWSE_PENDING': {
      return({...state, browseVisitor: {progress: 0}})
    }
    case 'VISITOR_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseVisitor: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseVisitor: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VISITOR_BROWSE_REJECTED': {
        return({...state, browseVisitor: {progress: 2, payload: action.payload}})
    }
    
    case 'VISITOR_GET_PENDING': {
        return({...state, getVisitor: {progress: 0}})
    }
    case 'VISITOR_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getVisitor: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getVisitor: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VISITOR_GET_REJECTED': {
        return({...state, getVisitor: {progress: 2, payload: action.payload}})
    }

    case 'VISITOR_STATUS_PENDING': {
      return({...state, statusVisitor: {progress: 0}})
    }
    case 'VISITOR_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusVisitor: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusVisitor: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VISITOR_STATUS_REJECTED': {
        return({...state, statusVisitor: {progress: 2, payload: action.payload}})
    }

    case 'VISITOR_CREATE_PENDING': {
      return({...state, createVisitor: {progress: 0}})
    }
    case 'VISITOR_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createVisitor: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createVisitor: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VISITOR_CREATE_REJECTED': {
        return({...state, createVisitor: {progress: 2, payload: action.payload}})
    }

    case 'VISITOR_UPDATE_PENDING': {
      return({...state, updateVisitor: {progress: 0}})
    }
    case 'VISITOR_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateVisitor: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateVisitor: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VISITOR_UPDATE_REJECTED': {
        return({...state, updateVisitor: {progress: 2, payload: action.payload}})
    }

    case 'VISITOR_REMOVE_PENDING': {
      return({...state, removeVisitor: {progress: 0}})
    }
    case 'VISITOR_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeVisitor: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeVisitor: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VISITOR_REMOVE_REJECTED': {
        return({...state, removeVisitor: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}