export default function knowledgeReducer (state = {}, action) {
  switch (action.type) {
    case 'KNOWLEDGE_RESET': {
        return({
            ...state,
            getKnowledge: {},
            updateKnowledge: {},
            createKnowledge: {},
        })
    }

    case 'KNOWLEDGE_BROWSE_PENDING': {
      return({...state, browseKnowledge: {progress: 0}})
    }
    case 'KNOWLEDGE_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseKnowledge: {...api.browseKnowledge.response, progress: 1, payload: action.payload}})
            return({...state, browseKnowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseKnowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'KNOWLEDGE_BROWSE_REJECTED': {
        return({...state, browseKnowledge: {progress: 2, payload: action.payload}})
    }
    
    case 'KNOWLEDGE_GET_PENDING': {
        return({...state, getKnowledge: {progress: 0}})
    }
    case 'KNOWLEDGE_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getKnowledge: {...api.getKnowledge.response, progress: 1, payload: action.payload}})
            return({...state, getKnowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getKnowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'KNOWLEDGE_GET_REJECTED': {
        return({...state, getKnowledge: {progress: 2, payload: action.payload}})
    }

    case 'KNOWLEDGE_STATUS_PENDING': {
      return({...state, statusKnowledge: {progress: 0}})
    }
    case 'KNOWLEDGE_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusKnowledge: {...api.statusKnowledge.response, progress: 1, payload: action.payload}})
            return({...state, statusKnowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusKnowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'KNOWLEDGE_STATUS_REJECTED': {
        return({...state, statusKnowledge: {progress: 2, payload: action.payload}})
    }

    case 'KNOWLEDGE_CREATE_PENDING': {
      return({...state, createKnowledge: {progress: 0}})
    }
    case 'KNOWLEDGE_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createKnowledge: {...api.createKnowledge.response, progress: 1, payload: action.payload}})
            return({...state, createKnowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createKnowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'KNOWLEDGE_CREATE_REJECTED': {
        return({...state, createKnowledge: {progress: 2, payload: action.payload}})
    }

    case 'KNOWLEDGE_UPDATE_PENDING': {
      return({...state, updateKnowledge: {progress: 0}})
    }
    case 'KNOWLEDGE_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateKnowledge: {...api.updateKnowledge.response, progress: 1, payload: action.payload}})
            return({...state, updateKnowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateKnowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'KNOWLEDGE_UPDATE_REJECTED': {
        return({...state, updateKnowledge: {progress: 2, payload: action.payload}})
    }

    case 'KNOWLEDGE_REMOVE_PENDING': {
      return({...state, removeKnowledge: {progress: 0}})
    }
    case 'KNOWLEDGE_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeKnowledge: {...api.removeKnowledge.response, progress: 1, payload: action.payload}})
            return({...state, removeKnowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeKnowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'KNOWLEDGE_REMOVE_REJECTED': {
        return({...state, removeKnowledge: {progress: 2, payload: action.payload}})
    }
    
    case 'KNOWLEDGE_TOPIC_PENDING': {
      return({...state, topicKnowledge: {progress: 0}})
    }
    case 'KNOWLEDGE_TOPIC_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, topicKnowledge: {...api.topicKnowledge.response, progress: 1, payload: action.payload}})
            return({...state, topicKnowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, topicKnowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'KNOWLEDGE_TOPIC_REJECTED': {
        return({...state, topicKnowledge: {progress: 2, payload: action.payload}})
    }
    
    case 'KNOWLEDGE_CONTENT_PENDING': {
      return({...state, contentKnowledge: {progress: 0}})
    }
    case 'KNOWLEDGE_CONTENT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, contentKnowledge: {...api.contentKnowledge.response, progress: 1, payload: action.payload}})
            return({...state, contentKnowledge: {progress: 2, payload: action.payload}})
        } else {
            return({...state, contentKnowledge: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'KNOWLEDGE_CONTENT_REJECTED': {
        return({...state, contentKnowledge: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}