import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ProductBrowse from '../../libraries/realm/halo/ProductBrowse'
import ProductForm from '../../libraries/realm/halo/ProductForm'
import ProductSearch from '../../libraries/realm/halo/ProductSearch'


class ProductBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        productId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, productId: id})
    }

    render() {
      const {mode, productId}= this.state
      const {screenWidth}= this.props
      
      return(
        <Container fluid>
          <div>
            <ProductSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <ProductBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} />}
              {mode==2 && <ProductForm mode={mode} productId={productId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <ProductForm mode={mode} productId={0} setMode={this.setMode.bind(this)} />}
            </div>
          </div>
        </Container>
      )
    }
}

export default ProductBody
