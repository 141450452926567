import React,{Component} from 'react';
import { Header, Card, Icon, Image, Container, List, Button, Label, Dimmer, Segment, Rating, Form, Divider} from 'semantic-ui-react'

import { filePath, urlUpload } from './_lib'

//FILEPOND
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
//import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
//import FilePondPluginImageResize from 'filepond-plugin-image-resize';
//import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
//import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

//registerPlugin(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginImageResize, FilePondPluginImageCrop, FilePondPluginImageTransform);
registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileRename);

export default class FileUploadFilepond extends Component {
  constructor(props) {
    super(props);
    this.state= {
      filePath: filePath
    }
  }

  render() {
    const { filePath, file }= this.state

    return(
      <div>
        <FilePond
          acceptedFileTypes= {['text/plain',
          'application/pdf',
          'application/doc',]}
          ref={ref => this.pond = ref}
          files={this.state.files}
          allowMultiple={false}
          maxFiles={3} 
          server={{ 
            url: this.props.server || urlUpload,
            process: {
              headers: {
                  'Authorization': localStorage.getItem('tokenAuth')
              }
            }
          }}
          fileRenameFunction= {(file) => {
            return Date.now() + '_' + file.name.replace(' ','-');
          }}
          //oninit={() => this.handleInit() }
          onupdatefiles={data => {
            // Set currently active file objects to this.state
            // console.log('ON UPDATE FILES: ', data)
            this.setState({
              files: data.map(item => item.file)
            });
          }}
          onprocessfile={(e,data)=>{
            // console.log('ON PROCESS FILES: ', e, data.file)
            this.props.onChange(filePath + data.file.name)
            this.setState({
              file: filePath + data.file.name
            });
          }}
          onremovefile={(e, data)=>{
            // console.log('ON REMOVE FILES: ', e, data)
            this.props.onChange('')
            this.setState({
              files: undefined,
              file: undefined
            });
          }}
          
          />
      </div>
    )
  }
  
}