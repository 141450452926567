import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { resetReport, createReport } from "../../actions/stream/reportAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    reportStore: state.reportStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetReport:(objParam) => dispatch(resetReport(objParam)),
    createReport:(objParam) => dispatch(createReport(objParam)),
  }
}

class ReportForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetReport(this.props.mode)
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= this.props.reportStore.createReport!=undefined ? this.props.reportStore.createReport : {}
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, reportId } = this.props
    this.props.createReport({...values})
  }
  

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.reportStore.updateReport!=undefined ? this.props.reportStore.updateReport : {}) : (this.props.reportStore.createReport!=undefined ? this.props.reportStore.createReport : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Header as='h3'>
            <Header.Content>
              Manual Report generation
              <Header.Subheader>For <b style={{color: '#c31'}}>Certain Date Only !</b> transaction, report files will be replaced by automation service if available.</Header.Subheader>
            </Header.Content>
          </Header>

          <Divider hidden />
          <Segment>
            <Form.Group widths='equal'>
              <Field name='transactDate' component={DatePickerField} style={{textAlign: 'center', fontSize: '2em'}}
              validate={[required]}
              label= {<Header as='h5'>
                <Icon name='calendar alternate outline' />
                <Header.Content>
                  Generation Date
                  <Header.Subheader>Exported file will have (D-1) transaction.</Header.Subheader>
                </Header.Content>
              </Header>}
              placeholder='Transaction Date of Report Data' />
              <Form.Field />
            </Form.Group>
          </Segment>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Report' : 'Insert Report') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: ({}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'ReportForm',	// a unique identifier for this form
  })(ReportForm)
  )
)