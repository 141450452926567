import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { resultOptions, timeOptions }  from '../../common/StaticMasterData'
import DismissableMessage from '../../common/DismissableMessage'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { resetTransact, getTransact,updateTransact, createTransact } from "../../../actions/stream/transactAction"
import { listProduct, listRemark } from "../../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('VerificationForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    transactStore: state.transactStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    
    resetTransact:(objParam) => dispatch(resetTransact(objParam)),
    getTransact:(objParam) => dispatch(getTransact(objParam)),
    createTransact:(objParam) => dispatch(createTransact(objParam)),
    updateTransact:(objParam) => dispatch(updateTransact(objParam)),
  }
}

class VerificationForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.resetTransact(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getTransact({transactId: this.props.transactId})
    }

    this.props.listProduct()
    this.props.listRemark({clientId: this.props.clientId, campaignId: this.props.campaignId})
    
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.transactStore.updateTransact!=undefined ? this.props.transactStore.updateTransact : {}) : (this.props.transactStore.createTransact!=undefined ? this.props.transactStore.createTransact : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, transactId } = this.props
    
    if (mode==3) { //INSERT
      this.props.createTransact({...values, voices: null, appointments: null})
    } else if(mode==2) { //UPDATE
      this.props.updateTransact({...values, voices: null, appointments: null })
    }
  }

  onChangeResult(v) {
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})

    this.props.dispatch(change('VerificationForm', 'progress', listRemark.progress[v]));
  }
  
  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props

    const { data }= (this.props.transactStore.getTransact!=undefined && this.props.transactStore.getTransact.progress=='1' ? this.props.transactStore.getTransact : {})
    const { progress, code, text }= (mode==2 ? (this.props.transactStore.updateTransact!=undefined ? this.props.transactStore.updateTransact : {}) : (this.props.transactStore.createTransact!=undefined ? this.props.transactStore.createTransact : {}))
    // const listAgent= (this.props.masterStore.listAgent!=undefined && this.props.masterStore.listAgent.progress=='1' ? this.props.masterStore.listAgent.data : [])
    const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Header as='h5'>
              <Icon name='edit outline' style={{fontSize: '1em'}}/>
              <Header.Content>Verification Remarks</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Form.Field width={8} />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group>
            <Form.Group>
              <Field name='remark' component={SelectField} onChange={this.onChangeResult.bind(this)}
              width={4}
              options={listRemark.options||[]}
              label= 'Result'
              placeholder='Verification Result' />
              {this.props.remark=='Rescheduled' && <>
              <Field name='appointmentDate' component={DatePickerField}
              width={4}
              label= 'Appointment Date'
              placeholder='Appointment Date' />
              <Field name='appointmentSession' component={SelectField}
              options={timeOptions||[]}
              width={4}
              label= 'Appointment Time'
              placeholder='Appointment Time' />
              </>}
              {this.props.remark!='Rescheduled' && <>
              <Field name='productId' component={SelectField}
              width={8}
              options={listProduct}
              label='Offered Product / Service'
              placeholder='Offered Product / Service' />
              <Field name='value' component={NumberField}
              prefix='Rp. '
              thousandSeparator=','
              width={4}
              label='Amount / Valuation'
              placeholder='Amount / Valuation' /></>}
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='reason' component={InputField}
              width={16}
              label= 'Reason'
              placeholder='Remarks Note / Reason' />  
            </Form.Group>
          </Segment>
          {data!=undefined && data.appointments!=null && 
          <Segment>
            <Header as='h5'>
              <Icon name='bell outline' style={{fontSize: '1em'}}/>
              <Header.Content>Appointment</Header.Content>
            </Header>
            {data.appointments.map((item, i)=>{
              if (i==0) { 
                return(
                  <div style={{display: 'flex', padding: '.5em 0'}}>
                    <div style={{flex: '1', padding: '1em'}}>
                      <Header as='h3' color='red'>
                        <Icon name='calendar check outline' style={{color: '#aaa'}}/>
                        <Header.Content>
                          {format(parse(item.startEvent, 'yyyy-MM-dd', new Date()), 'dd/MMM, yyyy')}
                          <Header.Subheader>{item.session || 'Pagi'} via {item.channelType || 'Voice'}</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>
                    <div style={{flex: '2', borderLeft: '1px dashed rgb(112 200 255)', padding: '.5em 1em'}}>
                      <Header as='h5' style={{margin: '0'}}>
                      <Header.Subheader>TOPIC</Header.Subheader>
                      <i>{item.topic}</i></Header>
                      <Header as='h5' style={{margin: '.5em 0'}}>
                      <Header.Subheader>VENUE</Header.Subheader>
                        <i>{item.venue}</i></Header>
                      <Divider />
                      <Header as='h5' style={{margin: '0'}}>
                        <Header.Content>
                          <Header.Subheader>CONTENT</Header.Subheader>
                          {item.content}
                        </Header.Content>
                      </Header>
                    </div>
                  </div>
                )
              }
            })}
          </Segment>}
          <Segment>
            <Header as='h5'>
              <Icon name='user circle' style={{fontSize: '1em'}}/>
              <Header.Content>Target / Campaign</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              readOnly
              width={8}
              placeholder='Name' />
              <Field name='mobile' component={InputField}
              readOnly
              width={4}
              placeholder='Mobile' />
              <Field name='email' component={InputField}
              readOnly
              width={4}
              placeholder='Email' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='information' component={InputField}
              readOnly
              width={16}
              placeholder='Information' />
            </Form.Group>
            {/* <Form.Group widths='equal'>
              <Field name='city' component={InputField}
              width={4}
              placeholder='City' />
              <Field name='province' component={InputField}
              width={4}
              placeholder='Province' />
              <Field name='address' component={InputField}
              width={8}
              placeholder='Address' />
            </Form.Group> */}
          
            <Divider hidden />
            <Form.Group widths='16'>
              <Field name='campaign' component={InputField}
              readOnly
              width={16}
              placeholder='Campaign' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='campaignType' component={InputField}
              readOnly
              width={4}
              placeholder='Campaign Type' />
              <Field name='startDate' component={InputField}
              readOnly
              width={4}
              placeholder='StartDate' />
              <Field name='endDate' component={InputField}
              readOnly
              width={4}
              placeholder='End Date' />
              <Field name='startTime' component={InputField}
              readOnly
              width={2}
              placeholder='Start Time' />
              <Field name='endTime' component={InputField}
              readOnly
              width={2}
              placeholder='End Time' />
            </Form.Group>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Transact' : 'Insert Transact') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
          
          <Segment>
            <Header as='h5'>
              <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
              <Header.Content>Engagement</Header.Content>
            </Header>
            <Table unstackable selectable columns='16' celled striped basic='very' style={{border: 'none'}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width='3'><Header color='blue' as='h5'>STAMP</Header></Table.HeaderCell>
                  <Table.HeaderCell width='1'><Header color='blue' as='h5'>CHANNEL</Header></Table.HeaderCell>
                  <Table.HeaderCell width='5'><Header color='blue' as='h5'>REMARKS</Header></Table.HeaderCell>
                  <Table.HeaderCell width='4'><Header color='blue' as='h5'>AGENT</Header></Table.HeaderCell>
                  <Table.HeaderCell width='3'><Header color='blue' as='h5'>RESULT</Header></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data!=undefined && data.voices!=undefined && data.voices.map((item, i)=>{
                  return(
                    <Table.Row key={i}>
                      <Table.Cell>{i+1}.</Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {item.dialStamp!=null ? format(parse(item.dialStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss') : ''}
                          <Header.Subheader>{item.dialStamp!=null ? format(parse(item.dialStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM, yyyy') : null}</Header.Subheader>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        {item.channelType || 'Voice'}
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <div style={{background: (item.status=='1' && '#84dbff') || (item.status=='0' && '#ffb3b3') || '#ddd', textAlign: 'right', display: 'inline-block', width: '4em', padding: '.5em', marginRight: '.5em'}}>{item.progress} %</div>
                          <Header.Content>
                            {item.remark || 'No remark !'}
                            <Header.Subheader>on <i>{item.expired!=null ? format(parse(item.expired, 'yyyy-MM-dd', new Date()), 'dd/MMM yyyy') : 'none'}</i></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {item.dunner!=null ? item.dunner.name : 'No agent info'}
                          <Header.Subheader>Tags: {item.dunner!=null ? item.dunner.tagger || 'null' : ''} / Account: {item.dunner!=null ? item.dunner.account || '-' : ''}</Header.Subheader>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {item.reason || 'No information'}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.transactStore.getTransact!=undefined && state.transactStore.getTransact.progress==1 ? state.transactStore.getTransact.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
      remark: selector(state, 'remark'),
    }),
  )
  (reduxForm({
    form: 'VerificationForm',	// a unique identifier for this form
  })(VerificationForm)
  )
)