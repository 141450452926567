import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetTunnel(objParam) {
  return {
      type: 'TUNNEL_RESET',
      payload: objParam
  }
}

export function getTunnel(objParam) {
  return {
      type: 'TUNNEL_GET',
      payload:
        axios.post(
          urlAPI + 'tunnel/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusTunnel(objParam) {
  return {
      type: 'TUNNEL_STATUS',
      payload:
        axios.post(
          urlAPI + 'tunnel/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseTunnel(objParam) {
  return {
      type: 'TUNNEL_BROWSE',
      payload:
        axios.post(
          urlAPI + 'tunnel/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createTunnel(objParam) {
  return {
      type: 'TUNNEL_CREATE',
      payload:
        axios.post(
          urlAPI + 'tunnel/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateTunnel(objParam) {
  return {
      type: 'TUNNEL_UPDATE',
      payload:
        axios.post(
          urlAPI + 'tunnel/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeTunnel(objParam) {
  return {
      type: 'TUNNEL_REMOVE',
      payload:
        axios.post(
          urlAPI + 'tunnel/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
