import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table, Label } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import {groupOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, TextAreaField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetAccount, getAccount,updateAccount, createAccount } from "../../actions/halo/accountAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    accountStore: state.accountStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetAccount:(objParam) => dispatch(resetAccount(objParam)),
    getAccount:(objParam) => dispatch(getAccount(objParam)),
    createAccount:(objParam) => dispatch(createAccount(objParam)),
    updateAccount:(objParam) => dispatch(updateAccount(objParam)),
  }
}

class MessagingForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      setAppointment: false,
    }
  }

  componentDidMount(){
    this.props.resetAccount(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getAccount({userId: this.props.userId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.accountStore.updateAccount!=undefined ? this.props.accountStore.updateAccount : {}) : (this.props.accountStore.createAccount!=undefined ? this.props.accountStore.createAccount : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateAccount({...values}) : this.props.createAccount({...values})
    
  }

  render() {
    const { setAppointment }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, bills } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.accountStore.updateAccount!=undefined ? this.props.accountStore.updateAccount : {}) : (this.props.accountStore.createAccount!=undefined ? this.props.accountStore.createAccount : {}))
    
    return (
      <div>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div style={{background: '#f0f0f0', display:'flex', flexDirection: 'column'}}>
            <div style={{flex: '1', padding: '1em', borderBottom: '1px solid rgba(100,100,100,.3)'}}>
              <div style={{display: 'flex', marginBottom: '1.5em'}}>
                <div style={{flex: '1', marginRight: '1.5em'}}>
                  <Header as='h5'>
                    <Header.Content>
                      Recent Message
                      <Header.Subheader>Recent message shown here</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div>
                  <Icon name='angle double down' style={{fontSize: '1.3em', margin: '0'}} />
                </div>
              </div>

              <div style={{margin: '1em 10em .5em 0', background: '#fafafa'}}>
                <Segment attached='top' style={{padding: '.5em 1em', marginRight: '10em', fontWeight: 'bold', background: 'rgb(126 247 237)'}}>
                  Jack Dickzon, 20/May, jack@mariana.com
                </Segment>
                <Segment  attached='bottom' style={{padding: '1em 1em', marginRight: '10em', background: '#fafafa'}}>
                  Content ! Messaging    
                </Segment>
              </div>

              <div style={{margin: '1em 0 .5em 10em', background: '#fafafa'}}>
                <Segment attached='top' style={{padding: '.5em 1em', marginRight: '10em', fontWeight: 'bold', background: 'rgb(253 255 174)', textAlign: 'right'}}>
                  Jack Dickzon, 20/May, jack@mariana.com
                </Segment>
                <Segment  attached='bottom' style={{padding: '1em 1em', marginRight: '10em', background: '#fafafa', textAlign: 'right'}}>
                  Content ! Messaging    
                </Segment>
              </div>

            </div>
            <div style={{padding: '1em', background: '#eaeaea', borderTop: '1px solid rgba(250,250,250,.7)'}}>
              <Form.Group widths='16'>
                <Field name='subject' component={InputField}
                width={16}
                label= 'Subject'
                placeholder='Subject' />
              </Form.Group>
              <Form.Group widths='16'>
                <Field name='message' component={TextAreaField}
                width={16}
                label= 'Message'
                placeholder='Message' />  
              </Form.Group>
            </div>
            <div style={{background: '#eaeaea', padding: '1em'}}>
              <Form.Group widths='16'>
                <Field name='template' component={InputField}
                width={4}
                label= 'Channel'
                placeholder='Channel' />
                <Field name='template' component={InputField}
                width={6}
                label= 'Template'
                placeholder='Template' />
                <Field name='sender' component={InputField}
                width={6}
                label= 'Sender'
                placeholder='Sender' />
              </Form.Group>

              <Divider hidden/>
              {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Account' : 'Insert Account') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
              
              <div style={{textAlign: 'right'}}>
                <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
                <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.accountStore.getAccount!=undefined && state.accountStore.getAccount.progress==1 ? state.accountStore.getAccount.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: getAccount }
  )
  (reduxForm({
    form: 'MessagingForm',	// a unique identifier for this form
  })(MessagingForm)
  )
)
