import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import DismissableMessage from '../../common/DismissableMessage'
import IconToggle from '../../common/IconToggle'

import {resultOptions, groupOptions, genderOptions, educationOptions, competencyOptions, protoOptions, accountOptions}  from '../../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, formValueSelector } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, NumberField, DatePickerField } from '../../validation/componentrsui'
import { required, strongPassword, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { resetAccount, getAccount,updateAccount, createAccount } from "../../../actions/halo/accountAction"
import { checkToken } from "../../../actions/auth/authAction"

//CONNECT REDUX STORE
const selector = formValueSelector('AccountForm')

const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    accountStore: state.accountStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),

    resetAccount:(objParam) => dispatch(resetAccount(objParam)),
    getAccount:(objParam) => dispatch(getAccount(objParam)),
    createAccount:(objParam) => dispatch(createAccount(objParam)),
    updateAccount:(objParam) => dispatch(updateAccount(objParam)),
  }
}

class AccountForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      showPasswd: false,
      channelOptions: null,
    }
  }

  componentDidMount(){
    this.props.resetAccount(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getAccount({userId: this.props.userId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.accountStore.updateAccount!=undefined ? this.props.accountStore.updateAccount : {}) : (this.props.accountStore.createAccount!=undefined ? this.props.accountStore.createAccount : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }

    const token= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken.data : {}
    if (!this.state.channelOptions && token) {
      const options= [];
      token.svcChannels && token.svcChannels.map(item=>{
        options.push({
          key: item,
          value: item,
          text: item,
        })
      })
      this.setState({
        channelOptions: options,
      })
    }
  }

  setMode(key) {
    if (key=='password') {
      this.setState({
        showPasswd: !this.state.showPasswd
      })
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateAccount({...values}) : this.props.createAccount({...values})
    
  }

  render() {
    const { showPasswd }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.accountStore.updateAccount!=undefined ? this.props.accountStore.updateAccount : {}) : (this.props.accountStore.createAccount!=undefined ? this.props.accountStore.createAccount : {}))
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='equal' >
              <Form.Field>
                <Field name='avatar' component={ImageUploadField}
                width={300} 
                height={300} 
                aspectRatio= '3:3'
                />
              </Form.Field>
            </Form.Group>
            {this.props.mode=='2' &&
            <Form.Group widths='equal'>
              <Field name='isEnabled' component={CheckboxField}
              toggle
              caption= 'Enable Now !' />
            </Form.Group>}
          </Segment>
          <Segment attached='top'>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={8}
              label= 'Name'
              placeholder='Full Name'
              validate={[required]} />
              {/* <Field name='quote' component={InputField}
              label= 'Quote'
              placeholder='Quote' /> */}
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='email' component={InputField}
              // readOnly= {mode==2? true : false}
              label= 'Email'
              placeholder='Email'
              validate={[required]} />
              <Field name='mobile' component={InputField}
              // readOnly= {mode==2? true : false}
              label= 'Mobile'
              placeholder='Mobile'
              validate={[required, phoneNumber]} />
            </Form.Group>
            {/* <Form.Group widths='equal'>
              <Field name='monthlySalary' component={NumberField}
                            label='Expected Salary / Month'
              placeholder='Rp. / Month' />
              <Field name='gender' component={SelectField}
                            label='Gender'
              options={genderOptions}
              placeholder='L/P' />
              <Field name='birthPlace' component={InputField}
              label='Birth Place'
              placeholder='Birth Place' />
              <Field name='birthDate' component={DatePickerField}
              label='Birth Date'
              placeholder='Birth Date' />
            </Form.Group> */}
          </Segment>

          {this.props.groupId=='Agent' && <Segment>
            <Form.Group widths='equal'>
              <Field name='tagger' component={InputField}
              label= 'Tagger'
              placeholder='Tagger (N1, N2, Nx)' />
              <Field name='userType' component={SelectField}
              options={accountOptions('usertype')}
              label= 'User Type'
              placeholder='AI / Human Agent' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='channels' component={SelectField}
              multiple
              label= 'Channels'
              options={this.state.channelOptions}
              placeholder='Conversation channels' />
            </Form.Group>
          </Segment>}

          <Segment>
            <div style={{display: 'flex', marginBottom: '2em'}}>
              <div style={{flex: '1'}}>
                <Header as='h5'>
                  <Icon name='spy' style={{fontSize: '1em'}} />
                  <Header.Content>
                    Account Credential
                    <Header.Subheader></Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div>
                <Header as='h5' style={{fontWeight: 'bold'}}>
                  <IconToggle active={showPasswd} onClick={this.setMode.bind(this, 'password')} />
                  <Header.Content>
                    Set Password
                    <Header.Subheader>Set password by Admin</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>    
            </div>
            
            <Form.Group widths='equal'>
              <Field name='account' component={InputField}
              readOnly= {mode==2? true : false}
              label= 'Account'
              placeholder='Account'
              validate={[required]} />
              <Field name='groupId' component={SelectField}
              label= 'User Group'
              placeholder='Role / Group'
              options={groupOptions('realm_halopagi')}
              validate={[required]} />
            </Form.Group>
            {showPasswd && <>
            {/* <Divider style={{margin: '1em 0 .5em 0'}}/> */}
            <Form.Group widths='equal'>
              <Field name='userPasswd' component={InputField}
              complexity
              icon='unlock alternate'
              type='password'
              validate={this.props.mode==2 ? [userPassword, strongPassword] : [required, strongPassword, userPassword]}
              label= {<b>New Password</b>}
              placeholder='Password' />
              <Field name='confirmedPasswd' component={InputField}
              complexity
              icon='unlock alternate'
              type='password'
              validate={this.props.mode==2 ? [confirmedPassword, strongPassword] : [required, strongPassword, confirmedPassword]}
              label= {<b>Confirmed Password</b>}
              placeholder='Confirmed Password' />
            </Form.Group>
            </>}
          </Segment>
          {/* <Segment>
            <Header as='h5'>
              <Icon name='map marker alternate' style={{fontSize: '1em'}}/>
              <Header.Content>Location</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='address' component={InputField}
                            placeholder='Address' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='city' component={InputField}
              width={8}
              placeholder='City' />
              <Field name='province' component={InputField}
              width={4}
              placeholder='Province' />
              <Field name='country' component={InputField}
              width={4}
              placeholder='Country' />
            </Form.Group>
          </Segment> */}

          
          {/* <Segment>
            <Header as='h5'>
              <Icon name='image outline' style={{fontSize: '1em'}}/>
              <Header.Content>Expertise</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='educationInstitute' component={InputField}
              width={8}
              label='School / Institute / University' />
              <Field name='educationDepartment' component={InputField}
              width={4}
              label='Major / Department' />
              <Field name='educationLevel' component={SelectField}
                            width={4}
              label='Level'
              options={educationOptions}
              placeholder='S1 / S2 / S3' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='competencies' component={SelectField}
              width={16}
              multiple
              search
              options={competencyOptions}
              label='Professional Competency' />
            </Form.Group>
          </Segment> */}
          {/* <Segment>
            <Header as='h5'>
              <Icon name='qrcode' style={{fontSize: '1em'}}/>
              <Header.Content>Voip Credential</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='voipAccount' component={InputField}
              width={4}
              label='Account' />
              <Field name='voipPasswd' component={InputField}
              width={4}
              label='Password' />
              <Field name='voipRealm' component={InputField}
              width={8}
              label='Realm' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='voipHost' component={InputField}
              width={8}
              label='Host / Server' />
              <Field name='voipPort' component={InputField}
              width={4}
              label='Port Number'/>
              <Field name='voipProto' component={SelectField}
              width={4}
              options={protoOptions}
              label='Protocol' />
            </Form.Group>
          </Segment> */}
          
          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Account' : 'Insert Account') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.accountStore.getAccount!=undefined && state.accountStore.getAccount.progress==1 ? state.accountStore.getAccount.data : {competencies: []}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      groupId: selector(state, 'groupId'),
    }),
    { load: getAccount }
  )
  (reduxForm({
    form: 'AccountForm',	// a unique identifier for this form
  })(AccountForm)
  )
)
