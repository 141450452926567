import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
} from 'semantic-ui-react'

import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { reportPath } from "../../libraries/common/_lib"
import { browseReport, statusReport } from "../../actions/stream/reportAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    reportStore: state.reportStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseReport:(objParam) => dispatch(browseReport(objParam)),
    statusReport:(objParam) => dispatch(statusReport(objParam)),
  }
}
class ReportingDownload extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.reportStore.browseReport!==undefined ? this.props.reportStore.browseReport : {})
    this.props.browseReport(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.reportStore.browseReport!==undefined ? this.props.reportStore.browseReport : {})
    this.props.browseReport({search: {transactDate: format(new Date(), 'yyyy-MM-01'), ...search}, paging: paging})
  }
  
  downloadReport(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
    // download("hello.txt","This is the content of my file");
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.reportStore.browseReport!==undefined ? this.props.reportStore.browseReport : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    const status= {};
    status[-1]= "Error";
    status[1]= "Succeed, Automation";
    status[2]= "Succeed, Manual Generation";

    // console.log('!!! REPORT BROWSE DATA', data)
    return (
      <div style={{display: 'flex', flexDirection: 'column', minHeight: screenHeight, padding: '1em'}}>
        <div style={{flex: '1', padding: '0'}}>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>
                    GENERATION
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='7'>
                  <Header as='h5' color='blue'>
                    STATUS
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='5'>
                  <Header as='h5' color='blue'>
                    REPORT
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data && data.length==0 &&<Table.Row><Table.Cell colSpan={4} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data ? data.map((item, key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                          {item.period.substr(6,2)}/{item.period.substr(4,2)} {item.period.substr(0,4)}
                            <Header.Subheader>{item.client}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {status[item.result]}
                            <Header.Subheader>{item.reason}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        {item.cdrFile && 
                          <a href={reportPath+ item.cdrFile}>
                            <Label size='large'>
                              <Icon color='red' name='cloud download' />CDR_<b style={{color: '#000'}}>{item.cdrFile}</b>
                            </Label>
                          </a>
                        }
                        {item.mdrFile &&
                          <a href={reportPath+ item.mdrFile}>
                            <Label size='small'>
                              <Icon color='red' name='cloud download' />MDR - <b style={{color: '#000'}}>{item.mdrFile}</b>
                            </Label>
                          </a>
                        }
                        {item.trxFile &&
                          <a href={reportPath+ item.trxFile}>
                            <Label size='small'>
                              <Icon color='red' name='cloud download outline' /> TRX - <b style={{color: '#000'}}>{item.trxFile}</b>
                            </Label>
                          </a>
                        }
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportingDownload)
