import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form, Rating, Label } from 'semantic-ui-react'

import { connect } from "react-redux"
import { rateOmniwidget } from "../../actions/flow/omniwidgetAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniwidgetStore: state.omniwidgetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    rateOmniwidget:(objParam) => dispatch(rateOmniwidget(objParam)),
  }
}


class OmniwidgetRate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lastChange: null,
      rating: 3,
      feedbacks: {
        attitude: {
          id: 'attitude',
          checked: false,
          title: 'Attitude',
          descript: 'Agent hospitality and tone must be improved.',
        },
        response: {
          id: 'response',
          checked: false,
          title: 'Response',
          descript: 'Response from agent is not fast as required.',
        },
        competency: {
          id: 'competency',
          checked: false,
          title: 'Competency',
          descript: 'Knowledge required to be improved.',
        },
      }
    };
  }

  componentDidMount() {
    const feedbacks= {}
    this.props.params && this.props.params.feedbacks && this.props.params.feedbacks.map((item, id)=>{
      feedbacks[item.topic]= {
        id: item.topic,
        checked: false,
        title: item.topic,
        descript: item.descript,
      }
    })

    this.props.params && this.props.params.feedbacks && this.setState({
      feedbacks: feedbacks,
    })
  }
  
  setFeedback(shortcut) {
    const { feedbacks }= this.state
    feedbacks[shortcut].checked= !feedbacks[shortcut].checked;

    this.setState({
      lastChange: new Date().getTime(),
      feedbacks: feedbacks
    })

    // const parent= this
    setTimeout(()=>{
      if (new Date().getTime()-this.state.lastChange>=3000) {
        this.props.setMode('home');
        this.props.rateOmniwidget({
          visitorId: this.props.booked ? this.props.booked.visitorId : null,
          rate: this.state.rating,
          feedbacks: this.state.feedbacks,
        })
      }
    }, 3000)
  }
  
  setRate(e, v) {
    // console.log(e, v)
    this.setState({
      lastChange: new Date().getTime(),
      rating: v.rating,
    })

    // const parent= this
    if (v.rating>=3 || this.props.params.feedbacks.length<=0) {
      setTimeout(()=>{
        if (new Date().getTime()-this.state.lastChange>=1000) {
          this.props.setMode('home');
          this.props.rateOmniwidget({
            visitorId: this.props.booked ? this.props.booked.visitorId : null,
            rate: this.state.rating,
            // feedbacks: this.state.feedbacks,
          })
        }
      }, 1000)
    }
  }

  render() {
    const { feedbacks, rating }= this.state
    const { screenWidth, screenHeight }= this.props
    
    const feedItems= []
    Object.keys(feedbacks).map(key=>{
      feedItems.push(feedbacks[key])
    })
    
    return(
      <div style={{margin: '-10em auto 0 auto', background: 'rgba(255, 255, 255, .95)',  borderRadius: '1.5em'}}>
        <div style={{padding: '2em 1em'}}>
          <div style={{minHeight: '15em'}}>
            <Header as='h4'>
              <Icon name='checkmark' />
              <Header.Content>
                Rate / Feedback
                <Header.Subheader>Better service for all of us.</Header.Subheader>
              </Header.Content>
            </Header>
            <div style={{textAlign: 'center', marginTop: '3em'}}>
              <div style={{margin: '4em 0'}}>
                <Divider horizontal>
                  <Label style={{padding: '1em .5em'}}>
                    <Rating maxRating={5} defaultRating={3} icon='star' size='huge' onRate={this.setRate.bind(this)} style={{fontSize: '2em'}}/>
                  </Label>
                </Divider>
              </div>

              <Label style={{borderRadius: '2em', background: '#333', padding: '.5em 2em'}}>
                <Header as='h2' style={{margin: '0', fontWeight: 'bold'}} color={(rating==1 && 'yellow') || (rating==2 && 'olive') || (rating==3 && 'teal') || (rating==4 && 'blue') || (rating==5 && 'black')} inverted>
                  <Header.Content>
                    <b>
                      {rating==1 && 'Worst !'}
                      {rating==2 && 'Bad'}
                      {rating==3 && 'Good'}
                      {rating==4 && 'Great'}
                      {rating==5 && 'Excelent'}
                    </b>
                  </Header.Content>
                </Header>
              </Label>
            </div>
            {((this.props.params && this.props.params.feedbacks && this.props.params.feedbacks.length>0) || this.state.feedbacks) && rating<=2 && <div style={{marginTop: '2em'}}>
              {feedItems.map((item, idx)=>{
                  return(<div key={idx}>
                    <Header as='h5' style={{margin: '0', fontWeight: 'bold', cursor: 'pointer'}} onClick={this.setFeedback.bind(this, item.id)} color={item.checked ? 'black' : null}>
                      <Icon name={item.checked ? 'check square outline' : 'square outline'} style={{fontSize: '1.5em'}}/>
                      <Header.Content>
                        <b>{item.title}</b>
                        <Header.Subheader>{item.checked ? <b>{item.descript}</b> : item.descript}</Header.Subheader>
                      </Header.Content>
                    </Header>
                    {idx<(feedItems.length-1) && <Divider style={{magin: '.5em 0 1em 0'}} />}
                  </div>)
                })
              }
            </div>}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OmniwidgetRate)