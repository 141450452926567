//LOCALHOST DEVELOPMENT
// module.exports.imagePath= 'http://localhost:1115/static/uploads/';
// module.exports.audioPath= 'http://localhost:1115/static/uploads/';
// module.exports.videoPath= 'http://localhost:1115/static/uploads/';
// module.exports.filePath= 'http://localhost:1115/static/uploads/';
// module.exports.urlUpload= 'http://localhost:1115/admin/api/v1/upload';
// module.exports.urlSave= 'http://localhost:1115/admin/api/v1/save';
// module.exports.savePath= 'http://localhost:1115/static/files/';
// module.exports.ivrPath= 'http://localhost:1115/static/interactives/';

// module.exports.reportPath= 'http://localhost:1115/report/';
// module.exports.recordPath= 'http://localhost:1115/voice/';

//HALOPAGI #1 DEVELOPMENT
// module.exports.imagePath= 'https://crm.halopagi.com/proxy/static/uploads/';
// module.exports.audioPath= 'https://crm.halopagi.com/proxy/static/uploads/';
// module.exports.videoPath= 'https://crm.halopagi.com/proxy/static/uploads/'
// module.exports.urlUpload= 'https://crm.halopagi.com/proxy/admin/api/v1/upload';
// module.exports.filePath= 'https://crm.halopagi.com/proxy/static/uploads/';
// module.exports.urlSave= 'https://crm.halopagi.com/proxy/admin/api/v1/save';
// module.exports.savePath= 'https://crm.halopagi.com/proxy/static/files/';
// module.exports.ivrPath= 'https://crm.halopagi.com/proxy/static/interactives/';

// module.exports.reportPath= 'https://crm.halopagi.com/report/';
// module.exports.recordPath= 'https://crm.halopagi.com/voice/';

//HALOPAGI #2 STREAM CLOUD
module.exports.imagePath= 'https://streamapp.halopagi.com/proxy/static/uploads/';
module.exports.audioPath= 'https://streamapp.halopagi.com/proxy/static/uploads/';
module.exports.videoPath= 'https://streamapp.halopagi.com/proxy/static/uploads/'
module.exports.urlUpload= 'https://streamapp.halopagi.com/proxy/admin/api/v1/upload';
module.exports.filePath= 'https://streamapp.halopagi.com/proxy/static/uploads/';
module.exports.urlSave= 'https://streamapp.halopagi.com/proxy/admin/api/v1/save';
module.exports.savePath= 'https://streamapp.halopagi.com/proxy/static/files/';
module.exports.ivrPath= 'https://streamapp.halopagi.com/proxy/static/interactives/';

module.exports.reportPath= 'https://streamapp.halopagi.com/report/';
module.exports.recordPath= 'https://streamapp.halopagi.com/voice/';

//TUNAIKU
// module.exports.imagePath= 'https://streamapp.tunaiku.com/proxy/static/uploads/';
// module.exports.audioPath= 'https://streamapp.tunaiku.com/proxy/static/uploads/';
// module.exports.videoPath= 'https://streamapp.tunaiku.com/proxy/static/uploads/'
// module.exports.urlUpload= 'https://streamapp.tunaiku.com/proxy/admin/api/v1/upload';
// module.exports.filePath= 'https://streamapp.tunaiku.com/proxy/static/uploads/';
// module.exports.urlSave= 'https://streamapp.tunaiku.com/proxy/admin/api/v1/save';
// module.exports.savePath= 'https://streamapp.tunaiku.com/proxy/static/files/';
// module.exports.ivrPath= 'https://streamapp.tunaiku.com/static/interactives/';

// module.exports.reportPath= 'https://streamapp.tunaiku.com/report/';
// module.exports.recordPath= 'https://streamapp.tunaiku.com/voice/';