// import socketIOClient from "socket.io-client";
// const socket = socketIOClient('http://127.0.0.1:4001');

// socket.on('FromAPI', payload => {
//   // update messages
//   // console.log('!!! WEBSOCKET EMIT EVENT', payload)
//   return payload
// })

export default function phoneReducer (state = {}, action) {
  // console.log("!!! REDUCER WEBSOCKET", action, state)

  switch (action.type) {
    case 'PHONE_STATUS': {
      // console.log("!!! REDUCER PHONE", action, state)
      return({...state, phoneStatus: {progress: 0, data: {}}})
    }

    default: {
      return state
    }
  }
}