import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetAssociate(objParam) {
  return {
      type: 'SOCMED_ASSOCIATE_RESET',
      payload: objParam
  }
}

export function getAssociate(objParam) {
  return {
    type: 'SOCMED_ASSOCIATE_GET',
    payload:
      axios.post(
        urlAPI + 'socmed/associate/get',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusAssociate(objParam) {
  return {
    type: 'SOCMED_ASSOCIATE_STATUS',
    payload:
      axios.post(
        urlAPI + 'socmed/associate/status',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseAssociate(objParam) {
  return {
      type: 'SOCMED_ASSOCIATE_BROWSE',
      payload:
        axios.post(
          urlAPI + 'socmed/associate/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
        ).catch(function(error){ //handle network error, return axios always 1
          // console.log('ERROR: ', error)
        })
  }
}

export function createAssociate(objParam) {
  return {
      type: 'SOCMED_ASSOCIATE_CREATE',
      payload:
        axios.post(
          urlAPI + 'socmed/associate/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateAssociate(objParam) {
  return {
      type: 'SOCMED_ASSOCIATE_UPDATE',
      payload:
        axios.post(
          urlAPI + 'socmed/associate/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeAssociate(objParam) {
  return {
      type: 'SOCMED_ASSOCIATE_REMOVE',
      payload:
        axios.post(
          urlAPI + 'socmed/associate/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
