import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Feed, Divider, Label, Button } from 'semantic-ui-react'

import MyBucketMenu from '../../../libraries/agent/ticket/MyBucketMenu';

import MyBucketBrowse from '../../../libraries/agent/ticket/MyBucketBrowse'
import MyBucketAssigned from '../../../libraries/agent/ticket/MyBucketAssigned';
import MyBucketNew from '../../../libraries/agent/ticket/MyBucketNew';
import MyBucketOpen from '../../../libraries/agent/ticket/MyBucketOpen';
import MyBucketPending from '../../../libraries/agent/ticket/MyBucketPending';
import MyBucketUnsolved from '../../../libraries/agent/ticket/MyBucketUnsolved';
import MyBucketSolved from '../../../libraries/agent/ticket/MyBucketSolved';
import MyBucketClosed from '../../../libraries/agent/ticket/MyBucketClosed';
import MyBucketAlert from '../../../libraries/agent/ticket/MyBucketAlert';


class MyBucketMenuBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      wide: true,
    };
  }

  render() {
    const {mode, layout, ticketId, param, bucket, filter, workspace, stamp, wide}= this.props
    const {screenWidth, screenHeight}= this.props
    
    // console.log(filter)
    return(
        <div style={{padding: '0 .6em'}}>
          <div style={{
            display: 'flex'
            }}>
            <div style={{
              width: 250, marginRight: '1em', padding: '1em .5em 0 .5em', display: wide ? 'block' : 'none',
              position: 'absolute', marginTop: '-4em', marginLeft: '.5em',
              background: 'rgb(238, 238, 238)', borderRadius: '.5em', border: '1px solid #ccc'
              }}>
              <MyBucketMenu bucket={bucket} filter={{...filter, scope: workspace}} workspace={workspace} setBucket={this.props.setBucket} setMode={this.props.setMode} setFilter={this.props.setFilter} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} closeModal={this.props.closeModal} />
            </div>
            <div style={{flex: 1, background: '#fafafa', padding: '1em .5em 0 .5em'}}>
              <div style={{position: 'fixed', marginTop: '2.5em', marginLeft: '-1em', cursor: 'pointer'}}>
                <Icon color='yellow' inverted circular onClick={this.props.setWide.bind(this)} name={wide ? 'arrow left' : 'arrow right'} />
              </div>
              {filter && (
                (filter.title=='New' && <MyBucketNew stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='Open' && <MyBucketOpen stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                
                (filter.title=='Pending' && <MyBucketPending stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='On Hold' && <MyBucketPending stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||

                (filter.title=='Unsolved' && <MyBucketUnsolved stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='Solved' && <MyBucketSolved stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='Closed' && <MyBucketClosed stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='Warning' && <MyBucketAlert stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                
                (filter.title=='Assigned' && <MyBucketAssigned stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='Unassigned' && <MyBucketAssigned stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                
                (filter.title=='SLA Breach' && <MyBucketAssigned stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||
                (filter.title=='SLA Complied' && <MyBucketAssigned stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />) ||

                (filter.title && <MyBucketBrowse stamp={stamp} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />)
              )}
            </div>
          </div>
        </div>
    )
  }
}

export default MyBucketMenuBody
