import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import MessagingBrowse from '../../libraries/agent/MessagingBrowse'
import MessagingForm from '../../libraries/agent/MessagingForm'
import MessagingSearch from '../../libraries/agent/MessagingSearch'


class MessagingBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        userId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, userId: id})
    }

    render() {
      const {mode, userId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
            <MessagingSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <MessagingBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
              {mode==2 && <MessagingForm mode={mode} userId={userId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <MessagingForm mode={mode} userId={0} setMode={this.setMode.bind(this)} />}
            </div>
        </Container>
      )
    }
}

export default MessagingBody
