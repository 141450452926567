import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid, Label, Progress, Input, Dropdown, Card, Checkbox, Form } from 'semantic-ui-react'

import numeral from "numeral"

import IconToggle from '../common/IconToggle'
import PieRechart from '../chart/PieRechart';

import { connect } from "react-redux"
import { socketStatus, socketSystemMonitoring } from "../../actions/socket/socketAction"

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketSystemMonitoring:(objParam) => dispatch(socketSystemMonitoring(objParam)),
  }
}

class OmniTrafficWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
    };
  }

  componentDidMount() {
  }

  componentDidUpdate() {

  }

  render() {
    const { mode, screenWidth,  screenHeight, responsive, agent }= this.props;
    const { agents, omnis, providers, billings }= this.props.socketStore.socketSystemMonitoring!=undefined && this.props.socketStore.socketSystemMonitoring.data && this.props.socketStore.socketSystemMonitoring.data.payload && this.props.socketStore.socketSystemMonitoring.data.payload.data ? this.props.socketStore.socketSystemMonitoring.data.payload.data: {};
    const { statistic }= omnis ? omnis : {};
    
    return (<>
      {!agent && <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart area' style={{fontSize: '1em'}} />
          <Header.Content>
            <i>Today!</i> Inbound Traffics
            <Header.Subheader></Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>}
      <Segment style={{padding: '1em 1.5em 1em 1.5em'}} attached='bottom'>
        {agent && <div style={{display: 'flex', marginBottom: '1em'}}>
          <div style={{flex: '1'}}>
            <Label color='purple' style={{borderRadius: '2em', padding: '.1em 1em .5em 1em'}}>
              <Header as='h4' style={{marginTop: '.25em'}} inverted>
                <Icon name='chart area' style={{fontSize: '1em'}} />
                <Header.Content style={{fontWeight: 'bold'}}>
                  <i>Today!</i> Inbound Traffics
                </Header.Content>
              </Header>
            </Label>
          </div>
          <div style={{marginLeft: '1em'}}>
          </div>
        </div>}

        <Divider />
        <Grid style={{margin: '0'}}>
          <Grid.Row width={16} >
            <Grid.Column width={4} style={{background: '#f0f0f0'}}>
              {statistic && statistic.totVoiceQueue && <Label circular color='red' floating>20</Label>}
              <Header as='h4' style={{margin: '0'}}>
                <Icon name='microphone' />
                <Header.Content>
                  Phone Call
                  <Header.Subheader>Phone Call / Telco</Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={12} style={{background: '#f5f5f5'}}>
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totVoiceQueue) || 0)/ ((statistic && statistic.totVoice) || 1))} progress style={{margin: '0'}}/>
              <Divider style={{margin: '.3em 0'}} />
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totVoiceDispatched) || 0)/ ((statistic && statistic.totVoice) || 1))} progress style={{margin: '0'}}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width={16} >
            <Grid.Column width={4} style={{background: '#f0f0f0'}}>
              {statistic && statistic.totInAppQueue && <Label circular color='red' floating>20</Label>}
              <Header as='h4' style={{margin: '0'}}>
                <Icon name='tablet alternate' />
                <Header.Content>
                  In-App Call
                  <Header.Subheader>Audio/Video In-App Call</Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={12} style={{background: '#f5f5f5'}}>
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totInAppQueue) || 0)/ ((statistic && statistic.totInApp) || 1))} progress style={{margin: '0'}}/>
              <Divider style={{margin: '.3em 0'}} />
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totInAppDispatched) || 0)/ ((statistic && statistic.totInApp) || 1))} progress style={{margin: '0'}}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width={16} >
            <Grid.Column width={4} style={{background: '#f0f0f0'}}>
              {statistic && statistic.totMailQueue && <Label circular color='red' floating>20</Label>}
              <Header as='h4' style={{margin: '0'}}>
                <Icon name='envelope open outline' />
                <Header.Content>
                  Email
                  <Header.Subheader>Email Conversation</Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={12} style={{background: '#f5f5f5'}}>
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totMailQueue) || 0)/ ((statistic && statistic.totMail) || 1))} progress style={{margin: '0'}}/>
              <Divider style={{margin: '.3em 0'}} />
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totMailDispatched) || 0)/ ((statistic && statistic.totMail) || 1))} progress style={{margin: '0'}}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width={16} >
            <Grid.Column width={4} style={{background: '#f0f0f0'}}>
              {statistic && statistic.totWhatsappQueue && <Label circular color='red' floating>20</Label>}
              <Header as='h4' style={{margin: '0'}}>
                <Icon name='whatsapp' />
                <Header.Content>
                  Whatsapp
                  <Header.Subheader>Whatsapp Messaging</Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={12} style={{background: '#f5f5f5'}}>
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totWhatsappQueue) || 0)/ ((statistic && statistic.totWhatsapp) || 1))} progress style={{margin: '0'}}/>
              <Divider style={{margin: '.3em 0'}} />
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totWhatsappDispatched) || 0)/ ((statistic && statistic.totWhatsapp) || 1))} progress style={{margin: '0'}}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width={16} >
            <Grid.Column width={4} style={{background: '#f0f0f0'}}>
              {statistic && statistic.totTwitterQueue && <Label circular color='red' floating>20</Label>}
              <Header as='h4' style={{margin: '0'}}>
                <Icon name='twitter' />
                <Header.Content>
                  Twitter
                  <Header.Subheader>Twitter Direct Message</Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={12} style={{background: '#f5f5f5'}}>
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totTwitterQueue) || 0)/ ((statistic && statistic.totTwitter) || 1))} progress style={{margin: '0'}}/>
              <Divider style={{margin: '.3em 0'}} />
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totTwitterDispatched) || 0)/ ((statistic && statistic.totTwitter) || 1))} progress style={{margin: '0'}}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width={16} >
            <Grid.Column width={4} style={{background: '#f0f0f0'}}>
              {statistic && statistic.totFacebookQueue && <Label circular color='red' floating>20</Label>}
              <Header as='h4' style={{margin: '0'}}>
                <Icon name='facebook' />
                <Header.Content>
                  Facebook
                  <Header.Subheader>Facebook Messenger</Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={12} style={{background: '#f5f5f5'}}>
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totFacebookQueue) || 0)/ ((statistic && statistic.totFacebook) || 1))} progress style={{margin: '0'}}/>
              <Divider style={{margin: '.3em 0'}} />
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totFacebookDispatched) || 0)/ ((statistic && statistic.totFacebook) || 1))} progress style={{margin: '0'}}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width={16} >
            <Grid.Column width={4} style={{background: '#f0f0f0'}}>
              {statistic && statistic.totInstagramQueue && <Label circular color='red' floating>20</Label>}
                <Header as='h4' style={{margin: '0'}}>
                  <Icon name='instagram' />
                  <Header.Content>
                    Instagram
                    <Header.Subheader>Instagram Direct Message</Header.Subheader>
                  </Header.Content>
                </Header>
            </Grid.Column>
            <Grid.Column width={12} style={{background: '#f5f5f5'}}>
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totInstagramQueue) || 0)/ ((statistic && statistic.totInstagram) || 1))} progress style={{margin: '0'}}/>
              <Divider style={{margin: '.3em 0'}} />
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totInstagramDispatched) || 0)/ ((statistic && statistic.totInstagram) || 1))} progress style={{margin: '0'}}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width={16} >
            <Grid.Column width={4} style={{background: '#f0f0f0'}}>
              {statistic && statistic.totAppstoreQueue && <Label circular color='red' floating>20</Label>}
                <Header as='h4' style={{margin: '0'}}>
                  <Icon name='apple' />
                  <Header.Content>
                    App Store
                    <Header.Subheader>Apple Store Feedback</Header.Subheader>
                  </Header.Content>
                </Header>
            </Grid.Column>
            <Grid.Column width={12} style={{background: '#f5f5f5'}}>
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totAppstoreQueue) || 0)/ ((statistic && statistic.totAppstore) || 1))} progress style={{margin: '0'}}/>
              <Divider style={{margin: '.3em 0'}} />
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totAppstoreDispatched) || 0)/ ((statistic && statistic.totAppstore) || 1))} progress style={{margin: '0'}}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width={16} >
            <Grid.Column width={4} style={{background: '#f0f0f0'}}>
              {statistic && statistic.totPlaystoreQueue && <Label circular color='red' floating>20</Label>}
                <Header as='h4' style={{margin: '0'}}>
                  <Icon name='google play' />
                  <Header.Content>
                    Play Store
                    <Header.Subheader>Android play store feedback.</Header.Subheader>
                  </Header.Content>
                </Header>
            </Grid.Column>
            <Grid.Column width={12} style={{background: '#f5f5f5'}}>
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totPlaystoreQueue) || 0)/ ((statistic && statistic.totPlaystore) || 1))} progress style={{margin: '0'}}/>
              <Divider style={{margin: '.3em 0'}} />
              <Progress size='small' indicating percent={Math.round(((statistic && statistic.totPlaystoreDispatched) || 0)/ ((statistic && statistic.totPlaystore) || 1))} progress style={{margin: '0'}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>);
  }
}

OmniTrafficWidget.contextType= WebSocketContext;

export default connect(mapStateToProps, mapDispatchToProps)(OmniTrafficWidget)
