import React, { Component } from 'react'

import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'

class AgentFooter extends Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }
  
  render() {
    const {mini, screenHeight}= this.props;

    return (
      <div style={{paddingTop: '1.5em', background: '#fafafa'}}>
        <div style={{padding: mini==true ? '2em 22.5em 2em 1.8em' : '2em 22.5em 2em 7.5em', 
        // top: (screenHeight-60), position: 'fixed', 
        width: '100%', minHeight: 60}}>
          {/* <CredentialWidget /> */}
          <Header as='h5'>
            <Header.Content>
              <b><i>Stream!</i></b> Multi Channel Digital Experience  2.3.01
              <Header.Subheader><b>Halo Pagi!</b> - Digital Communication Platform 2023</Header.Subheader>
            </Header.Content>
          </Header>
        </div>
      </div>
    )
  }
}

export default AgentFooter