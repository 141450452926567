import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetSetting(objParam) {
  return {
      type: 'SETTING_RESET',
      payload: objParam
  }
}

export function browseSetting(objParam) {
  return {
      type: 'SETTING_BROWSE',
      payload:
        axios.post(
          urlAPI + 'setting/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function getSetting(objParam) {
  return {
      type: 'SETTING_GET',
      payload:
        axios.post(
          urlAPI + 'setting/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateSetting(objParam) {
  return {
      type: 'SETTING_UPDATE',
      payload:
        axios.post(
          urlAPI + 'setting/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function omniSetting(objParam) {
  return {
      type: 'SETTING_OMNI',
      payload:
        axios.post(
          urlAPI + 'setting/omni',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function senderEnergy(objParam) {
  return {
      type: 'SETTING_ENERGY',
      payload:
        axios.post(
          urlAPI + 'setting/energy',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function senderQRCode(objParam, urlCustom) {
  return {
      type: 'SETTING_QRCODE',
      payload:
        axios.post(
          urlAPI + 'setting/qrcode',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
