import React,{Component} from 'react';
import { Header, Card, Icon, Image, Container, List, Button, Label, Dimmer, Segment, Rating, Form, Divider} from 'semantic-ui-react'

import { imagePath, urlUpload } from './_lib'

//FILEPOND
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

//registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileRename, FilePondPluginImagePreview, FilePondPluginImageResize, FilePondPluginImageCrop, FilePondPluginImageTransform);
registerPlugin(FilePondPluginFileValidateType)

export default class ImageUploadFilepond extends Component {
  constructor(props) {
    super(props);
    this.state= {
      imagePath: imagePath,
      mini: true,
    }
  }

  setMini() {
    this.setState({
      mini: !this.state.mini
    })
  }

  render() {
    const {height, width, resizeHeight, resizeWidth, aspectRatio } = this.props
    const { imagePath, file, mini }= this.state

    if (mini) {
      return(
        <div style={{width: width + 'px', verticalAlign: 'center', textAlign: 'center'}}>
          <Divider  style={{marginBottom: '-1.7em', }}/>
          <Button type='button' size='big' icon circular style={{padding: '0'}} onClick={this.setMini.bind(this)} color={file!=undefined || this.props.srcImage!='' ? 'red' : null}>
            <Icon name='image outline' size='large'/>
          </Button>
        </div>
      )
    } else {
      return(
        <div style={{width: width, verticalAlign: 'center', minHeight: height, }}>
          {file || this.props.srcImage!='' ? 
            <Image src={(file!=undefined ? file.name : undefined) || this.props.srcImage}  height={height} width={width} style={{marginBottom: '-8em'}} wrapped /> 
            // <div style={{height: height || 350, width: width||350, marginBottom: '-8em', backgroundColor: '#fafafa', backgroundImage: 'url('+ (file ? file.name : this.props.srcImage) +')', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: '100% auto'}}/> 
            : <Icon name='image outline' style={{fontSize: '5em', marginTop: '2em', width: '100%'}}/>
          }
          {this.props.readOnly!=true && <FilePond
            acceptedFileTypes= {['image/*']}
            ref={ref => this.pond = ref}
            files={this.state.files}
            allowMultiple={false}
            maxFiles={3} 
            server={{ 
              url: urlUpload,
              process: {
                headers: {
                    'Authorization': localStorage.getItem('tokenAuth')
                }
              }
            }}
            fileRenameFunction= {(file) => {
              return Date.now() + '_' + file.name.replace(' ','-');
            }}
            //oninit={() => this.handleInit() }
            onupdatefiles={data => {
              // Set currently active file objects to this.state
              // console.log('ON UPDATE FILES: ', data)
              this.setState({
                files: data.map(item => item.file)
              });
            }}
            onprocessfile={(e,data)=>{
              // console.log('ON PROCESS FILES: ', e, data.file)
              this.props.onChange(imagePath + data.file.name)
              this.setState({
                file: imagePath + data.file.name
              });
            }}
            onremovefile={(e, data)=>{
              // console.log('ON REMOVE FILES: ', e, data)
              this.props.onChange('')
              this.setState({
                files: undefined,
                file: undefined
              });
            }}

            allowImagePreview= {false}
            //imagePreviewMaxHeight= {height}

            //allowImageCrop= {true}
            //imageCropAspectRatio= {aspectRatio}

            //allowImageResize= {true}
            //imageResizeMode= 'cover'
            
            //allowImageTransform= {true}
            //imageResizeTargetWidth= {resizeWidth || width}
            
            />
          }
          <div style={{width: width + 'px', verticalAlign: 'center', textAlign: 'center', marginTop: '2em'}}>
            <Divider  style={{marginBottom: '-1em', }}/>
            <Button type='button' size='small' icon circular style={{padding: '0'}} onClick={this.setMini.bind(this)}>
              <Icon name='angle double up' size='large'/>
            </Button>
          </div>
        </div>
      )
    }
  } 
  
}