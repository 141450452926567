import { applyMiddleware, createStore } from 'redux'
// import axios from 'axios'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { loadingBarMiddleware } from 'react-redux-loading-bar'

//import {viewUser} from './actions/userAction'
import reducers from './Reducers'
// import reducers from './ovisoneReducers'

/*
const logger = (store) => (next) => (action) => {
  // console.log("ACTION-FIRE: ", action)
}
*/
// const middleware = applyMiddleware(promise,loadingBarMiddleware(),thunk, logger)
// const middleware = applyMiddleware(promise,thunk, logger)
const middleware = applyMiddleware(promise,loadingBarMiddleware())

const store= createStore(reducers, middleware)

/*
store.dispatch(viweUser())

store.dispatch({
  type: 'VIEW_USER',
  payload: axios.get('https://jsonplaceholder.typicode.com/post/1')
})

store.dispatch((dispatch)=> {
  dispatch({
    type: 'VIEW_USER',
    payload: axios.get('https://jsonplaceholder.typicode.com/post/1')
  })
  dispatch({
    type: 'VIEW_USER',
    payload: axios.get('https://jsonplaceholder.typicode.com/post/1')
  })
})

console.log('STORE STATE', store.getState())
*/

export default store
