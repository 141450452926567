import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse, addSeconds, differenceInSeconds, differenceInMinutes, startOfDay, subDays } from "date-fns"

import { recentOptions }  from '../../../modules/service/StaticMaster'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField, LabelSelectField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { ticketBucket } from "../../../actions/service/bucketAction"
import { listRemark } from "../../../actions/service/masterAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStoreTicket,
    bucketStore: state.bucketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    ticketBucket:(objParam) => dispatch(ticketBucket(objParam)),
  }
}


class MyBucketSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
    }
  }

  componentDidMount() {
    this.props.listRemark()
  }

  submitForm(values) {
    const { search }= this.state
    this.props.ticketBucket({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    const { search }= (this.props.bucketStore.ticketBucket!==undefined ? this.props.bucketStore.ticketBucket : {})
    const values= {};
    values[key]= v;
    this.props.ticketBucket({search: {...search}, param: this.props.filter})
  }

  onChangeInput(e, v) {
    const { search }= (this.props.bucketStore.ticketBucket!==undefined ? this.props.bucketStore.ticketBucket : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.ticketBucket({search: {...search, keyword: v}, param: this.props.filter})
      }
    }, 500)
  }

  onChangeSelect(e, v) {
    var ticketDate;
    const { search }= (this.props.bucketStore.ticketBucket!==undefined ? this.props.bucketStore.ticketBucket : {})

    if (e=='remark') {
      this.props.ticketBucket({search: {...search, remark: (v=='' ? null : v)}, param: this.props.filter})
    } else if (e=='recent') {
      switch (v) {
        case 'today':
          ticketDate= format(new Date(), 'yyyy-MM-dd 00:00:00');
        break;
        case '24H':
          ticketDate= format(subDays(new Date(), 1), 'yyyy-MM-dd HH:mm:ss');
        break;
        case '10D':
          ticketDate= format(startOfDay(subDays(new Date(), 10)), 'yyyy-MM-dd 00:00:00');
        break;
        case '30D':
          ticketDate= format(startOfDay(subDays(new Date(), 30)), 'yyyy-MM-dd 00:00:00');
        break;
        default:
      }
      this.props.ticketBucket({search: {...search, recent: (v=='' ? null : v), ticketDate: ticketDate}, param: this.props.filter})
    }

    // console.log(e, v, ticketDate)
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, layout }=this.props

    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : [])
    
    return (
      <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
        <div style={{padding: '1em 1em 1em 1em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div>
              {/* <Label color='blue' style={{borderRadius: '2em', padding: '.5em 1em .6em 1em'}} size='large'> */}
                <Header as='h4' style={{marginTop: '.25em'}}>
                  {/* <Icon name='ticket' style={{fontSize: '1em'}} /> */}
                  <Header.Content style={{fontWeight: 'bold'}}>
                    My Bucket
                    <Header.Subheader>List of your ticket here.</Header.Subheader>
                  </Header.Content>
                </Header>
              {/* </Label> */}
              {/* <Header as='h4' style={{marginTop: '.25em'}}>
                <Icon name='archive' style={{fontSize: '1em'}}/>
                <Header.Content>
                  {layout==1 && <>
                    Ticket Bucket / <b>{(this.props.bucket && this.props.bucket.text)}</b> - {<Label color={
                      (this.props.filter && this.props.filter.title=='New' && 'olive') || 
                      (this.props.filter && this.props.filter.title=='Open' && 'red') || 
                      (this.props.filter && this.props.filter.title=='Pending' && 'yellow') || 
                      (this.props.filter && this.props.filter.title=='On Hold' && 'black') || 
                      (this.props.filter && this.props.filter.title=='Warning' && 'Orange') || 
                      (this.props.filter && this.props.filter.title=='Solved' && 'blue') || 
                      (this.props.filter && this.props.filter.title=='Unsolved' && 'pink') || 
                      (this.props.filter && this.props.filter.title=='SLA Breached' && 'pink') || 
                      (this.props.filter && this.props.filter.title=='SLA Complied' && 'teal') || 
                      (this.props.filter && this.props.filter.title=='Closed' && 'teal') || 
                      (this.props.filter && this.props.filter.title=='Assigned' && 'purple') || 
                      (this.props.filter && this.props.filter.title=='Requested' && 'violet') || 
                      (this.props.filter && this.props.filter && 'brown') || 
                      'grey'
                    } style={{marginLeft: '0', marginTop:'-.4em', padding: '.3em .7em .4em .7em'}} size='big'>{(this.props.filter && this.props.filter.title) || 'No Filter'}</Label>}
                  </>}
                  {layout==2 && <>
                    Ticket Bucket / <b>{(this.props.bucket && this.props.bucket.text)}</b>
                  </>}
                </Header.Content>
              </Header> */}
            </div>
            <div style={{flex: '1', margin: '0 1.5em'}}>
              {mode==1 && searchMode && 
                <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      <Form.Group widths='16'>
                        <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                        width={6} 
                        placeholder= 'Search ticket ... <ENTER>' />
                        <Field name='remark' component={LabelSelectField} onChange= {this.onChangeSelect.bind(this, 'remark')}
                        label='Remark'
                        options={listRemark}
                        width={5} 
                        placeholder= 'Remark / Status' />
                        <Field name='recent' component={LabelSelectField} onChange= {this.onChangeSelect.bind(this, 'recent')}
                        options={recentOptions()}
                        width={5} 
                        label='Recent'
                        placeholder= 'Recent Period' />
                      </Form.Group>
                    </div>
                  </div>
                </Form>
              }
            </div>
            <div>
              {mode !=1 &&
              <Popup position= 'top right' inverted content='Back'
                trigger= {<Button size='big' color='blue' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='arrow left' /></Button>} 
              />}
              {/* {mode !=2 && mode !=3 &&
              <Popup position= 'top right' inverted content='New Bucket'
                trigger= {<Button size='small' color='red' onClick={this.props.setMode.bind(this,3)} icon style={{padding: '.5em'}}><Icon name='plus' /></Button>} 
              />} */}
              {/* {mode ==1 &&
              <Popup position= 'top right' inverted content='Show / Hide'
                trigger= {<Button size='big' color='grey' onClick={this.showSearch.bind(this)} icon style={{padding: '.5em'}}><Icon name={searchMode==true? 'angle up' : 'angle down'} /></Button>} 
              />} */}
              {layout==1 &&
              <Popup position= 'top right' inverted content='Set to Pipeline Mode'
                trigger= {<Button size='big' color='grey' onClick={this.props.setLayout.bind(this,2)} icon style={{padding: '.5em .4em .4em .4em'}}><Icon name='align justify' style={{fontSize: '1.2em', transform: 'rotate(90deg)'}} /></Button>} 
              />}
              {layout==2 &&
              <Popup position= 'top right' inverted content='Set to Menu Mode'
                trigger= {<Button size='big' color='brown' onClick={this.props.setLayout.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='list ul' /></Button>} 
              />}
            </div>
          </div>

          {/* {mode==1 && searchMode && 
          <div style={{margin: '1em 0 0em 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='16'>
                    <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                    width={8} 
                    placeholder= 'Search ticket ... <ENTER>' />
                    <Field name='remark' component={LabelSelectField} onChange= {this.onChangeSelect.bind(this, 'remark')}
                    label='Remark'
                    options={listRemark}
                    width={4} 
                    placeholder= 'Remark / Status' />
                    <Field name='recent' component={LabelSelectField} onChange= {this.onChangeSelect.bind(this, 'recent')}
                    options={recentOptions()}
                    width={4} 
                    label='Recent'
                    placeholder= 'Recent Period' />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>} */}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.bucketStore.ticketBucket!=undefined && state.bucketStore.ticketBucket.progress==1 ? state.bucketStore.ticketBucket.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'MyBucketSearch',	// a unique identifier for this form
  })(MyBucketSearch)
  )
)