import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetTimeline(objParam) {
  return {
      type: 'SOCMED_TIMELINE_RESET',
      payload: objParam
  }
}

export function getTimeline(objParam) {
  return {
    type: 'SOCMED_TIMELINE_GET',
    payload:
      axios.post(
        urlAPI + 'socmed/timeline/get',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusTimeline(objParam) {
  return {
    type: 'SOCMED_TIMELINE_STATUS',
    payload:
      axios.post(
        urlAPI + 'socmed/timeline/status',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseTimeline(objParam) {
  return {
      type: 'SOCMED_TIMELINE_BROWSE',
      payload:
        axios.post(
          urlAPI + 'socmed/timeline/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
        ).catch(function(error){ //handle network error, return axios always 1
          // console.log('ERROR: ', error)
        })
  }
}

export function createTimeline(objParam) {
  return {
      type: 'SOCMED_TIMELINE_CREATE',
      payload:
        axios.post(
          urlAPI + 'socmed/timeline/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateTimeline(objParam) {
  return {
      type: 'SOCMED_TIMELINE_UPDATE',
      payload:
        axios.post(
          urlAPI + 'socmed/timeline/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeTimeline(objParam) {
  return {
      type: 'SOCMED_TIMELINE_REMOVE',
      payload:
        axios.post(
          urlAPI + 'socmed/timeline/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
