import React, { Component } from 'react'
import { Icon, TextArea, Header, Divider } from 'semantic-ui-react';


import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

class EditorWysiwyg extends Component {
  constructor(props) {
    super(props)

    // const editorState= EditorState.createEmpty()
    // this.state = {
    //   editorState: editorState,
    //   contentState: null,
    // }

    this.state= {
      mode: null,
      editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(this.props.value))) || EditorState.createEmpty(),
      contentState: ContentState.createFromBlockArray(htmlToDraft(this.props.value))
    };
  }
  componentDidMount() {
    this.props.mode && this.setState({
      mode: this.props.mode,
    })
  }
  componentDidUpdate(prevProps) {
    // console.log('DID UPDATE PROPS VALUE', this.props.value, this.state.mode)
    if (this.state.mode=='wysiwyg' && (this.props.value!='' || prevProps.value!='')  && this.props.value!=prevProps.value && !this.state.contentState) {
      // console.log('PROPS VALUE', this.props.value, htmlToDraft(this.props.value))
    
      this.setState({
        editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(this.props.value))),
        contentState: ContentState.createFromBlockArray(htmlToDraft(this.props.value))
      });
    }
    if (this.state.mode!='wysiwyg' && (this.props.value!='' || prevProps.value!='')  && this.props.value!=prevProps.value) {
      this.setState({
        editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(this.props.value))),
        contentState: ContentState.createFromBlockArray(htmlToDraft(this.props.value))
      });
    }
  }

  onEditorStateChange(editorState) {
    // console.log(editorState)
    if (editorState) {
      this.setState({
        editorState: editorState
      })
    }
  }

  setMode() {
    this.setState({
      mode: (this.state.mode=='wysiwyg' ? 'html' : 'wysiwyg')
    });
  }

  onContentStateChange(contentState) {
    // console.log('DID UPDATE WYSIWYG PROPS: ', contentState)
    // console.log('CONTENT CHANGE WYSIWYG PROPS: ', draftToHtml(contentState))
    // console.log('DID UPDATE WYSIWYG PROPS: ', htmlToDraft(draftToHtml(contentState)))

    if (contentState && this.state.mode=='wysiwyg') {
      this.props.onChange(draftToHtml(contentState))

      this.setState({
        contentState: contentState
        // contentState: htmlToDraft(draftToHtml(contentState))
      });
    }
    if (this.state.mode!='wysiwyg') {
      this.props.onChange(draftToHtml(contentState))
    }
  };
  
  onChange(value) {
    // console.log(value);
    // e.bind(this.props.value)
    // if (this.state.mode!='wysiwyg') {
    //   // this.props.onChange(draftToHtml(contentState))
    //   this.props.onChange.bind(this.props.value)
    // }
  }



  render() {
    const { screenWidth }= this.props
    const { contentState, editorState, mode } = this.state
    // if (mode=='wysiwyg') {
    //   return (
    //     <div style={{background: 'rgba(255,255,255, 1)'}}>
    //       <div>
    //         <Icon name='file alternate outline' onClick={this.setMode.bind(this)} style={{cursor: 'pointer'}}/>
    //       </div>
    //       { <Editor
    //         editorState={editorState}
    //         initialContentState={contentState}
    //         toolbarClassName="toolbarClassName"
    //         wrapperClassName="wrapperClassName"
    //         editorClassName="editorClassName"
    //         onEditorStateChange={this.onEditorStateChange.bind(this)}
    //         onContentStateChange={this.onContentStateChange.bind(this)}
    //       /> }
    //     </div>
    //   )
    // } else {
    //   return (
    //     <div style={{background: 'rgba(255,255,255, 1)'}}>
    //       <div>
    //         <Icon name='file alternate outline' onClick={this.setMode.bind(this)} style={{cursor: 'pointer'}}/>
    //       </div>
    //       { <TextArea width={this.props.width} height={this.props.height}  rows={30}  {...this.props.input} {...this.props.rest}/> }
    //     </div>
    //   )
    // }
    return (
      <div style={{background: 'rgba(255,255,255, 1)'}}>
        {!this.props.mode && 
        <div style={{textAlign: 'right', background: '#eee', padding: '.5em .1em'}}>
          <Icon name={this.state.mode=='wysiwyg' ? 'wizard' : 'file code'} onClick={this.setMode.bind(this)} style={{cursor: 'pointer'}} circular inverted color={this.state.mode=='wysiwyg' ? 'blue' : 'red'} />
        </div>}
        <div style={{display: 'flex', background: '#fafafa', border: '1px solid #ddd'}}>
          { this.state.mode=='wysiwyg' && <div style={{flex: '1', margin: '.5em'}}>
            <Editor readOnly={mode=='wysiwyg' ? false : true}
              editorState={editorState}
              initialContentState={contentState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={this.onEditorStateChange.bind(this)}
              onContentStateChange={this.onContentStateChange.bind(this)}
            />
          </div> }
          { this.state.mode!='wysiwyg' && <div style={{flex: '1', margin: '.5em'}}>
            <div style={{display: screenWidth>1400 ? 'flex' : 'block'}}>
              <div style={{border: '1px solid #ccc', marginBottom: '.5em', minHeight: '450px', maxWidth: this.props.modal ? 'auto' : '480px', padding: '1em'}}>
                <Header as='h5'>
                  Content Preview, Here !
                </Header>
                <Divider />
                <div dangerouslySetInnerHTML={{__html: this.props.value}} />
              </div>
              <div style={{flex: 1, marginLeft: screenWidth>1400 ? '1em' : '0', marginBottom: screenWidth>1400 ? '.5em' : '0', }}>
                <TextArea value={this.props.value}width={this.props.width} height={this.props.height}  rows={30} {...this.props.input} {...this.props.rest}  readOnly={mode=='wysiwyg' ? true : false} style={{height: '100%', whiteSpace: 'nowrap'}}
                  onChange={this.props.onChange.bind(this.props.value)}
                  // onChange={this.onChange.bind(this, this.props.onChange, this.props.value)}
                />
              </div>
            </div>
          </div> }
        </div>
      </div>
    )
    
  }
}

export default EditorWysiwyg