import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  Popup,
} from 'semantic-ui-react'



class OmniphoneIncoming extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, layout, responsive }= this.props
    return (
      <>
        <Segment attached={true} style={{padding: '1em .5em', minHeight: '20em', background: '#da0000', border: 'none', textAlign: !responsive ? 'center' : 'left'}}>
          <Header as='h3' icon={!responsive} inverted style={{margin: '1em .5em 0 .5em', display: 'inline'}}>
            {responsive && <>
            <Popup header='Answer Now !' content='Callback must be answered to start performance counter.' trigger={
              <Icon name='key' className='bganimateiconborder' style={{transform: 'rotate(135deg)', fontSize: '1em', cursor: 'pointer'}} inverted circular color='blue' onClick={this.props.callAnswer.bind(this)}/>
            } />
            <Header.Content style={{paddingLeft: '.5em'}}>
              {mode=='predialed' ? 'Incoming ...' : 'Verification !'}
              <Header.Subheader>
                {mode=='predialed' ? 'Inbound call ...' : 'Verification callback ...'}
              </Header.Subheader>
            </Header.Content></>}
            {!responsive && <>
            <Icon name={mode=='predialed' ? 'user circle outline' : 'bell outline'} style={{fontSize: '5em', fontWeight: '100', textShadow: '3px 5px 3px #000'}}/>
            <Header.Content style={{paddingTop: '.5em'}}>
              {mode=='predialed' ? 'Incoming / Inbound Call' : 'Verification !'}
              <Header.Subheader style={{fontSize: '.8em'}}>
                {mode=='predialed' ? 'Your on predial mode, please pick up your incoming call.' : 'Our system assign auto callback, please pick it up to start performance counter.'}
              </Header.Subheader>
            </Header.Content></>}
          </Header>
        </Segment>

        {!responsive &&
        <Segment attached={true} style={{padding: '1em .5em 2em .5em', background: '#444', border: 'none'}}>
          <Divider />
          <Popup header='Answer Now !' content='Callback must be answered to start performance counter.' trigger={
            <div className='bganimateicon' style={{borderRadius: '10em', height: '8em', width: '8em', margin: '2.5em auto 0 auto', textAlign: 'center'}}>
              <Header as='h4' icon inverted style={{margin: '1.5em 0 1em 0'}}>
                <Icon name='key' style={{fontSize: '1.9em', cursor: 'pointer'}} circular inverted color='blue' onClick={this.props.callAnswer.bind(this)}/>
                {/* <Header.Content>Click to Hangup</Header.Content> */}
              </Header>
            </div>
          } />
        </Segment>}
      </>
    )
  }
}

export default OmniphoneIncoming