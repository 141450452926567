import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Label } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import { calendarOptions, uptimeOptions, responseOptions, resolveOptions }  from '../common/StaticMasterData'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetSegment, getSegment,updateSegment, createSegment } from "../../actions/service/segmentAction"
import { listWorkgroup, listSegments, listHandlings } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('SegmentForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    segmentStore: state.segmentStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listSegments:(objParam) => dispatch(listSegments(objParam)),
    listHandlings:(objParam) => dispatch(listHandlings(objParam)),

    resetSegment:(objParam) => dispatch(resetSegment(objParam)),
    getSegment:(objParam) => dispatch(getSegment(objParam)),
    createSegment:(objParam) => dispatch(createSegment(objParam)),
    updateSegment:(objParam) => dispatch(updateSegment(objParam)),
  }
}

class SegmentForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null
    }
  }

  componentDidMount(){
    this.props.resetSegment(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getSegment({segmentId: this.props.segmentId})
    }

    this.props.listWorkgroup({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
    this.props.listHandlings({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
    this.props.listSegments({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
  }

  componentDidUpdate(prevProps) {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.segmentStore.updateSegment!=undefined ? this.props.segmentStore.updateSegment : {}) : (this.props.segmentStore.createSegment!=undefined ? this.props.segmentStore.createSegment : {}))
    const listSegments= (this.props.masterStore.listSegments!=undefined && this.props.masterStore.listSegments.progress=='1' ? this.props.masterStore.listSegments.data : [])
    const listHandlings= (this.props.masterStore.listHandlings!=undefined && this.props.masterStore.listHandlings.progress=='1' ? this.props.masterStore.listHandlings.data : [])

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }

    if (this.props.segmentType && prevProps.segmentType!=this.props.segmentType) {
      listSegments.map(item=>{
        if (item.value==this.props.segmentType) {
          this.props.dispatch(change('SegmentForm', 'segmentParams', item.params ));
        }
      })
    } else if (!this.props.segmentType && prevProps.segmentType) {
      this.props.dispatch(change('SegmentForm', 'segmentParams', [] ));
    }

    if (this.props.handlings && JSON.stringify(prevProps.handlings || [])!=JSON.stringify(this.props.handlings)) {
      const handlings= [];
      listHandlings.map(item=>{
        if (this.props.handlings.includes(item.value)) {
          handlings.push({
            key: item.value,
            params: item.params,
          })
        }
      })
      this.props.dispatch(change('SegmentForm', 'segmentHandlings', handlings));
    } else if (!this.props.handlings && (JSON.stringify(prevProps.handlings || [])=="[]")) {
      this.props.dispatch(change('SegmentForm', 'segmentHandlings', [] ));
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateSegment({...values}) : this.props.createSegment({...values})
    
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    
    const { data }= (this.props.segmentStore.getSegment!=undefined && this.props.segmentStore.getSegment.progress=='1' ? this.props.segmentStore.getSegment : {})
    const { progress, code, text }= (mode==2 ? (this.props.segmentStore.updateSegment!=undefined ? this.props.segmentStore.updateSegment : {}) : (this.props.segmentStore.createSegment!=undefined ? this.props.segmentStore.createSegment : {}))

    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listHandlings= (this.props.masterStore.listHandlings!=undefined && this.props.masterStore.listHandlings.progress=='1' ? this.props.masterStore.listHandlings.data : [])
    const listSegments= (this.props.masterStore.listSegments!=undefined && this.props.masterStore.listSegments.progress=='1' ? this.props.masterStore.listSegments.data : [])
    
    return (
      <div style={{padding: '1em 1.5em'}}>
        <Header as='h4' style={{marginBottom: '2em'}}>
          <Icon name='clone outline' style={{fontSize: '1em'}}/>
          <Header.Content>
            Segmentation / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='16'>
              {this.props.mode==2 &&
              <Field name='isEnabled' component={CheckboxField}
              width={8}
              toggle
              caption='Enabled'
              label='Enable Now !'
              placeholder='Status enable/disabled' />}
              {/* <Form.Field width={4} />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' /> */}
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={8}
              validate={[required]}
              label='Segmentation'
              placeholder='Segmentation Name' />
              <Field name='segmentType' component={SelectField}
              width={8}
              options={listSegments}
              validate={[required]}
              label='Segment Type'
              placeholder='Segmentation Type / Category' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='descript' component={TextAreaField}
              rows={2}
              label='Description'
              placeholder='Description' />
            </Form.Group>

            <Header as='h5' color='blue' style={{margin: '1em 0 .3em 0'}}>
              Segment Assessment Params
            </Header>
            <Label.Group style={{padding: '.9em .9em .5em .9em', borderRadius: '.2em', border: '1px solid #dadada'}}>
              {this.props.segmentParams && this.props.segmentParams.map((item, i)=>{
                return(
                  <Label key={i} color='grey'>{item.key}</Label>
                )
              })}
            </Label.Group>
          </Segment>
          
          <Segment>
            <Form.Group widths='equal'>
              <Field name='slaResponse' component={SelectField}
              options={responseOptions}
              validate={[required]}
              label='Mean Time To Response'
              placeholder='Mean Time To Response' />
              <Field name='slaResolution' component={SelectField}
              options={resolveOptions}
              validate={[required]}
              label='Mean Time To Resolve'
              placeholder='Mean Time To Resolve' />
              <Field name='slaAvailability' component={SelectField}
              options={uptimeOptions}
              validate={[required]}
              label='Availability Uptime'
              placeholder='Availability Uptime' />
              <Field name='slaCalendar' component={SelectField}
              options= {calendarOptions}
              validate={[required]}
              label='Working Calendar'
              placeholder='Weekday / Calendar' />
            </Form.Group>
          </Segment>

          <Segment>
            <Form.Group widths='equal'>
              <Field name='handlings' component={SelectField}
              multiple
              options={listHandlings||[]}
              validate={[required]}
              label='Ticket Handling Activity'
              placeholder='Handling activity list' />
            </Form.Group>
          </Segment>
          
          <Segment>
              TICKET SPLIT AND GROUPING
          </Segment>

          <Segment>
            ASSIGNMENT
          </Segment>
          <div style={{display: 'flex'}}>
            <div style={{flex: 1, marginRight: '.5em'}}>
              <Segment style={{height: '100%'}}>
                <Form.Group widths='equal'>
                  <Field name='dispatches' component={SelectField}
                  multiple
                  options={listWorkgroup||[]}
                  validate={[required]}
                  label='Dispatched Workgroup Agent'
                  placeholder='Dispatched to first handler by Agent' />
                </Form.Group>
              </Segment>
            </div>
            <div style={{flex: 1, marginLeft: '.5em'}}>
              <Segment style={{height: '100%'}}>
                <Form.Group widths='equal'>
                  <Field name='dispositions' component={SelectField}
                  multiple
                  options={listWorkgroup||[]}
                  validate={[required]}
                  label='Dispositions Workgroup Handler'
                  placeholder='Dispositions to whom responsible or PIC / UIC' />
                </Form.Group>
              </Segment>
            </div>
          </div>
          
          <Segment>
            <Form.Group widths='equal'>
              <Field name='escalations' component={SelectField}
              multiple
              options={listWorkgroup||[]}
              validate={[required]}
              label='Escalations Workgroup Strategic'
              placeholder='Escalations to Higher Level Management' />
            </Form.Group>
          </Segment>

          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Segment' : 'Insert Segment') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.segmentStore.getSegment!=undefined && state.segmentStore.getSegment.progress==1 ? state.segmentStore.getSegment.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      segmentType: selector(state, 'segmentType'),
      segmentParams: selector(state, 'segmentParams'),
      handlings: selector(state, 'handlings'),
      segmentHandlings: selector(state, 'segmentHandlings'),
    }),
    { load: getSegment }
  )
  (reduxForm({
    form: 'SegmentForm',	// a unique identifier for this form
  })(SegmentForm)
  )
)
