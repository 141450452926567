import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import { format, parse } from "date-fns"
import MenuHorizontal from '../../../libraries/common/MenuHorizontal';
import CollectionBody from './CollectionBody'
import RestructuringBody from './RestructuringBody'
import TelesaleBody from './TelesaleBody'
import VerificationBody from './VerificationBody'

import { connect } from "react-redux"
import { browseTransact, statusTransact } from "../../../actions/stream/transactAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    transactStore: state.transactStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTransact:(objParam) => dispatch(browseTransact(objParam)),
  }
}

const options=(props)=> {
  const menu= [];

  if (props && (props.isCollection=='1')) {
    menu.push({
      key: '1',
      icon: 'copy outline',
      caption: 'Collection',
      hint: {
        header: 'Collection - 100%',
        content: 'List of collection call transaction which is complete 100%.',
      }
    })
  }
  if (props && (props.isRestructuring=='1')) {
    menu.push({
      key: '2',
      icon: 'cubes',
      caption: 'Restructuring',
      hint: {
        header: 'Restructuring - 100%',
        content: 'List of restructuring/remedial call transaction which is complete 100%.',
      }
    })
  }
  if (props && (props.isTelesales=='1')) {
    menu.push({
      key: '3',
      icon: 'closed captioning outline',
      caption: 'Telesales',
      hint: {
        header: 'Telesales - 100%',
        content: 'List of telesales call transaction which is complete 100%.',
      }
    })
  }
  if (props && (props.isVerification=='1')) {
    menu.push({
      key: '4',
      icon: 'lab',
      caption: 'Verification',
      hint: {
        header: 'Verification - 100%',
        content: 'List of verification call transaction which is complete 100%.',
      }
    })
  }
  return(menu);
  // return (
  //   [
  //     {
  //       key: '1',
  //       icon: 'copy outline',
  //       caption: 'Collection',
  //       hint: {
  //         header: 'Collection - 100%',
  //         content: 'List of collection call transaction which is complete 100%.',
  //       }
  //     },
  //     {
  //       key: '2',
  //       icon: 'cubes',
  //       caption: 'Restructuring',
  //       hint: {
  //         header: 'Restructuring - 100%',
  //         content: 'List of restructuring/remedial call transaction which is complete 100%.',
  //       }
  //     },
  //     {
  //       key: '3',
  //       icon: 'closed captioning outline',
  //       caption: 'Telesales',
  //       hint: {
  //         header: 'Telesales - 100%',
  //         content: 'List of telesales call transaction which is complete 100%.',
  //       }
  //     },
  //     {
  //       key: '4',
  //       icon: 'lab',
  //       caption: 'Verification',
  //       hint: {
  //         header: 'Verification - 100%',
  //         content: 'List of verification call transaction which is complete 100%.',
  //       }
  //     },
  //   ]
  // );
}

class ActivityBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 0, //1: browse, 2: edit, 3: insert, 4: delete
        // dashboardId: 0,
      };
    }
    componentDidMount() {
      const { mode }= this.state;
      const { search, paging }= (this.props.transactStore.browseTransact!==undefined ? this.props.transactStore.browseTransact : {})
      this.props.browseTransact(
        {
          search: {...search, ...{
            campaignType: (this.props.checkToken.isCollection=='1' && 'Collection') || (this.props.checkToken.isRestructuring=='1' && 'Restructuring') || (this.props.checkToken.isTelesales=='1' && 'Telesales') || (this.props.checkToken.isVerification=='1' && 'Verification') || 
            (mode=='2' && 'Restructuring') || (mode=='3' && 'Telesales') || (mode=='4' && 'Verification') || 'Collection', 
            transactDate: (search && search.transactDate ? search.transactDate : format(new Date(), 'yyyy-MM-dd'))
          }},
        }
      )

      this.props.checkToken && this.setState({
        mode: (this.props.checkToken.isCollection=='1' && 1) || (this.props.checkToken.isRestructuring=='1' && 2) || (this.props.checkToken.isTelesales=='1' && 3) || (this.props.checkToken.isVerification=='1' && 4) || '0'
      })
    }

    setMode(mode, id) {
      const { search, paging }= (this.props.transactStore.browseTransact!==undefined ? this.props.transactStore.browseTransact : {})
      this.setState({mode: mode})
      this.props.browseTransact(
        {
          search: {...search, campaignType: (mode=='2' && 'Restructuring') || (mode=='3' && 'Telesales') || (mode=='4' && 'Verification') || 'Collection', remark: undefined}
        }
      )
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props

      return(
        <Container fluid>
          <div style={{margin: '0em 1em 0 1em'}}>
            <MenuHorizontal onClick={this.setMode.bind(this)}  options= {options(this.props.checkToken)} color='red'/>
          </div>
          <div>
            {mode==1 && <CollectionBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
            {mode==2 && <RestructuringBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
            {mode==3 && <TelesaleBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
            {mode==4 && <VerificationBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
          </div>
        </Container>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityBody)