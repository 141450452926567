import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetMessaging(objParam) {
  return {
      type: 'MESSAGING_RESET',
      payload: objParam
  }
}

export function getMessaging(objParam) {
  return {
      type: 'MESSAGING_GET',
      payload:
        axios.post(
          urlAPI + 'messaging/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusMessaging(objParam) {
  return {
      type: 'MESSAGING_STATUS',
      payload:
        axios.post(
          urlAPI + 'messaging/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseMessaging(objParam) {
  return {
      type: 'MESSAGING_BROWSE',
      payload:
        axios.post(
          urlAPI + 'messaging/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createMessaging(objParam) {
  return {
      type: 'MESSAGING_CREATE',
      payload:
        axios.post(
          urlAPI + 'messaging/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateMessaging(objParam) {
  return {
      type: 'MESSAGING_UPDATE',
      payload:
        axios.post(
          urlAPI + 'messaging/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeMessaging(objParam) {
  return {
      type: 'MESSAGING_REMOVE',
      payload:
        axios.post(
          urlAPI + 'messaging/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function inboundMessaging(objParam) {
  return {
      type: 'MESSAGING_INBOUND',
      payload:
        axios.post(
          urlAPI + 'messaging/inbound',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function outboundMessaging(objParam) {
  return {
      type: 'MESSAGING_OUTBOUND',
      payload:
        axios.post(
          urlAPI + 'messaging/outbound',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function searchMessaging(objParam) {
  return {
      type: 'MESSAGING_SEARCH',
      payload:
        axios.post(
          urlAPI + 'messaging/search',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function agentMessaging(objParam) {
  return {
      type: 'MESSAGING_AGENT',
      payload:
        axios.post(
          urlAPI + 'messaging/agent',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}