import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Label, TextArea } from 'semantic-ui-react'

import DismissableMessage from '../../common/DismissableMessage'
import { paramOptions, responsedOptions, yesnoOptions }  from '../../common/StaticMasterData'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, ToggleField, SelectField, TextAreaField, DatePickerField, MaskedField, AudioUploadField, LabelNumberField, LabelSelectField, LabelInputField } from '../../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { resetInteractive, getInteractive,updateInteractive, createInteractive } from "../../../actions/flow/interactiveAction"
import { listWorkgroup } from "../../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('InteractiveGreetingForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    interactiveStore: state.interactiveStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),

    resetInteractive:(objParam) => dispatch(resetInteractive(objParam)),
    getInteractive:(objParam) => dispatch(getInteractive(objParam)),
    createInteractive:(objParam) => dispatch(createInteractive(objParam)),
    updateInteractive:(objParam) => dispatch(updateInteractive(objParam)),
  }
}


class InteractiveGreetingForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null,
      navigations: null,
    }
  }

  componentDidMount(){
    this.props.resetInteractive(this.props.mode)
    // if (this.props.mode==2) { //update
      this.props.getInteractive({interactiveId: this.props.params.interactiveId})
    // } else {
    //   this.setState({
    //     navigations: [
    //       {
    //         level: 0,
    //       }
    //     ]
    //   })
    // }

    this.props.listWorkgroup({search: {clientId: this.props.clientId!=undefined ? this.props.clientId : null}})
  }

  componentDidUpdate() {
    const { navigations } = this.state
    const { mode } = this.props
    const { progress, code, text }= 
    // (mode==2 ? 
      (this.props.interactiveStore.updateInteractive!=undefined ? this.props.interactiveStore.updateInteractive : {})
      //  : (this.props.interactiveStore.createInteractive!=undefined ? this.props.interactiveStore.createInteractive : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }

    const { data }= this.props.interactiveStore.getInteractive!=undefined ? this.props.interactiveStore.getInteractive : {}
    if (!navigations && data) {
      this.setState({
        navigations: data.navigations || [
          {
            level: 0,
          }
        ]
      })
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    const { navigations } = this.state
    // console.log('!!! SUBMIT: ', values)
    // mode==2 ? 
    this.props.updateInteractive({...values, navigations: navigations})
    //  : this.props.createInteractive({...values, navigations: navigations})
    
  }

  addChildNav(parentLevel) {
    const { navigations }= this.state

    if (parentLevel>0) {
      let i= (parentLevel * 10) + 1;
      while (i<((parentLevel + 1) * 10)) {
        if (!navigations[i]) {
          navigations[i]= {
            level: i,
          }
          break;
        }
        i++;
      }
    } else {
      let i= 1;
      while (i<((parentLevel + 1) * 10)) {
        if (!navigations[i]) {
          navigations[i]= {
            level: i,
          }
          break;
        }
        i++;
      }
    }

    this.setState({
      navigations: navigations
    })
  }

  delChildNav(level) {
    const { navigations }= this.state
    if (level>0) {
      navigations[level]= undefined
    }
    this.setState({
      navigations: navigations
    })
  }

  render() {
    const { navigations }= this.state
    const { screenWidth, error, handleSubmit, pristine, reset, submitting, mode } = this.props
    
    const { data }= (this.props.interactiveStore.getInteractive!=undefined && this.props.interactiveStore.getInteractive.progress=='1' ? this.props.interactiveStore.getInteractive : {})
    const { progress, code, text }= (mode==2 ? (this.props.interactiveStore.updateInteractive!=undefined ? this.props.interactiveStore.updateInteractive : {}) : (this.props.interactiveStore.createInteractive!=undefined ? this.props.interactiveStore.createInteractive : {}))

    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    
    const navItems= []
    
    let i= 0;
    while (i<=9) {
      if (navigations && navigations[i] && i!=0) {
        navItems.push(navigations[i]);
        let j= (i * 10) + 1;
        while (j<((i+1) * 10)) {
          if (navigations[j]) {
            navItems.push(navigations[j]);
            let k= (j * 10) + 1;
            while (k<((j+1) * 10)) {
              if (navigations[k]) {
                navItems.push(navigations[k]);
              }
              k++;
            }
          }
          j++;
        }
      } else if (navigations && navigations[i]) {
        navItems.push(navigations[i]);
      } else if (i==0) {
        navItems.push({
          level: 0,
        });
      }
      i++;
    }

    return (<>
      <div style={{display: 'flex', padding: '1em 1em'}}>
        <div style={{flex: '1'}}>
          <Header as='h4' style={{marginTop: '.25em'}}>
            <Icon name='sliders horizontal' style={{fontSize: '1em'}}/>
            <Header.Content>
              IVR Menu Content
            </Header.Content>
          </Header>
        </div>
        <div>
          {/* <Button size='mini' color='red' onClick={this.props.setMode.bind(this, '2',this.props.params.interactiveId, this.props.clientId)} icon style={{padding: '.5em'}}><Icon name='pencil alternate' /></Button> */}
          {/* <Button size='mini' color='blue' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='arrow left' /></Button> */}
        </div>
      </div>

      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {/* <Segment>
            <Form.Group widths='16'>
              <Field name='isEnabled' component={CheckboxField}
              width={8}
              toggle
              // caption='Enabled / Disabled'
              label='Enable Now !'
              placeholder='Enable/disabled' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
                            width={4}
              label='Name'
              placeholder='Name' />
              <Field name='descript' component={InputField}
              width={12}
              label='Descript'
              placeholder='Descript' />
            </Form.Group>
          </Segment> */}

          <Header as='h5'>
            <Header.Content>
              Default <b>Greeting</b> Voice
              <Header.Subheader>Default  and specific event's greeting in IVR menu.</Header.Subheader>
            </Header.Content>
          </Header>
          {this.props.params.currLevel=='scriptBackPrevious' && <Segment>
            <Form.Group widths='equal'>
              <Field name={'scriptBackPrevious'} component={LabelInputField}
              label='Script'
              caption='Back to Previous Menu (Key press *)'
              placeholder='Script talent will be converted to audio by text to speech system' />
            </Form.Group>
          </Segment>}
          {this.props.params.currLevel=='scriptRepeatCurrent' && <Segment>
            <Form.Group widths='equal'>
              <Field name={'scriptRepeatCurrent'} component={LabelInputField}
              label='Script'
              caption='Repeat for Current Menu (Key press 0)'
              placeholder='Script talent will be converted to audio by text to speech system' />
            </Form.Group>
          </Segment>}
          
          {this.props.params.currLevel=='scriptBusy' && <Segment>
            <Form.Group widths='equal'>
              <Field name={'scriptBusy'} component={LabelInputField}
              label='Script'
              caption='Agent is Busy / Not Available'
              placeholder='Script talent will be converted to audio by text to speech system' />
            </Form.Group>
          </Segment>}
          {this.props.params.currLevel=='scriptOffline' && <Segment>
            <Form.Group widths='equal'>
              <Field name={'scriptOffline'} component={LabelInputField}
              label='Script'
              caption='Agent is Offline'
              placeholder='Script talent will be converted to audio by text to speech system' />
            </Form.Group>
          </Segment>}
          
          {this.props.params.currLevel=='scriptThankyou' && <Segment>
            <Form.Group widths='equal'>
              <Field name={'scriptThankyou'} component={LabelInputField}
              label='Script'
              caption='Thank You / Good Bye'
              placeholder='Script talent will be converted to audio by text to speech system' />
            </Form.Group>
          </Segment>}
          {this.props.params.currLevel=='scriptProblem' && <Segment>
            <Form.Group widths='equal'>
              <Field name={'scriptProblem'} component={LabelInputField}
              label='Script'
              caption='System has Problem'
              placeholder='Script talent will be converted to audio by text to speech system' />
            </Form.Group>
          </Segment>}

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Interactive' : 'Insert Interactive') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    </>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.interactiveStore.getInteractive!=undefined && state.interactiveStore.getInteractive.progress==1 ? state.interactiveStore.getInteractive.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      navigations: selector(state, 'navigations'),
    }),
    { load: getInteractive }
  )
  (reduxForm({
    form: 'InteractiveGreetingForm',	// a unique identifier for this form
  })(InteractiveGreetingForm)
  )
)
