import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"


export function listClient(objParam) {
  return {
      type: 'CLIENT_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/client',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listUser(objParam) {
  return {
      type: 'USER_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/user',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listSupport(objParam) {
  return {
      type: 'SUPPORT_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/support',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listAgent(objParam) {
  return {
      type: 'AGENT_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/user',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listTeamleader(objParam) {
  return {
      type: 'TEAMLEADER_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/user',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listSupervisor(objParam) {
  return {
      type: 'SUPERVISOR_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/user',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listController(objParam) {
  return {
      type: 'CONTROLLER_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/user',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listManager(objParam) {
  return {
      type: 'MANAGER_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/user',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listTemplate(objParam) {
  return {
      type: 'TEMPLATE_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/template',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listProduct(objParam) {
  return {
      type: 'PRODUCT_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/product',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listWorkgroup(objParam) {
  return {
      type: 'WORKGROUP_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/workgroup',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listSegment(objParam) {
  return {
      type: 'SEGMENT_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/segment',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listTicket(objParam) {
  return {
      type: 'TICKET_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/ticket',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listRemark(objParam) {
  return {
      type: 'REMARK_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/remark',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listParams(objParam) {
  return {
      type: 'PARAMS_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/params',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listCustomer(objParam) {
  return {
      type: 'CUSTOMER_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/customer',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listOrganization(objParam) {
  return {
      type: 'ORGANIZATION_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/organization',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listClassification(objParam) {
  return {
      type: 'CLASSIFICATION_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/classification',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listResolution(objParam) {
  return {
      type: 'RESOLUTION_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/resolution',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listReasoning(objParam) {
  return {
      type: 'REASONING_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/reasoning',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listActivity(objParam) {
  return {
      type: 'ACTIVITY_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/activity',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listForm(objParam) {
  return {
      type: 'FORM_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/form',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listField(objParam) {
  return {
      type: 'FIELD_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/field',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listMacro(objParam) {
  return {
      type: 'MACRO_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/macro',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listChannel(objParam) {
  return {
      type: 'CHANNEL_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/channel',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listOfficeHour(objParam) {
  return {
      type: 'OFFICEHOUR_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/officehour',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listBucket(objParam) {
  return {
      type: 'BUCKET_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/bucket',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listDashboard(objParam) {
  return {
      type: 'DASHBOARD_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/dashboard',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listReport(objParam) {
  return {
      type: 'REPORT_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/report',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}




export function listHandlings(objParam) {
  return {
      type: 'HANDLINGS_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/handlings',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function listSegments(objParam) {
  return {
      type: 'SEGMENTS_LIST',
      payload:
        axios.post(
          urlAPI + 'service/master/segments',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}