import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label, Dropdown } from 'semantic-ui-react'

const lockOptions = [
  {
    key: '1',
    text: 'Briefing',
    value: 'Briefing',
  },
  {
    key: '2',
    text: 'Praying',
    value: 'Praying',
  },
  {
    key: '3',
    text: 'Toilet',
    value: 'Toilet',
  },
  {
    key: '4',
    text: 'Follow Up',
    value: 'FU',
  },
  {
    key: '5',
    text: 'After Call Work',
    value: 'ACW',
  },
  {
    key: '6',
    text: 'Auxiliary Time',
    value: 'AUX',
  },
]

class LockConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state={
    }
  }
    
  render() {
    if (!this.props.locked) {
      return (
        <div style={{padding: '2em'}}>
          <Header as='h1' color='red'>
            <Header.Content>
              <Icon name='unlock alternate' style={{marginLeft: '-.2em'}}/> Lock ?
            </Header.Content>
          </Header>
          <Header as='h5'>
            <Header.Content>
              Dear {this.props.user || ''}, 
              <br />
              You have clicked lock mode, to continue please click confirm button.
              <Header.Subheader></Header.Subheader>
            </Header.Content>
          </Header>

          <Header as='h4' color='blue'>
            <Header.Content>
              Reason Why on Lock Mode ?
              <Header.Subheader></Header.Subheader>
            </Header.Content>
          </Header>
          <Dropdown style={{fontWeight: 'bold'}} onChange={this.props.onChangeSelect}
            placeholder='Pilih alasan lock'
            fluid
            search
            selection
            options={lockOptions}
          />
          <div style={{textAlign: 'right', marginTop: '6em'}}>
          <Button disabled={!this.props.state.lockReason} onClick={this.props.onClick} size='massive' color='red'>Lock Now !</Button>
          </div>
        </div>
      )
    } else {
      return (
        <div style={{padding: '2em'}}>
          <Header as='h1' color='red'>
            <Header.Content>
              <Icon name='lock' style={{marginLeft: '-.2em'}}/> Locked !
            </Header.Content>
          </Header>
          <Header as='h5'>
            <Header.Content>
              Dear {this.props.user || ''}, 
              <br />
              You have clicked lock mode, to leave lock mode and return to active session, please click <b>close (X)</b>  button.
              <Header.Subheader></Header.Subheader>
            </Header.Content>
          </Header>

          <Divider style={{marginTop: '2.5em'}}/>
          <Header as='h2' color='blue'>
            <Header.Content>
              <Header.Subheader style={{color: '#333'}}><b>Reason on Lock Mode</b></Header.Subheader>
              <b>{this.props.reason.toUpperCase()}</b>
            </Header.Content>
          </Header>
          <div style={{textAlign: 'right', marginTop: '6em'}}>
          <Button onClick={this.props.closeModal} size='massive' color='teal'>Unlock !</Button>
          </div>
        </div>
      )
    }
  }
}

export default LockConfirmation;