import React,{Component} from 'react';
import { Header, Card, Icon, Image, Container, List, Button, Label, Dimmer, Segment, Rating, Form, Divider} from 'semantic-ui-react'

import { audioPath, urlUpload } from './_lib'

//FILEPOND
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import FilePondPluginImageResize from 'filepond-plugin-image-resize';
// import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
// import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// registerPlugin(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginImageResize, FilePondPluginImageCrop, FilePondPluginImageTransform);
registerPlugin(FilePondPluginFileValidateType);//, FilePondPluginFileRename);

export default class AudioUploadFilepond extends Component {
  constructor(props) {
    super(props);
    this.state= {
      audioPath: audioPath
    }
  }

  render() {
    const {height, width, resizeHeight, resizeWidth, aspectRatio } = this.props
    const { audioPath, file }= this.state

    return(
      <div style={{width: width + 'px', textAlign: 'center', display: 'flex', marginTop: '.5em'}}>
        <div style={{flex: '1', background: 'rgba(255,255,255,.6)'}}>
        </div>
        <div style={{flex: '1'}}>
          <FilePond
            acceptedFileTypes= {['audio/*']}
            ref={ref => this.pond = ref}
            files={this.state.files}
            allowMultiple={false}
            maxFiles={3} 
            server={{ 
              url: urlUpload,
              process: {
                headers: {
                    'Authorization': localStorage.getItem('tokenAuth')
                }
              }
            }}
            //oninit={() => this.handleInit() }
            onupdatefiles={data => {
              // Set currently active file objects to this.state
              // console.log('ON UPDATE FILES: ', data)
              this.setState({
                files: data.map(item => item.file)
              });
            }}
            onprocessfile={(e,data)=>{
              // console.log('ON PROCESS FILES: ', e, data.file)
              this.props.onChange(audioPath + data.file.name)
              this.setState({
                file: audioPath + data.file.name
              });
            }}
            onremovefile={(e, data)=>{
              // console.log('ON REMOVE FILES: ', e, data)
              this.props.onChange('')
              this.setState({
                files: undefined,
                file: undefined
              });
            }}
          />
        </div>
        <div style={{flex: '1'}}>
          {this.props.value &&
          <audio controls  style={{marginTop: '.1em', width: width + 'px', height: '37px'}}>
            <source src={this.props.value} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>}
        </div>
      </div>
    )
  }
  
}