import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  ModalDescription,
  Modal,
  Progress,
} from 'semantic-ui-react'

class TimerProgressBar extends Component {
  constructor(props) {
    super(props)
    this.state= {
      second: 0,
      data: null, 
    }

    this.timer= null;
  }

  componentDidMount() {
    var parent= this
    
    this.setState({
      data: this.props.data,
    })

    this.timer= setInterval(()=>{
      parent.tickProgress()
    }, 1000)
  }

  tickProgress() {
    const { second }= this.state
    this.setState({
      second: second + 1
    })
  }

  componentDidUpdate() {
    const { data, second }= this.state
    
    if (data!=this.props.data) {
      this.setState({
        second: 0,
        data: this.props.data,
      })
    } 
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const { second }= this.state
    const { maxSecond }= this.props

    return (
      <div style={{display: 'flex', background: '#dedede'}}>
        <div style={{margin: '.1em .3em 0 2em'}}>
          <Label className={(second > (maxSecond || 60)) ? 'blinkmiddle' : ''} color={second>=(maxSecond || 60) ? 'red' : 'teal'} style={{borderRadius: '.15em', padding: '.25em .4em', fontSize: '1.3em'}}>{Math.floor(second / 60)<10 ? '0' +  Math.floor(second / 60).toString() : Math.round(second / 60)}</Label>
          <Label className={(second > (maxSecond || 60)) ? 'blinkfast' : ''} color={second>=(maxSecond || 60) ? 'red' : 'teal'} style={{borderRadius: '.15em', padding: '.25em .4em', fontSize: '1.3em'}}>{(second % 60)<10 ? '0' + (second % 60).toString() : (second % 60)}</Label>
        </div>
        <div style={{flex: '1', margin: '.1em 2em 0 0'}}>
          <Segment style={{padding: '0', border: 'none', background: '#eae8e8', borderRadius: '.3em'}}>
            <Progress attached='top' size='medium' percent={Math.round(second / (maxSecond || 60) * 100)} color={(second/(maxSecond || 60)>=1 && 'red') || (second/(maxSecond || 60)>=0.75 && 'orange') || (second/(maxSecond || 60)>=0.5 && 'yellow') || (second/(maxSecond || 60)>=0.2 && 'teal') || 'olive'}/>
            <div style={{display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', 
            width: (Math.round(second / (maxSecond || 60) * 100)<100 ? Math.round(second / (maxSecond || 60) * 100) : 100) + '%', padding: '2px 0 1px 0', margin: '0',
            background: (second/(maxSecond || 60)>=1 && '#db2828') || (second/(maxSecond || 60)>=0.75 && '#f2731b') || (second/(maxSecond || 60)>=0.5 && '#fbbd08') || (second/(maxSecond || 60)>=0.2 && '#00b5ad') || '#b5cc18'
            }}>
              <Label color='black' style={{borderRadius: '.2em', margin: '0 .5em', padding: '.4em .5em'}}>{Math.round(second / (maxSecond || 60) * 100)}%</Label>
              <Label style={{borderRadius: '.2em', margin: '0', padding: '.3em .5em', background: 'none', color: '#fff'}}>{(second/(maxSecond || 60)>=1 && 'Anda melebih batas waktu !') || 'Waktu Anda masih tersedia'}</Label>
            </div>
            <Progress attached='bottom' size='medium' percent={Math.round(second / (maxSecond || 60) * 100)} color={(second/(maxSecond || 60)>=1 && 'red') || (second/(maxSecond || 60)>=0.75 && 'orange') || (second/(maxSecond || 60)>=0.5 && 'yellow') || (second/(maxSecond || 60)>=0.2 && 'teal') || 'olive'}/>
          </Segment>
        </div>
      </div>
      // <div style={{width: '150px', height: '150px', margin: '.5em auto'}}>
      //   <CircularProgressbar value={Math.round(second/60 * 100)} background={true}
      //   text={(second % 3600<600 ? ((Math.floor(second % 3600 / 60)==0 ? '' : Math.floor(second % 3600 / 60)+':')) : Math.floor(second % 3600 / 60)+':') + (second % 3600 % 60<10 ? '0'+Math.floor(second % 3600 % 60) : Math.floor(second % 3600 % 60)) || '00:00'}
      //   styles={{
      //     // Customize the root svg element
      //     root: {},
      //     // Customize the path, i.e. the "completed progress"
      //     text: {
      //       fill: 'rgb(230, 0, 0)',
      //       fontSize: '2.5em',
      //       fontWeight: '700',
      //     },
      //     path: {
      //       // Path color
      //       stroke: `rgb(251, 136, 0)`,
      //     },
      //     // Customize background - only used when the `background` prop is true
      //     background: {
      //       fill: 'rgb(236, 236, 236)',
      //     },
      //   }}
      //   />
      // </div>
    );
  }
}

export default TimerProgressBar;