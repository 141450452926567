export default function voipReducer (state = {}, action) {
  switch (action.type) {
    case 'VOIP_RESET': {
        return({
            ...state,
            getVoip: {},
            updateVoip: {},
            createVoip: {},
        })
    }

    case 'VOIP_GET_PENDING': {
        return({...state, getVoip: {progress: 0}})
    }
    case 'VOIP_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getVoip: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getVoip: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VOIP_GET_REJECTED': {
        return({...state, getVoip: {progress: 2, payload: action.payload}})
    }

    case 'VOIP_STATUS_PENDING': {
      return({...state, statusVoip: {progress: 0}})
    }
    case 'VOIP_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusVoip: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusVoip: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VOIP_STATUS_REJECTED': {
        return({...state, statusVoip: {progress: 2, payload: action.payload}})
    }

    case 'VOIP_BROWSE_PENDING': {
      return({...state, browseVoip: {progress: 0}})
    }
    case 'VOIP_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseVoip: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseVoip: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VOIP_BROWSE_REJECTED': {
        return({...state, browseVoip: {progress: 2, payload: action.payload}})
    }

    case 'VOIP_CREATE_PENDING': {
      return({...state, browseVoip: {progress: 0}})
    }
    case 'VOIP_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createVoip: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createVoip: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VOIP_CREATE_REJECTED': {
        return({...state, createVoip: {progress: 2, payload: action.payload}})
    }

    case 'VOIP_UPDATE_PENDING': {
      return({...state, updateVoip: {progress: 0}})
    }
    case 'VOIP_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateVoip: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateVoip: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VOIP_UPDATE_REJECTED': {
        return({...state, updateVoip: {progress: 2, payload: action.payload}})
    }

    case 'VOIP_REMOVE_PENDING': {
      return({...state, removeVoip: {progress: 0}})
    }
    case 'VOIP_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeVoip: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeVoip: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VOIP_REMOVE_REJECTED': {
        return({...state, removeVoip: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}