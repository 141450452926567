import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetLink(objParam) {
  return {
      type: 'LINK_RESET',
      payload: objParam
  }
}

export function getLink(objParam) {
  return {
      type: 'LINK_GET',
      payload:
        axios.post(
          urlAPI + 'link/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusLink(objParam) {
  return {
      type: 'LINK_STATUS',
      payload:
        axios.post(
          urlAPI + 'link/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseLink(objParam) {
  return {
      type: 'LINK_BROWSE',
      payload:
        axios.post(
          urlAPI + 'link/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createLink(objParam) {
  return {
      type: 'LINK_CREATE',
      payload:
        axios.post(
          urlAPI + 'link/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateLink(objParam) {
  return {
      type: 'LINK_UPDATE',
      payload:
        axios.post(
          urlAPI + 'link/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeLink(objParam) {
  return {
      type: 'LINK_REMOVE',
      payload:
        axios.post(
          urlAPI + 'link/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
