import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import MenuHorizontal from '../../../libraries/common/MenuHorizontal';

import AgentBody from './AgentBody'
import WorkgroupBody from './WorkgroupBody'
import ScriptBody from './ScriptBody'
import ContactBody from './ContactBody'
import CustomerBody from './CustomerBody'
import ProductBody from './ProductBody'
import VisitorBody from './VisitorBody'

const options= [
  // {
  //   key: '0',
  //   icon: 'id badge outline',
  //   caption: 'Visitor',
  // },
  {
    key: '1',
    icon: 'address card',
    caption: 'Customer',
    hint: {
      header: 'Customer',
      content: 'List all of your customers, for Telesales transaction if progress is status is ordered, contact will be your customer.',
    }
  },
  {
    key: '2',
    icon: 'folder outline',
    caption: 'Product',
    hint: {
      header: 'Product / Service',
      content: 'Reference product for Verification, Survey, Telesales information.',
    }
  },
  {
    key: '3',
    icon: 'object ungroup outline',
    caption: 'Workgroup',
    hint: {
      header: 'Workgroup',
      content: 'Group of agents as team up for specific purpose campaign.',
    }
  },
  {
    key: '4',
    icon: 'file code outline',
    caption: 'Script',
    hint: {
      header: 'Dynamic Content',
      content: 'Script for dynamic content automation conversion as reference for agent or sent to contact as messaging content.',
    }
  },
  // {
  //   key: '5',
  //   icon: 'address card outline',
  //   caption: 'Contacts',
  // },
  // {
  //   key: '6',
  //   icon: 'user circle outline',
  //   caption: 'Agent',
  // },
]
class ResourceBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        // dashboardId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props

      return(
        <Container fluid>
          <div style={{margin: '0em 1em 0 1em'}}>
            <MenuHorizontal onClick={this.setMode.bind(this)} options= {options} color='red'/>
          </div>
          <div>
            {mode==0 && <VisitorBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
            {mode==1 && <CustomerBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
            {mode==2 && <ProductBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
            {mode==3 && <WorkgroupBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
            {mode==4 && <ScriptBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
            {mode==5 && <ContactBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
            {mode==6 && <AgentBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
          </div>
        </Container>
      )
    }
}

export default ResourceBody
