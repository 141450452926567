import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'



class OmniphoneOfflineFloating extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { responsive, layout}= this.props
    return (
      <>
        <Segment attached={true} style={{padding: '1em .5em', minHeight: layout=='mini' ? '6em' : '20em', background: '#444', border: 'none'}}>
          <div style={{textAlign: !responsive && layout!='mini' ? 'center' : 'left'}}>
            <Header as='h3' icon={!responsive && layout!='mini'} inverted style={{margin: '1em .5em 0 .5em', display: 'inline'}} color={this.props.statusUA==4 ? 'red' : 'grey'}>
              <Icon name='unlink' style={{fontSize: !responsive && layout!='mini' ? '5em' : '1.5em'}}/>
              <Header.Content>
                {this.props.statusUA==0 && 'Offline'}
                {this.props.statusUA==1 && 'Connecting'}
                {this.props.statusUA==2 && 'Connected'}
                {this.props.statusUA==3 && 'Registered'}
                {this.props.statusUA==4 && 'No Access'}
                <Header.Subheader style={{fontSize: '.6em'}}>
                  {this.props.statusUA==4 ? 'Your account is not valid' : 'Waiting for connection'}
                </Header.Subheader>
              </Header.Content>
            </Header>
          </div>
        </Segment>
        <Segment attached={true} style={{padding: '1em .5em 2em .5em', background: '#444', border: 'none', textAlign: 'center'}}>
          <Divider />
          <Header as='h4' icon inverted style={{margin: '1em 0'}}>
            <Header.Content>
              Call will be available after your account connected.
            </Header.Content>
          </Header>
        </Segment>
      </>
    )
  }
}

export default OmniphoneOfflineFloating