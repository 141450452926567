export default function occupancyReducer (occupancye = {}, action) {
  switch (action.type) {
    case 'OCCUPANCY_RESET': {
        return({
            ...occupancye,
            occupancyMau: {},
            updateOccupancy: {},
            occupancyAsset: {},
        })
    }
    
    case 'TUNNEL_OCCUPANCY_PENDING': {
      return({...occupancye, occupancyTunnel: {progress: 0}})
    }
    case 'TUNNEL_OCCUPANCY_FULFILLED': {
      if (action.payload==undefined) {
          //return({...occupancye, occupancyTunnel: {...api.occupancyTunnel.response, progress: 1, payload: action.payload}})
          return({...occupancye, occupancyTunnel: {progress: 2, payload: action.payload}})
      } else {
          return({...occupancye, occupancyTunnel: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'TUNNEL_OCCUPANCY_REJECTED': {
      return({...occupancye, occupancyTunnel: {progress: 2, payload: action.payload}})
    }
    
    case 'MAU_OCCUPANCY_PENDING': {
        return({...occupancye, occupancyMau: {progress: 0}})
    }
    case 'MAU_OCCUPANCY_FULFILLED': {
        if (action.payload==undefined) {
            //return({...occupancye, occupancyMau: {...api.occupancyMau.response, progress: 1, payload: action.payload}})
            return({...occupancye, occupancyMau: {progress: 2, payload: action.payload}})
        } else {
            return({...occupancye, occupancyMau: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MAU_OCCUPANCY_REJECTED': {
        return({...occupancye, occupancyMau: {progress: 2, payload: action.payload}})
    }

    default: {
      return occupancye
    }
  }
}