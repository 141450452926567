import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form, Label } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"

import Draggable, {DraggableCore} from 'react-draggable';

import JsSIP from 'jssip'

import dialtone from '../../sound/tone.mp3'
import busytone from '../../sound/busy.mp3'
import ringtone from '../../sound/ring.mp3'
import jointone from '../../sound/join.mp3'

import PhonewidgetDial from './PhonewidgetDial';
import PhonewidgetCall from './PhonewidgetCall';
import PhonewidgetSetting from './PhonewidgetSetting';

import { connect } from "react-redux"
import { resetPhonewidget } from "../../actions/ring/phonewidgetAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    phonewidgetStore: state.phonewidgetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPhonewidget:(objParam) => dispatch(resetPhonewidget(objParam)),
  }
}

var ua;
var session;
var sessions= new Map(), localVideoStream, remoteVideoStream;

const dial= new Audio(dialtone);
const busy= new Audio(busytone);
const ring= new Audio(ringtone);


class DialTone {
  play() {
    dial.loop= true
    dial.muted= false;
    dial.play();  
  }
  
  stop() {
    dial.pause();
  }

  mute() {
    dial.muted= true;
  }
}
class BusyTone {
  play() {
    busy.muted= false;
    busy.play();
  }
  
  stop() {
    busy.pause();
  }

  mute() {
    busy.muted= true;
  }
}
class RingTone {
  play() {
    ring.loop= true;
    ring.muted= false;
    ring.play();  
  }
  
  stop() {
    ring.muted= true;
    ring.pause();
  }
}

class Phonewidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sipAccount: null,
      sipPasswd: null,
      // sipHost: null,
      
      // sipAccount: '7012345639',
      // sipPasswd: 'NDM3NDQxMTc0MTcwNjMzNTAwMDA=',
      // sipHost: 'call.halopagi.com:4063',
      sipRealm: 'halopagi',

      statusUA: 0, //0: disconnected, 1: connecting, 2: connected, 3: registered, 4: no access / account not valid / FAILED
      phoneState: 'offline', //offline, connecting, online, incoming, dialing, oncall
      text: null,

      mode: 'home',
      channel: 'Audio',
      chats: [
        // {
        //   stamp: '2019-01-01 20:00',
        //   sender: 'cust',
        //   message: 'content message',
        // },
        // {
        //   stamp: '2019-01-01 20:00',
        //   sender: 'agent',
        //   message: 'content message',
        // },
      ],
      variable: null, //'CONTACT',
      command: null,
    };

    this.dial= new DialTone();
    this.busy= new BusyTone();
    this.ring= new RingTone();
  }

  sipRegister(account, passwd, host, realm) {
    var sipAccount, sipPasswd, sipHost, sipRealm;

    if (account!=null && passwd!=null && host!=null && realm!=null) {
      sipAccount= account
      sipPasswd= passwd
      sipHost= host
      sipRealm= realm
    } else if (this.state.sipAccount!=null && this.state.sipPasswd!=null && this.state.sipHost!=null && this.state.sipRealm!=null) {
      sipAccount= this.state.sipAccount
      sipPasswd= this.state.sipPasswd
      sipHost= this.state.sipHost
      sipRealm= this.state.sipRealm
    } else {
      return;
    }

    this.setState({
      uiMode: [],
      statusUA: 1,
      chats: [],

      variable: null,
      command: null,
    })

    var socket = new JsSIP.WebSocketInterface("wss://"+ sipHost +"/");
    socket.via_transport = "udp";

    var configuration = {
      sockets  : [ socket ],
      uri      : "sip:"+ sipAccount +"@"+sipHost,
      password : sipPasswd,
      session_timers: false,
      iceServers: [],
      use_preloaded_route: true,
    };
    
    if (ua!=undefined) {
      ua.stop();
    } 

    ua = new JsSIP.UA(configuration);
    // console.log('!!! UA', ua)
    
    ua.start();
    this.sipEventHandler(ua)
  }
  
  sipUnregister() {
    // console.log('!!! UA', ua.isRegistered(), ua.isConnected(), ua)
    if (ua!=undefined) {
      // console.log('!!! UA', 'STOP')
      ua.stop();
      ua= undefined;
    }
  }

  sipEventHandler(ua) {
    const parent= this;

    //CONNECTION EVENT
    ua.on("connecting", function(data){
      console.log("!!! UA ON CONNECTING", data);
      parent.setState({
        statusUA: 1,
        phoneState: 'connecting',
      })
    });
    ua.on("connected", function(data){
      console.log("!!! UA ON CONNECTED", data);
      parent.setState({
        statusUA: 2,
        phoneState: 'connected',
      })
    });
    ua.on("disconnected", function(data){
      console.log("!!! UA ON DISCONNECTED", data);
      parent.setState({
        statusUA: 0,
        phoneState: 'offline',
      })
    });
    
    //CALL EVENT
    ua.on("newRTCSession", function(data){
      // console.log("!!! RTC SESSION EVENT ", data.session);
      // incoming call here
      session= data.session;
      // sessions.set(session.id, session)

      if (session.direction === "incoming") {
        console.log("!!! RTC SESSION INCOMING ");
        
        session.on("progress",function(e){
          // the call has answered
          console.log("!!! UA ON INCOMING - RTC PROGRESS");
          parent.setState({
            phoneState: 'incoming',
          })
          parent.ring.play();
          setTimeout(()=>{
            parent.callAnswer();
          }, 1000)
        });
        session.on("accepted",function(e){
            // the call has answered
            console.log("!!! UA ON INCOMING - RTC ACCEPTED");
            parent.setState({
              phoneState: 'oncall',
            })
        });
        session.on("confirmed",function(){
            // this handler will be called for incoming calls too
            console.log("!!! UA ON INCOMING - RTC CONFIRMED");
            parent.setState({
              phoneState: 'oncall',
            })
            parent.ring.stop();
        });
        session.on("peerconnection", function(e) {
          console.log('!!! UA ON INCOMING - RTC PEERCONNECTION: ', e)

          parent.addStream();
        });
        session.on("icecandidate", function(e) {
          if (e.Last) {
            console.log('!!! UA ON INCOMING - RTC ICE CANDIDATE: ', e)
            e.ready();
          }
        });
        session.on("sdp", function(e) {
          console.log('!!! UA ON INCOMING - RTC SDP: ', e)
        });
        session.on("newInfo", function(e) {
          console.log('!!! UA ON INCOMING - RTC INFO: ', e)
        });
        session.on("newDTMF", function(e) {
          console.log('!!! UA ON INCOMING - RTC DTMF: ', e)
        });
        session.on("failed",function(e){
            // unable to establish the call
            console.log("!!! UA ON INCOMING - RTC FAILED", e);
            if (e.originator=='remote' && e.cause=="Canceled") {
              parent.setState({
                statusUA: 2,
                phoneState: 'online',
              })
            } else if (e.originator=='local' && e.cause=="Rejected") {
              parent.setState({
                statusUA: 2,
                phoneState: 'online',
              })
            }
            // parent.setState({
            //   // phoneState: 'online',
            // })
            session= undefined;
            parent.ring.stop();

            remoteVideoStream= null;
            localVideoStream= null;
        });
        session.on("ended",function(){
            // the call has ended
            console.log("!!! UA ON INCOMING - RTC ENDED");
            parent.setState({
              phoneState: 'online',
            })
            session= undefined
            parent.ring.stop();
            parent.busy.play();

            remoteVideoStream= null;
            localVideoStream= null;
        });
      } else if (session.direction === "outgoing") {
        console.log("!!! RTC SESSION OUTGOING ");
        console.log("!!! UA ON OUTGOING - ADD STREAM");
        
        parent.addStream();
      }
    });

    //SIP EVENT
    ua.on("sipEvent", function(data){
      console.log("!!! UA ON SIP EVENT", data);
    });

    //REGISTERED EVENT
    ua.on("registered", function(){
      console.log("!!! UA ON REGISTERED");
      parent.setState({
        statusUA: 3,
        phoneState: 'online',
      })
    });
    ua.on("unregistered", function(data){
      console.log("!!! UA ON UNREGISTERED", data);
      parent.setState({
        statusUA: 0,
        phoneState: 'offline',
      })
    });
    ua.on("registrationFailed", function(data){
      console.log("!!! UA ON REGISTRATION FAILED", data);
      parent.setState({
        statusUA: 4,
        phoneState: 'offline',
      })
    });
  }

  dtmfSend(tone) {
    // var tones = '1234#';
    // console.log('!!! DTMF', tone);

    if (session && tone && tone!='') {
      session.sendDTMF(tone, {
        'duration': 160,
        'interToneGap': 1200,
        'extraHeaders': [ 'X-Foo: foo', 'X-Bar: bar' ]
      })
    }
  }

  addStream() {
    // session.connection.onaddstream = function(e) {
    session.connection.addEventListener('addstream',function(e) {
      // console.log("!!! CALL ADD STREAM");
      const remoteAudio = new window.Audio()
      remoteAudio.srcObject = e.stream
      remoteAudio.play();

      // const localView = document.getElementById('localView');
      // const remoteView = document.getElementById('remoteView');
      
      // if (remoteView) {
      //   remoteView.srcObject = (e.stream);
      // }
      // if (localView) {
      //   localView.srcObject = (session.connection.getLocalStreams()[0]);
      // }

      remoteVideoStream= (e.stream);
      localVideoStream= (session.connection.getLocalStreams()[0]);
    })
  }

  callAnswer() {
    const callOptions = {
      extraHeaders: [],
      // mediaConstraints: { audio: true, video: false },
      mediaConstraints : { audio: true, video: this.state.channel=='Video' },
      // mediaConstraints : { audio: true, video: true, screen: true },
    }
    
    try {
      if (session && !session.isEnded())  {
        session.answer(callOptions)
        // session.answer()
        this.setState({
          phoneState: 'oncall',
        })
        console.log('!!! INCOMING - SESSION ANSWER')
      } else {
        this.setState({
          phoneState: 'online',
        })
      }
    }
    catch(err) {
      console.log('ERROR ANSWER CALL', err)
    }
  }
  
  callDial(terminating) {
    const parent= this;
    this.setState({
      terminating: terminating
    })

    //CALL TERMINATING NUMBER
    this.dial.play()

    const callEventHandlers = {
      'connecting': function(e) {
        console.log('!!! UA ON OUTGOING - CONNECTING: ', e);
        parent.setState({
          phoneState: 'connecting',
        })
      },
      'progress': function(e) {
        console.log('!!! UA ON OUTGOING - PROGRESS: ', e);
        parent.state.phoneState!='oncall' && parent.setState({
          phoneState: 'dialing',
        })
      },
      'confirmed': function(e) {
        console.log('!!! UA ON OUTGOING - CONFIRMED: ', e);
        parent.setState({
          phoneState: 'oncall',
        })

        parent.dial.stop()
      },
      'icecandidate': function(e) {
        console.log('!!! UA ON OUTGOING - ICE CANDIDATE: ', e)
        // n(e.candidate.candidate);
        // if (e.Last) {
        //   e.ready();
        // }
      },
      'sdp': function(e) {
        console.log('!!! UA ON OUTGOING - SDP: ', e)
        if (e.originator=='remote' && e.type=="answer") {
          parent.setState({
            phoneState: 'oncall',
          })
          parent.dial.stop()
        }
        if (e.Last) {
          e.ready();
        }
      },
      'newInfo': function(e) {
        console.log('!!! UA ON OUTGOING - NEW INFO: ', e)
        // n(e.candidate.candidate);
        // if (e.Last) {
        //   e.ready();
        // }
      },
      'newDTMF': function(e) {
        console.log('!!! UA ON OUTGOING - NEW DTMF: ', e)
        // n(e.candidate.candidate);
        // if (e.Last) {
        //   e.ready();
        // }
      },
      'ended': function(e) {
        console.log('!!! UA ON OUTGOING - ENDED: ', e);
        parent.setState({
          phoneState: 'online',
        })
        session= undefined;
        parent.ring.stop();
        parent.busy.play();

        remoteVideoStream= null;
        localVideoStream= null;
      },
      'failed': function(e) {
        console.log('!!! UA ON OUTGOING - FAILED: ', e);
        if (e.originator=='remote' && (e.cause && e.cause.toLowerCase()=="not found")) {
          parent.setState({
            statusUA: 2,
            phoneState: 'online',
            terminating: null,
          })
        } else if (e.originator=='remote' && (e.cause && e.cause.toLowerCase()=="canceled")) {
          parent.setState({
            statusUA: 2,
            phoneState: 'online',
            terminating: null,
          })
        } else if (e.originator=='local' && (e.cause && e.cause.toLowerCase()=="rejected")) {
          parent.setState({
            statusUA: 2,
            phoneState: 'online',
            terminating: null,
          })
        } else {
          parent.setState({
            statusUA: 2,
            phoneState: 'online',
            terminating: null,
          })
        
          session= undefined
          remoteVideoStream= null;
          localVideoStream= null;
        }
            
        parent.dial.mute();
        parent.busy.play();
      },
    };

    const callOptions = {
      eventHandlers: callEventHandlers,
      // mediaConstraints: { audio: true, video: false },
      mediaConstraints : { audio: true, video: parent.state.channel=='Video' },
      // mediaConstraints : { audio: true, video: true, screen: true },
    }
    
    // session = ua.call('sip:'+ terminating +'@174.138.19.28:5060', callOptions);
    // session = ua.call('sip:'+ terminating +'@202.52.48.75:5160', callOptions);
    session = ua.call('sip:'+ terminating , callOptions);
  }

  callHangup() {
    this.setState({
      terminating: null
    })

    if (session) {
      session.terminate();
    } else {
      this.setState({
        phoneState: 'online',
      })
    }
    // sessions.forEach((session, key)=>{
    //   session.terminate();
    // })
  }

  setMode(mode, params) {
    console.log('!!! SET MODE', mode,'PARAMS', params)
    
    if (mode=='home') {
      this.setState({
        mode: mode,
      })
    } else if (mode=='setting') {
      if (this.state.mode!='setting') {
        this.setState({
          mode: 'setting',
        })
      } else {
        this.setState({
          mode: 'home',
        })
      }
    } else {
      this.setState({
        mode: mode || 'home',
      })
    }
  }

  componentDidMount() {
    this.props.resetPhonewidget();
  }
  
  componentDidUpdate(prevProps, prevStat) {
  }
  
  componentWillUnmount() {
    // console.log('!!! WILLUNMOUNT')
    this.sipUnregister();
  }

  onChangeText(k, e, v) {
    console.log(k, v.value);
    switch (k) {
      case 'uid':
        this.setState({
          sipAccount: v.value,
        })
        break;
      case 'passwd':
        this.setState({
          sipPasswd: v.value,
        })
        break;
      case 'host':
        this.setState({
          sipHost: v.value,
        })
        break;
      case 'realm':
        this.setState({
          sipRealm: v.value,
        })
        break;
      default:
    }
    // const parent= this
    // this.setState({
    //   lastChange: new Date().getTime(),
    //   dtmf: v.value
    // })
    
    // setTimeout(()=>{
    //   if (new Date().getTime()-parent.state.lastChange>=500) {
    //     this.props.dtmfSend(this.state.dtmf);
    //     parent.setState({
    //       dtmf: null,
    //     })
    //   }
    // }, 500)
  }
    
  render() {
    const { mode, size , chats, phoneState, variable, command}= this.state
    const { screenWidth, screenHeight }= this.props
    
    return(
      <div>
        {/* {mode} */}
        <div style={{background: '#eaeaea', border: '1px solid #ccc', borderRadius: '.5em', padding: '1.5em', width: 275}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <Label style={{padding: '.6em 1em .4em 1em', borderRadius: '2em'}} color='pink'>
                <Header  as='h5' inverted>
                  <b>Desk Phone Simulator</b>
                </Header>
              </Label>
            </div>
            <div>
              <Button icon size='tiny' onClick={this.setMode.bind(this, 'setting')}><Icon name='setting' color={this.state.mode=='setting' ? 'red' : null} /></Button>
            </div>
          </div>
          <Divider />
          <div>
            <Button icon size='tiny'><Icon name='microphone' /> Mute</Button>
            <Button icon size='tiny'><Icon name='user plus' /> Invite</Button>
            <Button icon size='tiny'><Icon name='arrow circle right' /> Forward</Button>
          </div>

          <Divider />
          <div style={{}}>
            {mode=='setting' && <PhonewidgetSetting
              uid={this.state.sipAccount} passwd={this.state.sipPasswd} host={this.state.sipHost}
              screenWidth={screenWidth} screenHeight={screenHeight}
              setMode={this.setMode.bind(this)} onChangeText={this.onChangeText.bind(this)} sipRegister={this.sipRegister.bind(this)}
            />}

            {(mode=='home' || this.state.phoneState=='online') && this.state.phoneState!='oncall'&&  mode!='setting' && <PhonewidgetDial
              terminating={this.state.terminating}
              phoneState={this.state.phoneState}
              statusUA={this.state.statusUA}
              screenWidth={screenWidth} screenHeight={screenHeight}
              setMode={this.setMode.bind(this)} onChangeText={this.onChangeText.bind(this)}
              dtmfSend={this.dtmfSend.bind(this)} callDial={this.callDial.bind(this)}
            />}

            {(mode=='call' || this.state.phoneState=='oncall') && mode!='setting' && 
            <PhonewidgetCall
              terminating={this.state.terminating}
              phoneState={this.state.phoneState}
              statusUA={this.state.statusUA}
              screenWidth={screenWidth} screenHeight={screenHeight} 
              remoteVideoStream= {remoteVideoStream} localVideoStream={localVideoStream}
              callDial={this.callDial.bind(this)} callHangup={this.callHangup.bind(this)} dtmfSend={this.dtmfSend.bind(this)} setMode={this.setMode.bind(this)} 
            />}
          </div>

          {/* <Divider />
          <div>
            <b>{(this.state.phoneState && this.state.phoneState.toUpperCase()) || 'Offline'} / {this.state.statusUA} - {this.state.mode.toUpperCase()}</b>
          </div> */}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Phonewidget)