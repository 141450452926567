import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetProduct(objParam) {
  return {
      type: 'PRODUCT_RESET',
      payload: objParam
  }
}

export function getProduct(objParam) {
  return {
      type: 'PRODUCT_GET',
      payload:
        axios.post(
          urlAPI + 'product/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusProduct(objParam) {
  return {
      type: 'PRODUCT_STATUS',
      payload:
        axios.post(
          urlAPI + 'product/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseProduct(objParam) {
  return {
      type: 'PRODUCT_BROWSE',
      payload:
        axios.post(
          urlAPI + 'product/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createProduct(objParam) {
  return {
      type: 'PRODUCT_CREATE',
      payload:
        axios.post(
          urlAPI + 'product/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateProduct(objParam) {
  return {
      type: 'PRODUCT_UPDATE',
      payload:
        axios.post(
          urlAPI + 'product/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeProduct(objParam) {
  return {
      type: 'PRODUCT_REMOVE',
      payload:
        axios.post(
          urlAPI + 'product/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
