import React from 'react'
import { Button, Menu, Icon, Label, Divider } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

class IconToggle extends React.Component {
  constructor(props){
  	super(props)
  	this.state= {
      
    }
  }

  componentDidMount() {
    const { active }= this.state
    //console.log('TOGLE MOUNT: ', active, this.props.active)
    
    if (active==undefined) {
      this.setState({
        active: this.props.active
      })
    }
  }

  componentDidUpdate(prev) {
    if (this.props.active!=prev.active) {
      this.setState({
        active: this.props.active
      })
    } 
  }

  onClick(e) {
    const { active }= this.state
    this.setState({
      active: active!=undefined ? !active : true
    })
    
    if (this.props.onClick!==undefined) {
      this.props.onClick()
    }
  }

  render() {
    const { color, readOnly, disabled }= this.props
    const { active }= this.state

    if (active==true)
      return (
        <Icon disabled={readOnly || disabled} name='toggle on' color={color || 'red'} style={{cursor: 'pointer', fontSize: '1.5em', transform: 'rotate(90deg)'}} onClick={this.onClick.bind(this)} />
      )
    else
      return (
        <Icon disabled={readOnly || disabled} name='toggle off' color={'grey'} style={{cursor: 'pointer', fontSize: '1.5em', transform: 'rotate(90deg)'}} onClick={this.onClick.bind(this)} />
      )
  }
}

export default IconToggle
