import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetAgent(objParam) {
  return {
      type: 'AGENT_RESET',
      payload: objParam
  }
}

export function getAgent(objParam) {
  return {
      type: 'AGENT_GET',
      payload:
        axios.post(
          urlAPI + 'agent/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusAgent(objParam) {
  return {
      type: 'AGENT_STATUS',
      payload:
        axios.post(
          urlAPI + 'agent/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseAgent(objParam) {
  return {
      type: 'AGENT_BROWSE',
      payload:
        axios.post(
          urlAPI + 'agent/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createAgent(objParam) {
  return {
      type: 'AGENT_CREATE',
      payload:
        axios.post(
          urlAPI + 'agent/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateAgent(objParam) {
  return {
      type: 'AGENT_UPDATE',
      payload:
        axios.post(
          urlAPI + 'agent/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeAgent(objParam) {
  return {
      type: 'AGENT_REMOVE',
      payload:
        axios.post(
          urlAPI + 'agent/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function realmAgent(objParam) {
  return {
      type: 'AGENT_REALM',
      payload:
        axios.post(
          urlAPI + 'agent/realm',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
