export default function linkReducer (state = {}, action) {
    switch (action.type) {
      case 'LINK_RESET': {
          return({
              ...state,
              getLink: {},
              updateLink: {},
              createLink: {},
          })
      }
  
      case 'LINK_GET_PENDING': {
          return({...state, getLink: {progress: 0}})
      }
      case 'LINK_GET_FULFILLED': {
          if (action.payload==undefined) {
              return({...state, getLink: {progress: 2, payload: action.payload}})
          } else {
              return({...state, getLink: {...action.payload.data, progress: 1, payload: action.payload}})
          }
      }
      case 'LINK_GET_REJECTED': {
          return({...state, getLink: {progress: 2, payload: action.payload}})
      }
  
      case 'LINK_STATUS_PENDING': {
        return({...state, statusLink: {progress: 0}})
      }
      case 'LINK_STATUS_FULFILLED': {
          if (action.payload==undefined) {
              return({...state, statusLink: {progress: 2, payload: action.payload}})
          } else {
              return({...state, statusLink: {...action.payload.data, progress: 1, payload: action.payload}})
          }
      }
      case 'LINK_STATUS_REJECTED': {
          return({...state, statusLink: {progress: 2, payload: action.payload}})
      }
  
      case 'LINK_BROWSE_PENDING': {
        return({...state, browseLink: {progress: 0}})
      }
      case 'LINK_BROWSE_FULFILLED': {
          if (action.payload==undefined) {
              return({...state, browseLink: {progress: 2, payload: action.payload}})
          } else {
              return({...state, browseLink: {...action.payload.data, progress: 1, payload: action.payload}})
          }
      }
      case 'LINK_BROWSE_REJECTED': {
          return({...state, browseLink: {progress: 2, payload: action.payload}})
      }
  
      case 'LINK_CREATE_PENDING': {
        return({...state, browseLink: {progress: 0}})
      }
      case 'LINK_CREATE_FULFILLED': {
          if (action.payload==undefined) {
              return({...state, createLink: {progress: 2, payload: action.payload}})
          } else {
              return({...state, createLink: {...action.payload.data, progress: 1, payload: action.payload}})
          }
      }
      case 'LINK_CREATE_REJECTED': {
          return({...state, createLink: {progress: 2, payload: action.payload}})
      }
  
      case 'LINK_UPDATE_PENDING': {
        return({...state, updateLink: {progress: 0}})
      }
      case 'LINK_UPDATE_FULFILLED': {
          if (action.payload==undefined) {
              return({...state, updateLink: {progress: 2, payload: action.payload}})
          } else {
              return({...state, updateLink: {...action.payload.data, progress: 1, payload: action.payload}})
          }
      }
      case 'LINK_UPDATE_REJECTED': {
          return({...state, updateLink: {progress: 2, payload: action.payload}})
      }
  
      case 'LINK_REMOVE_PENDING': {
        return({...state, removeLink: {progress: 0}})
      }
      case 'LINK_REMOVE_FULFILLED': {
          if (action.payload==undefined) {
              return({...state, removeLink: {progress: 2, payload: action.payload}})
          } else {
              return({...state, removeLink: {...action.payload.data, progress: 1, payload: action.payload}})
          }
      }
      case 'LINK_REMOVE_REJECTED': {
          return({...state, removeLink: {progress: 2, payload: action.payload}})
      }
  
      default: {
        return state
      }
    }
  }