import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import MenuHorizontal from '../../libraries/common/MenuHorizontal';

import StatisticBody from './StatisticBody'
import PerformanceBody from './PerformanceBody'
import ReportingBody from './ReportingBody'

const options= (module)=>{
  return([
    {
      key: '1',
      icon: 'calendar alternate outline',
      caption: 'Campaign',
      hint:{
        header: 'Campaign Result',
        content: 'Your deep analysis of any campaigns which delivered or on progress.'
      }
    },
    {
      key: '2',
      icon: 'user circle outline',
      caption: 'Agent',
      hint:{
        header: 'Agent Statistic',
        content: 'Agent activity statistic performance, available for one month performance.'
      }
    },
    {
      key: '3',
      icon: 'file alternate outline',
      caption: 'Reporting',
      hint:{
        header: 'Transaction Reporting',
        content: 'Download your reporting or manualy generate if necessary.'
      }
    },
  ])
}

class DashboardBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        // dashboardId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, dashboardId: id})
    }

    render() {
      const {mode, dashboardId}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <Container fluid>
          <div style={{margin: '0em 1em 0 1em'}}>
            <MenuHorizontal onClick={this.setMode.bind(this)} options= {options(module)} color='red'/>
          </div>
          <div>
            {mode==1 && <PerformanceBody mode={2} screenHeight={screenHeight} screenWidth={screenWidth} />}
            {mode==2 && <PerformanceBody mode={3} screenHeight={screenHeight} screenWidth={screenWidth} />}
            {mode==3 && <ReportingBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)}  />}
          </div>
        </Container>
      )
    }
}

export default DashboardBody
