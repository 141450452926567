import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetAssign(objParam) {
  return {
      type: 'ASSIGN_RESET',
      payload: objParam
  }
}

export function getAssign(objParam) {
  return {
      type: 'ASSIGN_GET',
      payload:
        axios.post(
          urlAPI + 'assign/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusAssign(objParam) {
  return {
      type: 'ASSIGN_STATUS',
      payload:
        axios.post(
          urlAPI + 'assign/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseAssign(objParam) {
  return {
      type: 'ASSIGN_BROWSE',
      payload:
        axios.post(
          urlAPI + 'assign/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createAssign(objParam) {
  return {
      type: 'ASSIGN_CREATE',
      payload:
        axios.post(
          urlAPI + 'assign/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateAssign(objParam) {
  return {
      type: 'ASSIGN_UPDATE',
      payload:
        axios.post(
          urlAPI + 'assign/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeAssign(objParam) {
  return {
      type: 'ASSIGN_REMOVE',
      payload:
        axios.post(
          urlAPI + 'assign/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function agentAssign(objParam) {
  return {
      type: 'ASSIGN_AGENT',
      payload:
        axios.post(
          urlAPI + 'assign/agent',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function workgroupAssign(objParam) {
  return {
      type: 'ASSIGN_WORKGROUP',
      payload:
        axios.post(
          urlAPI + 'assign/workgroup',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
