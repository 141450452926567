import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import { statusOptions }  from '../common/StaticMasterData'
import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { browseTarget } from "../../actions/stream/targetAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    targetStore: state.targetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTarget:(objParam) => dispatch(browseTarget(objParam)),
  }
}


class TargetSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
      lastChange: null,
    }
  }

  componentDidMount() {
  }

  submitForm(values) {
    const { search }= this.state
    this.props.browseTarget({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeInput(e, v) {
    const { search }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.browseTarget({search: {...search, keyword: v}})
      }
    }, 500)
  }

  onChangeSelect(e, v) {
    const { search }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    this.props.browseTarget({search: {...search, status: v}})
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props
    
    return (
      <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
        <div style={{padding: '1em 1em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{marginTop: '.25em'}}>
                <Icon name='user circle' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Target / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
                </Header.Content>
              </Header>
            </div>
            <div>
              {mode ==1 &&
              <Button size='mini' color='grey' onClick={this.showSearch.bind(this)} icon style={{padding: '.5em'}}><Icon name={searchMode==true? 'angle up' : 'angle down'} /></Button>}
            </div>
          </div>

          {mode==1 && searchMode && 
          <div style={{margin: '1em 0 0 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='equal'>
                    <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                    width={12} 
                    placeholder= 'Search target ... <ENTER>' />
                    <Field name='status' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'status')}
                    options={statusOptions}
                    width={4} 
                    placeholder= 'Status' />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.targetStore.browseTarget!=undefined && state.targetStore.browseTarget.progress==1 ? state.targetStore.browseTarget.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'TargetSearch',	// a unique identifier for this form
  })(TargetSearch)
  )
)