export default function messagingReducer (state = {}, action) {
  switch (action.type) {
    case 'MESSAGING_RESET': {
        return({
            ...state,
            getMessaging: {},
            updateMessaging: {},
            createMessaging: {},
            agentMessaging: {},
        })
    }

    case 'MESSAGING_BROWSE_PENDING': {
      return({...state, browseMessaging: {progress: 0}})
    }
    case 'MESSAGING_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseMessaging: {...api.browseMessaging.response, progress: 1, payload: action.payload}})
            return({...state, browseMessaging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseMessaging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGING_BROWSE_REJECTED': {
        return({...state, browseMessaging: {progress: 2, payload: action.payload}})
    }
    
    case 'MESSAGING_GET_PENDING': {
        return({...state, getMessaging: {progress: 0}})
    }
    case 'MESSAGING_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getMessaging: {...api.getMessaging.response, progress: 1, payload: action.payload}})
            return({...state, getMessaging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getMessaging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGING_GET_REJECTED': {
        return({...state, getMessaging: {progress: 2, payload: action.payload}})
    }

    case 'MESSAGING_STATUS_PENDING': {
      return({...state, statusMessaging: {progress: 0}})
    }
    case 'MESSAGING_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusMessaging: {...api.statusMessaging.response, progress: 1, payload: action.payload}})
            return({...state, statusMessaging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusMessaging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGING_STATUS_REJECTED': {
        return({...state, statusMessaging: {progress: 2, payload: action.payload}})
    }

    case 'MESSAGING_CREATE_PENDING': {
      return({...state, createMessaging: {progress: 0}})
    }
    case 'MESSAGING_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createMessaging: {...api.createMessaging.response, progress: 1, payload: action.payload}})
            return({...state, createMessaging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createMessaging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGING_CREATE_REJECTED': {
        return({...state, createMessaging: {progress: 2, payload: action.payload}})
    }

    case 'MESSAGING_UPDATE_PENDING': {
      return({...state, updateMessaging: {progress: 0}})
    }
    case 'MESSAGING_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateMessaging: {...api.updateMessaging.response, progress: 1, payload: action.payload}})
            return({...state, updateMessaging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateMessaging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGING_UPDATE_REJECTED': {
        return({...state, updateMessaging: {progress: 2, payload: action.payload}})
    }

    case 'MESSAGING_REMOVE_PENDING': {
      return({...state, removeMessaging: {progress: 0}})
    }
    case 'MESSAGING_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeMessaging: {...api.removeMessaging.response, progress: 1, payload: action.payload}})
            return({...state, removeMessaging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeMessaging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGING_REMOVE_REJECTED': {
        return({...state, removeMessaging: {progress: 2, payload: action.payload}})
    }

    case 'MESSAGING_INBOUND_PENDING': {
      return({...state, inboundMessaging: {progress: 0}})
    }
    case 'MESSAGING_INBOUND_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, inboundMessaging: {...api.inboundMessaging.response, progress: 1, payload: action.payload}})
            return({...state, inboundMessaging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, inboundMessaging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGING_INBOUND_REJECTED': {
        return({...state, inboundMessaging: {progress: 2, payload: action.payload}})
    }

    case 'MESSAGING_OUTBOUND_PENDING': {
      return({...state, outboundMessaging: {progress: 0}})
    }
    case 'MESSAGING_OUTBOUND_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, outboundMessaging: {...api.outboundMessaging.response, progress: 1, payload: action.payload}})
            return({...state, outboundMessaging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, outboundMessaging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGING_OUTBOUND_REJECTED': {
        return({...state, outboundMessaging: {progress: 2, payload: action.payload}})
    }
    
    case 'MESSAGING_SEARCH_PENDING': {
      return({...state, searchMessaging: {progress: 0}})
    }
    case 'MESSAGING_SEARCH_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, searchMessaging: {...api.searchMessaging.response, progress: 1, payload: action.payload}})
            return({...state, searchMessaging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, searchMessaging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGING_SEARCH_REJECTED': {
        return({...state, searchMessaging: {progress: 2, payload: action.payload}})
    }
    
    case 'MESSAGING_AGENT_PENDING': {
      return({...state, agentMessaging: {progress: 0}})
    }
    case 'MESSAGING_AGENT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, agentMessaging: {...api.agentMessaging.response, progress: 1, payload: action.payload}})
            return({...state, agentMessaging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, agentMessaging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGING_AGENT_REJECTED': {
        return({...state, agentMessaging: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}