export default function recordReducer (state = {}, action) {
  switch (action.type) {
    case 'RECORD_RESET': {
        return({
            ...state,
            getRecord: {},
            updateRecord: {},
            createRecord: {},
        })
    }

    case 'RECORD_GET_PENDING': {
        return({...state, getRecord: {progress: 0}})
    }
    case 'RECORD_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getRecord: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getRecord: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RECORD_GET_REJECTED': {
        return({...state, getRecord: {progress: 2, payload: action.payload}})
    }

    case 'RECORD_STATUS_PENDING': {
      return({...state, statusRecord: {progress: 0}})
    }
    case 'RECORD_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusRecord: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusRecord: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RECORD_STATUS_REJECTED': {
        return({...state, statusRecord: {progress: 2, payload: action.payload}})
    }

    case 'RECORD_BROWSE_PENDING': {
      return({...state, browseRecord: {progress: 0}})
    }
    case 'RECORD_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseRecord: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseRecord: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RECORD_BROWSE_REJECTED': {
        return({...state, browseRecord: {progress: 2, payload: action.payload}})
    }

    case 'RECORD_CREATE_PENDING': {
      return({...state, browseRecord: {progress: 0}})
    }
    case 'RECORD_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createRecord: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createRecord: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RECORD_CREATE_REJECTED': {
        return({...state, createRecord: {progress: 2, payload: action.payload}})
    }

    case 'RECORD_UPDATE_PENDING': {
      return({...state, updateRecord: {progress: 0}})
    }
    case 'RECORD_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateRecord: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateRecord: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RECORD_UPDATE_REJECTED': {
        return({...state, updateRecord: {progress: 2, payload: action.payload}})
    }

    case 'RECORD_REMOVE_PENDING': {
      return({...state, removeRecord: {progress: 0}})
    }
    case 'RECORD_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeRecord: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeRecord: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RECORD_REMOVE_REJECTED': {
        return({...state, removeRecord: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}