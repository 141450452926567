import React from 'react'

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';
import Supervisor from "./Supervisor"

class SupervisorContainer extends React.Component {
  render() {
    return(
      <WebSocketProvider>
        <Supervisor />
      </WebSocketProvider>
    )
  }
} 
export default SupervisorContainer
