import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

// import InteractiveBrowse from '../../libraries/realm/flow/InteractiveBrowse'
import InteractiveForm from '../../libraries/realm/flow/InteractiveForm'
// import InteractiveSearch from '../../libraries/realm/flow/InteractiveSearch'


class InteractiveBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 2, //1: browse, 2: edit, 3: insert, 4: delete
        interactiveId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, interactiveId: id, clientId: clientId})
    }

    render() {
      const {mode, interactiveId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              {/* <InteractiveSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} /> */}
              <div>
                {/* {mode==1 && <InteractiveBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />} */}
                {mode==2 && <InteractiveForm mode={mode} interactiveId={interactiveId} clientId={clientId} setMode={this.setMode.bind(this)} screenWidth={screenWidth}/>}
                {/* {mode==3 && <InteractiveForm mode={mode} interactiveId={0} clientId={0} setMode={this.setMode.bind(this)} />} */}
              </div>
            </div>
          </div>
        </Container>
      )
    }
}

export default InteractiveBody
