import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetMau(objParam) {
  return {
      type: 'MAU_RESET',
      payload: objParam
  }
}

export function getMau(objParam) {
  return {
      type: 'MAU_GET',
      payload:
        axios.post(
          urlAPI + 'voip/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusMau(objParam) {
  return {
      type: 'MAU_STATUS',
      payload:
        axios.post(
          urlAPI + 'voip/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseMau(objParam) {
  return {
      type: 'MAU_BROWSE',
      payload:
        axios.post(
          urlAPI + 'voip/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createMau(objParam) {
  return {
      type: 'MAU_CREATE',
      payload:
        axios.post(
          urlAPI + 'voip/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateMau(objParam) {
  return {
      type: 'MAU_UPDATE',
      payload:
        axios.post(
          urlAPI + 'voip/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeMau(objParam) {
  return {
      type: 'MAU_REMOVE',
      payload:
        axios.post(
          urlAPI + 'voip/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
