import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import TunnelBrowse from '../../../libraries/realm/talk/TunnelBrowse'
import TunnelForm from '../../../libraries/realm/talk/TunnelForm'
import TunnelSearch from '../../../libraries/realm/talk/TunnelSearch'


class TunnelBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        tunnelId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, tunnelId: id})
    }

    render() {
      const {mode, tunnelId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <TunnelSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
          <div>
            {mode==1 && <TunnelBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}/>}
            {mode==2 && <TunnelForm mode={mode} tunnelId={tunnelId} setMode={this.setMode.bind(this)} />}
            {mode==3 && <TunnelForm mode={mode} tunnelId={0} setMode={this.setMode.bind(this)} />}
          </div>
        </div>
      )
    }
}

export default TunnelBody
