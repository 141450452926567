import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetError(objParam) {
  return {
      type: 'ERROR_RESET',
      payload: objParam
  }
}

export function loggingError(objParam) {
  return {
    type: 'ERROR_LOGGING',
    payload: objParam
  }
}

export function getError(objParam) {
  return {
      type: 'ERROR_GET',
      payload:
        axios.post(
          urlAPI + 'system/error/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusError(objParam) {
  return {
      type: 'ERROR_STATUS',
      payload:
        axios.post(
          urlAPI + 'system/error/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseError(objParam) {
  return {
      type: 'ERROR_BROWSE',
      payload:
        axios.post(
          urlAPI + 'system/error/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createError(objParam) {
  return {
      type: 'ERROR_CREATE',
      payload:
        axios.post(
          urlAPI + 'system/error/create',
          JSON.stringify({...objParam, 
            vendor: navigator.vendor,
            language: navigator.language,
            userAgent: navigator.userAgent,
            documentUrl: document.URL,
            // userid: localStorage.userid,
            // cookiesEnabled: navigator.cookiesEnabled
          }),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateError(objParam) {
  return {
      type: 'ERROR_UPDATE',
      payload:
        axios.post(
          urlAPI + 'system/error/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeError(objParam) {
  return {
      type: 'ERROR_REMOVE',
      payload:
        axios.post(
          urlAPI + 'system/error/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
