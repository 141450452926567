import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label, Dropdown } from 'semantic-ui-react'


import Spyphone from '../../../libraries/spyphone/Spyphone';



import { connect } from "react-redux"
import { accountOmniphone, spyAgent, whisperAgent } from "../../../actions/stream/omniphoneAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    accountOmniphone:(objParam) => dispatch(accountOmniphone(objParam)),
    spyAgent:(objParam) => dispatch(spyAgent(objParam)),
    whisperAgent:(objParam) => dispatch(whisperAgent(objParam)),
  }
}


class MonitoringSpy extends Component {
  constructor(props) {
    super(props);
    this.state={
    }
  }

  componentDidMount() {
    this.props.accountOmniphone();
    navigator.mediaDevices.getUserMedia({ audio: true, video: false });
  }
  
  componentWillUnmount() {
    this.setState({
      spying: false,
    })
  }

  onSpying() {
    const { data }= (this.props.omniphoneStore.accountOmniphone!==undefined ? this.props.omniphoneStore.accountOmniphone : {})

    if (this.props.data) {
      this.props.spyAgent({
        mode: this.state.spying ? 'stop' : 'start',
        originating: data.voipAccount,

        account: this.props.data.account,
      });

      this.setState({
        spying: !this.state.spying,
      })
    }
  }

  onWhisper(v, k) {
    const dataSip= (this.props.omniphoneStore.accountOmniphone!==undefined ? this.props.omniphoneStore.accountOmniphone.data : {})
    const { data }= (this.props.omniphoneStore.spyAgent!==undefined ? this.props.omniphoneStore.spyAgent : {})

    if (this.props.data) {
      this.props.whisperAgent({
        whispering: k,
        mode: v ? 'start' : 'stop',
        originating: dataSip.voipAccount,

        account: this.props.data.account,
        voipAccount: data.voipAccount,
        voiceParking: data.voiceParking,
      });
      
      this.setState({
        whisper: k=='1' ? v : false,
        joining: k=='0' ? v : false,
      })
    }
  }
  
  
  render() {
    const { data }= (this.props.omniphoneStore.accountOmniphone!==undefined ? this.props.omniphoneStore.accountOmniphone : {})

    // console.log('AGENT SPY ITEM', this.props.data)
    return (<>
      {/* <Spyphone
        mode='mini'
        sipAccount='7000000001' 
        sipPasswd='111111'
        sipHost='call.halopagi.com:4063' 
        sipRealm='halopagi'/> */}

      {data!==undefined && data.voipAccount!==undefined &&
        <Spyphone
        spying={this.state.spying ? 'start' : 'stop'}
        whisper={this.state.whisper ? 'start' : 'stop'}

        sipAccount={data.voipAccount}
        sipPasswd={data.voipPasswd}
        sipHost={data.voipHost +':'+ data.voipPort}
        sipRealm={data.voipRealm}
        />
      }
      <div style={{padding: '2em'}}>
        <Header as='h2' color='red' style={{display: 'block', textAlign: 'center'}}>
          <Header.Content>
            <b>{this.props.data.name}</b>
            <Header.Subheader>
              Id: <b>{this.props.data.account}</b> / Tags: <b>{this.props.data.tagger || '-'}</b>
            </Header.Subheader>
            {/* <Header.Subheader>{this.props.data && JSON.stringify(this.props.data)}</Header.Subheader> */}
          </Header.Content>
        </Header>
        
        {/* {JSON.stringify(this.props.data)} */}
        <Divider />
        <Header as='h3' icon style={{display: 'block', textAlign: 'center'}}>
          <Icon color={(this.state.whisper && 'teal') || (this.state.joining && 'pink') || (this.state.spying && 'red') || 'grey'} name={(this.state.whisper && 'microphone') || (this.state.joining && 'microphone') || (this.state.spying && 'headphones') || 'circle notched'} style={{fontSize: '8em'}} />
          <Header.Content>
            <Header.Subheader><b>ACTIVITY</b></Header.Subheader>
            {(this.state.whisper && 'Whispering Agent Only') || (this.state.joining && 'Talk to All Parties') || (this.state.spying && 'Spying Conversation') || 'NONE'}
          </Header.Content>
        </Header>
      </div>

      <div style={{margin: '1em 1em 1em 1em', textAlign: 'center'}}>
        <Popup content='Click to start or stop listen to Agent and Customer conversations' inverted trigger={
          <Button size='big' color={(this.state.spying && 'red') || 'blue'}
          disabled={!this.props.oncall} 
          onClick={this.onSpying.bind(this)}>{this.state.spying ? <Icon name='pause' /> : <Icon name='wifi' style={{transform: 'rotate(90deg)'}}/>} {this.state.spying && 'Stop Listen'} {!this.state.spying && 'Start Listen'}</Button>
        } />
      </div>

      <Divider />
      <div style={{display: 'flex', margin: '1em 1em 1em 1em'}}>
        <div style={{flex: '1'}}>
          <Popup content='Hold to whisper, only agent could listen your words' inverted trigger={
            <Button size='large' color={(this.state.whisper && 'teal') || (this.state.spying && 'blue') || 'grey'}
            disabled={!this.state.spying || this.state.joining} 
            onMouseUp={this.onWhisper.bind(this, false, '1')}
            onMouseDown={this.onWhisper.bind(this, true, '1')} >Whisper</Button>
          } />
        </div>
        <div>
          <Popup content='Hold to talk to everyone, all parties could listen your words' inverted trigger={
            <Button size='large'  color={(this.state.joining && 'pink') || (this.state.spying && 'blue') || 'grey'}
            disabled={!this.state.spying || this.state.whisper} 
            onMouseUp={this.onWhisper.bind(this, false, '0')}
            onMouseDown={this.onWhisper.bind(this, true, '0')}>Talk to All</Button>
          } />
        </div>
      </div>
    </>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringSpy)