import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Button } from 'semantic-ui-react'

import MessageBrowse from '../../libraries/realm/halo/MessageBrowse'
import MessageForm from '../../libraries/realm/halo/MessageForm'


class MessageBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        messageId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, messageId: id})
    }

    render() {
      const {mode, messageId}= this.state
      const {screenWidth}= this.props
      
      return(
        <Container fluid>
          <div>
            <div style={{padding: '1em 2em', border: '0px solid rgba(100,100,100,.2)'}}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' style={{marginTop: '.25em'}}>
                    <Icon name='bell' style={{fontSize: '1em'}}/>
                    <Header.Content>
                      Messaging / Notification
                    </Header.Content>
                  </Header>
                </div>
                <div>
                  {mode!=1 &&
                  <Button size='mini' color='blue' onClick={this.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='arrow left' /></Button>}
                  {mode!=2 && mode!=3 &&
                  <Button size='mini' color='red' onClick={this.setMode.bind(this,3)} icon style={{padding: '.5em'}}><Icon name='plus' /></Button>}
                  {/* {mode ==1 &&
                  <Button size='mini' color='grey' onClick={this.showSearch.bind(this)} icon style={{padding: '.5em'}}><Icon name={searchMode==true? 'angle up' : 'angle down'} /></Button>} */}
                </div>
              </div>
            </div>
            
            <div style={{padding: '1em 2em'}}>
              {mode==1 && <MessageBrowse />}
              {mode==3 && <MessageForm mode={mode} notificationId={0} setMode={this.setMode.bind(this)} />}
            </div>
          </div>
        </Container>
      )
    }
}

export default MessageBody