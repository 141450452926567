import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  Popup,
} from 'semantic-ui-react'



class OmniphoneIncomingMini extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, layout, responsive, agent }= this.props
    return (
      <>
        <Segment attached={true} className='bganimatebgred' style={{padding: '1em .5em', minHeight: '6em', background: '#da0000', border: 'none'}}>
          <Header as='h3' inverted style={{margin: '1em .5em 0 .5em', display: 'inline'}} >
            {/* <Popup header='Answer Now !' content='Callback must be answered to start performance counter.' trigger={
              <Icon name='key' className='bganimateiconborder' style={{transform: 'rotate(35deg)', fontSize: '1em', cursor: 'pointer'}} inverted circular color='yellow' onClick={this.props.callAnswer.bind(this)}/>
            } /> */}
            <div className='bganimatebgdark' style={{display: 'inline-block', width: '2.5em', height: '2.5em', borderRadius: '2.5em', background: '#fff', backgroundImage: agent.avatar ? 'url("'+ agent.avatar +'")' : '', backgroundSize: 'cover', textAlign: 'center'}}>
              {!agent.avatar && <div style={{marginTop: '.45em', fontSize: '1.4em', fontWeight: 'bold'}}>{agent.initial}</div>}
            </div>
            <Header.Content style={{paddingLeft: '.5em'}}>
              {mode=='predialed' ? 'Incoming ...' : 'Verification !'}
              <Header.Subheader style={{fontSize: '.7em'}}>
                {mode=='predialed' ? 'Inbound call ...' : 'Answer for full access'}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
      </>
    )
  }
}

export default OmniphoneIncomingMini