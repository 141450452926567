import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

import AgentHeader from "./AgentHeader"
import AgentFooter from "./AgentFooter"
import AgentBody from "./AgentBody"

import { connect } from "react-redux"
import { createError, loggingError } from "../../actions/system/errorAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    errorStore: state.errorStore
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createError:(objParam) => dispatch(createError(objParam)),
    loggingError:(objParam) => dispatch(loggingError(objParam)),
  }
}

class Agent extends React.Component {
    constructor(props) {
      super(props)
      this.timer= null;
      this.state= {
        screenHeight: 800,
        screenWidth: 1024,
        mode: 'statistic',
        module: 'outbound',

        openDrawer: false,
      }
      this.updateDimension = this.updateDimension.bind(this)
    }

    componentDidMount() {
      this.updateDimension()
      window.addEventListener('resize', this.updateDimension)
      this.timer= setInterval(()=>{
        const { logs }= (this.props.errorStore.loggingError ? this.props.errorStore.loggingError : {})
        // console.log(logs)
        logs && logs.length>0 && this.props.createError({
          isBatch: true,
          logs: logs || [],
        })
      }, 15000)
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimension)
      this.context.socket && this.context.socket.close();

      clearTimeout(this.timer);
    }
    
    componentDidUpdate() {
      const { progress }= (this.props.errorStore.createError ? this.props.errorStore.createError : {})
      if (progress && progress==1) {
        this.props.loggingError(null)
      }
    }
    
    updateDimension() {
      this.setState({ ...this.state, screenWidth: window.innerWidth, screenHeight: window.innerHeight })
    }
  
    openModal(value, params) {
      // console.log(params)
      this.setState({
        modal: value,
        params: params,
      })
    }

    closeModal(params) {
      // console.log(params)
      this.setState({
        modal: null,
        params: params,
      })
    }

    openWindow(mode) {
      this.setState({
        mode: mode,
        // openDrawer: true
      })
    }

    openDrawer() {
      const { openDrawer }= this.state
      this.setState({
        openDrawer: !openDrawer
      })
    }

    setModule(module) {
      this.setState({
        module: module,
        mode: 'statistic',
      openDrawer: false
    })
  }

  render() {
    const {screenHeight, screenWidth, mode, modal, openDrawer, module}= this.state
    try {
      return(
        <Container fluid>
          <AgentHeader screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.openWindow.bind(this)} openModal={this.openModal.bind(this)} closeModal={this.closeModal.bind(this)} mode={mode} modal={modal}  openDrawer={this.openDrawer.bind(this)} setModule={this.setModule.bind(this)} mini={openDrawer}  module={module} />
          <AgentBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.openWindow.bind(this)} openModal={this.openModal.bind(this)} closeModal={this.closeModal.bind(this)} mode={mode} modal={modal}  openDrawer={this.openDrawer.bind(this)} mini={openDrawer} module={module} params={this.state.params}/>
          <AgentFooter screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.openWindow.bind(this)} mini={openDrawer}/>
        </Container>
      )
    } catch(error) {
      console.log(error)
      return(<div>ERROR !</div>)
    }
  }
} 


Agent.contextType= WebSocketContext;

// export default Agent
export default connect(mapStateToProps, mapDispatchToProps)(Agent)