import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
} from 'semantic-ui-react'

import { ivrPath } from "../../../libraries/common/_lib"

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse } from "date-fns"
import { } from "../../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { browseInteractive, statusInteractive } from "../../../actions/flow/interactiveAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    interactiveStore: state.interactiveStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseInteractive:(objParam) => dispatch(browseInteractive(objParam)),
  }
}

class InteractiveContent extends Component {
  constructor(props) {
    super(props)
    this.state= {
      greetingMode: false,
      level: 0,
    }
  }

  componentDidMount() {
    // const { search, paging }= (this.props.interactiveStore.browseInteractive!==undefined ? this.props.interactiveStore.browseInteractive : {})
    // this.props.browseInteractive({search: {transactDate: format(new Date(), 'yyyy-MM-dd 00:00:00'), ...search}, paging: paging})
  }

  showGreeting() {
    const { greetingMode }= this.state
    
    this.setState({
      greetingMode: !greetingMode
    })
  }

  setLevel(v) {
    this.setState({
      level: v
    })
  }
  
  render() {
    const { level }= this.state;
    const { screenWidth, screenHeight, params }= this.props
    // console.log(navigations)

    let seq= 0, seqX= 0;
    const greetings= params.greetings || []
    const navigations= params.navigations || []
    const navItems= []
    
    let i= 0;
    while (i<=9) {
      if (navigations && navigations[i] && i!=0) {
        navItems.push(navigations[i]);
        let j= i * 10;
        while (j<((i+1) * 10)) {
          if (navigations[j]) {
            navItems.push(navigations[j]);
            let k= j * 10;
            while (k<((j+1) * 10)) {
              if (navigations[k]) {
                navItems.push(navigations[k]);
              }
              k++;
            }
          }
          j++;
        }
      } else if (navigations && navigations[i]) {
        navItems.push(navigations[i]);
      } else if (i==0) {
        navItems.push({
          level: 0,
        });
      }
      i++;
    }

    seq= level==0 ? (level * 10) : (level * 10)+1;

    return (<>
      <div style={{display: 'flex', padding: '1em 1em'}}>
        <div style={{flex: '1'}}>
          <Header as='h4' style={{marginTop: '.25em'}}>
            <Icon name='sliders horizontal' style={{fontSize: '1em'}}/>
            <Header.Content>
              IVR Menu Content
            </Header.Content>
          </Header>
        </div>
        <div>
          {/* <Button size='mini' color='brown' onClick={this.showGreeting.bind(this)} icon style={{padding: '.5em', marginRight: '1em'}}><Icon name='clone' /></Button> */}
          <Button size='mini' color='red' onClick={this.props.setMode.bind(this, '33',{...this.props.params, parentLevel: level})} icon style={{padding: '.5em'}}><Icon name='plus' /></Button>
          <Button size='mini' color={this.state.greetingMode==true? 'brown' : 'grey'} onClick={this.showGreeting.bind(this)} icon style={{padding: '.5em'}}><Icon name={this.state.greetingMode==true? 'clone' : 'clone outline'} /></Button>
        </div>
      </div>

      {!this.state.greetingMode &&
        <div style={{padding: '1em 1em', minHeight: screenHeight}}>
          <div style={{textAlign: 'center'}}>
            <Header as='h4'>
              <Header.Content>
                <b>{params.name}</b>
                <Header.Subheader>{params.descript}</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          
          {level>0 && <div style={{marginBottom: '1em'}}>
            <Label as='a' color={'red'} circular size='big' onClick={this.setLevel.bind(this, Math.floor(level/10))}><Icon name='level up alternate' style={{margin: '0'}}/> </Label> <span style={{fontSize: '1.5em'}}>Back Upper Level</span>
          </div>}
          { navItems.map((item, i)=>{
            if (item && (item.level>=(level*10) && item.level<((level+1) * 10))) {
              return(<Segment key={seq}>
                <div style={{display: 'flex'}}>
                  <div style={{width: '3em'}}>
                    <Label as='a' color={(item.level<10 && 'yellow') || (item.level<99 && 'teal') || 'grey'} circular size='big' onClick={this.setLevel.bind(this, item.level)}>{seq++}</Label>
                  </div>
                  <div style={{flex: 3, padding: '0 1em'}}>
                    <Header as='h5'>
                      {/* <Icon name={item.responsed=='Agent' ?'spy' : 'fax' } color={item.responsed=='Agent' ?'blue' : 'orange' } circular inverted style={{fontSize: '1.2em'}}/> */}
                      <Header.Content>
                        <b>{item.name}</b>
                        <Header.Subheader>Level: <b>{item.level}</b> / Resp. by: <b>{item.responsed}</b></Header.Subheader>
                        {/* <Divider /> */}
                        {/* <Header.Subheader>{item.scriptTalent}</Header.Subheader> */}
                      </Header.Content>
                    </Header>
                    <Header as='h5' style={{margin: '0'}}>
                      <Header.Content>
                        {item.scriptTalent}
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{flex: 1, padding: '0 1em'}}>
                    <Header as='h5'>
                      <Header.Content>
                        <b>{item.params || 'No Param'}</b>
                        <Header.Subheader>Param Input</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div>
                    <ReactAudioPlayer
                      style={{
                        width: '210px',
                        display: 'block',
                        height: '35px',
                      }}
                      src={this.props.params.clientId ? ivrPath + this.props.params.clientId.toString().padStart(6,"0") + item.level.toString().padStart(2,"0") +'.mp3' : null}
                      controls
                    />
                  </div>
                  <div style={{width: '4em', textAlign: 'right'}}>
                    <Button icon='edit' style={{margin: '0'}} onClick={this.props.setMode.bind(this, '22', {...item, interactiveId: params.interactiveId})} />
                    <Button icon='delete' style={{margin: '.5em 0'}} onClick={this.props.setMode.bind(this, '44', {...item, interactiveId: params.interactiveId})} />
                    {/* <Button icon='plus' style={{margin: '.5em 0 0 0'}}/> */}
                  </div>
                </div>
              </Segment>)
            }
          })}
        </div>
      }
      {this.state.greetingMode &&
        <div style={{padding: '1em 1em', minHeight: screenHeight}}>
          <div style={{textAlign: 'center'}}>
            <Header as='h4'>
              <Header.Content>
                <b>{params.name}</b>
                <Header.Subheader>{params.descript}</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          { greetings && Object.keys(greetings).map((key)=>{
            return(
              <Segment key={++seqX}>
                <div style={{display: 'flex'}}>
                  <div style={{width: '3em'}}>
                    <Label as='a' color={'brown'} circular size='big'>{seqX}</Label>
                  </div>
                  <div style={{flex: 1, padding: '0 1em'}}>
                    <Header as='h5'>
                      {/* <Icon name='fax' color='orange' circular inverted style={{fontSize: '1.2em'}}/> */}
                      <Header.Content>
                        <b>{(key=='scriptBackPrevious' && 'Back to Previous Menu (*)') || (key=='scriptRepeatCurrent' && 'Repeat Current Menu (0)') || (key=='scriptBusy' && 'Agent is Busy / Not Available') || (key=='scriptOffline' && 'Agent is Offline') || (key=='scriptProblem' && 'System has Problem') || (key=='scriptThankyou' && 'Thank You / Good Bye')}</b>
                        <Header.Subheader><b>Default Greetings</b></Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{flex: 3, padding: '0 1em'}}>
                    <Header as='h5'>
                      <Header.Content>
                        <b>{greetings[key]}</b>
                      </Header.Content>
                    </Header>
                  </div>
                  <div>
                    <ReactAudioPlayer
                      style={{
                        width: '210px',
                        display: 'block',
                        height: '35px',
                      }}
                      src={this.props.params.clientId ? ivrPath + this.props.params.clientId.toString().padStart(6,"0") + ((key=='scriptBackPrevious' && 'previous') || (key=='scriptRepeatCurrent' && 'current') || (key=='scriptBusy' && 'busy') || (key=='scriptOffline' && 'offline') || (key=='scriptProblem' && 'problem') || (key=='scriptThankyou' && 'thankyou')) +'.mp3' : null}
                      controls
                    />
                  </div>
                  <div style={{width: '4em', textAlign: 'right'}}>
                    <Button icon='edit' style={{margin: '0'}} onClick={this.props.setMode.bind(this, '222', {currLevel: key, interactiveId: params.interactiveId})} />
                    {/* <Button icon='delete' style={{margin: '.5em 0'}} /> */}
                    {/* <Button icon='plus' style={{margin: '.5em 0 0 0'}}/> */}
                  </div>
                </div>
              </Segment>
            )
          })}
        </div>
      }

      {/* <Segment textAlign='right' style={{marginTop: '0'}}>
        <Button type='button' icon labelPosition='left' color='red' onClick={this.props.setMode.bind(this, '2',this.props.params.interactiveId, this.props.params.clientId)}><Icon name='setting' />CONFIG</Button>
      </Segment> */}
    </>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InteractiveContent)
