export default function voiceReducer (state = {}, action) {
  switch (action.type) {
    case 'VOICE_RESET': {
        return({
            ...state,
            getVoice: {},
            updateVoice: {},
            createVoice: {},
        })
    }

    case 'VOICE_BROWSE_PENDING': {
      return({...state, browseVoice: {progress: 0}})
    }
    case 'VOICE_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseVoice: {...api.browseVoice.response, progress: 1, payload: action.payload}})
            return({...state, browseVoice: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseVoice: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VOICE_BROWSE_REJECTED': {
        return({...state, browseVoice: {progress: 2, payload: action.payload}})
    }
    
    case 'VOICE_GET_PENDING': {
        return({...state, getVoice: {progress: 0}})
    }
    case 'VOICE_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getVoice: {...api.getVoice.response, progress: 1, payload: action.payload}})
            return({...state, getVoice: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getVoice: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VOICE_GET_REJECTED': {
        return({...state, getVoice: {progress: 2, payload: action.payload}})
    }

    case 'VOICE_STATUS_PENDING': {
      return({...state, statusVoice: {progress: 0}})
    }
    case 'VOICE_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusVoice: {...api.statusVoice.response, progress: 1, payload: action.payload}})
            return({...state, statusVoice: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusVoice: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VOICE_STATUS_REJECTED': {
        return({...state, statusVoice: {progress: 2, payload: action.payload}})
    }

    case 'VOICE_CREATE_PENDING': {
      return({...state, createVoice: {progress: 0}})
    }
    case 'VOICE_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createVoice: {...api.createVoice.response, progress: 1, payload: action.payload}})
            return({...state, createVoice: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createVoice: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VOICE_CREATE_REJECTED': {
        return({...state, createVoice: {progress: 2, payload: action.payload}})
    }

    case 'VOICE_UPDATE_PENDING': {
      return({...state, updateVoice: {progress: 0}})
    }
    case 'VOICE_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateVoice: {...api.updateVoice.response, progress: 1, payload: action.payload}})
            return({...state, updateVoice: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateVoice: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VOICE_UPDATE_REJECTED': {
        return({...state, updateVoice: {progress: 2, payload: action.payload}})
    }

    case 'VOICE_REMOVE_PENDING': {
      return({...state, removeVoice: {progress: 0}})
    }
    case 'VOICE_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeVoice: {...api.removeVoice.response, progress: 1, payload: action.payload}})
            return({...state, removeVoice: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeVoice: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'VOICE_REMOVE_REJECTED': {
        return({...state, removeVoice: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}