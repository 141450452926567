import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetTarget(objParam) {
  return {
      type: 'TARGET_RESET',
      payload: objParam
  }
}

export function getTarget(objParam) {
  return {
      type: 'TARGET_GET',
      payload:
        axios.post(
          urlAPI + 'target/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusTarget(objParam) {
  return {
      type: 'TARGET_STATUS',
      payload:
        axios.post(
          urlAPI + 'target/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function requeueTarget(objParam) {
  return {
      type: 'TARGET_REQUEUE',
      payload:
        axios.post(
          urlAPI + 'target/requeue',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseTarget(objParam) {
  return {
      type: 'TARGET_BROWSE',
      payload:
        axios.post(
          urlAPI + 'target/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createTarget(objParam) {
  return {
      type: 'TARGET_CREATE',
      payload:
        axios.post(
          urlAPI + 'target/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateTarget(objParam) {
  return {
      type: 'TARGET_UPDATE',
      payload:
        axios.post(
          urlAPI + 'target/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeTarget(objParam) {
  return {
      type: 'TARGET_REMOVE',
      payload:
        axios.post(
          urlAPI + 'target/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function engagementTarget(objParam) {
  return {
      type: 'TARGET_ENGAGEMENT',
      payload:
        axios.post(
          urlAPI + 'target/engagement',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
