import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import VerificationBrowse from '../../../libraries/realm/stream/VerificationBrowse'
import VerificationForm from '../../../libraries/realm/stream/VerificationForm'
import VerificationSearch from '../../../libraries/realm/stream/VerificationSearch'



class VerificationBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        transactId: 0,
      };
    }

    setMode(mode, id, campaignId, clientId) {
      this.setState({mode: mode, transactId: id, campaignId: campaignId, clientId: clientId})
    }

    render() {
      const {mode, transactId, campaignId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <VerificationSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
              <div>
                {mode==1 && <VerificationBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}/>}
                {mode==2 && <VerificationForm mode={mode} transactId={transactId} campaignId={campaignId} clientId={clientId} setMode={this.setMode.bind(this)} />}
                {mode==3 && <VerificationForm mode={mode} transactId={0} setMode={this.setMode.bind(this)} />}
              </div>
            </div>
            {/* <div style={{width: '175px', minHeight: screenHeight}}>
              <div style={{padding: '1em'}}>
                <VerificationWidget />
              </div>
            </div> */}
          </div>
        </Container>
      )
    }
}

export default VerificationBody
