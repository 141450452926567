import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Icon,
  Statistic,
  Button,
  Modal,
} from 'semantic-ui-react'


class ModalContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      open: true, 
    } 
  }

  handleOpen() {
    this.setState({ open: true })
  }

  handleClose() {
    if (this.props.closeModal!=undefined) {
      this.props.closeModal()
    }
    this.setState({ open: false })
  }
  
  render() {
    const open= this.props.open!=undefined ? this.props.open : this.state.open
    const { centered, headless, title, header, content, loader, dialog, style }= this.props
  
    return (
      <div>
        {!loader &&
          <Modal style={{background: dialog ? '#fff5f1' : '#f5f5f5', ...(style || {})}}
            open={open}
            // centered={centered || false}
            // onClose={this.handleClose.bind(this)}
            size={this.props.size || 'fullscreen'}>
            {!this.props.locked && !headless &&
            <Modal.Content style={{padding: '1em 1em 1em 0', background: dialog ? '#333' : '#333', borderRadius: '0', zIndex: '9999', }}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1', padding: '.5em 0 0 1em'}}>{
                  title && <Header as='h3' color='yellow'>
                    <Header.Content>
                      <Icon name='warning sign' /> {title}
                    </Header.Content>
                  </Header>
                }</div>
                <div><Icon name='window close' color={dialog ? 'red' : 'yellow'} style={{cursor: 'pointer', padding: '0', margin: '0', fontSize: '2em'}} onClick={this.handleClose.bind(this)}/></div>
              </div>
            </Modal.Content>}
            {header && 
            <Modal.Content style={{padding: '.5em', background: '#f5f5f5'}}>{header}</Modal.Content>}
            {content && 
            <Modal.Content style={{padding: dialog ? '0' : '.5em'}}>{content}</Modal.Content>}
            <Modal.Actions>
              <b>Halo Pagi!</b> Digital Communication 2.3.01
            </Modal.Actions>
          </Modal>
        }
        {loader &&
          <Modal style={{background: 'none', boxShadow: 'none'}}
            open={open}
            // centered={false}
            // onClose={this.handleClose.bind(this)}
            size={this.props.size || 'fullscreen'}>
            {loader && 
            <Modal.Content style={{padding: '.5em', background: 'none'}}>{loader}</Modal.Content>}
          </Modal>
        }
      </div>
    )
  }
}

export default ModalContainer