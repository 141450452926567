import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image } from 'semantic-ui-react'

const stylePopup = {
  borderRadius: '.5em',
  opacity: .8,
  padding: '.5em .8em',
  fontSize: '.8em'
}

export default class MenuLeft extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      
    };
  }

  render() {
    const { screenWidth,  screenHeight, mini, module}= this.props
    
    if (mini) {
      return(
        <></>
      )
    } else {
      return (
        <div style={{top:'3.5em', position: 'fixed', height: screenHeight, width: '80px', zIndex: 99}}>
          {/* <div style={{background: (module=='outbound' ? '#f96f14' : '#14a236'), padding: '.7em .5em', margin: '0 .1em .1em 1.1em', textAlign: 'center', fontWeight: 'bold', color: '#fff', borderRadius: '0 0 .2em .2em'}}>
          {module=='inbound' ? 'IN' : 'OUT'}<Divider style={{margin: '.3em -.5em'}}/>MODE
          </div> */}
          <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '80px'}}>
            {/* <div style={{flex: '1', padding: '.1em 0 1.5em 1em'}}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1', textAlign: 'center'}}>
                  <Popup header='OUTBOUND' trigger={
                    <Button icon style={{padding: '.5em', border: 'none'}} color={module=='outbound' ? 'orange' : null} onClick={this.props.setModule.bind(this, 'outbound')} >
                      <Icon name='angle double left' color={module=='inbound' ? 'red' : null} />
                    </Button>
                  } />
                </div>
                <div style={{flex: '1', textAlign: 'center'}}>
                  <Popup header='INBOUND' trigger={
                    <Button icon style={{padding: '.5em', border: 'none'}} color={module=='inbound' ? 'green' : null} onClick={this.props.setModule.bind(this, 'inbound')} >
                      <Icon name='angle double right' color={module=='outbound' ? 'blue' : null} />
                    </Button>
                  } />
                </div>
              </div>
            </div> */}
            <div style={{textAlign: 'center', background: '#ffffff', margin: '3em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Dashboard' content='Real time monitoring channel, traffic and system heartbeat.' trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'dashboard')}
                  color={this.props.mode=='dashboard' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='dashboard' ? '600' : '100'}}>
                    <Icon name='chart bar' style={{fontSize: '1.6em'}} color={this.props.mode=='dashboard' ? 'blue' : 'grey'}/>
                </Header>
              } />
            </div>
              
            <div style={{textAlign: 'center', background: '#ffffff', margin: '3em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Interaction' content='Engagement over multi channel as conversation activity.' trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'conversation')}
                  color={this.props.mode=='conversation' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='host' ? '600' : '100'}}>
                    <Icon name='comments outline' style={{fontSize: '1.6em'}} color={this.props.mode=='conversation' ? 'blue' : 'grey'}/>
                </Header>
              } />
              
              <Divider style={{margin: '1em  0'}}/>
              <Popup header='Completed' content='Achieved conversation as 100% score of remark or feedback is covered.' trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'activity')}
                  color={this.props.mode=='activity' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='activity' ? '600' : '100'}}>
                    <Icon name='envelope open outline' style={{fontSize: '1.6em'}} color={this.props.mode=='activity' ? 'blue' : 'grey'}/>
                    {/* <Header.Subheader>MESSAGING</Header.Subheader> */}
                </Header>
              } />
            </div>

            {/* <div style={{textAlign: 'center', background: '#ffffff', margin: '3em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Interactive' content='Look up your detail interactive ? check here !.' trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'interactive')}
                  color={this.props.mode=='interactive' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='host' ? '600' : '100'}}>
                    <Icon name='window restore outline' style={{fontSize: '1.6em'}} color={this.props.mode=='interactive' ? 'blue' : 'grey'}/>
                </Header>
              } />

              <Divider style={{margin: '1em  0'}}/>
              <Popup header='Ticket' content='Real time monitoring channel, traffic and system heartbeat.' trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'ticketing')}
                  color={this.props.mode=='ticketing' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='ticketing' ? '600' : '100'}}>
                    <Icon name='ticket alternate' style={{fontSize: '1.6em'}} color={this.props.mode=='ticketing' ? 'blue' : 'grey'}/>
                </Header>
              } />
            </div> */}

            <div style={{textAlign: 'center', background: '#ffffff', margin: '3em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Campaign Setup' content='Campaign management and target allocation.' trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'event')}
                  color={this.props.mode=='event' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='event' ? '600' : '100'}}>
                    
                    <Icon name='calendar alternate outline' style={{fontSize: '1.6em'}} color={this.props.mode=='event' ? 'blue' : 'grey'}/>
                </Header>
              } />

              {/* <Divider style={{margin: '1em  0'}}/>
              <Popup header='Resources' content='Manage private and customer resources for campaigns usage.' trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'resource')}
                  color={this.props.mode=='resource' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='resource' ? '600' : '100'}}>
                    <Icon name='hdd outline' style={{fontSize: '1.6em'}} color={this.props.mode=='resource' ? 'blue' : 'grey'}/>
                </Header>
              } /> */}

              {/* <Divider style={{margin: '1em  0'}}/>
              <Popup header='Client Area' content='Private area for client such as available API and billing information.' trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'private')}
                  color={this.props.mode=='private' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='api' ? '600' : '100'}}>
                    <Icon name='building outline' style={{fontSize: '1.6em'}} color={this.props.mode=='private' ? 'blue' : 'grey'}/>
                </Header>
              } /> */}

              {/* <Divider style={{margin: '1em  0'}}/>
              <Popup header='Ticket' content='Real time monitoring channel, traffic and system heartbeat.' trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'ticketing')}
                  color={this.props.mode=='ticketing' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='ticketing' ? '600' : '100'}}>
                    <Icon name='desktop' style={{fontSize: '1.4em'}} color={this.props.mode=='ticketing' ? 'blue' : 'grey'}/>
                </Header>
              } /> */}
              
            </div>
            <div style={{flex: '1', padding: '0 1.5em 1.5em 1em', }}>

            </div>
          </div>
        </div>
      );
    }
  }
}
