
import { format, parse, addSeconds } from "date-fns"

export default function errorReducer (state = {}, action) {
  switch (action.type) {
    case 'ERROR_RESET': {
        return({
            ...state,
            getError: {},
            updateError: {},
            createError: {},
        })
    }

    case 'ERROR_LOGGING': {
        const message= {};
        
        if (action.payload) {
            if (typeof(action.payload.message)=='object') {
                for(var propt1 in action.payload.message){
                    if (typeof(action.payload.message[propt1])!='object') {
                        message[propt1]= action.payload.message[propt1];
                    } else if (typeof(action.payload.message[propt1])=='object') {
                        for(var propt2 in action.payload.message[propt1]){
                            if (typeof(action.payload.message[propt1][propt2])!='object') {
                                message[propt2]= action.payload.message[propt1][propt2];
                            }
                        }
                    }
                }
            } else {
                message['info']= action.payload.message;
            }
            
            if (state.loggingError) {
                state.loggingError.logs.push({
                    ...action.payload, 
                    execStart: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), 
                    // message: (typeof(action.payload.message)=='object' ? JSON.stringify(message) : action.payload.message),
                    message: message,
                });
            } else {
                state['loggingError']= {
                    logs: [{
                        ...action.payload, 
                        execStart: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), 
                        // message: (typeof(action.payload.message)=='object' ? JSON.stringify(message) : action.payload.message),
                        message: message,
                    }]
                }
            }

            // console.log('!!! ERROR LOGGING REDUCER', state);
            return({
                ...state,
            })
        } else {
            if (state.loggingError) {
                state.loggingError.logs= [];
                state.createError= {};
            }
            return({
                ...state, 
            })
        }
    }

    case 'ERROR_GET_PENDING': {
        return({...state, getError: {progress: 0}})
    }
    case 'ERROR_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getError: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getError: {...action.payload.data, data: action.payload.data.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ERROR_GET_REJECTED': {
        return({...state, getError: {progress: 2, payload: action.payload}})
    }

    case 'ERROR_STATUS_PENDING': {
      return({...state, statusError: {progress: 0}})
    }
    case 'ERROR_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusError: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusError: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ERROR_STATUS_REJECTED': {
        return({...state, statusError: {progress: 2, payload: action.payload}})
    }

    case 'ERROR_BROWSE_PENDING': {
      return({...state, browseError: {progress: 0}})
    }
    case 'ERROR_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseError: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseError: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ERROR_BROWSE_REJECTED': {
        return({...state, browseError: {progress: 2, payload: action.payload}})
    }

    case 'ERROR_CREATE_PENDING': {
      return({...state, browseError: {progress: 0}})
    }
    case 'ERROR_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createError: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createError: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ERROR_CREATE_REJECTED': {
        return({...state, createError: {progress: 2, payload: action.payload}})
    }

    case 'ERROR_UPDATE_PENDING': {
      return({...state, updateError: {progress: 0}})
    }
    case 'ERROR_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateError: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateError: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ERROR_UPDATE_REJECTED': {
        return({...state, updateError: {progress: 2, payload: action.payload}})
    }

    case 'ERROR_REMOVE_PENDING': {
      return({...state, removeError: {progress: 0}})
    }
    case 'ERROR_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeError: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeError: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ERROR_REMOVE_REJECTED': {
        return({...state, removeError: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}