import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse } from "date-fns"
import { } from "../../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { browseInteractive, statusInteractive } from "../../../actions/flow/interactiveAction"
import InteractiveContent from './InteractiveContent';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    interactiveStore: state.interactiveStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseInteractive:(objParam) => dispatch(browseInteractive(objParam)),
    statusInteractive:(objParam) => dispatch(statusInteractive(objParam)),
  }
}

class InteractiveBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.interactiveStore.browseInteractive!==undefined ? this.props.interactiveStore.browseInteractive : {})
    this.props.browseInteractive(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.interactiveStore.browseInteractive!==undefined ? this.props.interactiveStore.browseInteractive : {})
    this.props.browseInteractive({search: {transactDate: format(new Date(), 'yyyy-MM-dd 00:00:00'), ...search}, paging: paging})
  }

  componentDidUpdate(prevProps, prevStates) {
    const { search, paging }= (this.props.interactiveStore.browseInteractive!==undefined ? this.props.interactiveStore.browseInteractive : {})

    if (this.props.mode==1 && prevProps && prevProps.mode!=this.props.mode) {
      this.props.browseInteractive({search: {...search}, paging: paging})
    }
  }

  onChangeStatus(id, status, e) {
    // console.log(id, status, e)
    this.props.statusInteractive({interactiveId:id, isEnabled: status})
  }

  render() {
    //console.log(this.props.interactiveStore.browseInteractive)
    const { expand, remark }= this.state
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.interactiveStore.browseInteractive!==undefined ? this.props.interactiveStore.browseInteractive : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (<>
      { data!==undefined && data.length==0 && <div style={{padding: '1em'}}>
        <Segment>
          <div style={{display: 'flex', padding: '1em 2em 4em 0'}}>
            <div style={{flex: '1'}}>
              <Header as='h2'>
                <Header.Content>
                  <Label style={{borderRadius: '4em', fontSize: '1em'}} color='red'>
                    <b>Activate Now !</b>
                  </Label>
                  <Header.Subheader style={{padding : '2em 0 0 1em', color: '#333'}}>
                    <b>Contact Your Account Executive . . .</b>
                    <Divider />
                    Get direct interactive insights from your current customer for best experience.
                    <Divider />
                    Define your IVR Interactive Menu based your valid customer experience.
                    <Divider />
                    Submit your IVR Interactive Menu to our administrator to active interactive menu configuration.
                    <Divider />
                    Advanced self update and custom configuration is available from this administrator page.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{flex: '1', padding: '0 8em'}}>
              <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <div style={{flex: '1'}}>
                </div>
                <Divider horizontal>
                  <Icon name='play' style={{fontSize: '6em'}} circular inverted color='grey' />
                </Divider>
                <div style={{flex: '1'}}>

                </div>
              </div>
            </div>
          </div>
        </Segment>
      </div>}

      { data!==undefined ? data.map((item,key)=>{
          return(
            <InteractiveContent key={key} clientId={item.clientId} params={item} setMode={this.props.setMode.bind(this)} />
          )
        }) : '-'
      }
    </>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InteractiveBrowse)
