import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Statistic,
  Progress,
  Feed,
} from 'semantic-ui-react'

import { connect } from "react-redux"
import { browseAppointment } from "../../actions/halo/appointmentAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    appointmentStore: state.appointmentStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseAppointment:(objParam) => dispatch(browseAppointment(objParam)),
  }
};


class MessagingFeed extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.appointmentStore.browseAppointment!==undefined ? this.props.appointmentStore.browseAppointment : {})
    this.props.browseAppointment(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.appointmentStore.browseAppointment!==undefined ? this.props.appointmentStore.browseAppointment : {})
    this.props.browseAppointment({search: {...search}, paging: paging})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.appointmentStore.browseAppointment!==undefined ? this.props.appointmentStore.browseAppointment : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{}}>
        <Header as='h5'>
          <Header.Content>
            Historical Message
            <Header.Subheader>Historical message shown here</Header.Subheader>
          </Header.Content>
        </Header>
        <Feed>
          <Feed.Event>
            <Feed.Label image='https://react.semantic-ui.com/images/avatar/small/joe.jpg' />
            <Feed.Content>
              <Feed.Summary>
                <a>Joe Henderson</a> posted on his page
              </Feed.Summary>
              <Feed.Meta>
                <Icon name='twitter' /> Twitter, 3 days ago
              </Feed.Meta>
              <Feed.Extra text>
                Ours is a life of constant reruns. We're always circling back to where
                we'd we started. <Label as='a' color='black' style={{border: 'none', padding: '.2em .3em', borderRadius: '0'}}>more</Label>
              </Feed.Extra>
            </Feed.Content>
          </Feed.Event>
        </Feed>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagingFeed)
