import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'


import { statusOptions }  from '../../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { browseTransact } from "../../../actions/stream/transactAction"
import { collectionStage, restructuringStage, telesaleStage, verificationStage } from "../../../actions/halo/masterAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    transactStore: state.transactStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTransact:(objParam) => dispatch(browseTransact(objParam)),

    collectionStage:(objParam) => dispatch(collectionStage(objParam)),
    restructuringStage:(objParam) => dispatch(restructuringStage(objParam)),
    telesaleStage:(objParam) => dispatch(telesaleStage(objParam)),
    verificationStage:(objParam) => dispatch(verificationStage(objParam)),
  }
};


class CollectionSearch extends Component {
  constructor(props) {
    super(props);
    this.state= {
      searchMode: true,
      lastChange: null,
    }
  }

  componentDidMount() {
    this.props.collectionStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    // this.props.restructuringStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    // this.props.telesaleStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    // this.props.verificationStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
  }

  submitForm(values) {
    const { search }= this.state
    this.props.browseTransact({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    const { search }= (this.props.transactStore.browseTransact!==undefined ? this.props.transactStore.browseTransact : {})
    var values= []
    values[key]= v
    this.props.browseTransact({search: {...search, ...values}})
  }

  onChangeInput(e, v) {
    const { search }= (this.props.transactStore.browseTransact!==undefined ? this.props.transactStore.browseTransact : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.browseTransact({search: {...search, keyword: v}})
      }
    }, 500)
  }

  onChangeSelect(k, e, v) {
    const { search }= (this.props.transactStore.browseTransact!==undefined ? this.props.transactStore.browseTransact : {})
    if (k=='status') {
      this.props.browseTransact({search: {...search, status: (v=='' ? null : v)}})
    } else if (k=='channelType') {
      this.props.browseTransact({search: {...search, channelType: (v=='' ? null : v)}})
    } else if (k=='remark') {
      this.props.browseTransact({search: {...search, remark: (v=='' ? null : v)}})
    }
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props

    const collectionStage= (this.props.masterStore.collectionStage!=undefined && this.props.masterStore.collectionStage.progress=='1' ? this.props.masterStore.collectionStage.data : [])
    // const restructuringStage= (this.props.masterStore.restructuringStage!=undefined && this.props.masterStore.restructuringStage.progress=='1' ? this.props.masterStore.restructuringStage.data : [])
    // const telesaleStage= (this.props.masterStore.telesaleStage!=undefined && this.props.masterStore.telesaleStage.progress=='1' ? this.props.masterStore.telesaleStage.data : [])
    // const verificationStage= (this.props.masterStore.verificationStage!=undefined && this.props.masterStore.verificationStage.progress=='1' ? this.props.masterStore.verificationStage.data : [])
    
    const remarkMap= new Map();
    const remarkOptions = []
    collectionStage && collectionStage.map(item=>{
      remarkMap.set(item.value, item)
    })
    // restructuringStage && restructuringStage.map(item=>{
    //   remarkMap.set(item.value)
    // })
    // telesaleStage && telesaleStage.map(item=>{
    //   remarkMap.set(item.value)
    // })
    // verificationStage && verificationStage.map(item=>{
    //   remarkMap.set(item.value)
    // })

    remarkMap && [...remarkMap.keys()].map((key, i)=>{
      remarkMap.get(key).progress=='100' && remarkOptions.push(
        { key: i, value: remarkMap.get(key).value, text: remarkMap.get(key).text }
      )
    })
    
    return (
      <div>
        <div style={{padding: '1em 1em', background: 'rgb(250, 250, 250)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{marginTop: '.25em'}}>
                <Icon name='copy outline' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Collection / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
                </Header.Content>
              </Header>
            </div>
            <div>
              {mode !=1 &&
              <Button size='mini' color='blue' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='arrow left' /></Button>}
              {/* {mode !=2 && mode !=3 &&
              <Button size='mini' color='red' onClick={this.props.setMode.bind(this,3)} icon style={{padding: '.5em'}}><Icon name='plus' /></Button>}
              {mode ==1 &&
              <Button size='mini' color='grey' onClick={this.showSearch.bind(this)} icon style={{padding: '.5em'}}><Icon name={searchMode==true? 'angle up' : 'angle down'} /></Button>} */}
            </div>
          </div>

          {mode==1 && searchMode && 
          <div style={{margin: '1em 0 0 0', border: '0px solid rgba(100,100,100,.2', borderRadius: '.25em'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))} >
              <Form.Group>
                <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                width={8} 
                placeholder= 'Search transact ... <ENTER>' />
                <Field name='remark' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'remark')}
                options={remarkOptions}
                width={4} 
                placeholder= 'Cust Remark' />
                <Field name='transactDate' component={DatePickerField} onChange= {this.onChangeDate.bind(this, 'transactDate')}
                width={4} 
                type='date'
                placeholder= 'Trx. Date' />
                {/* <Field name='status' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'status')}
                options={statusOptions}
                width={3} 
                placeholder= 'Status' /> */}
              </Form.Group>
            </Form>
          </div>}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.transactStore.browseTransact!=undefined && state.transactStore.browseTransact.progress==1 ? state.transactStore.browseTransact.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'CollectionSearch',	// a unique identifier for this form
  })(CollectionSearch)
  )
)