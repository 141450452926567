import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { browseAppointment } from "../../actions/halo/appointmentAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    appointmentStore: state.appointmentStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseAppointment:(objParam) => dispatch(browseAppointment(objParam)),
  }
}

class AppointmentWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }

  componentDidMount() {
    const { search, paging }= (this.props.appointmentStore.browseAppointment!==undefined ? this.props.appointmentStore.browseAppointment : {})
    this.props.browseAppointment({search: {...search, eventDate: format(new Date(), 'yyyy-MM-dd')}, paging: paging})
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const { data, search, paging }= (this.props.appointmentStore.browseAppointment!==undefined ? this.props.appointmentStore.browseAppointment : {})

    return (
      <div style={{margin: '0 0 1.5em 0'}}>
        <Segment attached='top' style={{padding: '1em .5em'}}>
          <Header as='h5'>
            <Icon name='window restore outline' style={{fontSize: '1em'}} />
            <Header.Content>
              Appointment
            </Header.Content>
          </Header>
        </Segment>
        <Segment attached='bottom' style={{padding: '1em'}}>
          <Feed>
            {data && data.length==0 &&
              <Header as='h5' color='red'>
                <Header.Content>
                  No today's appointment available
                  <Header.Subheader></Header.Subheader>
                </Header.Content>
              </Header>
            }
            {data && data.map((item, i)=>{
              return(
                <Feed.Event key={i}>
                  {item.target && item.target.avatar!=null ? <Feed.Label image={item.target.avatar} /> :  <Feed.Label><Icon name='bookmark outline' circular inverted color='orange'/></Feed.Label>}
                  <Feed.Content style={{marginTop: '-.25em'}}>
                    <Feed.User>{item.target && item.target.name} !</Feed.User>
                    <Feed.Summary>
                      {item.topic}
                      <Feed.Meta>{item.startEvent!=null ? format(parse(item.startEvent, 'yyyy-MM-dd', new Date()), 'hh:mm, dd/MMM yy') : 'No schedule'} <br />{item.venue}</Feed.Meta>
                    </Feed.Summary>
                    <Feed.Extra text>{item.content || 'No discussion information'}</Feed.Extra>
                    {/* <Feed.Meta><Feed.Like><Icon name='like' />5 Likes</Feed.Like></Feed.Meta> */}
                  </Feed.Content>
                </Feed.Event>
              )
            })}
          </Feed>
        </Segment>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentWidget)