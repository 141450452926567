import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { parse, format, addSeconds } from "date-fns"
import { recordPath } from "../../../libraries/common/_lib"
import { voiceStatus } from "../../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { browseVoice, statusVoice } from "../../../actions/stream/voiceAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    voiceStore: state.voiceStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseVoice:(objParam) => dispatch(browseVoice(objParam)),
    statusVoice:(objParam) => dispatch(statusVoice(objParam)),
  }
}

class InteractionIntro extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remark: false,
      expand: false,
    }
  }
  render() {
    const { screenWidth, screenHeight }= this.props

    return (
      <div>
        {/* <Header as='h2' >
          <Header.Content>
            Conversation Flow !
            <Header.Subheader>Click get conversation from list of available customers or target person.</Header.Subheader>
          </Header.Content>
        </Header> */}
        <div style={{display: 'flex'}}>
          <div style={{flex: 1, paddingRight: '1em'}}>
            <Header as='h4' style={{margin: '0'}} >
              <Label color='red' size='big' circular  style={{margin: '.1em 1em 0 0'}}>1</Label>
              <Header.Content>
                Available Conversation
                <Header.Subheader>List of contacts of available conversation.</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h4' style={{margin: '0'}} >
              <Label color='red' size='big' circular  style={{margin: '.1em 1em 0 0'}}>2</Label>
              <Header.Content>
                Pick / Select Contact
                <Header.Subheader>Click contact for detail/historical conversation.</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{flex: 1, paddingLeft: '1em'}}>
            <Header as='h4' style={{margin: '0'}} >
              <Label color='red' size='big' circular  style={{margin: '.1em 1em 0 0'}}>3</Label>
              <Header.Content>
                Initiate Conversation
                <Header.Subheader>From available channel start call or message for conversation.</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Header as='h4' style={{margin: '0'}} >
              <Label color='red' size='big' circular  style={{margin: '.1em 1em 0 0'}}>4</Label>
              <Header.Content>
                Waiting Connection
                <Header.Subheader>Interactive conversation requires connection.</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InteractionIntro)
