import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table, Feed, Label } from 'semantic-ui-react'

import DismissableMessage from '../../../common/DismissableMessage'
import { personalOptions, ticketOptions }  from '../../../../modules/service/StaticMaster'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, LabelSelectField } from '../../../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../../../validation/validation'

import { connect } from "react-redux"
import { checkToken } from "../../../../actions/auth/authAction"
import { resetTicket, getTicket,updateTicket, createTicket } from "../../../../actions/service/ticketAction"
import { resetInteraction, getInteraction,updateInteraction, createInteraction } from "../../../../actions/service/interactionAction"
import { listUser, listWorkgroup } from "../../../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('EscalateBox')

const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    masterStore: state.masterStore,
    ticketStore: state.ticketStore,
    interactionStore: state.interactionStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),

    listUser:(objParam) => dispatch(listUser(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),

    resetTicket:(objParam) => dispatch(resetTicket(objParam)),
    getTicket:(objParam) => dispatch(getTicket(objParam)),
    createTicket:(objParam) => dispatch(createTicket(objParam)),
    updateTicket:(objParam) => dispatch(updateTicket(objParam)),

    resetInteraction:(objParam) => dispatch(resetInteraction(objParam)),
    getInteraction:(objParam) => dispatch(getInteraction(objParam)),
    createInteraction:(objParam) => dispatch(createInteraction(objParam)),
    updateInteraction:(objParam) => dispatch(updateInteraction(objParam)),
  }
}

class EscalateBox extends Component {
  constructor(props) {
    super(props)
    this.state= {
      privacy: 'internal',
    }
  }

  componentDidMount(){
    console.log('!!! DID MOUNT', this.props.ticket, this.props.action)

    this.props.resetInteraction(this.props.mode)
    this.props.listUser()
    this.props.listWorkgroup()
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.interactionStore.updateInteraction!=undefined ? this.props.interactionStore.updateInteraction : {}) : (this.props.interactionStore.createInteraction!=undefined ? this.props.interactionStore.createInteraction : {}))

    if (progress==1 && code=='00') {
      // setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    values['action']= 'escalate';
    values['owner']= this.checkRole();
    values['privacy']= this.state.privacy;
    values['ticketId']= (this.props.ticket && this.props.ticket.ticketId) || (this.props.action && this.props.action.ticketId)

    this.props.createInteraction({...values})
  }

  checkRole() {
    const token= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken.data : {}
    const ticket= this.props.ticket
    if (ticket) {
      //requesterUserId, requesterWorkgroupId, dispatcherUserId, assigneeUserId, assigneeWorkgroupId, assigneeUsers, assigneeWorkgroups, 
      // escalationUserId, escalationWorkgroupId, escalationUsers,escalationWorkgroups, mentionUsers, mentionWorkgroups
      return(
        (ticket.escalationUserId==token.userId && 'escalation') ||  
        (ticket.escalationUsers && ticket.escalationUsers.indexOf(token.userId)>=0 && 'escalation') || 
        (ticket.assigneeUserId==token.userId && 'assignee') || 
        (ticket.requesterUserId==token.userId && 'requester') || 
        (ticket.dispatcherUserId==token.userId && 'dispatcher') || 
        (ticket.mentionUsers && ticket.mentionUsers.indexOf(token.userId)>=0 && 'follower') || 'follower'
      )
    }
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    const { progress, code, text }= (this.props.interactionStore.createInteraction!=undefined ? this.props.interactionStore.createInteraction : {})

    const token= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken.data : {}
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
        
    const users= new Map();
    const workgroups= new Map();

    listUser.map(item=>{
      users.set(item.value, item)
    })
    listWorkgroup.map(item=>{
      workgroups.set(item.value, item)
    })

    return (
      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <Header as='h5'>
          <Icon name='user plus' circular inverted color='orange' style={{fontSize: '1.1em'}} />
          <Header.Content>
            Escalation
            <Header.Subheader>Ticket escalation, support single and split ticket.</Header.Subheader>
          </Header.Content>
        </Header>
        <Segment>
          <Header as='h5'>
            <Header.Content>
              By <b>{token.name}</b>, {token.groupId}
              <Header.Subheader>As <b>{this.checkRole()}</b> at <b>{format(new Date(), 'HH:mm')}</b> {format(new Date(), 'dd/MMM yyyy')}</Header.Subheader>
            </Header.Content>
          </Header>
          <Divider />

          <Form.Group widths='16'>
            <Field name='escalationMethod' component={SelectField}
            options={ticketOptions('escalation')}
            width={16}
            label={<b>Escalation Method</b>}
            placeholder='Single / Split' />
          </Form.Group>
          <Form.Group widths='16' style={{marginTop: '1em'}}>
            <Field name='escalationWorkgroups' component={SelectField}
            options={listWorkgroup}
            width={16}
            multiple
            label={<b>Escalation Teams / Workgroups</b>}
            placeholder='Teams / Workgroups' />
          </Form.Group>
        </Segment>
        {this.props.escalationMethod=='single' && this.props.escalationWorkgroups && this.props.escalationWorkgroups.length>0 && <Segment>
            <Form.Group widths='equal'>
              <Field name={'activity'} component={TextAreaField}
              width={6}
              label={
                this.props.escalationWorkgroups.map((item, i)=>{
                  if (i==0) {
                    return('To: @'+ ((workgroups.get(item) && workgroups.get(item).text) || item))
                  } else {
                    return(', @'+ ((workgroups.get(item) && workgroups.get(item).text) || item))
                  }
                })
              }
              placeholder='Type message here ...' />
            </Form.Group>
            {/* ONE MESSAGE FOR ALL WITH @ACCOUNT_ID OR @TEAM_ID */}
          </Segment>}
        {this.props.escalationMethod=='split' && this.props.escalationWorkgroups && this.props.escalationWorkgroups.length>0 && this.props.escalationWorkgroups.map((item, i)=>{
          return(
            <Segment>
              <Form.Group widths='equal'>
                <Field name={'splitMessage_'+ item} component={TextAreaField}
                width={6}
                label={'To: @'+ ((workgroups.get(item) && workgroups.get(item).text) || item)}
                placeholder='Type message here ...' />
              </Form.Group>
              {/* MULTIPLE MESSAGE / ONE MESSAGE FOR EACH WORKGROUP / TEAM ESCALATION */}
            </Segment>
          )
        })}

        <Segment>
          {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Ticket' : 'Insert Ticket') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
          
          <div style={{textAlign: 'right'}}>
            <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
            <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
          </div>
        </Segment>

      </Form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: {},
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      escalationMethod: selector(state, 'escalationMethod'),
      escalationWorkgroups: selector(state, 'escalationWorkgroups'),
    }),
    { load: getTicket }
  )
  (reduxForm({
    form: 'EscalateBox',	// a unique identifier for this form
  })(EscalateBox)
  )
)
