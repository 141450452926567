import React from 'react'

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';
import Agent from "./Agent"

class AgentContainer extends React.Component {
    
  render() {
    return(
      <WebSocketProvider>
        <Agent />
      </WebSocketProvider>
    )
  }
} 
export default AgentContainer
