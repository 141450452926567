import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import MenuHorizontal from '../../libraries/common/MenuHorizontal';

import TelephonyBody from '../stream/TelephonyBody'
// import MessagingBody from '../stream/MessagingBody'
// import SocialBody from '../stream/SocialBody'

const options= [
  {
    key: '1',
    icon: 'call',
    // caption: 'Telephony',
  },
  {
    key: '2',
    icon: 'comment alternate outline',
    // caption: 'Messaging',
  },
  {
    key: '3',
    icon: 'share alternate',
    // caption: 'Social Media',
  },
]

class ConversationBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        // dashboardId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight, style}= this.props

      return(
        <Container fluid style={{...style}}>
          <div>
            {/* <div style={{margin: '0em 1em 0 1em'}}>
              <MenuHorizontal onClick={this.setMode.bind(this)} options= {options} color='red'/>
            </div> */}
            <div>
              <div style={{display: (mode==1 && 'block') || 'none'}}><TelephonyBody openWindow={this.props.openWindow.bind(this)} screenHeight={screenHeight} screenWidth={screenWidth} /></div>
              {/* <div style={{display: (mode==2 && 'block') || 'none'}}><MessagingBody openWindow={this.props.openWindow.bind(this)} screenHeight={screenHeight} screenWidth={screenWidth} /></div>
              <div style={{display: (mode==3 && 'block') || 'none'}}><SocialBody openWindow={this.props.openWindow.bind(this)} screenHeight={screenHeight} screenWidth={screenWidth} /></div> */}
            </div>
          </div>
        </Container>
      )
    }
}

export default ConversationBody
