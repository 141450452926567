export default function omniwidgetReducer (state = {}, action) {
  switch (action.type) {
    case 'OMNIWIDGET_RESET': {
        return({
            ...state,
            // navigationOmniwidget: undefined,
            bookOmniwidget: undefined,
            messageOmniwidget: undefined,
            rateOmniwidget: undefined,
            dialOmniwidget: undefined,
        })
    }

    case 'OMNIWIDGET_NAVIGATION_PENDING': {
      return({...state, navigationOmniwidget: {progress: 0}})
    }
    case 'OMNIWIDGET_NAVIGATION_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, navigationOmniwidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, navigationOmniwidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIWIDGET_NAVIGATION_REJECTED': {
        return({...state, navigationOmniwidget: {progress: 2, payload: action.payload}})
    }

    case 'OMNIWIDGET_BOOK_PENDING': {
      return({...state, bookOmniwidget: {progress: 0}})
    }
    case 'OMNIWIDGET_BOOK_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, bookOmniwidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, bookOmniwidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIWIDGET_BOOK_REJECTED': {
        return({...state, bookOmniwidget: {progress: 2, payload: action.payload}})
    }

    case 'OMNIWIDGET_RATE_PENDING': {
      return({...state, rateOmniwidget: {progress: 0}})
    }
    case 'OMNIWIDGET_RATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, rateOmniwidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, rateOmniwidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIWIDGET_RATE_REJECTED': {
        return({...state, rateOmniwidget: {progress: 2, payload: action.payload}})
    }

    case 'OMNIWIDGET_MESSAGE_PENDING': {
      return({...state, messageOmniwidget: {progress: 0}})
    }
    case 'OMNIWIDGET_MESSAGE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, messageOmniwidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, messageOmniwidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIWIDGET_MESSAGE_REJECTED': {
        return({...state, messageOmniwidget: {progress: 2, payload: action.payload}})
    } 

    case 'OMNIWIDGET_VARIABLE_PENDING': {
      return({...state, variableOmniwidget: {progress: 0}})
    }
    case 'OMNIWIDGET_VARIABLE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, variableOmniwidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, variableOmniwidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIWIDGET_VARIABLE_REJECTED': {
        return({...state, variableOmniwidget: {progress: 2, payload: action.payload}})
    }

    case 'OMNIWIDGET_CHAT_PENDING': {
      return({...state, chatOmniwidget: {progress: 0}})
    }
    case 'OMNIWIDGET_CHAT_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, chatOmniwidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, chatOmniwidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIWIDGET_CHAT_REJECTED': {
        return({...state, chatOmniwidget: {progress: 2, payload: action.payload}})
    }

    case 'OMNIWIDGET_DIAL_PENDING': {
      return({...state, dialOmniwidget: {progress: 0}})
    }
    case 'OMNIWIDGET_DIAL_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, dialOmniwidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, dialOmniwidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNIWIDGET_DIAL_REJECTED': {
        return({...state, dialOmniwidget: {progress: 2, payload: action.payload}})
    }
    
    case 'OMNIWIDGET_PARAM_PENDING': {
      return({...state, paramOmniwidget: {progress: 0}})
    }
    case 'OMNIWIDGET_PARAM_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, paramOmniwidget: {progress: 2, payload: action.payload}})
      } else {
          return({...state, paramOmniwidget: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'OMNIWIDGET_PARAM_REJECTED': {
      return({...state, paramOmniwidget: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}