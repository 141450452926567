export default function interactiveReducer (state = {}, action) {
  switch (action.type) {
    case 'INTERACTIVE_RESET': {
        return({
            ...state,
            getInteractive: {},
            updateInteractive: {},
            createInteractive: {},
        })
    }

    case 'INTERACTIVE_BROWSE_PENDING': {
      return({...state, browseInteractive: {progress: 0}})
    }
    case 'INTERACTIVE_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseInteractive: {...api.browseInteractive.response, progress: 1, payload: action.payload}})
            return({...state, browseInteractive: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseInteractive: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'INTERACTIVE_BROWSE_REJECTED': {
        return({...state, browseInteractive: {progress: 2, payload: action.payload}})
    }
    
    case 'INTERACTIVE_GET_PENDING': {
        return({...state, getInteractive: {progress: 0}})
    }
    case 'INTERACTIVE_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getInteractive: {...api.getInteractive.response, progress: 1, payload: action.payload}})
            return({...state, getInteractive: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getInteractive: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'INTERACTIVE_GET_REJECTED': {
        return({...state, getInteractive: {progress: 2, payload: action.payload}})
    }

    case 'INTERACTIVE_STATUS_PENDING': {
      return({...state, statusInteractive: {progress: 0}})
    }
    case 'INTERACTIVE_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusInteractive: {...api.statusInteractive.response, progress: 1, payload: action.payload}})
            return({...state, statusInteractive: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusInteractive: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'INTERACTIVE_STATUS_REJECTED': {
        return({...state, statusInteractive: {progress: 2, payload: action.payload}})
    }

    case 'INTERACTIVE_CREATE_PENDING': {
      return({...state, createInteractive: {progress: 0}})
    }
    case 'INTERACTIVE_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createInteractive: {...api.createInteractive.response, progress: 1, payload: action.payload}})
            return({...state, createInteractive: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createInteractive: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'INTERACTIVE_CREATE_REJECTED': {
        return({...state, createInteractive: {progress: 2, payload: action.payload}})
    }

    case 'INTERACTIVE_UPDATE_PENDING': {
      return({...state, updateInteractive: {progress: 0}})
    }
    case 'INTERACTIVE_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateInteractive: {...api.updateInteractive.response, progress: 1, payload: action.payload}})
            return({...state, updateInteractive: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateInteractive: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'INTERACTIVE_UPDATE_REJECTED': {
        return({...state, updateInteractive: {progress: 2, payload: action.payload}})
    }

    case 'INTERACTIVE_REMOVE_PENDING': {
      return({...state, removeInteractive: {progress: 0}})
    }
    case 'INTERACTIVE_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeInteractive: {...api.removeInteractive.response, progress: 1, payload: action.payload}})
            return({...state, removeInteractive: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeInteractive: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'INTERACTIVE_REMOVE_REJECTED': {
        return({...state, removeInteractive: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}