export default function ticketReducer (state = {}, action) {
  switch (action.type) {
    case 'TICKET_RESET': {
        return({
            ...state,
            getTicket: {},
            updateTicket: {},
            createTicket: {},
        })
    }

    case 'TICKET_BROWSE_PENDING': {
      return({...state, browseTicket: {progress: 0}})
    }
    case 'TICKET_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseTicket: {...api.browseTicket.response, progress: 1, payload: action.payload}})
            return({...state, browseTicket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseTicket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TICKET_BROWSE_REJECTED': {
        return({...state, browseTicket: {progress: 2, payload: action.payload}})
    }
    
    case 'TICKET_GET_PENDING': {
        return({...state, getTicket: {progress: 0}})
    }
    case 'TICKET_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getTicket: {...api.getTicket.response, progress: 1, payload: action.payload}})
            return({...state, getTicket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getTicket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TICKET_GET_REJECTED': {
        return({...state, getTicket: {progress: 2, payload: action.payload}})
    }

    case 'TICKET_STATUS_PENDING': {
      return({...state, statusTicket: {progress: 0}})
    }
    case 'TICKET_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusTicket: {...api.statusTicket.response, progress: 1, payload: action.payload}})
            return({...state, statusTicket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusTicket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TICKET_STATUS_REJECTED': {
        return({...state, statusTicket: {progress: 2, payload: action.payload}})
    }

    case 'TICKET_CREATE_PENDING': {
      return({...state, createTicket: {progress: 0}})
    }
    case 'TICKET_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createTicket: {...api.createTicket.response, progress: 1, payload: action.payload}})
            return({...state, createTicket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createTicket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TICKET_CREATE_REJECTED': {
        return({...state, createTicket: {progress: 2, payload: action.payload}})
    }

    case 'TICKET_UPDATE_PENDING': {
      return({...state, updateTicket: {progress: 0}})
    }
    case 'TICKET_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateTicket: {...api.updateTicket.response, progress: 1, payload: action.payload}})
            return({...state, updateTicket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateTicket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TICKET_UPDATE_REJECTED': {
        return({...state, updateTicket: {progress: 2, payload: action.payload}})
    }

    case 'TICKET_REMOVE_PENDING': {
      return({...state, removeTicket: {progress: 0}})
    }
    case 'TICKET_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeTicket: {...api.removeTicket.response, progress: 1, payload: action.payload}})
            return({...state, removeTicket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeTicket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TICKET_REMOVE_REJECTED': {
        return({...state, removeTicket: {progress: 2, payload: action.payload}})
    }
    
    case 'TICKET_HANDLING_PENDING': {
      return({...state, handlingTicket: {progress: 0}})
    }
    case 'TICKET_HANDLING_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, handlingTicket: {...api.handlingTicket.response, progress: 1, payload: action.payload}})
            return({...state, handlingTicket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, handlingTicket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TICKET_HANDLING_REJECTED': {
        return({...state, handlingTicket: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}