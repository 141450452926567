import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form } from 'semantic-ui-react'

import OmniwidgetDial from './OmniwidgetDial'
import OmniwidgetVideo from './OmniwidgetVideo'

import { connect } from "react-redux"
import { dialOmniwidget } from "../../actions/flow/omniwidgetAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniwidgetStore: state.omniwidgetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dialOmniwidget:(objParam) => dispatch(dialOmniwidget(objParam)),
  }
}

class OmniwidgetCall extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isCalled: false,
        video: null,
      };
    }

    callDial(terminating) {
      this.props.callDial(terminating);
    }

    callHangup() {
      this.props.callHangup()
      this.props.setMode('rate')
    }

    componentDidMount() {
      // setTimeout(()=>{
      //   this.props.dtmfSend('12#')
      // }, 2000)
    }
    
    componentDidUpdate(prevProps) {
      const {booked}= this.props
      // agentExten: "7000000005"
      // agentId: "4"
      // clientId: "2"
      // sipAccount: "7012345215"
      // sipHost: "host"
      // sipPasswd: "MTQ3MTgyODU4Nzc5MzE0MTAwMDA="
      // sipPort: "port"
      // visitorId: "215"
      // voiceParking: "4175"      

      // console.log('DID UPDATE', booked)
      
      if (!this.state.isCalled && this.props.phoneState=='online' && booked) {
        this.setState({
          isCalled: true
        })
        // this.callDial('41756000000000001')
        // console.log('CALL DIAL OMNIWIDGET-CALL', booked)
        // const parking= booked.voiceParking +'6'+ booked.clientId.toString().padStart(6,"0") + booked.visitorId.toString().padStart(6,"0"); //clientId(6)visitorId(6)agentId(6)level(09) 2 digits to defined level position and current menu
        // const parking= booked.voiceParking +'6'+ booked.clientId.toString().padStart(5,"0") + booked.visitorId.toString().padStart(5,"0") + booked.level.toString().padStart(2,"0"); //clientId(6)visitorId(6)agentId(6)level(09) 2 digits to defined level position and current menu
        // this.callDial(parking);
        this.props.dialOmniwidget({
          userId: booked ? booked.agentId : null, // for booked agent allocation
          clientId: booked ? booked.clientId : null,
          visitorId: booked ? booked.visitorId : null,
          level: booked ? booked.level : null,
          // parking: parking,
          terminating: booked ? booked.sipAccount : null,
          // terminating: parking,
        });
      }

      if (this.props.phoneState=='online' && prevProps.phoneState=='oncall') {
        this.props.setMode('rate')
      }
      
      if (this.props.remoteVideoStream && this.state.video==null) {
        this.setState({
          video: true,
        })
      }
    }

    showVideo() {
      this.setState({
        video: !this.state.video,
      })
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight, booked, phoneState}= this.props
      
      return(
        <div style={{margin: '-10em auto 0 auto', background: 'rgba(255, 255, 255, .95)',  borderRadius: '1.5em'}}>
          <div style={{padding: '1em 1em', borderBottom: '1px solid #ccc'}}>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h4' color='blue' style={{fontWeight: 'bold'}}>
                  {booked && booked.agentAvatar!='-' && <Image avatar src='https://www.shareicon.net/data/512x512/2016/05/24/770117_people_512x512.png' style={{margin: '0'}}/> }
                  {(!booked || (booked && booked.agentAvatar=='-')) && <Icon name='user circle' style={{fontSize: '2.1em'}} color={(this.props.phoneState=='oncall' && 'blue') || 'grey'}/>}
                  <Header.Content>
                    {(booked && booked.agentName) || 'Virtual Agent'}
                    <Header.Subheader>Interactive / Autonomous Call</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div>
                {/* <Button size='tiny' icon onClick={this.props.setMode.bind(this, 'rate')} >
                  <Icon name='close' color='red' />
                </Button> */}
              </div>
            </div>
          </div>

          {this.props.channel=='Video' && this.state.video && <div style={{padding: '1em', borderTop: '1px solid #fff', borderBottom: '1px solid #ccc'}}>
            <div style={{height: 180, width: 222}}>
                <div style={{position: 'fixed'}}>
                  <OmniwidgetVideo  width={222} height={180} remoteVideoStream= {this.props.remoteVideoStream} localVideoStream={this.props.localVideoStream}/>
                </div>
            </div>
          </div>}

          <div style={{padding: '1em', borderTop: '1px solid #fff'}}>
            <div style={{textAlign: 'center', paddingTop: this.state.video ? 0 : '2em'}}>
              <Button disabled={this.props.channel!='Video'} style={{borderRadius: '.5em'}} color={this.state.video ? 'pink' : 'grey'} size='small' icon onClick={this.props.remoteVideoStream ? this.showVideo.bind(this) : null}><Icon name={this.state.video ? 'video' : 'microphone'} style={{fontSize: '1.3em'}}/></Button>
              <Header as='h3' icon style={{margin: '.5em 0 0 0'}}>
                <Header.Content>
                  <Header.Subheader>For continous improvement, <br />your conversation is recorded.</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div>
              <OmniwidgetDial dtmfSend={this.props.dtmfSend.bind(this)} />
            </div>
            <div style={{padding: '1em', textAlign: 'center'}}>
              <Divider style={{margin: '0 0 1.5em 0'}} />
              <Button size='massive' icon color='red' circular onClick={this.callHangup.bind(this)}>
                <Icon name='phone' style={{transform: 'rotate(0deg)'}}/>
              </Button>
            </div>
            <Divider />
            <div>
              <Header as='h5' style={{fontWeight: 'bold', margin: '0'}}>
                <Header.Content style={{textTransform: 'capitalize'}}>
                  {(phoneState=='oncall' && 'On Call') || phoneState}
                </Header.Content>
              </Header>
            </div>
          </div>
        </div>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (OmniwidgetCall)