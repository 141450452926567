import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetScript(objParam) {
  return {
      type: 'SCRIPT_RESET',
      payload: objParam
  }
}

export function getScript(objParam) {
  return {
      type: 'SCRIPT_GET',
      payload:
        axios.post(
          urlAPI + 'script/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusScript(objParam) {
  return {
      type: 'SCRIPT_STATUS',
      payload:
        axios.post(
          urlAPI + 'script/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseScript(objParam) {
  return {
      type: 'SCRIPT_BROWSE',
      payload:
        axios.post(
          urlAPI + 'script/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createScript(objParam) {
  return {
      type: 'SCRIPT_CREATE',
      payload:
        axios.post(
          urlAPI + 'script/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateScript(objParam) {
  return {
      type: 'SCRIPT_UPDATE',
      payload:
        axios.post(
          urlAPI + 'script/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeScript(objParam) {
  return {
      type: 'SCRIPT_REMOVE',
      payload:
        axios.post(
          urlAPI + 'script/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
