import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table, Feed, Label } from 'semantic-ui-react'

import DismissableMessage from '../../../common/DismissableMessage'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, LabelSelectField } from '../../../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../../../validation/validation'

import { connect } from "react-redux"
import { checkToken } from "../../../../actions/auth/authAction"
import { resetTicket, getTicket,updateTicket, createTicket } from "../../../../actions/service/ticketAction"
import { resetInteraction, getInteraction,updateInteraction, createInteraction } from "../../../../actions/service/interactionAction"
import { listUser, listWorkgroup, listResolution, listRemark } from "../../../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ActionBox')

const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    masterStore: state.masterStore,

    ticketStore: state.ticketStore,
    interactionStore: state.interactionStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),

    listUser:(objParam) => dispatch(listUser(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listResolution:(objParam) => dispatch(listResolution(objParam)),
    listRemark:(objParam) => dispatch(listRemark(objParam)),

    resetTicket:(objParam) => dispatch(resetTicket(objParam)),
    getTicket:(objParam) => dispatch(getTicket(objParam)),
    createTicket:(objParam) => dispatch(createTicket(objParam)),
    updateTicket:(objParam) => dispatch(updateTicket(objParam)),

    resetInteraction:(objParam) => dispatch(resetInteraction(objParam)),
    getInteraction:(objParam) => dispatch(getInteraction(objParam)),
    createInteraction:(objParam) => dispatch(createInteraction(objParam)),
    updateInteraction:(objParam) => dispatch(updateInteraction(objParam)),
  }
}

class ActionBox extends Component {
  constructor(props) {
    super(props)
    this.state= {
      privacy: 'internal',
    }
  }

  componentDidMount(){
    // console.log('!!! DID MOUNT', this.props.ticket, this.props.action)
    
    this.props.resetInteraction(this.props.mode)
    // if (this.props.mode==2) { //modify update interaction NOT REQUIRED
    //   this.props.getInteraction({interactionId: this.props.interactionId})
    // }
    this.props.listUser()
    this.props.listWorkgroup()
    this.props.listRemark()
    this.props.listResolution()
  }

  componentDidUpdate() {
    // const { progress, code, text }= (this.props.modify ? (this.props.interactionStore.updateInteraction!=undefined ? this.props.interactionStore.updateInteraction : {}) : (this.props.interactionStore.createInteraction!=undefined ? this.props.interactionStore.createInteraction : {}))
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    values['action']= ((this.props.modify && 'modify') || 'update');
    values['owner']= this.checkRole();
    values['privacy']= this.state.privacy;
    values['ticketId']= this.props.ticket.ticketId

    this.props.modify ? this.props.updateInteraction({...values}) : this.props.createInteraction({...values})
  }

  checkRole() {
    const token= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken.data : {}
    const ticket= this.props.ticket
    if (ticket) {
      //requesterUserId, requesterWorkgroupId, dispatcherUserId, assigneeUserId, assigneeWorkgroupId, assigneeUsers, assigneeWorkgroups, 
      // escalationUserId, escalationWorkgroupId, escalationUsers,escalationWorkgroups, mentionUsers, mentionWorkgroups
      return(
        (ticket.escalationUserId==token.userId && 'escalation') ||  
        (ticket.escalationUsers && ticket.escalationUsers.indexOf(token.userId)>=0 && 'escalation') || 
        (ticket.assigneeUserId==token.userId && 'assignee') || 
        (ticket.requesterUserId==token.userId && 'requester') || 
        (ticket.dispatcherUserId==token.userId && 'dispatcher') || 
        (ticket.mentionUsers && ticket.mentionUsers.indexOf(token.userId)>=0 && 'follower') || 'follower'
      )
    }
  }

  onChangeResult(v) {
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})

    // this.props.dispatch(change('ActionBox', 'progress', listRemark.progress[v]));
    // this.props.dispatch(change('ActionBox', 'action', 'Update'));

    // this.props.dispatch(change('ActionBox', 'owner', 'assignee'));
    // this.props.dispatch(change('ActionBox', 'privacy', 'internal'));

    // this.props.dispatch(change('ActionBox', 'requesterId', 'Update'));
    // this.props.dispatch(change('ActionBox', 'requesterUserId', 'Update'));
    // this.props.dispatch(change('ActionBox', 'assigneeUserId', 'Update'));
    // this.props.dispatch(change('ActionBox', 'dispatcherUserId', 'Update'));
    // this.props.dispatch(change('ActionBox', 'escalationUserId', 'Update'));
  }

  setPrivacy(v) {
    this.setState({
      privacy: v,
    })
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style, view, modify, update, ticket } = this.props
    
    const { progress, code, text }= (mode==2 ? (this.props.interactionStore.updateInteraction!=undefined ? this.props.interactionStore.updateInteraction : {}) : (this.props.interactionStore.createInteraction!=undefined ? this.props.interactionStore.createInteraction : {}))

    const token= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken.data : {}
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : [])
    const listResolution= (this.props.masterStore.listResolution!=undefined && this.props.masterStore.listResolution.progress=='1' ? this.props.masterStore.listResolution.data : [])

    const users= new Map();
    const workgroups= new Map();

    listUser.map(item=>{
      users.set(item.value, item)
    })
    listWorkgroup.map(item=>{
      workgroups.set(item.value, item)
    })
    
    return (
      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <Header as='h5'>
          <Icon name={(view && 'sticky note outline') || 'wizard'} circular inverted color={ticket ? 'yellow' : 'blue'} style={{fontSize: '1.1em'}} />
          <Header.Content>
            {(view && 'View Update') || (modify && 'Modify Update') || (update && 'Update Ticket') || 'Action / Handling'}
            <Header.Subheader>{(view && 'View current ticket update.') || (modify && 'Modify current ticket update.') || 'Do action for ticket update.'}</Header.Subheader>
          </Header.Content>
        </Header>
        {view && this.props.action && <>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                By <b>{this.props.action.user && this.props.action.user.name}</b>
                <Header.Subheader>As <b>{this.props.action.owner}</b> at <b>{this.props.action.updatedStamp ? format(parse(this.props.action.updatedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm, dd/MMM yyyy') : 'unknown time'}</b></Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            {this.props.action.mentionUsers && this.props.action.mentionUsers.length>0 && <>
              <div style={{display: 'flex', border: '1px solid #ccc', borderRadius: '.3em'}}>
                <div style={{padding: '.5em .5em', borderRight: '1px solid #ccc', fontWeight: 'bold', fontSize: '1.1em'}}>
                  Share To:
                </div>
                <div style={{flex: 1, padding: '.5em .5em 0 .5em'}}>
                  <Label.Group>
                    {this.props.action.mentionUsers && this.props.action.mentionUsers.map(item=>{
                      return(
                        <Label>{(users.get(item)||{}).text}</Label>
                      )
                    })}
                  </Label.Group>
                </div>
              </div>
              <Divider />
            </>}
            {this.props.action.escalationWorkgroups && this.props.action.escalationWorkgroups.length>0 && <>
              <div style={{display: 'flex', border: '1px solid #ccc', borderRadius: '.3em'}}>
                <div style={{padding: '.5em .5em', borderRight: '1px solid #ccc', fontWeight: 'bold', fontSize: '1.1em'}}>
                  Esc. To:
                </div>
                <div style={{flex: 1, padding: '.5em .5em 0 .5em'}}>
                  <Label.Group>
                    {this.props.action.escalationWorkgroups && this.props.action.escalationWorkgroups.map(item=>{
                      return(
                        <Label>{(workgroups.get(item)||{}).text}</Label>
                      )
                    })}
                  </Label.Group>
                </div>
              </div>
              <Divider />
            </>}
            <b style={{textTransform: 'capitalize'}}>{this.props.action.privacy}</b>
            <div style={{fontSize: '1.3em'}}>
              {this.props.action.activity}
            </div>
            <Divider />
          </Segment>
          <Segment>
            <div style={{display:'flex'}}>
              <div style={{width: '10em', paddingRight: '1em', borderRight: '1px solid #ccc', textAlign: 'center'}}>
                <Label color={(this.props.action && this.props.action.remark_ && this.props.action.remark_.color) || 'black'} size='big' className='fluid'>
                  {(this.props.action && this.props.action.remark) || 'Progress'}
                </Label>
              </div>
              <div style={{flex: 1, paddingLeft: '1em'}}>
                <Header as='h4'>
                  <Header.Content>
                    {(this.props.action && this.props.action.resolution && this.props.action.resolution.name) || 'No Resolution'}
                    <Header.Subheader style={{fontWeight: 'bold', textTransform: 'uppercase'}}>Action: {this.props.action && this.props.action.action}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </Segment>
        </>}
        {modify && <>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                By <b>{token.name}</b>, {token.groupId}
                <Header.Subheader>As <b>{this.checkRole()}</b> at <b>{format(new Date(), 'HH:mm')}</b> {format(new Date(), 'dd/MMM yyyy')}</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Form.Group widths='equal'>
              <Field name='activity' component={TextAreaField}
              label='Activity'
              rows={6}
              placeholder='Describe activity here ...' />
            </Form.Group>
            <Divider />
            Disclaimer: Previous update will be saved as history.
          </Segment>
          <Segment>
            <div style={{display:'flex'}}>
              <div style={{width: '10em', paddingRight: '1em', borderRight: '1px solid #ccc'}}>
                <Label color={(this.props.action && this.props.action.remark_ && this.props.action.remark_.color) || 'black'} size='big' className='fluid'>
                  Closed
                </Label>
              </div>
              <div style={{flex: 1, paddingLeft: '1em'}}>
                <Header as='h5'>
                  <Header.Content>
                    -
                    <Header.Subheader>No resolution defined.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </Segment>
        </>}
        {update && <>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                By <b>{token.name}</b>, {token.groupId}
                <Header.Subheader>As <b>{this.checkRole()}</b> at <b>{format(new Date(), 'HH:mm')}</b> {format(new Date(), 'dd/MMM yyyy')}</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Form.Group widths='equal'>
              <Field name='mentionUsers' component={SelectField}
              multiple
              options={listUser}
              label={<b>Mentions</b>}
              placeholder='Set mentions here ...' />
            </Form.Group>

            <div style={{display: 'flex', marginTop: '2em'}}>
              <div style={{flex: 1}}>
                <Header as='h5' color='blue'>
                  <Header.Content>
                    <b>Update {this.state.privacy=='internal' ? 'Activity' : 'Information'}</b>
                    <Header.Subheader></Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div>
                <Button.Group size='tiny'>
                  <Popup content='Internal Only'  position= 'bottom center'
                  trigger={
                    <Button color={this.state.privacy=='internal' ? 'blue' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '3em'}} icon onClick={this.setPrivacy.bind(this, 'internal')}><Icon name='comments' /></Button>
                  }
                  inverted />
                  <Button.Or></Button.Or>
                  <Popup content='Public'  position= 'bottom center'
                  trigger={
                    <Button color={this.state.privacy=='public' ? 'red' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '3em'}} icon onClick={this.setPrivacy.bind(this, 'public')}><Icon name='envelope open' /></Button>
                  }
                  inverted />
                </Button.Group>
              </div>
            </div>
            <Form.Group widths='equal'>
              <Field name='activity' component={TextAreaField}
              // label='Activity'
              rows={6}
              placeholder='Describe activity here ...' />
            </Form.Group>
          </Segment>
          
          <Segment>
            <Form.Group widths='equal'>
              <Field name='remark' component={SelectField} onChange={this.onChangeResult.bind(this)}
              options={listRemark}
              label={<b>Status</b>}
              placeholder='Status / remark' />
              <Field name='resolution' component={SelectField}
              options={listResolution}
              label={<b>Resolution</b>}
              placeholder='Solving resolution' />
            </Form.Group>
          </Segment>

          <Segment>
            <Header as='h5'>
              <b>Assets / Resources</b>
              <Header.Subheader>Upload document and images here.</Header.Subheader>
            </Header>
            <Divider />
          </Segment>
        </>}

        {(modify || update) && <Segment>
          {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(modify ? 'Modify Ticket Update' : 'Create Ticket Update') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
          
          <div style={{textAlign: 'right'}}>
            <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
            <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
          </div>
        </Segment>}

      </Form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.interactionStore.getInteraction!=undefined && state.interactionStore.getInteraction.progress==1 ? state.interactionStore.getInteraction.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: getInteraction }
  )
  (reduxForm({
    form: 'ActionBox',	// a unique identifier for this form
  })(ActionBox)
  )
)
