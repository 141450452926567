export default function mauReducer (state = {}, action) {
  switch (action.type) {
    case 'MAU_RESET': {
        return({
            ...state,
            getMau: {},
            updateMau: {},
            createMau: {},
        })
    }

    case 'MAU_GET_PENDING': {
        return({...state, getMau: {progress: 0}})
    }
    case 'MAU_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getMau: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getMau: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MAU_GET_REJECTED': {
        return({...state, getMau: {progress: 2, payload: action.payload}})
    }

    case 'MAU_STATUS_PENDING': {
      return({...state, statusMau: {progress: 0}})
    }
    case 'MAU_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusMau: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusMau: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MAU_STATUS_REJECTED': {
        return({...state, statusMau: {progress: 2, payload: action.payload}})
    }

    case 'MAU_BROWSE_PENDING': {
      return({...state, browseMau: {progress: 0}})
    }
    case 'MAU_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseMau: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseMau: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MAU_BROWSE_REJECTED': {
        return({...state, browseMau: {progress: 2, payload: action.payload}})
    }

    case 'MAU_CREATE_PENDING': {
      return({...state, browseMau: {progress: 0}})
    }
    case 'MAU_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createMau: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createMau: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MAU_CREATE_REJECTED': {
        return({...state, createMau: {progress: 2, payload: action.payload}})
    }

    case 'MAU_UPDATE_PENDING': {
      return({...state, updateMau: {progress: 0}})
    }
    case 'MAU_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateMau: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateMau: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MAU_UPDATE_REJECTED': {
        return({...state, updateMau: {progress: 2, payload: action.payload}})
    }

    case 'MAU_REMOVE_PENDING': {
      return({...state, removeMau: {progress: 0}})
    }
    case 'MAU_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeMau: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeMau: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MAU_REMOVE_REJECTED': {
        return({...state, removeMau: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}