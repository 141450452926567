export default function workgroupReducer (state = {}, action) {
  switch (action.type) {
    case 'LIST_RESET': {
        return({
            ...state,
            getWorkgroup: {},
            updateWorkgroup: {},
            createWorkgroup: {},
        })
    }

    case 'LIST_GET_PENDING': {
        return({...state, getWorkgroup: {progress: 0}})
    }
    case 'LIST_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getWorkgroup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'LIST_GET_REJECTED': {
        return({...state, getWorkgroup: {progress: 2, payload: action.payload}})
    }

    case 'LIST_STATUS_PENDING': {
      return({...state, statusWorkgroup: {progress: 0}})
    }
    case 'LIST_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusWorkgroup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'LIST_STATUS_REJECTED': {
        return({...state, statusWorkgroup: {progress: 2, payload: action.payload}})
    }

    case 'LIST_BROWSE_PENDING': {
      return({...state, browseWorkgroup: {progress: 0}})
    }
    case 'LIST_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseWorkgroup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'LIST_BROWSE_REJECTED': {
        return({...state, browseWorkgroup: {progress: 2, payload: action.payload}})
    }

    case 'LIST_CREATE_PENDING': {
      return({...state, browseWorkgroup: {progress: 0}})
    }
    case 'LIST_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createWorkgroup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'LIST_CREATE_REJECTED': {
        return({...state, createWorkgroup: {progress: 2, payload: action.payload}})
    }

    case 'LIST_UPDATE_PENDING': {
      return({...state, updateWorkgroup: {progress: 0}})
    }
    case 'LIST_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateWorkgroup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'LIST_UPDATE_REJECTED': {
        return({...state, updateWorkgroup: {progress: 2, payload: action.payload}})
    }

    case 'LIST_REMOVE_PENDING': {
      return({...state, removeWorkgroup: {progress: 0}})
    }
    case 'LIST_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeWorkgroup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'LIST_REMOVE_REJECTED': {
        return({...state, removeWorkgroup: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}