import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Item,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Progress,
  Pagination,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { summaryCampaign } from "../../../actions/stream/campaignAction"
import CampaignWidget from '../../widget/CampaignWidget';


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    campaignStore: state.campaignStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    summaryCampaign:(objParam) => dispatch(summaryCampaign(objParam)),
  }
};

class CampaignStatistic extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
      };
    }
  
    componentDidMount() {
      this.props.summaryCampaign({campaignId: this.props.campaignId})
    }

    render() {
      const {screenWidth, screenHeight}= this.props
      const { data, search, paging }= (this.props.campaignStore.summaryCampaign!==undefined ? this.props.campaignStore.summaryCampaign : {})
      
      // console.log(data)
      return(
        <div style={{padding: '1em 1em', minHeight: screenHeight}}>
          <Item.Group>
            { data && <CampaignWidget item={data} screenWidth={screenWidth} />}
            { !data && <Segment><Icon name='circle notch' loading />loading ...</Segment>}
          </Item.Group>
        </div>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignStatistic)
