import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label, Popup } from 'semantic-ui-react'

class InstagramProfile extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      return(
        <div>
          <Grid>
            <Grid.Row width={16}>
              <Grid.Column width={6}>
                <Segment style={{textAlign: 'center'}}>
                  <Image src='https://react.semantic-ui.com/images/wireframe/square-image.png' size='medium' circular centered />
                </Segment>
              </Grid.Column>
              <Grid.Column width={10}>
                <Segment style={{minHeight: screenHeight/2}}>
                  <Header as='h5'>
                    <Header.Content>
                      <Header.Subheader>Name</Header.Subheader>
                      John Doe
                    </Header.Content>
                  </Header>
                  <Header as='h5'>
                    <Header.Content>
                      <Header.Subheader>Account</Header.Subheader>
                      John Doe
                    </Header.Content>
                  </Header>
                  <Header as='h5'>
                    <Header.Content>
                      <Header.Subheader>Location</Header.Subheader>
                      Jakarta
                    </Header.Content>
                  </Header>
                  <Header as='h5'>
                    <Header.Content>
                      <Header.Subheader>Description</Header.Subheader>
                      Description
                    </Header.Content>
                  </Header>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      )
    }
}

export default InstagramProfile
