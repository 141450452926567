export default function watchdogReducer (state = {}, action) {
  switch (action.type) {
    case 'WATCHDOG_RESET': {
        return({
            ...state,
            getWatchdog: {},
            updateWatchdog: {},
            createWatchdog: {},
        })
    }

    case 'WATCHDOG_GET_PENDING': {
        return({...state, getWatchdog: {progress: 0}})
    }
    case 'WATCHDOG_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getWatchdog: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getWatchdog: {...action.payload.data, data: action.payload.data.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WATCHDOG_GET_REJECTED': {
        return({...state, getWatchdog: {progress: 2, payload: action.payload}})
    }

    case 'WATCHDOG_STATUS_PENDING': {
      return({...state, statusWatchdog: {progress: 0}})
    }
    case 'WATCHDOG_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusWatchdog: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusWatchdog: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WATCHDOG_STATUS_REJECTED': {
        return({...state, statusWatchdog: {progress: 2, payload: action.payload}})
    }

    case 'WATCHDOG_BROWSE_PENDING': {
      return({...state, browseWatchdog: {progress: 0}})
    }
    case 'WATCHDOG_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseWatchdog: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseWatchdog: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WATCHDOG_BROWSE_REJECTED': {
        return({...state, browseWatchdog: {progress: 2, payload: action.payload}})
    }

    case 'WATCHDOG_CREATE_PENDING': {
      return({...state, browseWatchdog: {progress: 0}})
    }
    case 'WATCHDOG_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createWatchdog: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createWatchdog: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WATCHDOG_CREATE_REJECTED': {
        return({...state, createWatchdog: {progress: 2, payload: action.payload}})
    }

    case 'WATCHDOG_UPDATE_PENDING': {
      return({...state, updateWatchdog: {progress: 0}})
    }
    case 'WATCHDOG_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateWatchdog: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateWatchdog: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WATCHDOG_UPDATE_REJECTED': {
        return({...state, updateWatchdog: {progress: 2, payload: action.payload}})
    }

    case 'WATCHDOG_REMOVE_PENDING': {
      return({...state, removeWatchdog: {progress: 0}})
    }
    case 'WATCHDOG_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeWatchdog: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeWatchdog: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WATCHDOG_REMOVE_REJECTED': {
        return({...state, removeWatchdog: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}