import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import KnowledgeBrowse from '../../libraries/agent/km/KnowledgeBrowse'
import KnowledgeSearch from '../../libraries/agent/km/KnowledgeSearch'


class KnowledgeBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        layout: 1,
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        knowledgeId: 0,
      };
    }

    setMode(mode, id, param) {
      this.setState({mode: mode, knowledgeId: id, param: param})
    }

    setLayout(v) {
      this.setState({layout: v})
    }

    closeModal(pristine) { //true: no data change, false: data been changed
      if (pristine===false) {
        console.log(pristine)
        this.setState({
          modal: this.state.modal===1 ? 0 : 1, //1: confirmation
        });
      } else {
        this.setState({
          mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
          knowledgeId: 0,

          modal: 0,
        });
      }
    }
    render() {
      const {mode, knowledgeId, param, modal, layout}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <div style={{padding: '1em'}}>
            {/* <KnowledgeSearch layout={layout} mode={mode} screenWidth={screenWidth} setLayout={this.setLayout.bind(this)} setMode={this.setMode.bind(this)} /> */}
            <KnowledgeBrowse layout={layout} mode={mode} screenHeight={screenHeight} screenWidth={screenWidth} setLayout={this.setLayout.bind(this)} setMode={this.setMode.bind(this)} />
          </div>
        </div>
      )
    }
}

export default KnowledgeBody
