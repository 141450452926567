import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import { loggerOptions }  from '../../common/StaticMasterData'
import DismissableMessage from '../../common/DismissableMessage'

import { SubmissionApilog, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField, TextAreaField, LabelNumberField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'
import { connect } from "react-redux"
import { browseApilog, resetApilog, getApilog,updateApilog, createApilog } from "../../../actions/system/apilogAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    apilogStore: state.apilogStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseApilog:(objParam) => dispatch(browseApilog(objParam)),

    resetApilog:(objParam) => dispatch(resetApilog(objParam)),
    getApilog:(objParam) => dispatch(getApilog(objParam)),
    createApilog:(objParam) => dispatch(createApilog(objParam)),
    updateApilog:(objParam) => dispatch(updateApilog(objParam)),
  }
}

class ApilogForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetApilog(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getApilog({loggerId: this.props.loggerId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.apilogStore.updateApilog!=undefined ? this.props.apilogStore.updateApilog : {}) : (this.props.apilogStore.createApilog!=undefined ? this.props.apilogStore.createApilog : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  componentWillUnmount() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.apilogStore.updateApilog!=undefined ? this.props.apilogStore.updateApilog : {}) : (this.props.apilogStore.createApilog!=undefined ? this.props.apilogStore.createApilog : {}))
    if (progress==1 && code=='00') {
      const { search, paging }= (this.props.apilogStore.browseApilog!==undefined ? this.props.apilogStore.browseApilog : {})
      this.props.browseApilog({search: {...search}, paging: paging})
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, loggerId } = this.props
    mode==2 ? this.props.updateApilog({...values}) : this.props.createApilog({...values})
  }
  

  render() {
    const { apilog, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.apilogStore.updateApilog!=undefined ? this.props.apilogStore.updateApilog : {}) : (this.props.apilogStore.createApilog!=undefined ? this.props.apilogStore.createApilog : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths={16}>
              <Field name='actor' component={InputField}
              width={4}
              label='actor'
              placeholder='actor' />
              <Field name='group' component={InputField}
              width={4}
              label='group'
              placeholder='group' />
              <Field name='host' component={InputField}
              width={4}
              label='host'
              placeholder='host' />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group widths='equal'>
              <Field name='period' component={InputField}
              label='Period'
              placeholder='Period' />
              <Field name='execStart' component={InputField}
              label='Exec Start'
              placeholder='Exec Start' />
              <Field name='execFinish' component={InputField}
              label='Exec Finish'
              placeholder='Exec Finish' />
              <Field name='execDuration' component={LabelNumberField}
              label='mSec'
              caption='Exec Duration'
              placeholder='Exec Duration' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='scope' component={SelectField}
              options={loggerOptions('scope')}
              label='Scope'
              placeholder='Scope' />
              <Field name='event' component={SelectField}
              options={loggerOptions('event')}
              label='Event'
              placeholder='Event' />
              <Field name='action' component={InputField}
              label='Action'
              placeholder='Action' />
              <Field name='method' component={InputField}
              label='Method'
              placeholder='Method' />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group widths='16'>
              <Field name='status' component={InputField}
              width={4}
              label='Status'
              placeholder='Status' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='params' component={InputField}
              width={8}
              label='Params'
              placeholder='Params' />
              <Field name='outputs' component={InputField}
              width={8}
              label='Outputs'
              placeholder='Outputs' />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group widths='equal'>
              <Field name='info' component={InputField}
              label='Info'
              placeholder='Info' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='warning' component={InputField}
              label='Warning'
              placeholder='Warning' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='severe' component={InputField}
              label='Severe'
              placeholder='Severe' />
            </Form.Group>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Apilog' : 'Insert Apilog') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.apilogStore.getApilog!=undefined && state.apilogStore.getApilog.progress==1 ? state.apilogStore.getApilog.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'ApilogForm',	// a unique identifier for this form
  })(ApilogForm)
  )
)