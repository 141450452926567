export default function tunnelReducer (state = {}, action) {
  switch (action.type) {
    case 'TUNNEL_RESET': {
        return({
            ...state,
            getTunnel: {},
            updateTunnel: {},
            createTunnel: {},
        })
    }

    case 'TUNNEL_GET_PENDING': {
        return({...state, getTunnel: {progress: 0}})
    }
    case 'TUNNEL_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getTunnel: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getTunnel: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TUNNEL_GET_REJECTED': {
        return({...state, getTunnel: {progress: 2, payload: action.payload}})
    }

    case 'TUNNEL_STATUS_PENDING': {
      return({...state, statusTunnel: {progress: 0}})
    }
    case 'TUNNEL_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusTunnel: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusTunnel: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TUNNEL_STATUS_REJECTED': {
        return({...state, statusTunnel: {progress: 2, payload: action.payload}})
    }

    case 'TUNNEL_BROWSE_PENDING': {
      return({...state, browseTunnel: {progress: 0}})
    }
    case 'TUNNEL_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseTunnel: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseTunnel: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TUNNEL_BROWSE_REJECTED': {
        return({...state, browseTunnel: {progress: 2, payload: action.payload}})
    }

    case 'TUNNEL_CREATE_PENDING': {
      return({...state, browseTunnel: {progress: 0}})
    }
    case 'TUNNEL_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createTunnel: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createTunnel: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TUNNEL_CREATE_REJECTED': {
        return({...state, createTunnel: {progress: 2, payload: action.payload}})
    }

    case 'TUNNEL_UPDATE_PENDING': {
      return({...state, updateTunnel: {progress: 0}})
    }
    case 'TUNNEL_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateTunnel: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateTunnel: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TUNNEL_UPDATE_REJECTED': {
        return({...state, updateTunnel: {progress: 2, payload: action.payload}})
    }

    case 'TUNNEL_REMOVE_PENDING': {
      return({...state, removeTunnel: {progress: 0}})
    }
    case 'TUNNEL_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeTunnel: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeTunnel: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TUNNEL_REMOVE_REJECTED': {
        return({...state, removeTunnel: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}