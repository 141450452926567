export default function assignReducer (state = {}, action) {
  switch (action.type) {
    case 'ASSIGN_RESET': {
        return({
            ...state,
            getAssign: {},
            updateAssign: {},
            createAssign: {},
            agentAssign: {},
            workgroupAssign: {},
        })
    }

    case 'ASSIGN_BROWSE_PENDING': {
      return({...state, browseAssign: {progress: 0}})
    }
    case 'ASSIGN_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseAssign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseAssign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ASSIGN_BROWSE_REJECTED': {
        return({...state, browseAssign: {progress: 2, payload: action.payload}})
    }
    
    case 'ASSIGN_GET_PENDING': {
        return({...state, getAssign: {progress: 0}})
    }
    case 'ASSIGN_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getAssign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getAssign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ASSIGN_GET_REJECTED': {
        return({...state, getAssign: {progress: 2, payload: action.payload}})
    }

    case 'ASSIGN_STATUS_PENDING': {
      return({...state, statusAssign: {progress: 0}})
    }
    case 'ASSIGN_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusAssign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusAssign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ASSIGN_STATUS_REJECTED': {
        return({...state, statusAssign: {progress: 2, payload: action.payload}})
    }

    case 'ASSIGN_CREATE_PENDING': {
      return({...state, createAssign: {progress: 0}})
    }
    case 'ASSIGN_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createAssign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createAssign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ASSIGN_CREATE_REJECTED': {
        return({...state, createAssign: {progress: 2, payload: action.payload}})
    }

    case 'ASSIGN_UPDATE_PENDING': {
      return({...state, updateAssign: {progress: 0}})
    }
    case 'ASSIGN_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateAssign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateAssign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ASSIGN_UPDATE_REJECTED': {
        return({...state, updateAssign: {progress: 2, payload: action.payload}})
    }

    case 'ASSIGN_REMOVE_PENDING': {
      return({...state, removeAssign: {progress: 0}})
    }
    case 'ASSIGN_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeAssign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeAssign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ASSIGN_REMOVE_REJECTED': {
        return({...state, removeAssign: {progress: 2, payload: action.payload}})
    }

    case 'ASSIGN_AGENT_PENDING': {
      return({...state, agentAssign: {progress: 0}})
    }
    case 'ASSIGN_AGENT_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, agentAssign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, agentAssign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ASSIGN_AGENT_REJECTED': {
        return({...state, agentAssign: {progress: 2, payload: action.payload}})
    }

    case 'ASSIGN_WORKGROUP_PENDING': {
      return({...state, workgroupAssign: {progress: 0}})
    }
    case 'ASSIGN_WORKGROUP_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, workgroupAssign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, workgroupAssign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ASSIGN_WORKGROUP_REJECTED': {
        return({...state, workgroupAssign: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}