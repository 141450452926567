export default function socialReducer (state = {}, action) {
  switch (action.type) {
    case 'SOCIAL_RESET': {
        return({
            ...state,
            getSocial: {},
            updateSocial: {},
            createSocial: {},
        })
    }

    case 'SOCIAL_BROWSE_PENDING': {
      return({...state, browseSocial: {progress: 0}})
    }
    case 'SOCIAL_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseSocial: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseSocial: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCIAL_BROWSE_REJECTED': {
        return({...state, browseSocial: {progress: 2, payload: action.payload}})
    }
    
    case 'SOCIAL_GET_PENDING': {
        return({...state, getSocial: {progress: 0}})
    }
    case 'SOCIAL_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getSocial: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getSocial: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCIAL_GET_REJECTED': {
        return({...state, getSocial: {progress: 2, payload: action.payload}})
    }

    case 'SOCIAL_STATUS_PENDING': {
      return({...state, statusSocial: {progress: 0}})
    }
    case 'SOCIAL_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusSocial: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusSocial: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCIAL_STATUS_REJECTED': {
        return({...state, statusSocial: {progress: 2, payload: action.payload}})
    }

    case 'SOCIAL_CREATE_PENDING': {
      return({...state, createSocial: {progress: 0}})
    }
    case 'SOCIAL_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createSocial: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createSocial: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCIAL_CREATE_REJECTED': {
        return({...state, createSocial: {progress: 2, payload: action.payload}})
    }

    case 'SOCIAL_UPDATE_PENDING': {
      return({...state, updateSocial: {progress: 0}})
    }
    case 'SOCIAL_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateSocial: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateSocial: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCIAL_UPDATE_REJECTED': {
        return({...state, updateSocial: {progress: 2, payload: action.payload}})
    }

    case 'SOCIAL_REMOVE_PENDING': {
      return({...state, removeSocial: {progress: 0}})
    }
    case 'SOCIAL_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeSocial: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeSocial: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCIAL_REMOVE_REJECTED': {
        return({...state, removeSocial: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}