import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format } from "date-fns"
import { MediaPermissionsError, MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';

import Linkwidget from '../../linkwidget/Linkwidget';


class LinkcallWidget extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.conferenceStore.browseConference!==undefined ? this.props.conferenceStore.browseConference : {})
    this.props.browseConference(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    // const { search, paging }= (this.props.conferenceStore.browseConference!==undefined ? this.props.conferenceStore.browseConference : {})
    // this.props.browseConference({search: {...search}, paging: paging})

  // requestMediaPermissions({audio: true, video: false})
	// .then(() => {
		// can successfully access camera and microphone streams
		// DO SOMETHING HERE

  //   console.log('MEDIA PERMISSION OK')
	// })
	// .catch((err) => {
  //   console.log('MEDIA PERMISSION NOK', err)
	// 	const { type, name, message } = err;
		// if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
		// 	// browser does not have permission to access camera or microphone
		// } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
		// 	// user didn't allow app to access camera or microphone
		// } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
		// 	// camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
		// 	// (mostly Windows specific problem)
		// } else {
		// 	// not all error types are handled by this library
		// }
	// });

    // // another way to request AUDIO only...
    // requestMediaPermissions({audio: true, video: false})
    // .then(() => {})
    // .catch((err: MediaPermissionsError) => {});

    // // Requesting VIDEO permission only:
    // requestVideoPermissions()
    // .then(() => {})
    // .catch((err: MediaPermissionsError) => {});

    // // another way to request VIDEO only...
    // requestMediaPermissions({audio: false, video: true})
    // .then(() => {})
    // .catch((err: MediaPermissionsError) => {});
  }

  render() {
    const { screenWidth, screenHeight, conferenceId, params }= this.props
    return (<div style={{padding: '3em 0'}}>
      <Linkwidget conferenceId={conferenceId} screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} />
    </div>);
  }
}

export default LinkcallWidget
