export default function masterReducer (state = {}, action) {
  switch (action.type) {

    case 'CLIENT_LIST_PENDING': {
      return({...state, listClient: {progress: 0}})
    }
    case 'CLIENT_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listClient: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listClient: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'CLIENT_LIST_REJECTED': {
      return({...state, listClient: {progress: 2, payload: action.payload}})
    }

    case 'USER_LIST_PENDING': {
      return({...state, listUser: {progress: 0}})
    }
    case 'USER_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listUser: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listUser: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'USER_LIST_REJECTED': {
      return({...state, listUser: {progress: 2, payload: action.payload}})
    }

    case 'SUPPORT_LIST_PENDING': {
      return({...state, listSupport: {progress: 0}})
    }
    case 'SUPPORT_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listSupport: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listSupport: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'SUPPORT_LIST_REJECTED': {
      return({...state, listSupport: {progress: 2, payload: action.payload}})
    }

    case 'AGENT_LIST_PENDING': {
      return({...state, listAgent: {progress: 0}})
    }
    case 'AGENT_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listAgent: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listAgent: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'AGENT_LIST_REJECTED': {
      return({...state, listAgent: {progress: 2, payload: action.payload}})
    }
    
    case 'TEAMLEADER_LIST_PENDING': {
      return({...state, listTeamleader: {progress: 0}})
    }
    case 'TEAMLEADER_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listTeamleader: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listTeamleader: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'TEAMLEADER_LIST_REJECTED': {
      return({...state, listTeamleader: {progress: 2, payload: action.payload}})
    }

    case 'SUPERVISOR_LIST_PENDING': {
      return({...state, listSupervisor: {progress: 0}})
    }
    case 'SUPERVISOR_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listSupervisor: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listSupervisor: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'SUPERVISOR_LIST_REJECTED': {
      return({...state, listSupervisor: {progress: 2, payload: action.payload}})
    }

    case 'CONTROLLER_LIST_PENDING': {
      return({...state, listController: {progress: 0}})
    }
    case 'CONTROLLER_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listController: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listController: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'CONTROLLER_LIST_REJECTED': {
      return({...state, listController: {progress: 2, payload: action.payload}})
    }

    case 'MANAGER_LIST_PENDING': {
      return({...state, listManager: {progress: 0}})
    }
    case 'MANAGER_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listManager: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listManager: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'MANAGER_LIST_REJECTED': {
      return({...state, listManager: {progress: 2, payload: action.payload}})
    }

    case 'TEMPLATE_LIST_PENDING': {
      return({...state, listTemplate: {progress: 0}})
    }
    case 'TEMPLATE_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listTemplate: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listTemplate: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'TEMPLATE_LIST_REJECTED': {
      return({...state, listTemplate: {progress: 2, payload: action.payload}})
    }

    case 'PRODUCT_LIST_PENDING': {
      return({...state, listProduct: {progress: 0}})
    }
    case 'PRODUCT_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listProduct: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listProduct: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'PRODUCT_LIST_REJECTED': {
      return({...state, listProduct: {progress: 2, payload: action.payload}})
    }

    case 'WORKGROUP_LIST_PENDING': {
      return({...state, listWorkgroup: {progress: 0}})
    }
    case 'WORKGROUP_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listWorkgroup: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listWorkgroup: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'WORKGROUP_LIST_REJECTED': {
      return({...state, listWorkgroup: {progress: 2, payload: action.payload}})
    }

    case 'SEGMENT_LIST_PENDING': {
      return({...state, listSegment: {progress: 0}})
    }
    case 'SEGMENT_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listSegment: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listSegment: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'SEGMENT_LIST_REJECTED': {
      return({...state, listSegment: {progress: 2, payload: action.payload}})
    }
    
    case 'TICKET_LIST_PENDING': {
      return({...state, listTicket: {progress: 0}})
    }
    case 'TICKET_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listTicket: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listTicket: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'TICKET_LIST_REJECTED': {
      return({...state, listTicket: {progress: 2, payload: action.payload}})
    }
    
    case 'REMARK_LIST_PENDING': {
      return({...state, listRemark: {progress: 0}})
    }
    case 'REMARK_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listRemark: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listRemark: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'REMARK_LIST_REJECTED': {
      return({...state, listRemark: {progress: 2, payload: action.payload}})
    }

    case 'PARAMS_LIST_PENDING': {
      return({...state, listParams: {progress: 0}})
    }
    case 'PARAMS_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listParams: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listParams: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'PARAMS_LIST_REJECTED': {
      return({...state, listParams: {progress: 2, payload: action.payload}})
    }
    
    case 'CUSTOMER_LIST_PENDING': {
      return({...state, listCustomer: {progress: 0}})
    }
    case 'CUSTOMER_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listCustomer: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listCustomer: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'CUSTOMER_LIST_REJECTED': {
      return({...state, listCustomer: {progress: 2, payload: action.payload}})
    }

    case 'ORGANIZATION_LIST_PENDING': {
      return({...state, listOrganization: {progress: 0}})
    }
    case 'ORGANIZATION_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listOrganization: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listOrganization: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'ORGANIZATION_LIST_REJECTED': {
      return({...state, listOrganization: {progress: 2, payload: action.payload}})
    }

    case 'CLASSIFICATION_LIST_PENDING': {
      return({...state, listClassification: {progress: 0}})
    }
    case 'CLASSIFICATION_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listClassification: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listClassification: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'CLASSIFICATION_LIST_REJECTED': {
      return({...state, listClassification: {progress: 2, payload: action.payload}})
    }

    case 'RESOLUTION_LIST_PENDING': {
      return({...state, listResolution: {progress: 0}})
    }
    case 'RESOLUTION_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listResolution: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listResolution: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'RESOLUTION_LIST_REJECTED': {
      return({...state, listResolution: {progress: 2, payload: action.payload}})
    }

    case 'REASONING_LIST_PENDING': {
      return({...state, listReasoning: {progress: 0}})
    }
    case 'REASONING_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listReasoning: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listReasoning: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'REASONING_LIST_REJECTED': {
      return({...state, listReasoning: {progress: 2, payload: action.payload}})
    }

    case 'ACTIVITY_LIST_PENDING': {
      return({...state, listActivity: {progress: 0}})
    }
    case 'ACTIVITY_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listActivity: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listActivity: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'ACTIVITY_LIST_REJECTED': {
      return({...state, listActivity: {progress: 2, payload: action.payload}})
    }

    case 'FORM_LIST_PENDING': {
      return({...state, listForm: {progress: 0}})
    }
    case 'FORM_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listForm: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listForm: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'FORM_LIST_REJECTED': {
      return({...state, listForm: {progress: 2, payload: action.payload}})
    }

    case 'FIELD_LIST_PENDING': {
      return({...state, listField: {progress: 0}})
    }
    case 'FIELD_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listField: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listField: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'FIELD_LIST_REJECTED': {
      return({...state, listField: {progress: 2, payload: action.payload}})
    }

    case 'MACRO_LIST_PENDING': {
      return({...state, listMacro: {progress: 0}})
    }
    case 'MACRO_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listMacro: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listMacro: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'MACRO_LIST_REJECTED': {
      return({...state, listMacro: {progress: 2, payload: action.payload}})
    }

    case 'CHANNEL_LIST_PENDING': {
      return({...state, listChannel: {progress: 0}})
    }
    case 'CHANNEL_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listChannel: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listChannel: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'CHANNEL_LIST_REJECTED': {
      return({...state, listChannel: {progress: 2, payload: action.payload}})
    }

    case 'OFFICEHOUR_LIST_PENDING': {
      return({...state, listOfficeHour: {progress: 0}})
    }
    case 'OFFICEHOUR_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listOfficeHour: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listOfficeHour: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'OFFICEHOUR_LIST_REJECTED': {
      return({...state, listOfficeHour: {progress: 2, payload: action.payload}})
    }

    case 'BUCKET_LIST_PENDING': {
      return({...state, listBucket: {progress: 0}})
    }
    case 'BUCKET_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listBucket: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listBucket: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'BUCKET_LIST_REJECTED': {
      return({...state, listBucket: {progress: 2, payload: action.payload}})
    }

    case 'DASHBOARD_LIST_PENDING': {
      return({...state, listDashboard: {progress: 0}})
    }
    case 'DASHBOARD_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listDashboard: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listDashboard: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'DASHBOARD_LIST_REJECTED': {
      return({...state, listDashboard: {progress: 2, payload: action.payload}})
    }

    case 'REPORT_LIST_PENDING': {
      return({...state, listReport: {progress: 0}})
    }
    case 'REPORT_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listReport: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listReport: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'REPORT_LIST_REJECTED': {
      return({...state, listReport: {progress: 2, payload: action.payload}})
    }





    case 'HANDLINGS_LIST_PENDING': {
      return({...state, listHandlings: {progress: 0}})
    }
    case 'HANDLINGS_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listHandlings: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listHandlings: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'HANDLINGS_LIST_REJECTED': {
      return({...state, listHandlings: {progress: 2, payload: action.payload}})
    }
    
    case 'SEGMENTS_LIST_PENDING': {
      return({...state, listSegments: {progress: 0}})
    }
    case 'SEGMENTS_LIST_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, listSegments: {progress: 2, payload: action.payload}})
      } else {
          return({...state, listSegments: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'SEGMENTS_LIST_REJECTED': {
      return({...state, listSegments: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}