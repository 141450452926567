import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../../libraries/common/ModalContainer';

import ConferenceBrowse from '../../../libraries/realm/talk/ConferenceBrowse'
import ConferenceForm from '../../../libraries/realm/talk/ConferenceForm'
import ConferenceSearch from '../../../libraries/realm/talk/ConferenceSearch'
import LinkBody from './LinkBody'


class ConferenceBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        conferenceId: 0,
      };
    }

    setMode(mode, id, params) {
      this.setState({mode: mode, conferenceId: id, params: params})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        conferenceId: 0,
        params: null,
      });
    }

    render() {
      const {mode, conferenceId, params}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
            <ConferenceSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {(mode==1 || mode==4) && <ConferenceBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125}/>}
              {mode==2 && <ConferenceForm mode={mode} conferenceId={conferenceId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <ConferenceForm mode={mode} conferenceId={0} setMode={this.setMode.bind(this)} />}
              {mode==4 && <ModalContainer size='large' content={<LinkBody screenWidth={screenWidth}  closeModal={this.closeModal.bind(this)} conferenceId={conferenceId} />} closeModal={this.closeModal.bind(this)} /> }
            </div>
        </Container>
      )
    }
}

export default ConferenceBody
