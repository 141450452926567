import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form } from 'semantic-ui-react'

import { connect } from "react-redux"
import { navigationOmniwidget } from "../../actions/flow/omniwidgetAction"

import bg_home  from '../../assets/widget-bg.svg'


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniwidgetStore: state.omniwidgetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    navigationOmniwidget:(objParam) => dispatch(navigationOmniwidget(objParam)),
  }
}

class OmniwidgetHome extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        
      };
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      const { data }= (this.props.omniwidgetStore.navigationOmniwidget!=undefined && this.props.omniwidgetStore.navigationOmniwidget.progress=='1' ? this.props.omniwidgetStore.navigationOmniwidget : {})
      const agent= {
        avatar: 'https://react.semantic-ui.com/images/avatar/large/patrick.png',
        name: 'James',
        descript: 'Expert Solution',
        online: false,
      }
      if (this.props.params && this.props.params.agents && this.props.params.agents.length>0) {
        agent.avatar= this.props.params.agents[0].avatar;
        agent.name= this.props.params.agents[0].name;
        agent.descript= this.props.params.agents[0].descript;
        agent.online= this.props.params.agents[0].bridging && this.props.params.agents[0].bridging.online==1;
      }
      return(<>
          <div>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h4' color={agent.online ? 'black' : 'grey'} style={{margin: '0', fontWeight: 'bold'}} inverted disabled={!agent.online}>
                  {/* <Icon name='user circle outline' /> */}
                  <Image circular src={agent.avatar} style={{opacity: !agent.online ? '.3' : '1'}}/>
                  <Header.Content>
                    {agent.name}
                    <Header.Subheader>{agent.descript}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{paddingTop: '.3em'}}>
                <Button icon size='big' color='orange' onClick={this.props.setMode.bind(this, 'menu')} style={{borderRadius: '.3em'}} >
                  <Icon name='microphone' /> <span style={{borderLeft: '1px solid #fff', marginLeft: '.3em', paddingLeft: '.5em'}}>Talk !</span>
                </Button>
              </div>
            </div>
          </div>
      </>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OmniwidgetHome)