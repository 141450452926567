export default function performanceReducer (state = {}, action) {
  switch (action.type) {
    case 'PERF_RESET': {
        return({
            ...state, 
            perfRecent: {},
        })
    }
    
    case 'CAMPAIGN_PERF_PENDING': {
        return({...state, perfCampaign: {progress: 0}})
    }
    case 'CAMPAIGN_PERF_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, perfCampaign: {...api.perfCampaign.response, progress: 1, payload: action.payload}})
            return({...state, perfCampaign: {progress: 2, payload: action.payload}})
        } else {
            return({...state, perfCampaign: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CAMPAIGN_PERF_REJECTED': {
        return({...state, perfCampaign: {progress: 2, payload: action.payload}})
    }

    case 'RECENT_PERF_PENDING': {
      return({...state, perfRecent: {progress: 0}})
    }
    case 'RECENT_PERF_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, perfRecent: {...api.perfRecent.response, progress: 1, payload: action.payload}})
            return({...state, perfRecent: {progress: 2, payload: action.payload}})
        } else {
            return({...state, perfRecent: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RECENT_PERF_REJECTED': {
        return({...state, perfRecent: {progress: 2, payload: action.payload}})
    }

    case 'AGENT_PERF_PENDING': {
        return({...state, perfAgent: {progress: 0}})
    }
    case 'AGENT_PERF_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, perfAgent: {...api.perfAgent.response, progress: 1, payload: action.payload}})
            return({...state, perfAgent: {progress: 2, payload: action.payload}})
        } else {
            return({...state, perfAgent: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AGENT_PERF_REJECTED': {
        return({...state, perfAgent: {progress: 2, payload: action.payload}})
    }

    case 'CLIENT_PERF_PENDING': {
        return({...state, perfClient: {progress: 0}})
    }
    case 'CLIENT_PERF_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, perfClient: {...api.perfClient.response, progress: 1, payload: action.payload}})
            return({...state, perfClient: {progress: 2, payload: action.payload}})
        } else {
            return({...state, perfClient: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLIENT_PERF_REJECTED': {
        return({...state, perfClient: {progress: 2, payload: action.payload}})
    }

    case 'PRODUCT_PERF_PENDING': {
      return({...state, perfProduct: {progress: 0}})
    }
    case 'PRODUCT_PERF_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, perfProduct: {...api.perfProduct.response, progress: 1, payload: action.payload}})
            return({...state, perfProduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, perfProduct: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PRODUCT_PERF_REJECTED': {
        return({...state, perfProduct: {progress: 2, payload: action.payload}})
    }

    case 'AGENT_EXCEL_PENDING': {
      return({...state, excelAgent: {progress: 0}})
    }
    case 'AGENT_EXCEL_FULFILLED': {
      if (action.payload==undefined) {
          //return({...state, excelAgent: {...api.excelAgent.response, progress: 1, payload: action.payload}})
          return({...state, excelAgent: {progress: 2, payload: action.payload}})
      } else {
          return({...state, excelAgent: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'AGENT_EXCEL_REJECTED': {
      return({...state, excelAgent: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}