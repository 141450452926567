export default function expertReducer (state = {}, action) {
  switch (action.type) {
    case 'BLOGGING_RESET': {
        return({
            ...state,
            getBlogging: {},
            updateBlogging: {},
            createBlogging: {},
        })
    }

    case 'BLOGGING_GET_PENDING': {
        return({...state, getBlogging: {progress: 0}})
    }
    case 'BLOGGING_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getBlogging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getBlogging: {...action.payload.data, data: action.payload.data.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BLOGGING_GET_REJECTED': {
        return({...state, getBlogging: {progress: 2, payload: action.payload}})
    }

    case 'BLOGGING_STATUS_PENDING': {
      return({...state, statusBlogging: {progress: 0}})
    }
    case 'BLOGGING_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusBlogging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusBlogging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BLOGGING_STATUS_REJECTED': {
        return({...state, statusBlogging: {progress: 2, payload: action.payload}})
    }

    case 'BLOGGING_BROWSE_PENDING': {
      return({...state, browseBlogging: {progress: 0}})
    }
    case 'BLOGGING_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseBlogging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseBlogging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BLOGGING_BROWSE_REJECTED': {
        return({...state, browseBlogging: {progress: 2, payload: action.payload}})
    }

    case 'BLOGGING_CREATE_PENDING': {
      return({...state, browseBlogging: {progress: 0}})
    }
    case 'BLOGGING_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createBlogging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createBlogging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BLOGGING_CREATE_REJECTED': {
        return({...state, createBlogging: {progress: 2, payload: action.payload}})
    }

    case 'BLOGGING_UPDATE_PENDING': {
      return({...state, updateBlogging: {progress: 0}})
    }
    case 'BLOGGING_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateBlogging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateBlogging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BLOGGING_UPDATE_REJECTED': {
        return({...state, updateBlogging: {progress: 2, payload: action.payload}})
    }

    case 'BLOGGING_REMOVE_PENDING': {
      return({...state, removeBlogging: {progress: 0}})
    }
    case 'BLOGGING_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeBlogging: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeBlogging: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BLOGGING_REMOVE_REJECTED': {
        return({...state, removeBlogging: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}