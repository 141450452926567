import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed } from 'semantic-ui-react'


import secToFormat from 'sec-to-format';

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus } from "../../actions/socket/socketAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
  }
}


class IdleWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }
  
  render() {
    const { screenWidth,  screenHeight, responsive}= this.props
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data : undefined)
    const { timer }= (agentStatus && agentStatus.payload.data) || {status: 'connect'}
    
    const setters = {
      '%H': hour => (hour < 10 ? `0${hour}` : hour),
      '%m': min => (min < 10 ? `0${min}` : min),
      '%s': sec => (sec < 10 ? `0${sec}` : sec),
    };

    return (
      <div style={{margin: '0 0 1.5em 0'}}>
        <Segment attached='top' style={{padding: '1em .5em', background: responsive ? '#fff' : '#444'}}>
          <Header as='h5' inverted={!responsive}>
            <Icon name='clock outline' style={{fontSize: '1em'}} />
            <Header.Content>
              Idle / Waiting
            </Header.Content>
          </Header>
        </Segment>
        <Segment attached='bottom' style={{padding: '1em', background:  responsive ? '#fff' : '#fbbd08'}}>
          <div style={{display: 'flex', textAlign: 'center'}}>
            <div style={{flex: '1', borderRight: '1px solid rgba(50,50,50,.4)'}}>
              <Header as='h3'>
                <Header.Content>
                  {secToFormat(timer && timer.secondIdle, '%H:%m:%s', { setters }).substring(0,2)}
                  <Header.Subheader>Hour</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{flex: '1', borderLeft: '1px solid rgba(255,255,255,.5)', borderRight: '1px solid rgba(50,50,50,.4)'}}>
              <Header as='h3'>
                <Header.Content>
                  {secToFormat(timer && timer.secondIdle, '%H:%m:%s', { setters }).substring(3,5)}
                  <Header.Subheader>Minute</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{flex: '1', borderLeft: '1px solid rgba(255,255,255,.4)'}}>
              <Header as='h3'>
                <Header.Content>
                  {secToFormat(timer && timer.secondIdle, '%H:%m:%s', { setters }).substring(6,8)}
                  <Header.Subheader>Second</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
          </div>
        </Segment>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(IdleWidget)