import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import VoiceBrowse from '../../libraries/agent/VoiceBrowse'
import VoiceForm from '../../libraries/agent/VoiceForm'
import VoiceSearch from '../../libraries/agent/VoiceSearch'


class TelephonyBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        userId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, userId: id})
    }

    render() {
      const {mode, userId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid style={{padding: '1em'}}>
          <Segment style={{padding: '.5em .7em'}}>
            <VoiceSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <VoiceBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)}/>}
              {mode==2 && <VoiceForm mode={mode} userId={userId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <VoiceForm mode={mode} userId={0} setMode={this.setMode.bind(this)} />}
            </div>
          </Segment>
        </Container>
      )
    }
}

export default TelephonyBody
