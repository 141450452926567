import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label, Popup } from 'semantic-ui-react'
import TwitterBody from './TwitterBody';
import InstagramBody from './InstagramBody';
import FacebookBody from './FacebookBody';
import PlaystoreBody from './PlaystoreBody';
import AppstoreBody from './AppstoreBody';

class SocmedBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      if (mode==0) {
        return(
          <Container fluid style={{minHeight: screenHeight}}>
            <div style={{padding: '1em 1em'}}>
              <Header as='h4'>
                <Icon name='life ring outline' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Social Media Accounts
                  {/* <Header.Subheader>Sample widget phone which embedded to your website/microsite.</Header.Subheader> */}
                </Header.Content>
              </Header>
            </div>
            <div style={{padding: '1.5em 0'}}>
              <div style={{display: 'flex', textAlign: 'center', marginBottom: '2em'}}>
                <div style={{flex: '1', padding: '0 1em'}}>
                  <Card as='a' style={{margin: 'auto'}} onClick={this.setMode.bind(this, 1)}>
                    <Card.Content style={{padding: '1em 1em 1em 1em'}}>
                      <div style={{textAlign: 'center'}}>
                        <Icon color='blue' name='twitter' size='big' circular inverted /> 
                      </div>
                    </Card.Content>
                    <Card.Content style={{padding: '1em 1em 1em 1em'}}>
                      <Header as='h4' style={{minHeight: '6em', padding: '0 1em'}}>
                        <Header.Content>
                          Twitter creation and interaction, direct message. list and user lookup information.
                          <Header.Subheader></Header.Subheader>
                        </Header.Content>
                      </Header>
  
                      <Divider style={{margin: '.5em 0'}} />
                      <Card.Header style={{fontSize: '2em'}}>Twitter</Card.Header>
                      <Card.Meta>Charge rate, subscription package and transaction limit may change without prior notice.</Card.Meta>
                    </Card.Content>
                  </Card>
                </div>
                <div style={{flex: '1', padding: '0 1em'}}>
                  <Card as='a' style={{margin: 'auto'}} onClick={this.setMode.bind(this, 2)}>
                    <Card.Content style={{padding: '1em 1em 1em 1em'}}>
                      <div style={{textAlign: 'center'}}>
                        <Icon color='orange' name='instagram' size='big' circular inverted /> 
                      </div>
                    </Card.Content>
                    <Card.Content style={{padding: '1em 1em 1em 1em'}}>
                      <Header as='h4' style={{minHeight: '6em', padding: '0 1em'}}>
                        <Header.Content>
                          Support  for content management (photos, videos and stories), comments, hastag, mentions and measuring interaction.
                          <Header.Subheader></Header.Subheader>
                        </Header.Content>
                      </Header>
  
                      <Divider style={{margin: '.5em 0'}} />
                      <Card.Header style={{fontSize: '2em'}}>Instagram</Card.Header>
                      <Card.Meta>Instagram Business User API may change without prior notice due to provider's policy.</Card.Meta>
                    </Card.Content>
                  </Card>
                </div>
                <div style={{flex: '1', padding: '0 1em'}}>
                  <Card as='a' style={{margin: 'auto'}} onClick={this.setMode.bind(this, 3)}>
                    <Card.Content style={{padding: '1em 1em 1em 1em'}}>
                      <div style={{textAlign: 'center'}}>
                        <Icon color='blue' name='facebook f' size='big' circular inverted /> 
                      </div>
                    </Card.Content>
                    <Card.Content style={{padding: '1em 1em 1em 1em'}}>
                      <Header as='h4' style={{minHeight: '6em', padding: '0 1em'}}>
                        <Header.Content>
                          Facebook Messenger API, Group API and Page API for content management, interaction and conversation insight.
                          <Header.Subheader></Header.Subheader>
                        </Header.Content>
                      </Header>
  
                      <Divider style={{margin: '.5em 0'}} />
                      <Card.Header style={{fontSize: '2em'}}>Facebook</Card.Header>
                      <Card.Meta>Facebook Developer API for Page, Messenger and Group may change without notice.</Card.Meta>
                    </Card.Content>
                  </Card>
                </div>
              </div>
              <div style={{display: 'flex', textAlign: 'center'}}>
                <div style={{flex: '1', padding: '0 1em'}}>
                  <Card as='a' style={{margin: 'auto'}} onClick={this.setMode.bind(this, 4)}>
                    <Card.Content style={{padding: '1em 1em 1em 1em'}}>
                      <div style={{textAlign: 'center'}}>
                        <Icon color='yellow' name='google play' size='big' circular inverted /> 
                      </div>
                    </Card.Content>
                    <Card.Content style={{padding: '1em 1em 1em 1em'}}>
                      <Header as='h4' style={{minHeight: '6em', padding: '0 1em'}}>
                        <Header.Content>
                          Conversation, interaction and insight for mobile app in Android Play Store.
                          <Header.Subheader></Header.Subheader>
                        </Header.Content>
                      </Header>
  
                      <Divider style={{margin: '.5em 0'}} />
                      <Card.Header style={{fontSize: '2em'}}>Play Store</Card.Header>
                      <Card.Meta>Publishing, Reporting and Commentary API may change without prior notice due to provider's policy.</Card.Meta>
                    </Card.Content>
                  </Card>
                </div>
                <div style={{flex: '1', padding: '0 1em'}}>
                  <Card as='a' style={{margin: 'auto'}} onClick={this.setMode.bind(this, 5)}>
                    <Card.Content style={{padding: '1em 1em 1em 1em'}}>
                      <div style={{textAlign: 'center'}}>
                        <Icon color='grey' name='apple' size='big' circular inverted /> 
                      </div>
                    </Card.Content>
                    <Card.Content style={{padding: '1em 1em 1em 1em'}}>
                      <Header as='h4' style={{minHeight: '6em', padding: '0 1em'}}>
                        <Header.Content>
                          Conversation, interaction and insight  mobile app in IOS / Apple App Store.
                          <Header.Subheader></Header.Subheader>
                        </Header.Content>
                      </Header>
  
                      <Divider style={{margin: '.5em 0'}} />
                      <Card.Header style={{fontSize: '2em'}}>App Store</Card.Header>
                      <Card.Meta>Publishing, Reporting and Reviews API may change without prior notice due to provider's policy.</Card.Meta>
                    </Card.Content>
                  </Card>
                </div>
                <div style={{flex: '1', padding: '0 1em'}}>
                </div>
              </div>
            </div>
          </Container>
        )
      }  else {
        return(
          <Container fluid style={{minHeight: screenHeight}}>
            <div style={{background: '#fafafa', padding: '1em'}}>
              <Label.Group>
                <Label as='a' color={mode==1 ? 'yellow' : null} size='large' onClick={this.setMode.bind(this, 1)}>Twitter</Label>
                <Label as='a' color={mode==2 ? 'yellow' : null} size='large' onClick={this.setMode.bind(this, 2)}>Instagram</Label>
                <Label as='a' color={mode==3 ? 'yellow' : null} size='large' onClick={this.setMode.bind(this, 3)}>Facebook</Label>
                <Label as='a' color={mode==4 ? 'yellow' : null} size='large' onClick={this.setMode.bind(this, 4)}>Play Store</Label>
                <Label as='a' color={mode==5 ? 'yellow' : null} size='large' onClick={this.setMode.bind(this, 5)}>App Store</Label>
              </Label.Group>
            </div>
            {mode==1 && <TwitterBody screenHeight={screenHeight} />}
            {mode==2 && <InstagramBody screenHeight={screenHeight} />}
            {mode==3 && <FacebookBody screenHeight={screenHeight} />}
            {mode==4 && <PlaystoreBody screenHeight={screenHeight} />}
            {mode==5 && <AppstoreBody screenHeight={screenHeight} />}
          </Container>
        )
      }
    }
}

export default SocmedBody
