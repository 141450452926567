import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import {groupOptions, genderOptions, educationOptions, competencyOptions, protoOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, NumberField, DatePickerField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetForm, checkToken, setOnboarding} from "../../actions/auth/authAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
    setOnboarding:(objParam) => dispatch(setOnboarding(objParam)),

    resetForm:(objParam) => dispatch(resetForm(objParam)),
  }
}

class OnboardingForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      step: 0,
    }
  }

  componentDidMount(){
    this.props.resetForm('setOnboarding')
    // this.props.checkToken();
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= this.props.authStore.setOnboarding!=undefined ? this.props.authStore.setOnboarding : {}

    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.setState({
          step: 4,
        });
    }, 1000)
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  submitForm(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    if (this.state.step<3) {
      this.setState({
        step: this.state.step+1,
      })  
    } else {
      this.props.setOnboarding({...values})
    }
  }

  clickStep(v) {
    this.setState({
      step: v
    })
  }

  closeModal() {
    this.props.closeModal();
    window.location.reload();
  }

  render() {
    const { step }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, dialog } = this.props
    const { progress, code, text }= this.props.authStore.setOnboarding!=undefined ? this.props.authStore.setOnboarding : {}
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
            {step==0 &&
              <div>
                <div>
                  <div style={{minHeight: '325px'}}>
                    <div style={{textAlign: 'center'}}>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Please, <b>introduce</b> yourself !
                          <Header.Subheader>
                            Share your image profile to your employer to get more views.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>

                    <Divider style={{margin: '2em 0 2em 0'}}/>
                    <Form.Group widths='equal' >
                      <Form.Field>
                        <Field name='avatar' component={ImageUploadField}
                        width={300} 
                        height={300} 
                        aspectRation={3/3} 
                        />
                      </Form.Field>
                    </Form.Group>
                  </div>
                </div>
                <div style={{textAlign: 'right', marginTop: '2em'}}>
                  <Button color='blue' size='large'> Next</Button>
                </div>
              </div>
            }

            {step==1 &&
              <div>
                <div>
                  <div style={{minHeight: '325px'}}>
                    <div style={{textAlign: 'center'}}>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Update your <b>mandatory data</b> ?
                          <Header.Subheader>
                            Most of <b>salary, name, mobile and email</b> are required.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>

                    <Divider style={{margin: '2em 0'}}/>
                    {/* <Form.Group widths='16'>
                      <Field name='monthlyRate' component={NumberField}
                      width={8}
                      label='Expected Salary'
                      validate={[required]}
                      placeholder='Rp. / Month' />
                    </Form.Group> */}
                    <Form.Group widths='16'>
                      <Field name='name' component={InputField}
                      width={8}
                      label='Name'
                      validate={[required]}
                      placeholder='Full Name' />
                      {/* <Field name='quote' component={InputField}
                      label='Tagline'
                      placeholder='Quote / Tagline' /> */}
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Field name='mobile' component={InputField}
                      readOnly
                      label='Mobile'
                      validate={[required]}
                      icon='square outline'
                      placeholder='Handphone' />
                      <Field name='email' component={InputField}
                      readOnly
                      label='Email'
                      validate={[required]}
                      icon='envelope outline'
                      placeholder='Email' />
                    </Form.Group>
                  </div>
                </div>
                <div style={{display: 'flex', marginTop: '2em'}}>
                  <div style={{flex: 1}}>
                    <Button disabled={!(progress!=1 || code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this, 0)}> Back</Button>
                  </div>
                  <div>
                  <Button color='blue' size='large'> Next</Button>
                  </div>
                </div>
              </div>
            }

            {step==2 &&
              <div>
                <div>
                  <div style={{minHeight: '325px'}}>
                    <div style={{textAlign: 'center'}}>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Describe your <b>profile</b>  ?
                          <Header.Subheader>
                            Your profile is required for <b>matching up</b> assignment and requirement.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>

                    <Divider style={{margin: '2em 0'}}/>

                    <Form.Group widths='16'>
                      <Field name='gender' component={SelectField}
                      width={3}
                      label='Gender'
                      validate={[required]}
                      options={genderOptions}
                      placeholder='L/P' />
                      <Field name='birthDate' component={DatePickerField}
                      width={5}
                      label='Birth Date'
                      validate={[required]}
                      placeholder='Birth Date' />
                      <Field name='birthPlace' component={InputField}
                      width={8}
                      label='Birth Place'
                      validate={[required]}
                      placeholder='Birth Place' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Field name='address' component={InputField}
                                            label='Address [OPTIONAL]'
                      placeholder='Address' />
                    </Form.Group>
                    <Form.Group widths='16'>
                      <Field name='city' component={InputField}
                      width={8}
                      placeholder='City' />
                      <Field name='province' component={InputField}
                      width={8}
                      placeholder='Province' />
                      {/* <Field name='country' component={InputField}
                      width={4}
                      placeholder='Country' /> */}
                    </Form.Group>
                  </div>
                </div>
                <div style={{display: 'flex', marginTop: '2em'}}>
                  <div style={{flex: 1}}>
                    <Button disabled={!(progress!=1 || code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this, 1)}> Back</Button>
                  </div>
                  <div>
                  <Button color='blue' size='large'> Next</Button>
                  </div>
                </div>
              </div>
            }

            {step==3 &&
              <div>
                <div >
                  <div style={{minHeight: '325px'}}>
                    <div style={{textAlign: 'center'}}>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          What is <b>your competency</b> and <b>preference</b> ? 
                          <Header.Subheader>
                            Competency will be <b>assessed</b> by providers.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>

                    <Divider style={{margin: '2em 0'}}/>
                    <Form.Group widths='equal' style={{margin: '1em -.5em'}}>
                      <Field name='educationInstitute' component={InputField}
                      width={16}
                      validate={[required]}
                      label='School / Institute / University' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Field name='educationDepartment' component={InputField}
                      validate={[required]}
                      label='Major / Department' />
                      <Field name='educationLevel' component={SelectField}
                                            label='Level'
                      validate={[required]}
                      options={educationOptions}
                      placeholder='S1 / S2 / S3' />
                    </Form.Group>
                    <Form.Group widths='16'>
                      <Field name='competencies' component={SelectField}
                      width={16}
                      multiple
                      validate={[required]}
                      search
                      options={competencyOptions}
                      label='Professional Competency' />
                    </Form.Group>
                  </div>
                </div>
                <div style={{display: 'flex', marginTop: '2em'}}>
                  <div style={{flex: 1}}>
                    <Button disabled={!(progress!=1 || code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this,2)}> Back</Button>
                  </div>
                  <div>
                    <Button disabled={!(progress!=1 || code!="00")} color='red' size='large' id='btnOnboarding' icon={progress==0 ? true : false}> {progress==0 ? <Icon name='notch circle' loading /> : ''} Save</Button>
                  </div>
                </div>
              </div>
            }

            {step==4 &&
              <div>
                <div>
                  <div style={{minHeight: '325px'}}>
                    <div style={{textAlign: 'center', paddingTop: '100px'}}>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Thank you, <b>your profile</b> is prepared. 
                          <Header.Subheader>
                            It would refresh page, your conversation channel connection will be established.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>
                  </div>
                </div>
                {dialog && <div style={{marginTop: '2em', textAlign: 'center'}}>
                  <Button type='button' color='red' size='large' onClick={this.closeModal.bind(this)}> Close, I 'm Done</Button>
                </div>}
              </div>
            }
          </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.authStore.checkToken!=undefined && state.authStore.checkToken.progress==1 ? state.authStore.checkToken.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: checkToken }
  )
  (reduxForm({
    form: 'OnboardingForm',	// a unique identifier for this form
  })(OnboardingForm)
  )
)
