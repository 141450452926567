import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetOmnichannel(objParam) {
  return {
      type: 'OMNICHANNEL_RESET',
      payload: objParam
  }
}

export function recentContact(objParam) {
  return {
      type: 'OMNICHANNEL_RECENT',
      payload:
        axios.post(
          urlAPI + 'omnichannel/recent',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function getConversation(objParam) {
  return {
      type: 'OMNICHANNEL_GET',
      payload:
        axios.post(
          urlAPI + 'omnichannel/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function remarkTarget(objParam) {
  return {
      type: 'OMNICHANNEL_REMARK',
      payload:
        axios.post(
          urlAPI + 'omnichannel/remark',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function predialTarget(objParam) {
  return {
    type: 'OMNICHANNEL_PREDIAL',
    payload:
      axios.post(
        urlAPI + 'omnichannel/predial',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function inappTarget(objParam) {
  return {
    type: 'OMNICHANNEL_INAPP',
    payload:
      axios.post(
        urlAPI + 'omnichannel/inapp',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function chatTarget(objParam) {
  return {
    type: 'OMNICHANNEL_CHAT',
    payload:
      axios.post(
        urlAPI + 'omnichannel/chat',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function emailTarget(objParam) {
  return {
      type: 'OMNICHANNEL_EMAIL',
      payload:
        axios.post(
          urlAPI + 'omnichannel/email',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function smsTarget(objParam) {
  return {
    type: 'OMNICHANNEL_SMS',
    payload:
      axios.post(
        urlAPI + 'omnichannel/sms',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function whatsappTarget(objParam) {
  return {
    type: 'OMNICHANNEL_WHATSAPP',
    payload:
      axios.post(
        urlAPI + 'omnichannel/whatsapp',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function twitterTarget(objParam) {
  return {
    type: 'OMNICHANNEL_TWITTER',
    payload:
      axios.post(
        urlAPI + 'omnichannel/twitter',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function facebookTarget(objParam) {
  return {
    type: 'OMNICHANNEL_FACEBOOK',
    payload:
      axios.post(
        urlAPI + 'omnichannel/facebook',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function instagramTarget(objParam) {
  return {
    type: 'OMNICHANNEL_INSTAGRAM',
    payload:
      axios.post(
        urlAPI + 'omnichannel/instagram',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}
