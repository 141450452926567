import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Button } from 'semantic-ui-react'

import ModalContainer from '../../../libraries/common/ModalContainer';
import AgentStatisticWidget from '../../../libraries/widget/AgentStatisticWidget';
import CampaignStatisticWidget from '../../../libraries/widget/CampaignStatisticWidget';
import OccupancyStatisticWidget from '../../../libraries/widget/OccupancyStatisticWidget';

import { connect } from "react-redux"
import { checkToken} from "../../../actions/auth/authAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
  }
}

class WallboardBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: false, //1: browse, 2: edit, 3: insert, 4: delete
        dark: false,
        reportId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: !this.state.mode, clientId: id})
    }
    setDaylight() {
      this.setState({dark: !this.state.dark})
    }

    closeModal() {
      this.setState({mode: 1})
      this.props.closeModal();
    }

    render() {
      const {mode, dark}= this.state
      const {screenWidth, screenHeight}= this.props
      const me= this.props.authStore.checkToken!=undefined && this.props.authStore.checkToken.data ? this.props.authStore.checkToken.data : {}
      
      return( 
          <div style={{display: 'flex', flexDirection: 'column', padding: mode ? '1em' : '.5em 1em 2.5em 1em', minHeight: mode ? screenHeight : (screenHeight-300), ...(mode ? {position: 'absolute', zIndex:200000, left: 0, top: 0, width: screenWidth,} : {}), background: mode && dark ? '#333' : (mode ? '#eee' : '#fcfcfc') }}>
            <div style={{height: mode ? '5em' : '2.2em', marginTop: mode ? 0 : '-2em'}}>
              <div style={{display: 'flex', border: '0px solid rgba(100,100,100,.2)'}}>
                <div style={{flex: '1'}}>
                  {mode && <Header as='h3' inverted={mode && dark} color={mode && dark ? 'yellow' : 'black'}>
                    <Header.Content>
                      <b>{me && me.company && me.company.toUpperCase()}</b>
                      <Header.Subheader>Wallboard | Simply real time monitoring at anytime.</Header.Subheader>
                    </Header.Content>
                  </Header>}
                </div>
                <div>
                  {mode && <Button size='mini' color={mode && dark ? 'black' : 'orange'} onClick={this.setDaylight.bind(this)} icon style={{marginLeft: '.8em', padding: '.7em .6em .5em .6em'}}><Icon name={mode && dark ? 'sun outline' : 'sun'} style={{fontSize: '1.3em', cursor: 'pointer'}}/></Button>}
                  <Button size='mini' color={mode ? 'red' : null} onClick={this.setMode.bind(this)} icon style={{marginLeft: '1em', padding: '.7em .6em .5em .6em'}}><Icon name={mode ? 'window restore' : 'window maximize outline'} style={{fontSize: '1.3em', cursor: 'pointer'}}/></Button>
                </div>
              </div>
            </div>
            <div style={{flex: '1'}}>
              <div style={{display: screenWidth<800 ? 'block' : 'flex'}}>
                <div style={{flex: 3}}>
                  <AgentStatisticWidget dark={mode && dark} screenWidth={screenWidth} screenHeight={screenHeight} />
                </div>
                <div style={{flex: 2, marginTop: screenWidth<800 ? '1em' : '0', paddingLeft: screenWidth<800 ? '0' : '1em'}}>
                  <CampaignStatisticWidget dark={mode && dark} screenWidth={screenWidth} screenHeight={screenHeight} />
                </div>
              </div>
            </div>
            <div style={{paddingTop: '1em'}}>
              {mode && <OccupancyStatisticWidget dark={mode && dark} screenWidth={screenWidth} screenHeight={screenHeight} />}
              {!mode && <OccupancyStatisticWidget dark={mode && dark} screenHeight={screenHeight} />}
            </div>
          </div>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WallboardBody)