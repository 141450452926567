import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form, Dropdown, Input, Label } from 'semantic-ui-react'

import { SubmissionError, Field, reduxForm, change, formValueSelector, submit, reset } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, LabelInputField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { messageOmniwidget, chatOmniwidget, variableOmniwidget } from "../../actions/flow/omniwidgetAction"

function submitText(values, e, props) {
  // const { mode } = this.props
  // console.log('!!! SUBMIT: ', values)
  // console.log('!!! SUBMIT: ', e)
  // console.log('!!! SUBMIT: ', props)
  // messageOmniwidget({...values})
  // props.messageOmniwidget({...values})
}

//CONNECT REDUX STORE
const selector = formValueSelector('OmniwidgetChat')

const mapStateToProps = (state, props) => {
  return {
    omniwidgetStore: state.omniwidgetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    messageOmniwidget:(objParam) => dispatch(messageOmniwidget(objParam)),
    chatOmniwidget:(objParam) => dispatch(chatOmniwidget(objParam)),
    
    variableOmniwidget:(objParam) => dispatch(variableOmniwidget(objParam)),
  }
}


class OmniwidgetChat extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        lastSent: null,
        currSent: null,
        isInserted: false,
        lastCommand: null,
      };
    }

    textInput(e, v) {
      // console.log(e)
      // console.log(v.value)
      if (v.value[v.value.length-1]=='\n') {
        if (v.value.length>1) {
          // console.log('SEND')
          // this.props.dispatch(submit('OmniwidgetChat'))
          this.textSend(v.value.substr(0, v.value.length-1))
          this.setState({
            currSent: new Date()
          })
        }
        // this.props.dispatch(change('OmniwidgetChat', 'message', ''))
      }
    }

    itemClick(e, v) {
      // console.log(v)
      this.props.dispatch(change('OmniwidgetChat', 'message', (this.props.message || '') + (this.props.message ? '\n\n' : '') + (v.text || '') ));
    }

    onSubmit(values) {
      const { mode, booked, reset } = this.props
      console.log('!!! SUBMIT: ', values)
      // this.props.messageOmniwidget({...values})
      if (values.name || values.contact || values.custRef || values.contractRef) {
        this.props.variableOmniwidget({
          visitorId: booked ? booked.visitorId : null,
          clientId: booked ? booked.clientId : null,
          ...values
        })
        
        if (values.name && values.mobile) {
          this.variableSend(values, 'CONTACT', false)
        } else if (values.custRef) {
          this.variableSend(values, 'CUST_ID', false)
        } else if (values.contractRef) {
          this.variableSend(values, 'SUBS_ID', false)
        }

        reset()
      } else {

      }
    }

    textSend(message) {
      // console.log('!!! TEXT SEND', message)
      this.props.textSend(message)
    }

    variableSend(values, variable, isCanceled) {
      this.props.variableSend(values, variable, isCanceled);
    }
    
    closeChat() {
      const {booked, chats}= this.props
      this.props.setMode('rate')
      // send end chat notification and save chat history
      this.props.chatOmniwidget({
        command: 'end_by_cust',
        visitorId: booked ? booked.visitorId : null,
        agentId: booked ? booked.agentId : null,
        clientId: booked ? booked.clientId : null,
        conversations: chats,
      })
    }

    closeCommand() {
      this.props.chatClose();
    }

    componentDidUpdate() {
      const { booked, command }= this.props
      const { lastSent, currSent, isInserted, lastCommand }= this.state
      // agentExten: "7000000005"
      // agentId: "4"
      // clientId: "2"
      // sipAccount: "7012345215"
      // sipHost: "host"
      // sipPasswd: "MTQ3MTgyODU4Nzc5MzE0MTAwMDA="
      // sipPort: "port"
      // visitorId: "215"
      // voiceParking: "4175"      

      if (!isInserted && this.props.phoneState=='online' && booked) {
        this.setState({
          isInserted: true,
        })
        this.props.messageOmniwidget({
          userId: booked.agentId, // for booked agent allocation
          clientId: booked.clientId,
          visitorId: booked.visitorId,
          originating: booked.sipAccount,
          terminating: booked.agentExten,
        });
      }

      if (lastSent!=currSent) {
        this.setState({
          lastSent: currSent,
        })
        this.props.dispatch(change('OmniwidgetChat', 'message', ''))
      }
      var objDiv = document.getElementById("wd_chat");
      objDiv.scrollTop = objDiv.scrollHeight;

      if (lastCommand!=command && command=='CLOSE') {
        this.setState({
          lastCommand: command,
        })
        this.closeCommand();
      }
    }

    componentWillUnmount() {
      this.closeChat()
    }

    render() {
      const { chats, variable, handleSubmit, pristine, reset, submitting, mode, dispatch, booked, phoneState } = this.props
      const { questions }= (this.props.omniwidgetStore.navigationOmniwidget!=undefined && this.props.omniwidgetStore.navigationOmniwidget.progress=='1' ? this.props.omniwidgetStore.navigationOmniwidget.data : {})
      
      return(
        <div style={{margin: '-10em auto 0 auto', background: 'rgba(255, 255, 255, .95)',  borderRadius: '1.5em'}}>
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {/* <Form onSubmit={handleSubmit}> */}
            <div style={{padding: '1em 1em', borderBottom: '1px solid #ccc'}}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Header as='h4' color={(this.props.phoneState=='online' && 'blue') || 'grey'} style={{fontWeight: 'bold'}}>
                    {booked && booked.agentAvatar!='-' && <Image avatar src='https://www.shareicon.net/data/512x512/2016/05/24/770117_people_512x512.png' style={{margin: '0'}}/> }
                    {(!booked || (booked && booked.agentAvatar=='-')) && <Icon name='user circle' style={{fontSize: '2.1em'}} color={(this.props.phoneState=='online' && 'blue') || 'grey'}/>}
                    <Header.Content>
                      {(this.props.phoneState=='online' && booked && booked.agentName) || 'No Agent'}
                      <Header.Subheader>{(this.props.phoneState=='online' && 'Online chat') || 'Offline / Disconnect'}</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div>
                  {/* <Button type='button' size='tiny' icon onClick={this.closeChat.bind(this)} >
                    <Icon name='close' color='red' />
                  </Button> */}
                </div>
              </div>
            </div>
            <div style={{borderTop: '1px solid #fff'}}>
              {variable && variable=='CONTACT' &&
              <div id='wd_chat' className='chat' style={{background: '#fff'}}>
                <div style={{background:'#e9e9e9', padding: '2em 1em 2em 1em'}}>
                  <Header as='h4' color='red' style={{fontWeight: '500'}}>
                    {/* <Icon name='warning sign' style={{fontSize: '1.8em'}} color='yellow'/> */}
                    <Header.Content>
                      <b>Contact Info</b>
                      <Header.Subheader>Please describe your contact info.</Header.Subheader>
                    </Header.Content>
                  </Header>

                  <Divider />
                  <Form.Group widths='equal'>
                    <Field name='name' component={LabelInputField}
                    disabled={this.props.phoneState=='offline'}
                    validate={[required]}
                    label='Name'
                    placeholder='Full Name' />  
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='mobile' component={LabelInputField}
                    disabled={this.props.phoneState=='offline'}
                    validate={[required]}
                    label='Mobile'
                    placeholder='Mobile' />  
                  </Form.Group>
                </div>
                <div style={{background:'#d2d2d2', padding: '2em 1em 2em 1em'}}>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1', textAlign: 'left'}}>
                      <Button type='button' size='medium' color='grey' onClick={this.variableSend.bind(this, null, 'CONTACT', true)}><Icon name='close' /> <span style={{borderLeft: '1px solid #fff', marginLeft: '.3em', paddingLeft: '.5em'}}>Nope</span></Button>
                    </div>
                    <div style={{flex: '1', textAlign: 'right'}}>
                      <Button size='medium' color='blue' icon><Icon name='check' /> <span style={{borderLeft: '1px solid #fff', marginLeft: '.3em', paddingLeft: '.5em'}}>Send</span></Button> 
                    </div>
                  </div>
                </div>
              </div>
              }
              {variable && variable=='CUST_ID' &&
              <div id='wd_chat' className='chat' style={{background: '#ffffff'}}>
                <div style={{background:'#e9e9e9', padding: '2em 1em 2em 1em'}}>
                  <Header as='h4' color='red' style={{fontWeight: '500'}}>
                    {/* <Icon name='warning sign' style={{fontSize: '1.8em'}} color='yellow'/> */}
                    <Header.Content>
                      <b>Customer ID</b>
                      <Header.Subheader>Input Customer No. / Code</Header.Subheader>
                    </Header.Content>
                  </Header>

                  <Divider />
                  <Form.Group widths='equal'>
                    <Field name='custRef' component={LabelInputField}
                    disabled={this.props.phoneState=='offline'}
                    validate={[required]}
                    label='Cust. No'
                    placeholder='Customer No.' />  
                  </Form.Group>
                </div>
                <div style={{background:'#d2d2d2', padding: '2em 1em 2em 1em'}}>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1', textAlign: 'left'}}>
                      <Button type='button' size='medium' color='grey' onClick={this.variableSend.bind(this, null, 'CUST_ID', true)}><Icon name='close' /> <span style={{borderLeft: '1px solid #fff', marginLeft: '.3em', paddingLeft: '.5em'}}>Nope</span></Button>
                    </div>
                    <div style={{flex: '1', textAlign: 'right'}}>
                      <Button size='medium' color='blue'><Icon name='check' /> <span style={{borderLeft: '1px solid #fff', marginLeft: '.3em', paddingLeft: '.5em'}}>Send</span></Button>
                    </div>
                  </div>
                </div>
              </div>
              }
              {variable && variable=='SUBS_ID' &&
              <div id='wd_chat' className='chat' style={{background: '#ffffff'}}>
                <div style={{background:'#e9e9e9', padding: '2em 1em 2em 1em'}}>
                  <Header as='h4' color='red' style={{fontWeight: '500'}}>
                    {/* <Icon name='warning sign' style={{fontSize: '1.8em'}} color='yellow'/> */}
                    <Header.Content>
                      <b>My Subscription</b>
                      <Header.Subheader>Input Subscription No.</Header.Subheader>
                    </Header.Content>
                  </Header>

                  <Divider />
                  <Form.Group widths='equal'>
                    <Field name='contractRef' component={LabelInputField}
                    disabled={this.props.phoneState=='offline'}
                    validate={[required]}
                    label='Subs. No'
                    placeholder='Subscription No.' />  
                  </Form.Group>
                </div>
                <div style={{background:'#d2d2d2', padding: '2em 1em 2em 1em'}}>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1', textAlign: 'left'}}>
                      <Button size='medium' type='button' color='grey' onClick={this.variableSend.bind(this, null, 'SUBS_ID', true)}><Icon name='close' /> <span style={{borderLeft: '1px solid #fff', marginLeft: '.3em', paddingLeft: '.5em'}}>Nope</span></Button>
                    </div>
                    <div style={{flex: '1', textAlign: 'right'}}>
                      <Button size='medium' color='blue'><Icon name='check' /> <span style={{borderLeft: '1px solid #fff', marginLeft: '.3em', paddingLeft: '.5em'}}>Send</span></Button>
                    </div>
                  </div>
                </div>
              </div>
              }
              {!variable && <>
                <div id='wd_chat' className='chat' style={{padding: '1em 1em', height: '300px', overflowY: 'auto', borderBottom: '1px solid #ccc'}}>
                  <div style={{minHeight: '250px', display: 'flex', flexDirection: 'column'}}>
                    <div style={{flex: '1'}}>
                    </div>
                    {
                      chats.map((item, idx)=>{
                        return(
                          <div key={idx} style={{margin: '.1em 0', textAlign: item.sender=='agent' ? 'left' : 'right'}}>
                            <div style={{background: item.sender=='agent' ? '#fffd8a' : '#c5fcff', float: item.sender=='agent' ? 'left' : 'right', padding: item.sender=='agent' ? '.5em 1em .2em .5em' : '.5em .5em .2em 1em', margin: item.sender=='cust' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='cust' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                              <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                <Header.Content>
                                  <Header.Subheader style={{fontSize: '.8em', borderBottom: '1px solid rgba(100, 100, 100, .5)'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                  {item.message}
                                  {item.sender!='agent' &&
                                  <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>}
                                </Header.Content>
                              </Header>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div style={{padding: '1em 1em .5em 1em'}}>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      {questions && questions.length>0 && 
                        <Dropdown text='Write or pick your questions' icon='angle down'>
                          <Dropdown.Menu>
                            <Dropdown.Menu scrolling>
                              {questions.map((option) => (
                                <Dropdown.Item key={option.value} {...option}  onClick={this.itemClick.bind(this)}/>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown.Menu>
                        </Dropdown>}
                    </div>
                    {/* <div>
                      <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                        <Icon name='phone' style={{fontSize: '1.1em', transform: 'rotate(20deg)'}}/>
                      </Button>
                    </div> */}
                    {/* <div>
                      <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                        <Icon name='file alternate outline' style={{fontSize: '1.1em'}}/>
                      </Button>
                    </div> */}
                    {/* <div>
                      <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                        <Icon name='image outline' style={{fontSize: '1.3em'}}/>
                      </Button>
                    </div> */}
                    {/* <div>
                      <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                        <Icon name='camera retro' style={{fontSize: '1.2em'}}/>
                      </Button>
                    </div> */}
                  </div>
                  <Form.Group widths='16'>
                  <Field name='message' component={TextAreaField} onInput={this.textInput.bind(this)}
                  readOnly={phoneState=='offline' ? true : false}
                  disabled={this.props.phoneState=='offline'}
                  width={16}
                  maxLength={150}
                  placeholder='Type message here ...' />  
                </Form.Group>
                </div>
              </>}
            </div>
            <div style={{padding: '.5em 1em', }}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Header as='h6' style={{fontWeight: 'bold', margin: '0'}}>
                    <Header.Content>
                      {phoneState=='online' ? 'Online' : 'Offline'}
                    </Header.Content>
                  </Header>
                </div>
                <div>
                  {/* <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                    <Icon name='phone' style={{fontSize: '1.1em', transform: 'rotate(20deg)'}}/>
                  </Button> */}
                </div>
                {/* <div>
                  <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                    <Icon name='file alternate outline' style={{fontSize: '1.1em'}}/>
                  </Button>
                </div> */}
                <div>
                  <Button type='button' icon style={{border: 'none', background: 'none', padding: '0 .3em'}}>
                    <Icon name='image outline' style={{fontSize: '1.3em'}}/>
                  </Button>
                </div>
                {/* <div>
                  <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                    <Icon name='camera retro' style={{fontSize: '1.2em'}}/>
                  </Button>
                </div> */}
              </div>
            </div>
          </Form>
        </div>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: {}, //state.omniwidgetStore && state.omniwidgetStore.messageOmniwidget!=undefined ? state.omniwidgetStore.messageOmniwidget.data : {},
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:false,
      message: selector(state, 'message'),
    }),
  )
  (
    reduxForm({
      form: 'OmniwidgetChat',	// a unique identifier for this form
      // onSubmit: submitText,
    }) (OmniwidgetChat)
  )
)