import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import DismissableMessage from '../../common/DismissableMessage'
import IconToggle from '../../common/IconToggle';

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField, MaskedField, LabelNumberField, LabelSelectField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'

import { conferenceOptions, hourOptions }  from '../../common/StaticMasterData'

import { connect } from "react-redux"
import { resetConference, getConference,updateConference, createConference } from "../../../actions/talk/conferenceAction"
import { listCampaign, listWorkgroup, listTemplate } from "../../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ConferenceForm')
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    conferenceStore: state.conferenceStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetConference:(objParam) => dispatch(resetConference(objParam)),
    getConference:(objParam) => dispatch(getConference(objParam)),
    createConference:(objParam) => dispatch(createConference(objParam)),
    updateConference:(objParam) => dispatch(updateConference(objParam)),

    listCampaign:(objParam) => dispatch(listCampaign(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listTemplate:(objParam) => dispatch(listTemplate(objParam)),
  }
}

class ConferenceForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.resetConference(this.props.mode)

    this.props.listCampaign({search: {}})
    this.props.listWorkgroup({search: {}})
    this.props.listTemplate({search: {}})
    
    if (this.props.mode==2) { //update
      this.props.getConference({conferenceId: this.props.conferenceId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.conferenceStore.updateConference!=undefined ? this.props.conferenceStore.updateConference : {}) : (this.props.conferenceStore.createConference!=undefined ? this.props.conferenceStore.createConference : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
    if (this.props.uniqueLink=='1' && (!this.props.audience || this.props.audience=='Unlimited')) {
      this.props.dispatch(change('ConferenceForm', 'audience', 'Limited' ));
    }
    if (this.props.audience=='Unlimited' && this.props.totCapacity!=null) {
      this.props.dispatch(change('ConferenceForm', 'totCapacity', null ));
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, conferenceId } = this.props
    mode==2 ? this.props.updateConference({...values}) : this.props.createConference({...values})
  }
  


  setMode(key) {
    if (this.props[key]==undefined || this.props[key]=='0') {
      this.props.dispatch(change('ConferenceForm', key, '1' ));
    } else {
      this.props.dispatch(change('ConferenceForm', key, '0' ));
    }
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.conferenceStore.updateConference!=undefined ? this.props.conferenceStore.updateConference : {}) : (this.props.conferenceStore.createConference!=undefined ? this.props.conferenceStore.createConference : {}))

    const listCampaign= (this.props.masterStore.listCampaign!=undefined && this.props.masterStore.listCampaign.progress=='1' ? this.props.masterStore.listCampaign.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listTemplate= (this.props.masterStore.listTemplate!=undefined && this.props.masterStore.listTemplate.progress=='1' ? this.props.masterStore.listTemplate.data : [])
    
    const filteredListTemplate_1= [{ key: 0, value: null, text: 'No Template', icon: 'code'}];
    if (listTemplate.length>0) {
      listTemplate.map(item=>{
        item.media=='Voice' && filteredListTemplate_1.push(item);
      })
    }
    
    // console.log(listCampaign)
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='16' style={{marginTop: '2em'}}>
              <Field name='isEnabled' component={CheckboxField}
              toggle
              width={8}
              caption='Enable Now !'
              placeholder='Status enable/disabled' />
              {/* <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' /> */}
              {/* <Field name='code' component={InputField}
              width={4}
              validate={[required]}
              label='Campaign Code'
              placeholder='Campaign Code' /> */}
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={12}
              label='Name'
              placeholder='Name attribute' />
              {/* <Field name='passcode' component={MaskedField}
              mask='999999'
              width={4}
              validate={[required]}
              label='Passcode'
              placeholder='Passcode' /> */}
              <Field name='joinType' component={SelectField}
              width={4}
              options= {conferenceOptions('talk')}
              validate={[required]}
              label='Join Type'
              placeholder='Join Type' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='descript' component={InputField}
              width={16}
              // label='Descript'
              placeholder='Description / Information' />
            </Form.Group>
          </Segment>

          <Segment>
            <Form.Group widths='16'>
              <Field name='campaignId' component={SelectField}
              width={8}
              options={listCampaign}
              label='Reff. Campaign'
              placeholder='Reff. Campaign' />
              <Field name='scriptId' component={SelectField}
              options={filteredListTemplate_1}
              width={8}
              label='Reff. Script'
              placeholder='Conversation Script / Template' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='tagger' component={InputField}
              width={8}
              label='Tagger'
              placeholder='Assigned Tagger' />
              <Field name='workgroups'  component={SelectField}
              multiple
              options={listWorkgroup}
              width={8}
              label='Assigned Workgroups'
              placeholder='Assigned Workgroups' />
            </Form.Group>
          </Segment>

          <Segment>
            <div style={{display: 'flex', margin: '0 0 1.5em 0'}}>
              <div style={{flex: '1'}}>
                <Header as='h5'>
                  <Header.Content>
                    Availability and Capacity
                  </Header.Content>
                </Header>
              </div>
              <div style={{width: '16.5em'}}>
                <Header as='h5' style={{margin: '0', fontWeight: 'bold'}}>
                  <IconToggle readOnly={mode==2} active={this.props.uniqueLink=='1'} onClick={this.setMode.bind(this, 'uniqueLink')} />
                  <Header.Content>
                    Unique Link
                    <Header.Subheader>Customer Based Link</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
            {this.props.uniqueLink=='1' && 
            <Form.Group widths='16'>
              <Field name='expiredMinute' component={LabelNumberField}
              readOnly={mode==2}
              width={4}
              validate={[required]}
              label='Minute'
              caption='Expired In'
              placeholder='Expired In' />
              <Field name='repeatCall' component={LabelNumberField}
              readOnly={mode==2}
              width={4}
              validate={[required]}
              label='Calls'
              caption='Repeat Call'
              placeholder='Max. Repeat Call' />
            </Form.Group>
            }
            <Form.Group widths='equal'>
              <Field name='startDate' component={DatePickerField}
              readOnly={mode==2}
              validate={[required]}
              label='Start Date'
              placeholder='Start Date' />
              <Field name='endDate' component={DatePickerField}
              readOnly={mode==2}
              validate={[required]}
              label='End Date'
              placeholder='End Date' />
              <Field name='startTime'  component={LabelSelectField}
              options= {hourOptions()}
              readOnly={mode==2}
              icon='clock'
              validate={[required]}
              label='HH:MM'
              caption='Start Time'
              placeholder='Start Time' />
              <Field name='endTime'  component={LabelSelectField}
              options= {hourOptions()}
              readOnly={mode==2}
              icon='clock'
              validate={[required]}
              label='HH:MM'
              caption='End Time'
              placeholder='End Time' />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group widths='16'>
              <Field name='audience' component={SelectField}
              readOnly={mode==2 || this.props.uniqueLink=='1'}
              width={4}
              options= {conferenceOptions('audience')}
              label='Audience'
              placeholder='Audience Policy' />
              <Field name='totCapacity' component={LabelNumberField}
              width={4}
              readOnly={mode==2 || this.props.audience==='Unlimited'}
              validate={this.props.audience==='Unlimited' ? [] : [required]}
              label='Users'
              caption='Capacity'
              placeholder='Tot. capacity' />
              <Field name='method' component={SelectField}
              readOnly={mode==2}
              width={4}
              options= {conferenceOptions('method')}
              label='Method'
              placeholder='Method' />
              <Field name='channels' component={SelectField}
              readOnly={mode==2}
              width={4}
              multiple
              options= {conferenceOptions('channel')}
              validate={[required]}
              label='Channels'
              placeholder='Communication Chanels' />
            </Form.Group>
            <Form.Group widths='equal'>
              {/* <Field name='params' component={SelectField}
              readOnly={mode==2}
              label='Params'
              placeholder='Verification Params' /> */}
              <Field name='baseUrl' component={InputField}
              readOnly={mode==2}
              label='Base URL'
              placeholder='Base URL Call Link' />
            </Form.Group>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Conference' : 'Insert Conference') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.conferenceStore.getConference!=undefined && state.conferenceStore.getConference.progress==1 ? state.conferenceStore.getConference.data : {workgroups: [], channels: []}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,

      uniqueLink: selector(state, 'uniqueLink'),
      audience: selector(state, 'audience'),
      totCapacity: selector(state, 'totCapacity'),
    }),
  )
  (reduxForm({
    form: 'ConferenceForm',	// a unique identifier for this form
  })(ConferenceForm)
  )
)