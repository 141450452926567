import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../../libraries/common/ModalContainer';

import LinkBrowse from '../../../libraries/realm/talk/LinkBrowse'
import LinkSearch from '../../../libraries/realm/talk/LinkSearch'


class LinkBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      linkId: 0,
    };
  }

  setMode(mode, id, params) {
    this.setState({mode: mode, linkId: id, params: params})
  }

  closeModal() {
    this.setState({
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      linkId: 0,
      params: null,
    });
  }

  render() {
    const {mode, linkId, params}= this.state
    const {screenWidth, screenHeight}= this.props
    
    return(
      <Container fluid>
          <LinkSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
          <div>
            <LinkBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} closeModal={this.props.closeModal.bind(this)} conferenceId={this.props.conferenceId}/>
          </div>
      </Container>
    )
  }
}

export default LinkBody
