export default function documentReducer (state = {}, action) {
  switch (action.type) {
    case 'DOCUMENT_RESET': {
        return({
            ...state,
            getDocument: {},
            updateDocument: {},
            createDocument: {},
        })
    }

    case 'DOCUMENT_GET_PENDING': {
        return({...state, getDocument: {progress: 0}})
    }
    case 'DOCUMENT_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getDocument: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getDocument: {...action.payload.data, data: action.payload.data.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DOCUMENT_GET_REJECTED': {
        return({...state, getDocument: {progress: 2, payload: action.payload}})
    }

    case 'DOCUMENT_STATUS_PENDING': {
      return({...state, statusDocument: {progress: 0}})
    }
    case 'DOCUMENT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusDocument: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusDocument: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DOCUMENT_STATUS_REJECTED': {
        return({...state, statusDocument: {progress: 2, payload: action.payload}})
    }

    case 'DOCUMENT_BROWSE_PENDING': {
      return({...state, browseDocument: {progress: 0}})
    }
    case 'DOCUMENT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseDocument: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseDocument: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DOCUMENT_BROWSE_REJECTED': {
        return({...state, browseDocument: {progress: 2, payload: action.payload}})
    }

    case 'DOCUMENT_CREATE_PENDING': {
      return({...state, browseDocument: {progress: 0}})
    }
    case 'DOCUMENT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createDocument: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createDocument: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DOCUMENT_CREATE_REJECTED': {
        return({...state, createDocument: {progress: 2, payload: action.payload}})
    }

    case 'DOCUMENT_UPDATE_PENDING': {
      return({...state, updateDocument: {progress: 0}})
    }
    case 'DOCUMENT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateDocument: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateDocument: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DOCUMENT_UPDATE_REJECTED': {
        return({...state, updateDocument: {progress: 2, payload: action.payload}})
    }

    case 'DOCUMENT_REMOVE_PENDING': {
      return({...state, removeDocument: {progress: 0}})
    }
    case 'DOCUMENT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeDocument: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeDocument: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DOCUMENT_REMOVE_REJECTED': {
        return({...state, removeDocument: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}