import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../../libraries/common/ModalContainer';

import ApilogBrowse from '../../../libraries/realm/middleware/ApilogBrowse'
import ApilogForm from '../../../libraries/realm/middleware/ApilogForm'
import ApilogSearch from '../../../libraries/realm/middleware/ApilogSearch'


class ApilogBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        loggerId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, loggerId: id})
    }

    closeModal() {
      this.setState({
        mode: 1,
        loggerId: 0,
      });
      this.props.closeModal() 
    }

    render() {
      const {mode, loggerId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div>
            <ApilogSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {/* {mode==1 && <ApilogBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} />} */}
              {<ApilogBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
              {/* {mode==2 && <ApilogForm mode={mode} loggerId={loggerId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <ApilogForm mode={mode} loggerId={0} setMode={this.setMode.bind(this)} />} */}
              {mode==2 && <ModalContainer size='large' content={<><ApilogSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} /><ApilogForm mode={mode} loggerId={loggerId} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} /></>} closeModal={this.closeModal.bind(this)} /> }
              {mode==3 && <ModalContainer size='large' content={<><ApilogSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} /><ApilogForm mode={mode} loggerId={0} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} /></>} closeModal={this.closeModal.bind(this)} /> }
            </div>
          </div>
        </Container>
      )
    }
}

export default ApilogBody
