import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import DismissableMessage from '../../common/DismissableMessage'
import {groupOptions, genderOptions, educationOptions, competencyOptions, protoOptions}  from '../../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, NumberField, DatePickerField } from '../../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { resetForm, checkToken, setOnboarding} from "../../../actions/auth/authAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
    setOnboarding:(objParam) => dispatch(setOnboarding(objParam)),

    resetForm:(objParam) => dispatch(resetForm(objParam)),
  }
}

class OnboardingForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      step: 0,
    }
  }

  componentDidMount(){
    this.props.resetForm('setOnboarding')
    // this.props.checkToken();
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= this.props.authStore.setOnboarding!=undefined ? this.props.authStore.setOnboarding : {}

    if (progress==1 && code=='00') {
      setTimeout(()=>{this.setState({
        step: 4,
      })
    }, 1000)
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  submitForm(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    if (this.state.step<3) {
      this.setState({
        step: this.state.step+1,
      })  
    } else {
      this.props.setOnboarding({...values})
    }
  }

  clickStep(v) {
    this.setState({
      step: v
    })
  }

  render() {
    const { step }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, dialog } = this.props
    const { progress, code, text }= this.props.authStore.setOnboarding!=undefined ? this.props.authStore.setOnboarding : {}
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
            {step==0 &&
              <div>
                <div>
                  <div style={{minHeight: '325px'}}>
                    <div style={{textAlign: 'center'}}>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Please, <b>introduce</b> yourself !
                          <Header.Subheader>
                          Share and define <b>your company information</b> for better service delivery.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>

                    <Divider style={{margin: '2em 0 2em 0'}}/>
                    <Form.Group widths='16' style={{marginBottom: '2em'}}>
                      <Field name='name' component={InputField}
                                            width={8}
                      label='Admin'
                      validate={[required]}
                      placeholder='Full Name' />
                      <Field name='mobile' component={InputField}
                      readOnly
                                            width={4}
                      label='Mobile'
                      validate={[required]}
                      placeholder='Mobile number' />
                      <Field name='email' component={InputField}
                      readOnly
                                            width={4}
                      label='Email'
                      validate={[required]}
                      placeholder='Email address' />
                    </Form.Group>

                    <Divider style={{margin: '1em 0 1em 0'}}/>
                    <Form.Group widths='16'>
                      <Field name='company' component={InputField}
                                            width={8}
                      label='Company'
                      validate={[required]}
                      placeholder='Company' />
                      <Field name='brand' component={InputField}
                                            width={4}
                      label='Brand / Code'
                      validate={[required]}
                      placeholder='Brand / Code' />
                      <Field name='business' component={InputField}
                                            width={4}
                      label='Business Stream'
                      validate={[required]}
                      placeholder='Business Stream' />
                    </Form.Group>
                    
                    <Header as='h5' color='blue'>
                      <Header.Content>
                        Address
                      </Header.Content>
                    </Header>
                    <Form.Group widths='equal'>
                      <Field name='address' component={InputField}
                                            // label='Address'
                      validate={[required]}
                      placeholder='Address' />
                    </Form.Group>
                    <Form.Group widths='16'>
                      <Field name='city' component={InputField}
                      width={8}
                      validate={[required]}
                      placeholder='City' />
                      <Field name='province' component={InputField}
                      width={8}
                      validate={[required]}
                      placeholder='Province' />
                      {/* <Field name='country' component={InputField}
                      width={4}
                      placeholder='Country' /> */}
                    </Form.Group>
                  </div>
                </div>
                <div style={{textAlign: 'right', marginTop: '2em'}}>
                  <Button color='blue' size='large'> Next</Button>
                </div>
              </div>
            }

            {step==1 &&
              <div>
                <div>
                  <div style={{minHeight: '325px'}}>
                    <div style={{textAlign: 'center'}}>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          What kind of <b>campaign delivery</b> ?
                          <Header.Subheader>
                            Choose one or more <b>campaign delivery</b> which applicable.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>

                    <Divider style={{margin: '2em 0'}}/>
                    <Form.Group widths='equal' style={{margin: '1em -.5em'}}>
                      <Field name='isTelesales' component={CheckboxField}
                      validate={[required]}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          Telesales
                          <Header.Subheader>Create new prospect, new lead or even sales conversion from one single app through online digital marketing.</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                    </Form.Group>
                    <Form.Group widths='equal' style={{margin: '1em -.5em'}}>
                      <Field name='isCollection' component={CheckboxField}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          Collection
                          <Header.Subheader>Increase your collection rate and decrease Non Performing Loand through multiple channels campaign.</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                    </Form.Group>
                    <Form.Group widths='equal' style={{margin: '1em -.5em'}}>
                      <Field name='isRestructuring' component={CheckboxField}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          Restructuring
                          <Header.Subheader>Get your customer feedback and adjust installment or do restructuring based on customer validation.</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                    </Form.Group>
                    <Form.Group widths='equal' style={{margin: '1em -.5em'}}>
                      <Field name='isVerification' component={CheckboxField}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          Survey / Verification
                          <Header.Subheader>Validate your customer's insight through online and multi channels survey and verification.</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                    </Form.Group>
                  </div>
                </div>
                <div style={{display: 'flex', marginTop: '2em'}}>
                  <div style={{flex: 1}}>
                    <Button disabled={!(progress!=1 || code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this, 0)}> Back</Button>
                  </div>
                  <div>
                  <Button color='blue' size='large'> Next</Button>
                  </div>
                </div>
              </div>
            }

            {step==2 &&
              <div>
                <div>
                  <div style={{minHeight: '325px'}}>
                    <div style={{textAlign: 'center'}}>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          What <b>channels</b> will you use ?
                          <Header.Subheader>
                            You can add or remove any channel at any times. It will be charged <b>only on contacted call</b> or <b>per message</b> on certain channels.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>

                    <Divider style={{margin: '2em 0'}}/>
                    <Form.Group widths='equal' style={{margin: '1em -.5em'}}>
                      <Field name='isVoice' component={CheckboxField}
                      validate={[required]}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          Voice
                          <Header.Subheader>Call target's mobile directly</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                      <Field name='isIVR' component={CheckboxField}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          IVR
                          <Header.Subheader>Voice blast with TTS automation</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                    </Form.Group>
                    <Form.Group widths='equal' style={{margin: '1em -.5em'}}>
                      <Field name='isSMS' component={CheckboxField}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          SMS
                          <Header.Subheader>Send content through SMS</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                      <Field name='isEmail' component={CheckboxField}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          Email
                          <Header.Subheader>Send content through email</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                    </Form.Group><Form.Group widths='equal' style={{margin: '1em -.5em'}}>
                      <Field name='isWhatsapp' component={CheckboxField}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          Whatsapp
                          <Header.Subheader>Send content through WA</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                      <Field name='isTwitter' component={CheckboxField}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          Twitter
                          <Header.Subheader>Direct message through Twitter</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                    </Form.Group>
                    {/* <Form.Group widths='equal' style={{margin: '1em -.5em'}}>
                      <Field name='isFacebook' component={CheckboxField}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          Facebook
                          <Header.Subheader>Post content and message in FB</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                      <Field name='isInstagram' component={CheckboxField}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          Instagram
                          <Header.Subheader>Post content and message in IG</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                    </Form.Group> */}
                  </div>
                </div>
                <div style={{display: 'flex', marginTop: '2em'}}>
                  <div style={{flex: 1}}>
                    <Button disabled={!(progress!=1 || code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this, 1)}> Back</Button>
                  </div>
                  <div>
                  <Button color='blue' size='large'> Next</Button>
                  </div>
                </div>
              </div>
            }

            {step==3 &&
              <div>
                <div >
                  <div style={{minHeight: '325px'}}>
                    <div style={{textAlign: 'center'}}>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          What <b>dialing method</b> on <b>voice call</b> ? 
                          <Header.Subheader>
                            You can add or remove any dialing menthod at any times.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>

                    <Divider style={{margin: '2em 0'}}/>
                    <Form.Group widths='equal' style={{margin: '1em -.5em'}}>
                      <Field name='svcPredictive' component={CheckboxField}
                      validate={[required]}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          Predictive
                          <Header.Subheader>Automation predictive dialing, based on campaign's list of targets and preference profiling system will dial automatically on dynamic call ratio as required to makesure your agents on top of performance.</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                    </Form.Group>
                    <Form.Group widths='equal' style={{margin: '1em -.5em'}}>
                      <Field name='svcProgressive' component={CheckboxField}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          Progressive
                          <Header.Subheader>Automation dialing as one on one call ratio basis. For each targets number system will be sequentially call target's number one by one.</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                    </Form.Group>
                    <Form.Group widths='equal' style={{margin: '1em -.5em'}}>
                      <Field name='svcPredialed' component={CheckboxField}
                      label={<Header as='h4' style={{marginTop: '-.5em'}} color='red'>
                        <Header.Content>
                          Preview / Manual Dial
                          <Header.Subheader>It's manual dialing by clicking contact for each target which listed on agent's dashboard / computer telephony integration.</Header.Subheader>
                        </Header.Content>
                      </Header>} />
                    </Form.Group>
                  </div>
                </div>
                <div style={{display: 'flex', marginTop: '2em'}}>
                  <div style={{flex: 1}}>
                    <Button disabled={!(progress!=1 || code!="00")} type='button' color='grey' size='large' id='btnOnboarding' onClick={this.clickStep.bind(this,2)}> Back</Button>
                  </div>
                  <div>
                    <Button disabled={!(progress!=1 || code!="00")} color='red' size='large' id='btnOnboarding' icon={progress==0 ? true : false}> {progress==0 ? <Icon name='notch circle' loading /> : ''} Save</Button>
                  </div>
                </div>
              </div>
            }

            {step==4 &&
              <div>
                <div>
                  <div style={{minHeight: '325px'}}>
                    <div style={{textAlign: 'center', paddingTop: '100px'}}>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Thank you, <b>service</b> is prepared. 
                          <Header.Subheader>
                            You can reconfigure at any times through setting menu. And don't be hesitate.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>
                  </div>
                </div>
                {dialog && <div style={{marginTop: '2em', textAlign: 'center'}}>
                  <Button type='button' color='red' size='large' onClick={this.props.closeModal.bind(this)}> Close, I 'm Done</Button>
                </div>}
              </div>
            }
          </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.authStore.checkToken!=undefined && state.authStore.checkToken.progress==1 ? state.authStore.checkToken.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: checkToken }
  )
  (reduxForm({
    form: 'OnboardingForm',	// a unique identifier for this form
  })(OnboardingForm)
  )
)
