export default function audittrailReducer (state = {}, action) {
  switch (action.type) {
    case 'AUDITTRAIL_RESET': {
        return({
            ...state,
            getAudittrail: {},
            updateAudittrail: {},
            createAudittrail: {},
        })
    }

    case 'AUDITTRAIL_LOGGING': {
        if (state.loggingAudittrail) {
            state.loggingAudittrail.logs.push(action.payload);
        } else {
            state['loggingAudittrail']= {
                logs: [action.payload]
            }
        }

        console.log('!!! AUDITTRAIL LOGGING REDUCER', state);
        return({
            ...state,
        })
    }

    case 'AUDITTRAIL_GET_PENDING': {
        return({...state, getAudittrail: {progress: 0}})
    }
    case 'AUDITTRAIL_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getAudittrail: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getAudittrail: {...action.payload.data, data: action.payload.data.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AUDITTRAIL_GET_REJECTED': {
        return({...state, getAudittrail: {progress: 2, payload: action.payload}})
    }

    case 'AUDITTRAIL_STATUS_PENDING': {
      return({...state, statusAudittrail: {progress: 0}})
    }
    case 'AUDITTRAIL_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusAudittrail: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusAudittrail: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AUDITTRAIL_STATUS_REJECTED': {
        return({...state, statusAudittrail: {progress: 2, payload: action.payload}})
    }

    case 'AUDITTRAIL_BROWSE_PENDING': {
      return({...state, browseAudittrail: {progress: 0}})
    }
    case 'AUDITTRAIL_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseAudittrail: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseAudittrail: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AUDITTRAIL_BROWSE_REJECTED': {
        return({...state, browseAudittrail: {progress: 2, payload: action.payload}})
    }

    case 'AUDITTRAIL_CREATE_PENDING': {
      return({...state, browseAudittrail: {progress: 0}})
    }
    case 'AUDITTRAIL_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createAudittrail: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createAudittrail: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AUDITTRAIL_CREATE_REJECTED': {
        return({...state, createAudittrail: {progress: 2, payload: action.payload}})
    }

    case 'AUDITTRAIL_UPDATE_PENDING': {
      return({...state, updateAudittrail: {progress: 0}})
    }
    case 'AUDITTRAIL_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateAudittrail: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateAudittrail: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AUDITTRAIL_UPDATE_REJECTED': {
        return({...state, updateAudittrail: {progress: 2, payload: action.payload}})
    }

    case 'AUDITTRAIL_REMOVE_PENDING': {
      return({...state, removeAudittrail: {progress: 0}})
    }
    case 'AUDITTRAIL_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeAudittrail: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeAudittrail: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AUDITTRAIL_REMOVE_REJECTED': {
        return({...state, removeAudittrail: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}