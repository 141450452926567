import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';

class RecordingMedia extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  render() {
    return (<div>
      {this.props.url.includes(".mp4") &&
        <video controls>
            <source src={this.props.url} type="video/mp4" />
        </video>
      }
      {this.props.url.includes(".mp3") &&
        <ReactAudioPlayer
          style={{
            margin: 'auto',
            width: '450px',
            display: 'block',
            height: '55px',
          }}
          src={this.props.url}
          controls
        />
      }
    </div>
    )
  }
}

export default RecordingMedia
