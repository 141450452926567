import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
  Statistic,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"



import { connect } from "react-redux"
import { getTicket } from "../../../../actions/service/ticketAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    ticketStore: state.ticketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTicket:(objParam) => dispatch(getTicket(objParam)),
  }
}

class SummaryBox extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  formatSeconds(seconds) {
    // console.log('!!! SECONDS', seconds)
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log('!!! DATE', date)
    if (seconds>(24 * 60 * 60)) {
      return (Math.floor(seconds/(24 * 60 * 60)) +'D / '+ format(date, 'HH:mm'));
    } else {
      return (format(date, 'HH:mm'));
    }
  }

  render() {
    console.log(this.props.ticket)

    const { screenWidth, screenHeight, style }= this.props
    const { data, search, paging }= (this.props.ticketStore.getTicket!==undefined ? this.props.ticketStore.getTicket : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', flexDirection: 'column', height: '100%', ...style}}>
        <div style={{flex: '1', padding: '0'}}>

          <Header as='h5'>
            <Header.Content>
              <b>Statistic</b>
              <Header.Subheader>Ticket interaction statistics.</Header.Subheader>
            </Header.Content>
          </Header>
          <Segment style={{textAlign: 'center'}}>
            <div style={{display: 'flex', padding: '.5em .5em 1em .5em', borderBottom: '1px solid #ccc'}}>
              <div style={{flex: 1, borderRight: '1px solid #ccc'}}>
                <Popup position= 'top left' inverted content={'Total updates / timelines.'}
                  trigger={
                  <Statistic size='mini'>
                    <Statistic.Value>{(this.props.ticket && this.props.ticket.totUpdate) || 0}</Statistic.Value>
                    <Statistic.Label style={{fontSize: '14pt'}}>UPDATE</Statistic.Label>
                  </Statistic>
                } />
              </div>
              <div style={{flex: 1, borderLeft: '1px solid #fff'}}>
              <Popup position= 'top left' inverted content={'Total child / escalation tickets.'}
                trigger={
                  <Statistic size='mini'>
                    <Statistic.Value>{(this.props.ticket && this.props.ticket.totChild) || 0}</Statistic.Value>
                    <Statistic.Label style={{fontSize: '14pt'}}>CHILD</Statistic.Label>
                  </Statistic>
                } />
              </div>
            </div>
            <div style={{display: 'flex', padding: '1em .5em 0 .5em', borderTop: '1px solid #fff'}}>
              <div style={{flex: 1, borderRight: '1px solid #ccc'}}>
                <Popup position= 'top left' inverted content={'Total time ticket from created.'}
                  trigger={
                  <Statistic size='mini'>
                    <Statistic.Value>{(this.props.ticket && this.props.ticket.totDispatch) || 0}</Statistic.Value>
                    <Statistic.Label style={{fontSize: '14pt'}}>DISPATCH</Statistic.Label>
                  </Statistic>
                } />
              </div>
              <div style={{flex: 1, borderLeft: '1px solid #fff'}}>
              <Popup position= 'top left' inverted content={'Total time ticket from created.'}
                trigger={
                  <Statistic size='mini'>
                    <Statistic.Value>{(this.props.ticket && this.props.ticket.totAssignment) || 0}</Statistic.Value>
                    <Statistic.Label style={{fontSize: '14pt'}}>ASSIGNMENT</Statistic.Label>
                  </Statistic>
                } />
              </div>
            </div>
          </Segment>
          
          <Header as='h5'>
            <Header.Content>
              <b>Timers / Ticker</b>
              <Header.Subheader>Ticket timers information.</Header.Subheader>
            </Header.Content>
          </Header>
          <Segment style={{textAlign: 'center'}}>
            <div style={{display: 'flex', padding: '.5em .5em 1em .5em', borderBottom: '1px solid #ccc'}}>
              <div style={{flex: 1, borderRight: '1px solid #ccc'}}>
                <Popup position= 'top left' inverted content={'Total time ticket on waiting.'}
                  trigger={
                    <Statistic size='mini'>
                      <Statistic.Value>{this.formatSeconds((this.props.ticket && this.props.ticket.secondWaiting) || 0)}</Statistic.Value>
                      <Statistic.Label style={{fontSize: '14pt'}}>WAITING</Statistic.Label>
                    </Statistic>
                } />
              </div>
              <div style={{flex: 1, borderLeft: '1px solid #fff'}}>
                <Popup position= 'top left' inverted content={'Total time ticket on escalation.'}
                  trigger={
                    <Statistic size='mini'>
                      <Statistic.Value>{this.formatSeconds((this.props.ticket && this.props.ticket.secondOnHold) || 0)}</Statistic.Value>
                      <Statistic.Label style={{fontSize: '14pt'}}>ON HOLD</Statistic.Label>
                    </Statistic>
                } />
              </div>
            </div>
            <div style={{display: 'flex', padding: '1em .5em 0 .5em', borderTop: '1px solid #fff'}}>
              <div style={{flex: 1, borderRight: '1px solid #ccc'}}>
                <Popup position= 'top left' inverted content={'Total time ticket to solve.'}
                  trigger={
                    <Statistic size='mini'>
                      <Statistic.Value>{this.formatSeconds((this.props.ticket && this.props.ticket.secondSolved) || 0)}</Statistic.Value>
                      <Statistic.Label style={{fontSize: '14pt'}}>RESOLVED</Statistic.Label>
                    </Statistic>
                } />
              </div>
              <div style={{flex: 1, borderLeft: '1px solid #fff'}}>
                <Popup position= 'top left' inverted content={'Total time ticket from created.'}
                  trigger={
                    <Statistic size='mini'>
                      <Statistic.Value>{this.formatSeconds((this.props.ticket && this.props.ticket.secondLived) || 0)}</Statistic.Value>
                      <Statistic.Label style={{fontSize: '14pt'}}>DURATION</Statistic.Label>
                    </Statistic>
                } />
              </div>
            </div>
          </Segment>

          <Header as='h5'>
            <Header.Content>
              <b>SLA Milestones</b>
              <Header.Subheader>SLA timelines information.</Header.Subheader>
            </Header.Content>
          </Header>
          <Segment style={{textAlign: 'center'}}>
            <div style={{display: 'flex', padding: '.5em .5em 1em .5em', borderBottom: '1px solid #ccc'}}>
              <div style={{flex: 1, borderRight: '1px solid #ccc'}}>
                <Popup position= 'top left' inverted content={'First Responsed SLA Target.'}
                  trigger={
                  <Statistic size='mini'>
                    <Statistic.Value>{this.props.ticket && this.props.ticket.slaResponsedStamp!=null && format(parse(this.props.ticket.slaResponsedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm')}</Statistic.Value>
                    <Statistic.Label style={{fontSize: '14pt'}}>RESPONSED</Statistic.Label>
                  </Statistic>
                } />
              </div>
              <div style={{flex: 1, borderLeft: '1px solid #fff'}}>
                <Popup position= 'top left' inverted content={'Periodic Responsed SLA Target.'}
                  trigger={
                  <Statistic size='mini'>
                    <Statistic.Value>{this.props.ticket && this.props.ticket.slaPeriodicResponsedStamp!=null && format(parse(this.props.ticket.slaPeriodicResponsedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm')}</Statistic.Value>
                    <Statistic.Label style={{fontSize: '14pt'}}>PERIODIC</Statistic.Label>
                  </Statistic>
                } />
              </div>
            </div>
            <div style={{display: 'flex', padding: '1em .5em 0 .5em', borderTop: '1px solid #fff'}}>
              <div style={{flex: 1, borderRight: '1px solid #ccc'}}>
                <Popup position= 'top left' inverted content={'Resolved Time SLA Target.'}
                  trigger={
                  <Statistic size='mini'>
                    <Statistic.Value>{this.props.ticket && this.props.ticket.slaSolvedStamp!=null && format(parse(this.props.ticket.slaSolvedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm')}</Statistic.Value>
                    <Statistic.Label style={{fontSize: '14pt'}}>RESOLVED</Statistic.Label>
                  </Statistic>
                } />
              </div>
              <div style={{flex: 1, borderLeft: '1px solid #fff'}}>
                <Popup position= 'top left' inverted content={'Closing Time SLA Target.'}
                  trigger={
                  <Statistic size='mini'>
                    <Statistic.Value>{this.props.ticket && this.props.ticket.slaClosedStamp!=null && format(parse(this.props.ticket.slaClosedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm')}</Statistic.Value>
                    <Statistic.Label style={{fontSize: '14pt'}}>CLOSED</Statistic.Label>
                  </Statistic>
                } />
              </div>
            </div>
          </Segment>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryBox)
