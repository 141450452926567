import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import { competencyOptions, voipOptions, genderOptions,educationOptions,protoOptions }  from '../../common/StaticMasterData'
import DismissableMessage from '../../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField, MaskedField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { listClient } from "../../../actions/halo/masterAction"
import { resetMau, getMau,updateMau, createMau } from "../../../actions/talk/mauAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    mauStore: state.mauStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listClient:(objParam) => dispatch(listClient(objParam)),

    resetMau:(objParam) => dispatch(resetMau(objParam)),
    getMau:(objParam) => dispatch(getMau(objParam)),
    createMau:(objParam) => dispatch(createMau(objParam)),
    updateMau:(objParam) => dispatch(updateMau(objParam)),
  }
}

class MauForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.listClient();

    this.props.resetMau(this.props.mode);
    if (this.props.mode==2) { //update
      this.props.getMau({voipId: this.props.voipId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.mauStore.updateMau!=undefined ? this.props.mauStore.updateMau : {}) : (this.props.mauStore.createMau!=undefined ? this.props.mauStore.createMau : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, voipId } = this.props
    mode==2 ? this.props.updateMau({...values}) : this.props.createMau({...values})
  }
  

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.mauStore.updateMau!=undefined ? this.props.mauStore.updateMau : {}) : (this.props.mauStore.createMau!=undefined ? this.props.mauStore.createMau : {}))
    
    const listClient= (this.props.masterStore.listClient && this.props.masterStore.listClient.progress=='1' ? this.props.masterStore.listClient.data : [])

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {mode==3 && <>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                Generate MAU Account
                <Header.Subheader>Account required to copied to mau proxy platform.</Header.Subheader>
              </Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='clientId' component={SelectField}
                width={8}
                options= {listClient}
                placeholder= 'Client' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='voipGroup' component={SelectField}
              width={8}
              options= {voipOptions('mau')}
              validate={[required]}
              label='Group MAU Account'
              placeholder='Customer Account' />
              <Field name='prefixPattern' component={MaskedField}
              mask='7099999XXX'
              width={8}
              validate={[required]}
              label='Pattern of Prefix Account'
              placeholder='Format of account prefix number' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                MAU Realm
                <Header.Subheader>Realm or Domain Coverage Authentication</Header.Subheader>
              </Header.Content>
            </Header>

            <Form.Group widths='16'>
              <Field name='voipRealm' component={InputField}
              width={8}
              label='Realm'
              placeholder='MAU realm' />
              <Field name='voipProto' component={SelectField}
              width={8}
              options={protoOptions}
              label='Protocol'
              placeholder='Kind of connection protocol' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='voipHost' component={InputField}
              width={8}
              label='Host / Server'
              placeholder='Host domain or ip address' />
              <Field name='voipPort' component={InputField}
              width={8}
              label='Port Number'
              placeholder='Port number to connect' />
            </Form.Group>
          </Segment></>}

          {mode==2 && <>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                Account Credential
                <Header.Subheader>MAU Account Assigned For Customer</Header.Subheader>
              </Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='voipGroup' component={SelectField}
              width={4}
              readOnly
              options= {voipOptions('mau')}
              validate={[required]}
              label='Group MAU Account'
              placeholder='Agent or Public' />
              <Field name='voipAccount' component={InputField}
              width={4}
              label='Account'
              placeholder='User account for mau access' />
              <Field name='voipPasswd' component={InputField}
              width={8}
              label='Password'
              placeholder='Password for mau access' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                MAU Realm
                <Header.Subheader>Realm or Domain Coverage Authentication</Header.Subheader>
              </Header.Content>
            </Header>
            
            <Form.Group widths='16'>
              <Field name='voipRealm' component={InputField}
              width={8}
              label='Realm'
              placeholder='MAU realm' />
              <Field name='voipProto' component={SelectField}
              width={8}
              options={protoOptions}
              label='Protocol'
              placeholder='Kind of connection protocol' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='voipHost' component={InputField}
              width={8}
              label='Host / Server'
              placeholder='Host domain or ip address' />
              <Field name='voipPort' component={InputField}
              width={8}
              label='Port Number'
              placeholder='Port number to connect' />
            </Form.Group>
          </Segment></>}

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Mau' : 'Insert Mau') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.mauStore.getMau!=undefined && state.mauStore.getMau.progress==1 ? state.mauStore.getMau.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'MauForm',	// a unique identifier for this form
  })(MauForm)
  )
)