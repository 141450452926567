import React, { Component } from 'react'
import { Header, Divider, Icon, Label } from 'semantic-ui-react'

const tickCols= [
  { key: '1', value: 'code', text: 'Ticket - Code' },
  { key: '2', value: 'remark', text: 'Ticket - Status' },
  { key: '3', value: 'group', text: 'Ticket - Group' },
  { key: '4', value: 'category', text: 'Ticket - Category' },
  { key: '5', value: 'subject', text: 'Ticket - Subject' },
  { key: '6', value: 'descript', text: 'Ticket - Description' },
  { key: '7', value: 'channel', text: 'Ticket - Contact Channel' },
  { key: '8', value: 'productId', text: 'Ticket - Product / Service' },
  { key: '9', value: 'tags', text: 'Ticket - Tags' },
  { key: '10', value: 'skillsets', text: 'Ticket - Skill Set' },
  { key: '11', value: 'priority', text: 'Ticket - Priority' },
  { key: '12', value: 'severity', text: 'Ticket - Severity' },
  { key: '13', value: 'classification', text: 'Ticket - Classification' },
  { key: '14', value: 'resolution', text: 'Ticket - Resolution' },
]
const assgCols= [
  { key: '1', value: 'assignee.name', text: 'Assignee - Name' },
  { key: '2', value: 'assignee.groupId', text: 'Assignee - Role' },
  { key: '3', value: 'assignee.tags', text: 'Assignee - Tags' },
  { key: '4', value: 'assignee.status', text: 'Assignee - Status' },

  <Divider style={{margin: '0'}}/>,
  { key: '1', value: 'assignee.domain', text: 'Assignee - Domain' },
  { key: '2', value: 'assignee.name', text: 'Assignee - Organization' },
]
const dispCols= [ 
  { key: '1', value: 'dispatcher.name', text: 'Dispatcher - Name' },
  { key: '3', value: 'dispatcher.groupId', text: 'Dispatcher - Role' },
  { key: '4', value: 'dispatcher.tags', text: 'Dispatcher - Tags' },
  { key: '5', value: 'dispatcher.Status', text: 'Dispatcher - Status' },

  <Divider style={{margin: '0'}}/>,
  { key: '1', value: 'dispatcher.domain', text: 'Dispatcher - Domain' },
  { key: '2', value: 'dispatcher.name', text: 'Dispatcher - Organization' },
]
const reqCols= [
  { key: '1', value: 'requester.name', text: 'Requester - Name' },
  { key: '2', value: 'requester.groupId', text: 'Requester - Role' },
  { key: '3', value: 'requester.tags', text: 'Requester - Tags' },
  { key: '4', value: 'requester.Status', text: 'Requester - Status' },

  <Divider style={{margin: '0'}}/>,
  { key: '1', value: 'requester.domain', text: 'Requester - Domain' },
  { key: '2', value: 'requester.name', text: 'Requester - Organization' },
]
const orgCols= [
  { key: '1', value: 'organization.name', text: 'Organization - Name' },
  { key: '2', value: 'organization.groupId', text: 'Organization - Role' },
  { key: '3', value: 'organization.tags', text: 'Organization - Tags' },
  { key: '4', value: 'organization.Status', text: 'Organization - Status' },
]
const csatCols= [
  { key: '1', value: 'rating', text: 'CSAT - Rating' },
  { key: '2', value: 'score', text: 'CSAT - Score' },
  { key: '3', value: 'feedback', text: 'CSAT - Feedback' },
]
const intrCols= [
  { key: '1', value: 'createdStamp', text: 'Ticket - Created' },
  { key: '2', value: 'assignedStamp', text: 'Ticket - Assigned' },
  { key: '3', value: 'updatedStamp', text: 'Ticket - Updated' },
  { key: '4', value: 'duedateStamp', text: 'Ticket - Due Date' },
  { key: '7', value: 'slabreachStamp', text: 'Ticket - Next SLA Breach' },
  { key: '5', value: 'alertStamp', text: 'Ticket - Warned / Alert' },
  { key: '6', value: 'commentedStamp', text: 'Ticket - Commented' },
  { key: '7', value: 'escalatedStamp', text: 'Ticket - Escalated' },
  { key: '7', value: 'pendingStamp', text: 'Ticket - Escalated' },
  { key: '7', value: 'onholdStamp', text: 'Ticket - Escalated' },
  { key: '8', value: 'solvedStamp', text: 'Ticket - Solved' },
  { key: '9', value: 'reopenStamp', text: 'Ticket - Reopen' },
  { key: '10', value: 'closedStamp', text: 'Ticket - Closed' },
]
const tickMetrics= [
  { key: '1', value: 'remark', text: 'Ticket - Status' },
  { key: '2', value: 'priority', text: 'Ticket - Priority' },
  { key: '3', value: 'severity', text: 'Ticket - Severity' },
  { key: '4', value: 'classification', text: 'Ticket - Classification' },
  { key: '5', value: 'resolution', text: 'Ticket - Resolution' },
  { key: '6', value: 'sla', text: 'Ticket - SLA' },
]
const dispMetrics= [
  { key: '1', value: 'dispatcher.userId', text: 'Dispatch - User' },
  { key: '2', value: 'dispatcher.method', text: 'Dispatch - Method' },
]
const reqMetrics= [
  { key: '1', value: 'requester.userId', text: 'Requester - User' },
  { key: '2', value: 'requester.Cluster', text: 'Requester - Cluster' },
  { key: '3', value: 'requester.Channels', text: 'Requester - Channels' },
  { key: '4', value: 'requester.Corporate', text: 'Requester - Corporate' },
]
const assgMetrics= [
  { key: '1', value: 'assignee.userId', text: 'Assignee - User' },
  { key: '2', value: 'assignee.agentId', text: 'Assignee - Agent' },
  { key: '3', value: 'assignee.supportId', text: 'Assignee - Backend / Support' },
]
const skillMetrics= [
  { key: '1', value: 'skillset.routing', text: 'Skillset Routing' },
  { key: '2', value: 'skillset.usage', text: 'Skillset Usage Rate' },
  { key: '3', value: 'skillset.fulfillment', text: 'Skillset Fulfillment Rate' },
]
const csatMetrics= [
  { key: '1', value: 'csat.rating', text: 'CSAT - Rating' },
  { key: '2', value: 'csat.scoring', text: 'CSAT - Scoring' },
  { key: '3', value: 'csat.feedback', text: 'CSAT - Feedback' },
]
const orgMetrics= [
  { key: '1', value: 'organization.name', text: 'Organization - Name' },
  { key: '2', value: 'organization.groupId', text: 'Organization - Role' },
  { key: '3', value: 'organization.tags', text: 'Organization - Tags' },
  { key: '4', value: 'organization.Status', text: 'Organization - Status' },
]
const intrMetrics= [
]
const tickRows= [
  { key: '1', value: 'code', text: 'Ticket - Code' },
  { key: '2', value: 'remark', text: 'Ticket - Status' },
  { key: '3', value: 'group', text: 'Ticket - Group' },
  { key: '4', value: 'category', text: 'Ticket - Category' },
  { key: '5', value: 'subject', text: 'Ticket - Subject' },
  { key: '6', value: 'descript', text: 'Ticket - Description' },
  { key: '7', value: 'channel', text: 'Ticket - Contact Channel' },
  { key: '8', value: 'productId', text: 'Ticket - Product / Service' },
  { key: '9', value: 'tags', text: 'Ticket - Tags' },
  { key: '10', value: 'skillsets', text: 'Ticket - Skill Set' },
  { key: '11', value: 'priority', text: 'Ticket - Priority' },
  { key: '12', value: 'severity', text: 'Ticket - Severity' },
  { key: '13', value: 'classification', text: 'Ticket - Classification' },
  { key: '14', value: 'resolution', text: 'Ticket - Resolution' },
]
const assgRows= [
  { key: '1', value: 'assignee.name', text: 'Assignee - Name' },
  { key: '2', value: 'assignee.groupId', text: 'Assignee - Role' },
  { key: '3', value: 'assignee.tags', text: 'Assignee - Tags' },
  { key: '4', value: 'assignee.status', text: 'Assignee - Status' },

  <Divider style={{margin: '0'}}/>,
  { key: '1', value: 'assignee.domain', text: 'Assignee - Domain' },
  { key: '2', value: 'assignee.name', text: 'Assignee - Organization' },
]
const dispRows= [ 
  { key: '1', value: 'dispatcher.name', text: 'Dispatcher - Name' },
  { key: '3', value: 'dispatcher.groupId', text: 'Dispatcher - Role' },
  { key: '4', value: 'dispatcher.tags', text: 'Dispatcher - Tags' },
  { key: '5', value: 'dispatcher.Status', text: 'Dispatcher - Status' },

  <Divider style={{margin: '0'}}/>,
  { key: '1', value: 'dispatcher.domain', text: 'Dispatcher - Domain' },
  { key: '2', value: 'dispatcher.name', text: 'Dispatcher - Organization' },
]
const reqRows= [
  { key: '1', value: 'requester.name', text: 'Requester - Name' },
  { key: '2', value: 'requester.groupId', text: 'Requester - Role' },
  { key: '3', value: 'requester.tags', text: 'Requester - Tags' },
  { key: '4', value: 'requester.Status', text: 'Requester - Status' },

  <Divider style={{margin: '0'}}/>,
  { key: '1', value: 'requester.domain', text: 'Requester - Domain' },
  { key: '2', value: 'requester.name', text: 'Requester - Organization' },
]
const orgRows= [
  { key: '1', value: 'organization.name', text: 'Organization - Name' },
  { key: '2', value: 'organization.groupId', text: 'Organization - Role' },
  { key: '3', value: 'organization.tags', text: 'Organization - Tags' },
  { key: '4', value: 'organization.Status', text: 'Organization - Status' },
]
const csatRows= [
  { key: '1', value: 'rating', text: 'CSAT - Rating' },
  { key: '2', value: 'score', text: 'CSAT - Score' },
  { key: '3', value: 'feedback', text: 'CSAT - Feedback' },
]
const intrRows= [
  { key: '1', value: 'createdStamp', text: 'Ticket - Created' },
  { key: '2', value: 'assignedStamp', text: 'Ticket - Assigned' },
  { key: '3', value: 'updatedStamp', text: 'Ticket - Updated' },
  { key: '4', value: 'duedateStamp', text: 'Ticket - Due Date' },
  { key: '7', value: 'slabreachStamp', text: 'Ticket - Next SLA Breach' },
  { key: '5', value: 'alertStamp', text: 'Ticket - Warned / Alert' },
  { key: '6', value: 'commentedStamp', text: 'Ticket - Commented' },
  { key: '7', value: 'escalatedStamp', text: 'Ticket - Escalated' },
  { key: '7', value: 'pendingStamp', text: 'Ticket - Escalated' },
  { key: '7', value: 'onholdStamp', text: 'Ticket - Escalated' },
  { key: '8', value: 'solvedStamp', text: 'Ticket - Solved' },
  { key: '9', value: 'reopenStamp', text: 'Ticket - Reopen' },
  { key: '10', value: 'closedStamp', text: 'Ticket - Closed' },
]

// INPUT: > | < | >= | <= | <> | = | LIKE : SOURCE_FIELD OPS VALUE
// SELECT: > | < | >= | <= | <> | = : SOURCE_FIELD OPS VALUE
// SELECTS: IN | NOT IN : SOURCE_FIELD OPS VALUES
// CONTAIN: CONTAINS | NOT CONTAINS : SOURCE_FIELD_VALUES OPS VALUE => MYSQL NOT JSON SQL => POSITION("3" IN "W3Schools.com")
// CONTAINS: CONTAINS | NOT CONTAINS : SOURCE_FIELD_VALUES OPS VALUES => MYSQL NOT JSON SQL => POSITION("3" IN "W3Schools.com")
// TAGGINGS: CONTAINS | NOT CONTAINS : SOURCE_FIELD_VALUES OPS VALUES => MYSQL NOT JSON SQL => POSITION("3" IN "W3Schools.com")

const tickConds=[
  <Header key='a1' as='h5'><Icon name='address book' color='blue' /> USER / ACCOUNT</Header>,
  <Divider key='a2' />,
  // { key: '10', value: 'dispatcherUserId', text: 'Dispatcher User', description: 'User', input: 'selects', options: 'listUser' },
  { key: '11', value: 'requesterUserId', text: 'Requester User', description: 'User', input: 'selects', options: 'listUser' },
  { key: '12', value: 'assigneeUserId', text: 'Assignee User', description: 'User', input: 'selects', options: 'listUser'  },
  { key: '13', value: 'escalationUserId', text: 'Escalation User', description: 'User', input: 'selects', options: 'listUser'  },
  <Divider key='a3' style={{margin: '0'}} />,
  { key: '14', value: 'requesterWorkgroupId', text: 'Requester Team', description: 'Team', input: 'selects', options: 'listWorkgroup' },
  { key: '15', value: 'assigneeWorkgroupId', text: 'Assignee Team', description: 'Team', input: 'selects', options: 'listWorkgroup' },
  { key: '16', value: 'escalationWorkgroupId', text: 'Escalation Team', description: 'Team', input: 'selects', options: 'listWorkgroup' },
  <Divider key='a4' style={{margin: '0'}} />,
  { key: '17', value: 'assigneeUsers', text: 'Assignee Users', description: 'Users', input: 'contains', options: 'listUser' },
  { key: '18', value: 'assigneeWorkgroups', text: 'Assignee Teams', description: 'Teams', input: 'contains', options: 'listWorkgroup' },

  <Header key='a5' as='h5'><Icon name='object ungroup' color='blue' /> REFERENCE</Header>,
  <Divider key='a6' />,
  { key: '19', value: 'organizationId', text: 'Organization', description: 'Ref.', input: 'selects', options: 'listOrganization' },
  { key: '20', value: 'productId', text: 'Product / Service', description: 'Ref.', input: 'selects', options: 'listProduct' },
  
  <Divider key='a7' style={{margin: '0'}} />,
  { key: '21', value: 'formId', text: 'Custom Form', description: 'Ref.', input: 'selects', options: 'listForm' },
  { key: '22', value: 'macroId', text: 'Custom Macro', description: 'Ref.', input: 'selects', options: 'listMacro' },

  <Header key='a8' as='h5'><Icon name='folder' color='blue' /> TICKET INFORMATION</Header>,
  <Divider key='a9'/>,
  { key: '23', value: 'remark', text: 'Ticket Status', description: 'Info', input: 'selects', options: 'listRemark' },
  { key: '24', value: 'channelType', text: 'Contact Channel', description: 'Info', input: 'selects', options: 'listChannel' },
  { key: '25', value: 'channelSupports', text: 'Alternative Channels', description: 'Info', input: 'contains', options: 'listChannel' },

  <Divider key='a10' style={{margin: '0'}} />,
  { key: '26', value: 'priority', text: 'Priority', description: 'Info', input: 'selects', options: 'priorityOptions' },
  { key: '27', value: 'severity', text: 'Severity', description: 'Info', input: 'selects', options: 'severityOptions' },
  { key: '28', value: 'privacy', text: 'Privacy', description: 'Info', input: 'selects', options: 'privacyOptions' },
  { key: '29', value: 'category', text: 'Category', description: 'Info', input: 'selects', options: 'categoryOptions' },

  <Divider key='a11' style={{margin: '0'}} />,
  { key: '30', value: 'skillsets', text: 'Skill Sets', description: 'Info', input: 'taggings' },
  { key: '31', value: 'tags', text: 'Tags / Taggings', description: 'Info', input: 'taggings' },
]
const intrConds=[
  <Header key='b1' as='h5'><Icon name='clock' color='blue' /> SLA / SERVICE LEVEL</Header>,
  <Divider key='b2' />,
  { key: '50', value: 'next_nextSLAStamp', text: 'Mins To Next SLA', description: 'SLA', input: 'duration' },
  { key: '51', value: 'next_slaSolvedStamp', text: 'Mins To Due Date', description: 'SLA', input: 'duration' },
  <Divider key='b3' style={{margin: '0'}}/>,
  { key: '52', value: 'since_lastSLAStamp', text: 'Mins Since Last SLA', description: 'SLA', input: 'duration' },

  <Header key='b3' as='h5'><Icon name='clock' color='blue' /> TIMELINE / DURATION</Header>,
  <Divider key='b4' />,
  { key: '53', value: 'since_createStamp', text: 'Mins Since Created', description: 'Time', input: 'duration' },
  { key: '54', value: 'since_openStamp', text: 'Mins Since Opened', description: 'Time', input: 'duration' },
  { key: '55', value: 'since_pendingStamp', text: 'Mins Since Pending', description: 'Time', input: 'duration' },
  { key: '56', value: 'since_onHoldStamp', text: 'Mins Since On Hold', description: 'Time', input: 'duration' },
  { key: '57', value: 'since_solvedStamp', text: 'Mins Since Solved', description: 'Time', input: 'duration' },
  { key: '58', value: 'since_unsolvedStamp', text: 'Mins Since Unsolved', description: 'Time', input: 'duration' },
  { key: '59', value: 'since_closedStamp', text: 'Mins Since Closed', description: 'Time', input: 'duration' },
  
  { key: '60', value: 'since_periodicResponsedStamp', text: 'Mins Since Last Responsed', description: 'Time', input: 'duration' },
  { key: '61', value: 'since_repliedStamp', text: 'Mins Since Replied', description: 'Time', input: 'duration' },
]
const tickActions=[
  <Header key='a1' as='h5'><Icon name='ticket' color='blue' /> TICKET / ISSUE</Header>,
  <Divider key='a2'/>,
  { key: '11', value: 'set_subject', text: 'Set Subject', description: 'Ticket' },
  { key: '12', value: 'set_descript', text: 'Set Description', description: 'Ticket', input: 'textarea' },
  
  <Divider key='a3' style={{margin: '0'}} />,
  { key: '13', value: 'set_remark', text: 'Set Remark / Status', description: 'Ticket', input: 'select', options: 'listRemark' },
  { key: '14', value: 'set_channelType', text: 'Set Contact Channel', description: 'Ticket', input: 'select', options: 'listChannel' },

  <Divider key='a4' style={{margin: '0'}} />,
  { key: '15', value: 'set_comment', text: 'Set Comment', description: 'Ticket', input: 'textarea' },
  { key: '16', value: 'set_commentMode', text: 'Set Comment Mode', description: 'Ticket', input: 'select', options: 'commentOptions' },

  <Divider key='a5' style={{margin: '0'}} />,
  { key: '17', value: 'set_channelSupports', text: 'Set Alternative Channels', description: 'Ticket', input: 'selects', options: 'listChannel' },

  <Header key='a6' as='h5'><Icon name='users' color='blue' /> PEOPLE</Header>,
  <Divider key='a7' />,
  // { key: '18', value: 'set_dispatcherUserId', text: 'Set Dispatcher User', description: 'User', input: 'select', options: 'listUser' },
  // { key: '19', value: 'set_requesterUserId', text: 'Set Requester User', description: 'User', input: 'select', options: 'listUser' },
  { key: '20', value: 'set_assigneeUserId', text: 'Set Assignee User', description: 'User', input: 'select', options: 'listUser' },
  // { key: '21', value: 'set_escalationUserId', text: 'Set Escalation User', description: 'User', input: 'select', options: 'listUser' },
  
  <Divider key='a8' style={{margin: '0'}} />,
  // { key: '22', value: 'set_dispatcherWorkgroupId', text: 'Set Dispatcher Team', description: 'Team', input: 'select', options: 'listWorkgroup' },
  // { key: '23', value: 'set_requesterWorkgroupId', text: 'Set Requester Team', description: 'Team', input: 'select', options: 'listWorkgroup' },
  { key: '24', value: 'set_assigneeWorkgroupId', text: 'Set Assignee Team', description: 'Team', input: 'select', options: 'listWorkgroup' },
  // { key: '25', value: 'set_escalationWorkgroupId', text: 'Set Escalation Team', description: 'Team', input: 'select', options: 'listWorkgroup' },
  
  <Divider key='a9' style={{margin: '0'}} />,
  { key: '26', value: 'set_assigneeUsers', text: 'Set Assignee Users', description: 'Multi Users', input: 'selects', options: 'listUser' },
  { key: '27', value: 'set_assigneeWorkgroups', text: 'Set Assignee Teams', description: 'Multi Teams', input: 'selects', options: 'listWorkgroup' },
  
  <Header key='b1' as='h5'><Icon name='object ungroup' color='blue' /> REF. OBJECTS</Header>,
  <Divider key='b2' />,
  { key: '28', value: 'set_formId', text: 'Set Form', description: 'Object', input: 'select', options: 'listForm' },
  { key: '29', value: 'set_organizationId', text: 'Organization', description: 'Object', input: 'select', options: 'listOrganization' },
  { key: '30', value: 'set_productId', text: 'Product / Service', description: 'Object', input: 'select', options: 'listProduct' },

  <Header key='b3' as='h5'><Icon name='unlock alternate' color='blue' /> PRIVILEGE</Header>,
  <Divider key='b4' />,
  { key: '31', value: 'set_priority', text: 'Set Priority', description: 'Ticket', input: 'select', options: 'priorityOptions' },
  { key: '32', value: 'set_severity', text: 'Set Severity', description: 'Ticket', input: 'select', options: 'severityOptions' },
  { key: '33', value: 'set_privacy', text: 'Set Privacy', description: 'Ticket', input: 'select', options: 'privacyOptions' },
  { key: '34', value: 'set_category', text: 'Set Category', description: 'Ticket', input: 'select', options: 'categoryOptions' },
  { key: '35', value: 'set_isFinal', text: 'Set Permanent Update', description: 'Ticket', input: 'select', options: 'yesnoOptions' },
  
  <Header key='b5' as='h5'><Icon name='clone' color='blue' /> MULTI VALUES</Header>,
  <Divider key='b6' />,
  { key: '36', value: 'set_skillsets', text: 'Set Skill Sets', description: 'Values', input: 'taggings' },

  <Divider key='b7' style={{margin: '0'}} />,
  { key: '37', value: 'set_tags', text: 'Set Tags', description: 'Values', input: 'taggings' },
  
  <Divider key='b8' style={{margin: '0'}}/>,
  { key: '38', value: 'add_tags', text: 'Append Tags', description: 'Values', input: 'taggings' },

  <Divider key='b9' style={{margin: '0'}}/>,
  { key: '39', value: 'del_tags', text: 'Remove Tags', description: 'Values', input: 'taggings' },
]
const notifyActions=[
  <Header key='c1' as='h5'><Icon name='bell' color='blue' /> NOTIFICATION</Header>,
  <Divider key='c2' />,
  { key: '51', value: 'send_email', text: 'Send Email', description: 'Messaging', input: 'wysiwyg' },
  
  <Divider key='c3' style={{margin: '0'}}/>,
  { key: '52', value: 'send_sms', text: 'Send Texting', description: 'Messaging', input: 'textarea' },
  { key: '53', value: 'send_wa', text: 'Send Whatsapp', description: 'Messaging', input: 'textarea' },
  
  <Divider key='c4' style={{margin: '0'}}/>,
  { key: '54', value: 'send_ivr', text: 'Call IVR Message', description: 'Voice / Call', input: 'textarea' },

  <Divider key='c5' style={{margin: '0'}}/>,
  { key: '55', value: 'to_groups', text: 'Notify To: Role / Group', description: 'Receiver', input: 'selects', options: 'groupOptions' },
  { key: '56', value: 'to_workgroups', text: 'Notify To: Team / Workgroup', description: 'Receiver', input: 'selects', options: 'listWorkgroup' },
]

const slaParams= [
  { key: '1', value: 'First Response Time', text: 'First Response Time', icon: 'clock outline', description: 'First responsed time by Agent on new assigned ticket.' },
  { key: '2', value: 'Next Response Time', text: 'Next Response Time', icon: 'clock outline', description: 'Next responsed time by Agent after ticket being replied by customer.' },
  { key: '3', value: 'Periodic Response Time', text: 'Periodic Response Time', icon: 'clock outline', description: 'Interval responsed time by Agent between each ticket update.' },
  <Divider key='c1' style={{margin: '0'}}/>,
  { key: '4', value: 'Resolve Time', text: 'Ticket Resolve Time', icon: 'clock', description: 'Max. duration time for ticket to be re/solved, exclude paused time.' },
  { key: '5', value: 'Duration Time', text: 'Ticket Duration Time', icon: 'clock', description: 'Max. duration time for ticket to be closed, exclude paused time.' },
]

const tickFields=[
  { name: 'dispatcherUserId', descript: 'Dispatcher User', input: 'select', options: 'listUser' },
  { name: 'requesterUserId', descript: 'Requester User', input: 'select', options: 'listUser' },
  { name: 'assigneeUserId', descript: 'Assignee User', input: 'select', options: 'listUser'  },
  { name: 'escalationUserId', descript: 'Escalation User', input: 'select', options: 'listUser'  },
  { name: 'requesterWorkgroupId', descript: 'Requester Team', input: 'select', options: 'listWorkgroup' },
  { name: 'assigneeWorkgroupId', descript: 'Assignee Team', input: 'select', options: 'listWorkgroup' },
  { name: 'escalationWorkgroupId', descript: 'Escalation Team', input: 'select', options: 'listWorkgroup' },
  { name: 'assigneeUsers', descript: 'Assignee Users', input: 'selects', options: 'listUser' },
  { name: 'assigneeWorkgroups', descript: 'Assignee Teams', input: 'selects', options: 'listWorkgroup' },
  { name: 'productId', descript: 'Product / Service', input: 'select', options: 'listProduct' },
  { name: 'formId', descript: 'Custom Form', input: 'select', options: 'listForm' },
  { name: 'macroId', descript: 'Custom Macro', input: 'select', options: 'listMacro' },

  { name: 'organizationId', descript: 'Organization', input: 'select', options: 'listOrganization' },

  { name: 'name', descript: 'Full Name', input: 'input', options: null },

  { name: 'remark', descript: 'Ticket Status', input: 'select', options: 'listRemark', validations: ['required'] },
  { name: 'classificationId', descript: 'Ticket Classification', input: 'select', options: 'listClassification' },
  { name: 'resolutionId', descript: 'Ticket Resolution', input: 'select', options: 'listResolution' },
  { name: 'reasoningId', descript: 'Ticket Reasoning', input: 'select', options: 'listReasoning' },

  { name: 'channelType', descript: 'Contact Channel', input: 'select', options: 'listChannel' },
  { name: 'channelSupports', descript: 'Contact Channels', input: 'selects', options: 'listChannel' },

  { name: 'priority', descript: 'Priority', input: 'select', options: 'priorityOptions' },
  { name: 'severity', descript: 'Severity', input: 'select', options: 'severityOptions' },
  { name: 'privacy', descript: 'Privacy', input: 'select', options: 'privacyOptions' },
  { name: 'category', descript: 'Category', input: 'select', options: 'categoryOptions' },

  { name: 'skillsets', descript: 'Skill Sets', input: 'taggings' },
  { name: 'tags', descript: 'Tags / Taggings', input: 'taggings' },
]

export const groupOptions = (v)=>{
  if (v=='Admin') {
    
  } else {
    return(
      [ 
        { key: '1', value: 'Agent', text: 'Agent',  description: 'Frontliner' },
        { key: '2', value: 'Support', text: 'Support',  description: 'Operation / Business' },
        { key: '3', value: 'Backend', text: 'Backend',  description: 'Backend support' },
        { key: '4', value: 'Viewer', text: 'Viewer',  description: 'Read only access' },
        { key: '5', value: 'Executive', text: 'Executive',  description: 'Management' },
        { key: '6', value: 'Client', text: 'Admin Realm',  description: 'Super user' },
      ]
    )
  }
}
export const conditionOptions = (v, o)=>{
  switch (v) {
    case 'sla-param':
      return([...slaParams])
    case 'bucket-param':
      return([
        ...tickConds, 
        ...intrConds
      ])
    case 'forms-param':
      return([...tickConds])
    case 'routing-param':
      return([...tickConds])
    case 'trigger-param':
      return([...tickConds])
    case 'automation-param':
      return([...intrConds, ...tickConds])
    case 'routing-ops':
    case 'forms-ops':
    case  'ops':
      if (o=='selects') {
        return(
          [ 
            { key: '1', value: 'IN', text: 'In' },
            { key: '2', value: 'NOTIN', text: 'Not In' },
          ]
        )
      } else if (o=='contain' || o=='contains' || o=='taggings') {
        return(
          [ 
            { key: '1', value: 'CONTAINS', text: 'Contains' },
            { key: '2', value: 'NOTCONTAINS', text: 'Not Contains' },
          ]
        )
      } else if (o=='duration') {
        return(
          [ 
            { key: '1', value: '=', text: 'Equal' },
            { key: '2', value: '<', text: 'Lower' },
            { key: '3', value: '>', text: 'Greater' },
            { key: '4', value: '<>', text: 'Not Equal' },
            { key: '5', value: '<=', text: 'Lower / Equal' },
            { key: '6', value: '>=', text: 'Greater / Equal' },
          ]
        )
      } else {
        return(
          [ 
            { key: '1', value: '=', text: 'Equal' },
            { key: '2', value: '<', text: 'Lower' },
            { key: '3', value: '>', text: 'Greater' },
            { key: '4', value: '<>', text: 'Not Equal' },
            { key: '5', value: '<=', text: 'Lower / Equal' },
            { key: '6', value: '>=', text: 'Greater / Equal' },
            // { key: '7', value: 'LIKE', text: 'Like / Similar' },
          ]
        )
      }
    default:
      return([])
  }
}
export const eventOptions = (v)=>{
  switch (v) {
    case 'macro':
      return([
        { key: '1', value: '-', text: 'Dummy Macro Event' },
      ])
    case 'trigger':
      return([
        { key: '1', value: '-', text: 'Dummy Trigger Event' },
      ])
    case 'automation':
      return([
        { key: '1', value: '-', text: 'Dummy Automation Event' },
      ])
    default:
      return([])
  }
}
export const actionOptions = (v)=>{
  switch (v) {
    case 'macro-param':
      return([...tickActions])
    case 'trigger-param':
      return([...tickActions])
    case 'automation-param':
      return([...notifyActions, ...tickActions])
    default:
      return([])
  }
}
export const fieldOptions = (v)=>{
  switch (v) {
    case 'type':
      return([
        { key: '1', value: 'number', text: 'Number' },
        { key: '2', value: 'string', text: 'String' },
        { key: '3', value: 'select', text: 'Dropdown' },
        { key: '3', value: 'multiple', text: 'Multiple Select' },
      ])
    case 'validation':
      return([
        { key: '1', value: 'email', text: 'Email' },
        { key: '2', value: 'phoneNumberID', text: 'Phone Number / ID Only' },
        <Divider key='a' style={{margin: '0'}} />,
        { key: '3', value: 'number', text: 'Number' },
        { key: '4', value: 'date', text: 'Date' },
        { key: '5', value: 'alphaNumeric', text: 'String / Alpha Numeric' },
        <Divider key='b' style={{margin: '0'}} />,
        { key: '6', value: 'required', text: 'Required' },
        <Divider key='c' style={{margin: '0'}} />,
        { key: '7', value: 'regex', text: 'Custom Validation' },
      ])
    case 'primary':
      return([
        <Header key='x1' as='h5'><Icon name='address card' color='blue' /> REQUESTER</Header>,
        <Divider key='x2' />,
        { key: '10', value: 'blockPersonal', text: 'Requester - Personal', description: 'Field Group' },
        { key: '11', value: 'blockCustomer', text: 'Requester - Customer', description: 'Field Group' },
        { key: '12', value: 'blockCorporate', text: 'Requester - Corporate', description: 'Field Group' },
        { key: '13', value: 'blockAddress', text: 'Requester - Address', description: 'Field Group' },
        
        <Header key='x4'><Icon name='ticket' color='blue' /> TICKET / ISSUE</Header>,
        <Divider key='x5' />,
        { key: '14', value: 'blockBrand', text: 'Ticket - Brand/Channel', description: 'Field Group' },
        { key: '15', value: 'blockSeverity', text: 'Ticket - Severity/Impact', description: 'Field Group' },

        <Divider key='x6' style={{margin: '0'}} />,
        { key: '16', value: 'remark', text: 'Ticket - Status', description: 'Field' },
        { key: '17', value: 'classificationId', text: 'Ticket - Classification', description: 'Field' },
        <Divider key='x7' style={{margin: '0'}} />,
        { key: '18', value: 'tags', text: 'Ticket - Tags', description: 'Field' },
        { key: '19', value: 'skillsets', text: 'Ticket - Skillsets', description: 'Field' },
        // <Divider key='x8' style={{margin: '0'}} />,
        // { key: '20', value: 'channelType', text: 'Ticket - ChannelType', description: 'Field' },
        // { key: '21', value: 'channelSupports', text: 'Ticket - ChannelSupports', description: 'Field' },
      ])
    case 'secondary':
      return([
        <Header key='x1' as='h5'><Icon name='ticket' color='blue' /> TICKET / ISSUE</Header>,
        <Divider key='x2' />,
        { key: '30', value: 'remark', text: 'Ticket - Status', description: 'Field' },
        { key: '31', value: 'classificationId', text: 'Ticket - Classification', description: 'Field' },
        <Divider key='x3' style={{margin: '0'}} />,
        { key: '32', value: 'tags', text: 'Ticket - Tags', description: 'Field' },
        { key: '33', value: 'skillsets', text: 'Ticket - Skillsets', description: 'Field' },
      ])
    case 'grouping':
      return([
        //REQUESTER
        <Header key='a3' as='h5'>
          <Icon name='address card' color='blue' />
          <Header.Content>
            REQUESTER
            <Header.Subheader>Shown in requester column</Header.Subheader>
          </Header.Content>
        </Header>,
        <Divider key='a2' />,
        { key: '1', value: 'Requester', text: 'Requester', description: 'Requester' },
        // { key: '2', value: 'Personal', text: 'Personal', description: 'Requester' },
        // { key: '3', value: 'Customer', text: 'Customer', description: 'Requester' },
        // { key: '4', value: 'Corporate', text: 'Corporate', description: 'Requester' },
        // { key: '5', value: 'Contacts', text: 'Contacts', description: 'Requester' },
        { key: '5', value: 'Subscription', text: 'Subscription', description: 'Requester' },
        { key: '7', value: 'Service', text: 'Service/Product', description: 'Requester' },
        // { key: '8', value: 'Product', text: 'Product', description: 'Requester' },

        //TICKET
        <Header key='a3' as='h5'>
          <Icon name='ticket' color='blue' />
          <Header.Content>
            TICKET / ISSUE
            <Header.Subheader>Shown in ticket column</Header.Subheader>
          </Header.Content>
        </Header>,
        <Divider key='a4' />,
        { key: '9', value: 'Ticket', text: 'Ticket', description: 'Ticket' },
        { key: '10', value: 'Impact', text: 'Impact', description: 'Ticket' },
        { key: '11', value: 'Extended', text: 'Extended', description: 'Ticket' },
        { key: '12', value: 'Prospect', text: 'Prospect', description: 'Ticket' },
      ])
    default:
      return([])
  }
}

export const ratingOptions = (v)=>{
  switch (v) {
    case 'level':
      return([
        { key: '1', value: 2, text: '2' },
        { key: '2', value: 3, text: '3' },
        { key: '3', value: 5, text: '5' },
      ])
    case 'score':
      return([
        { key: '1', value: 1, text: '1' },
        { key: '2', value: 5, text: '5' },
        { key: '3', value: 10, text: '10' },
      ])
    case 'score-min':
      return([
        { key: '1', value: 0, text: '0' },
        { key: '2', value: 2, text: '2' },
        { key: '3', value: 4, text: '4' },
      ])
    case 'icon':
      return([
        { key: '1', value: 'star', text: 'Star' },
        { key: '2', value: 'thumbs up outline', text: 'Like' },
        { key: '3', value: 'thumbs down outline', text: 'Dislike' },
      ])
    default:
      return([])
  }
}
export const explorerOptions = (v, k)=>{
  switch (v) {
    case 'period':
      return([
        { key: '1', value: 'daily', text: 'Daily' },
        { key: '2', value: 'monthly', text: 'Monthly' },
        { key: '3', value: 'last10days', text: 'Last 10 Days' },
        { key: '4', value: 'last30days', text: 'Last 30 Days' },
      ])
    case 'ui':
      return([
        { key: '1', value: 'pie', text: 'Pie chart' },
        { key: '2', value: 'radial', text: 'Radial Chart' },
        { key: '3', value: 'bar', text: 'Bar Chart' },
        { key: '4', value: 'line', text: 'Line Chart' },
      ])
    case 'export':
      return([
        { key: '1', value: 'csv', text: 'CSV' },
        { key: '2', value: 'pdf', text: 'PDF' },
        { key: '3', value: 'xls', text: 'Excel' },
      ])
    case 'filter':
      return([
        { key: '1', value: 'remark', text: 'Ticket Status' },
        { key: '2', value: 'priority', text: 'Priority' },
        { key: '3', value: 'severity', text: 'Severity' },
        { key: '4', value: 'assigneeId', text: 'Assignee' },
        { key: '5', value: 'workgroupId', text: 'Team / Workgroup' },
      ])
    case 'rowtype':
      return([
        { key: '1', value: 'sheet', text: 'Data Sheet' },
        { key: '2', value: 'summary', text: 'Summary / Aggregate' },
      ])
    case 'source':
      return([
        { key: '1', value: 'ticket', text: 'Tickets' },
        { key: '2', value: 'dispatcher', text: 'Dispatcher' },
        { key: '3', value: 'requester', text: 'Requester' },
        { key: '4', value: 'assignee', text: 'Assignee' },
        { key: '5', value: 'interaction', text: 'Interaction' },
        // { key: '4', value: 'user', text: 'User & Organization' },
        // { key: '5', value: 'skillset', text: 'Skillset & Routing' },
      ])
    case 'column':
      if (k=='ticket') {
        return(tickCols)
      } else if (k=='dispatcher') {
        return(dispCols)
      } else if (k=='assignee') {
        return(assgCols)
      } else if (k=='requester') {
        return(reqCols)
      } else if (k=='organization') {
        return(orgCols)
      } else if (k=='csat') {
        return(csatCols)
      } else if (k=='interaction') {
        return(intrCols)
      } else {
        return([])
      }
    case 'metric':
      if (k=='ticket') {
        return(tickMetrics)
      } else if (k=='dispatcher') {
        return(dispMetrics)
      } else if (k=='assignee') {
        return(assgMetrics)
      } else if (k=='requester') {
        return(reqMetrics)
      } else if (k=='organization') {
        return(orgMetrics)
      } else if (k=='csat') {
        return(csatMetrics)
      } else if (k=='interaction') {
        return(intrMetrics)
      } else {
        return([])
      }
    case 'metric-function':  
    return([
      { key: '1', value: 'sum', text: 'Sum', description: 'Summ All' },
      { key: '2', value: 'avg', text: 'Average', description: 'Average All' },
      <Divider key='a1' style={{margin: '0'}}/>,
      { key: '3', value: 'count', text: 'Count', description: 'Count All' },
      { key: '4', value: 'count_distinct', text: 'Count_Unique', description: 'Count Unique' },
      <Divider key='a2' style={{margin: '0'}}/>,
      { key: '5', value: 'min', text: 'Minimum', description: 'Min. Value ' },
      { key: '6', value: 'max', text: 'Maximum', description: 'Max. Value' },
    ])
    case 'groupby':
      if (k=='ticket') {
        return(tickRows)
      } else if (k=='dispatcher') {
        return(dispRows)
      } else if (k=='assignee') {
        return(assgRows)
      } else if (k=='requester') {
        return(reqRows)
      } else if (k=='organization') {
        return(orgRows)
      } else if (k=='csat') {
        return(csatRows)
      } else if (k=='interaction') {
        return(intrRows)
      } else {
        return([])
      }
    case 'drill':
      if (k=='ticket') {
        return(tickCols)
      } else if (k=='dispatcher') {
        return(dispCols)
      } else if (k=='assignee') {
        return(assgCols)
      } else if (k=='requester') {
        return(reqCols)
      } else if (k=='organization') {
        return(orgCols)
      } else if (k=='csat') {
        return(csatCols)
      } else if (k=='interaction') {
        return(intrCols)
      } else {
        return([])
      }
    case 'widget':
      return([
        <Header as='h5'><Icon name='ticket' color='blue' />TICKET / ISSUE</Header>,
        <Divider />,
        { key: '1', value: 'ticket-summary', text: 'Ticket Summary' },
        <Divider style={{margin: '0'}} />,
        { key: '1', value: 'ticket-status', text: 'Ticket Status' },
        { key: '1', value: 'ticket-group', text: 'Ticket Group' },
        { key: '1', value: 'ticket-category', text: 'Ticket Category' },
        { key: '1', value: 'ticket-priority', text: 'Ticket Priority' },
        { key: '1', value: 'ticket-severity', text: 'Ticket Severity' },
        { key: '1', value: 'ticket-classification', text: 'Ticket Classification' },
        { key: '1', value: 'ticket-resolution', text: 'Ticket Resolution' },
        <Divider style={{margin: '0'}} />,
        { key: '1', value: 'ticket-channel', text: 'Ticket Channel' },
        { key: '1', value: 'ticket-service', text: 'Ticket Service' },
        <Divider style={{margin: '0'}} />,
        { key: '1', value: 'ticket-duration', text: 'Ticket Duration' },

        <Header as='h5'><Icon name='wait' color='blue' />SLA / SERVICE LEVEL</Header>,
        <Divider />,
        { key: '1', value: 'sla-compliance', text: 'SLA Compliance' },

        <Header as='h5'><Icon name='star' color='blue' />CSAT</Header>,
        <Divider />,
        { key: '1', value: 'csat-rating', text: 'CSAT Rating' },
        { key: '1', value: 'csat-scoring', text: 'CSAT Scoring' },
        { key: '1', value: 'csat-feedback', text: 'CSAT Feedback' },

        <Header as='h5'><Icon name='chart area' color='blue' />Historical</Header>,
        <Divider />,
        { key: '1', value: 'history-sla', text: 'Historical - SLA' },
        { key: '1', value: 'history-csat', text: 'Historical - CSAT' },
        <Divider style={{margin: '0'}} />,
        { key: '1', value: 'history-ticket', text: 'Historical - Ticket' },
        { key: '1', value: 'history-workload', text: 'Historical - Workload' },
        { key: '1', value: 'history-performance', text: 'Historical - Performance' },
      ])
    default:
      return([])
  }
}

export const calendarOptions = (v)=>{
  switch (v) {
    case 'day':
      return([
        { key: '1', value: '1', text: 'Monday' },
        { key: '2', value: '2', text: 'Tuesday' },
        { key: '3', value: '3', text: 'Wednesday' },
        { key: '4', value: '4', text: 'Thursday' },
        { key: '5', value: '5', text: 'Friday' },
        { key: '6', value: '6', text: 'Saturday' },
        { key: '7', value: '7', text: 'Sunday' },
      ])
    case 'day-array':
      return(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'])
    case 'hour':
      return([
        { key: '1', value: '07:00', text: '07:00' },
        { key: '2', value: '08:00', text: '08:00' },
        { key: '3', value: '09:00', text: '09:00' },
        { key: '4', value: '16:00', text: '16:00' },
        { key: '5', value: '17:00', text: '17:00' },
      ])
    default:
      return([])
  }
}

export const organizationOptions= (v)=>{
  switch (v) {
    case 'organization':
      return([
        { key: '1', value: 'Corporate', text: 'Corporate' },
        { key: '2', value: 'BOD', text: 'BOD' },
        { key: '3', value: 'Division', text: 'Division' },
        { key: '4', value: 'Department', text: 'Department' },
        { key: '5', value: 'Unit', text: 'Unit' },
      ])
    case 'business':
      return([
        { key: '1', value: 'Cost', text: 'Cost Center' },
        <Divider key='a' style={{margin: '0'}}/>,
        { key: '2', value: 'Non Profit', text: 'Non Profit' },
        { key: '3', value: 'Profit', text: 'Profit Center' },
      ])
    default:
      return([])
  }
}

export const yesnoOptions= (v)=>{
  return([
    {key: 1, value: '1', text: 'Yes'},
    {key: 2, value: '0', text: 'No'},
  ])
}
export const ticketOptions= (v, map)=>{
  switch (v) {
    case 'event':
      return([
        { key: '1', value: 'created', text: 'Created' },
        { key: '2', value: 'opened', text: 'Opened' },
        { key: '3', value: 'pending', text: 'Pending' },
        { key: '4', value: 'escalated', text: 'Escalated' },
        { key: '5', value: 'solved', text: 'Solved' },
        { key: '6', value: 'closed', text: 'Closed' },
      ])
    case 'priority':
      if (map) {
        return(
          new Map()
          .set('1', { key: '1', value: '1', text: 'Urgent', color: 'red' })
          .set('2', { key: '2', value: '2', text: 'High', color: 'pink' })
          .set('3', { key: '3', value: '3', text: 'Normal', color: 'blue' })
          .set('4', { key: '4', value: '4', text: 'Low', color: 'grey' })
        )
      } else {
        return([
          { key: '1', value: '1', text: 'Urgent', color: 'red' },
          { key: '2', value: '2', text: 'High', color: 'pink' },
          { key: '3', value: '3', text: 'Normal', color: 'blue' },
          { key: '4', value: '4', text: 'Low', color: 'grey' },
        ])
      }
    case 'severity':
      if (map) {
        return(
          new Map()
          .set('1', { key: '1', value: '1', text: 'Critical Impact', color: 'red' })
          // .set('2_Customer', { key: '2', value: '2_Customer', text: 'Customer Impact', color: 'yellow' })
          // .set('2_Business', { key: '3', value: '2_Business', text: 'Business Impact', color: 'yellow' })
          .set('2', { key: '4', value: '2', text: 'Significant Impact', color: 'yellow' })
          .set('3', { key: '5', value: '3', text: 'Minor Impact', color: 'brown' })
          .set('4', { key: '6', value: '4', text: 'Low Impact', color: 'grey' })
        )
      } else {
        return([
          { key: '1', value: '1', text: 'Critical Impact', color: 'red' },
          <Divider key='a1' style={{margin: '0'}} />,
          // { key: '2', value: '2_Customer', text: 'Customer Impact', color: 'yellow' },
          // { key: '3', value: '2_Business', text: 'Business Impact', color: 'yellow' },
          { key: '4', value: '2', text: 'Significant Impact', color: 'yellow' },
          <Divider key='a2' style={{margin: '0'}} />,
          { key: '5', value: '3', text: 'Minor Impact', color: 'brown' },
          { key: '6', value: '4', text: 'Low Impact', color: 'grey' },
        ])
      }
    case 'dispatch':
      return([ 
        { key: '0', value: 'bot', text: 'Bot' },
        { key: '1', value: 'agent', text: 'Agent' },
        { key: '2', value: 'workgroup', text: 'Workgroup' },
      ])
    case 'escalation':
      return([ 
        { key: '0', value: 'single', text: 'Single' },
        { key: '1', value: 'split', text: 'Split' },
      ])
    case 'composition':
      return([ 
        { key: '0', value: 'split', text: 'Split' },
        { key: '1', value: 'merge', text: 'Merge' },
      ])
    case 'impact':
      return([ 
        { key: '0', value: 'Downtime', text: 'Downtime' },
        { key: '1', value: 'Intermitten', text: 'Intermitten' },
        { key: '2', value: 'Error', text: 'Error Result' },
        { key: '3', value: 'Warning', text: 'Warning Only' },
      ])
    case 'scope':
      return([ 
        { key: '0', value: 'Local', text: 'Local' },
        { key: '1', value: 'Global', text: 'Global / All' },
      ])
    case 'remark':
      return([ 
        { key: 1, value: 'New', text: 'New', description: 'New ticket with no assignee' },
        { key: 2, value: 'Open', text: 'Open', description: 'Responsed by assignee and no reply yet' },
        <Divider key='x1' style={{margin: '0'}}/>,
        { key: 3, value: 'Pending', text: 'Pending', description: 'Waiting requester update' },
        { key: 4, value: 'On Hold', text: 'On Hold', description: 'Waiting escalation to be done' },
        <Divider key='x2' style={{margin: '0'}}/>,
        { key: 5, value: 'Solved', text: 'Solved', description: 'Solved with certain resolution' },
        { key: 6, value: 'Unsolved', text: 'Unsolved', description: 'No solution available' },
        { key: 7, value: 'Closed', text: 'Closed', description: 'Closed after being solved or unsolved' },
        <Divider key='x3' style={{margin: '0'}}/>,
        { key: 8, value: 'Warning', text: 'Warning', description: 'Break service level or overdue' },
        <Divider key='x4' style={{margin: '0'}}/>,
        { key: 9, value: 'Assigned', text: 'Assigned', description: 'New ticket been assigned' },
        { key: 10, value: 'Unassigned', text: 'Unassigned', description: 'New ticket with no assignee' },
        <Divider key='x5' style={{margin: '0'}}/>,
      ])
    case 'category':
      return([
        {key: 1, value: 'Incident', text: 'Incident'},
        {key: 2, value: 'Information', text: 'Information'},
        {key: 3, value: 'Purchase', text: 'Purchase'},
      ]);
    case 'privacy':
      return([
        {key: 2, value: 'Public', text: 'Public', description: 'Cust. / Visitor'},
        {key: 1, value: 'Internal', text: 'Internal', description: 'Internal Only'},
      ]);
    case 'color':
      return([
        {key: 1, icon: <Icon name='square' color='red' />, value: 'red', text: 'Red'},
        {key: 2, icon: <Icon name='square' color='orange' />, value: 'orange', text: 'Orange'},
        {key: 3, icon: <Icon name='square' color='pink' />, value: 'pink', text: 'Pink'},
        {key: 4, icon: <Icon name='square' color='purple' />, value: 'purple', text: 'Purple'},
        {key: 5, icon: <Icon name='square' color='blue' />, value: 'blue', text: 'Blue'},
        {key: 6, icon: <Icon name='square' color='teal' />, value: 'teal', text: 'Teal'},
        {key: 7, icon: <Icon name='square' color='green' />, value: 'green', text: 'Green'},
        {key: 8, icon: <Icon name='square' color='yellow' />, value: 'yellow', text: 'Yellow'},
        {key: 9, icon: <Icon name='square' color='olive' />, value: 'olive', text: 'Olive'},
        {key: 10, icon: <Icon name='square' color='brown' />, value: 'brown', text: 'Brown'},
        {key: 11, icon: <Icon name='square' color='grey' />, value: 'grey', text: 'Grey'},
        {key: 12, icon: <Icon name='square' color='black' />, value: 'black', text: 'Black'},
      ]);
    case 'sequence':
      return([
        {key: 1, value: 1, text: '1'},
        {key: 2, value: 2, text: '2'},
        {key: 3, value: 3, text: '3'},
        {key: 4, value: 4, text: '4'},
        {key: 5, value: 5, text: '5'},
        {key: 6, value: 6, text: '6'},
        {key: 7, value: 7, text: '7'},
        {key: 8, value: 8, text: '8'},
        {key: 9, value: 9, text: '9'},
        {key: 10, value: 10, text: '10'},
      ]);
    case 'slabreach':
      return([
        { key: '1', value: 'OK', text: 'Complied' },
        { key: '2', value: 'NOK', text: 'Breached' },
      ])
      default:
      return([])
  }
}
export const commentOptions= (v)=>{
  return([
    { key: 1, value: 'Internal', text: 'Internal / Side Conversation' },
    { key: 2, value: 'Public', text: 'Public / Response to Requester' },
  ])
}
export const localOptions= (v)=>{
  switch (v) {
    case 'country':
      return([
        { key: 1, value: 'id', text: 'Indonesia' },
        { key: 2, value: 'us', text: 'USA' },
        { key: 3, value: 'en', text: 'England' },
      ])
    case 'language':
      return([
        { key: 1, value: 'id', text: 'Bahasa' },
        { key: 2, value: 'en', text: 'English' },
      ])
    case 'currency':
      return([
        { key: 1, value: 'idr', text: 'Rp.' },
        { key: 2, value: 'usd', text: 'USD' },
      ])
    case 'thousand':
      return([
        {key: 1, value: ',', text: ', (comma)'},
        {key: 2, value: '.', text: '. (dot)'},
        {key: 3, value: ' ', text: ' (space)'},
      ]);
    case 'decimal':
      return([
        {key: 1, value: '.', text: '. (dot)'},
        {key: 2, value: ',', text: ', (comma)'},
      ]);
    case 'rounding':
      return([
        {key: 1, value: '0', text: '0 Decimal Digit'},
        {key: 2, value: '1', text: '1 Decimal Digit'},
        {key: 3, value: '2', text: '2 Decimal Digit'},
        {key: 4, value: '3', text: '3 Decimal Digit'},
      ]);
    case 'time':
      return([
        {key: 1, value: 'hh:mm', text: '02:00 PM'},
        {key: 2, value: 'HH:mm', text: '14:00'},
      ]);
    case 'zone':
      return([
        {key: 1, value: '7', text: 'GMT +7 / WIB - Jakarta / Asia'},
        {key: 2, value: '8', text: 'GMT +8 / WITA - Ujung Pandang / Asia'},
        {key: 2, value: '9', text: 'GMT +9 / WIT - Papua / Asia'},
      ]);
    case 'date':
      return([
        {key: 1, value: 'DD/MMM yyyy', text: '29 / Apr 2023'},
        {key: 2, value: 'MMM DD yyyy', text: 'Apr 29 2023'},
        {key: 3, value: 'DD MMM yyyy', text: '29 April 2023'},
      ]);
    default:
      return([])
  }
}

export const personalOptions= (v)=>{
  switch (v) {
    case 'idcard':
      return([
        { key: 1, value: 'KTP', text: 'KTP' },
        { key: 2, value: 'SIM', text: 'SIM' },
        { key: 3, value: 'Passport', text: 'Passport' },
      ])
    case 'citizenship':
      return([
        { key: 1, value: 'WNI', text: 'WNI' },
        { key: 2, value: 'WNA', text: 'WNA' },
      ])
    case 'gender':
      return([
        { key: 1, value: 'Female', text: 'Female' },
        { key: 2, value: 'Male', text: 'Male' },
      ])
    case 'occupation':
      return([
        {key: 1, value: 'Professional', text: 'Professional'},
        {key: 2, value: 'Entrepreneur', text: 'Entrepreneur'},
      ]);
    default:
      return([])
  }
}

export const bucketOptions= (v)=>{
  switch (v) {
    case 'user':
      return([
        { key: 1, value: 'New', text: 'New' },
        { key: 2, value: 'Open', text: 'Open' },
        <Divider key='x1' style={{margin: '0'}}/>,
        { key: 3, value: 'Pending', text: 'Pending' },
        { key: 4, value: 'On Hold', text: 'On Hold' },
        <Divider key='x2' style={{margin: '0'}}/>,
        { key: 5, value: 'Solved', text: 'Solved' },
        { key: 6, value: 'Unsolved', text: 'Unsolved' },
        { key: 7, value: 'Closed', text: 'Closed' },
        <Divider key='x3' style={{margin: '0'}}/>,
        { key: 8, value: 'Warning', text: 'Warning' },
        <Divider key='x4' style={{margin: '0'}}/>,
        { key: 9, value: 'Requested', text: 'Requested' },
        { key: 10, value: 'Assigned', text: 'Assigned' },
        { key: 11, value: 'Escalated', text: 'Escalated' },
        <Divider key='x5' style={{margin: '0'}}/>,
        { key: 12, value: 'SLA Breach', text: 'SLA Breach' },
        { key: 13, value: 'SLA Complied', text: 'SLA Complied' },
      ])
    case 'group':
      return([
        { key: 1, value: 'New', text: 'New' },
        { key: 2, value: 'Open', text: 'Open' },
        <Divider key='x1' style={{margin: '0'}}/>,
        { key: 3, value: 'Pending', text: 'Pending' },
        { key: 4, value: 'On Hold', text: 'On Hold' },
        <Divider key='x2' style={{margin: '0'}}/>,
        { key: 5, value: 'Solved', text: 'Solved' },
        { key: 6, value: 'Unsolved', text: 'Unsolved' },
        { key: 7, value: 'Closed', text: 'Closed' },
        <Divider key='x3' style={{margin: '0'}}/>,
        { key: 8, value: 'Warning', text: 'Warning' },
        <Divider key='x4' style={{margin: '0'}}/>,
        { key: 9, value: 'Assigned', text: 'Assigned' },
        { key: 10, value: 'Unassigned', text: 'Unassigned' },
        { key: 11, value: 'Escalated', text: 'Escalated' },
        <Divider key='x5' style={{margin: '0'}}/>,
        { key: 12, value: 'SLA Breach', text: 'SLA Breach' },
        { key: 13, value: 'SLA Complied', text: 'SLA Complied' },
      ])
    default:
      return([])
  }
}

export const fieldMaps= (v)=>{
  const map= new Map();

  switch (v) {
    case 'ticket':
      tickFields.map(item=>{
        map.set(item.name, item)
      })
      return(map);
    case '':
      return(map);
    default:
  }
}

export const recentOptions= (v)=>{
  switch (v) {
    case 'recent':
      return([
        { key: 1, value: 'today', text: 'Today' },
        { key: 2, value: '24H', text: 'Last 24 Hours' },
        <Divider key='x1' style={{margin: '0'}}/>,
        { key: 3, value: '10D', text: 'Last 10 Days' },
        { key: 4, value: '30D', text: 'Last 30 Days' },
      ])
    case 'period':
      return([
        { key: 1, value: 'today', text: 'Today' },
        { key: 2, value: 'this week', text: 'This Week' },
        <Divider key='x1' style={{margin: '0'}}/>,
        { key: 3, value: 'this month', text: 'This Month' },
        { key: 4, value: 'this year', text: 'This Year' },
      ])
    default:
      return([
        { key: 1, value: 'today', text: 'Today' },
        { key: 2, value: '24H', text: 'Last 24 Hours' },
        <Divider key='x1' style={{margin: '0'}}/>,
        { key: 3, value: '10D', text: 'Last 10 Days' },
        { key: 4, value: '30D', text: 'Last 30 Days' },
      ])
  }
}