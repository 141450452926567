import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table, Feed, Label } from 'semantic-ui-react'

import DismissableMessage from '../../../common/DismissableMessage'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, LabelSelectField } from '../../../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../../../validation/validation'

import { connect } from "react-redux"
import { checkToken } from "../../../../actions/auth/authAction"
import { resetTicket, getTicket,updateTicket, createTicket } from "../../../../actions/service/ticketAction"
import { resetInteraction, getInteraction,updateInteraction, createInteraction } from "../../../../actions/service/interactionAction"
import { listUser, listWorkgroup } from "../../../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ShareBox')

const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    masterStore: state.masterStore,
    ticketStore: state.ticketStore,
    interactionStore: state.interactionStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),

    listUser:(objParam) => dispatch(listUser(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),

    resetTicket:(objParam) => dispatch(resetTicket(objParam)),
    getTicket:(objParam) => dispatch(getTicket(objParam)),
    createTicket:(objParam) => dispatch(createTicket(objParam)),
    updateTicket:(objParam) => dispatch(updateTicket(objParam)),

    resetInteraction:(objParam) => dispatch(resetInteraction(objParam)),
    getInteraction:(objParam) => dispatch(getInteraction(objParam)),
    createInteraction:(objParam) => dispatch(createInteraction(objParam)),
    updateInteraction:(objParam) => dispatch(updateInteraction(objParam)),
  }
}

class ShareBox extends Component {
  constructor(props) {
    super(props)
    this.state= {
      isAgent: 1,
      privacy: 'internal',
    }
  }

  componentDidMount(){
    console.log('!!! DID MOUNT', this.props.ticket, this.props.action)

    this.props.resetInteraction(this.props.mode)
    this.props.listUser()
    this.props.listWorkgroup()
  }

  componentDidUpdate() {
    const { progress, code, text }= (this.props.interactionStore.createInteraction!=undefined ? this.props.interactionStore.createInteraction : {})

    if (progress==1 && code=='00') {
      // setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    values['action']= ((this.props.share && 'share') || 'transfer');
    values['owner']= this.checkRole();
    values['privacy']= this.state.privacy;
    values['ticketId']= (this.props.ticket && this.props.ticket.ticketId) || (this.props.action && this.props.action.ticketId)

    this.props.createInteraction({...values})
  }

  checkRole() {
    const token= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken.data : {}
    const ticket= this.props.ticket
    if (ticket) {
      //requesterUserId, requesterWorkgroupId, dispatcherUserId, assigneeUserId, assigneeWorkgroupId, assigneeUsers, assigneeWorkgroups, 
      // escalationUserId, escalationWorkgroupId, escalationUsers,escalationWorkgroups, mentionUsers, mentionWorkgroups
      return(
        (ticket.escalationUserId==token.userId && 'escalation') ||  
        (ticket.escalationUsers && ticket.escalationUsers.indexOf(token.userId)>=0 && 'escalation') || 
        (ticket.assigneeUserId==token.userId && 'assignee') || 
        (ticket.requesterUserId==token.userId && 'requester') || 
        (ticket.dispatcherUserId==token.userId && 'dispatcher') || 
        (ticket.mentionUsers && ticket.mentionUsers.indexOf(token.userId)>=0 && 'follower') || 'follower'
      )
    }
  }

  setSharing(v) {
    this.setState({isAgent: v})
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (this.props.interactionStore.createInteraction!=undefined ? this.props.interactionStore.createInteraction : {})
    
    const token= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken.data : {}
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    
    const users= new Map();
    const workgroups= new Map();

    listUser.map(item=>{
      users.set(item.value, item)
    })
    listWorkgroup.map(item=>{
      workgroups.set(item.value, item)
    })

    return (
      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <Header as='h5'>
          <Icon name={this.props.share ? 'share alternate' : 'user plus'} circular inverted color='blue' style={{fontSize: '1.1em'}} />
          <Header.Content>
            {(this.props.share && (this.props.ticket ? 'Share Ticket' : 'Share Update')) || (this.props.transfer && 'Transfer Ticket') || 'Share Update'}
            <Header.Subheader>{(this.props.share && (this.props.ticket ? 'Share current ticket.' : 'Share current ticket update.')) || (this.props.transfer && 'Transfer current ticket.') || 'Share only current ticket update.'}</Header.Subheader>
          </Header.Content>
        </Header>
        {false && this.props.share && this.props.action && <>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                <b>{this.props.action.user && this.props.action.user.name}</b>
                <Header.Subheader>As <b>{this.props.action.owner}</b> at <b>{this.props.action.updatedStamp ? format(parse(this.props.action.updatedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm, dd/MMM yyyy') : 'unknown time'}</b></Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            {this.props.action.mentionUsers && this.props.action.mentionUsers.length>0 && <>
              <div style={{display: 'flex', border: '1px solid #ccc', borderRadius: '.3em'}}>
                <div style={{padding: '.5em .5em', borderRight: '1px solid #ccc', fontWeight: 'bold', fontSize: '1.1em'}}>
                  To:
                </div>
                <div style={{flex: 1, padding: '.5em .5em 0 .5em'}}>
                  <Label.Group>
                    {this.props.action.mentionUsers && this.props.action.mentionUsers.map(item=>{
                      return(
                        <Label>{(users.get(item)||{}).text}</Label>
                      )
                    })}
                  </Label.Group>
                </div>
              </div>
              <Divider />
            </>}

            {this.props.action.escalationWorkgroups && this.props.action.escalationWorkgroups.length>0 && <>
              <div style={{display: 'flex', border: '1px solid #ccc', borderRadius: '.3em'}}>
                <div style={{padding: '.5em .5em', borderRight: '1px solid #ccc', fontWeight: 'bold', fontSize: '1.1em'}}>
                  Esc. To:
                </div>
                <div style={{flex: 1, padding: '.5em .5em 0 .5em'}}>
                  <Label.Group>
                    {this.props.action.escalationWorkgroups && this.props.action.escalationWorkgroups.map(item=>{
                      return(
                        <Label>{(workgroups.get(item)||{}).text}</Label>
                      )
                    })}
                  </Label.Group>
                </div>
              </div>
              <Divider />
            </>}
            <b style={{textTransform: 'capitalize'}}>{this.props.action.privacy}</b>
            <div style={{fontSize: '1.3em'}}>
              {this.props.action.activity}
            </div>
            <Divider />
            <div style={{display:'flex'}}>
              <div style={{width: '10em', paddingRight: '1em', borderRight: '1px solid #ccc', textAlign: 'center'}}>
                <Label color={(this.props.action && this.props.action.remark_ && this.props.action.remark_.color) || 'black'} size='big' className='fluid'>
                  {(this.props.action.remark) || 'Progress'}
                </Label>
              </div>
              <div style={{flex: 1, paddingLeft: '1em'}}>
                <Header as='h4'>
                  <Header.Content>
                    {(this.props.action.resolution && this.props.action.resolution.name) || 'No Resolution'}
                    <Header.Subheader style={{fontWeight: 'bold', textTransform: 'uppercase'}}>Action: {this.props.action.action}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </Segment>
        </>}
        {false && this.props.share && this.props.ticket && <>
          <Segment>
            <Header as='h5'>
              <Icon name={
                (this.props.ticket.channelType=='Voice' && 'microphone') || (this.props.ticket.channelType=='IVR' && 'volume down') || (this.props.ticket.channelType=='SMS' && 'sticky note outline')
                || (this.props.ticket.channelType=='Email' && 'envelope outline') || (this.props.ticket.channelType=='Whatsapp' && 'whatsapp') || (this.props.ticket.channelType=='Telegram' && 'telegram plane')
                || (this.props.ticket.channelType=='Twitter' && 'twitter') || (this.props.ticket.channelType=='Facebook' && 'facebook') || (this.props.ticket.channelType=='Instagram' && 'instagram') || 'share alternate'
              } />
              <Header.Content>
                From <b>{this.props.ticket.name}</b>, as <span style={{textTransform: 'capitalize'}}>{this.props.ticket.cluster || 'Public Visitor'}</span>
                <Header.Subheader><b>{this.props.ticket.channelType || 'Walk In'} - {this.props.ticket.channelMethod || 'Input by Agent'}</b></Header.Subheader>
              </Header.Content>
            </Header>
            {/* <Divider />
            <div style={{display: 'flex', border: '1px solid #ccc', borderRadius: '.3em'}}>
              <div style={{padding: '.5em .5em', borderRight: '1px solid #ccc', fontWeight: 'bold', fontSize: '1.1em'}}>
                To:
              </div>
              <div style={{flex: 1, padding: '.5em .5em 0 .5em'}}>
                <Label.Group>
                  <Label>@Don Juan</Label> <Label>@Sarah Imla</Label> <Label>@Dean Warns</Label>
                </Label.Group>
              </div>
            </div> */}
            <Divider />
            <div>
              <Header as='h4' color='red'>
                <Header.Content>
                  <b>{this.props.ticket.subject}</b>
                  <Header.Subheader><b>{this.props.ticket.createStamp!=null && format(parse(this.props.ticket.createStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm')}</b> {this.props.ticket.createStamp!=null && format(parse(this.props.ticket.createStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM yyyy')}</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
              <Divider style={{margin: '.3em 0'}} />
              <div>
                <Header as='h5'>
                  <Header.Content>
                    {(this.props.ticket.classification||{}).name || 'No Classification'}
                    <Header.Subheader>Privacy: <b>{this.props.ticket.privacy}</b> / Category: <b>{this.props.ticket.category}</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            <Divider />
            <div style={{fontSize: '1.2em'}}>
            {this.props.ticket.descript}
            </div>
            <Divider />
            <div style={{display:'flex'}}>
              <div style={{width: '10em', paddingRight: '1em', borderRight: '1px solid #ccc', textAlign: 'center'}}>
                <Label color={(this.props.ticket && this.props.ticket.remark_ && this.props.ticket.remark_.color) || 'black'} size='big' className='fluid'>
                  {(this.props.ticket.remark) || 'Progress'}
                </Label>
              </div>
              <div style={{flex: 1, paddingLeft: '1em'}}>
                <Header as='h4'>
                  <Header.Content>
                    {(this.props.ticket.resolution && this.props.ticket.resolution.name) || 'No Resolution'}
                    <Header.Subheader>Progress: <b>{this.props.ticket.progress}%</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
            <Divider />
            <Header as='h5' style={{margin: '0'}}>
              <Header.Content>
                <b>{this.props.ticket.assigneeUser && this.props.ticket.assigneeUser.name}</b> as Assignee
                <Header.Subheader><b>{this.props.ticket. openStamp!=null && format(parse(this.props.ticket. openStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm')}</b> {(this.props.ticket. openStamp!=null && format(parse(this.props.ticket. openStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM yyyy')) || 'at Unknown Time'}</Header.Subheader>
              </Header.Content>
            </Header>
          </Segment>
        </>}
        {this.props.share && 
          <Segment>
            <Header as='h5'>
              <Header.Content>
                By <b>{token.name}</b>, {token.groupId}
                <Header.Subheader>As <b>{this.checkRole()}</b> at <b>{format(new Date(), 'HH:mm')}</b> {format(new Date(), 'dd/MMM yyyy')}</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            
            <div style={{display: 'flex', paddingBottom: '.5em'}}>
              <div style={{flex: 1}}>
                <Header as='h5' color='blue'>
                  <Header.Content>
                    <b>Share To</b>
                    <Header.Subheader></Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div>
                {/* <Button.Group size='tiny'>
                  <Popup content='Share to Users'  position= 'top right'
                  trigger={
                    <Button color={this.state.isAgent==1 ? 'grey' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '2.5em'}} icon onClick={this.setSharing.bind(this, 1)}><Icon name='user add' /></Button>
                  }
                  inverted />
                  <Button.Or></Button.Or>
                  <Popup content='Share to Teams'  position= 'top left'
                  trigger={
                    <Button disabledcolor={this.state.isAgent!=1 ? 'grey' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '2.5em'}} icon onClick={this.setSharing.bind(this, 0)}><Icon name='users' /></Button>
                  }
                  inverted />
                </Button.Group> */}
              </div>
            </div>
            {this.state.isAgent==1 && <Form.Group widths='equal'>
              <Field name='mentionUsers' component={SelectField}
              multiple
              options={listUser}
              placeholder='Accounts' />
            </Form.Group>}
            {this.state.isAgent!=1 && <Form.Group widths='equal'>
              <Field name='mentionWorkgroups' component={SelectField}
              multiple
              options={listWorkgroup}
              placeholder='Teams / Workgroups' />
            </Form.Group>}
          </Segment>
        }
        {this.props.transfer && <>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                By <b>{token.name}</b>, {token.groupId}
                <Header.Subheader>As <b>{this.checkRole()}</b> at <b>{format(new Date(), 'HH:mm')}</b> {format(new Date(), 'dd/MMM yyyy')}</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <div style={{display: 'flex', paddingBottom: '.5em'}}>
              <div style={{flex: 1}}>
                <Header as='h5' color='blue'>
                  <Header.Content>
                    <b>New Assignee</b>
                    <Header.Subheader></Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div>
                {/* <Button.Group size='tiny'>
                  <Popup content='Share to Users'  position= 'top right'
                  trigger={
                    <Button color={this.state.isAgent==1 ? 'grey' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '2.5em'}} icon onClick={this.setSharing.bind(this, 1)}><Icon name='user add' /></Button>
                  }
                  inverted />
                  <Button.Or></Button.Or>
                  <Popup content='Share to Teams'  position= 'top left'
                  trigger={
                    <Button disabledcolor={this.state.isAgent!=1 ? 'grey' : null} type='button' style={{padding: '.4em .2em', height: '1.8em', width: '2.5em'}} icon onClick={this.setSharing.bind(this, 0)}><Icon name='users' /></Button>
                  }
                  inverted />
                </Button.Group> */}
              </div>
            </div>
            {this.state.isAgent==1 && <Form.Group widths='equal'>
              <Field name='asigneeId' component={SelectField}
              options={listUser}
              placeholder={'Accounts as assignee'} />
            </Form.Group>}
            {this.state.isAgent!=1 && <Form.Group widths='equal'>
              <Field name='asigneeWorkgroupId' component={SelectField}
              options={listWorkgroup}
              placeholder={'Team as assignee'} />
            </Form.Group>}
            <Form.Group widths='equal'>
              <Field name='activity' component={TextAreaField}
              label={<b>Message / Activity</b>}
              rows={6}
              placeholder='Write message or request activity here ...' />
            </Form.Group>
            
            
          </Segment>
        </>}
        <Segment>
          {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(this.props.share ? 'Share Ticket' : 'Transfer Ticket') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
          
          <div style={{textAlign: 'right'}}>
            <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
            <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
          </div>
        </Segment>
      </Form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: ({}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: getTicket }
  )
  (reduxForm({
    form: 'ShareBox',	// a unique identifier for this form
  })(ShareBox)
  )
)
