import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form, Dropdown, Input,  Label } from 'semantic-ui-react'

import { SubmissionError, Field, reduxForm, change, formValueSelector, submit, reset } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { messageOmniwidget } from "../../actions/flow/omniwidgetAction"
import { contactOmniphone } from "../../actions/stream/omniphoneAction"

function submitText(values, e, props) {
  // const { mode } = this.props
  // console.log('!!! SUBMIT: ', values)
  // console.log('!!! SUBMIT: ', e)
  // console.log('!!! SUBMIT: ', props)
  // messageOmniwidget({...values})
  // props.messageOmniwidget({...values})
}

//CONNECT REDUX STORE
const selector = formValueSelector('OmniphoneSms')

const mapStateToProps = (state, props) => {
  return {
    omniwidgetStore: state.omniwidgetStore,
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    contactOmniphone:(objParam) => dispatch(contactOmniphone(objParam)),

    messageOmniwidget:(objParam) => dispatch(messageOmniwidget(objParam)),
  }
}


class OmniphoneSms extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        chatLength: 0,
        lastSent: null,
        currSent: null,
        isChatted: false,
      };
    }

    textInput(e, v) {
      // console.log(e)
      // console.log(v.value)
      if (v.value[v.value.length-1]=='\n') {
        if (v.value.length>1) {
          // console.log('SEND')
          this.props.dispatch(submit('OmniphoneSms'))
          this.textSend(v.value.substr(0, v.value.length-1))
          this.setState({
            currSent: new Date()
          })
        }
        // this.props.dispatch(change('OmniphoneSms', 'message', ''))
      }
    }

    itemClick(e, v) {
      // console.log(v)
      this.props.dispatch(change('OmniphoneSms', 'message', (this.props.message || '') + (this.props.message ? '\n\n' : '') + (v.text || '') ));
    }

    onSubmit(values) {
      // const { mode } = this.props
      // console.log('!!! SUBMIT: ', values)
      // this.props.messageOmniwidget({...values})
    }

    textSend(message) {
      // console.log('!!! TEXT SEND', message)
      this.props.textSend(message)
    }

    variableSend(message) {
      this.props.textSend(message, 'variable')
    }

    commandSend(message) {
      this.props.textSend(message, 'command')
    }

    closeMessage() {
      const { conversation } = this.props
      this.props.endConversation('SMS', 
        {
          command: 'end_by_agent',
          visitorId: conversation.visitorId,
          agentId: conversation.userId,
          clientId: conversation.clientId,
          messagingId: conversation.messagingId,
        }
      )
    }

    componentDidUpdate(props, prevProps) {
      const {screenWidth, screenHeight, chats}= this.props
      const { chatLength, lastSent, currSent, isChatted }= this.state
      // const { progress, code, data }= (this.props.omniwidgetStore.messageOmniwidget!=undefined ? this.props.omniwidgetStore.messageOmniwidget : {})
      // console.log('OMNIPHONE STORE', progress, code, data)
      // if (progress=='1') {
      //   setTimeout(()=>{
      //     this.props.setMode('home')
      //   }, 3000)
      // }
      // console.log(progress)
      // if (progress==1) {
      //   this.props.dispatch(reset('OmniphoneSms'));
      // }      

      // if (!isChatted && this.props.phoneState=='registered' && booked) {
      //   this.setState({
      //     isChatted: true,
      //   })
      //   this.props.messageOmniwidget({
      //     userId: booked.agentId, // for booked agent allocation
      //     clientId: booked.clientId,
      //     visitorId: booked.visitorId,
      //     originating: booked.sipAccount,
      //     terminating: booked.agentExten,
      //   });
      // }

      if (lastSent!=currSent) {
        this.setState({
          lastSent: currSent,
        })
        this.props.dispatch(change('OmniphoneSms', 'message', ''))
      }

      if (chats.length>chatLength) {
        this.setState({
          chatLength: chats.length
        })
        
        var objDiv = document.getElementById("wd_chat");
        objDiv && (objDiv.scrollTop = objDiv.scrollHeight);
      }
      // console.log(props, prevProps)
      // if (chats.length!=prevProps.chats.length) {
      //   setTimeout(()=>{
      //     var objDiv = document.getElementById("wd_chat");
      //     objDiv.scrollTop = objDiv.scrollHeight;
      //   }, 500)
      // }
    }

    render() {
      const { screenHeight, chats, phoneState, visitor, conversation, status, handleSubmit, pristine, reset, submitting, mode, dispatch } = this.props

      const { target, customer }= (this.props.omniphoneStore.contactOmniphone && this.props.omniphoneStore.contactOmniphone.progress=='1' ? this.props.omniphoneStore.contactOmniphone.data : {})
      const { questions }= (this.props.omniwidgetStore.navigationOmniwidget!=undefined && this.props.omniwidgetStore.navigationOmniwidget.progress=='1' ? this.props.omniwidgetStore.navigationOmniwidget.data : {})
      
      return(
        <div style={{width: '100%', maxHeight: screenHeight, display: 'flex', flexDirection: 'column', background: '#f5f5f5'}}>
          <div style={{padding: '1.2em 1em', background: '#f5f5f5', borderBottom: '1px solid #ccc'}}>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h4' color={((this.props.phoneState=='disconnected' || status!='occupied') && 'grey') || 'blue'} style={{fontWeight: 'bold'}}>
                  <Icon name='file alternate outline' style={{fontSize: '2.2em'}} color={((this.props.phoneState=='disconnected' || status!='occupied') && 'grey') || 'blue'}/>
                  <Header.Content>
                    {(target && target.name) || (customer && customer.name) || (visitor && visitor.name) || 'Online Chat !'}
                    <Header.Subheader>{status=='occupied' ? ((target && target.campaign) || (visitor && 'Public visitor') || 'Guest Information') : 'Mobile Phone'}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              {/* <div>
                <Icon name='comment alternate outline' style={{float: 'right', color: '#fff', cursor: 'pointer', padding: '0', fontSize: '1.8em'}} onClick={this.closeMessage.bind(this)} color={status=='occupied' ? 'red' : 'grey'} disabled={status!='occupied'} />
              </div> */}
            </div>
          </div>

          <div id='wd_chat' className='chat' style={{flex: '1', padding: '0em 1em', overflowY: 'auto', borderBottom: '1px solid #ccc'}}>
            <div style={{padding: '1em 0em', minHeight: '250px', display: 'flex', flexDirection: 'column'}}>
              <div style={{flex: '1'}}>
              </div>
              {
                chats && chats.map((item, idx)=>{
                  return(
                    <div key={idx} style={{margin: '.1em 0', textAlign: item.sender=='cust' ? 'left' : 'right'}}>
                      <div style={{background: item.sender=='cust' ? '#fffd8a' : '#c5fcff', float: item.sender=='cust' ? 'left' : 'right', padding: '.5em .5em .2em .5em', margin: item.sender=='cust' ? '0 2em 0 0' : '0 0 0 2em', borderRadius: item.sender=='cust' ? '1em 2.5em 2.5em 0em' : '2.5em 1em 0em 2.5em'}}>
                        <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                          <Header.Content>
                            <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                            {item.message}
                            {item.sender=='agent' &&
                            <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>}
                          </Header.Content>
                        </Header>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>

          <div style={{padding: '1em', borderTop: '1px solid #fff'}}>
            {/* <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}> */}
            <Form onSubmit={handleSubmit}>
              <Form.Group widths='16'>
                <Field name='message' component={TextAreaField} 
                // onInput={this.textInput.bind(this)}
                disabled={this.props.phoneState=='disconnected' || status!='occupied'}
                width={16}
                rows={5}
                maxLength={150}
                placeholder='Type SMS text here ... '
                label='SMS Box!' />
              </Form.Group>
            </Form>
            <div style={{background: '#f5f5f5', }}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Header as='h6' style={{fontWeight: 'bold', margin: '0'}}>
                    <Header.Content>
                      {phoneState}
                    </Header.Content>
                  </Header>
                </div>
                <div>
                  {/* <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                    <Icon name='phone' style={{fontSize: '1.1em', transform: 'rotate(20deg)'}}/>
                  </Button> */}
                </div>
                {/* <div>
                  <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                    <Icon name='file alternate outline' style={{fontSize: '1.1em'}}/>
                  </Button>
                </div> */}
                {/* <div>
                  <Button type='button' icon style={{border: 'none', background: 'none', padding: '0 .3em'}}>
                    <Icon name='image outline' style={{fontSize: '1.3em'}}/>
                  </Button>
                </div> */}
                {/* <div>
                  <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                    <Icon name='camera retro' style={{fontSize: '1.2em'}}/>
                  </Button>
                </div> */}
              </div>

              <Divider style={{margin: '.5em 0 1em 0'}}/>
              <div style={{textAlign: 'right'}}>
                <Button size='small' onClick={this.closeMessage.bind(this)} color={status=='occupied' ? 'blue' : 'grey'} disabled={status!='occupied'} >SEND</Button>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: {}, //state.omniwidgetStore && state.omniwidgetStore.messageOmniwidget!=undefined ? state.omniwidgetStore.messageOmniwidget.data : {},
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:false,
      message: selector(state, 'message'),
    }),
  )
  (
    reduxForm({
      form: 'OmniphoneSms',	// a unique identifier for this form
      onSubmit: submitText,
    }) (OmniphoneSms)
  )
)