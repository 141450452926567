export default function ratesReducer (state = {}, action) {
  switch (action.type) {
    case 'RATES_RESET': {
        return({
            ...state,
            getRates: {},
            updateRates: {},
            createRates: {},
        })
    }

    case 'RATES_GET_PENDING': {
        return({...state, getRates: {progress: 0}})
    }
    case 'RATES_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getRates: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getRates: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RATES_GET_REJECTED': {
        return({...state, getRates: {progress: 2, payload: action.payload}})
    }

    case 'RATES_STATUS_PENDING': {
      return({...state, statusRates: {progress: 0}})
    }
    case 'RATES_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusRates: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusRates: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RATES_STATUS_REJECTED': {
        return({...state, statusRates: {progress: 2, payload: action.payload}})
    }

    case 'RATES_BROWSE_PENDING': {
      return({...state, browseRates: {progress: 0}})
    }
    case 'RATES_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseRates: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseRates: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RATES_BROWSE_REJECTED': {
        return({...state, browseRates: {progress: 2, payload: action.payload}})
    }

    case 'RATES_CREATE_PENDING': {
      return({...state, browseRates: {progress: 0}})
    }
    case 'RATES_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createRates: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createRates: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RATES_CREATE_REJECTED': {
        return({...state, createRates: {progress: 2, payload: action.payload}})
    }

    case 'RATES_UPDATE_PENDING': {
      return({...state, updateRates: {progress: 0}})
    }
    case 'RATES_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateRates: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateRates: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RATES_UPDATE_REJECTED': {
        return({...state, updateRates: {progress: 2, payload: action.payload}})
    }

    case 'RATES_REMOVE_PENDING': {
      return({...state, removeRates: {progress: 0}})
    }
    case 'RATES_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeRates: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeRates: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'RATES_REMOVE_REJECTED': {
        return({...state, removeRates: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}