import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetPhonewidget(objParam) {
  return {
      type: 'PHONEWIDGET_RESET',
      payload: objParam
  }
}

export function paramPhonewidget(objParam) {
  return {
      type: 'PHONEWIDGET_PARAM',
      payload:
        axios.post(
          urlAPI + 'phonewidget/param',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function bookPhonewidget(objParam) {
  return {
      type: 'PHONEWIDGET_BOOK',
      payload:
        axios.post(
          urlAPI + 'phonewidget/book',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function ratePhonewidget(objParam) {
  return {
      type: 'PHONEWIDGET_RATE',
      payload:
        axios.post(
          urlAPI + 'phonewidget/rate',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function messagePhonewidget(objParam) {
  return {
    type: 'PHONEWIDGET_MESSAGE',
    payload:
      axios.post(
        urlAPI + 'phonewidget/message',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function variablePhonewidget(objParam) {
  return {
    type: 'PHONEWIDGET_VARIABLE',
    payload:
      axios.post(
        urlAPI + 'phonewidget/variable',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function chatPhonewidget(objParam) {
  return {
    type: 'PHONEWIDGET_CHAT',
    payload:
      axios.post(
        urlAPI + 'phonewidget/chat',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function dialPhonewidget(objParam) {
  return {
    type: 'PHONEWIDGET_DIAL',
    payload:
      axios.post(
        urlAPI + 'phonewidget/dial',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}
