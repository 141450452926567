import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetLinkwidget(objParam) {
  return {
      type: 'LINKWIDGET_RESET',
      payload: objParam
  }
}

export function paramLinkwidget(objParam) {
  return {
      type: 'LINKWIDGET_PARAM',
      payload:
        axios.post(
          urlAPI + 'linkwidget/param',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function bookLinkwidget(objParam) {
  return {
      type: 'LINKWIDGET_BOOK',
      payload:
        axios.post(
          urlAPI + 'linkwidget/book',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function rateLinkwidget(objParam) {
  return {
      type: 'LINKWIDGET_RATE',
      payload:
        axios.post(
          urlAPI + 'linkwidget/rate',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function messageLinkwidget(objParam) {
  return {
    type: 'LINKWIDGET_MESSAGE',
    payload:
      axios.post(
        urlAPI + 'linkwidget/message',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function variableLinkwidget(objParam) {
  return {
    type: 'LINKWIDGET_VARIABLE',
    payload:
      axios.post(
        urlAPI + 'linkwidget/variable',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function chatLinkwidget(objParam) {
  return {
    type: 'LINKWIDGET_CHAT',
    payload:
      axios.post(
        urlAPI + 'linkwidget/chat',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function dialLinkwidget(objParam) {
  return {
    type: 'LINKWIDGET_DIAL',
    payload:
      axios.post(
        urlAPI + 'linkwidget/dial',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}
