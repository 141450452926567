import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Label, TextArea } from 'semantic-ui-react'

import DismissableMessage from '../../common/DismissableMessage'
import { paramOptions, responsedOptions, yesnoOptions, valueOptions }  from '../../common/StaticMasterData'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector, getFormValues } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, ToggleField, SelectField, TextAreaField, DatePickerField, MaskedField, AudioUploadField, LabelNumberField, LabelSelectField, LabelInputField } from '../../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { resetInteractive, getInteractive,updateInteractive, createInteractive } from "../../../actions/flow/interactiveAction"
import { listWorkgroup } from "../../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('InteractiveNavigationForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    interactiveStore: state.interactiveStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),

    resetInteractive:(objParam) => dispatch(resetInteractive(objParam)),
    getInteractive:(objParam) => dispatch(getInteractive(objParam)),
    createInteractive:(objParam) => dispatch(createInteractive(objParam)),
    updateInteractive:(objParam) => dispatch(updateInteractive(objParam)),
  }
}


class InteractiveNavigationForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null,
      navigations: null,
    }
  }

  componentDidMount(){
    // console.log(this.props.params)
    this.props.resetInteractive(this.props.mode)
    // if (this.props.mode==2) { //update
      this.props.getInteractive({interactiveId: this.props.params.interactiveId})
    // }
    
    this.props.listWorkgroup()
  }

  componentDidUpdate(prevProps, prevState) {
    const { navigations } = this.state
    const { mode } = this.props
    const { progress, code, text }= 
    // (mode==2 ? 
      (this.props.interactiveStore.updateInteractive!=undefined ? this.props.interactiveStore.updateInteractive : {})
      //  : (this.props.interactiveStore.createInteractive!=undefined ? this.props.interactiveStore.createInteractive : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }

    const { data }= this.props.interactiveStore.getInteractive!=undefined ? this.props.interactiveStore.getInteractive : {}
    if (!navigations && data) {
      this.setState({
        navigations: data.navigations || [
          {
            level: 0,
          }
        ]
      })
    }
    if (!prevState.navigations && this.state.navigations && this.props.mode==33) {
      this.addChildNav(this.props.params.parentLevel)
    }
    if (!prevState.navigations && this.state.navigations && this.props.mode==44) {
      this.delChildNav(this.props.params.currLevel)
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    const { navigations } = this.state
    if (this.props.mode==44) {
      navigations[this.props.params.level]= undefined
    }
    // console.log('!!! SUBMIT: ', values)
    // mode==2 ? 
    this.props.updateInteractive({...values, navigations: navigations}) 
    // : this.props.createInteractive({...values, navigations: navigations})
  }

  addChildNav(parentLevel) {
    var currLevel ;
    const { navigations }= this.state

    if (parentLevel>0) {
      let i= (parentLevel * 10) + 1;
      while (i<((parentLevel + 1) * 10)) {
        if (!navigations[i]) {
          navigations[i]= {
            level: i,
          }
          currLevel= i;
          break;
        }
        i++;
      }
    } else {
      let i= 1;
      while (i<((parentLevel + 1) * 10)) {
        if (!navigations[i]) {
          navigations[i]= {
            level: i,
          }
          currLevel= i;
          break;
        }
        i++;
      }
    }

    // console.log(parentLevel, currLevel)

    this.setState({
      currLevel: currLevel,
      navigations: navigations
    })
  }

  delChildNav(level) {
    const { navigations }= this.state
    if (level>0) {
      navigations[level]= undefined
    }
    this.setState({
      navigations: navigations
    })
  }

  render() {
    const { navigations }= this.state
    const { screenWidth, error, handleSubmit, pristine, reset, submitting, mode } = this.props
    
    const { data }= (this.props.interactiveStore.getInteractive!=undefined && this.props.interactiveStore.getInteractive.progress=='1' ? this.props.interactiveStore.getInteractive : {})
    const { progress, code, text }= 
    // (mode==2 ? 
    (this.props.interactiveStore.updateInteractive!=undefined ? this.props.interactiveStore.updateInteractive : {})
    //  : (this.props.interactiveStore.createInteractive!=undefined ? this.props.interactiveStore.createInteractive : {}))

    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    
    const navItems= []

    // console.log(this.props.params)
    
    let i= 0;
    while (i<=9) {
      if (navigations && navigations[i] && i!=0) {
        navItems.push(navigations[i]);
        let j= (i * 10) + 1;
        while (j<((i+1) * 10)) {
          if (navigations[j]) {
            navItems.push(navigations[j]);
            let k= (j * 10) + 1;
            while (k<((j+1) * 10)) {
              if (navigations[k]) {
                navItems.push(navigations[k]);
              }
              k++;
            }
          }
          j++;
        }
      } else if (navigations && navigations[i]) {
        navItems.push(navigations[i]);
      } else if (i==0) {
        navItems.push({
          level: 0,
        });
      }
      i++;
    }

    if (this.props.mode==44) {
      return(<div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Header as='h5'>
            {/* <Icon name={this.props.params.responsed=='Agent' ?'spy' : 'fax' } color={this.props.params.responsed=='Agent' ?'blue' : 'orange' } circular inverted style={{fontSize: '1.2em'}}/> */}
            <Header.Content>
              <b>{this.props.params.name}</b>
              <Header.Subheader>Level: <b>{this.props.params.level}</b> / Resp. by: <b>{this.props.params.responsed}</b></Header.Subheader>
              {/* <Divider /> */}
              {/* <Header.Subheader>{this.props.params.scriptTalent}</Header.Subheader> */}
            </Header.Content>
          </Header>
          <Segment>
            <div style={{display: 'flex'}}>
              {/* <div style={{width: '3em'}}>
                <Label as='a' color={(this.props.params.level<10 && 'yellow') || (this.props.params.level<99 && 'teal') || 'grey'} circular size='big' onClick={this.setLevel.bind(this, this.props.params.level)}>{seq++}</Label>
              </div> */}
              <div style={{flex: 3, padding: '0 1em'}}>
                <Header as='h5' style={{margin: '0'}}>
                  <Header.Content>
                    {this.props.params.scriptTalent}
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: 1, padding: '0 1em'}}>
                <Header as='h5'>
                  <Header.Content>
                    <b>{this.props.params.params || 'No Param'}</b>
                    <Header.Subheader>Param Input</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              {/* <div>
                <ReactAudioPlayer
                  style={{
                    width: '210px',
                    display: 'block',
                    height: '35px',
                  }}
                  src={this.props.params.clientId ? ivrPath + this.props.params.clientId.toString().padStart(6,"0") + this.props.params.level.toString().padStart(2,"0") +'.mp3' : null}
                  controls
                />
              </div> */}
            </div>
          </Segment>

          <Divider style={{margin: '3em 0'}} />
          <div style={{margin: '0'}}>
            <Header as='h3'>
              <Icon name='warning sign' color='black' style={{fontSize: '2em', paddingRight: '.3em'}}/>
              <Header.Content>
                <b><i>Delete!</i></b> Confirmation
                <Header.Subheader style={{fontSize: '.95em'}}>
                  This activity will permanently removed your data from system.
                </Header.Subheader>
              </Header.Content>
            </Header>
            
            <Divider style={{marginTop: '3em'}}/>
            <div style={{textAlign: 'right'}}>
              <Button type='button' size='large' content=' Cancel ' color='grey' onClick={this.props.closeModal.bind(this)} />
              <Button type='submit' size='large' color='red' content=' Delete ' />
            </div>
          </div>
        </Form>
      </div>)
    } else {
      return (<>
        <div style={{display: 'flex', padding: '1em 1em'}}>
          <div style={{flex: '1'}}>
            <Header as='h4' style={{marginTop: '.25em'}}>
              <Icon name='sliders horizontal' style={{fontSize: '1em'}}/>
              <Header.Content>
                IVR Menu Content
              </Header.Content>
            </Header>
          </div>
          <div>
            {/* <Button size='mini' color='red' onClick={this.props.setMode.bind(this, '2',this.props.interactiveId, this.props.clientId)} icon style={{padding: '.5em'}}><Icon name='pencil alternate' /></Button> */}
            {/* <Button size='mini' color='blue' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='arrow left' /></Button> */}
          </div>
        </div>
  
        <div style={{padding: '1em 1em'}}>
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Segment>
              <Form.Group widths='16'>
                {/* <Field name={'level_'+ (this.state.currLevel || this.props.params.level)} component={InputField}
                width={4}
                label='Level'
                placeholder='Level' /> */}
                <Field name={'shortcut_'+ (this.state.currLevel || this.props.params.level)} component={LabelSelectField}
                width={4}
                options={valueOptions('keypad')}
                validate={required}
                label='Dial'
                caption={<b>Menu Key</b>}
                placeholder='Menu' />
                <Field name={'responsed_'+ (this.state.currLevel || this.props.params.level)} component={SelectField}
                width={4}
                validate={required}
                options={responsedOptions}
                label='Resp. By'
                placeholder='By' />
                <Field name={'name_'+ (this.state.currLevel || this.props.params.level)} component={InputField}
                width={8}
                validate={required}
                label='Title / Caption'
                placeholder='Menu Title / Caption' />
                {/* <Field name={'prepared_'+ (this.state.currLevel || this.props.params.level)} component={SelectField}
                  width={4}
                  options={yesnoOptions('ivr')}
                  label='Re/Generate Speech'
                /> */}
              </Form.Group>
              <Form.Group widths='16'>
                <Field name={'prepared_'+ (this.state.currLevel || this.props.params.level)} component={LabelSelectField}
                  width={4}
                  caption='Re/Generate Audio'
                  options={yesnoOptions('ivr')}
                  label='TTS' 
                  placeholder='Yes for regenerate audio file'/>
                <Field name={'params_'+ (this.state.currLevel || this.props.params.level)} component={SelectField}
                width={4}
                options={paramOptions('IVR')}
                label={<b>Params</b>}
                placeholder='Params' />
                <Field name={'dispatches_'+ (this.state.currLevel || this.props.params.level)} component={SelectField}
                multiple
                options={listWorkgroup}
                width={8}
                label={<b>Dispatch / Handler</b>}
                placeholder='Dispatches' />
              </Form.Group>
              {/* <div style={{display: 'flex', marginBottom: '1em'}}>
                <div style={{flex: '1'}}>
                  <Header as='h5' color='blue'>
                    <Header.Content>
                      Voice Talent
                      <Header.Subheader>Greeting voice when customer entering navigation.</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div>
                  <Header as='h5'>
                    <IconToggle active={false} onClick={this.setScript.bind(this)} />
                    <Header.Content>
                      File Upload
                      <Header.Subheader>Upload file or text to speech conversion</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
              </div> */}
            </Segment>
            
            <Segment>
              <Form.Group widths='16'>
                <Field name={'scriptTalent_'+ (this.state.currLevel || this.props.params.level)} component={TextAreaField}
                width={16}
                label={<b>Script Voice Talent</b>}
                placeholder='Script voice talent will be converted to audio by text to speech system' />
              </Form.Group>
            </Segment>

            {(this.props.formValues && this.props.formValues['params_' + (this.state.currLevel || this.props.params.level)]) && <Segment>
              <Form.Group widths='16'>
                <Field name={'scriptSucceed_'+ (this.state.currLevel || this.props.params.level)} component={TextAreaField}
                width={16}
                label={<b>Succeed Verification Script</b>}
                placeholder='Script voice talent when param value is valid / correct' />
              </Form.Group>
              <Form.Group widths='16'>
                <Field name={'scriptFailed_'+ (this.state.currLevel || this.props.params.level)} component={TextAreaField}
                width={16}
                label={<b>Failed / Invalid Verification Script</b>}
                placeholder='Script voice talent when param value is failed / invalid' />
              </Form.Group>
            </Segment>}
            
            
            <Segment>
              {/* <Divider hidden/> */}
              {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Interactive' : 'Insert Interactive') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
              
              <div style={{textAlign: 'right'}}>
                <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
                <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
              </div>
            </Segment>
          </Form>
        </div>
      </>)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.interactiveStore.getInteractive!=undefined && state.interactiveStore.getInteractive.progress==1 ? state.interactiveStore.getInteractive.data : {}),
      formValues: getFormValues('InteractiveNavigationForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      
      navigations: selector(state, 'navigations'),
    }),
    { load: getInteractive }
  )
  (reduxForm({
    form: 'InteractiveNavigationForm',	// a unique identifier for this form
  })(InteractiveNavigationForm)
  )
)
