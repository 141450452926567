import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import { }  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { contactOptions }  from '../common/StaticMasterData'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, TextAreaField, LabelSelectField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumberID, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetDialer, getDialer,updateDialer, createDialer } from "../../actions/stream/dialerAction"
import { listTemplate, listParams } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    dialerStore: state.dialerStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listTemplate:(objParam) => dispatch(listTemplate(objParam)),
    listParams:(objParam) => dispatch(listParams(objParam)),

    resetDialer:(objParam) => dispatch(resetDialer(objParam)),
    getDialer:(objParam) => dispatch(getDialer(objParam)),
    createDialer:(objParam) => dispatch(createDialer(objParam)),
    updateDialer:(objParam) => dispatch(updateDialer(objParam)),
  }
}

class DialerForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      showPasswd: false,
    }
  }

  componentDidMount(){
    this.props.resetDialer(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getDialer({dialerId: this.props.dialerId})
    }

    this.props.listTemplate()
    this.props.listParams()
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.dialerStore.updateDialer!=undefined ? this.props.dialerStore.updateDialer : {}) : (this.props.dialerStore.createDialer!=undefined ? this.props.dialerStore.createDialer : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateDialer({...values}) : this.props.createDialer({...values})
    
  }

  render() {
    const { showPasswd }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.dialerStore.updateDialer!=undefined ? this.props.dialerStore.updateDialer : {}) : (this.props.dialerStore.createDialer!=undefined ? this.props.dialerStore.createDialer : {}))

    const listTemplate= (this.props.masterStore.listTemplate!=undefined && this.props.masterStore.listTemplate.progress=='1' ? this.props.masterStore.listTemplate.data : [])
    const { svcCampaigns, svcRatios, svcChannels }= this.props.masterStore.listParams && this.props.masterStore.listParams.data ? this.props.masterStore.listParams.data : {}

    const campaignOptions= svcCampaigns
    const channelOptions= svcChannels
    const ratioOptions= svcRatios
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='16'>
              <Field name='contactType' component={LabelSelectField}
              width={16}
              options={contactOptions('voice')}
              validate= {[required]}
              caption= 'Contact Type'
              label= 'Type'
              placeholder='Contact Type' />
            </Form.Group>
            
            <Form.Group widths='16'>
              <Field name='terminating' component={LabelInputField}
              width={16}
              validate= {[phoneNumberID, required]}
              caption= 'Contact Number'
              label= 'Phone'
              placeholder='Terminating Number' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='name' component={LabelInputField}
              width={16}
              caption= 'Customer / Target Full Name'
              label= 'Name'
              placeholder='Contact Name' />
            </Form.Group>
          </Segment>
          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Dialer' : 'Insert Dialer') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.dialerStore.getDialer!=undefined && state.dialerStore.getDialer.progress==1 ? state.dialerStore.getDialer.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: getDialer }
  )
  (reduxForm({
    form: 'DialerForm',	// a unique identifier for this form
  })(DialerForm)
  )
)
