import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import SegmentBrowse from '../../libraries/service/SegmentBrowse'
import SegmentForm from '../../libraries/service/SegmentForm'
import SegmentSearch from '../../libraries/service/SegmentSearch'


class SegmentBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        segmentId: 0,
      };
    }

    setMode(mode, id, clientId) {
      this.setState({mode: mode, segmentId: id, clientId: clientId})
    }

    render() {
      const {mode, segmentId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <SegmentSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
              <div>
                {mode==1 && <SegmentBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
                {mode==2 && <SegmentForm mode={mode} segmentId={segmentId} clientId={clientId} setMode={this.setMode.bind(this)} />}
                {mode==3 && <SegmentForm mode={mode} segmentId={0} clientId={0} setMode={this.setMode.bind(this)} />}
              </div>
            </div>
          </div>
        </Container>
      )
    }
}

export default SegmentBody
