import React, { Component } from 'react';
import NumberFormat from 'react-number-format'


class InputNumberFormat extends Component {
  constructor(props) {
    super(props);
    this.state={
      
    }
  }
  
  onValueChange(values) {
    // console.log('!!! VALUE ONCHANGE: ', values.value,' ---- ', this.props.value)

    if (values.value!=this.props.value) {
      this.props.onChange(values.value)
    }
  }

  onChange(e) {
    // console.log('!!! ONCHANGE: ', e.target.value,' ---- ', this.props.value, '----', this.props.thousandSeparator)
    this.props.onChange(e.target.value.replace(this.props.prefix, '').replace(this.props.suffix,'').replace((this.props.thousandSeparator==undefined ? new RegExp(',', 'g') : new RegExp(this.props.thousandSeparator, 'g')),''));
  }
  
  render() {
    return (
      <NumberFormat style={{textAlign: 'right', ...this.props.style}}
      //isNumericString={true}
      disabled= {this.props.disabled}
      readOnly= {this.props.readOnly}
      thousandSeparator={this.props.thousandSeparator==undefined ? ',' : this.props.thousandSeparator} 
      decimalSeparator={(this.props.thousandSeparator==undefined || (this.props.thousandSeparator && this.props.thousandSeparator!='.')) ? '.' : this.props.thousandSeparator} 
      decimalScale={this.props.decimalScale || 0} 
      prefix={this.props.prefix}
      suffix={this.props.suffix}
      value={this.props.value}
      placeholder={this.props.placeholder}
      onChange={this.onChange.bind(this)}
      //onValueChange={this.onValueChange.bind(this)}
      />
    );
  }
}

export default InputNumberFormat;