import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import DismissableMessage from '../../common/DismissableMessage'
import { personalOptions, ticketOptions, groupOptions, fieldMaps }  from '../../../modules/service/StaticMaster'

import ConversationBox from './box/ConversationBox'
import ResourceBox from './box/ResourceBox'
import KnowledgeBox from './box/KnowledgeBox'
import PeopleBox from './box/PeopleBox'
import TicketBox from './box/TicketBox'
import InteractionBox from './box/InteractionBox'

import { SubmissionError, Field, reduxForm, change, formValueSelector, getFormValues } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, LabelNumberField, DatePickerField, MaskedField, LabelSelectField, InsertableDropdownField } from '../../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email, phoneNumberID } from '../../validation/validation'

import { connect } from "react-redux"
import { resetTicket, getTicket,updateTicket, createTicket } from "../../../actions/service/ticketAction"
import { listField, listForm, listMacro, listUser, listAgent, listWorkgroup, listProduct, listCustomer, listChannel, listReasoning, listClassification, listResolution, listRemark } from "../../../actions/service/masterAction"
import { contactOmniphone,ticketOmniphone, resetOmniphone } from "../../../actions/stream/omniphoneAction"

//CONNECT REDUX STORE
const selector = formValueSelector('MyTicketFormCreate')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStoreTicket,
    ticketStore: state.ticketStore,
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listField:(objParam) => dispatch(listField(objParam)),
    listForm:(objParam) => dispatch(listForm(objParam)),
    listMacro:(objParam) => dispatch(listMacro(objParam)),
    listAgent:(objParam) => dispatch(listAgent(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    listCustomer:(objParam) => dispatch(listCustomer(objParam)),
    listChannel:(objParam) => dispatch(listChannel(objParam)),

    listReasoning:(objParam) => dispatch(listReasoning(objParam)),
    listResolution:(objParam) => dispatch(listResolution(objParam)),
    listClassification:(objParam) => dispatch(listClassification(objParam)),
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    
    resetTicket:(objParam) => dispatch(resetTicket(objParam)),
    getTicket:(objParam) => dispatch(getTicket(objParam)),
    createTicket:(objParam) => dispatch(createTicket(objParam)),
    updateTicket:(objParam) => dispatch(updateTicket(objParam)),
    
    resetOmniphone:(objParam) => dispatch(resetOmniphone(objParam)),
    contactOmniphone:(objParam) => dispatch(contactOmniphone(objParam)),
    ticketOmniphone:(objParam) => dispatch(ticketOmniphone(objParam)),
  }
}

class MyTicketFormCreate extends Component {
  constructor(props) {
    super(props)
    this.state= {
      box: 'request',
      cluster: 'customer',
      contact: 'phone',
    }
  }

  componentDidMount(){
    console.log('!!! CONVERSATION', this.props.conversation);
    
    if (this.props.conversation) {
      this.props.dispatch(change('MyTicketFormCreate', 'channelType', this.props.conversation.media));
      this.props.dispatch(change('MyTicketFormCreate', 'channelSupports', [this.props.conversation.media]));
      this.props.conversation.media=='Voice' && this.props.dispatch(change('MyTicketFormCreate', 'mobile', this.props.conversation.terminating));
      this.props.conversation.media=='Email' && this.props.dispatch(change('MyTicketFormCreate', 'email', this.props.conversation.terminating));
      this.props.conversation.media=='Whatsapp' && this.props.dispatch(change('MyTicketFormCreate', 'whatsapp', this.props.conversation.terminating));
      this.props.conversation.media=='SMS' && this.props.dispatch(change('MyTicketFormCreate', 'sms', this.props.conversation.terminating));
      this.props.conversation.media=='Twitter' && this.props.dispatch(change('MyTicketFormCreate', 'twitter', this.props.conversation.terminating));
      this.props.conversation.media=='Instagram' && this.props.dispatch(change('MyTicketFormCreate', 'instagram', this.props.conversation.terminating));
      this.props.conversation.media=='Facebook' && this.props.dispatch(change('MyTicketFormCreate', 'facebook', this.props.conversation.terminating));

      this.setState({
        contact: ((this.props.conversation.media=='Voice' && 'phone') || (this.props.conversation.media=='Email' && 'mail') || (this.props.conversation.media=='Twitter' && 'twitter') || (this.props.conversation.media=='Facebook' && 'facebook') || (this.props.conversation.media=='Instagram' && 'instagram') || (this.props.conversation.media=='Whatsapp' && 'whatsapp') || 'phone')
      })
    }

    this.props.resetTicket(this.props.mode)

    this.props.listField()
    this.props.listForm()
    this.props.listMacro()
    this.props.listAgent()
    this.props.listWorkgroup()
    this.props.listProduct()
    this.props.listCustomer()
    this.props.listChannel()

    this.props.listReasoning()
    this.props.listResolution()
    this.props.listClassification()
    this.props.listRemark()
  }

  componentDidUpdate(prevProps) {
    // const { mode } = this.props
    // const { progress, code, text }= this.props.ticketStore.createTicket!=undefined ? this.props.ticketStore.createTicket : {};
    const { progress, code, text }= this.props.omniphoneStore.ticketOmniphone!=undefined ? this.props.omniphoneStore.ticketOmniphone : {}

    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.props.resetOmniphone();
        this.props.hasSaved();
      }, 3000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { media } = this.props
    // console.log('!!! SUBMIT: ', values)
    // mode==2 ? this.props.updateTicket({...values, cluster: this.state.cluster}) : this.props.createTicket({...values, cluster: this.state.cluster})

    // this.props.ticketOmniphone({...values, cluster: this.state.cluster}); //SAVE TICKET THROUGH OMNIPHONE
    this.props.createTicket({...values, cluster: this.state.cluster}); //SHOULD REPLACE WITH ticketOmniphone

    this.props.endConversation(media, 
      (media=='Chat' && {
        command: 'end_by_agent',
        visitorId: this.props.visitorId,
        agentId: this.props.agentId,
        clientId: this.props.clientId,
      })
    )
  }

  onChangeResult(v) {
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})

    this.props.dispatch(change('MyTicketFormCreate', 'progress', listRemark.progress[v]));
  }

  onChangeMacro(e, v) {
    const listMacro= (this.props.masterStore.listMacro!=undefined && this.props.masterStore.listMacro.progress=='1' ? this.props.masterStore.listMacro.data : [])

    // console.log(e, v)
    listMacro.map(item=>{
      if (item.value==v) {
        this.applyMacro(item.metadata)
      }
    })
  }

  setMode(e, d) {
    // console.log(d.activeIndex)
    this.setState({tab: d.activeIndex})
  }

  setBox(v) {
    this.setState({
      box: v,
    })
  }
  setCluster(v) {
    this.setState({
      cluster: v
    })
  }
  setContact(v) {
    this.setState({
      contact: v
    })
  }

  applyMacro(actions) {
    // console.log(actions)
    actions && actions.map(item=>{
      item && item.length>=2 && this.props.dispatch(change('MyTicketFormCreate', item[0].substring(4), item[1]));
    })
    
  }

  checkConditional(v) {
    var result= false;
    // console.log(this.props.formValues)

    v && v.map(item=>{
      switch (item[1]) {
        case '=':
          result= this.props.formValues && this.props.formValues[item[0]]==item[2];
          break;
        case '>':
          result= this.props.formValues && this.props.formValues[item[0]]>item[2];
          break;
        case '<':
          result= this.props.formValues && this.props.formValues[item[0]]<item[2];
          break;
        case '>=':
          result= this.props.formValues && this.props.formValues[item[0]]>=item[2];
          break;
        case '<=':
          result= this.props.formValues && this.props.formValues[item[0]]<=item[2];
          break;
        default:
      }
    })
    return result;
  }

  renderCustom() {
    var reqSecondaryForm= false, tickSecondaryForm= false;

    const { box, cluster, contact } = this.state
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    
    const { data }= (this.props.ticketStore.getTicket!=undefined && this.props.ticketStore.getTicket.progress=='1' ? this.props.ticketStore.getTicket : {})
    const { progress, code, text }= this.props.ticketStore.createTicket!=undefined ? this.props.ticketStore.createTicket : {};

    const listField= (this.props.masterStore.listField!=undefined && this.props.masterStore.listField.progress=='1' ? this.props.masterStore.listField.data : [])
    const listForm= (this.props.masterStore.listForm!=undefined && this.props.masterStore.listForm.progress=='1' ? this.props.masterStore.listForm.data : [])
    const listMacro= (this.props.masterStore.listMacro!=undefined && this.props.masterStore.listMacro.progress=='1' ? this.props.masterStore.listMacro.data : [])
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    const listAgent= (this.props.masterStore.listAgent!=undefined && this.props.masterStore.listAgent.progress=='1' ? this.props.masterStore.listAgent.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    const listChannel= (this.props.masterStore.listChannel!=undefined && this.props.masterStore.listChannel.progress=='1' ? this.props.masterStore.listChannel.data : [])
    const listOrganization= (this.props.masterStore.listOrganization!=undefined && this.props.masterStore.listOrganization.progress=='1' ? this.props.masterStore.listOrganization.data : [])

    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : [])
    const listReasoning= (this.props.masterStore.listReasoning!=undefined && this.props.masterStore.listReasoning.progress=='1' ? this.props.masterStore.listReasoning.data : [])
    const listResolution= (this.props.masterStore.listResolution!=undefined && this.props.masterStore.listResolution.progress=='1' ? this.props.masterStore.listResolution.data : [])
    const listClassification= (this.props.masterStore.listClassification!=undefined && this.props.masterStore.listClassification.progress=='1' ? this.props.masterStore.listClassification.data : [])

    const options= {};

    options['privacyOptions']= ticketOptions('privacy');
    options['categoryOptions']= ticketOptions('category');
    options['priorityOptions']= ticketOptions('priority');
    options['severityOptions']= ticketOptions('severity');
    options['groupOptions']= groupOptions();
    
    options['listField']= listField;
    options['listWorkgroup']= listWorkgroup;
    options['listUser']= listUser;
    options['listAgent']= listAgent;
    options['listOrganization']= listOrganization;
    options['listProduct']= listProduct;
    options['listForm']= listForm;
    options['listMacro']= listMacro;
    options['listChannel']= listChannel;

    options['listRemark']= listRemark;
    options['listReasoning']= listReasoning;
    options['listResolution']= listResolution;
    options['listClassification']= listClassification;

    const users= new Map();
    const workgroups= new Map();
    
    listUser.map(item=>{
      users.set(item.value, item)
    })
    listWorkgroup.map(item=>{
      workgroups.set(item.value, item)
    })

    const forms= new Map(), fields= fieldMaps('ticket'), indexPrimaries= new Map(), indexSecondaries= new Map(), fieldPrimaries= new Map(), groupPrimaries= new Map(), fieldSecondaries= new Map(), groupSecondaries= new Map();
    
    listForm && listForm.map(item=>{
      forms.set(item.value, item.metadata)
    })
    listField && listField.map(item=>{
      fields.set(item.value, item.metadata)
    })

    const customForm= (forms.get(this.props.formValues['formId']) || {});
    customForm.primaries && customForm.primaries.map(item=>{
      indexPrimaries.set(item.name, item)
    })
    customForm.secondaries && customForm.secondaries.map(item=>{
      indexSecondaries.set(item.name, item)
    })

    //REQUESTER FIELD / GROUP
    const reqBlocks= ['Requester', 'Personal', 'Customer', 'Corporate', 'Contacts', 'Service', 'Product', 'Subscription'];
    reqBlocks.map(block=>{
      const fieldsP= fieldPrimaries.get(block) || [];
      const groupsP= groupPrimaries.get(block) || [];
      [...indexPrimaries.keys()].map((key, i)=>{
        if (indexPrimaries.get(key).grouping==block && key.substring(0,5)!='block') {
          fieldsP.push(indexPrimaries.get(key))
          indexPrimaries.delete(key)
        } else if (indexPrimaries.get(key).grouping==block) {
          groupsP.push(indexPrimaries.get(key))
          indexPrimaries.delete(key)
        }
      })
      fieldsP.length>0 && fieldPrimaries.set(block, fieldsP)
      groupsP.length>0 && groupPrimaries.set(block, groupsP)

      const fieldsS= fieldSecondaries.get(block) || [];
      const groupsS= groupSecondaries.get(block) || [];
      
      [...indexSecondaries.keys()].map((key, i)=>{
        if (indexSecondaries.get(key).grouping==block && key.substring(0,5)!='block') {
          fieldsS.push(indexSecondaries.get(key))
          indexSecondaries.delete(key)
        } else if (indexSecondaries.get(key).grouping==block) {
          groupsS.push(indexSecondaries.get(key))
          indexSecondaries.delete(key)
        }
      })
      if (fieldsS.length>0 || groupsS.length>0) reqSecondaryForm= true;
      fieldsS.length>0 && fieldSecondaries.set(block, fieldsS)
      groupsS.length>0 && groupSecondaries.set(block, groupsS)
    })

    //TICKET FIELD / GROUP
    const ticketBlocks= ['Ticket', 'Impact', 'Extended', 'Prospect', 'Channel'];
    ticketBlocks.map(block=>{
      const fieldsP= fieldPrimaries.get(block) || [];
      const groupsP= groupPrimaries.get(block) || [];
      [...indexPrimaries.keys()].map((key, i)=>{
        if (indexPrimaries.get(key).grouping==block && key.substring(0,5)!='block') {
          fieldsP.push(indexPrimaries.get(key))
          indexPrimaries.delete(key)
        } else if (indexPrimaries.get(key).grouping==block) {
          groupsP.push(indexPrimaries.get(key))
          indexPrimaries.delete(key)
        }
      })
      fieldsP.length>0 && fieldPrimaries.set(block, fieldsP)
      groupsP.length>0 && groupPrimaries.set(block, groupsP)

      const fieldsS= fieldSecondaries.get(block) || [];
      const groupsS= groupSecondaries.get(block) || [];
      [...indexSecondaries.keys()].map((key, i)=>{
        if (indexSecondaries.get(key).grouping==block && key.substring(0,5)!='block') {
          fieldsS.push(indexSecondaries.get(key))
          indexSecondaries.delete(key)
        } else if (indexSecondaries.get(key).grouping==block) {
          groupsS.push(indexSecondaries.get(key))
          indexSecondaries.delete(key)
        }
      })
      if (fieldsS.length>0 || groupsS.length>0) tickSecondaryForm= true;
      fieldsS.length>0 && fieldSecondaries.set(block, fieldsS)
      groupsS.length>0 && groupSecondaries.set(block, groupsS)
    })

    //CHECK CONDITIONAL FOR SECONDARY FORM
    const isCond= this.checkConditional(customForm.conditions);

    return(
      <div className='ticketing' style={{padding: '1em 0em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={'equal'}>
              <Grid.Column style={{background: '#fafafa'}}>
                <div style={{display: 'flex'}}>
                  <div style={{flex: 1, whiteSpace: 'nowrap', overflow: 'hidden'}}>
                    <Header as='h5'>
                      <Header.Content>
                        <b>Assignee</b>
                        <Header.Subheader>Assignee and dispatcher information.</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{width: '13em'}}>
                    <Form.Group widths='equal' style={{marginTop: '-1em'}}>
                      <Field name='macroId' component={SelectField} style={{minHeight: '2em!important', background: '#ffe58d'}} onChange={this.onChangeMacro.bind(this)}
                      label=' '
                      options={listMacro}
                      placeholder='Macro' />
                    </Form.Group>
                  </div>
                </div>
                <Segment>
                  <Form.Group widths='16'>
                    <Field name='dispatcherMethod' component={SelectField}
                    width={6}
                    options={ticketOptions('dispatch')}
                    validate={required}
                    label='Assignment'
                    placeholder='Dispatcher' />
                  {/* </Form.Group>
                  <Form.Group widths='equal'> */}
                    {(!this.props.dispatcherMethod || this.props.dispatcherMethod!='workgroup') && 
                      <Field name='assigneeUserId' component={SelectField}
                      options={listAgent}
                      validate={required}
                      label='Agent'
                      width={10}
                      placeholder='Assigned Agent / Frontliner' />
                    }
                    {this.props.dispatcherMethod=='workgroup' && 
                      <Field name='assigneeWorkgroupId' component={SelectField}
                      options={listWorkgroup}
                      validate={required}
                      label='Team / Workgroup'
                      width={10}
                      placeholder='Assigned Team / Workgroup' />
                    }
                  </Form.Group>
                </Segment>

                <div style={{display: 'flex', marginTop: '1.5em'}}>
                  <div style={{flex: 1, whiteSpace: 'nowrap', overflow: 'hidden'}}>
                    <Header as='h5'>
                      {this.props.conversation && <Icon name={
                        (this.props.conversation.media=='Voice' && 'microphone') || (this.props.conversation.media=='IVR' && 'volume down') || (this.props.conversation.media=='SMS' && 'sticky note outline')
                        || (this.props.conversation.media=='Email' && 'envelope outline') || (this.props.conversation.media=='Whatsapp' && 'whatsapp') || (this.props.conversation.media=='Telegram' && 'telegram plane')
                        || (this.props.conversation.media=='Twitter' && 'twitter') || (this.props.conversation.media=='Facebook' && 'facebook') || (this.props.conversation.media=='Instagram' && 'instagram') || 'share alternate'
                      } />}
                      <Header.Content>
                        <b>{(cluster=='customer' && 'Customer') || (cluster=='public' && 'Public / Visitor') || (cluster=='corporate' && 'Corporate') || 'Customer'}</b>
                        {this.props.conversation && <Header.Subheader>Incoming stream from <b>{
                          (this.props.conversation.media=='Voice' && 'Phone Call') || (this.props.conversation.media=='SMS' && 'Texting / SMS') || 
                          (this.props.conversation.media=='Email' && 'email') || (this.props.conversation.media=='Whatsapp' && 'Whatsapp') || (this.props.conversation.media=='Telegram' && 'Telegram') || 
                          (this.props.conversation.media=='Twitter' && 'Twitter') || (this.props.conversation.media=='Facebook' && 'Facebook') || (this.props.conversation.media=='Instagram' && 'Instagram') || 'Walkin Ticket'
                          }</b>
                        </Header.Subheader>}
                      </Header.Content>
                    </Header>
                  </div>
                  <div>
                    <Popup content='Customer' position='left center' inverted trigger={
                      <Icon name='id badge' color={cluster=='customer' ? 'pink' : 'grey'} style={{cursor: 'pointer', fontSize: '1.15em', marginTop: '-.12em'}} onClick={this.setCluster.bind(this, 'customer')} />
                    } />
                  </div>
                  <div>
                    <Popup content='Public / Visitor' position='top center' inverted trigger={
                      <Icon name='user secret' color={cluster=='public' ? 'pink' : 'grey'} style={{cursor: 'pointer'}} onClick={this.setCluster.bind(this, 'public')} />
                    } />
                  </div>
                  <div>
                    <Popup content='Corporate' position='right center' inverted trigger={
                      <Icon name='building' color={cluster=='corporate' ? 'pink' : 'grey'} style={{cursor: 'pointer', fontSize: '1.06em'}} onClick={this.setCluster.bind(this, 'corporate')} />
                    } />
                  </div>
                </div>
                <Segment>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: 1, whiteSpace: 'nowrap', overflow: 'hidden'}}>
                      <Header as='h5' color='blue' style={{margin: '0 0 .3em 0'}}>
                        <Header.Content>Contacts</Header.Content>
                      </Header>
                    </div>
                    <div>
                      <Popup content='Email' position='left center' inverted trigger={
                        <Icon name='envelope open outline' color={contact=='mail' ? 'pink' : 'grey'}  style={{cursor: 'pointer', fontSize: '1em', marginTop: '-.1em'}} onClick={this.setContact.bind(this, 'mail')} />
                      } />
                    </div>
                    <div>
                      <Popup content='Phone' position='top center' inverted trigger={
                        <Icon name='mobile alternate' color={contact=='phone' ? 'pink' : 'grey'} style={{cursor: 'pointer', fontSize: '1em'}} onClick={this.setContact.bind(this, 'phone')} />
                      } />
                    </div>
                    <div>
                      <Popup content='Whatsapp' position='top center' inverted trigger={
                        <Icon name='whatsapp' color={contact=='whatsapp' ? 'pink' : 'grey'}  style={{cursor: 'pointer', fontSize: '1.15em', marginTop: '-.1em'}} onClick={this.setContact.bind(this, 'whatsapp')} />
                      } />
                    </div>
                    <div>
                      <Popup content='Twitter' position='right center' inverted trigger={
                        <Icon name='twitter' color={contact=='twitter' ? 'pink' : 'grey'}  style={{cursor: 'pointer', fontSize: '1em'}} onClick={this.setContact.bind(this, 'twitter')} />
                      } />
                    </div>
                    <div>
                      <Popup content='Instagram' position='right center' inverted trigger={
                        <Icon name='instagram' color={contact=='instagram' ? 'pink' : 'grey'}  style={{cursor: 'pointer', fontSize: '1em'}} onClick={this.setContact.bind(this, 'instagram')} />
                      } />
                    </div>
                    <div>
                      <Popup content='Facebook' position='right center' inverted trigger={
                        <Icon name='facebook' color={contact=='facebook' ? 'pink' : 'grey'}  style={{cursor: 'pointer', fontSize: '1em'}} onClick={this.setContact.bind(this, 'facebook')} />
                      } />
                    </div>
                  </div>
                  {contact=='phone' && <>
                    <Form.Group widths='equal'>
                      <Field name='mobile' component={InputField} icon='mobile'
                      validate={[phoneNumberID]}
                      placeholder='Mobile' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Field name='phone' component={InputField} icon='fax'
                      validate={[phoneNumberID]}
                      placeholder='Phone' />
                    </Form.Group>
                  </>}
                  {contact=='mail' && <>
                    <Form.Group widths='equal'>
                      <Field name='email' component={InputField} icon='envelope open outline'
                      validate={[email]}
                      placeholder='Email Address' />
                    </Form.Group>
                  </>}
                  {contact=='whatsapp' && <>
                    <Form.Group widths='equal'>
                      <Field name='whatsapp' component={InputField} icon='whatsapp'
                      validate={[phoneNumberID]}
                      placeholder='Whatsapp' />
                    </Form.Group>
                  </>}
                  {contact=='twitter' && <>
                    <Form.Group widths='equal'>
                      <Field name='twitter' component={InputField} icon='twitter'
                      placeholder='Twitter Account' />
                    </Form.Group>
                  </>}
                  {contact=='facebook' && <>
                    <Form.Group widths='equal'>
                      <Field name='facebook' component={InputField} icon='facebook'
                      placeholder='Facebook Account' />
                    </Form.Group>
                  </>}
                  {contact=='instagram' && <>
                    <Form.Group widths='equal'>
                      <Field name='instagram' component={InputField} icon='instagram'
                      placeholder='Instagram Account' />
                    </Form.Group>
                  </>}
                </Segment>

                {reqBlocks.map(block=>{
                  if (groupPrimaries.get(block)) {
                    return(<>
                      {groupPrimaries.get(block).map(item=>{
                        if (item.name=='blockPersonal') {
                          return(<>
                            <Segment>
                              <Form.Group widths='equal'>
                                <Field name='name' component={InputField}
                                validate={required}
                                label='Personal'
                                placeholder='Full Name' />
                              </Form.Group>
                              <Form.Group widths='equal'>
                                <Field name='gender' component={SelectField}
                                options={personalOptions('gender')}
                                placeholder='Gender' />
                                <Field name='occupation' component={SelectField}
                                options={personalOptions('occupation')}
                                placeholder='Occupation' />
                              </Form.Group>
                            </Segment>
                            <Segment>
                              <Form.Group widths='equal'>
                                <Field name='idcardCode' component={InputField}
                                placeholder='ID Card Number' />
                              </Form.Group>
                              <Form.Group widths='equal'>
                                <Field name='idcardType' component={SelectField}
                                options={personalOptions('idcard')}
                                placeholder='ID Card Type' />
                                <Field name='citizenship' component={SelectField}
                                options={personalOptions('citizenship')}
                                placeholder='Citizenship' />
                              </Form.Group>
                            </Segment>
                          </>)
                        } else if (item.name=='blockCorporate') {
                          return(
                            <Segment>
                              <Form.Group widths='equal'>
                                <Field name='company' component={InputField}
                                label='Corporate'
                                placeholder='Corporate' />
                              </Form.Group>
                              <Form.Group widths='equal'>
                                <Field name='brand' component={InputField}
                                placeholder='Brand' />
                                <Field name='business' component={InputField}
                                placeholder='Business Stream' />
                              </Form.Group>
                            </Segment>
                          )
                        } else if (item.name=='blockCustomer') {
                          return(
                            <Segment>
                              <Form.Group widths='equal'>
                                <Field name='custRef' component={InputField}
                                label='Customer'
                                placeholder='Cust. Ref / Account' />
                              </Form.Group>
                            </Segment>
                          )
                        } else if (item.name=='blockAddress') {
                          return(
                            <Segment>
                            <Form.Group widths='equal'>
                              <Field name='address' component={InputField}
                              label='Address'
                              placeholder='Address' />
                            </Form.Group>
                            <Form.Group widths='equal'>
                              <Field name='city' component={InputField}
                              placeholder='City' />
                              <Field name='province' component={InputField}
                              placeholder='Province' />
                            </Form.Group>
                            <Form.Group widths='equal'>
                              <Field name='country' component={InputField}
                              placeholder='Country' />
                              <Field name='zipcode' component={InputField}
                              placeholder='Zipcode' />
                            </Form.Group>
                          </Segment>
                          )
                        }
                      })}
                    </>)
                  } else {
                    return(<></>)
                  }
                })}
                {reqBlocks.map(block=>{
                  if (fieldPrimaries.get(block)) {
                    return(<Segment>
                      <Header as='h5' color='blue' style={{textTransform: 'capitalize'}}>{block}</Header>
                      {fieldPrimaries.get(block).map(item=>{
                        return(
                          <Form.Group widths='equal'>
                            <Field name={item.name} component={InputField}
                            // options={ticketOptions()}
                            placeholder={(fields.get(item.name) && fields.get(item.name).descript) || item.descript || item.name} />
                          </Form.Group>
                        )
                      })}
                      </Segment>)
                  } else {
                    return(<></>)
                  }
                })}

                {isCond && reqSecondaryForm && <Header as='h5'>
                    <Header.Content>
                      <b>Secondary Form</b>
                      <Header.Subheader>Available on certain conditions.</Header.Subheader>
                    </Header.Content>
                  </Header>
                }
                {isCond && reqBlocks.map(block=>{
                  if (fieldSecondaries.get(block)) {
                    return(<Segment style={{background: '#d9f3ed'}}>
                      <Header as='h5' color='blue' style={{textTransform: 'capitalize'}}><b>{block}</b></Header>
                      {fieldSecondaries.get(block).map(item=>{
                        return(
                          <Form.Group widths='equal'>
                            <Field name={item.name} component={
                              (fields.get(item.name) && fields.get(item.name).input=='select' && SelectField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='selects' && SelectField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='contain' && SelectField) ||
                              (fields.get(item.name) && fields.get(item.name).input=='contains' && SelectField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='duration' && LabelNumberField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='taggings' && InsertableDropdownField) || 
                              InputField
                            }
                            label={(fields.get(item.name) && fields.get(item.name).input=='duration' && NumberField) && 'Min'}
                            multiple={(((fields.get(item.name) && fields.get(item.name).input=='selects') || (fields.get(item.name) && fields.get(item.name).input=='contains')) && SelectField)}
                            options={options && options[(fields.get(item.name) && fields.get(item.name).options)]}

                            // multiple={(((fields.get(item.name) && fields.get(item.name).input=='selects')) && SelectField)}
                            // options={options && options[(fields.get(item.name) && fields.get(item.name).options)]}

                            placeholder={(fields.get(item.name) && fields.get(item.name).descript) || item.descript || item.name} />
                          </Form.Group>
                        )
                      })}
                      </Segment>)
                  } else {
                    return(<></>)
                  }
                })}
              </Grid.Column>
              <Grid.Column style={{background: '#f7f7f7'}}>
                <div style={{display: 'flex'}}>
                  <div style={{flex: 1, whiteSpace: 'nowrap', overflow: 'hidden'}}>
                    <Header as='h5'>
                      <Header.Content>
                        <b>Ticket / Issue</b>
                        <Header.Subheader>Ticket information and description.</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{minWidth: '13em'}}>
                    <Form.Group widths='equal' style={{marginTop: '-1em'}}>
                      <Field name='formId' component={SelectField} style={{height: '1.8em!important', background: '#d2ea8a'}}
                      label=' '
                      options={listForm}
                      placeholder='Form' />
                    </Form.Group>
                  </div>
                </div>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='priority' component={SelectField}
                    validate={required}
                    options={ticketOptions('priority')}
                    label='Priority'
                    placeholder='Priority' />
                    <Field name='code' component={InputField}
                    readOnly
                    label='Code'
                    placeholder='Ticket No' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='category' component={SelectField}
                    validate={required}
                    options={ticketOptions('category')}
                    label='Category'
                    placeholder='Category' />
                    <Field name='privacy' component={SelectField}
                    validate={required}
                    options={ticketOptions('privacy')}
                    label='Privacy'
                    placeholder='Privacy' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='subject' component={InputField}
                    validate={required}
                    label='Subject'
                    placeholder='Subject of  Ticket' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    validate={required}
                    placeholder='Description / Short Information' />
                  </Form.Group>
                </Segment>

                {ticketBlocks.map(block=>{
                  if (groupPrimaries.get(block)) {
                    return(<>
                      {groupPrimaries.get(block).map(item=>{
                        if (item.name=='blockBrand') {
                          return(
                            <Segment>
                              <Form.Group widths='equal'>
                                <Field name='productId' component={SelectField}
                                options={listProduct}
                                label='Brand of Product'
                                placeholder='Product / Service' />
                              </Form.Group>
                              <Form.Group widths='equal'>
                                <Field name='channelSupports' component={SelectField}
                                multiple
                                options={listChannel}
                                label='Contact Channels'
                                placeholder='Channel Preference' />
                              </Form.Group>
                            </Segment>
                          )
                        } else if (item.name=='blockSeverity') {
                          return(
                            <Segment>
                              <Form.Group widths='equal'>
                                <Field name='severity' component={SelectField}
                                options={ticketOptions('severity')}
                                label='Severity Level'
                                placeholder='Severity Level' />
                              </Form.Group>
                              {this.props.severity && <>
                                <Form.Group widths='equal' style={{marginTop: '1.5em'}}>
                                  <Field name='impact' component={SelectField}
                                  options={ticketOptions('impact')}
                                  label='Current Impact'
                                  placeholder='Impact of issues' />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                  <Field name='scope' component={SelectField}
                                  options={ticketOptions('scope')}
                                  // label='Domain of Impact'
                                  placeholder='Domain / group of localization' />
                                </Form.Group>
                              </>}
                            </Segment>
                          )
                        }
                      })}
                    </>)
                  }
                })}

                {ticketBlocks.map(block=>{
                  if (fieldPrimaries.get(block)) {
                    return(<Segment>
                      <Header as='h5' color='blue' style={{textTransform: 'capitalize'}}>{block}</Header>
                      {fieldPrimaries.get(block).map(item=>{
                        return(
                          <Form.Group widths='equal'>
                            <Field name={item.name} component={
                              (fields.get(item.name) && fields.get(item.name).input=='selects' && SelectField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='multiselect-reverse' && SelectField) ||
                              (fields.get(item.name) && fields.get(item.name).input=='select' && SelectField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='select-reverse' && SelectField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='taggings' && InsertableDropdownField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='multitag-reverse' && InsertableDropdownField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='duration' && NumberField) || 
                              InputField
                            }
                            multiple={(((fields.get(item.name) && fields.get(item.name).input=='selects')) && SelectField)}
                            options={options && options[(fields.get(item.name) && fields.get(item.name).options)]}
                    
                            placeholder={(fields.get(item.name) && fields.get(item.name).descript) || item.descript || item.name} />
                          </Form.Group>
                        )
                      })}
                      </Segment>)
                  } else {
                    return(<></>)
                  }
                })}

                {isCond && tickSecondaryForm && <Header as='h5'>
                    <Header.Content>
                      <b>Secondary Form</b>
                      <Header.Subheader>Available on certain conditions.</Header.Subheader>
                    </Header.Content>
                  </Header>
                }     
                {isCond && ticketBlocks.map(block=>{
                  if (fieldSecondaries.get(block)) {
                    return(<Segment style={{background: '#d9f3ed'}}>
                      <Header as='h5' color='blue' style={{textTransform: 'capitalize'}}><b>{block}</b></Header>
                      {fieldSecondaries.get(block).map(item=>{
                        return(
                          <Form.Group widths='equal'>
                            <Field name={item.name} component={
                              (fields.get(item.name) && fields.get(item.name).input=='selects' && SelectField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='multiselect-reverse' && SelectField) ||
                              (fields.get(item.name) && fields.get(item.name).input=='select' && SelectField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='select-reverse' && SelectField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='taggings' && InsertableDropdownField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='multitag-reverse' && InsertableDropdownField) || 
                              (fields.get(item.name) && fields.get(item.name).input=='duration' && NumberField) || 
                              InputField
                            }
                            multiple={(((fields.get(item.name) && fields.get(item.name).input=='selects')) && SelectField)}
                            options={options && options[(fields.get(item.name) && fields.get(item.name).options)]}
                            
                            placeholder={(fields.get(item.name) && fields.get(item.name).descript) || item.descript || item.name} />
                          </Form.Group>
                        )
                      })}
                      </Segment>)
                  } else {
                    return(<></>)
                  }
                })}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={'equal'}>
              <Grid.Column style={{background: '#f5f5f5'}}>
                <Header as='h5'>
                  <Header.Content>
                    Escalation
                    <Header.Subheader>Further assignment for ticket solution.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='16'>
                    <Field name='escalationMethod' component={SelectField}
                    options={ticketOptions('escalation')}
                    width={6}
                    label='Method'
                    placeholder='Single / Split' />
                    <Field name='escalationWorkgroups' component={SelectField}
                    options={listWorkgroup}
                    width={10}
                    multiple
                    label='Teams'
                    placeholder='Teams / Workgroups' />
                  </Form.Group>
                </Segment>
                {this.props.escalationMethod=='single' && this.props.escalationWorkgroups && this.props.escalationWorkgroups.length>0 && <Segment>
                    <Form.Group widths='equal'>
                      <Field name={'singleMessage'} component={TextAreaField}
                      width={6}
                      label={
                        this.props.escalationWorkgroups.map((item, i)=>{
                          if (i==0) {
                            return('To: @'+ (workgroups.get(item)||{}).text)
                          } else {
                            return(', @'+ (workgroups.get(item)||{}).text)
                          }
                        })
                      }
                      placeholder='Type message here ...' />
                    </Form.Group>
                    {/* ONE MESSAGE FOR ALL WITH @ACCOUNT_ID OR @TEAM_ID */}
                  </Segment>}
                {this.props.escalationMethod=='split' && this.props.escalationWorkgroups && this.props.escalationWorkgroups.length>0 && this.props.escalationWorkgroups.map((item, i)=>{
                  return(
                    <Segment>
                      <Form.Group widths='equal'>
                        <Field name={'splitMessage_@'+ item} component={TextAreaField}
                        width={6}
                        label={'To: @'+ (workgroups.get(item)||{}).text}
                        placeholder='Type message here ...' />
                      </Form.Group>
                      {/* MULTIPLE MESSAGE / ONE MESSAGE FOR EACH WORKGROUP / TEAM ESCALATION */}
                    </Segment>
                  )
                })}
              </Grid.Column>
              <Grid.Column style={{background: '#f3f3f3'}}>
                <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                  <div style={{flex: 1, background: '#eaeaea', padding: '1em 1em', margin: '-1em'}}>
                    <Header as='h5'>
                      <Header.Content>
                        <b>Remark / Status</b>
                        <Header.Subheader>Set ticket status to start SLA.</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Segment>
                      <Form.Group widths='equal'>
                        <Field name='remark' component={SelectField}
                        validate={required}
                        options={listRemark}
                        label='Remark / Status'
                        placeholder='Ticket remark or status' />
                      </Form.Group>
                    </Segment>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Ticket' : 'Insert Ticket') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }

  renderDefault() {
    const { box, cluster, contact } = this.state
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    
    const { data }= (this.props.ticketStore.getTicket!=undefined && this.props.ticketStore.getTicket.progress=='1' ? this.props.ticketStore.getTicket : {})
    const { progress, code, text }= this.props.ticketStore.createTicket!=undefined ? this.props.ticketStore.createTicket : {};

    const listField= (this.props.masterStore.listField!=undefined && this.props.masterStore.listField.progress=='1' ? this.props.masterStore.listField.data : [])
    const listForm= (this.props.masterStore.listForm!=undefined && this.props.masterStore.listForm.progress=='1' ? this.props.masterStore.listForm.data : [])
    const listMacro= (this.props.masterStore.listMacro!=undefined && this.props.masterStore.listMacro.progress=='1' ? this.props.masterStore.listMacro.data : [])
    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    const listAgent= (this.props.masterStore.listAgent!=undefined && this.props.masterStore.listAgent.progress=='1' ? this.props.masterStore.listAgent.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    const listChannel= (this.props.masterStore.listChannel!=undefined && this.props.masterStore.listChannel.progress=='1' ? this.props.masterStore.listChannel.data : [])
    const listOrganization= (this.props.masterStore.listOrganization!=undefined && this.props.masterStore.listOrganization.progress=='1' ? this.props.masterStore.listOrganization.data : [])
    
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : [])
    const listReasoning= (this.props.masterStore.listReasoning!=undefined && this.props.masterStore.listReasoning.progress=='1' ? this.props.masterStore.listReasoning.data : [])
    const listResolution= (this.props.masterStore.listResolution!=undefined && this.props.masterStore.listResolution.progress=='1' ? this.props.masterStore.listResolution.data : [])
    const listClassification= (this.props.masterStore.listClassification!=undefined && this.props.masterStore.listClassification.progress=='1' ? this.props.masterStore.listClassification.data : [])

    

    const users= new Map();
    const workgroups= new Map();
    
    listUser.map(item=>{
      users.set(item.value, item)
    })
    listWorkgroup.map(item=>{
      workgroups.set(item.value, item)
    })

    return (
      <div className='ticketing' style={{padding: '1em 0em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={'equal'}>
              <Grid.Column style={{background: '#fafafa'}}>
                <div style={{display: 'flex'}}>
                  <div style={{flex: 1, whiteSpace: 'nowrap', overflow: 'hidden'}}>
                    <Header as='h5'>
                      <Header.Content>
                        <b>Assignee</b>
                        <Header.Subheader>Assignee and dispatcher information.</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{width: '13em'}}>
                    <Form.Group widths='equal' style={{marginTop: '-1em'}}>
                      <Field name='macroId' component={SelectField} style={{minHeight: '2em!important', background: '#ffe58d'}} onChange={this.onChangeMacro.bind(this)}
                      label=' '
                      options={listMacro}
                      placeholder='Macro' />
                    </Form.Group>
                  </div>
                </div>
                <Segment>
                  <Form.Group widths='16'>
                    <Field name='dispatcherMethod' component={SelectField}
                    width={6}
                    options={ticketOptions('dispatch')}
                    validate={required}
                    label='Assignment'
                    placeholder='Dispatcher' />
                  {/* </Form.Group>
                  <Form.Group widths='equal'> */}
                    {(!this.props.dispatcherMethod || this.props.dispatcherMethod!='workgroup') && 
                      <Field name='assigneeUserId' component={SelectField}
                      options={listAgent}
                      validate={required}
                      label='Agent'
                      width={10}
                      placeholder='Assigned Agent / Frontliner' />
                    }
                    {this.props.dispatcherMethod=='workgroup' && 
                      <Field name='assigneeWorkgroupId' component={SelectField}
                      options={listWorkgroup}
                      validate={required}
                      label='Team / Workgroup'
                      width={10}
                      placeholder='Assigned Team / Workgroup' />
                    }
                  </Form.Group>
                </Segment>
                
                <div style={{display: 'flex', marginTop: '1.8em'}}>
                  <div style={{flex: 1, whiteSpace: 'nowrap', overflow: 'hidden'}}>
                    <Header as='h5'>
                      {this.props.conversation && <Icon name={
                        (this.props.conversation.media=='Voice' && 'microphone') || (this.props.conversation.media=='IVR' && 'volume down') || (this.props.conversation.media=='SMS' && 'sticky note outline')
                        || (this.props.conversation.media=='Email' && 'envelope outline') || (this.props.conversation.media=='Whatsapp' && 'whatsapp') || (this.props.conversation.media=='Telegram' && 'telegram plane')
                        || (this.props.conversation.media=='Twitter' && 'twitter') || (this.props.conversation.media=='Facebook' && 'facebook') || (this.props.conversation.media=='Instagram' && 'instagram') || 'share alternate'
                      } />}
                      <Header.Content>
                        <b>{(cluster=='customer' && 'Customer') || (cluster=='public' && 'Public / Visitor') || (cluster=='corporate' && 'Corporate') || 'Customer'}</b>
                        {this.props.conversation && <Header.Subheader>Incoming stream from <b>{
                          (this.props.conversation.media=='Voice' && 'Phone Call') || (this.props.conversation.media=='SMS' && 'Texting / SMS') || 
                          (this.props.conversation.media=='Email' && 'email') || (this.props.conversation.media=='Whatsapp' && 'Whatsapp') || (this.props.conversation.media=='Telegram' && 'Telegram') || 
                          (this.props.conversation.media=='Twitter' && 'Twitter') || (this.props.conversation.media=='Facebook' && 'Facebook') || (this.props.conversation.media=='Instagram' && 'Instagram') || 'Walkin Ticket'
                          }</b>
                        </Header.Subheader>}
                      </Header.Content>
                    </Header>
                  </div>
                  <div>
                    <Popup content='Customer' position='left center' inverted trigger={
                      <Icon name='id badge' color={cluster=='customer' ? 'pink' : 'grey'} style={{cursor: 'pointer', fontSize: '1.15em', marginTop: '-.12em'}} onClick={this.setCluster.bind(this, 'customer')} />
                    } />
                  </div>
                  <div>
                    <Popup content='Public / Visitor' position='top center' inverted trigger={
                      <Icon name='user secret' color={cluster=='public' ? 'pink' : 'grey'} style={{cursor: 'pointer'}} onClick={this.setCluster.bind(this, 'public')} />
                    } />
                  </div>
                  <div>
                    <Popup content='Corporate' position='right center' inverted trigger={
                      <Icon name='building' color={cluster=='corporate' ? 'pink' : 'grey'} style={{cursor: 'pointer', fontSize: '1.06em'}} onClick={this.setCluster.bind(this, 'corporate')} />
                    } />
                  </div>
                </div>
                <Segment>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: 1, whiteSpace: 'nowrap', overflow: 'hidden'}}>
                      <Header as='h5' color='blue' style={{margin: '0 0 .3em 0'}}>
                        <Header.Content>Contacts</Header.Content>
                      </Header>
                    </div>
                    <div>
                      <Popup content='Email' position='left center' inverted trigger={
                        <Icon name='envelope open outline' color={contact=='mail' ? 'pink' : 'grey'}  style={{cursor: 'pointer', fontSize: '1em', marginTop: '-.1em'}} onClick={this.setContact.bind(this, 'mail')} />
                      } />
                    </div>
                    <div>
                      <Popup content='Phone' position='top center' inverted trigger={
                        <Icon name='mobile alternate' color={contact=='phone' ? 'pink' : 'grey'} style={{cursor: 'pointer', fontSize: '1em'}} onClick={this.setContact.bind(this, 'phone')} />
                      } />
                    </div>
                    <div>
                      <Popup content='Whatsapp' position='top center' inverted trigger={
                        <Icon name='whatsapp' color={contact=='whatsapp' ? 'pink' : 'grey'}  style={{cursor: 'pointer', fontSize: '1.15em', marginTop: '-.1em'}} onClick={this.setContact.bind(this, 'whatsapp')} />
                      } />
                    </div>
                    <div>
                      <Popup content='Twitter' position='right center' inverted trigger={
                        <Icon name='twitter' color={contact=='twitter' ? 'pink' : 'grey'}  style={{cursor: 'pointer', fontSize: '1em'}} onClick={this.setContact.bind(this, 'twitter')} />
                      } />
                    </div>
                    <div>
                      <Popup content='Instagram' position='right center' inverted trigger={
                        <Icon name='instagram' color={contact=='instagram' ? 'pink' : 'grey'}  style={{cursor: 'pointer', fontSize: '1em'}} onClick={this.setContact.bind(this, 'instagram')} />
                      } />
                    </div>
                    <div>
                      <Popup content='Facebook' position='right center' inverted trigger={
                        <Icon name='facebook' color={contact=='facebook' ? 'pink' : 'grey'}  style={{cursor: 'pointer', fontSize: '1em'}} onClick={this.setContact.bind(this, 'facebook')} />
                      } />
                    </div>
                  </div>
                  {contact=='phone' && <>
                    <Form.Group widths='equal'>
                      <Field name='mobile' component={InputField} icon='mobile'
                      validate={[phoneNumberID]}
                      placeholder='Mobile' />
                    </Form.Group>
                    {/* <Form.Group widths='equal'>
                      <Field name='phone' component={InputField} icon='fax'
                      validate={[phoneNumberID]}
                      placeholder='Phone' />
                    </Form.Group> */}
                  </>}
                  {contact=='mail' && <>
                    <Form.Group widths='equal'>
                      <Field name='email' component={InputField} icon='envelope open outline'
                      validate={[email]}
                      placeholder='Email Address' />
                    </Form.Group>
                  </>}
                  {contact=='whatsapp' && <>
                    <Form.Group widths='equal'>
                      <Field name='whatsapp' component={InputField} icon='whatsapp'
                      validate={[phoneNumberID]}
                      placeholder='Whatsapp' />
                    </Form.Group>
                  </>}
                  {contact=='twitter' && <>
                    <Form.Group widths='equal'>
                      <Field name='twitter' component={InputField} icon='twitter'
                      placeholder='Twitter Account' />
                    </Form.Group>
                  </>}
                  {contact=='facebook' && <>
                    <Form.Group widths='equal'>
                      <Field name='facebook' component={InputField} icon='facebook'
                      placeholder='Facebook Account' />
                    </Form.Group>
                  </>}
                  {contact=='instagram' && <>
                    <Form.Group widths='equal'>
                      <Field name='instagram' component={InputField} icon='instagram'
                      placeholder='Instagram Account' />
                    </Form.Group>
                  </>}
                </Segment>
                {/* {cluster=='customer' && <>
                  <Segment>
                    <Form.Group widths='equal'>
                      <Field name='custRef' component={InputField}
                      label='Customer'
                      placeholder='Cust. Ref / Account' />
                    </Form.Group>
                  </Segment>
                </>} */}
                {cluster!='corporate' && <>
                  <Segment>
                    <Form.Group widths='equal'>
                      <Field name='name' component={InputField}
                      validate={required}
                      label='Personal'
                      placeholder='Full Name' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Field name='gender' component={SelectField}
                      options={personalOptions('gender')}
                      placeholder='Gender' />
                      <Field name='occupation' component={SelectField}
                      options={personalOptions('occupation')}
                      placeholder='Occupation' />
                    </Form.Group>
                  </Segment>
                  {/* <Segment>
                    <Form.Group widths='equal'>
                      <Field name='idcardCode' component={InputField}
                      placeholder='ID Card Number' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Field name='idcardType' component={SelectField}
                      options={personalOptions('idcard')}
                      placeholder='ID Card Type' />
                      <Field name='citizenship' component={SelectField}
                      options={personalOptions('citizenship')}
                      placeholder='Citizenship' />
                    </Form.Group>
                  </Segment> */}
                </>}
                {cluster=='corporate' && <>
                  <Segment>
                    <Form.Group widths='equal'>
                      <Field name='company' component={InputField}
                      label='Corporate'
                      placeholder='Corporate' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Field name='brand' component={InputField}
                      placeholder='Brand' />
                      <Field name='business' component={InputField}
                      placeholder='Business Stream' />
                    </Form.Group>
                  </Segment>
                </>}
                {/* {cluster!='customer' && <>
                  <Segment>
                    <Form.Group widths='equal'>
                      <Field name='address' component={InputField}
                      label='Address'
                      placeholder='Address' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Field name='city' component={InputField}
                      placeholder='City' />
                      <Field name='province' component={InputField}
                      placeholder='Province' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Field name='country' component={InputField}
                      placeholder='Country' />
                      <Field name='zipcode' component={InputField}
                      placeholder='Zipcode' />
                    </Form.Group>
                  </Segment>
                </>} */}
                
                {/* <Header as='h5'>
                  <Header.Content>
                    Assets / Documents
                    <Header.Subheader>Related assets, documents or images</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Header as='h5' color='blue' style={{margin: '0 0 .3em 0'}}>
                    Photo / Images
                  </Header>

                  <Divider />
                  <Header as='h5' color='blue' style={{margin: '0 0 .3em 0'}}>
                    Files / Documents
                  </Header>
                </Segment> */}
              </Grid.Column>
              <Grid.Column style={{background: '#f7f7f7'}}>
                <div style={{display: 'flex'}}>
                  <div style={{flex: 1, whiteSpace: 'nowrap', overflow: 'hidden'}}>
                    <Header as='h5'>
                      <Header.Content>
                        <b>Ticket / Issue</b>
                        <Header.Subheader>Ticket information and description.</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{minWidth: '13em'}}>
                    <Form.Group widths='equal' style={{marginTop: '-1em'}}>
                      <Field name='formId' component={SelectField} style={{height: '1.8em!important', background: '#d2ea8a'}}
                      label=' '
                      options={listForm}
                      placeholder='Form' />
                    </Form.Group>
                  </div>
                </div>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='priority' component={SelectField}
                    validate={required}
                    options={ticketOptions('priority')}
                    label='Priority'
                    placeholder='Priority' />
                    <Field name='code' component={InputField}
                    readOnly
                    label='Code'
                    placeholder='Ticket No' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='category' component={SelectField}
                    validate={required}
                    options={ticketOptions('category')}
                    label='Category'
                    placeholder='Category' />
                    <Field name='privacy' component={SelectField}
                    validate={required}
                    options={ticketOptions('privacy')}
                    label='Privacy'
                    placeholder='Privacy' />
                  </Form.Group>
                </Segment>
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='subject' component={InputField}
                    validate={required}
                    label='Subject'
                    placeholder='Subject of  Ticket' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='descript' component={TextAreaField}
                    validate={required}
                    placeholder='Description / Short Information' />
                  </Form.Group>
                </Segment>
                {/* <Segment>
                  <Form.Group widths='equal'>
                    <Field name='productId' component={SelectField}
                    options={listProduct}
                    label='Brand of Product'
                    placeholder='Product / Service' />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <Field name='channelSupports' component={SelectField}
                    multiple
                    options={listChannel}
                    label='Contact Channels'
                    placeholder='Channel Preference' />
                  </Form.Group>
                </Segment> */}
                <Segment>
                  <Form.Group widths='equal'>
                    <Field name='tags' component={InsertableDropdownField}
                    label='Tags / Taggers'
                    placeholder='Ticket Taggings' />
                  </Form.Group>
                </Segment>
                {/* <Segment>
                  <Form.Group widths='equal'>
                    <Field name='severity' component={SelectField}
                    options={ticketOptions('severity')}
                    label='Severity Level'
                    placeholder='Severity Level' />
                  </Form.Group>
                  {this.props.severity && <>
                    <Form.Group widths='equal' style={{marginTop: '1.5em'}}>
                      <Field name='impact' component={SelectField}
                      options={ticketOptions('impact')}
                      label='Current Impact'
                      placeholder='Impact of issues' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Field name='scope' component={SelectField}
                      options={ticketOptions('scope')}
                      // label='Domain of Impact'
                      placeholder='Domain / group of localization' />
                    </Form.Group>
                  </>}
                </Segment> */}
                {/* <Segment>
                  <Form.Group widths='equal'>
                    <Field name='classificationId' component={SelectField}
                    options={listClassification}
                    label='Classification'
                    placeholder='Classification of  Ticket' />
                  </Form.Group>
                </Segment> */}
                {/* {this.props.classificationId && 
                  <Segment>
                    <Header as='h5' color='blue' style={{margin: '0 0 .3em 0'}}>
                      Initial Assessment
                    </Header>
                    <Form.Group widths='equal'>
                      <Field name='assesment' component={InputField}
                      placeholder='Assessment Information' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Field name='assesment' component={SelectField}
                      placeholder='Assessment Information' />
                    </Form.Group>
                  </Segment>
                } */}
                {/* <Segment>
                  <Form.Group widths='equal'>
                    <Field name='skillsets' component={InsertableDropdownField}
                    label='Required Skill Set'
                    placeholder='Backend / Support Skill Sets' />
                  </Form.Group>
                </Segment> */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={'equal'}>
              <Grid.Column style={{background: '#f0f0f0'}}>
                <Header as='h5'>
                  <Header.Content>
                    <b>Escalation</b>
                    <Header.Subheader>Further assignment for ticket solution.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  <Form.Group widths='16'>
                    <Field name='escalationMethod' component={SelectField}
                    options={ticketOptions('escalation')}
                    width={6}
                    label='Method'
                    placeholder='Single / Split' />
                    <Field name='escalationWorkgroups' component={SelectField}
                    options={listWorkgroup}
                    width={10}
                    multiple
                    label='Teams'
                    placeholder='Teams / Workgroups' />
                  </Form.Group>
                </Segment>
                {this.props.escalationMethod=='single' && this.props.escalationWorkgroups && this.props.escalationWorkgroups.length>0 && <Segment>
                    <Form.Group widths='equal'>
                      <Field name={'singleMessage'} component={TextAreaField}
                      width={6}
                      label={
                        this.props.escalationWorkgroups.map((item, i)=>{
                          if (i==0) {
                            return('To: @'+ (workgroups.get(item)||{}).text)
                          } else {
                            return(', @'+ (workgroups.get(item)||{}).text)
                          }
                        })
                      }
                      placeholder='Type message here ...' />
                    </Form.Group>
                    {/* ONE MESSAGE FOR ALL WITH @ACCOUNT_ID OR @TEAM_ID */}
                  </Segment>}
                {this.props.escalationMethod=='split' && this.props.escalationWorkgroups && this.props.escalationWorkgroups.length>0 && this.props.escalationWorkgroups.map((item, i)=>{
                  return(
                    <Segment>
                      <Form.Group widths='equal'>
                        <Field name={'splitMessage_@'+ item} component={TextAreaField}
                        width={6}
                        label={'To: @'+ (workgroups.get(item)||{}).text}
                        placeholder='Type message here ...' />
                      </Form.Group>
                      {/* MULTIPLE MESSAGE / ONE MESSAGE FOR EACH WORKGROUP / TEAM ESCALATION */}
                    </Segment>
                  )
                })}
              </Grid.Column>
              <Grid.Column style={{background: '#f0f0f0'}}>
                <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                  <div>
                    {false && <>
                      <Divider style={{margin: '1em 0 1em 0'}} />
                      <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}>
                        </div>
                        <div style={{padding: '0 0 0 1em'}}>
                          <Popup content='First initial ticket conversation from public / private requester through certain channel.' position='left center' inverted trigger={
                            <Button type='button' onClick={this.setBox.bind(this, 'request')} color={box=='request' ? 'orange' : 'grey'} circular icon style={{padding: '0 0 0 0', margin: '0'}}><Icon name='commenting' /></Button>
                          } />
                        </div>
                        <div style={{padding: '0 0 0 1em'}}>
                          {/* <Divider style={{margin: '.5em 0'}}/>
                          <Popup content='Ticket Interaction Timelines, shortened recently, based on ticket update.' position='left center' inverted trigger={
                            <Button type='button' onClick={this.setBox.bind(this, 'interaction')} color={box=='interaction' ? 'teal' : null} circular icon style={{padding: '0 0 0 0', margin: '0'}}><Icon name='ellipsis vertical' /></Button>
                          } /> */}
                          {/* <Divider style={{margin: '.5em 0'}}/>
                          <Popup content='Ticket Conversations, from main  to side conversation whether public and internal conversation.' position='left center' inverted trigger={
                            <Button type='button' onClick={this.setBox.bind(this, 'conversation')} color={box=='conversation' ? 'teal' : null} circular icon style={{padding: '0 0 0 0', margin: '0'}}><Icon name='comments' /></Button>
                          } /> */}
                          {/* <Divider style={{margin: '.5em 0'}}/>
                          <Popup content='Tickets, All related sub tickets for parent / main ticket.' position='left center' inverted trigger={
                            <Button type='button' onClick={this.setBox.bind(this, 'ticket')} color={box=='ticket' ? 'teal' : null} circular icon style={{padding: '0 .25em 0 0', margin: '0'}}><Icon name='tags' /></Button>
                          } /> */}
                          {/* <Divider style={{margin: '.5em 0'}}/>
                          <Popup content='People, Agents and backend support who responsible for ticket.' position='left center' inverted trigger={
                            <Button type='button' onClick={this.setBox.bind(this, 'people')} color={box=='people' ? 'teal' : null} circular icon style={{padding: '0 .25em 0 0', margin: '0'}}><Icon name='users' /></Button>
                          } /> */}
                          {/* <Divider style={{margin: '.5em 0'}}/>
                          <Popup content='Photo, Images and Documents related to ticket, shortened recently.' position='left center' inverted trigger={
                            <Button type='button' onClick={this.setBox.bind(this, 'resource')} color={box=='resource' ? 'teal' : null} circular icon style={{padding: '0 .25em 0 0', margin: '0'}}><Icon name='cloud' /></Button>
                          } /> */}
                          {/* <Divider style={{margin: '.5em 0'}}/> */}
                          <Popup content='Knowledge Based of service, product and customer care information, available for copy paste shortcut.' position='left center' inverted trigger={
                            <Button type='button' onClick={this.setBox.bind(this, 'knowledge')} color={box=='knowledge' ? 'teal' : 'grey'} circular icon style={{padding: '0 0 0 0', margin: '0'}}><Icon name='book' /></Button>
                          } />
                        </div>
                      </div>
                      <div style={{marginTop: '-2.3em'}}>
                        {this.state.box=='request' && <ConversationBox mode='request' channel='voice' />}
                        {this.state.box=='interaction' && <InteractionBox />}
                        {this.state.box=='conversation' && <ConversationBox mode='conversation' />}
                        {this.state.box=='ticket' && <TicketBox />}
                        {this.state.box=='people' && <PeopleBox />}
                        {this.state.box=='resource' && <ResourceBox />}
                        {this.state.box=='knowledge' && <KnowledgeBox />}
                      </div>
                    </>}
                  </div>
                  <div style={{background: '#eaeaea', padding: '1em 1em', margin: '-1em', flex: 1}}>
                    <Header as='h5'>
                      <Header.Content>
                        <b>Remark / Status</b>
                        <Header.Subheader>Set ticket status to start SLA.</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Segment>
                      <Form.Group widths='equal'>
                        <Field name='remark' component={SelectField}
                        validate={required}
                        options={listRemark}
                        label='Remark / Status'
                        placeholder='Ticket remark or status' />
                      </Form.Group>
                    </Segment>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Ticket' : 'Insert Ticket') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{flex: 1, textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }

  render() {
    if (this.props.formValues && this.props.formValues['formId']) {
      return(<div style={{...this.props.style}}>{this.renderCustom()}</div>)
    } else {
      return(<div style={{...this.props.style}}>{this.renderDefault()}</div>)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.ticketStore.getTicket!=undefined && state.ticketStore.getTicket.progress==1 ? state.ticketStore.getTicket.data : {}),
      formValues: getFormValues('MyTicketFormCreate')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      severity: selector(state, 'severity'),
      classificationId: selector(state, 'classificationId'),
      dispatcherMethod: selector(state, 'dispatcherMethod'),
      escalationMethod: selector(state, 'escalationMethod'),
      escalationWorkgroups: selector(state, 'escalationWorkgroups'),
    }),
    { load: getTicket }
  )
  (reduxForm({
    form: 'MyTicketFormCreate',	// a unique identifier for this form
  })(MyTicketFormCreate)
  )
)
