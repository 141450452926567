import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

import AgentHeader from "./AgentHeader"
import AgentFooter from "./AgentFooter"
import AgentBody from "./AgentBody"

class Agent extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        screenHeight: 800,
        screenWidth: 1024,
        mode: 'dashboard',

        openDrawer: true,
      }
      this.updateDimension = this.updateDimension.bind(this)
    }

    componentDidMount() {

      var elem=  document.getElementById("screen");
      elem.requestFullscreen()
      .catch(error=>{})
      window.screen.orientation.lock('portrait')
      
      this.updateDimension()
      window.addEventListener('resize', this.updateDimension)
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimension)
      this.context.socket && this.context.socket.close();
    }
    
    updateDimension() {
      this.setState({ ...this.state, screenWidth: window.innerWidth, screenHeight: window.innerHeight })
    }

    openWindow(mode) {
      this.setState({
        mode: mode,
      })
    }

    openDrawer() {
      const { openDrawer }= this.state
      this.setState({
        openDrawer: !openDrawer
      })
    }

    render() {
      const {screenHeight, screenWidth, mode, openDrawer}= this.state
      
      return(
        <div style={{display: 'flex', flexDirection: 'column', height: screenHeight}}>
          <div style={{flex: '1', overflowY: 'auto'}}>
            <Container fluid>
              <AgentHeader screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.openWindow.bind(this)} mode={mode} openDrawer={this.openDrawer.bind(this)} mini={openDrawer}/>
              <AgentBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.openWindow.bind(this)} mode={mode} mini={openDrawer}/>
              <AgentFooter screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.openWindow.bind(this)}/>
            </Container>
          </div>

          <div style={{background: '#fafafa', padding: '1em', borderTop: '1px solid #ccc'}}>
            <div style={{display: 'flex', textAlign: 'center'}}>
              <div style={{flex: '1'}}>
                <Button icon size='medium' style={{border: 'none', background: 'none', padding: '.8em .3em .3em .3em', color: mode=='dashboard' ? '#da0000' : '#545454', textShadow: '0 1px #ccc', marginTop: mode=='dashboard' ? '0' : 0}} onClick={this.openWindow.bind(this, 'dashboard')} active= {mode=='dashboard'}>
                  <Icon name='arrow alternate circle up outline' style={{fontSize: '1.9em'}}/>
                </Button>
              </div>
              <div style={{flex: '1'}}>
                <Button icon size='medium' style={{border: 'none', background: 'none', padding: '.8em .3em .3em .3em', color: mode=='statistic' ? '#da0000' : '#545454', textShadow: '0 1px #ccc', marginTop: mode=='statistic' ? '0' : 0}} onClick={this.openWindow.bind(this, 'statistic')} active= {mode=='statistic'}>
                  <Icon name='chart bar' style={{fontSize: '1.9em'}}/>
                </Button>
              </div>
              <div style={{flex: '1'}}>
                <Button icon size='medium' style={{border: 'none', background: 'none', padding: '.8em .3em .3em .3em', color: mode=='target' ? '#da0000' : '#545454', textShadow: '0 1px #ccc', marginTop: mode=='target' ? '0' : 0}} onClick={this.openWindow.bind(this, 'target')} active= {mode=='target'}>
                  <Icon name='user circle' style={{fontSize: '1.9em'}}/>
                </Button>
              </div>
              {/* <div style={{flex: '1'}}>
                <Button icon size='medium' style={{border: 'none', background: 'none', padding: '.8em .3em .3em .3em', color: mode=='conversation' ? '#da0000' : '#545454', textShadow: '0 1px #ccc', marginTop: mode=='conversation' ? '0' : 0}} onClick={this.openWindow.bind(this, 'conversation')} active= {mode=='conversation'}>
                  <Icon name='comments outline' style={{fontSize: '1.9em'}}/>
                </Button>
              </div> */}
              {/* <div style={{flex: '1'}}>
                <Button icon size='medium' style={{border: 'none', background: 'none', padding: '.8em .3em .3em .3em', color: mode=='transact' ? '#da0000' : '#545454', textShadow: '0 1px #ccc', marginTop: mode=='transact' ? '0' : 0}} onClick={this.openWindow.bind(this, 'transact')} active= {mode=='transact'}>
                  <Icon name='envelope open' style={{fontSize: '1.9em'}}/>
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      )
    }
} 


Agent.contextType= WebSocketContext;

export default Agent
