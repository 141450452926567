import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'

import OccupancyBrowse from '../../../libraries/realm/ring/OccupancyBrowse';
import BillingWidget from '../../../libraries/widget/BillingWidget'
import NotificationWidget from '../../../libraries/widget/NotificationWidget'

class DashboardBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      userId: 0,
    };
  }

  setMode(mode, id) {
    this.setState({mode: mode, userId: id})
  }

  render() {
    const {screenWidth, screenHeight}= this.props
    
    return(
      <div>
        {screenWidth<1000 && 
          <div style={{padding: '1em'}}>
              <OccupancyBrowse screenWidth={screenWidth} screenHeight={screenHeight}/>
          </div>
        }
          
        <div style={{display: 'flex'}}>
          <div style={{flex: '1', padding: '1em'}}>
            <OccupancyBrowse screenWidth={screenWidth} screenHeight={screenHeight}/>
          </div>
          {screenWidth>1000 &&
            <div style={{width: '300px', minHeight: screenHeight, padding: '1em'}}>
              <BillingWidget screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow.bind(this)} openModal={this.props.openModal.bind(this)} />
              {/* <Omniwidget /> */}
              <NotificationWidget />
              {/* <AppointmentWidget /> */}
            </div>
          }
        </div>
      </div>
    )
  }
}

export default DashboardBody
