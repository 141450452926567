import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import { packageOptions, billingOptions }  from '../../common/StaticMasterData'
import DismissableMessage from '../../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'
import { connect } from "react-redux"
import { resetRates, getRates,updateRates, createRates } from "../../../actions/halo/ratesAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    ratesStore: state.ratesStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetRates:(objParam) => dispatch(resetRates(objParam)),
    getRates:(objParam) => dispatch(getRates(objParam)),
    createRates:(objParam) => dispatch(createRates(objParam)),
    updateRates:(objParam) => dispatch(updateRates(objParam)),
  }
}

class RatesForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetRates(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getRates({ratesId: this.props.ratesId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.ratesStore.updateRates!=undefined ? this.props.ratesStore.updateRates : {}) : (this.props.ratesStore.createRates!=undefined ? this.props.ratesStore.createRates : {}))
    if (progress==1 && code=='00') {
      // setTimeout(()=>{this.props.setMode(1)}, 1000 )
      setTimeout(()=>{this.props.closeModal()}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, ratesId } = this.props
    mode==2 ? this.props.updateRates({...values}) : this.props.createRates({...values})
  }
  

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.ratesStore.updateRates!=undefined ? this.props.ratesStore.updateRates : {}) : (this.props.ratesStore.createRates!=undefined ? this.props.ratesStore.createRates : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Header as='h3'>
          <Icon name='warning sign' style={{fontSize: '1em'}}/>
          <Header.Content>
            Service Rating / Billing Estimation
            <Header.Subheader><b>Default rating is random value, for valid value ask your providers</b></Header.Subheader>
          </Header.Content>
        </Header>
        <Divider hidden />
        
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {/* <Segment>
            <Header as='h5'>
              <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
              <Header.Content>License Cost</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='userLicense' component={NumberField}
              suffix=' / User'
              label='User Voice License'
              placeholder='User voice license cost' />
              <Field name='omniLicense' component={NumberField}
              suffix=' / User'
              label='User Omni License'
              placeholder='User omni license cost, including voice' />
              <Field name='serverLicense' component={NumberField}
              suffix=' / Server'
              label='Server License'
              placeholder='Server box license cost / cpu basis' />
              <Field name='apiLicense' component={NumberField}
              suffix=' / Hit'
              label='API License'
              placeholder='API license cost as per hit' />
            </Form.Group>
          </Segment> */}

          <Segment>
            <Header as='h5'>
              <Icon name='microphone' style={{fontSize: '1em'}}/>
              <Header.Content>Voice / Call Cost</Header.Content>
            </Header>
            {/* <Form.Group widths='equal'>
              <Field name='previewDialer' component={NumberField}
              suffix=' / Contacted'
              label='Preview Dialer'
              placeholder='Preview dialing per contacted cost' />
              <Field name='progressiveDialer' component={NumberField}
              suffix=' / Contacted'
              label='Progressive Dialer'
              placeholder='Progressive dialing per contacted cost' />
              <Field name='predictiveDialer' component={NumberField}
              suffix=' / Contacted'
              label='Predictive Dialer'
              placeholder='Predictive dialing per contacted cost' />
              <Field name='omniphone' component={NumberField}
              suffix=' / Contacted'
              label='Omniphone'
              placeholder='Omniphone cost' />
            </Form.Group> */}
            <Form.Group widths='equal'>
              {/* <Field name='voiceProcessing' component={NumberField}
              suffix=' / Sec'
              label='Voice Processing'
              placeholder='Voice content processing cost' />
              <Field name='voiceRecording' component={NumberField}
              suffix=' / Sec'
              decimalScale={1}
              label='Voice Recording'
              placeholder='Voice recording cost per second' /> */}
              <Field name='voiceMailboxDetection' component={NumberField}
              validate= {[required]}
              suffix=' / Call'
              label='Mailbox Detection'
              placeholder='Mailbox detection per call' />
              <Field name='voiceDelivery' component={NumberField}
              validate= {[required]}
              suffix=' / Sec'
              placeholder='Voice transmission cost'
              label='Call Delivery' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='volume down' style={{fontSize: '1em'}}/>
              <Header.Content>IVR / Blast Cost</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='ivrProcessing' component={NumberField}
              validate= {[required]}
              suffix=' / Sec'
              label='IVR Processing'
              placeholder='Content processing cost' />
              {/* <Field name='ivrRecording' component={NumberField}
              decimalScale={1}
              suffix=' / Sec'
              label='IVR Recording'
              placeholder='Voice recording cost per second' />
              <Field name='ivrMailboxDetection' component={NumberField}
              suffix=' / Call'
              label='Mailbox Detection'
              placeholder='Mailbox detection per call' /> */}
              <Field name='voiceDelivery' component={NumberField}
              validate= {[required]}
              suffix=' / Sec'
              label='Call Delivery'
              placeholder='Voice transmission cost' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='whatsapp' style={{fontSize: '1em'}}/>
              <Header.Content>Whatsapp Cost</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='whatsappProcessing' component={NumberField}
              validate= {[required]}
              suffix=' / Mesg'
              label='Content Processing'
              placeholder='Content processing cost' />
              {/* <Field name='whatsappMaskedDelivery' component={NumberField}
              suffix=' / Mesg'
              label='Whatsapp Masked Sender Delivery'
              placeholder='Masked sender delivery cost' /> */}
              <Field name='whatsappNumberDelivery' component={NumberField}
              validate= {[required]}
              suffix=' / Mesg'
              label='Content Delivery'
              placeholder='Long number sender delivery cost' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='sticky note outline' style={{fontSize: '1em'}}/>
              <Header.Content>SMS / Texting Cost</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='smsProcessing' component={NumberField}
              validate= {[required]}
              suffix=' / Mesg'
              label='Content Processing'
              placeholder='Content processing cost' />
              {/* <Field name='smsMaskedDelivery' component={NumberField}
              suffix=' / Mesg'
              label='SMS Masked Sender Delivery'
              placeholder='Masked sender delivery cost' /> */}
              <Field name='smsNumberDelivery' component={NumberField}
              validate= {[required]}
              suffix=' / Mesg'
              label='Content Delivery'
              placeholder='Long number sender delivery cost' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='envelope outline' style={{fontSize: '1em'}}/>
              <Header.Content>Email Cost</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='emailProcessing' component={NumberField}
              validate= {[required]}
              width={8}
              suffix=' / Mesg'
              label='Content Processing'
              placeholder='Content processing cost' />
              <Field name='emailDelivery' component={NumberField}
              validate= {[required]}
              width={8}
              suffix=' / Mesg'
              label='Content Delivery'
              placeholder='Email transmission cost' />
            </Form.Group>
          </Segment>
          {/* <Segment>
            <Header as='h5'>
              <Icon name='share alternate' style={{fontSize: '1em'}}/>
              <Header.Content>Social Media Cost (Twitter, Facebook, Instagram)</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='socialProcessing' component={NumberField}
              width={8}
              suffix=' / Mesg'
              label='Content/Message Processing'
              placeholder='Content processing cost' />
              <Field name='socialContentDelivery' component={NumberField}
              width={4}
              suffix=' / Mesg'
              label='Post Content Delivery'
              placeholder='Post content delivery cost' />
              <Field name='socialMessageDelivery' component={NumberField}
              width={4}
              suffix=' / Mesg'
              label='Send Message Delivery'
              placeholder='Send message delivery cost' />
            </Form.Group>
          </Segment> */}

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Rates' : 'Insert Rates') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.ratesStore.getRates!=undefined && state.ratesStore.getRates.progress==1 ? state.ratesStore.getRates.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'RatesForm',	// a unique identifier for this form
  })(RatesForm)
  )
)