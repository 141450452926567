export default function disasterReducer (state = {}, action) {
  switch (action.type) {
    case 'DISASTER_RESET': {
        return({
            ...state,
            getDisaster: {},
            updateDisaster: {},
            createDisaster: {},
        })
    }

    case 'DISASTER_GET_PENDING': {
        return({...state, getDisaster: {progress: 0}})
    }
    case 'DISASTER_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getDisaster: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getDisaster: {...action.payload.data, data: action.payload.data.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DISASTER_GET_REJECTED': {
        return({...state, getDisaster: {progress: 2, payload: action.payload}})
    }

    case 'DISASTER_STATUS_PENDING': {
      return({...state, statusDisaster: {progress: 0}})
    }
    case 'DISASTER_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusDisaster: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusDisaster: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DISASTER_STATUS_REJECTED': {
        return({...state, statusDisaster: {progress: 2, payload: action.payload}})
    }

    case 'DISASTER_BROWSE_PENDING': {
      return({...state, browseDisaster: {progress: 0}})
    }
    case 'DISASTER_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseDisaster: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseDisaster: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DISASTER_BROWSE_REJECTED': {
        return({...state, browseDisaster: {progress: 2, payload: action.payload}})
    }

    case 'DISASTER_CREATE_PENDING': {
      return({...state, browseDisaster: {progress: 0}})
    }
    case 'DISASTER_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createDisaster: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createDisaster: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DISASTER_CREATE_REJECTED': {
        return({...state, createDisaster: {progress: 2, payload: action.payload}})
    }

    case 'DISASTER_UPDATE_PENDING': {
      return({...state, updateDisaster: {progress: 0}})
    }
    case 'DISASTER_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateDisaster: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateDisaster: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DISASTER_UPDATE_REJECTED': {
        return({...state, updateDisaster: {progress: 2, payload: action.payload}})
    }

    case 'DISASTER_REMOVE_PENDING': {
      return({...state, removeDisaster: {progress: 0}})
    }
    case 'DISASTER_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeDisaster: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeDisaster: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DISASTER_REMOVE_REJECTED': {
        return({...state, removeDisaster: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}