import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import {groupOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetSocial, getSocial,updateSocial, createSocial } from "../../actions/stream/socialAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socialStore: state.socialStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetSocial:(objParam) => dispatch(resetSocial(objParam)),
    getSocial:(objParam) => dispatch(getSocial(objParam)),
    createSocial:(objParam) => dispatch(createSocial(objParam)),
    updateSocial:(objParam) => dispatch(updateSocial(objParam)),
  }
}

class SocialForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      showPasswd: false,
    }
  }

  componentDidMount(){
    this.props.resetSocial(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getSocial({userId: this.props.userId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.socialStore.updateSocial!=undefined ? this.props.socialStore.updateSocial : {}) : (this.props.socialStore.createSocial!=undefined ? this.props.socialStore.createSocial : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateSocial({...values}) : this.props.createSocial({...values})
    
  }

  render() {
    const { showPasswd }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.socialStore.updateSocial!=undefined ? this.props.socialStore.updateSocial : {}) : (this.props.socialStore.createSocial!=undefined ? this.props.socialStore.createSocial : {}))
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='equal' >
              <Form.Field>
                <Field name='avatar' component={ImageUploadField}
                width={300} 
                height={300} 
                aspectRatio= '3:3'
                //srcImage='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRl2225qeR2VLzqmTgeKcS2kNsERIHhG0jsHv0X_dgLVL9MJsD-'
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='isEnabled' component={CheckboxField}
              toggle
              caption= 'Enable Now !' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='name' component={InputField}
              icon='id badge outline'
              label= 'Full Name'
              placeholder='Full Name'
              validate={[required]} />
            </Form.Group>
          </Segment>
          
          <Segment>
            <Form.Group widths='equal'>
              <Field name='social' component={InputField}
              readOnly= {mode==2? true : false}
              icon='user outline'
              label= 'Social'
              placeholder='Social'
              validate={[required]} />
              <Field name='groupId' component={SelectField}
              label= 'User Group'
              placeholder='Role / Group'
              options={groupOptions('halopagi')}
              validate={[required]} />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group widths='equal'>
              <Field name='email' component={InputField}
              // readOnly= {mode==2? true : false}
              label= 'Email'
              icon='envelope outline'
              placeholder='Email'
              validate={[required]} />
              <Field name='mobile' component={InputField}
              // readOnly= {mode==2? true : false}
              label= 'Mobile'
              icon='square outline'
              placeholder='Mobile'
              validate={[required, phoneNumber]} />
            </Form.Group>
            
            <Divider hidden />
            <Form.Group widths='equal'>
              <Field name='userPasswd' component={InputField}
              icon='unlock alternate'
              type='password'
              validate={this.props.mode==2 ? [userPassword] : [required, userPassword]}
              label= 'New Password'
              placeholder='Password' />
              <Field name='confirmedPasswd' component={InputField}
              icon='unlock alternate'
              type='password'
              validate={this.props.mode==2 ? [confirmedPassword] : [required, confirmedPassword]}
              label= 'Confirmed Password'
              placeholder='Confirmed Password' />
            </Form.Group>
          </Segment>
          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Social' : 'Insert Social') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.socialStore.getSocial!=undefined && state.socialStore.getSocial.progress==1 ? state.socialStore.getSocial.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: getSocial }
  )
  (reduxForm({
    form: 'SocialForm',	// a unique identifier for this form
  })(SocialForm)
  )
)
