import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Card } from 'semantic-ui-react'

import numeral from "numeral"

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus } from "../../actions/socket/socketAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
  }
}

class ConversationWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }

  render() {
    const { mini, screenWidth,  screenHeight}= this.props
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data : undefined)
    const { performance }= (agentStatus && agentStatus.payload.data) || {status: 'connect'}

    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em', margin: '0', background: '#eaeaea'}}>
        <Header as='h5'>
          <Icon name='comments outline' style={{fontSize: '1em'}} />
          <Header.Content>
            Interaction
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached={true} style={{padding: '1em .5em', margin: '0', textAlign: 'center'}}>
        <div style={{display: 'flex'}}>
          <div style={{flex: '1', textAlign: 'center', marginRight: '.1em'}}>
            <Header as='h5'>
              <Header.Content>
                {performance && (performance.totEngaged>1000 ? numeral(performance.totEngaged).format('0.00a') : numeral(performance.totEngaged).format('0,0'))}
                <Header.Subheader><Icon name='phone' />/Contacted</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{flex: '1', textAlign: 'center', marginLeft: '.1em'}}>
            <Header as='h5'>
              <Header.Content>
                {performance && (performance.totAchieved>1000 ? numeral(performance.totAchieved).format('0.00a') : numeral(performance.totAchieved).format('0,0'))}
                <Header.Subheader><Icon name='envelope open outline' />/Achieved</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
        </div>
      </Segment>
      {(mini==undefined || mini==false) && <>
      <Segment attached={true} style={{padding: '1em .5em', margin: '0', textAlign: 'center'}}>
        <Header as='h5' color='red' style={{margin: '0'}}>
          <Header.Content>
            Stat. Quantity
          </Header.Content>
        </Header>
        <Divider style={{margin: '.5em'}}/>
        <div style={{display: 'flex'}}>
          <div style={{flex: '1', textAlign: 'center', marginRight: '.1em'}}>
            <Header as='h5'>
              <Header.Content>
                30
                <Header.Subheader><Icon name='phone' />/ Avg. Calls</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{flex: '1', textAlign: 'center', marginLeft: '.1em'}}>
            <Header as='h5'>
              <Header.Content>
                30
                <Header.Subheader><Icon name='envelope open outline' />/ Avg. Chats</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
        </div>
        <Divider style={{margin: '.5em'}}/>
        <div style={{display: 'flex'}}>
          <div style={{flex: '1', textAlign: 'center', marginRight: '.1em'}}>
            <Header as='h5'>
              <Header.Content>
                30
                <Header.Subheader><Icon name='phone' />/ Max. Calls</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{flex: '1', textAlign: 'center', marginLeft: '.1em'}}>
            <Header as='h5'>
              <Header.Content>
                30
                <Header.Subheader><Icon name='envelope open outline' />/ Max. Chats</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
        </div>
      </Segment>
      <Segment attached={true} style={{padding: '1em .5em', margin: '0', textAlign: 'center'}}>
        <Header as='h5' color='red' style={{margin: '0'}}>
          <Header.Content>
            Stat. Duration
          </Header.Content>
        </Header>
        <Divider style={{margin: '.5em'}}/>
        <div style={{display: 'flex'}}>
          <div style={{flex: '1', textAlign: 'center', marginRight: '.1em'}}>
            <Header as='h5'>
              <Header.Content>
                30
                <Header.Subheader><Icon name='phone' />/ Avg. Calls</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{flex: '1', textAlign: 'center', marginLeft: '.1em'}}>
            <Header as='h5'>
              <Header.Content>
                30
                <Header.Subheader><Icon name='envelope open outline' />/ Avg. Chats</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
        </div>
        <Divider style={{margin: '.5em'}}/>
        <div style={{display: 'flex'}}>
          <div style={{flex: '1', textAlign: 'center', marginRight: '.1em'}}>
            <Header as='h5'>
              <Header.Content>
                30
                <Header.Subheader><Icon name='phone' />/ Max. Calls</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{flex: '1', textAlign: 'center', marginLeft: '.1em'}}>
            <Header as='h5'>
              <Header.Content>
                30
                <Header.Subheader><Icon name='envelope open outline' />/ Max. Chats</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
        </div>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em .5em', margin: '0', textAlign: 'center'}}>

      </Segment></>}
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConversationWidget)