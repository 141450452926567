import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'



class OmniphoneOfflineMini extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { responsive, layout}= this.props
    return (
      <>
        <Segment attached={true} style={{padding: '1em .5em', minHeight: '6em', background: '#444444f2', border: 'none'}}>
          <div>
            <Header as='h3' inverted style={{margin: '1em .5em 0 .5em', display: 'inline'}} color={this.props.statusUA==4 ? 'red' : 'grey'}>
              <Icon name='unlink' style={{fontSize: '1.5em'}}/>
              <Header.Content>
                {this.props.statusUA==0 && 'Offline'}
                {this.props.statusUA==1 && 'Connecting'}
                {this.props.statusUA==2 && 'Connected'}
                {this.props.statusUA==3 && 'Registered'}
                {this.props.statusUA==4 && 'No Access'}
                <Header.Subheader style={{fontSize: '.7em'}}>
                  {this.props.statusUA==4 ? 'Your account is not valid' : 'Waiting for connection'}
                </Header.Subheader>
              </Header.Content>
            </Header>
          </div>
        </Segment>
      </>
    )
  }
}

export default OmniphoneOfflineMini