import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'



class OmniphoneOnlineFloating extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { responsive, layout }= this.props
    return (
      <>
        <Segment attached={'top'} style={{padding: '1em .5em', minHeight: '5em', background: '#444444e6', border: 'none', textAlign: 'center'}}>
          <Header as='h3' icon inverted style={{margin: '1em .5em 0 .5em', display: 'inline'}}>
            <Icon name='plug' style={{fontSize: '3em', textShadow: '3px 5px 3px #000'}} />
            <Header.Content>
              Registered !
              <Header.Subheader style={{fontSize: '.8em'}}>
                You 've been registered.
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
        {!responsive &&
        <Segment attached={'bottom'} style={{padding: '1em .5em 2em .5em', background: '#444', border: 'none', textAlign: 'center'}}>
          <Divider />
          <Header as='h5' style={{margin: '0'}} color='yellow'>
            Supported Call Mode
          </Header>
          <Header as='h3' icon inverted style={{margin: '.5em'}} color='red'>
            <Header.Content>
              <Label as='a' color='teal' style={{padding: '.4em .5em'}}>Predialed</Label>
              <Header.Subheader style={{paddingTop: '.5em'}}>Click to call targeted number and wait while system dialing and connecting call.</Header.Subheader>
            </Header.Content>
          </Header>
          <Header as='h3' icon inverted style={{margin: '.5em'}} color='red'>
            <Header.Content>
              <Label as='a' color='orange' style={{padding: '.4em .5em'}}>Dispatched</Label>
              <Header.Subheader style={{paddingTop: '.5em'}}>System will blast auto / predictive dialer and when customer picked a call you will be dispatched.</Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>}
      </>
    )
  }
}

export default OmniphoneOnlineFloating