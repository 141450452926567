import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form, Dropdown, Label } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, LabelInputField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { navigationOmniwidget, messageOmniwidget } from "../../actions/flow/omniwidgetAction"

//CONNECT REDUX STORE
const selector = formValueSelector('OmniwidgetMessage')

const mapStateToProps = (state, props) => {
  return {
    omniwidgetStore: state.omniwidgetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    messageOmniwidget:(objParam) => dispatch(messageOmniwidget(objParam)),
  }
}

class OmniwidgetMessage extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        contact: 'mobile'
      };
    }

    textInput(e, v) {
      // console.log(e)
      console.log(v.value)
      if (v.value[v.value.length-1]=='\n') {
        // console.log('SEND')  
      } 
    }

    itemClick(e, v) {
      console.log(v)
      this.props.dispatch(change('OmniwidgetMessage', 'message', (this.props.message || '') + (this.props.message ? '\n\n' : '') + (v.text || '') ));
    }

    onSubmit(values) {
      const { booked } = this.props
      // console.log('!!! SUBMIT: ', values)
      this.props.messageOmniwidget({clientId: booked.clientId, visitorId: booked.visitorId, ...values})
    }

    componentDidUpdate() {
      const { progress, code, text }= (this.props.omniwidgetStore.messageOmniwidget!=undefined ? this.props.omniwidgetStore.messageOmniwidget : {})
      if (progress=='1') {
        setTimeout(()=>{
          this.props.setMode('home')
        }, 500)
      }
    }

    setContact(v) {
      this.setState({
        contact: v
      })
    }

    render() {
      const { chats, handleSubmit, pristine, reset, submitting, mode } = this.props
      const { progress, code, text }= (this.props.omniwidgetStore.messageOmniwidget!=undefined ? this.props.omniwidgetStore.messageOmniwidget : {})
      const { questions }= (this.props.omniwidgetStore.navigationOmniwidget!=undefined && this.props.omniwidgetStore.navigationOmniwidget.progress=='1' ? this.props.omniwidgetStore.navigationOmniwidget.data : {})

      return(
        <div style={{margin: '-10em auto 0 auto', background: 'rgba(255, 255, 255, .95)',  borderRadius: '1.5em'}}>
          <div style={{padding: '1.5em 1em',  borderBottom: '1px solid #ccc'}}>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h4' color='red' style={{margin:'0', fontWeight: 'bold'}}>
                  <Icon name='envelope open outline' color='grey' style={{fontSize: '1.8em'}}/>
                  <Header.Content>
                    Send Message.
                    <Header.Subheader>{this.props.reason}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div>
                {/* <Button size='tiny' icon onClick={this.props.setMode.bind(this, 'home')} >
                  <Icon name='close' color='red' />
                </Button> */}
              </div>
            </div>
          </div>
          <div style={{padding: '.5em',  borderTop: '1px solid #fff'}}>
            <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
              <div style={{padding: '1em .5em'}}>
                <Form.Group widths='16' style={{marginBottom: 0}}>
                  <Field name='name' component={LabelInputField}
                  width={16}
                  label={<Label><Icon name='user circle' /></Label>}
                  validate={[required]}
                  placeholder='Name' />  
                </Form.Group>
                {this.state.contact=='mobile' && <Form.Group widths='16'>
                  <Field name='mobile' component={LabelInputField}
                  width={16}
                  label={<Label>
                    <div style={{display: 'flex',}}>
                      <div style={{flex: '1'}}>
                        <Icon style={{cursor: 'pointer'}} color='black' onClick={this.setContact.bind(this, 'mobile')}name='phone square' />
                      </div>
                      <div style={{flex: '1', textAlign: 'right'}}>
                        <Icon style={{cursor: 'pointer'}} color='grey' onClick={this.setContact.bind(this, 'email')} name='envelope' />
                      </div>
                    </div>
                    </Label>}
                  validate={[required]}
                  placeholder='Mobile number' />  
                </Form.Group>}
                {this.state.contact=='email' && <Form.Group widths='16'>
                  <Field name='email' component={LabelInputField}
                  width={16}
                  label={<Label>
                    <div style={{display: 'flex'}}>
                      <div style={{flex: '1'}}>
                        <Icon style={{cursor: 'pointer'}} color='grey' onClick={this.setContact.bind(this, 'mobile')}name='phone square' />
                      </div>
                      <div style={{flex: '1', textAlign: 'right'}}>
                        <Icon style={{cursor: 'pointer'}} color='black' onClick={this.setContact.bind(this, 'email')} name='envelope' />
                      </div>
                    </div>
                  </Label>}
                  validate={[required]}
                  placeholder='Email address' />  
                </Form.Group>}
                <div style={{display: 'flex'}}>
                  <div style={{flex: '1'}}>
                    {/* <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                      <Icon name='angle down' style={{fontSize: '1.1em'}}/> Questions template here
                    </Button> */}
                    {questions && questions.length>0 && 
                    <Dropdown text='Write or pick your questions' icon='angle down'>
                        <Dropdown.Menu>
                          <Dropdown.Menu scrolling>
                            {questions.map((option) => (
                              <Dropdown.Item key={option.value} {...option}  onClick={this.itemClick.bind(this)}/>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown.Menu>
                      </Dropdown>}
                  </div>
                  {/* <div>
                    <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                      <Icon name='file alternate outline' style={{fontSize: '1.1em'}}/>
                    </Button>
                  </div> */}
                  {/* <div>
                    <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                      <Icon name='image outline' style={{fontSize: '1.3em'}}/>
                    </Button>
                  </div> */}
                  {/* <div>
                    <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                      <Icon name='camera retro' style={{fontSize: '1.2em'}}/>
                    </Button>
                  </div> */}
                </div>
                <Form.Group widths='16'>
                  <Field name='message' component={TextAreaField} onInput={this.textInput.bind(this)}
                  rows={10}
                  width={16}
                  maxLength={150}
                  placeholder='Type message here ...' />  
                </Form.Group>
                <div>
                  {(progress==1 || progress==2)  && <DismissableMessage hide='auto' style={{marginBottom: '1em'}} mode={this.props.mode} header={'Sending message - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={code + ' ' + text}/>}
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      {/* <Button type='button' icon style={{border: 'none', background: 'none', padding: '.5em 0'}}>
                        <Icon name='image outline' style={{fontSize: '1.3em'}}/>
                      </Button> */}
                    </div>
                    <div>
                      <Button type='submit' size='medium' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: {},
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      message: selector(state, 'message'),
    }),
  )
  (reduxForm({
    form: 'OmniwidgetMessage',	// a unique identifier for this form
  })(OmniwidgetMessage)
  )
)