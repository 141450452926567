export default function bucketReducer (state = {}, action) {
  switch (action.type) {
    case 'BUCKET_RESET': {
        return({
            ...state,
            menuBucket: undefined,
            ticketBucket: undefined,
            getBucket: {},
            updateBucket: {},
            createBucket: {},
        })
    }

    case 'BUCKET_BROWSE_PENDING': {
      return({...state, browseBucket: {progress: 0}})
    }
    case 'BUCKET_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseBucket: {...api.browseBucket.response, progress: 1, payload: action.payload}})
            return({...state, browseBucket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseBucket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BUCKET_BROWSE_REJECTED': {
        return({...state, browseBucket: {progress: 2, payload: action.payload}})
    }
    
    case 'BUCKET_GET_PENDING': {
        return({...state, getBucket: {progress: 0}})
    }
    case 'BUCKET_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getBucket: {...api.getBucket.response, progress: 1, payload: action.payload}})
            return({...state, getBucket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getBucket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BUCKET_GET_REJECTED': {
        return({...state, getBucket: {progress: 2, payload: action.payload}})
    } 

    case 'BUCKET_MENU_PENDING': {
      return({...state, menuBucket: {progress: 0}})
    }
    case 'BUCKET_MENU_FULFILLED': {
      if (action.payload==undefined) {
          //return({...state, menuBucket: {...api.menuBucket.response, progress: 1, payload: action.payload}})
          return({...state, menuBucket: {progress: 2, payload: action.payload}})
      } else {
          return({...state, menuBucket: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'BUCKET_MENU_REJECTED': {
      return({...state, menuBucket: {progress: 2, payload: action.payload}})
    }

    case 'BUCKET_TICKET_PENDING': {
      return({...state, ticketBucket: {progress: 0}})
    }
    case 'BUCKET_TICKET_FULFILLED': {
      if (action.payload==undefined) {
          //return({...state, ticketBucket: {...api.ticketBucket.response, progress: 1, payload: action.payload}})
          return({...state, ticketBucket: {progress: 2, payload: action.payload}})
      } else {
          return({...state, ticketBucket: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'BUCKET_TICKET_REJECTED': {
      return({...state, ticketBucket: {progress: 2, payload: action.payload}})
    }

    case 'BUCKET_STATUS_PENDING': {
      return({...state, statusBucket: {progress: 0}})
    }
    case 'BUCKET_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusBucket: {...api.statusBucket.response, progress: 1, payload: action.payload}})
            return({...state, statusBucket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusBucket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BUCKET_STATUS_REJECTED': {
        return({...state, statusBucket: {progress: 2, payload: action.payload}})
    }

    case 'BUCKET_CREATE_PENDING': {
      return({...state, createBucket: {progress: 0}})
    }
    case 'BUCKET_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createBucket: {...api.createBucket.response, progress: 1, payload: action.payload}})
            return({...state, createBucket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createBucket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BUCKET_CREATE_REJECTED': {
        return({...state, createBucket: {progress: 2, payload: action.payload}})
    }

    case 'BUCKET_UPDATE_PENDING': {
      return({...state, updateBucket: {progress: 0}})
    }
    case 'BUCKET_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateBucket: {...api.updateBucket.response, progress: 1, payload: action.payload}})
            return({...state, updateBucket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateBucket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BUCKET_UPDATE_REJECTED': {
        return({...state, updateBucket: {progress: 2, payload: action.payload}})
    }

    case 'BUCKET_REMOVE_PENDING': {
      return({...state, removeBucket: {progress: 0}})
    }
    case 'BUCKET_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeBucket: {...api.removeBucket.response, progress: 1, payload: action.payload}})
            return({...state, removeBucket: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeBucket: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BUCKET_REMOVE_REJECTED': {
        return({...state, removeBucket: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}