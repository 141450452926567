export default function omnichannelReducer (state = {}, action) {
  switch (action.type) {
    case 'OMNICHANNEL_RESET': {
        return({
            ...state,
            // recentContact: undefined,
            getConversation: undefined,
            predialTarget: undefined,
            remarkTarget: undefined,
            smsTarget: undefined,
        })
    }

    case 'OMNICHANNEL_RECENT_PENDING': {
      return({...state, recentContact: {progress: 0}})
    }
    case 'OMNICHANNEL_RECENT_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, recentContact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, recentContact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNICHANNEL_RECENT_REJECTED': {
        return({...state, recentContact: {progress: 2, payload: action.payload}})
    }

    case 'OMNICHANNEL_GET_PENDING': {
      return({...state, getConversation: {progress: 0}})
    }
    case 'OMNICHANNEL_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getConversation: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getConversation: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNICHANNEL_GET_REJECTED': {
        return({...state, getConversation: {progress: 2, payload: action.payload}})
    }

    case 'OMNICHANNEL_REMARK_PENDING': {
      return({...state, remarkTarget: {progress: 0}})
    }
    case 'OMNICHANNEL_REMARK_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, remarkTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, remarkTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNICHANNEL_REMARK_REJECTED': {
        return({...state, remarkTarget: {progress: 2, payload: action.payload}})
    }

    case 'OMNICHANNEL_PREDIAL_PENDING': {
      return({...state, predialTarget: {progress: 0}})
    }
    case 'OMNICHANNEL_PREDIAL_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, predialTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, predialTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNICHANNEL_PREDIAL_REJECTED': {
        return({...state, predialTarget: {progress: 2, payload: action.payload}})
    } 

    case 'OMNICHANNEL_INAPP_PENDING': {
      return({...state, inappTarget: {progress: 0}})
    }
    case 'OMNICHANNEL_INAPP_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, inappTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, inappTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNICHANNEL_INAPP_REJECTED': {
        return({...state, inappTarget: {progress: 2, payload: action.payload}})
    }

    case 'OMNICHANNEL_CHAT_PENDING': {
      return({...state, chatTarget: {progress: 0}})
    }
    case 'OMNICHANNEL_CHAT_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, chatTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, chatTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNICHANNEL_CHAT_REJECTED': {
        return({...state, chatTarget: {progress: 2, payload: action.payload}})
    }
    
    case 'OMNICHANNEL_EMAIL_PENDING': {
      return({...state, emailTarget: {progress: 0}})
    }
    case 'OMNICHANNEL_EMAIL_FULFILLED': {
      if (action.payload==undefined) {
          return({...state, emailTarget: {progress: 2, payload: action.payload}})
      } else {
          return({...state, emailTarget: {...action.payload.data, progress: 1, payload: action.payload}})
      }
    }
    case 'OMNICHANNEL_EMAIL_REJECTED': {
      return({...state, emailTarget: {progress: 2, payload: action.payload}})
    }

    case 'OMNICHANNEL_SMS_PENDING': {
      return({...state, smsTarget: {progress: 0}})
    }
    case 'OMNICHANNEL_SMS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, smsTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, smsTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNICHANNEL_SMS_REJECTED': {
        return({...state, smsTarget: {progress: 2, payload: action.payload}})
    }

    case 'OMNICHANNEL_WHATSAPP_PENDING': {
      return({...state, whatsappTarget: {progress: 0}})
    }
    case 'OMNICHANNEL_WHATSAPP_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, whatsappTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, whatsappTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNICHANNEL_WHATSAPP_REJECTED': {
        return({...state, whatsappTarget: {progress: 2, payload: action.payload}})
    }

    case 'OMNICHANNEL_TWITTER_PENDING': {
      return({...state, twitterTarget: {progress: 0}})
    }
    case 'OMNICHANNEL_TWITTER_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, twitterTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, twitterTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNICHANNEL_TWITTER_REJECTED': {
        return({...state, twitterTarget: {progress: 2, payload: action.payload}})
    }

    case 'OMNICHANNEL_FACEBOOK_PENDING': {
      return({...state, facebookTarget: {progress: 0}})
    }
    case 'OMNICHANNEL_FACEBOOK_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, facebookTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, facebookTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNICHANNEL_FACEBOOK_REJECTED': {
        return({...state, facebookTarget: {progress: 2, payload: action.payload}})
    }

    case 'OMNICHANNEL_INSTAGRAM_PENDING': {
      return({...state, instagramTarget: {progress: 0}})
    }
    case 'OMNICHANNEL_INSTAGRAM_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, instagramTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, instagramTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'OMNICHANNEL_INSTAGRAM_REJECTED': {
        return({...state, instagramTarget: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}