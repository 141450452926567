import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import SocialBrowse from '../../../libraries/realm/middleware/SocialBrowse'
import SocialForm from '../../../libraries/realm/middleware/SocialForm'
import SocialSearch from '../../../libraries/realm/middleware/SocialSearch'


class SocialBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        userId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, userId: id})
    }

    render() {
      const {mode, userId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
            <SocialSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <SocialBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
              {mode==2 && <SocialForm mode={mode} userId={userId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <SocialForm mode={mode} userId={0} setMode={this.setMode.bind(this)} />}
            </div>
        </Container>
      )
    }
}

export default SocialBody
