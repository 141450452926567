import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form, Rating, Label } from 'semantic-ui-react'

class OmniwidgetBusy extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
      };
    }
    
    render() {
      const { screenWidth, screenHeight }= this.props
      
      return(
        <div style={{margin: '-10em auto 0 auto', background: 'rgba(255, 255, 255, .95)',  borderRadius: '1.5em'}}>
          <div style={{padding: '1.5em 1em', borderBottom: '1px solid #ccc'}}>
              <Header as='h4'>
                <Icon name='warning sign' />
                <Header.Content>
                  Agent Busy
                  <Header.Subheader>Agents are busy or unavailable.</Header.Subheader>
                </Header.Content>
              </Header>
          </div>
          <div style={{padding: '3em 1.5em', borderTop: '1px solid #fff'}}>
            <Header as='h5'>
              <Header.Content>
                All agents are currently busy, please do click <Label style={{padding: '.4em .5em'}} color='grey'>RETRY</Label> to get another agent.
                <Divider /> 
                 Or click <Label style={{padding: '.4em .5em'}} color='blue'>SEND</Label> to message our Agent for immediate callback.
                <Divider /> 
                We really sorry for inconvinience.
              </Header.Content>
            </Header>
          </div>

          <div style={{display: 'flex', padding: '1em'}}>
            <div style={{flex: '1'}}>
              <Button type='button' size='medium' icon color='grey' onClick={this.props.setMode.bind(this, null, null, null)}><Icon name='spy' /><span style={{borderLeft: '1px solid #fff', marginLeft: '.3em', paddingLeft: '.5em'}}>Retry</span></Button>
            </div>
            <div>
              <Button type='button' size='medium' icon color='blue' onClick={this.props.setMode.bind(this, 'message')}><Icon name='envelope outline' /><span style={{borderLeft: '1px solid #fff', marginLeft: '.3em', paddingLeft: '.5em'}}>Send</span></Button>
            </div>
          </div>
        </div>
      )
    }
}

export default OmniwidgetBusy
