import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import DismissableMessage from '../../common/DismissableMessage'
import {groupOptions}  from '../../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField } from '../../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { resetTimeline, getTimeline, updateTimeline, createTimeline } from "../../../actions/socmed/timelineAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    timelineStore: state.socmed_timelineStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetTimeline:(objParam) => dispatch(resetTimeline(objParam)),
    getTimeline:(objParam) => dispatch(getTimeline(objParam)),
    createTimeline:(objParam) => dispatch(createTimeline(objParam)),
    updateTimeline:(objParam) => dispatch(updateTimeline(objParam)),
  }
}

class TwitterPost extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.resetTimeline(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getTimeline({userId: this.props.userId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.timelineStore.updateTimeline!=undefined ? this.props.timelineStore.updateTimeline : {}) : (this.props.timelineStore.createTimeline!=undefined ? this.props.timelineStore.createTimeline : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateTimeline({...values}) : this.props.createTimeline({...values})
    
  }

  render() {
    const { showPasswd }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.timelineStore.updateTimeline!=undefined ? this.props.timelineStore.updateTimeline : {}) : (this.props.timelineStore.createTimeline!=undefined ? this.props.timelineStore.createTimeline : {}))
    
    return (
      <div>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='equal'>
              <Field name='email' component={InputField}
              // readOnly= {mode==2? true : false}
              label= 'Email'
              icon='envelope outline'
              placeholder='Email'
              validate={[required]} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='mobile' component={InputField}
              // readOnly= {mode==2? true : false}
              label= 'Mobile'
              icon='square outline'
              placeholder='Mobile'
              validate={[required, phoneNumber]} />
            </Form.Group>
          </Segment>

          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Timeline' : 'Insert Timeline') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.socmed_timelineStore.getTimeline!=undefined && state.socmed_timelineStore.getTimeline.progress==1 ? state.socmed_timelineStore.getTimeline.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: getTimeline }
  )
  (reduxForm({
    form: 'TwitterPost',	// a unique identifier for this form
  })(TwitterPost)
  )
)
