import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetReport(objParam) {
  return {
      type: 'REPORT_RESET',
      payload: objParam
  }
}

export function getReport(objParam) {
  return {
      type: 'REPORT_GET',
      payload:
        axios.post(
          urlAPI + 'report/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusReport(objParam) {
  return {
      type: 'REPORT_STATUS',
      payload:
        axios.post(
          urlAPI + 'report/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseReport(objParam) {
  return {
      type: 'REPORT_BROWSE',
      payload:
        axios.post(
          urlAPI + 'report/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createReport(objParam) {
  return {
      type: 'REPORT_CREATE',
      payload:
        axios.post(
          urlAPI + 'report/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateReport(objParam) {
  return {
      type: 'REPORT_UPDATE',
      payload:
        axios.post(
          urlAPI + 'report/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeReport(objParam) {
  return {
      type: 'REPORT_REMOVE',
      payload:
        axios.post(
          urlAPI + 'report/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
