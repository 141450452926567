import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'
import RecordingMedia from '../../libraries/agent/RecordingMedia';

// import RecordingMedia from '../../../libraries/realm/halo/RecordingMedia'


class RecordingBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, customerId: id})
    }

    render() {
      return(
        <Container fluid style={{padding: '3em 0', textAlign: 'center'}}>
            {this.props.params && <RecordingMedia url={this.props.params.url} />}
        </Container>
      )
    }
}

export default RecordingBody
