import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetSyslog(objParam) {
  return {
      type: 'SYSLOG_RESET',
      payload: objParam
  }
}

export function getSyslog(objParam) {
  return {
      type: 'SYSLOG_GET',
      payload:
        axios.post(
          urlAPI + 'syslog/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseSyslog(objParam) {
  return {
      type: 'SYSLOG_BROWSE',
      payload:
        axios.post(
          urlAPI + 'syslog/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
