import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup,
  Pagination,
} from 'semantic-ui-react'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField } from '../../../validation/componentrsui'
import { required, number, minValue, email } from '../../../validation/validation'

import { connect } from "react-redux"
import { topicKnowledge, contentKnowledge } from "../../../../actions/service/knowledgeAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    knowledgeStore: state.knowledgeStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    topicKnowledge:(objParam) => dispatch(topicKnowledge(objParam)),
    contentKnowledge:(objParam) => dispatch(contentKnowledge(objParam)),
  }
}


class KnowledgeBox extends Component {
  constructor(props) {
    super(props)
    this.state= {
      showContent: false,
    }
  }

  submitForm(values) {
    const { search }= this.state
    this.props.topicKnowledge({search: {...values, ...search}})
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.knowledgeStore.topicKnowledge!==undefined ? this.props.knowledgeStore.topicKnowledge : {})
    this.props.topicKnowledge(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }

  onChangeInput(e, v) {
    const { search }= (this.props.knowledgeStore.topicKnowledge!==undefined ? this.props.knowledgeStore.topicKnowledge : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.topicKnowledge({search: {...search, keyword: v}})
      }
    }, 500)
  }

  onChangeSelect(e, v) {
    const { search }= (this.props.knowledgeStore.topicKnowledge!==undefined ? this.props.knowledgeStore.topicKnowledge : {})
    this.props.topicKnowledge({search: {...search, knowledge: (v=='' ? null : v)}})
  }

  setContent(v, k) {
    this.setState({
      showContent: !this.state.showContent,
      topicId: v, 
      param: k,
    })
  }

  drawTopic() {
    const { data, search, paging }= (this.props.knowledgeStore.topicKnowledge!==undefined ? this.props.knowledgeStore.topicKnowledge : {})

    return(<div style={{marginLeft: '30%', textAlign: 'right', height: '100%'}}>
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', height: '100%', background: '#fafafa', borderLeft: '1px solid #dadada'}}>
        <div style={{flex: '1', padding: '0'}}>
              { data!=undefined && data.length==0 && <Header as='h5'> . . .</Header>}
              { data!==undefined ? data.map((item,i)=>{
                  return(
                    <Popup position= 'bottom right' inverted content={item.title} trigger= {<div style={{marginBottom: i==data.length ? '0' :  '1.5em'}}>
                      <Header as='h5' color='blue' onClick={this.setContent.bind(this, item.topicId, item)} style={{cursor: 'pointer'}}>
                        <Header.Content>
                          <b>{item.title.substring(0, 25)+ ' ...'}</b>
                          <Header.Subheader>{item.descript.substring(0, 35)+ ' ...'}</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </div>} />
                  )
                }) : <Icon name='ellipsis horizontal' />
              }
        </div>
        {paging!=undefined && Math.ceil(paging.count/paging.size)>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              // firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              firstItem={null}
              // lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              lastItem= {null}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    </div>)
  }

  drawContent() {
    const {param}= this.state
    const { data, search, paging }= (this.props.knowledgeStore.contentKnowledge!==undefined ? this.props.knowledgeStore.contentKnowledge : {})
    
    
    return(
      <div style={{marginTop: '0em'}}>
        <div style={{position: 'absolute', right: '.5em'}}>
          <Popup position= 'bottom right' inverted content='Copy Selected' trigger= {
            <Icon inverted size='small' circular color='blue' name='clone' style={{cursor: 'pointer', marginRight: '1em'}} />
          } />
          <Popup position= 'bottom right' inverted content='Back to Topic' trigger= {
            <Icon inverted size='small' circular color='red' name='times' style={{cursor: 'pointer'}} onClick={this.setContent.bind(this)} />
          } />
        </div>
        <div>
          {
            param && param.contents && param.contents.map(item=>{
              return (<div style={{padding: '.5em 0'}}>
                <Header as='h5'>
                  <Header.Content>
                    <b>{item.title}</b>
                    {/* <Header.Subheader>Title 1</Header.Subheader> */}
                  </Header.Content>
                </Header>
                <Divider />
                <div 
                // dangerouslySetInnerHTML={{__html: item.content.replace(/img/g, 'div').replace(/[0-9][0-9]px/g, '1.2em').replace(/style/g, '_style')}} 
                dangerouslySetInnerHTML={{__html: item.contentHtml}} 
                />
              </div>) 
            })
          }
        </div>
      </div>
    )
  }
  
  render() {
    //console.log(this.props.knowledgeStore.topicKnowledge)
    const { showContent }= this.state;
    const { screenWidth, screenHeight, error, handleSubmit, pristine, reset, submitting, mode }=this.props
    const { data, search, paging }= (this.props.knowledgeStore.topicKnowledge!==undefined ? this.props.knowledgeStore.topicKnowledge : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{height: '450px'}}>
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
          <div style={{paddingBottom: '1em'}}>
            <Header as='h5'>
              <Icon name='book' />
              <Header.Content>
                Knowledge Reference
                <Header.Subheader>Assigned or global knowledge documents.</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{flex: '1', padding: '0'}}>
              <Segment>
                <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      <Form.Group widths='16'>
                        <Form.Field width={6}/>
                        <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)} style={{borderRadius: '2em'}}
                        width={10} 
                        placeholder= 'Search topic ... <ENTER>' />
                      </Form.Group>
                    </div>
                  </div>
                </Form>
                <Divider />
                <div className='noscroll' style={{height: '300px', overflowY:'auto'}}>
                  {!showContent && this.drawTopic()}
                  {showContent && this.drawContent()}
                </div>
                {/* INDEX TICKET / CLASSIFICATION RELATED CONTENT, CONTENT DETAIL, COPY CONTENT */}
              </Segment>
          </div>
          {/* {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          } */}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.knowledgeStore.topicKnowledge!=undefined && state.knowledgeStore.topicKnowledge.progress==1 ? state.knowledgeStore.topicKnowledge.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'KnowledgeBox',	// a unique identifier for this form
  })(KnowledgeBox)
  )
)
