import React from 'react'

import WebSocketProvider, { WebSocketContext } from '../../../actions/socket/Websocket';
import Realm from "./Realm"

class RealmContainer extends React.Component {
  render() {
    return(
      <WebSocketProvider>
        <Realm />
      </WebSocketProvider>
    )
  }
} 
export default RealmContainer
