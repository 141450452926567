export default function prepaidReducer (state = {}, action) {
  switch (action.type) {
    case 'PREPAID_RESET': {
        return({
            ...state,
            getPrepaid: {},
            updatePrepaid: {},
            createPrepaid: {},
        })
    }

    case 'PREPAID_GET_PENDING': {
        return({...state, getPrepaid: {progress: 0}})
    }
    case 'PREPAID_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getPrepaid: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getPrepaid: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PREPAID_GET_REJECTED': {
        return({...state, getPrepaid: {progress: 2, payload: action.payload}})
    }

    case 'PREPAID_STATUS_PENDING': {
      return({...state, statusPrepaid: {progress: 0}})
    }
    case 'PREPAID_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusPrepaid: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusPrepaid: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PREPAID_STATUS_REJECTED': {
        return({...state, statusPrepaid: {progress: 2, payload: action.payload}})
    }

    case 'PREPAID_BROWSE_PENDING': {
      return({...state, browsePrepaid: {progress: 0}})
    }
    case 'PREPAID_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browsePrepaid: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browsePrepaid: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PREPAID_BROWSE_REJECTED': {
        return({...state, browsePrepaid: {progress: 2, payload: action.payload}})
    }

    case 'PREPAID_CREATE_PENDING': {
      return({...state, browsePrepaid: {progress: 0}})
    }
    case 'PREPAID_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createPrepaid: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createPrepaid: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PREPAID_CREATE_REJECTED': {
        return({...state, createPrepaid: {progress: 2, payload: action.payload}})
    }

    case 'PREPAID_UPDATE_PENDING': {
      return({...state, updatePrepaid: {progress: 0}})
    }
    case 'PREPAID_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updatePrepaid: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updatePrepaid: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PREPAID_UPDATE_REJECTED': {
        return({...state, updatePrepaid: {progress: 2, payload: action.payload}})
    }

    case 'PREPAID_REMOVE_PENDING': {
      return({...state, removePrepaid: {progress: 0}})
    }
    case 'PREPAID_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removePrepaid: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removePrepaid: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PREPAID_REMOVE_REJECTED': {
        return({...state, removePrepaid: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}