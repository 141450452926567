import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function browseService(objParam) {
  return {
      type: 'SERVICE_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}