export default function conferenceReducer (state = {}, action) {
  switch (action.type) {
    case 'CONFERENCE_RESET': {
        return({
            ...state,
            getConference: {},
            updateConference: {},
            createConference: {},
        })
    }

    case 'CONFERENCE_GET_PENDING': {
        return({...state, getConference: {progress: 0}})
    }
    case 'CONFERENCE_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getConference: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getConference: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONFERENCE_GET_REJECTED': {
        return({...state, getConference: {progress: 2, payload: action.payload}})
    }

    case 'CONFERENCE_STATUS_PENDING': {
      return({...state, statusConference: {progress: 0}})
    }
    case 'CONFERENCE_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusConference: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusConference: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONFERENCE_STATUS_REJECTED': {
        return({...state, statusConference: {progress: 2, payload: action.payload}})
    }

    case 'CONFERENCE_BROWSE_PENDING': {
      return({...state, browseConference: {progress: 0}})
    }
    case 'CONFERENCE_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseConference: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseConference: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONFERENCE_BROWSE_REJECTED': {
        return({...state, browseConference: {progress: 2, payload: action.payload}})
    }

    case 'CONFERENCE_CREATE_PENDING': {
      return({...state, browseConference: {progress: 0}})
    }
    case 'CONFERENCE_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createConference: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createConference: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONFERENCE_CREATE_REJECTED': {
        return({...state, createConference: {progress: 2, payload: action.payload}})
    }

    case 'CONFERENCE_UPDATE_PENDING': {
      return({...state, updateConference: {progress: 0}})
    }
    case 'CONFERENCE_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateConference: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateConference: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONFERENCE_UPDATE_REJECTED': {
        return({...state, updateConference: {progress: 2, payload: action.payload}})
    }

    case 'CONFERENCE_REMOVE_PENDING': {
      return({...state, removeConference: {progress: 0}})
    }
    case 'CONFERENCE_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeConference: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeConference: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONFERENCE_REMOVE_REJECTED': {
        return({...state, removeConference: {progress: 2, payload: action.payload}})
    }
    
    default: {
      return state
    }
  }
}