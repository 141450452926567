import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed } from 'semantic-ui-react'

import BarRechart from '../chart/BarRechart';

import { parse, format, addSeconds } from "date-fns"

import { connect } from "react-redux"
import { statEngagement } from "../../actions/halo/statisticAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    statEngagement:(objParam) => dispatch(statEngagement(objParam)),
  }
}

class EngagementRateWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      stamp: '',
      barData: null,
      // [
      //   {name: '01', car_0709: 1, car_1012: 5, car_1315: 15, car_1618: 5}, 
      //   {name: '02', car_0709: 2, car_1012: 6, car_1315: 9, car_1618: 5}, 
      //   {name: '03', car_0709: 3, car_1012: 7, car_1315: 5, car_1618: 5}, 
      //   {name: '04', car_0709: 4, car_1012: 8, car_1315: 7, car_1618: 5}, 
      // ],
      barLegend: [
        {id: 'car_0709', name: 'CAR 07-09AM'},
        {id: 'car_1012', name: 'CAR 10-12AM'},
        {id: 'car_1315', name: 'CAR 13-15PM'},
        {id: 'car_1618', name: 'CAR 16-18PM'},
      ],

      dates: null,
    };
  }

  componentDidMount() {
    const dates= [];
    var startDate = parse(format(new Date(), 'yyyy-MM-') + '01', 'yyyy-MM-dd', new Date()) //parse('20210101', 'yyyyMMdd', new Date())
    var endDate= parse(format(new Date(), 'yyyy-MM-')  + '01', 'yyyy-MM-dd', new Date()) //parse('20210101', 'yyyyMMdd', new Date())
    endDate.setMonth(endDate.getMonth()+1)
    
    while (startDate<endDate) {
      dates.push({
        dd: format(startDate, 'dd'),
        value: format(startDate, 'yyyy-MM-dd'),
        text: format(startDate, 'dd/MMM, yyyy'),
      })

      startDate.setDate(startDate.getDate() + 1)
    }

    this.setState({
      dates: dates,
    })

    this.props.statEngagement() 
  }
  
  componentDidUpdate() {
    const { data }= this.props.statisticStore.statEngagement!=undefined ? this.props.statisticStore.statEngagement: {}
    
    if (data && !this.state.barData) {
      const values= new Map(), barData= [];
      // console.log(this.state.dates)

      data && data.map(item=>{
        values.set(item.dd, item)
      })

      this.state.dates.map(item=>{
        if (values.get(item.dd)) {
          barData.push({name: item.dd, 
            car_0709: Math.round((values.get(item.dd).totEngaged_0709 || '0')/(values.get(item.dd).totAttempt_0709!=0 ? values.get(item.dd).totAttempt_0709 : 1) * 100), 
            car_1012: Math.round((values.get(item.dd).totEngaged_1012 || '0')/(values.get(item.dd).totAttempt_1012!=0 ? values.get(item.dd).totAttempt_1012 : 1) * 100), 
            car_1315: Math.round((values.get(item.dd).totEngaged_1315 || '0')/(values.get(item.dd).totAttempt_1315!=0 ? values.get(item.dd).totAttempt_1315 : 1) * 100), 
            car_1618: Math.round((values.get(item.dd).totEngaged_1618 || '0')/(values.get(item.dd).totAttempt_1618!=0 ? values.get(item.dd).totAttempt_1618 : 1) * 100),
          })
        } else {
          barData.push({name: item.dd, car_0709: 0, car_1012: 0, car_1315: 0, car_1618: 0})
        }
      })
      this.setState({
        barData: barData,
      })
    }
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const { barData, barLegend}= this.state
    const { data, search, paging }= (this.props.statisticStore.statEngagement!==undefined ? this.props.statisticStore.statEngagement : {})
    
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart bar' style={{fontSize: '1em'}} />
          <Header.Content>
            Engagement/Answering Rate
            <Header.Subheader>Call answering rate trends for certain session/hours.</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em'}}>
        {data!=undefined && <>
          <BarRechart mode='tiny' height={450} yAxis={false} dashed={true} label={true} data={barData} legend={barLegend} />
        </>}
      </Segment>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EngagementRateWidget)