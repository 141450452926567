import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'


import WebSocketProvider, { WebSocketContext } from '../../../actions/socket/Websocket';

import RealmHeader from "./RealmHeader"
import RealmFooter from "./RealmFooter"
import RealmBody from "./RealmBody"

class Realm extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        screenHeight: 800,
        screenWidth: 1024,
        mode: 'dashboard',
        module: 'outbound', // inbound, outbound, home, api

        openDrawer: true,
      }
      this.updateDimension = this.updateDimension.bind(this)
    }
  
    componentDidMount() {
      this.updateDimension()
      window.addEventListener('resize', this.updateDimension)
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimension);
      this.context.socket && this.context.socket.close();
    }
    
    updateDimension() {
      this.setState({ ...this.state, screenWidth: window.innerWidth, screenHeight: window.innerHeight })
    }

    openModule(v) {
      if (v=='outbound') {
        this.setState({
          openDrawer: false,
          module: v,
          mode: this.state.module!='outbound' ? 'dashboard' : this.state.mode
        })
      } else if (v=='inbound') {
        this.setState({
          openDrawer: false,
          module: v,
          mode: this.state.module!='inbound' ? 'dashboard' : this.state.mode
        })
      } else if (v=='inapp') {
        this.setState({
          openDrawer: false,
          module: v,
          mode: this.state.module!='inapp' ? 'dashboard' : this.state.mode
        })
      } else if (v=='office') {
        this.setState({
          openDrawer: false,
          module: v,
          mode: this.state.module!='office' ? 'dashboard' : this.state.mode
        })
      } else if (v=='middleware') {
        this.setState({
          openDrawer: false,
          module: v,
          mode: this.state.module!='middleware' ? 'dashboard' : this.state.mode
        })
      } else if (v=='home') {
        this.setState({
          openDrawer: false,
          module: v,
          mode: this.state.module!='home' ? 'dashboard' : this.state.mode
        })
      } else {
        this.setState({
          openDrawer: false,
          module: v,
          // mode: ''
        })
      }
    }
  
    openModal(value, params) {
      // console.log(params)
      this.setState({
        modal: value,
        params: params,
      })
    }

    closeModal(params) {
      // console.log(params)
      this.setState({
        modal: null,
        params: params,
      })
    }

    openWindow(mode) {
      this.setState({
        mode: mode,
        // openDrawer: true
      })
    }

    setModule(module) {
      this.setState({
        module: module,
      })
    }
    
    openDrawer() {
      const { openDrawer }= this.state
      this.setState({
        openDrawer: !openDrawer
      })
    }  

    render() {
      const {screenHeight, screenWidth, mode, module, openDrawer, modal, params}= this.state
      
      return(
        <Container fluid>
          <RealmHeader screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.openModule.bind(this)} openWindow={this.openWindow.bind(this)} openModal={this.openModal.bind(this)} mode={mode} module={module} openDrawer={this.openDrawer.bind(this)} mini={openDrawer} closeModal={this.closeModal.bind(this)} modal={modal}  params={params} />
          <RealmBody screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.openModule.bind(this)} openWindow={this.openWindow.bind(this)} mode={mode} mini={openDrawer} module={module} setModule={this.setModule.bind(this)} openModal={this.openModal.bind(this)} closeModal={this.closeModal.bind(this)} modal={modal}  params={params} />
          <RealmFooter screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.openWindow.bind(this)}/>
        </Container>
      )
    }
} 

Realm.contextType= WebSocketContext;
export default Realm
