import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'

import { connect } from "react-redux"
import { checkToken } from "../../../actions/auth/authAction"
import MenuLeftDashboard from '../../../libraries/realm/halo/MenuLeftDashboard';

import DashboardBody from './DashboardBody'

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
  }
}

class RealmDashboardBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      mode: 'dashboard',
    }
  }

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  render() {
    const { mode, module, screenWidth, screenHeight, mini, modal }= this.props
    
    return (
      <div style={{display: 'flex'}}>
        {!mini &&
        <div style={{background: '#ccc', minHeight: screenHeight-300, borderRight: '1px solid #fff'}}>
          <MenuLeftDashboard openModule={this.props.openModule} openWindow={this.props.openWindow} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} mini={mini} setModule={this.props.setModule}/>
        </div>}
        <div style={{flex: '1', marginLeft: mini ? '2px' : '80px', paddingTop: '1em'}}>
          {mode==='dashboard' && <DashboardBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RealmDashboardBody)