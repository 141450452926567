import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format } from "date-fns"

import { connect } from "react-redux"
import { browseMiddleware } from "../../../actions/middleware/middlewareAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    middlewareStore: state.middlewareStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseMiddleware:(objParam) => dispatch(browseMiddleware(objParam)),
  }
};


class APIInventoryBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.middlewareStore.browseMiddleware!==undefined ? this.props.middlewareStore.browseMiddleware : {})
    this.props.browseMiddleware(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.middlewareStore.browseMiddleware!==undefined ? this.props.middlewareStore.browseMiddleware : {})
    this.props.browseMiddleware({search: {...search}, paging: paging})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.middlewareStore.browseMiddleware!==undefined ? this.props.middlewareStore.browseMiddleware : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
      
        { data!==undefined ? data.map((item,key)=>{
          return(<div key={key}>
            <Segment textAlign='center'>
              <Card.Group>
                <Card style={{width: '14em', borderRadius: '1em'}}>
                  <Card.Content>
                    <Card.Header style={{fontWeight: 'bold', fontSize: '1.5em'}}>Campaign</Card.Header>
                    <Card.Meta>API Inventory</Card.Meta>
                  </Card.Content>
                  <Card.Content>
                    <Card.Description>
                      Manage campaign through direct automation from app to app interfacing.
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Icon color={item.isCampaign=='1' ? 'red' :  null} name={(item.isCampaign=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0', fontSize: '1.5em', background: '#eaeaea'}} circular />
                  </Card.Content>
                </Card>
                <Card style={{width: '14em', borderRadius: '1em'}}>
                  <Card.Content>
                    <Card.Header style={{fontWeight: 'bold', fontSize: '1.5em'}}>Target/Contacts</Card.Header>
                    <Card.Meta>API Inventory</Card.Meta>
                  </Card.Content>
                  <Card.Content>
                    <Card.Description>
                      Manage contacts target from API for any related campaigns.
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Icon color={item.isTarget=='1' ? 'red' :  null} name={(item.isTarget=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0', fontSize: '1.5em', background: '#eaeaea'}} circular />
                  </Card.Content>
                </Card>
                <Card style={{width: '14em', borderRadius: '1em'}}>
                  <Card.Content>
                    <Card.Header style={{fontWeight: 'bold', fontSize: '1.5em'}}>Agent</Card.Header>
                    <Card.Meta>API Inventory</Card.Meta>
                  </Card.Content>
                  <Card.Content>
                    <Card.Description>
                      Control how your Agent interaction through API from your backend platform.
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Icon color={item.isAgent=='1' ? 'red' :  null} name={(item.isAgent=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0', fontSize: '1.5em', background: '#eaeaea'}} circular />
                  </Card.Content>
                </Card>
                <Card style={{width: '14em', borderRadius: '1em'}}>
                  <Card.Content>
                    <Card.Header style={{fontWeight: 'bold', fontSize: '1.5em'}}>Widget</Card.Header>
                    <Card.Meta>API Inventory</Card.Meta>
                  </Card.Content>
                  <Card.Content>
                    <Card.Description>
                      Widget management for Web Phone, Desk Phone and Mobile App directly from API.
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Icon color={item.isWidget=='1' ? 'red' :  null} name={(item.isWidget=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0', fontSize: '1.5em', background: '#eaeaea'}} circular />
                  </Card.Content>
                </Card>
                <Card style={{width: '14em', borderRadius: '1em'}}>
                  <Card.Content>
                    <Card.Header style={{fontWeight: 'bold', fontSize: '1.5em'}}>Webhook</Card.Header>
                    <Card.Meta>API Inventory</Card.Meta>
                  </Card.Content>
                  <Card.Content>
                    <Card.Description>
                      Receive real time status for certain activity on communication platform.
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Icon color={item.isWebhook=='1' ? 'red' :  null} name={(item.isWebhook=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0', fontSize: '1.5em', background: '#eaeaea'}} circular />
                  </Card.Content>
                </Card>
                <Card style={{width: '14em', borderRadius: '1em'}}>
                  <Card.Content>
                    <Card.Header style={{fontWeight: 'bold', fontSize: '1.5em'}}>Reporting</Card.Header>
                    <Card.Meta>API Inventory</Card.Meta>
                  </Card.Content>
                  <Card.Content>
                    <Card.Description>
                      Generate and download transaction report directly from API.
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Icon color={item.isReport=='1' ? 'red' :  null} name={(item.isReport=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0', fontSize: '1.5em', background: '#eaeaea'}} circular />
                  </Card.Content>
                </Card>
                <Card style={{width: '14em', borderRadius: '1em'}}>
                  <Card.Content>
                    <Card.Header style={{fontWeight: 'bold', fontSize: '1.5em'}}>Interaction</Card.Header>
                    <Card.Meta>API Inventory</Card.Meta>
                  </Card.Content>
                  <Card.Content>
                    <Card.Description>
                      Control how interaction happens between Agent and target Contacts from API.
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Icon color={item.isInteraction=='1' ? 'red' :  null} name={(item.isInteraction=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0', fontSize: '1.5em', background: '#eaeaea'}} circular />
                  </Card.Content>
                </Card>
                <Card style={{width: '14em', borderRadius: '1em'}}>
                  <Card.Content>
                    <Card.Header style={{fontWeight: 'bold', fontSize: '1.5em'}}>Communication</Card.Header>
                    <Card.Meta>API Inventory</Card.Meta>
                  </Card.Content>
                  <Card.Content>
                    <Card.Description>
                      Manage push communication to your target Contacts through API.
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Icon color={item.isCommunication=='1' ? 'red' :  null} name={(item.isCommunication=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0', fontSize: '1.5em', background: '#eaeaea'}} circular />
                  </Card.Content>
                </Card>
              </Card.Group>

              {/* <Label.Group>
                <Label size='huge' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isCampaign=='1' ? 'red' :  null} name={(item.isCampaign=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Campaign</Label> 
                <Label size='huge' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isTarget=='1' ? 'red' :  null} name={(item.isTarget=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Target</Label>
                <Label size='huge' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isAgent=='1' ? 'red' :  null} name={(item.isAgent=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Agent</Label>
                <Label size='huge' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isWidget=='1' ? 'red' :  null} name={(item.isWidget=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Widget</Label>
                <Label size='huge' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isWebhook=='1' ? 'red' :  null} name={(item.isWebhook=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Webhook</Label>
                <Label size='huge' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isReport=='1' ? 'red' :  null} name={(item.isReport=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Reporting</Label>
                <Label size='huge' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isInteraction=='1' ? 'red' :  null} name={(item.isInteraction=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Interaction</Label>
                <Label size='huge' style={{minWidth: '7.5em', padding: '.5em .7em', margin: '.5em', borderRadius: '1em'}}><Icon color={item.isCommunication=='1' ? 'red' :  null} name={(item.isCommunication=='1' && 'checkmark') || 'close'} style={{margin: '0 .5em 0 0'}} />Comm</Label>
              </Label.Group> */}
            </Segment>
            <Segment textAlign='right' style={{marginTop: '0'}}>
              <Button type='button' size='small' content='Update' color='red' onClick={this.props.setMode.bind(this, '2',item.settingId, item)} />
            </Segment>
          </div>)
        }) : <Segment><Icon name='circle notch' loading />loading ...</Segment>}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(APIInventoryBrowse)
