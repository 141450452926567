import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label, Popup } from 'semantic-ui-react'

import InstagramMessage from '../../../libraries/realm/socmed/InstagramMessage';
import InstagramContent from '../../../libraries/realm/socmed/InstagramContent';
import InstagramInteraction from '../../../libraries/realm/socmed/InstagramInteraction';
import InstagramNetwork from '../../../libraries/realm/socmed/InstagramNetwork';
import InstagramProfile from '../../../libraries/realm/socmed/InstagramProfile';

class InstagramBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      return(
        <div style={{display: 'flex', padding: '0 1em', minHeight: screenHeight}}>
          <div style={{flex: 1, background: '#f5f5f5', padding: '1em 1em'}}>
            <Header as='h5'>
              <Header.Content>
                Instagram Bussiness User API
                <Header.Subheader>Instagram conversation, content and interaction business user.</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            
            {mode==0 && <div style={{height: screenHeight}}>
              <div style={{textAlign: 'center', paddingTop: screenHeight/2}}>
                <Icon name='instagram' style={{fontSize: '32em', color: '#fff'}} />
              </div>
              <div style={{height: screenHeight, position: 'relative', marginTop: -1 * screenHeight + 25}}>
                <InstagramProfile screenHeight={screenHeight} />
              </div>
            </div>}
            {mode==1 && <InstagramMessage screenHeight={screenHeight} />}
            {mode==2 && <InstagramContent screenHeight={screenHeight} />}
            {mode==3 && <InstagramInteraction screenHeight={screenHeight} />}
            {mode==4 && <InstagramNetwork screenHeight={screenHeight} />}
          </div>
          <div style={{paddingLeft: '0', background: '#f0f0f0', padding: '1em 1em'}}>
            <Popup content='Instagram Conversation' position='left center' inverted trigger={<Label color={mode==1 ? 'orange' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 1)}><Icon name='comments' size='large' style={{margin: '0'}} /></Label>} />
            <Divider />
            <Popup content='Instagram Contents' position='left center' inverted trigger={<Label color={mode==2 ? 'orange' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 2)}><Icon name='images outline' size='large' style={{margin: '0'}} /></Label>} />
            <Divider />
            <Popup content='Instagram Interaction' position='left center' inverted trigger={<Label color={mode==3 ? 'orange' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 3)}><Icon name='heartbeat' size='large' style={{margin: '0'}} /></Label>} />
            <Divider />
            <Popup content='Instagram Networks' position='left center' inverted trigger={<Label color={mode==4 ? 'orange' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 4)}><Icon name='users' size='large' style={{margin: '0'}} /></Label>} />
          </div>
        </div>
      )
    }
}

export default InstagramBody
