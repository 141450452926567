import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import { format, parse } from "date-fns"
import { socialStatus } from "../../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { getConversation } from "../../../actions/flow/omnichannelAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omnichannelStore: state.omnichannelStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConversation:(objParam) => dispatch(getConversation(objParam)),
  }
}
class InteractionSocial extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remark: false,
      expand: false,
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.omnichannelStore.getConversation!==undefined ? this.props.omnichannelStore.getConversation : {})
    this.props.getConversation(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }

  onChangeStatus(id, status, e) {
    // console.log(id, status, e)
    this.props.statusSocial({socialId:id, isEnabled: status})
  }
  setExpand() {
    const { expand }= this.state
    this.setState({
      expand: !expand
    })
  }
  setRemark() {
    const { remark }= this.state
    this.setState({
      remark: !remark
    })
  }


  expandConversation(id) {
    // console.log(id)
    this.setState({
      socialId: (this.state.socialId==id ? 0 : id),
    })
  }

  render() {
    //console.log(this.props.omnichannelStore.getConversation)
    const { expand, remark }= this.state
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.omnichannelStore.getConversation!==undefined ? this.props.omnichannelStore.getConversation : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    if (data && data.socmeds && data.socmeds.length>0 || true) {
      return (
        <div style={{padding: '1em .5em'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' color='blue'>
                {/* <Icon name='share alternate' style={{fontSize: '1.7em'}}/> */}
                <Header.Content>
                  Social Media
                  <Header.Subheader>
                    Social media interaction Twitter, Instagram, Facebook.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div>
              {!this.props.limit && <Button disabled={data && data.socmeds && data.socmeds.length<3} onClick={this.props.openModal.bind(this, 'historical_social')} size='small' icon><Icon name='ellipsis vertical' /></Button>}
            </div>
          </div>
          { data && data.socmeds && data.socmeds.length==0 && <div style={{padding: '.5em 2.5em .5em 0'}}>
            <Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label>
          </div>}
          { data && data.socmeds && data.socmeds.length>0 && <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width={8}>
                  <Header as='h5' color='blue'>CONTACT</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={8}>
                  <Header as='h5' color='blue'>REMARK</Header>
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
            { data.socmeds.map((item,i)=>{
              return(
                <Table.Row>
                  <Table.Cell>{i+1}</Table.Cell>
                  <Table.Cell>
                    <Icon name={item.flow==0 ? 'chevron circle right' : 'chevron circle left'} color={(item.flow!=1 && 'black') || (item.clickStamp && 'orange') || (item.readStamp && 'blue') || (item.deliverStamp && 'teal') || (item.sentStamp && 'grey')} style={{fontSize: '1em'}} />
                    <b>{item.channelType}</b> at {item.sentStamp!=null && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss, dd/MMM yyyy')}
                  </Table.Cell>
                  <Table.Cell>
                    {item.remark || 'No remark'}
                  </Table.Cell>
                  <Table.Cell>
                    {item.channelType=='Twitter' && 
                      <Popup size='small' style={{background: item.content ? null : '#fafafa', padding: item.content ? '1em' : '0 .5em', borderRadius: '.5em', minHeight: '3em', minWidth: '20em'}} content={<>
                        {item.content || (item.conversations && 
                          <div id='wd_chat' style={{padding: '0em'}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                              <div className='chat' style={{maxHeight: screenHeight, overflowY: 'scroll'}}>
                                <div>
                                  {item.conversations.map((item, idx)=>{
                                    return(
                                      <div key={idx} style={{margin: '.5em 0', textAlign: item.sender=='agent' ? 'right' : 'left'}}>
                                        <div style={{display: 'inline-block', background: item.sender=='agent' ? '#d7f5fe' : '#ffebe9', padding: '.5em 1em .2em 1em', margin: item.sender=='agent' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='agent' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                                          <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                            <Header.Content>
                                              <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                              {item.message}
                                              <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>
                                            </Header.Content>
                                          </Header>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>} 
                      position='left center' inverted={item.content ? true : false}
                      trigger={
                        <Icon 
                        name={item.channelType.toLowerCase()}
                        color='blue'
                        style={{fontSize: '1em', cursor: 'pointer'}}/>
                      } />
                    }
                    {item.channelType=='Facebook' && 
                      <Popup size='small' style={{background: item.content ? null : '#fafafa', padding: item.content ? '1em' : '0 .5em', borderRadius: '.5em', minHeight: '3em', minWidth: '20em'}} content={<>
                        {item.conversations && 
                          <div id='wd_chat' style={{padding: '0em'}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                              <div className='chat' style={{maxHeight: screenHeight, overflowY: 'scroll'}}>
                                <div>
                                  {item.conversations.map((item, idx)=>{
                                    return(
                                      <div key={idx} style={{margin: '.5em 0', textAlign: item.sender=='agent' ? 'right' : 'left'}}>
                                        <div style={{display: 'inline-block', background: item.sender=='agent' ? '#d7f5fe' : '#ffebe9', padding: '.5em 1em .2em 1em', margin: item.sender=='agent' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='agent' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                                          <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                            <Header.Content>
                                              <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                              {item.message}
                                              <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>
                                            </Header.Content>
                                          </Header>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </>} 
                      position='left center' inverted={item.content ? true : false}
                      trigger={
                        <Icon 
                        name={item.channelType.toLowerCase()}
                        color='blue'
                        inverted circular
                        style={{fontSize: '1em', cursor: 'pointer'}}/>
                      } />
                    }
                    {item.channelType=='Instagram' && 
                      <Popup size='small' style={{background: item.content ? null : '#fafafa', padding: item.content ? '1em' : '0 .5em', borderRadius: '.5em', minHeight: '3em', minWidth: '20em'}} content={<>
                        {item.content || (item.conversations && 
                          <div id='wd_chat' style={{padding: '0em'}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                              <div className='chat' style={{maxHeight: screenHeight, overflowY: 'scroll'}}>
                                <div>
                                  {item.conversations.map((item, idx)=>{
                                    return(
                                      <div key={idx} style={{margin: '.5em 0', textAlign: item.sender=='agent' ? 'right' : 'left'}}>
                                        <div style={{display: 'inline-block', background: item.sender=='agent' ? '#d7f5fe' : '#ffebe9', padding: '.5em 1em .2em 1em', margin: item.sender=='agent' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='agent' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                                          <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                            <Header.Content>
                                              <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                              {item.message}
                                              <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>
                                            </Header.Content>
                                          </Header>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>} 
                      position='left center' inverted={item.content ? true : false}
                      trigger={
                        <Icon 
                        name={item.channelType.toLowerCase()}
                        color='blue'
                        style={{fontSize: '1em', cursor: 'pointer'}}/>
                      } />
                    }
                  </Table.Cell>
                </Table.Row>
              )
            })}
            </Table.Body>
          </Table>}
          { false && data && data.socmeds && data.socmeds.length>0 && <Card.Group itemsPerRow={this.props.limit ? 4 : 3} style={{padding: '1em 0'}}>
            { data && data.socmeds && data.socmeds.map((item, i)=>{
                if (i<(this.props.limit || 3)) {
                  return(
                    <Card key={i} style={{padding: '.3em .5em', background: '#e2f6ec'}}>
                      <Card.Content style={{padding: '.5em .5em'}}>
                        <Card.Meta>
                          <Icon name={item.flow==0 ? 'chevron circle right' : 'chevron circle left'} color={(item.flow!=1 && 'black') || (item.clickStamp && 'orange') || (item.readStamp && 'blue') || (item.deliverStamp && 'teal') || (item.sentStamp && 'grey')} style={{fontSize: '1em'}} />
                          <b>{item.channelType}</b> at
                          <br />{item.sentStamp!=null && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss, dd/MMM')}
                        </Card.Meta>
                        {/* <Card.Meta>
                          <span>{item.channelType}</span>
                        </Card.Meta>
                        <Card.Header>{item.sentStamp!=null && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss, dd/MMM')}</Card.Header> */}
                      </Card.Content>
                      {item.remark && <Card.Content extra>
                        {item.remark}
                      </Card.Content>}
                      <Card.Content extra>
                        <div style={{display: 'flex'}}>
                          <div style={{flex: '1', paddingTop: '.5em'}}>
                            <b style={{fontSize: '1em'}}>{socialStatus[item.status]}</b>
                          </div>
                          <div>
                            {item.channelType=='Twitter' && 
                              <Popup size='small' style={{background: item.content ? null : '#fafafa', padding: item.content ? '1em' : '0 .5em', borderRadius: '.5em', minHeight: '3em', minWidth: '20em'}} content={<>
                                {item.content || (item.conversations && 
                                  <div id='wd_chat' style={{padding: '0em'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                      <div className='chat' style={{maxHeight: screenHeight, overflowY: 'scroll'}}>
                                        <div>
                                          {item.conversations.map((item, idx)=>{
                                            return(
                                              <div key={idx} style={{margin: '.5em 0', textAlign: item.sender=='agent' ? 'right' : 'left'}}>
                                                <div style={{display: 'inline-block', background: item.sender=='agent' ? '#d7f5fe' : '#ffebe9', padding: '.5em 1em .2em 1em', margin: item.sender=='agent' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='agent' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                                                  <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                                    <Header.Content>
                                                      <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                                      {item.message}
                                                      <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>
                                                    </Header.Content>
                                                  </Header>
                                                </div>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>} 
                              position='left center' inverted={item.content ? true : false}
                              trigger={
                                <Icon 
                                name={item.channelType.toLowerCase()}
                                color='blue'
                                style={{fontSize: '1em', cursor: 'pointer'}}/>
                              } />
                            }
                            {item.channelType=='Facebook' && 
                              <Popup size='small' style={{background: item.content ? null : '#fafafa', padding: item.content ? '1em' : '0 .5em', borderRadius: '.5em', minHeight: '3em', minWidth: '20em'}} content={<>
                                {item.conversations && 
                                  <div id='wd_chat' style={{padding: '0em'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                      <div className='chat' style={{maxHeight: screenHeight, overflowY: 'scroll'}}>
                                        <div>
                                          {item.conversations.map((item, idx)=>{
                                            return(
                                              <div key={idx} style={{margin: '.5em 0', textAlign: item.sender=='agent' ? 'right' : 'left'}}>
                                                <div style={{display: 'inline-block', background: item.sender=='agent' ? '#d7f5fe' : '#ffebe9', padding: '.5em 1em .2em 1em', margin: item.sender=='agent' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='agent' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                                                  <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                                    <Header.Content>
                                                      <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                                      {item.message}
                                                      <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>
                                                    </Header.Content>
                                                  </Header>
                                                </div>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </>} 
                              position='left center' inverted={item.content ? true : false}
                              trigger={
                                <Icon 
                                name={item.channelType.toLowerCase()}
                                color='blue'
                                inverted circular
                                style={{fontSize: '1em', cursor: 'pointer'}}/>
                              } />
                            }
                            {item.channelType=='Instagram' && 
                              <Popup size='small' style={{background: item.content ? null : '#fafafa', padding: item.content ? '1em' : '0 .5em', borderRadius: '.5em', minHeight: '3em', minWidth: '20em'}} content={<>
                                {item.content || (item.conversations && 
                                  <div id='wd_chat' style={{padding: '0em'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                      <div className='chat' style={{maxHeight: screenHeight, overflowY: 'scroll'}}>
                                        <div>
                                          {item.conversations.map((item, idx)=>{
                                            return(
                                              <div key={idx} style={{margin: '.5em 0', textAlign: item.sender=='agent' ? 'right' : 'left'}}>
                                                <div style={{display: 'inline-block', background: item.sender=='agent' ? '#d7f5fe' : '#ffebe9', padding: '.5em 1em .2em 1em', margin: item.sender=='agent' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='agent' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                                                  <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                                    <Header.Content>
                                                      <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                                      {item.message}
                                                      <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>
                                                    </Header.Content>
                                                  </Header>
                                                </div>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>} 
                              position='left center' inverted={item.content ? true : false}
                              trigger={
                                <Icon 
                                name={item.channelType.toLowerCase()}
                                color='blue'
                                style={{fontSize: '1em', cursor: 'pointer'}}/>
                              } />
                            }
                          </div>
                        </div>
                      </Card.Content>
                    </Card>
                  )
                }
              })
            }
          </Card.Group>}
        </div>
      )
    } else {
      return<></>
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InteractionSocial)
