export default function contractReducer (state = {}, action) {
  switch (action.type) {
    case 'CONTRACT_RESET': {
        return({
            ...state,
            getContract: {},
            updateContract: {},
            createContract: {},
        })
    }

    case 'CONTRACT_BROWSE_PENDING': {
      return({...state, browseContract: {progress: 0}})
    }
    case 'CONTRACT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseContract: {...api.browseContract.response, progress: 1, payload: action.payload}})
            return({...state, browseContract: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseContract: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONTRACT_BROWSE_REJECTED': {
        return({...state, browseContract: {progress: 2, payload: action.payload}})
    }
    
    case 'CONTRACT_GET_PENDING': {
        return({...state, getContract: {progress: 0}})
    }
    case 'CONTRACT_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getContract: {...api.getContract.response, progress: 1, payload: action.payload}})
            return({...state, getContract: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getContract: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONTRACT_GET_REJECTED': {
        return({...state, getContract: {progress: 2, payload: action.payload}})
    }

    case 'CONTRACT_STATUS_PENDING': {
      return({...state, statusContract: {progress: 0}})
    }
    case 'CONTRACT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusContract: {...api.statusContract.response, progress: 1, payload: action.payload}})
            return({...state, statusContract: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusContract: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONTRACT_STATUS_REJECTED': {
        return({...state, statusContract: {progress: 2, payload: action.payload}})
    }

    case 'CONTRACT_CREATE_PENDING': {
      return({...state, createContract: {progress: 0}})
    }
    case 'CONTRACT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createContract: {...api.createContract.response, progress: 1, payload: action.payload}})
            return({...state, createContract: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createContract: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONTRACT_CREATE_REJECTED': {
        return({...state, createContract: {progress: 2, payload: action.payload}})
    }

    case 'CONTRACT_UPDATE_PENDING': {
      return({...state, updateContract: {progress: 0}})
    }
    case 'CONTRACT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateContract: {...api.updateContract.response, progress: 1, payload: action.payload}})
            return({...state, updateContract: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateContract: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONTRACT_UPDATE_REJECTED': {
        return({...state, updateContract: {progress: 2, payload: action.payload}})
    }

    case 'CONTRACT_REMOVE_PENDING': {
      return({...state, removeContract: {progress: 0}})
    }
    case 'CONTRACT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeContract: {...api.removeContract.response, progress: 1, payload: action.payload}})
            return({...state, removeContract: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeContract: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONTRACT_REMOVE_REJECTED': {
        return({...state, removeContract: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}