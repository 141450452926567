import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'

import InteractionVoice from '../../libraries/agent/flow/InteractionVoice';
import InteractionMessaging from '../../libraries/agent/flow/InteractionMessaging';
import InteractionSocial from '../../libraries/agent/flow/InteractionSocial';


class InteractionHistoricalBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        params: null,
      };
    }

    setMode(mode, params) {
      this.setState({mode: mode, params: params})
    }

    render() {
      const {mode, params}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid style={{padding: '1em'}}>
          <Segment style={{padding: '0'}}>
              <div style={{background: '#f5f5f5', minHeight: '100%', padding: '0 .5em'}}>
                {this.props.channel=='voice' && <InteractionVoice limit={20} params={params} openModal={this.props.openModal.bind(this)} />}
                {this.props.channel=='messaging' && <InteractionMessaging limit={20} params={params} openModal={this.props.openModal.bind(this)} />}
                {this.props.channel=='social' && <InteractionSocial limit={20} params={params} openModal={this.props.openModal.bind(this)} />}
              </div>
          </Segment>
        </Container>
      )
    }
}

export default InteractionHistoricalBody
