import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format } from "date-fns"

import { connect } from "react-redux"
import { browseConference } from "../../../actions/talk/conferenceAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    conferenceStore: state.conferenceStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseConference:(objParam) => dispatch(browseConference(objParam)),
  }
};


class LinkcallBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.conferenceStore.browseConference!==undefined ? this.props.conferenceStore.browseConference : {})
    this.props.browseConference(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.conferenceStore.browseConference!==undefined ? this.props.conferenceStore.browseConference : {})
    this.props.browseConference({search: {eventDate: format(new Date(), 'yyyy-MM-dd'), ...search}, paging: paging})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.conferenceStore.browseConference!==undefined ? this.props.conferenceStore.browseConference : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1'}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width='5'>
                  <Header as='h5' color='blue'>
                    NAME
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>
                    CONF. TYPE
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>
                    SCHEDULE
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>
                    CAPACITY
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      {/* <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.conferenceId)}/>
                      </Table.Cell> */}
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.name}
                            <Header.Subheader>{item.baseUrl}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.audience}
                            <Header.Subheader>{item.joinType} / {item.method}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                              {item.startDate!=null ? format(parse(item.startDate, 'yyyy-MM-dd', new Date()), 'dd/MMM') : ''} - 
                              {item.endDate!=null ? format(parse(item.endDate, 'yyyy-MM-dd', new Date()), 'dd/MMM, yyyy') : ''}
                              <Header.Subheader>{item.startTime} - {item.endTime}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell textAlign='right'>
                        <Header as='h5'>
                          <Header.Content>
                            {numeral(item.totAccessed || '0').format('0,0')} / <b>{item.audience=='Unlimited'? '~' : numeral(item.totCapacity || '0').format('0,0')}</b>
                            <Header.Subheader>Usage of tot. capacity</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Button icon labelPosition='left' onClick={this.props.setMode.bind(this, '2', item.conferenceId, item)}><Icon color='blue' name={item.channels.indexOf('Video')>0 ? 'video' : 'microphone'} /> Call</Button>
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        
        {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
               
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkcallBrowse)
