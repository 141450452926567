import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form } from 'semantic-ui-react'

import PhonewidgetDial from './PhonewidgetDial'
import PhonewidgetVideo from './PhonewidgetVideo'

import { connect } from "react-redux"
import { dialOmniwidget } from "../../actions/flow/omniwidgetAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniwidgetStore: state.omniwidgetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dialOmniwidget:(objParam) => dispatch(dialOmniwidget(objParam)),
  }
}

class PhonewidgetCall extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isCalled: false,
        video: null,
      };
    }

    callDial(terminating) {
      this.props.callDial(terminating);
    }

    callHangup() {
      this.props.callHangup()
    }

    componentDidMount() {
      // setTimeout(()=>{
      //   this.props.dtmfSend('12#')
      // }, 2000)
    }
    
    componentDidUpdate(prevProps) {
      const {booked}= this.props
      // agentExten: "7000000005"
      // agentId: "4"
      // clientId: "2"
      // sipAccount: "7012345215"
      // sipHost: "host"
      // sipPasswd: "MTQ3MTgyODU4Nzc5MzE0MTAwMDA="
      // sipPort: "port"
      // visitorId: "215"
      // voiceParking: "4175"      

      // console.log('DID UPDATE', booked)
      
      if (!this.state.isCalled && this.props.phoneState=='online' && booked) {
        this.setState({
          isCalled: true
        })
        // this.callDial('41756000000000001')
        // console.log('CALL DIAL OMNIWIDGET-CALL', booked)
        // const parking= booked.voiceParking +'6'+ booked.clientId.toString().padStart(6,"0") + booked.visitorId.toString().padStart(6,"0"); //clientId(6)visitorId(6)agentId(6)level(09) 2 digits to defined level position and current menu
        // const parking= booked.voiceParking +'6'+ booked.clientId.toString().padStart(5,"0") + booked.visitorId.toString().padStart(5,"0") + booked.level.toString().padStart(2,"0"); //clientId(6)visitorId(6)agentId(6)level(09) 2 digits to defined level position and current menu
        // this.callDial(parking);
        this.props.dialOmniwidget({
          userId: booked ? booked.agentId : null, // for booked agent allocation
          clientId: booked ? booked.clientId : null,
          visitorId: booked ? booked.visitorId : null,
          level: booked ? booked.level : null,
          // parking: parking,
          terminating: booked ? booked.sipAccount : null,
          // terminating: parking,
        });
      }

      if (this.props.phoneState=='online' && prevProps.phoneState=='oncall') {
        this.props.setMode('rate')
      }
      
      if (this.props.remoteVideoStream && this.state.video==null) {
        this.setState({
          video: true,
        })
      }
    }

    showVideo() {
      this.setState({
        video: !this.state.video,
      })
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight, booked, phoneState}= this.props
      
      return(
        <div style={{}}>
          <div style={{textAlign: 'left'}}>
            <Header as='h3' style={{background: '#fff8c5', borderRadius: '.2em', padding: '.5em', border: '1px solid rgba(150, 150, 150, .3)'}}>
              <Header.Content>
                <Header.Subheader>
                  <b>{(this.props.phoneState && this.props.phoneState.toUpperCase()) || 'DISCONNECTED'}</b>
                </Header.Subheader>
                {this.props.terminating || 'Press keypad to dial'}
              </Header.Content>
            </Header>
          </div>

          {/* <Divider />
          {this.props.channel=='Video' && this.state.video && <>
            <div style={{height: 180, width: 222}}>
                <div style={{position: 'fixed'}}>
                  <PhonewidgetVideo  width={233} height={180} remoteVideoStream= {this.props.remoteVideoStream} localVideoStream={this.props.localVideoStream}/>
                </div>
            </div>
          </>}
          <div style={{textAlign: 'center'}}>
            <Button disabled={this.props.channel!='Video'} style={{borderRadius: '.5em'}} color={this.state.video ? 'pink' : 'grey'} size='small' icon onClick={this.props.remoteVideoStream ? this.showVideo.bind(this) : null}><Icon name={this.state.video ? 'video' : 'microphone'} style={{fontSize: '1.3em'}}/></Button>
          </div>
          <div style={{textAlign: 'center'}}>
            <Header as='h3' icon>
              <Header.Content>
                <Header.Subheader>For continous improvement, <br />optional recording is available.</Header.Subheader>
              </Header.Content>
            </Header>
          </div> */}

          <div>
            <PhonewidgetDial
              dtmf
              terminating={this.props.terminating}
              phoneState={this.props.phoneState}
              statusUA={this.props.statusUA}
              screenWidth={screenWidth} screenHeight={screenHeight}
              setMode={this.props.setMode} onChangeText={this.props.onChangeText}
              dtmfSend={this.props.dtmfSend} callDial={this.props.callDial}
            />
          </div>

          <Divider hidden />
          <div style={{textAlign: 'center'}}>
            <Button size='massive' icon color='red' circular onClick={this.callHangup.bind(this)}>
              <Icon name='phone' style={{transform: 'rotate(0deg)'}}/>
            </Button>
          </div>
        </div>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (PhonewidgetCall)