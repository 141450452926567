import React, { Component } from 'react'
import { Message } from 'semantic-ui-react'

class DismissableMessage extends Component {
  constructor(props) {
    super(props)
    this.state = { visible: true } 
  }

  componentDidMount() {
    if (this.props.hide=='auto') {
      setTimeout(()=>{this.setState({visible: false})}, 3000)
    }
  }

  handleDismiss = () => {
    this.setState({ visible: false })
  }

  render() {
    const { style }= this.props;
    
    if (this.state.visible) {
      return (
        <div>
          <Message size='tiny' 
            style= {{...style}}
            //onDismiss={this.handleDismiss}
            header={this.props.header}
            content={this.props.provider}

            {...this.props}
          />
        </div>
      )
    }

    return (
      null
    )
  }
}

export default DismissableMessage