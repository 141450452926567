export default function productReducer (state = {}, action) {
  switch (action.type) {
    case 'PRODUCT_RESET': {
        return({
            ...state,
            getProduct: {},
            updateProduct: {},
            createProduct: {},
        })
    }

    case 'PRODUCT_GET_PENDING': {
        return({...state, getProduct: {progress: 0}})
    }
    case 'PRODUCT_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getProduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getProduct: {...action.payload.data, data: action.payload.data.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PRODUCT_GET_REJECTED': {
        return({...state, getProduct: {progress: 2, payload: action.payload}})
    }

    case 'PRODUCT_STATUS_PENDING': {
      return({...state, statusProduct: {progress: 0}})
    }
    case 'PRODUCT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusProduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusProduct: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PRODUCT_STATUS_REJECTED': {
        return({...state, statusProduct: {progress: 2, payload: action.payload}})
    }

    case 'PRODUCT_BROWSE_PENDING': {
      return({...state, browseProduct: {progress: 0}})
    }
    case 'PRODUCT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseProduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseProduct: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PRODUCT_BROWSE_REJECTED': {
        return({...state, browseProduct: {progress: 2, payload: action.payload}})
    }

    case 'PRODUCT_CREATE_PENDING': {
      return({...state, browseProduct: {progress: 0}})
    }
    case 'PRODUCT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createProduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createProduct: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PRODUCT_CREATE_REJECTED': {
        return({...state, createProduct: {progress: 2, payload: action.payload}})
    }

    case 'PRODUCT_UPDATE_PENDING': {
      return({...state, updateProduct: {progress: 0}})
    }
    case 'PRODUCT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateProduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateProduct: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PRODUCT_UPDATE_REJECTED': {
        return({...state, updateProduct: {progress: 2, payload: action.payload}})
    }

    case 'PRODUCT_REMOVE_PENDING': {
      return({...state, removeProduct: {progress: 0}})
    }
    case 'PRODUCT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeProduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeProduct: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PRODUCT_REMOVE_REJECTED': {
        return({...state, removeProduct: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}