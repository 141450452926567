import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label, Dropdown } from 'semantic-ui-react'

class SessionAutostart extends Component {
  constructor(props) {
    super(props);
    this.state={
      count: 30,
    }
  }

  componentDidMount() {
    this.timer= setInterval(()=>{
      if (this.state.count>1) {
        this.setState({
          count: --this.state.count,
        })
      } else {
        this.props.startSession()
      }
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }
    
  render() {
    return (
      <div style={{padding: '2em'}}>
        <Header as='h1' color='red'>
          <Header.Content>
            <Icon name='clock outline' style={{marginLeft: '-.2em'}}/> <b>Starting</b>
          </Header.Content>
        </Header>

        <div style={{textAlign: 'center'}}>
          <Header as='h5'>
            <Header.Content>
              <b>Dear {this.props.user || ''}</b>, 
              <br />
              Your session will be started, makesure you would be ready to receive push dispatch from customer conversation.
              <Header.Subheader></Header.Subheader>
            </Header.Content>
          </Header>
          <Label color={(this.state.count<10 && 'red') || (this.state.count<20 && 'orange') || 'black'} size='massive' circular style={{fontSize: '6em', margin: '.3em 0 0 0', width: '2.15em', height: '2.15em'}}>
            <b>{this.state.count}</b>
          </Label>
        </div>

        <Divider style={{margin: '3em 0 2em 0'}} />
        <div style={{display: 'flex', marginTop: '0'}}>
          <div style={{flex: '1'}}>
            <Popup 
              position='top center'
              header={'Wait 60 secs more!'} 
              content={'Add waiting interval and will be auto started after 60 secs'} 
              trigger={
                <Button onClick={this.props.quitSession} style={{margin: '0', minWidth: '4.5em'}} size='massive' color='red'> WAIT</Button>
              }
            />
          </div>
          <div>
            <Button onClick={this.props.startSession} style={{margin: '0'}} size='massive' color='blue'>START !</Button>
          </div>
          
        </div>
      </div>
    )
  }
}

export default SessionAutostart;