import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import BillingWidget from '../../../libraries/widget/BillingWidget'
import ProgressWidget from '../../../libraries/widget/ProgressWidget';
import RecentWidget from '../../../libraries/widget/RecentWidget';
import NotificationWidget from '../../../libraries/widget/NotificationWidget'
import AppointmentWidget from '../../../libraries/widget/AppointmentWidget'
import Omniwidget from '../../../libraries/omniwidget/Omniwidget';
import OmniTrafficWidget from '../../../libraries/widget/OmniTrafficWidget';

class StatisticBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        userId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, userId: id})
    }

    render() {
      const {mode, userId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          {screenWidth<1000 && 
            <div style={{padding: '0 1em'}}>
                <BillingWidget screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow.bind(this)} openModal={this.props.openModal.bind(this)} />
            </div>
          }
           
          <div style={{display: 'flex'}}>
           <div style={{flex: '1', padding: '0 0em 1em 0em'}}>
              <div style={{padding: '1.5em 1em'}}>
                <OmniTrafficWidget screenHeight={screenHeight} />
                {/* <ProgressWidget screenWidth={screenWidth} screenHeight={screenHeight} /> */}
              </div>
              {/* <div style={{padding: '.5em 1em'}}>
                <RecentWidget openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} screenWidth={screenWidth} />
              </div> */}
            </div>
            {screenWidth>1000 &&
              <div style={{width: '300px', minHeight: screenHeight, padding: '1em 0em 1em 0em'}}>
                <div style={{padding: '.5em 1em'}}>
                  <BillingWidget screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow.bind(this)} openModal={this.props.openModal.bind(this)} />
                  {/* <Omniwidget /> */}
                  <NotificationWidget />
                  {/* <AppointmentWidget /> */}
                </div>
              </div>
            }
          </div>
        </div>
      )
    }
}

export default StatisticBody
