export default function linkwidgetReducer (state = {}, action) {
  switch (action.type) {
    case 'LINKWIDGET_RESET': {
        return({
            ...state,
            // paramLinkwidget: undefined,
            bookLinkwidget: undefined,
            rateLinkwidget: undefined,
            dialLinkwidget: undefined,
        })
    }

    case 'LINKWIDGET_PARAM_PENDING': {
      return({...state, paramLinkwidget: {progress: 0}})
    }
    case 'LINKWIDGET_PARAM_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, paramLinkwidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, paramLinkwidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'LINKWIDGET_PARAM_REJECTED': {
        return({...state, paramLinkwidget: {progress: 2, payload: action.payload}})
    }

    case 'LINKWIDGET_BOOK_PENDING': {
      return({...state, bookLinkwidget: {progress: 0}})
    }
    case 'LINKWIDGET_BOOK_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, bookLinkwidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, bookLinkwidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'LINKWIDGET_BOOK_REJECTED': {
        return({...state, bookLinkwidget: {progress: 2, payload: action.payload}})
    }

    case 'LINKWIDGET_RATE_PENDING': {
      return({...state, rateLinkwidget: {progress: 0}})
    }
    case 'LINKWIDGET_RATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, rateLinkwidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, rateLinkwidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'LINKWIDGET_RATE_REJECTED': {
        return({...state, rateLinkwidget: {progress: 2, payload: action.payload}})
    }

    case 'LINKWIDGET_VARIABLE_PENDING': {
      return({...state, variableLinkwidget: {progress: 0}})
    }
    case 'LINKWIDGET_VARIABLE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, variableLinkwidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, variableLinkwidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'LINKWIDGET_VARIABLE_REJECTED': {
        return({...state, variableLinkwidget: {progress: 2, payload: action.payload}})
    }

    case 'LINKWIDGET_CHAT_PENDING': {
      return({...state, chatLinkwidget: {progress: 0}})
    }
    case 'LINKWIDGET_CHAT_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, chatLinkwidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, chatLinkwidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'LINKWIDGET_CHAT_REJECTED': {
        return({...state, chatLinkwidget: {progress: 2, payload: action.payload}})
    }

    case 'LINKWIDGET_DIAL_PENDING': {
      return({...state, dialLinkwidget: {progress: 0}})
    }
    case 'LINKWIDGET_DIAL_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, dialLinkwidget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, dialLinkwidget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'LINKWIDGET_DIAL_REJECTED': {
        return({...state, dialLinkwidget: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}