import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'



class OmniphoneAccount extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    return (
      <Segment attached={true} style={{background: '#f3da00', border: 'none'}}>
        <div style={{padding: '1em'}}>
          <Header as='h4' style={{margin: '0 0 1em 0'}}>
            <Header.Content>
              PHONE ACCOUNT
              <Header.Subheader>
                This account for dev / testing only.
              </Header.Subheader>
            </Header.Content>
          </Header>
          <div style={{display: 'flex'}}>
            <div style={{flex: '2', marginRight: '.5em'}}>
              <Input fluid placeholder='Account' defaultValue='1001' style={{margin: '.2em 0'}} onChange={this.props.onChangeText.bind(this, 'account')}/> 
            </div>
            <div style={{flex: '3'}}>
              <Input fluid placeholder='Password' defaultValue='1001@d4nk0m2020' style={{margin: '.2em 0'}} onChange={this.props.onChangeText.bind(this, 'passwd')}/> 
            </div>
          </div>
          <Input fluid label='URI' defaultValue='sip:?@call.halopagi.com:4063' placeholder='sip:?@v01.halopagi.com:4063' style={{margin: '.2em 0'}} onChange={this.props.onChangeText.bind(this, 'uri')}/> 
          <Input fluid label='Host' defaultValue='wss://call.halopagi.com:4063/' placeholder='wss://v01.halopagi.com:4063/' style={{margin: '.2em 0'}} onChange={this.props.onChangeText.bind(this, 'host')}/> 
          <Divider />
          <div style={{textAlign: 'right'}}>
            <Button color='red' onClick={this.props.onClickReconnect.bind(this)}>CONNECT</Button>
          </div>
        </div>
      </Segment>
    )
  }
}

export default OmniphoneAccount