import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'

import SettingForm from '../../libraries/halo/SettingForm'


class SettingBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 2, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div style={{margin: '0 0 0 0', padding: '1em 1em', background: 'rgba(240, 240, 240, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
            <Header as='h4' style={{marginTop: '.25em'}}>
              <Icon name='cog' style={{fontSize: '1em'}} />
              <Header.Content>
                App Setting / Configuration
              </Header.Content>
            </Header>
          </div>
          <div style={{margin: '1em 1em 1em 1em'}}>
            <SettingForm mode={mode} settingId={1} setMode={this.setMode.bind(this)} />
          </div>
        </Container>
      )
    }
}

export default SettingBody
