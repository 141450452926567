import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetSegment(objParam) {
  return {
      type: 'SEGMENT_RESET',
      payload: objParam
  }
}

export function getSegment(objParam) {
  return {
      type: 'SEGMENT_GET',
      payload:
        axios.post(
          urlAPI + 'service/segment/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusSegment(objParam) {
  return {
      type: 'SEGMENT_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/segment/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseSegment(objParam) {
  return {
      type: 'SEGMENT_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/segment/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createSegment(objParam) {
  return {
      type: 'SEGMENT_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/segment/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateSegment(objParam) {
  return {
      type: 'SEGMENT_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/segment/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeSegment(objParam) {
  return {
      type: 'SEGMENT_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/segment/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
