export default function wachatReducer (state = {}, action) {
  switch (action.type) {
    case 'WACHAT_RESET': {
        return({
            ...state,
            getWachat: {},
            updateWachat: {},
            createWachat: {},
        })
    }

    case 'WACHAT_GET_PENDING': {
        return({...state, getWachat: {progress: 0}})
    }
    case 'WACHAT_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getWachat: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getWachat: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACHAT_GET_REJECTED': {
        return({...state, getWachat: {progress: 2, payload: action.payload}})
    }

    case 'WACHAT_STATUS_PENDING': {
      return({...state, statusWachat: {progress: 0}})
    }
    case 'WACHAT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusWachat: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusWachat: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACHAT_STATUS_REJECTED': {
        return({...state, statusWachat: {progress: 2, payload: action.payload}})
    }

    case 'WACHAT_BROWSE_PENDING': {
      return({...state, browseWachat: {progress: 0}})
    }
    case 'WACHAT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseWachat: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseWachat: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACHAT_BROWSE_REJECTED': {
        return({...state, browseWachat: {progress: 2, payload: action.payload}})
    }

    case 'WACHAT_CREATE_PENDING': {
      return({...state, browseWachat: {progress: 0}})
    }
    case 'WACHAT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createWachat: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createWachat: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACHAT_CREATE_REJECTED': {
        return({...state, createWachat: {progress: 2, payload: action.payload}})
    }

    case 'WACHAT_UPDATE_PENDING': {
      return({...state, updateWachat: {progress: 0}})
    }
    case 'WACHAT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateWachat: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateWachat: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACHAT_UPDATE_REJECTED': {
        return({...state, updateWachat: {progress: 2, payload: action.payload}})
    }

    case 'WACHAT_REMOVE_PENDING': {
      return({...state, removeWachat: {progress: 0}})
    }
    case 'WACHAT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeWachat: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeWachat: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACHAT_REMOVE_REJECTED': {
        return({...state, removeWachat: {progress: 2, payload: action.payload}})
    }
    
    default: {
      return state
    }
  }
}