import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'



class PhonewidgetSetting extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      // uid: '7012345639',
      // passwd: 'NDM3NDQxMTc0MTcwNjMzNTAwMDA=',
    }
  }

  sipConnect() {
    this.props.sipRegister();
    this.props.setMode('home')
  }

  render() {
    return (
      <Segment>
        <Header as='h4' color='red'>
          <Header.Content>
            Setting
            <Header.Subheader>
              Account and phone preferences.
            </Header.Subheader>
          </Header.Content>
        </Header>
        
        <Divider hidden />
        <Input fluid label='User' placeholder='Account' value= {this.props.uid} style={{margin: '.2em 0'}} onChange={this.props.onChangeText.bind(this, 'uid')}/> 
        <Input fluid label='Pass' placeholder='Password' value= {this.props.passwd} style={{margin: '.2em 0'}} onChange={this.props.onChangeText.bind(this, 'passwd')}/> 
        
        <Divider hidden />
        <label className='ui'><b>Host / Server</b></label>
        <Input fluid label='wss://' value= {this.props.host} placeholder='server.halopagi.com:4063' style={{margin: '.2em 0'}} onChange={this.props.onChangeText.bind(this, 'host')}/> 
        {/* <Input fluid label='Uri' defaultValue='sip:?@call.halopagi.com:4063' placeholder='sip:?@v01.halopagi.com:4063' style={{margin: '.2em 0'}} onChange={this.props.onChangeText.bind(this, 'uri')}/>  */}
        
        <Divider hidden />
        <div style={{textAlign: 'right'}}>
          <Button color='blue' onClick={this.sipConnect.bind(this)}>OK</Button>
        </div>
      </Segment>
    )
  }
}

export default PhonewidgetSetting