import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetVisitor(objParam) {
  return {
      type: 'VISITOR_RESET',
      payload: objParam
  }
}

export function getVisitor(objParam) {
  return {
      type: 'VISITOR_GET',
      payload:
        axios.post(
          urlAPI + 'visitor/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusVisitor(objParam) {
  return {
      type: 'VISITOR_STATUS',
      payload:
        axios.post(
          urlAPI + 'visitor/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseVisitor(objParam) {
  return {
      type: 'VISITOR_BROWSE',
      payload:
        axios.post(
          urlAPI + 'visitor/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createVisitor(objParam) {
  return {
      type: 'VISITOR_CREATE',
      payload:
        axios.post(
          urlAPI + 'visitor/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateVisitor(objParam) {
  return {
      type: 'VISITOR_UPDATE',
      payload:
        axios.post(
          urlAPI + 'visitor/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeVisitor(objParam) {
  return {
      type: 'VISITOR_REMOVE',
      payload:
        axios.post(
          urlAPI + 'visitor/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
