export default function contactReducer (state = {}, action) {
  switch (action.type) {
    case 'CONTACT_RESET': {
        return({
            ...state,
            getContact: {},
            updateContact: {},
            createContact: {},
        })
    }

    case 'CONTACT_BROWSE_PENDING': {
      return({...state, browseContact: {progress: 0}})
    }
    case 'CONTACT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseContact: {...api.browseContact.response, progress: 1, payload: action.payload}})
            return({...state, browseContact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseContact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONTACT_BROWSE_REJECTED': {
        return({...state, browseContact: {progress: 2, payload: action.payload}})
    }
    
    case 'CONTACT_GET_PENDING': {
        return({...state, getContact: {progress: 0}})
    }
    case 'CONTACT_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getContact: {...api.getContact.response, progress: 1, payload: action.payload}})
            return({...state, getContact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getContact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONTACT_GET_REJECTED': {
        return({...state, getContact: {progress: 2, payload: action.payload}})
    }

    case 'CONTACT_STATUS_PENDING': {
      return({...state, statusContact: {progress: 0}})
    }
    case 'CONTACT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusContact: {...api.statusContact.response, progress: 1, payload: action.payload}})
            return({...state, statusContact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusContact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONTACT_STATUS_REJECTED': {
        return({...state, statusContact: {progress: 2, payload: action.payload}})
    }

    case 'CONTACT_CREATE_PENDING': {
      return({...state, createContact: {progress: 0}})
    }
    case 'CONTACT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createContact: {...api.createContact.response, progress: 1, payload: action.payload}})
            return({...state, createContact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createContact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONTACT_CREATE_REJECTED': {
        return({...state, createContact: {progress: 2, payload: action.payload}})
    }

    case 'CONTACT_UPDATE_PENDING': {
      return({...state, updateContact: {progress: 0}})
    }
    case 'CONTACT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateContact: {...api.updateContact.response, progress: 1, payload: action.payload}})
            return({...state, updateContact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateContact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONTACT_UPDATE_REJECTED': {
        return({...state, updateContact: {progress: 2, payload: action.payload}})
    }

    case 'CONTACT_REMOVE_PENDING': {
      return({...state, removeContact: {progress: 0}})
    }
    case 'CONTACT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeContact: {...api.removeContact.response, progress: 1, payload: action.payload}})
            return({...state, removeContact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeContact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CONTACT_REMOVE_REJECTED': {
        return({...state, removeContact: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}