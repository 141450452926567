export default function associateReducer (state = {}, action) {
  switch (action.type) {
    case 'SOCMED_ASSOCIATE_RESET': {
        return({
            ...state,
            getAssociate: {},
            updateAssociate: {},
            createAssociate: {},
        })
    }

    case 'SOCMED_ASSOCIATE_BROWSE_PENDING': {
        return({...state, browseAssociate: {progress: 0}})
    }
    case 'SOCMED_ASSOCIATE_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseAssociate: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseAssociate: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_ASSOCIATE_BROWSE_REJECTED': {
        return({...state, browseAssociate: {progress: 2, payload: action.payload}})
    }
    
    case 'SOCMED_ASSOCIATE_GET_PENDING': {
        return({...state, getAssociate: {progress: 0}})
    }
    case 'SOCMED_ASSOCIATE_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getAssociate: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getAssociate: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_ASSOCIATE_GET_REJECTED': {
        return({...state, getAssociate: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_ASSOCIATE_STATUS_PENDING': {
      return({...state, statusAssociate: {progress: 0}})
    }
    case 'SOCMED_ASSOCIATE_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusAssociate: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusAssociate: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_ASSOCIATE_STATUS_REJECTED': {
        return({...state, statusAssociate: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_ASSOCIATE_CREATE_PENDING': {
      return({...state, createAssociate: {progress: 0}})
    }
    case 'SOCMED_ASSOCIATE_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createAssociate: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createAssociate: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_ASSOCIATE_CREATE_REJECTED': {
        return({...state, createAssociate: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_ASSOCIATE_UPDATE_PENDING': {
      return({...state, updateAssociate: {progress: 0}})
    }
    case 'SOCMED_ASSOCIATE_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateAssociate: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateAssociate: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_ASSOCIATE_UPDATE_REJECTED': {
        return({...state, updateAssociate: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_ASSOCIATE_REMOVE_PENDING': {
      return({...state, removeAssociate: {progress: 0}})
    }
    case 'SOCMED_ASSOCIATE_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeAssociate: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeAssociate: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_ASSOCIATE_REMOVE_REJECTED': {
        return({...state, removeAssociate: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}