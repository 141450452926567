import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Card, Modal, Tab, Input } from 'semantic-ui-react'

import MenuHorizontal from '../../libraries/common/MenuHorizontal'
import CollectionForm from './CollectionForm'
import RestructuringForm from './RestructuringForm'
import TelesalesForm from './TelesalesForm'
import VerificationForm from './VerificationForm'

import CustomerForm from './CustomerForm'
import VisitorForm from './VisitorForm'


import MyTicketFormCreate from '../../libraries/agent/ticket/MyTicketFormCreate'

// import TicketBrowse from '../../libraries/service/TicketBrowse'
// import TicketForm from '../../libraries/service/TicketForm'
// import HandlingForm from '../../libraries/service/HandlingForm'
// import TickethandlingBrowse from '../../libraries/service/TickethandlingBrowse'

import EngagementBrowse from './EngagementBrowse'
import KnowledgeBrowse from './KnowledgeBrowse'

import { connect } from "react-redux"
import { contactOmniphone, remarkOmniphone, knowledgeOmniphone } from "../../actions/stream/omniphoneAction"
import { variableOmniwidget } from "../../actions/flow/omniwidgetAction"
import { listRemark, listProduct } from "../../actions/halo/masterAction"
import { browseTarget } from "../../actions/stream/targetAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    omniphoneStore: state.omniphoneStore,

    omniwidgetStore: state.omniwidgetStore,
    targetStore: state.targetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),

    contactOmniphone:(objParam) => dispatch(contactOmniphone(objParam)),
    remarkOmniphone:(objParam) => dispatch(remarkOmniphone(objParam)),
    knowledgeOmniphone:(objParam) => dispatch(knowledgeOmniphone(objParam)),

    variableOmniwidget:(objParam) => dispatch(variableOmniwidget(objParam)),
    browseTarget:(objParam) => dispatch(browseTarget(objParam)),
  }
}

const options= (props)=>{
  // console.log(props)
  const menus= [];
  
  props.campaign=='Collection' && 
  menus.push(
    {
      key: 1,
      icon: 'copy outline',
      caption: 'Collection',
    }
  );
  props.campaign=='Restructuring' && 
  menus.push(
    {
      key: 1,
      icon: 'cubes',
      caption: 'Restructuring',
    }
  );
  props.campaign=='Telesales' && 
  menus.push(
    {
      key: 1,
      icon: 'closed captioning outline',
      caption: 'Telesales',
    }
  );
  props.campaign=='Verification' && 
  menus.push(
    {
      key: 1,
      icon: 'lab',
      caption: 'Verification',
    }
  );
  // false && 
  // menus.push(
  //   {
  //     key: 1,
  //     icon: 'hotel',
  //     caption: 'Tourism',
  //   }
  // )
  // false && 
  // menus.push(
  //   {
  //     key: 1,
  //     icon: 'stethoscope',
  //     caption: 'Insurance',
  //   }
  // )
  
  menus.push(
    {
      key: 2,
      icon: 'ticket alternate',
      caption: 'Ticket',
    }
  );

  // !props.campaign && !props.customer && menus.push(
  //   {
  //     key: 3,
  //     icon: 'id badge outline',
  //     caption: 'Visitor',
  //   }
  // );
  props.customer && menus.push(
    {
      key: 4,
      icon: 'user circle outline',
      caption: 'Customer',
    }
  );

  // (props.campaign || props.customer) && 
  menus.push(
    {
      key: 5,
      icon: 'ellipsis vertical',
      caption: 'Engagement',
    } 
  );

  menus.push(
    {
      key: 6,
      icon: 'folder outline',
      caption: 'Knowledge',
    }
  );

  return menus;
}

class OmniphoneContentInbound extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      collapsed: false,
      key: -1,
    };
  }

  componentDidMount() {
    const { status, conversation }= this.props;
    
    this.props.listProduct();
    this.props.knowledgeOmniphone();
    
    if (conversation) {
      // console.log('DIDMOUNT', status, 'visitorId: ', conversation.visitorId, 'targetId: ', conversation.targetId);

      conversation.campaignId>0 && this.props.listRemark({campaignId: conversation.campaignId});
      this.props.contactOmniphone({targetId: conversation.targetId, visitorId: conversation.visitorId, customerId: conversation.customerId, contactId: conversation.contactId});
    }
  }

  componentDidUpdate(prevProps) {
    const { status, conversation }= this.props;
    const { key }= this.state;

    if (status=='occupied' && prevProps.conversation && 
      (conversation.targetId!=0 || conversation.visitorId!=0 || conversation.customerId!=0 || conversation.contactId!=0) &&
      ((prevProps.conversation.targetId!=conversation.targetId) || (prevProps.conversation.visitorId!=conversation.visitorId) || (prevProps.conversation.customerId!=conversation.customerId) || (prevProps.conversation.contactId!=conversation.contactId))      
    ) {
      // console.log('DIDUPDATE', status, 'visitorId: ', conversation.visitorId, 'targetId: ', conversation.targetId, 'prevProps: ', prevProps.conversation);

      conversation.campaignId>0 && this.props.listRemark({campaignId: conversation.campaignId});
      this.props.contactOmniphone({targetId: conversation.targetId, visitorId: conversation.visitorId, customerId: conversation.customerId, contactId: conversation.contactId});

      if (conversation.targetId!=0) {
        key==-1 && this.setState({
          key: 1,
        })
      } else if (conversation.customerId!=0) {
        key==-1 && this.setState({
          key: 3,
        })
      } else {
        key==-1 && this.setState({
          key: 2,
        })
      }
    }

    if (conversation) {
      if (conversation.targetId!=0) {
        key==-1 && this.setState({
          key: 1,
        })
      } else if (conversation.customerId!=0) {
        key==-1 && this.setState({
          key: 3,
        })
      } else {
        key==-1 && this.setState({
          key: 2,
        })
      }
    }
  }

  componentWillUnmount() {
    const { search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    this.props.browseTarget({search: search, paging: paging})
  }

  textCollapse() {
    const { collapsed }= this.state
    this.setState({
      collapsed: !collapsed,
    })
  }

  setMenu(key) {
    // console.log(key)
    this.setState({key: key})
  }

  setMode(mode, id, clientId) {
    this.setState({mode: mode, ticketId: id, clientId: clientId})
  }

  onChangeInput(e, v) {
    const { conversation}= this.props
    const { search }= (this.props.omniwidgetStore.variableOmniwidget!==undefined ? this.props.omniwidgetStore.variableOmniwidget : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.variableOmniwidget({
          isVoice: conversation.voiceId!=0,
          visitorId: conversation.visitorId,
          clientId: conversation.clientId,
          custRef: v.value,
        })
      }
    }, 500)
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.variableOmniwidget({
          isVoice: conversation.voiceId!=0,
          visitorId: conversation.visitorId,
          clientId: conversation.clientId,
          contractRef: v.value,
        })
      }
    }, 1000)
  }

  render() {
    const { collapsed, key }= this.state
    const { mode, status, conversation, screenWidth,  screenHeight}= this.props
    const { target, customer }= (this.props.omniphoneStore.contactOmniphone!=undefined && this.props.omniphoneStore.contactOmniphone.progress=='1' && this.props.omniphoneStore.contactOmniphone.data ? this.props.omniphoneStore.contactOmniphone.data : {})

    let script=  target && target.script && target.script.script; ///'Selamat datang, bagaimana kabarnya, kami informasikan tagihan Anda sebesar ... silahkan lakukan pembayaran melalui bank atau pembayaran terdekat sebelum tanggal 01/Jan agar tidak dikenakan denda.'

    try {
      if (collapsed && script) {
        script= script.split(' ').slice(0,10).join(' ')
      }
      // console.log('!!! RENDERED WEBPHONE CONTENT', conversation)
      return(
        <>
          {/* {(script || script=='') &&
            <Segment style={{padding: '1em', background: '#eaeaea', borderRadius: '0em', overflow: 'hidden', color: '#666', border: 'none', margin: '1em -1em'}}>
              <Header as='h5' color='red' style={{marginBottom: '.5em'}}>
                <Icon name={collapsed ? 'angle double down' : 'angle double up'}  style={{fontSize: '1em', margin: '0', cursor: 'pointer'}} color={!collapsed ? 'yellow' : 'yellow'} onClick={this.textCollapse.bind(this)} circular inverted/>
                <Header.Content style={{paddingLeft: '1em'}}>
                  <p style={{fontSize: '1.1em', color: '#dd0000', textShadow: '0 1px 0 #fff', fontWeight: 'bold'}}>
                    <sup><Icon name='quote left' color='blue' style={{marginRight: '.5em'}}/></sup>
                    {script}
                    <sub><Icon name='quote right' color='blue' style={{marginLeft: '.5em'}}/></sub>
                  </p> 
                </Header.Content>
              </Header>
            </Segment>
          } */}
          <div style={{display: 'flex', margin: '1.5em 0 0 0'}}>
            <div style={{flex: '1'}}>
              {key!=-1 && <MenuHorizontal onClick={this.setMenu.bind(this)} options= {options({campaign: (target && target.campaignType), customer: (conversation.customerId)})} color='red' simple activeItem={key}/>}
            </div>
            <div style={{paddingTop: '.3em'}}>
              <Input  onChange= {this.onChangeInput.bind(this)}
              placeholder= 'Cust. or Contract Reference'
              label='Search By'/>
            </div>
          </div>
          
          <div style={{paddingTop: '1.53em', background: '#fff'}}>
            {target && target.campaignType==='Collection' && 
              <CollectionForm screenWidth={screenWidth} screenHeight={screenHeight} 
              media={conversation.media} targetId={conversation.targetId} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} campaignId={conversation.campaignId} voiceId={conversation.voiceId} messagingId={conversation.messagingId} 
              hasSaved={this.props.hasSaved.bind(this)} 
              endConversation={this.props.endConversation.bind(this)}
              style={{display: (key==1 && 'block') || 'none'}}/>
            }
            {target && target.campaignType==='Restructuring' && 
              <RestructuringForm screenWidth={screenWidth} screenHeight={screenHeight} 
              media={conversation.media} targetId={conversation.targetId} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} campaignId={conversation.campaignId} voiceId={conversation.voiceId} messagingId={conversation.messagingId} 
              hasSaved={this.props.hasSaved.bind(this)} 
              endConversation={this.props.endConversation.bind(this)}
              style={{display: (key==1 && 'block') || 'none'}}/>
            }
            {target && target.campaignType==='Telesales' && 
              <TelesalesForm screenWidth={screenWidth} screenHeight={screenHeight} 
              media={conversation.media} targetId={conversation.targetId} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} campaignId={conversation.campaignId} voiceId={conversation.voiceId} messagingId={conversation.messagingId} 
              hasSaved={this.props.hasSaved.bind(this)} 
              endConversation={this.props.endConversation.bind(this)}
              style={{display: (key==1 && 'block') || 'none'}}/>
            }
            {target && target.campaignType==='Verification' && 
              <VerificationForm screenWidth={screenWidth} screenHeight={screenHeight} 
              media={conversation.media} targetId={conversation.targetId} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} campaignId={conversation.campaignId} voiceId={conversation.voiceId} messagingId={conversation.messagingId} 
              hasSaved={this.props.hasSaved.bind(this)} 
              endConversation={this.props.endConversation.bind(this)}
              style={{display: (key==1 && 'block') || 'none'}}/>
            }

            {
              <MyTicketFormCreate 
              screenWidth={screenWidth} screenHeight={screenHeight-125} 
              setMode={this.setMode.bind(this)} 
              conversation={conversation}
              media={conversation.media} customerId={conversation.customerId} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} voiceId={conversation.voiceId} messagingId={conversation.messagingId} socialId={conversation.socialId} visitor={this.props.visitor} 
              hasSaved={this.props.hasSaved.bind(this)} 
              endConversation={this.props.endConversation.bind(this)}
              style={{display: (key==2 && 'block') || 'none'}}
              />
            }
            {/* {!target && !customer && 
              <VisitorForm setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} 
              media={conversation.media} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} voiceId={conversation.voiceId} messagingId={conversation.messagingId} visitor={this.props.visitor} 
              hasSaved={this.props.hasSaved.bind(this)} 
              endConversation={this.props.endConversation.bind(this)}
              style={{display: (key==3 && 'block') || 'none'}} />
            } */}
            
            {customer && 
              <CustomerForm setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} 
              media={conversation.media} customerId={conversation.customerId} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} voiceId={conversation.voiceId} messagingId={conversation.messagingId} 
              hasSaved={this.props.hasSaved.bind(this)} 
              endConversation={this.props.endConversation.bind(this)}
              style={{display: (key==4 && 'block') || 'none'}} />
            }
            
            {/* {customer && 
              <TicketBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} 
              media={conversation.media} customerId={conversation.customerId} visitorId={conversation.visitorId} agentId={conversation.userId} clientId={conversation.clientId} 
              style={{display: key==4 && 'block' || 'none'}} />
            } */}
            {/* <TickethandlingBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} style={{display: key==2 && 'block' || 'none'}}/> */}
            {/* <TicketForm setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} style={{display: key==2 && 'block' || 'none'}}/> */}
            {/* <HandlingForm setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} style={{display: key==2 && 'block' || 'none'}}/> */}

            {/* {(target || customer) &&  */}
              <EngagementBrowse screenWidth={screenWidth} screenHeight={screenHeight} 
              targetId={conversation.targetId} customerId={conversation.customerId} clientId={conversation.clientId} 
              style={{display: (key==5 && 'block') || 'none'}} /> 
            {/* } */}
            <KnowledgeBrowse screenWidth={screenWidth} screenHeight={screenHeight} clientId={conversation.clientId} 
            style={{display: (key==6 && 'block') || 'none'}} />
          </div>
        </>
      )
    } catch(err) {
      console.log(err)
      this.props.loggingError({level: 'error', actor: 'APP HALO', scope: 'OmniphoneContentOutbound', event: 'On render', message: err });
      return<>ERROR UX RENDERING</>
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(OmniphoneContentInbound)
