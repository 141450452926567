import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label, Dropdown } from 'semantic-ui-react'

const signoutOptions = [
  {
    key: '1',
    text: 'After Hours',
    value: 'After Hours',
  },
  {
    key: '2',
    text: 'No Campaign',
    value: 'No Campaign',
  },
]

class SignoutConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state={
    }
  }
    
  render() {
    return (
      <div style={{padding: '2em'}}>
        <Header as='h1' color='red'>
          <Header.Content>
            <Icon name='sign-out' style={{marginLeft: '-.2em'}}/> Signout !
          </Header.Content>
        </Header>
        <Header as='h5'>
          <Header.Content>
            Dear {this.props.user || ''}, 
            <br />
            You have clicked signout, to continue please click confirm button. 
            <br />
            After signout your session will be wiped out right away.
            <Header.Subheader></Header.Subheader>
          </Header.Content>
        </Header>

        <Header as='h4' color='blue'>
          <Header.Content>
            Reason Why Signout ?
            <Header.Subheader></Header.Subheader>
          </Header.Content>
        </Header>
        <Dropdown style={{fontWeight: 'bold'}} onChange={this.props.onChangeSelect}
          placeholder='Pilih alasan signout'
          fluid
          search
          selection
          options={signoutOptions}
        />
        <div style={{textAlign: 'right', marginTop: '6em'}}>
        <Button disabled={!this.props.state.signoutReason} onClick={this.props.onClick} size='large' color='red'>Confirm Now !</Button>
        </div>
      </div>
    )
  }
}

export default SignoutConfirmation;