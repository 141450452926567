import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form } from 'semantic-ui-react'

import LinkwidgetDial from './LinkwidgetDial'
import LinkwidgetVideo from './LinkwidgetVideo'

import { connect } from "react-redux"
import { dialLinkwidget } from "../../actions/talk/linkwidgetAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    linkwidgetStore: state.linkwidgetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dialLinkwidget:(objParam) => dispatch(dialLinkwidget(objParam)),
  }
}

class LinkwidgetCall extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isCalled: false,
        video: null,
      };
    }

    callDial(terminating) {
      this.props.callDial(terminating);
    }

    callHangup() {
      this.props.callHangup()
      this.props.setMode('rate')
    }

    componentDidMount() {
      // setTimeout(()=>{
      //   this.props.dtmfSend('12#')
      // }, 2000)
    }
    
    componentDidUpdate(prevProps) {
      const {booked}= this.props
      // agentExten: "7000000005"
      // agentId: "4"
      // clientId: "2"
      // sipAccount: "7012345215"
      // sipHost: "host"
      // sipPasswd: "MTQ3MTgyODU4Nzc5MzE0MTAwMDA="
      // sipPort: "port"
      // visitorId: "215"
      // voiceParking: "4175"      

      // console.log('DID UPDATE', booked)
      
      if (!this.state.isCalled && this.props.phoneState=='online' && booked) {
        this.setState({
          isCalled: true
        })
        // this.callDial('41756000000000001')
        // console.log('CALL DIAL LINKWIDGET-CALL', booked)
        const parking= booked.voiceParking +'6'+ booked.clientId.toString().padStart(6,"0") + booked.visitorId.toString().padStart(6,"0");
        // const parking= booked.voiceParking +'6'+ booked.clientId.toString().padStart(5,"0") + booked.visitorId.toString().padStart(5,"0") + booked.level.toString().padStart(2,"0"); //clientId(6)visitorId(6)agentId(6)level(09) 2 digits to defined level position and current menu
        // this.callDial(parking);
        this.props.dialLinkwidget({
          agentId: booked ? booked.agentId : null, // for booked agent allocation
          // clientId: booked ? booked.clientId : null,
          visitorId: booked ? booked.visitorId : null,
          // parking: parking,
          terminating: booked ? booked.sipAccount : null,
          // terminating: parking,
        });
      }

      if (this.props.phoneState=='online' && prevProps.phoneState=='oncall') {
        this.props.setMode('rate')
      }
      
      if (this.props.remoteVideoStream && this.state.video==null && this.props.params.channels.includes('Video')) {
        this.setState({
          video: true,
        })
      }
    }

    showVideo() {
      this.setState({
        video: !this.state.video,
      })
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight, booked, phoneState, params}= this.props
      
      return(<>
        <div style={{padding: '1em 1em', minHeight: screenHeight/2}}>
          {/* {phoneState} */}
          <div style={{padding: '1.5em', maxWidth: 375, background: '#eee', borderRadius: '1em', margin: '0 auto'}}>
            {/* <div>
              <div style={{textAlign: 'center', padding: '1.5em 0'}}>
                <Header as='h2' icon style={{margin: '0'}}>
                {(params && params.avatar && <Image style={{width: '3.5em', height: '3.5em'}} circular src={params.avatar} />) || <Icon name='user circle' style={{fontSize: '3.5em'}}/>}
                  <Header.Content>
                    {(params && params.brand) || 'CORPORATE BRAND'}
                    <Header.Subheader>{(params && params.descript) || 'Corporate description / tagline'}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <Divider />
            </div> */}

            <div>
              <div style={{textAlign: 'center', padding: '1.5em 0'}}>
                <Header as='h2' color='blue' icon>
                  {booked && booked.agentAvatar!='-' && 
                    <Image  style={{width: '3.5em', height: '3.5em'}} avatar src={booked.agentAvatar || 'https://www.shareicon.net/data/512x512/2016/05/24/770117_people_512x512.png'} /> 
                  }
                  {(!booked || (booked && booked.agentAvatar=='-')) && 
                    <Icon name='user circle' style={{fontSize: '3.2em'}} color={(this.props.phoneState=='oncall' && 'blue') || 'grey'} />
                  }
                  <Header.Content>
                    {(booked && booked.agentName) || 'Virtual Agent'}
                    <Header.Subheader>Interactive / Autonomous Call</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <Divider />
            </div>

            <div>
              <div style={{textAlign: 'center', padding: '1em 0'}}>
                {this.state.video && 
                  <div style={{padding: '1em'}}>
                    <div style={{height: 210, width: 305}}>
                        <div style={{position: 'fixed'}}>
                          <LinkwidgetVideo  width={305} height={210} remoteVideoStream= {this.props.remoteVideoStream} localVideoStream={this.props.localVideoStream}/>
                        </div>
                    </div>
                  </div>
                }

                <div style={{padding: '1em 3em'}}>
                  <div style={{textAlign: 'center', paddingTop: this.state.video ? 0 : '1em'}}>
                    <Button style={{borderRadius: '.5em'}} disabled={!this.props.remoteVideoStream || !params.channels.includes('Video')} color={this.state.video ? 'pink' : 'grey'} size='small' icon onClick={this.props.remoteVideoStream ? this.showVideo.bind(this) : null}><Icon name={this.state.video ? 'video' : 'microphone'} style={{fontSize: '1.3em'}}/></Button>
                    <br />
                    <Header as='h3' icon style={{margin: '.5em 0 0 0'}}>
                      <Header.Content style={{fontWeight: 300}}>
                        Untuk memastikan layanan terbaik, pembicaraan dengan Petugas kami akan direkam.
                      </Header.Content>
                    </Header>
                  </div>
                  {/* <div>
                    <LinkwidgetDial dtmfSend={this.props.dtmfSend.bind(this)} />
                  </div> */}
                </div>  
              </div>

              <div style={{textAlign: 'center', padding: '1.5em 0'}}>
                <Button color='red' disabled={phoneState!='oncall'} type='button' size='massive' icon labelPosition='left' style={{margin: '.5em 1em', borderRadius: '2em'}}  onClick={this.callHangup.bind(this)}><Icon name='phone' /> Selesai</Button>
              </div>
            </div>
          </div>
        </div>
      </>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (LinkwidgetCall)