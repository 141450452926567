import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'



class OmniphoneDial extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      dial: '',
    }
  }

  sendDTMF(v) {
    // console.log(v)
    this.setState({
      dial: this.state.dial + v,
    })
    this.props.sendDTMF && this.props.sendDTMF(v)
  }

  render() {
    return (
      <>
        <Segment attached={true} style={{background: 'none', border: 'none', textAlign: 'center'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <Input fluid readOnly value={this.state.dial} placeholder='Dialed number' style={{paddingTop: '.2em',fontSize: '1.6em', fontWeight: 'bold'}} onChange={this.props.onChangeText && this.props.onChangeText.bind(this, 'number')}/> 
            </div>
            {/* <div>
              <Button icon color='red' style={{padding: '1.2em .5em 1em .5em'}} onClick={this.props.callDial && this.props.callDial.bind(this)}><Icon style={{fontSize: '1.8em'}} name='call' /></Button>
            </div> */}
          </div>
          <Grid columns={3} style={{background: '#4a4a4a', margin: '1em -1em -1.5em -1em'}}>
            <Grid.Column style={{marginBottom: '-1em'}}>
              <Button fluid style={{textAlign: 'center', borderRadius: '.1em', margin: '0em 0em', padding: '.3em 0', fontSize: '1.5em',  fontWeight: 'bold'}} onClick={this.sendDTMF.bind(this, '1')}>
                  1
              </Button>
            </Grid.Column>
            <Grid.Column style={{marginBottom: '-1em'}}>
              <Button fluid style={{textAlign: 'center', borderRadius: '.1em', margin: '0em 0em', padding: '.3em 0', fontSize: '1.5em',  fontWeight: 'bold'}} onClick={this.sendDTMF.bind(this, '2')}>
                  2
              </Button>
            </Grid.Column>
            <Grid.Column style={{marginBottom: '-1em'}}>
              <Button fluid style={{textAlign: 'center', borderRadius: '.1em', margin: '0em 0em', padding: '.3em 0', fontSize: '1.5em',  fontWeight: 'bold'}} onClick={this.sendDTMF.bind(this, '3')}>
                  3
              </Button>
            </Grid.Column>
            <Grid.Column style={{marginBottom: '-1em'}}>
              <Button fluid style={{textAlign: 'center', borderRadius: '.1em', margin: '0em 0em', padding: '.3em 0', fontSize: '1.5em',  fontWeight: 'bold'}} onClick={this.sendDTMF.bind(this, '4')}>
                  4
              </Button>
            </Grid.Column>
            <Grid.Column style={{marginBottom: '-1em'}}>
              <Button fluid style={{textAlign: 'center', borderRadius: '.1em', margin: '0em 0em', padding: '.3em 0', fontSize: '1.5em',  fontWeight: 'bold'}} onClick={this.sendDTMF.bind(this, '5')}>
                  5
              </Button>
            </Grid.Column>
            <Grid.Column style={{marginBottom: '-1em'}}>
              <Button fluid style={{textAlign: 'center', borderRadius: '.1em', margin: '0em 0em', padding: '.3em 0', fontSize: '1.5em',  fontWeight: 'bold'}} onClick={this.sendDTMF.bind(this, '6')}>
                  6
              </Button>
            </Grid.Column>
            <Grid.Column style={{marginBottom: '-1em'}}>
              <Button fluid style={{textAlign: 'center', borderRadius: '.1em', margin: '0em 0em', padding: '.3em 0', fontSize: '1.5em',  fontWeight: 'bold'}} onClick={this.sendDTMF.bind(this, '7')}>
                  7
              </Button>
            </Grid.Column>
            <Grid.Column style={{marginBottom: '-1em'}}>
              <Button fluid style={{textAlign: 'center', borderRadius: '.1em', margin: '0em 0em', padding: '.3em 0', fontSize: '1.5em',  fontWeight: 'bold'}} onClick={this.sendDTMF.bind(this, '8')}>
                  8
              </Button>
            </Grid.Column>
            <Grid.Column style={{marginBottom: '-1em'}}>
              <Button fluid style={{textAlign: 'center', borderRadius: '.1em', margin: '0em 0em', padding: '.3em 0', fontSize: '1.5em',  fontWeight: 'bold'}} onClick={this.sendDTMF.bind(this, '9')}>
                  9
              </Button>
            </Grid.Column>
            
            <Grid.Column>
              <Button fluid style={{textAlign: 'center', borderRadius: '.1em', margin: '0em 0em', padding: '.3em 0', fontSize: '1.5em',  fontWeight: 'bold'}} onClick={this.sendDTMF.bind(this, '*')}>
                  *
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button fluid style={{textAlign: 'center', borderRadius: '.1em', margin: '0em 0em', padding: '.3em 0', fontSize: '1.5em',  fontWeight: 'bold'}} onClick={this.sendDTMF.bind(this, '0')}>
                0
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Header as='h3' style={{background: '#ccc', textAlign: 'center', borderRadius: '.1em', margin: '0em 0em', padding: '.2em 0'}} onClick={this.sendDTMF.bind(this, '#')}>
                <Header.Content>
                  #
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid>
        </Segment>
      </>
    )
  }
}

export default OmniphoneDial