export default function clientReducer (state = {}, action) {
  switch (action.type) {
    case 'CLIENT_RESET': {
        return({
            ...state,
            getClient: {},
            updateClient: {},
            createClient: {},
        })
    }

    case 'CLIENT_BROWSE_PENDING': {
      return({...state, browseClient: {progress: 0}})
    }
    case 'CLIENT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseClient: {...api.browseClient.response, progress: 1, payload: action.payload}})
            return({...state, browseClient: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseClient: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLIENT_BROWSE_REJECTED': {
        return({...state, browseClient: {progress: 2, payload: action.payload}})
    }
    
    case 'CLIENT_GET_PENDING': {
        return({...state, getClient: {progress: 0}})
    }
    case 'CLIENT_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getClient: {...api.getClient.response, progress: 1, payload: action.payload}})
            return({...state, getClient: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getClient: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLIENT_GET_REJECTED': {
        return({...state, getClient: {progress: 2, payload: action.payload}})
    }

    case 'CLIENT_STATUS_PENDING': {
      return({...state, statusClient: {progress: 0}})
    }
    case 'CLIENT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusClient: {...api.statusClient.response, progress: 1, payload: action.payload}})
            return({...state, statusClient: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusClient: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLIENT_STATUS_REJECTED': {
        return({...state, statusClient: {progress: 2, payload: action.payload}})
    }

    case 'CLIENT_CREATE_PENDING': {
      return({...state, createClient: {progress: 0}})
    }
    case 'CLIENT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createClient: {...api.createClient.response, progress: 1, payload: action.payload}})
            return({...state, createClient: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createClient: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLIENT_CREATE_REJECTED': {
        return({...state, createClient: {progress: 2, payload: action.payload}})
    }

    case 'CLIENT_UPDATE_PENDING': {
      return({...state, updateClient: {progress: 0}})
    }
    case 'CLIENT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateClient: {...api.updateClient.response, progress: 1, payload: action.payload}})
            return({...state, updateClient: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateClient: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLIENT_UPDATE_REJECTED': {
        return({...state, updateClient: {progress: 2, payload: action.payload}})
    }

    case 'CLIENT_REMOVE_PENDING': {
      return({...state, removeClient: {progress: 0}})
    }
    case 'CLIENT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeClient: {...api.removeClient.response, progress: 1, payload: action.payload}})
            return({...state, removeClient: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeClient: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLIENT_REMOVE_REJECTED': {
        return({...state, removeClient: {progress: 2, payload: action.payload}})
    }

    case 'CLIENT_REALM_PENDING': {
      return({...state, realmClient: {progress: 0}})
    }
    case 'CLIENT_REALM_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, realmClient: {...api.realmClient.response, progress: 1, payload: action.payload}})
            return({...state, realmClient: {progress: 2, payload: action.payload}})
        } else {
            localStorage.setItem('tokenAuth', (action.payload.data.data.tokenAuth==undefined ? '' : action.payload.data.data.tokenAuth))
            return({...state, realmClient: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CLIENT_REALM_REJECTED': {
        return({...state, realmClient: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}