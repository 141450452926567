import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label } from 'semantic-ui-react'

import { Link } from 'react-router-dom'
import {withRouter} from "react-router-dom"

import { connect } from "react-redux"
import { signOut } from "../../actions/auth/authAction"
import { socketStatus, socketAgentStatus, socketAgentStatistic, socketAgentNotification } from "../../actions/socket/socketAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,

    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut:(objParam) => dispatch(signOut(objParam)),

    socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
    socketAgentStatistic:(objParam) => dispatch(socketAgentStatistic(objParam)),
    socketAgentNotification:(objParam) => dispatch(socketAgentNotification(objParam)),
  }
}

const stylePopup = {
  borderRadius: '0',
  opacity: 1,
  padding: '.5em .8em',
  fontSize: '1em',
  background: 'rgb(122 223 255)',
  color: '#000'
}
const styleButton={
  margin: '.3em 0em',
  border: 'none',
}

class AgentHeader extends Component {
  constructor(props) {
    super(props);
    this.state={
      openDrawer: false
    }
  }

  openDrawer() {
    this.props.openDrawer()
  }

  doSignOut(values) {
    this.props.signOut(values)
    this.props.history.push('/signin')
  }

  openWindow(value) {
    this.props.openWindow(value)
    this.setState({
      
    })
  }

  componentDidMount() {
    const tokenAuth= localStorage.getItem('tokenAuth');
    if (tokenAuth==undefined || tokenAuth=='') {
      this.props.history.push('/signin')
    }
  }

  componentDidUpdate() {
    if (localStorage.getItem('tokenAuth')=='') {
      this.props.history.push('/signin');
    }
  }
  
  render() {
    const { screenWidth,  screenHeight, mini }= this.props
    const socketStatus= (this.props.socketStore.socketStatus!==undefined ? this.props.socketStore.socketStatus.data : undefined)
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data.payload.data : undefined)
    const agentStatistic= (this.props.socketStore.socketAgentStatistic!==undefined ? this.props.socketStore.socketAgentStatistic.data : undefined)
    
    const { status }= socketStatus && socketStatus.isConnected && agentStatus && agentStatus.status ? {status: agentStatus.status||'connect'} : agentStatus && agentStatus.status ? {status: 'NO ACCESS'} : {status: 'SERVER UNREACHABLE'};
    // console.log('!!! AGENT HEADER', agentStatus)
    // console.log('!!! AGENT HEADER SOCKET', socketStatus)

    return (
      <div style={{marginLeft: '5em'}}>
        <Menu fixed='top'  style={{padding: '0', border: 'none', boxShadow: 'none', background : 'none'}}>
          <div style={{display: 'flex', width: '100%', background : 'rgb(0, 0, 0)'}}>
            {/* <div style={{padding: '.3em 0', textAlign: 'center'}}>
              <Label as='a' style={{border: 'none', background: 'none', padding: '0 .75em', border: '0px solid rgba(100, 100, 100, .3)'}} onClick={this.openWindow.bind(this,'profile')}>
                <Icon size='tiny' name='user secret' style={{fontSize: '1.3em', margin: '0'}} color='grey' circular inverted/>
              </Label>
            </div> */}
            <div style={{padding: '.3em 0 0 0em'}}>
              <Label 
                style={{padding: '.5em 2em .5em .5em', borderRadius: '0 5em 5em 0'}} 
                color={
                (status=='connect' && 'blue') ||
                (status=='callback' && 'orange') ||
                (status=='online' && 'red') ||
                (status=='occupied' && 'red') ||
                (status=='predialed' && 'orange') ||
                (status=='locked' && 'red') || 'red'} >
                <Header as='h5' inverted style={{margin: '0', padding: '0'}}>
                  <Icon name={
                    (status=='connect' && 'plug') ||
                    (status=='callback' && 'phone volume') ||
                    (status=='online' && 'microphone') ||
                    (status=='occupied' && 'phone') ||
                    (status=='predialed' && 'grid layout') ||
                    (status=='locked' && 'lock') || 'warning'
                  } style={{fontSize: '1em', paddingRight: '.3em'}}/>
                  <Header.Content style={{fontWeight: 'bold'}}>
                    <span style={{textTransform: 'capitalize'}}>{(status=='connect' && 'connected') || status}</span> / {agentStatus && agentStatus.mode && agentStatus.mode.toUpperCase()}
                  </Header.Content>
                </Header>
              </Label>
            </div>
            <div style={{flex: '1', textAlign: 'right', paddingTop: '.25em', marginRight: '1em'}}>
            
              {/* <Popup style={stylePopup}
                trigger={<Button onClick={this.openWindow.bind(this,'profile')} basic={this.props.mode!='profile'} color={this.props.mode=='profile'? 'yellow' : null} style={styleButton} icon><Icon name='user outline' style={{color: this.props.mode=='profile' ? '#000' : '#fff'}}/></Button>}
                content='My Account'
                inverted
                on={['hover']}
              />
              <Popup style={stylePopup}
                trigger={<Button onClick={this.openWindow.bind(this,'message')} basic={this.props.mode!='message'} color={this.props.mode=='message'? 'yellow' : null} style={styleButton} icon><Icon name='bell' style={{color: this.props.mode=='message' ? '#000' : '#fff'}} /></Button>}
                content='Notification'
                inverted
                on={['hover']}
              /> */}
              <Popup style={stylePopup}
                trigger={<Button onClick={()=>this.doSignOut()} color={this.props.mode=='signout'? 'yellow' : null} style={{...styleButton, marginLeft: '3em', background: '#444'}} icon><Icon name='sign-out' style={{color: this.props.mode=='signout' ? '#000' : '#f61'}} /></Button>}
                content='Signout'
                inverted
                on={['hover']}
              />
            </div>
          </div>
        </Menu>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgentHeader))