import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'

import ModalContainer from '../../../libraries/common/ModalContainer';

import ReportingSearch from '../../../libraries/stream/ReportingSearch';
import ReportingProcess from '../../../libraries/stream/ReportingProcess';
import ReportingDownload from '../../../libraries/stream/ReportingDownload';
import ReportingForm from '../../../libraries/stream/ReportingForm';


class ReportingBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        reportId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, reportId: id})
    }

    closeModal() {
      this.setState({mode: 1})
      this.props.closeModal();
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return( 
        <div>
          <ReportingSearch setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
          <ReportingDownload setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
          {mode==3 && <div>
              <ModalContainer size='small' content={<ReportingForm screenWidth={screenWidth} screenHeight={screenHeight} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />
          </div>}
        </div>
      )
    }
}

export default ReportingBody
