import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import CampaignBrowse from '../../libraries/realm/stream/CampaignBrowse'
import CampaignForm from '../../libraries/realm/stream/CampaignForm'
import CampaignSearch from '../../libraries/realm/stream/CampaignSearch'


class CampaignBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        campaignId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, campaignId: id})
    }

    render() {
      const {mode, campaignId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
            <CampaignSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <CampaignBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} params={this.props.params} />}
              {mode==2 && <CampaignForm mode={mode} campaignId={campaignId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <CampaignForm mode={mode} campaignId={0} setMode={this.setMode.bind(this)} />}
            </div>
        </Container>
      )
    }
}

export default CampaignBody
