import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import MenuHorizontal from '../../../libraries/common/MenuHorizontal';

import AppointmentBody from './AppointmentBody'
import CampaignBody from './CampaignBody'
// import TargetBody from './TargetBody'
// import AssignBody from './AssignBody'


const options=(module)=>{
  if (module=='inbound') {
    return(
      [
        {
          key: '1',
          icon: 'calendar check outline',
          caption: 'Campaign',
          hint: {
            header: 'Campaign Setup',
            content: 'Setup your campaign based on your schedule, allocated agent and contact targets.',
          }
        },
        {
          key: '4',
          icon: 'bell outline',
          caption: 'Appointment',
        },
      ]
    )
  } else {
    return(
      [
        {
          key: '1',
          icon: 'calendar check outline',
          caption: 'Campaign',
          hint: {
            header: 'Campaign Setup',
            content: 'Setup your campaign based on your schedule, allocated agent and contact targets.',
          }
        },
        {
          key: '2',
          icon: 'user circle',
          caption: 'All Targets',
          hint: {
            header: 'Contact Target',
            content: 'All campaigns target is available and manageable through this, filtered your target based on campaign and contact for your convinience.',
          }
        },
        {
          key: '3',
          icon: 'bookmark outline',
          caption: 'Assignment',
        },
        {
          key: '4',
          icon: 'bell outline',
          caption: 'Appointment',
        },
      ]
    )
  }
} 

class EventBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: props.module=='inbound' ? 1 : 1, //1: browse, 2: edit, 3: insert, 4: delete
        // dashboardId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <Container fluid>
          <div style={{margin: '0em 1em 0 1em'}}>
            <MenuHorizontal onClick={this.setMode.bind(this)} options= {options(module)} color='red'/>
          </div>
          <div>
            {mode==1 && <CampaignBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} params={this.props.params} />}
            {/* {mode==2 && <TargetBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} params={this.props.params} />}
            {mode==3 && <AssignBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />} */}
            {mode==4 && <AppointmentBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
          </div>
        </Container>
      )
    }
}

export default EventBody
