export default function backendReducer (state = {}, action) {
  switch (action.type) {
    case 'BACKEND_RESET': {
        return({
            ...state,
            getBackend: {},
            updateBackend: {},
            createBackend: {},
        })
    }

    case 'BACKEND_GET_PENDING': {
        return({...state, getBackend: {progress: 0}})
    }
    case 'BACKEND_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getBackend: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getBackend: {...action.payload.data, data: action.payload.data.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BACKEND_GET_REJECTED': {
        return({...state, getBackend: {progress: 2, payload: action.payload}})
    }

    case 'BACKEND_STATUS_PENDING': {
      return({...state, statusBackend: {progress: 0}})
    }
    case 'BACKEND_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusBackend: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusBackend: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BACKEND_STATUS_REJECTED': {
        return({...state, statusBackend: {progress: 2, payload: action.payload}})
    }

    case 'BACKEND_BROWSE_PENDING': {
      return({...state, browseBackend: {progress: 0}})
    }
    case 'BACKEND_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseBackend: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseBackend: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BACKEND_BROWSE_REJECTED': {
        return({...state, browseBackend: {progress: 2, payload: action.payload}})
    }

    case 'BACKEND_CREATE_PENDING': {
      return({...state, browseBackend: {progress: 0}})
    }
    case 'BACKEND_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createBackend: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createBackend: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BACKEND_CREATE_REJECTED': {
        return({...state, createBackend: {progress: 2, payload: action.payload}})
    }

    case 'BACKEND_UPDATE_PENDING': {
      return({...state, updateBackend: {progress: 0}})
    }
    case 'BACKEND_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateBackend: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateBackend: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BACKEND_UPDATE_REJECTED': {
        return({...state, updateBackend: {progress: 2, payload: action.payload}})
    }

    case 'BACKEND_REMOVE_PENDING': {
      return({...state, removeBackend: {progress: 0}})
    }
    case 'BACKEND_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeBackend: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeBackend: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BACKEND_REMOVE_REJECTED': {
        return({...state, removeBackend: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}