import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';


import MenuLeft from '../../libraries/agent/MenuLeft'

import DashboardBody from './DashboardBody'
import TransactBody from './TransactBody';
import ConversationBody from './ConversationBody';
import StatisticBody from './StatisticBody'

import AppointmentBody from './AppointmentBody'
import TargetBody from './TargetBody'

import ProfileBody from './ProfileBody'
import MessageBody from './MessageBody'
import SettingBody from './SettingBody'

import MessagingWidget from '../../libraries/agent/MessagingWidget'
import Omniphone from '../../libraries/omniphone/Omniphone';
import AssignmentWidget from '../../libraries/widget/AssignmentWidget';


import { connect } from "react-redux"
import { accountOmniphone, contactOmniphone } from "../../actions/stream/omniphoneAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    accountOmniphone:(objParam) => dispatch(accountOmniphone(objParam)),
    contactOmniphone:(objParam) => dispatch(contactOmniphone(objParam)),
  }
}

class AgentBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      homeMode: 'dashboard'
    }
  }

  componentDidMount() {
    this.props.accountOmniphone();
    navigator.mediaDevices.getUserMedia({ audio: true, video: false });
  }

  componentDidUpdate() {
    const { mode }= this.props

    if ((mode=='dashboard' || mode=='conversation' || mode=='transact' || mode=='statistic' || mode==='appointment' || mode==='target' || mode==='collection' || mode==='restructuring' || mode==='telesale' || mode==='verification'
    ) && this.state.homeMode!=mode) {
      this.setState({
        homeMode: mode
      })
    }
  }

  closeModal() {
    const { homeMode }= this.state
    this.props.openWindow(homeMode)
  }
  
  openWindow(mode) {
    if (mode=='dashboard' || mode=='conversation' || mode=='transact' || mode=='statistic' || mode==='appointment' || mode==='target' || mode==='collection' || mode==='restructuring' || mode==='telesale' || mode==='verification'
     ) {
      this.setState({
        mode: mode,
        homeMode: mode
      })
    }
    this.props.openWindow(mode)
  }


  render() {
    const { homeMode }=this.state
    const { mode, screenWidth, screenHeight, mini }= this.props
    const { data }= (this.props.omniphoneStore.accountOmniphone!==undefined ? this.props.omniphoneStore.accountOmniphone : {})

    console.log(homeMode, mode)
    return (
      <div style={{marginTop: '3em'}}>
        <div style={{position: 'relative', zIndex: '999'}}>
          <div style={{display: 'flex', flexDirection: mode!='dashboard' ? 'row' : 'column', padding: '0', marginTop: '.5em'}}>
            <div style={{flex: '1'}}>
              {/* <MessagingWidget /> */}
              {/* <OmniphoneWidget /> */}
              {data!==undefined && data.voipAccount!==undefined &&
                // <Omniphone
                // mode='mini'
                // sipAccount='7000000001' 
                // sipPasswd='111111'
                // sipHost='call.halopagi.com:4063' 
                // sipRealm='halopagi'/>
                <Omniphone
                responsive
                agent={{name: data.name, avatar: data.avatar, initial: data.name.split(' ')[0][0]+ (data.name.split(' ')[1] ? data.name.split(' ')[1][0] : '')}}
                ui='mini'
                sipAccount={data.voipAccount}
                sipPasswd={data.voipPasswd}
                sipHost={data.voipHost +':'+ data.voipPort}
                sipRealm={data.voipRealm}/>
              }
            </div>
            {/* <div style={{}}>
              <AssignmentWidget tiny={mode!='dashboard'} openWindow={this.openWindow.bind(this)} responsive screenWidth={screenWidth} screenHeight={screenHeight} />
            </div> */}
          </div>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{background: '#ccc', minHeight: screenHeight-300, borderRight: '1px solid #fff'}}>
            <MenuLeft openWindow={this.openWindow.bind(this)} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} mini={mini}/>
          </div>
          <div style={{flex: '1', marginLeft: mini ? '2px' : '80px', marginRight: '2px'}}>
              <div style={{flex: '1'}}>
                <div>
                  <DashboardBody data={data} openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} style={{display: (mode==='dashboard') ? 'block' : 'none'}} />
                  {/* <ConversationBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} style={{display: (mode==='conversation') ? 'block' : 'none'}} /> */}
                  <TargetBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} style={{display: (mode==='target') ? 'block' : 'none'}} />
                  <TransactBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} style={{display: (mode==='transact') ? 'block' : 'none'}} />
                  <StatisticBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} style={{display: (mode==='statistic') ? 'block' : 'none'}} />

                  {(homeMode=='appointment' || mode==='appointment') && <AppointmentBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
                </div>

                <div style={{background: '#f7f7f7', padding: '.5em'}}>
                  {/* <NotificationWidget /> */}
                  {/* <div style={{marginTop: '2em'}}>
                    {this.props.mode=='verification' && <VerificationWidget />}
                    {this.props.mode=='appointment' && <AppointmentWidget />}
                  </div> */}
                </div>

                {mode==='profile' && <ModalContainer size='small' content={<ProfileBody screenWidth={screenWidth} />} closeModal={this.closeModal.bind(this)} /> }
                {mode==='message' && <ModalContainer size='small' content={<MessageBody screenWidth={screenWidth} />} closeModal={this.closeModal.bind(this)} /> }
                {mode==='setting' && <ModalContainer size='large' content={<SettingBody screenWidth={screenWidth} />} closeModal={this.closeModal.bind(this)} /> }
              </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentBody)