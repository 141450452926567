import React,{Component} from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  ModalDescription,
  Modal,
  Progress,
} from 'semantic-ui-react'

import JsSIP from 'jssip'

import dialtone from '../../sound/tone.mp3'
import busytone from '../../sound/busy.mp3'
import ringtone from '../../sound/ring.mp3'
import jointone from '../../sound/join.mp3'

// import SpyphoneHeader from './SpyphoneHeader';
// import SpyphoneFooter from './SpyphoneFooter';

import SpyphoneOncall from './SpyphoneOncall';
import SpyphoneOnline from './SpyphoneOnline';
import SpyphoneOffline from './SpyphoneOffline';
import SpyphoneIncoming from './SpyphoneIncoming';

import { connect } from "react-redux"
import { socketStatus, socketAgentStatus } from "../../actions/socket/socketAction"

const callOptions = {
  extraHeaders: [],
  mediaConstraints : { audio: true, video: false }
}

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
  }
}

var ua;
var session;
const dial= new Audio(dialtone);
const busy= new Audio(busytone);
const ring= new Audio(ringtone);
const join= new Audio(jointone);


class DialTone {
  play() {
    dial.loop= true
    dial.muted= false;
    dial.play();  
  }
  
  stop() {
    dial.pause();
  }

  mute() {
    dial.muted= true;
  }
}

class BusyTone {
  play() {
    busy.muted= false;
    busy.play();
  }
  
  stop() {
    busy.pause();
  }

  mute() {
    busy.muted= true;
  }
}

class RingTone {
  play() {
    ring.loop= true;
    ring.muted= false;
    ring.play();  
  }
  
  stop() {
    ring.muted= true;
    ring.pause();
  }
}

class JoinTone {
  play() {
    join.loop= false;
    join.muted= false;
    join.play();  
  }
  
  stop() {
    join.muted= true;
    join.pause();
  }
}

class Spyphone extends Component {
  constructor(props){
    super(props)
    this.state = {
      // sipAccount: '1001',
      // sipPasswd: '1001@d4nk0m2020',
      // sipUri: 'sip:?@174.138.19.28:4063', DISCARD
      // sipHost: '174.138.19.28:4061/', //ws://174.138.19.28:4061/
      // sipHost: 'call.halopagi.com:4063/', //wss://call.halopagi.com:4063/
      // sipRealm: 'halopagi',

      init: false,
      uiMode: [],
      statusUA: 0, //0: disconnected, 1: connecting, 2: connected, 3: registered, 4: no access / account not valid / FAILED
      dialMode: false,
      phoneMode: 'offline', //offline, registered, online, occupied
      phoneState: 'disconnected', //disconnected, connecting, connected, registered, incoming, online, dialing, occupied, 
      text: null,

      layout: 'mini', //'mini', 'normal', 'floating'
      ringDuration: 5,
    }

    this.dial= new DialTone();
    this.busy= new BusyTone();
    this.ring= new RingTone();
    this.join= new JoinTone();
  }

  setSkinMode(layout) {
    this.setState({
      layout: layout
    })
  }
  
  sipRegister(account, passwd, host, realm) {
    var sipAccount, sipPasswd, sipHost, sipRealm;

    if (account!=null && passwd!=null && host!=null && realm!=null) {
      sipAccount= account
      sipPasswd= passwd
      sipHost= host
      sipRealm= realm
    } else {
      sipAccount= this.state.sipAccount
      sipPasswd= this.state.sipPasswd
      sipHost= this.state.sipHost
      sipRealm= this.state.sipRealm
    }

    this.setState({
      uiMode: [],
      statusUA: 1,
      phoneMode: 'offline',
    })
    
    var socket = new JsSIP.WebSocketInterface("wss://"+ sipHost +"/");
    socket.via_transport = "udp";
    // console.log(socket)
    // this.socketEventHandler(socket)

    var configuration = {
      sockets  : [ socket ],
      uri      : "sip:"+ sipAccount +"@"+sipHost,
      password : sipPasswd,
      session_timers: false,
      iceServers: [],
      use_preloaded_route: true,
    };
    
    if (ua!=undefined) {
      ua.stop();
    } 

    ua = new JsSIP.UA(configuration);
    // console.log('!!! UA', ua)
    
    ua.start();
    this.sipEventHandler(ua)
  }

  sipUnregister() {
    // console.log('!!! UA', ua.isRegistered(), ua.isConnected(), ua)
    if (ua!=undefined) {
      // console.log('!!! UA', 'STOP')
      ua.stop()
    }
  }

  socketEventHandler(socket) {
    // socket.on('onconnect', function () {
    //   // console.log('!!! SOCKET ON CONNECT: ');
    // });
    // socket.on('ondisconnect', function (error, code, reason) {
    //   // console.log('!!! SOCKET ON DISCONNECT: ', error);
    // });
    // socket.on('ondata', function (data) {
    //   // console.log('!!! SOCKET ON DATA: ', data);
    // });
  }

  sipEventHandler(ua) {
    const parent= this;

    //CONNECTION EVENT
    ua.on("connecting", function(data){
      // console.log("!!! CONNECTING", data);
      parent.setState({
        statusUA: 1,
        phoneState: 'connecting',
      })
    });
    ua.on("connected", function(data){
      // console.log("!!! CONNECTED", data);
      parent.setState({
        statusUA: 2,
        phoneState: 'connected',
      })
    });
    ua.on("disconnected", function(data){
      // console.log("!!! DISCONNECTED", data);
      parent.setState({
        statusUA: 0,
        phoneState: 'disconnected',
        phoneMode: 'offline',
      })
    });
    
    //CALL EVENT
    ua.on("newRTCSession", function(data){
      // console.log("!!! RTC SESSION EVENT ", data.session);
      // incoming call here
      session= data.session;

      if (session.direction === "incoming") {
        // console.log("!!! RTC SESSION INCOMING ");
        
        session.on("progress",function(e){
          // the call has answered
          // console.log("!!! RTC PROGRESS");
          parent.setState({
            phoneState: 'incoming',
          })
          parent.ring.play()
          session.answer(callOptions);
          parent.callMute(true);
        });
        session.on("accepted",function(e){
            // the call has answered
            // console.log("!!! RTC ACCEPTED");
            parent.setState({
              phoneMode: 'oncall',
              phoneState: 'online',
            })
        });
        session.on("confirmed",function(){
            // this handler will be called for incoming calls too
            // console.log("!!! RTC CONFIRMED");
            parent.setState({
              phoneMode: 'oncall',
              phoneState: 'online',
            })
            parent.ring.stop();
        });
        session.on("peerconnection", function(e) {
          // console.log('!!! CALL PEERCONNECTION: ', e)
          session.connection.onaddstream = function(e) {
            // console.log("!!! CALL ADD STREAM");
            var remoteAudio = new window.Audio()
            remoteAudio.srcObject = e.stream
            remoteAudio.play();
          }
        });
        session.on("icecandidate", function(e) {
          // console.log('!!! CALL ICE CANDIDATE: ', e)
          // n(e.candidate.candidate);
          // if (e.Last) {
          //   e.ready();
          // }
        });
        session.on("sdp", function(e) {
          // console.log('!!! CALL SDP: ', e)
        });
        session.on("newInfo", function(e) {
          // console.log('!!! CALL INFO: ', e)
        });
        session.on("newDTMF", function(e) {
          // console.log('!!! CALL DTMF: ', e)
        });
        session.on("failed",function(e){
            // unable to establish the call
            // console.log("!!! RTC FAILED", e);
            parent.setState({
              phoneMode: 'registered',
              // phoneState: 'registered',
            })
            parent.ring.stop();
        });
        session.on("ended",function(){
            // the call has ended
            // console.log("!!! RTC ENDED");
            parent.setState({
              phoneMode: 'registered',
              phoneState: 'registered',
            })
            parent.ring.stop();
            parent.busy.play()
        });
      } else if (session.direction === "outgoing") {
        // console.log("!!! RTC SESSION OUTGOING ");
        session.connection.onaddstream = function(e) {
          // console.log("!!! CALL ADD STREAM");
          var remoteAudio = new window.Audio()
          remoteAudio.srcObject = e.stream
          remoteAudio.play();
        }
      }
    });

    //MESSAGE EVENT
    ua.on("newMessage", function(data){
      // console.log("!!! TEXTING EVENT", data);
      if (data.request=='OutgoingRequest') { //OUTGOING

      } else { //INCOMING

      }
    });

    //SIP EVENT
    ua.on("sipEvent", function(data){
      // console.log("!!! SIP EVENT", data);
    });

    //REGISTERED EVENT
    ua.on("registered", function(){
      // console.log("!!! REGISTERED");
      parent.setState({
        statusUA: 3,
        phoneMode: 'registered',
      })
    });
    ua.on("unregistered", function(data){
      // console.log("!!! UNREGISTERED", data);
      parent.setState({
        statusUA: 0,
        phoneMode: 'offline',
      })
    });
    ua.on("registrationFailed", function(data){
      // console.log("!!! REGISTRATION FAILED", data);
      parent.setState({
        statusUA: 4,
        phoneMode: 'offline',
        phoneState: 'disconnected',
      })
    });
  }

  callAnswer() {
    if (session.isInProgress()) {
      session.answer(callOptions);
    }
  }

  callMute(v) {
    if (session && v) {
      session.mute({audio: true});
    } else if(session) {
      session.unmute({audio: true});
    }
  }

  callHangup() {
    let i= 0;
    try {
      session.terminate();
      this.setState({
        phoneMode: 'registered',
        phoneState: 'registered',
      })
    } catch(err) {
      this.setState({
        phoneMode: 'registered',
        phoneState: 'registered',
      })
    }
  }
  
  componentDidMount() {
    const {sipAccount, sipPasswd,sipHost,sipRealm }= this.props 
    this.setState({
      init: true,
      sipAccount: sipAccount,
      sipPasswd: sipPasswd, 
      sipHost: sipHost, 
      sipRealm: sipRealm,
    })

    this.sipRegister(sipAccount, sipPasswd, sipHost, sipRealm);
  }

  componentWillUnmount() {
    this.sipUnregister();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.spying!=this.props.spying && this.props.spying=='stop') {
      this.callHangup();
    }
    if (prevProps.spying!=this.props.spying && this.props.spying=='start') {
      this.callMute(false);
    }
    if (prevProps.spying!=this.props.spying && this.props.spying=='stop') {
      this.callMute(true);
    }
  }

  render() {
    const { layout, windowPos, uiMode, sipAccount, phoneMode, phoneState, dialMode, statusUA }= this.state;
    const { screenWidth, screenHeight, responsive }= this.props

    // console.log('!!!! WEBPHONE', phoneMode, phoneState)

    return (
      <div style={{background: '#444', margin: '-.5em'}}>
        {(phoneState=='registered' || phoneState=='connected') &&
        <SpyphoneOnline />}

        {phoneState=='incoming' &&
        <SpyphoneIncoming mode={phoneMode} callAnswer={this.callAnswer.bind(this)} callHangup={this.callHangup.bind(this)}  layout={layout} />}

        {phoneState=='online' && 
        <SpyphoneOncall mode={phoneMode} callHangup={this.callHangup.bind(this)}  layout={layout} status={phoneMode} />}

        {(phoneMode=='offline' || phoneState=='disconnected') && 
        <SpyphoneOffline mode={phoneMode} statusUA={statusUA} layout={layout} />}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Spyphone)