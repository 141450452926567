export default function bridgingReducer (state = {}, action) {
  switch (action.type) {
    
    case 'BRIDGING_UPDATE_PENDING': {
      return({...state, updateBridging: {progress: 0, start: new Date().getTime()}})
    }
    case 'BRIDGING_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateBridging: {...api.updateBridging.response, progress: 1, payload: action.payload}})
            return({...state, updateBridging: {progress: 2, payload: action.payload, finish: new Date().getTime(), start: state.updateBridging.start}})
        } else {
            return({...state, updateBridging: {...action.payload.data, progress: 1, payload: action.payload, finish: new Date().getTime(), start: state.updateBridging.start}})
        }
    }
    case 'BRIDGING_UPDATE_REJECTED': {
        return({...state, updateBridging: {progress: 2, payload: action.payload, finish: new Date().getTime(), start: state.updateBridging.start}})
    }

    default: {
      return state
    }
  }
}