export default function expertReducer (state = {}, action) {
  switch (action.type) {
    case 'SCRIPT_RESET': {
        return({
            ...state,
            getScript: {},
            updateScript: {},
            createScript: {},
        })
    }

    case 'SCRIPT_GET_PENDING': {
        return({...state, getScript: {progress: 0}})
    }
    case 'SCRIPT_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getScript: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getScript: {...action.payload.data, data: action.payload.data.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SCRIPT_GET_REJECTED': {
        return({...state, getScript: {progress: 2, payload: action.payload}})
    }

    case 'SCRIPT_STATUS_PENDING': {
      return({...state, statusScript: {progress: 0}})
    }
    case 'SCRIPT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusScript: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusScript: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SCRIPT_STATUS_REJECTED': {
        return({...state, statusScript: {progress: 2, payload: action.payload}})
    }

    case 'SCRIPT_BROWSE_PENDING': {
      return({...state, browseScript: {progress: 0}})
    }
    case 'SCRIPT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseScript: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseScript: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SCRIPT_BROWSE_REJECTED': {
        return({...state, browseScript: {progress: 2, payload: action.payload}})
    }

    case 'SCRIPT_CREATE_PENDING': {
      return({...state, browseScript: {progress: 0}})
    }
    case 'SCRIPT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createScript: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createScript: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SCRIPT_CREATE_REJECTED': {
        return({...state, createScript: {progress: 2, payload: action.payload}})
    }

    case 'SCRIPT_UPDATE_PENDING': {
      return({...state, updateScript: {progress: 0}})
    }
    case 'SCRIPT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateScript: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateScript: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SCRIPT_UPDATE_REJECTED': {
        return({...state, updateScript: {progress: 2, payload: action.payload}})
    }

    case 'SCRIPT_REMOVE_PENDING': {
      return({...state, removeScript: {progress: 0}})
    }
    case 'SCRIPT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeScript: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeScript: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SCRIPT_REMOVE_REJECTED': {
        return({...state, removeScript: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}