import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed } from 'semantic-ui-react'

import LineRechart from '../chart/LineRechart';

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus, socketSystemHeartbeat } from "../../actions/socket/socketAction"
import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketSystemHeartbeat:(objParam) => dispatch(socketSystemHeartbeat(objParam)),
  }
}

class HeartbeatWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      stamp: '',
      barData: [
        // {name: '05:02', svcApi: 1, svcCrm: 5, svcCallback: 15, svcLoader: 1, svcReporting: 5, svcStatistic: 3, svcWatchdog: 3}, 
        // {name: '05:03', svcApi: 2, svcCrm: 6, svcCallback: 9, svcLoader: 1, svcReporting: 5, svcStatistic: 3, svcWatchdog: 3}, 
        // {name: '05:04', svcApi: 3, svcCrm: 7, svcCallback: 5, svcLoader: 1, svcReporting: 5, svcStatistic: 3, svcWatchdog: 3}, 
        // {name: '05:05', svcApi: 4, svcCrm: 8, svcCallback: 7, svcLoader: 1, svcReporting: 5, svcStatistic: 3, svcWatchdog: 3}, 
      ],
      barLegend: [
        {id: 'svcApi', name: 'API'},
        {id: 'svcCrm', name: 'CRM'},
        {id: 'svcCallback', name: 'Callback'},
        {id: 'svcLoader', name: 'Callback'},
        {id: 'svcReporting', name: 'Reporting'},
        {id: 'svcStatistic', name: 'Statistic'}, 
        {id: 'svcWatchdog', name: 'Watchdog'},
      ]
    };
  }

  componentDidUpdate() {
    const { barData, stamp}= this.state
    const { data }= this.props.socketStore.socketSystemHeartbeat!=undefined ? this.props.socketStore.socketSystemHeartbeat: {}
    const { period, service, traffic }= (data && data.payload && data.payload.data) || {}

    // console.log(data)
    if (data && period && stamp!=period.substring(11,16)) {
      if (barData.length>10) {
        this.setState({
          barData: [...barData.slice(1), {name: period.substring(11,16), ...service}],
          stamp: period.substring(11,16),
        })
      } else {
        this.setState({
          barData: [...barData, {name: period.substring(11,16), ...service}],
          stamp: period.substring(11,16),
        })
      }
    }
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const { barData, barLegend}= this.state
    const { data, search, paging }= (this.props.socketStore.socketSystemHeartbeat!==undefined ? this.props.socketStore.socketSystemHeartbeat : {})
    
    // console.log(barData);
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart bar' style={{fontSize: '1em'}} />
          <Header.Content>
            Heartbeat Monitoring
            <Header.Subheader>System availability and heartbeat detection.</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em'}}>
        {data!=undefined &&
        <LineRechart mode='tiny' height={450} yAxis={false} dashed={true} label={true} data={barData} legend={barLegend}/>
        }
      </Segment>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HeartbeatWidget)