export default function interactionReducer (state = {}, action) {
  switch (action.type) {
    case 'SOCMED_INTERACTION_RESET': {
        return({
            ...state,
            getInteraction: {},
            updateInteraction: {},
            createInteraction: {},
        })
    }

    case 'SOCMED_INTERACTION_BROWSE_PENDING': {
      return({...state, browseInteraction: {progress: 0}})
    }
    case 'SOCMED_INTERACTION_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseInteraction: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseInteraction: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_INTERACTION_BROWSE_REJECTED': {
        return({...state, browseInteraction: {progress: 2, payload: action.payload}})
    }
    
    case 'SOCMED_INTERACTION_GET_PENDING': {
        return({...state, getInteraction: {progress: 0}})
    }
    case 'SOCMED_INTERACTION_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getInteraction: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getInteraction: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_INTERACTION_GET_REJECTED': {
        return({...state, getInteraction: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_INTERACTION_STATUS_PENDING': {
      return({...state, statusInteraction: {progress: 0}})
    }
    case 'SOCMED_INTERACTION_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusInteraction: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusInteraction: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_INTERACTION_STATUS_REJECTED': {
        return({...state, statusInteraction: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_INTERACTION_CREATE_PENDING': {
      return({...state, createInteraction: {progress: 0}})
    }
    case 'SOCMED_INTERACTION_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createInteraction: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createInteraction: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_INTERACTION_CREATE_REJECTED': {
        return({...state, createInteraction: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_INTERACTION_UPDATE_PENDING': {
      return({...state, updateInteraction: {progress: 0}})
    }
    case 'SOCMED_INTERACTION_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateInteraction: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateInteraction: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_INTERACTION_UPDATE_REJECTED': {
        return({...state, updateInteraction: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_INTERACTION_REMOVE_PENDING': {
      return({...state, removeInteraction: {progress: 0}})
    }
    case 'SOCMED_INTERACTION_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeInteraction: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeInteraction: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_INTERACTION_REMOVE_REJECTED': {
        return({...state, removeInteraction: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}