import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'


import IdleWidget from '../../libraries/widget/IdleWidget'
import NotificationWidget from '../../libraries/widget/NotificationWidget'
import ConversationWidget from '../../libraries/widget/ConversationWidget'

import VerificationWidget from '../../libraries/widget/VerificationWidget'
import AppointmentWidget from '../../libraries/widget/AppointmentWidget'
import SessionWidget from '../../libraries/widget/SessionWidget';


class DashboardBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        // dashboardId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight, style, data}= this.props

      return(
        <Container fluid style={{...style}}>
          <div style={{padding: '1em', marginTop: '.5em'}}>
            <IdleWidget responsive screenWidth={screenWidth} screenHeight={screenHeight} />
          </div>
          
          <div style={{padding: '1em', marginTop: '.5em'}}>
            <SessionWidget responsive screenWidth={screenWidth} screenHeight={screenHeight} />
          </div>
          
          <div style={{padding: '1em', marginTop: '.5em'}}>
            <ConversationWidget mini={true} />
          </div>
        </Container>
      )
    }
}

export default DashboardBody
