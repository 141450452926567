import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetOmniwidget(objParam) {
  return {
      type: 'OMNIWIDGET_RESET',
      payload: objParam
  }
}

export function navigationOmniwidget(objParam) {
  return {
      type: 'OMNIWIDGET_NAVIGATION',
      payload:
        axios.post(
          urlAPI + 'omniwidget/navigation',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function bookOmniwidget(objParam) {
  return {
      type: 'OMNIWIDGET_BOOK',
      payload:
        axios.post(
          urlAPI + 'omniwidget/book',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function rateOmniwidget(objParam) {
  return {
      type: 'OMNIWIDGET_RATE',
      payload:
        axios.post(
          urlAPI + 'omniwidget/rate',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function messageOmniwidget(objParam) {
  return {
    type: 'OMNIWIDGET_MESSAGE',
    payload:
      axios.post(
        urlAPI + 'omniwidget/message',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function variableOmniwidget(objParam) {
  return {
    type: 'OMNIWIDGET_VARIABLE',
    payload:
      axios.post(
        urlAPI + 'omniwidget/variable',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function chatOmniwidget(objParam) {
  return {
    type: 'OMNIWIDGET_CHAT',
    payload:
      axios.post(
        urlAPI + 'omniwidget/chat',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function dialOmniwidget(objParam) {
  return {
    type: 'OMNIWIDGET_DIAL',
    payload:
      axios.post(
        urlAPI + 'omniwidget/dial',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
    ).catch(function(error){ //handle network error, return axios always 1
      // console.log('ERROR: ', error)
    })
  }
}

export function paramOmniwidget(objParam) {
  return {
      type: 'OMNIWIDGET_PARAM',
      payload:
        axios.post(
          urlAPI + 'omniwidget/param',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
