export default function deductReducer (state = {}, action) {
  switch (action.type) {
    case 'DEDUCT_RESET': {
        return({
            ...state,
            getDeduct: {},
            updateDeduct: {},
            createDeduct: {},
        })
    }

    case 'DEDUCT_GET_PENDING': {
        return({...state, getDeduct: {progress: 0}})
    }
    case 'DEDUCT_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getDeduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getDeduct: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DEDUCT_GET_REJECTED': {
        return({...state, getDeduct: {progress: 2, payload: action.payload}})
    }

    case 'DEDUCT_STATUS_PENDING': {
      return({...state, statusDeduct: {progress: 0}})
    }
    case 'DEDUCT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusDeduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusDeduct: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DEDUCT_STATUS_REJECTED': {
        return({...state, statusDeduct: {progress: 2, payload: action.payload}})
    }

    case 'DEDUCT_BROWSE_PENDING': {
      return({...state, browseDeduct: {progress: 0}})
    }
    case 'DEDUCT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseDeduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseDeduct: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DEDUCT_BROWSE_REJECTED': {
        return({...state, browseDeduct: {progress: 2, payload: action.payload}})
    }

    case 'DEDUCT_CREATE_PENDING': {
      return({...state, browseDeduct: {progress: 0}})
    }
    case 'DEDUCT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createDeduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createDeduct: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DEDUCT_CREATE_REJECTED': {
        return({...state, createDeduct: {progress: 2, payload: action.payload}})
    }

    case 'DEDUCT_UPDATE_PENDING': {
      return({...state, updateDeduct: {progress: 0}})
    }
    case 'DEDUCT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateDeduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateDeduct: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DEDUCT_UPDATE_REJECTED': {
        return({...state, updateDeduct: {progress: 2, payload: action.payload}})
    }

    case 'DEDUCT_REMOVE_PENDING': {
      return({...state, removeDeduct: {progress: 0}})
    }
    case 'DEDUCT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeDeduct: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeDeduct: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DEDUCT_REMOVE_REJECTED': {
        return({...state, removeDeduct: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}