import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function statReset(objParam) {
  return {
      type: 'STAT_RESET',
      payload: objParam
  }
}

export function statCampaign(objParam) {
  return {
      type: 'CAMPAIGN_STAT',
      payload:
        axios.post(
          urlAPI + 'statistic/campaign',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statCustomer(objParam) {
  return {
      type: 'CUSTOMER_STAT',
      payload:
        axios.post(
          urlAPI + 'statistic/customer',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statProduct(objParam) {
  return {
      type: 'PRODUCT_STAT',
      payload:
        axios.post(
          urlAPI + 'statistic/product',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statAsset(objParam) {
  return {
      type: 'ASSET_STAT',
      payload:
        axios.post(
          urlAPI + 'statistic/asset',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statAssignment(objParam) {
  return {
      type: 'ASSIGNMENT_STAT',
      payload:
        axios.post(
          urlAPI + 'statistic/assignment',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statEngagement(objParam) {
  return {
      type: 'ENGAGEMENT_STAT',
      payload:
        axios.post(
          urlAPI + 'statistic/engagement',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statConversation(objParam) {
  return {
      type: 'CONVERSATION_STAT',
      payload:
        axios.post(
          urlAPI + 'statistic/conversation',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_STAT',
      payload:
        axios.post(
          urlAPI + 'statistic/middleware',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statWebhook(objParam) {
  return {
      type: 'WEBHOOK_STAT',
      payload:
        axios.post(
          urlAPI + 'statistic/webhook',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statApi(objParam) {
  return {
      type: 'API_STAT',
      payload:
        axios.post(
          urlAPI + 'statistic/api',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statRemark(objParam) {
  return {
      type: 'REMARK_STAT',
      payload:
        axios.post(
          urlAPI + 'statistic/remark',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}