import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetInteraction(objParam) {
  return {
      type: 'INTERACTION_RESET',
      payload: objParam
  }
}

export function getInteraction(objParam) {
  return {
      type: 'INTERACTION_GET',
      payload:
        axios.post(
          urlAPI + 'service/interaction/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusInteraction(objParam) {
  return {
      type: 'INTERACTION_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/interaction/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseInteraction(objParam) {
  return {
      type: 'INTERACTION_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/interaction/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createInteraction(objParam) {
  return {
      type: 'INTERACTION_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/interaction/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateInteraction(objParam) {
  return {
      type: 'INTERACTION_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/interaction/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeInteraction(objParam) {
  return {
      type: 'INTERACTION_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/interaction/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function handlingInteraction(objParam) {
  return {
      type: 'INTERACTION_HANDLING',
      payload:
        axios.post(
          urlAPI + 'service/interaction/handling',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
