export default function reportReducer (state = {}, action) {
  switch (action.type) {
    case 'REPORT_RESET': {
        // console.log('!!! ACTION PAYLOAD', action, state)
        return({
            ...state, 
            getReport: {},
            updateReport: {},
            createReport: {},
        })
    }

    case 'REPORT_BROWSE_PENDING': {
      return({...state, browseReport: {progress: 0}})
    }
    case 'REPORT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseReport: {...api.browseReport.response, progress: 1, payload: action.payload}})
            return({...state, browseReport: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseReport: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORT_BROWSE_REJECTED': {
        return({...state, browseReport: {progress: 2, payload: action.payload}})
    }
    
    case 'REPORT_GET_PENDING': {
        return({...state, getReport: {progress: 0}})
    }
    case 'REPORT_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getReport: {...api.getReport.response, progress: 1, payload: action.payload}})
            return({...state, getReport: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getReport: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORT_GET_REJECTED': {
        return({...state, getReport: {progress: 2, payload: action.payload}})
    }

    case 'REPORT_STATUS_PENDING': {
      return({...state, statusReport: {progress: 0}})
    }
    case 'REPORT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusReport: {...api.statusReport.response, progress: 1, payload: action.payload}})
            return({...state, statusReport: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusReport: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORT_STATUS_REJECTED': {
        return({...state, statusReport: {progress: 2, payload: action.payload}})
    }

    case 'REPORT_CREATE_PENDING': {
      return({...state, createReport: {progress: 0}})
    }
    case 'REPORT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createReport: {...api.createReport.response, progress: 1, payload: action.payload}})
            return({...state, createReport: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createReport: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORT_CREATE_REJECTED': {
        return({...state, createReport: {progress: 2, payload: action.payload}})
    }

    case 'REPORT_UPDATE_PENDING': {
      return({...state, updateReport: {progress: 0}})
    }
    case 'REPORT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateReport: {...api.updateReport.response, progress: 1, payload: action.payload}})
            return({...state, updateReport: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateReport: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORT_UPDATE_REJECTED': {
        return({...state, updateReport: {progress: 2, payload: action.payload}})
    }

    case 'REPORT_REMOVE_PENDING': {
      return({...state, removeReport: {progress: 0}})
    }
    case 'REPORT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeReport: {...api.removeReport.response, progress: 1, payload: action.payload}})
            return({...state, removeReport: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeReport: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'REPORT_REMOVE_REJECTED': {
        return({...state, removeReport: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}