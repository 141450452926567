import React,{Component} from 'react'
import { Container, Segment, Header, Form, Icon, Divider, Button, Image } from 'semantic-ui-react'

import { ReactSVG } from 'react-svg'
import stream_logo  from '../../assets/stream-logo.svg'

import DismissableMessage from '../common/DismissableMessage'
import {withRouter} from "react-router-dom"

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
// import { lang } from '../language/lang'

import { connect } from "react-redux"
import { signIn, resetForm } from "../../actions/auth/authAction"

const WHITELABEL= '-' || 'POJ' || 'MDM';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn:(objParam) => dispatch(signIn(objParam)),
    resetForm:(objParam) => dispatch(resetForm(objParam)),
  }
}

class SigninForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      prevProgress: 0,
    }
    
  }

  componentDidMount() {
    this.props.resetForm("signIn")
  }
  
  resetForm() {
    const { reset } = this.props
    reset()
    //this.props.onClose()
    this.props.resetForm("signIn")
  }

  submitForm(values) {
    // console.log('!!! SIGN IN SUBMIT VALUES: ', values)
    const { reset } = this.props
    reset()
    this.props.signIn(values)
    if (this.props.onSubmit!=undefined) {
      this.props.onSubmit(values)
    }
  }

  componentDidUpdate() {
    const tokenAuth= localStorage.getItem('tokenAuth');
    const { code, text, progress, data }= this.props.authStore.signIn ? this.props.authStore.signIn : {}
    
    if (progress==1 && code!='00') {
      setTimeout(()=>{
        this.props.resetForm("signIn")
      }, 3000)
    } else {
      if (progress!=undefined && progress==1 && data.groupId=='Admin' && tokenAuth!='') {
         setTimeout(()=>{this.props.history.push('/admin')}, 1000)
      } else if (progress!=undefined && progress==1 && data.groupId=='Agent' && tokenAuth!='') {
        setTimeout(()=>{this.props.history.push('/agent')}, 1000)
      } else if (progress!=undefined && progress==1 && data.groupId=='Client' && tokenAuth!='') {
        setTimeout(()=>{this.props.history.push('/realm')}, 1000)
      } else if (progress!=undefined && progress==1 && (data.groupId=='Supervisor' || data.groupId=='Team Leader' || data.groupId=='Leader') && tokenAuth!='') {
        setTimeout(()=>{this.props.history.push('/spv')}, 1000)
      } else if (progress!=undefined && progress==1 && data.groupId=='Emulator' && tokenAuth!='') {
        setTimeout(()=>{this.props.history.push('/emulator')}, 1000)
      } else if (progress!=undefined && progress==1 && data.groupId=='Whatsapp' && tokenAuth!='') {
        setTimeout(()=>{this.props.history.push('/wasap')}, 1000)
      }
    }
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, screenWidth, screenHeight } = this.props
    const { code, text, progress, data }= this.props.authStore.signIn ? this.props.authStore.signIn : {}

    return (
      <div className="signin-form" style={{padding: '0 2em'}}>
        <div style={{width: screenWidth>720 ? '375px' : '100%', margin: 'auto', padding: '3em', border: '1px solid rgba(100,100,100,.2)', borderRadius: '.5em', background: 'rgba(255,255,255, 1)'}}>
          <div style={{textAlign: 'center'}}>
            <div style={{margin: '0 auto', textAlign: 'center'}}>
              {
                (WHITELABEL=='POJ' && <Image src='https://optimajasa.co.id/img/tele-center/call-center-logo.png' style={{width: '18em', display: 'inline-block'}} />) || 
                (WHITELABEL=='MDM' && <Icon name='mobile alternate' style={{fontSize: '3em'}} circular inverted color='red'/>) || 
                <div style={{width: '8em', display: 'inline-block'}}><ReactSVG src={stream_logo} /></div>
              }
              {/* <Divider /> */}
            </div>
            <Header as='h4' style={{margin: '0 0 1em 0'}}>
                { 
                  (WHITELABEL=='MDM' && 
                    <Header.Content>
                      <span style={{fontSize: '1.3em', color: '#874343', fontWeight: '600'}}>V O I C E</span> 
                      <Header.Subheader>Inbound, Outbound, In-App Call and Office Phone.</Header.Subheader>
                    </Header.Content>
                  ) ||
                  ( 
                    <Header.Content>
                      <span style={{fontSize: '1.3em', color: '#874343', fontWeight: '600'}}>S T R E A M</span> 
                      <Header.Subheader>Multi Channel Digital Experience 2.3.01.</Header.Subheader>
                    </Header.Content>
                  )
                }
                {/* <span style={{fontSize: '1.3em', color: '#874343', fontWeight: '600'}}>O.M.N.I</span>  */}
                {/* <Header.Subheader>Omni Channel Communication Platform.</Header.Subheader> */}
            </Header>  
          </div>

          <Divider />
          <div style={{margin: '1em 0 1em 0'}}>
            <Header as='h3' color='blue'> 
              <Header.Content style={{fontWeight: '400'}}>
                Sign In
                <Header.Subheader>Valid email and mobile phone is required for further transaction validation.</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
            <Form.Group widths='equal'>
              <Field name='account' component={InputField}
                label= 'Handphone / Email'
                icon='mobile alternate'
                placeholder='No. Handphone / Email'
                validate={[required]} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='passwd' component={InputField}
                                type='password'
                icon='unlock alternate'
                label='Password'
                placeholder='Password'
                validate={required} />
            </Form.Group>

            {progress==1 ? <DismissableMessage style={{boxShadow: 'none', color: code=="00" ? '#fff' : '#fff', background: code=="00" ? '#0a717c' : '#b32800', textAlign: 'center'}} header={'Signin ' + (code=="00" ?  'OK' : 'NOK')} content={text} /> : null}
            {progress==2 ? <DismissableMessage style={{boxShadow: 'none', color: '#fff', background: 'rgb(253, 81, 27)', textAlign: 'center'}} header='Error' content='Network connection failures.' /> : null}

            <Divider hidden />
            <Form.Group widths='equal'>
              <Form.Field style={{textAlign: 'center'}}>
                <Button color='blue' size='large' id='btnSignIn' icon={progress==0 ? true : false}> {progress==0 ? <Icon name='notch circle' loading /> : ''} Sign In</Button>
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)
(reduxForm({
  form: 'SigninForm',	// a unique identifier for this form
})(SigninForm)))
