import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select, Table, Feed, Label } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import DismissableMessage from '../../common/DismissableMessage'
import { personalOptions, ticketOptions }  from '../../../modules/service/StaticMaster'

import ConversationBox from './box/ConversationBox'
import ResourceBox from './box/ResourceBox'
import KnowledgeBox from './box/KnowledgeBox'
import PeopleBox from './box/PeopleBox'
import TicketBox from './box/TicketBox'
import InteractionBox from './box/InteractionBox'
import TimelinesBox from './box/TimelinesBox'
import SummaryBox from './box/SummaryBox'
import CommentsBox from './box/CommentsBox'
import ActionBox from './box/ActionBox'
import ShareBox from './box/ShareBox'
import EscalateBox from './box/EscalateBox'
import CompositionBox from './box/CompositionBox'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField, LabelSelectField } from '../../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { resetTicket, getTicket,updateTicket, createTicket } from "../../../actions/service/ticketAction"
import { listForm, listMacro, listAgent, listWorkgroup, listProduct, listCustomer, listChannel, listClassification, listRemark } from "../../../actions/service/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('MyTicketFormUpdate')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStoreTicket,
    ticketStore: state.ticketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listForm:(objParam) => dispatch(listForm(objParam)),
    listMacro:(objParam) => dispatch(listMacro(objParam)),
    listAgent:(objParam) => dispatch(listAgent(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    listCustomer:(objParam) => dispatch(listCustomer(objParam)),
    listChannel:(objParam) => dispatch(listChannel(objParam)),
    listClassification:(objParam) => dispatch(listClassification(objParam)),
    listRemark:(objParam) => dispatch(listRemark(objParam)),

    resetTicket:(objParam) => dispatch(resetTicket(objParam)),
    getTicket:(objParam) => dispatch(getTicket(objParam)),
    createTicket:(objParam) => dispatch(createTicket(objParam)),
    updateTicket:(objParam) => dispatch(updateTicket(objParam)),
  }
}

class MyTicketFormUpdate extends Component {
  constructor(props) {
    super(props)
    this.state= {
      timeline: 0,
      box: 'summary',
    }
  }

  componentDidMount(){
    // console.log('!!! DID MOUNT', this.props.mode, this.props.ticketId, this.props.param)
    this.props.resetTicket(this.props.mode)
    if (this.props.mode==2 || this.props.mode==4) { //update
      this.props.getTicket({ticketId: this.props.ticketId})
    }

    // this.props.listForm()
    // this.props.listMacro()
    // this.props.listAgent()
    // this.props.listWorkgroup()
    // this.props.listProduct()
    // this.props.listCustomer()
    // this.props.listChannel()
    // this.props.listClassification()
    // this.props.listRemark()
  }

  componentDidUpdate() {
    // const { mode } = this.props
    // const { progress, code, text }= (mode==2 ? (this.props.ticketStore.updateTicket!=undefined ? this.props.ticketStore.updateTicket : {}) : (this.props.ticketStore.createTicket!=undefined ? this.props.ticketStore.createTicket : {}))

    // if (progress==1 && code=='00') {
    //   setTimeout(()=>{this.props.setMode(1)}, 1000 )
    // }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    // const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    // mode==2 ? this.props.updateTicket({...values}) : this.props.createTicket({...values})
    
  }

  setBox(v, param) {
    // console.log('!!! SET BOX', v, param)
    this.setState({
      box: v,
      param: param,
      ticket: false,
    })
  }
  setBoxTicket(v, param) {
    // console.log('!!! SET BOX TICKET', v, param)
    this.setState({
      box: v,
      param: param,
      ticket: true,
    })
  }
  setTimeline() {
    this.setState({
      timeline: this.state.timeline<2 ? this.state.timeline + 1 : 0,
    })
  }

  render() {
    const { box, ticket, param } = this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, screenHeight, screenWidth } = this.props
    
    const { data }= (this.props.ticketStore.getTicket!=undefined && this.props.ticketStore.getTicket.progress=='1' ? this.props.ticketStore.getTicket : {})
    const { progress, code, text }= (mode==2 ? (this.props.ticketStore.updateTicket!=undefined ? this.props.ticketStore.updateTicket : {}) : (this.props.ticketStore.createTicket!=undefined ? this.props.ticketStore.createTicket : {}))

    // const listForm= (this.props.masterStore.listForm!=undefined && this.props.masterStore.listForm.progress=='1' ? this.props.masterStore.listForm.data : [])
    // const listMacro= (this.props.masterStore.listMacro!=undefined && this.props.masterStore.listMacro.progress=='1' ? this.props.masterStore.listMacro.data : [])
    // const listAgent= (this.props.masterStore.listAgent!=undefined && this.props.masterStore.listAgent.progress=='1' ? this.props.masterStore.listAgent.data : [])
    // const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    // const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    // const listCustomer= (this.props.masterStore.listCustomer!=undefined && this.props.masterStore.listCustomer.progress=='1' ? this.props.masterStore.listCustomer.data : [])
    // const listChannel= (this.props.masterStore.listChannel!=undefined && this.props.masterStore.listChannel.progress=='1' ? this.props.masterStore.listChannel.data : [])
    // const listClassification= (this.props.masterStore.listClassification!=undefined && this.props.masterStore.listClassification.progress=='1' ? this.props.masterStore.listClassification.data : [])

    // const priority= ticketOptions('priority', true);
    // const severity= ticketOptions('severity', true);

    return (
      <div className='ticketing' style={{padding: '1em 1.5em'}}>
        <Header as='h4' style={{marginBottom: '2em'}}>
          <Icon name='ticket' style={{fontSize: '1em', transform: 'rotate(90deg)'}} />
          <Header.Content>
            My Ticket / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
          </Header.Content>
        </Header>
        
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Grid stackable doubling>
            <Grid.Row columns={'equal'}>
              {(this.state.timeline==0 || this.state.timeline==1) && <Grid.Column style={{background: '#f7f7f7'}}>
                {data && <TicketBox setBox={this.setBox.bind(this)} setBoxTicket={this.setBoxTicket.bind(this)} timeline={this.state.timeline} setTimeline={this.setTimeline.bind(this)} ticket={data}/>}
              </Grid.Column>}
              {(this.state.timeline==0 || this.state.timeline==2) &&
                <Grid.Column style={{background: '#efefe1'}}>
                  {data && <TimelinesBox setBox={this.setBox.bind(this)} setBoxTicket={this.setBoxTicket.bind(this)} timeline={this.state.timeline} setTimeline={this.setTimeline.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} ticket={data}/>}
                </Grid.Column>
              }
              <Grid.Column style={{background: '#c6eeff'}}>
                <div style={{display: 'flex'}}>
                  <div style={{flex: 1}}>
                    {(!box || box=='summary') && <SummaryBox ticket={data} />}
                    {(box=='split') && <CompositionBox split ticket={ticket ? param : null} />}
                    {(box=='merge') && <CompositionBox merge ticket={ticket ? param : null} />}

                    {(box=='update' && param) && <ActionBox update ticket={ticket ? param : null} action={!ticket ? param : null}/>}
                    {(box=='view' && param) && <ActionBox view ticket={ticket ? param : null} action={!ticket ? param : null}/>}
                    {/* {(box=='modify') && <ActionBox modify  />} */}

                    {(box=='escalate') && <EscalateBox escalate ticket={ticket ? param : null} />}
                    
                    {(box=='comments') && <CommentsBox action={!ticket ? param : null} />}
                    {(box=='share' && param) && <ShareBox share ticket={ticket ? param : null} action={!ticket ? param : null} />}
                    {(box=='transfer' && param) && <ShareBox transfer ticket={ticket ? param : null} action={!ticket ? param : null} />}

                    
                    {this.state.box=='request' && <ConversationBox mode='request' channel='voice' />}
                    {this.state.box=='interaction' && <InteractionBox />}
                    {this.state.box=='conversation' && <ConversationBox mode='conversation' />}
                    {this.state.box=='ticket' && <TicketBox />}
                    {this.state.box=='people' && <PeopleBox />}
                    {this.state.box=='resource' && <ResourceBox />}
                    {this.state.box=='knowledge' && <KnowledgeBox />}
                  </div>
                  <div>
                    <div style={{margin: '3em -1em 0 1em', background: '#eee', padding: '1em .3em', borderRadius: '2em'}}>
                      <Popup content='First initial ticket conversation from public / private requester through certain channel.' position='left center' inverted trigger={
                        <Button type='button' onClick={this.setBox.bind(this, 'request')} color={box=='request' ? 'teal' : null} circular icon style={{padding: '0 0 0 0', margin: '0'}}><Icon name='commenting' /></Button>
                      } />
                      {/* <Divider style={{margin: '.5em 0'}}/>
                      <Popup content='Ticket Interaction Timelines, shortened recently, based on ticket update.' position='left center' inverted trigger={
                        <Button type='button' onClick={this.setBox.bind(this, 'interaction')} color={box=='interaction' ? 'teal' : null} circular icon style={{padding: '0 0 0 0', margin: '0'}}><Icon name='ellipsis vertical' /></Button>
                      } /> */}
                      <Divider style={{margin: '.5em 0'}}/>
                      <Popup content='Ticket Conversations, from main  to side conversation whether public and internal conversation.' position='left center' inverted trigger={
                        <Button type='button' onClick={this.setBox.bind(this, 'conversation')} color={box=='conversation' ? 'teal' : null} circular icon style={{padding: '0 0 0 0', margin: '0'}}><Icon name='comments' /></Button>
                      } />
                      {/* <Divider style={{margin: '.5em 0'}}/>
                      <Popup content='Tickets, All related sub tickets for parent / main ticket.' position='left center' inverted trigger={
                        <Button type='button' onClick={this.setBox.bind(this, 'ticket')} color={box=='ticket' ? 'teal' : null} circular icon style={{padding: '0 .25em 0 0', margin: '0'}}><Icon name='tags' /></Button>
                      } /> */}
                      <Divider style={{margin: '.5em 0'}}/>
                      <Popup content='People, Agents and backend support who responsible for ticket.' position='left center' inverted trigger={
                        <Button type='button' onClick={this.setBox.bind(this, 'people')} color={box=='people' ? 'teal' : null} circular icon style={{padding: '0 .25em 0 0', margin: '0'}}><Icon name='users' /></Button>
                      } />
                      <Divider style={{margin: '.5em 0'}}/>
                      <Popup content='Photo, Images and Documents related to ticket, shortened recently.' position='left center' inverted trigger={
                        <Button type='button' onClick={this.setBox.bind(this, 'resource')} color={box=='resource' ? 'teal' : null} circular icon style={{padding: '0 .25em 0 0', margin: '0'}}><Icon name='cloud' /></Button>
                      } />
                      <Divider style={{margin: '.5em 0'}}/>
                      <Popup content='Knowledge Based of service, product and customer care information, available for copy paste shortcut.' position='left center' inverted trigger={
                        <Button type='button' onClick={this.setBox.bind(this, 'knowledge')} color={box=='knowledge' ? 'teal' : null} circular icon style={{padding: '0 0 0 0', margin: '0'}}><Icon name='book' /></Button>
                      } />
                      <Divider style={{margin: '.5em 0'}}/>
                      <Popup content='Summary and statistic of current ticket.' position='left center' inverted trigger={
                        <Button type='button' onClick={this.setBox.bind(this, 'summary')} color={box=='summary' ? 'teal' : null} circular icon style={{padding: '0 0 0 0', margin: '0'}}><Icon name='info' /></Button>
                      } />
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          {/* <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Ticket' : 'Insert Ticket') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment> */}
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.ticketStore.getTicket!=undefined && state.ticketStore.getTicket.progress==1 ? state.ticketStore.getTicket.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      openStamp: selector(state, 'openStamp'),
      remark: selector(state, 'remark'),

      segmentId: selector(state, 'segmentId'),
      segmentParams: selector(state, 'segmentParams'),

      dispositions: selector(state, 'dispositions'),
    }),
    { load: getTicket }
  )
  (reduxForm({
    form: 'MyTicketFormUpdate',	// a unique identifier for this form
  })(MyTicketFormUpdate)
  )
)
