export default function settingReducer (state = {}, action) {
  switch (action.type) {
    case 'SETTING_RESET': {
        return({
            ...state,
            getSetting: undefined,
            updateSetting: undefined,
        })
    }

    case 'SETTING_BROWSE_PENDING': {
      return({...state, browseSetting: {progress: 0}})
    }
    case 'SETTING_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseSetting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseSetting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SETTING_BROWSE_REJECTED': {
        return({...state, browseSetting: {progress: 2, payload: action.payload}})
    }
    
    case 'SETTING_GET_PENDING': {
        return({...state, getSetting: {progress: 0}})
    }
    case 'SETTING_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getSetting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getSetting: {...action.payload.data,
                data: {...action.payload.data.data,
                },  progress: 1, payload: action.payload}})
        }
    }
    case 'SETTING_GET_REJECTED': {
        return({...state, getSetting: {progress: 2, payload: action.payload}})
    }

    case 'SETTING_UPDATE_PENDING': {
      return({...state, updateSetting: {progress: 0}})
    }
    case 'SETTING_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateSetting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateSetting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SETTING_UPDATE_REJECTED': {
        return({...state, updateSetting: {progress: 2, payload: action.payload}})
    }

    case 'SETTING_OMNI_PENDING': {
      return({...state, omniSetting: {progress: 0}})
    }
    case 'SETTING_OMNI_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, omniSetting: {progress: 2, payload: action.payload}})
        } else {
            return({...state, omniSetting: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SETTING_OMNI_REJECTED': {
        return({...state, omniSetting: {progress: 2, payload: action.payload}})
    }

    case 'SETTING_ENERGY_PENDING': {
      return({...state, senderEnergy: {progress: 0}})
    }
    case 'SETTING_ENERGY_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, senderEnergy: {progress: 2, payload: action.payload}})
        } else {
            return({...state, senderEnergy: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SETTING_ENERGY_REJECTED': {
        return({...state, senderEnergy: {progress: 2, payload: action.payload}})
    }

    case 'SETTING_QRCODE_PENDING': {
      return({...state, senderQRCode: {progress: 0}})
    }
    case 'SETTING_QRCODE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, senderQRCode: {progress: 2, payload: action.payload}})
        } else {
            return({...state, senderQRCode: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SETTING_QRCODE_REJECTED': {
        return({...state, senderQRCode: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}