import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetContact(objParam) {
  return {
      type: 'CONTACT_RESET',
      payload: objParam
  }
}

export function getContact(objParam) {
  return {
      type: 'CONTACT_GET',
      payload:
        axios.post(
          urlAPI + 'contact/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusContact(objParam) {
  return {
      type: 'CONTACT_STATUS',
      payload:
        axios.post(
          urlAPI + 'contact/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseContact(objParam) {
  return {
      type: 'CONTACT_BROWSE',
      payload:
        axios.post(
          urlAPI + 'contact/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createContact(objParam) {
  return {
      type: 'CONTACT_CREATE',
      payload:
        axios.post(
          urlAPI + 'contact/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateContact(objParam) {
  return {
      type: 'CONTACT_UPDATE',
      payload:
        axios.post(
          urlAPI + 'contact/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeContact(objParam) {
  return {
      type: 'CONTACT_REMOVE',
      payload:
        axios.post(
          urlAPI + 'contact/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
