import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider  } from 'semantic-ui-react'

import PerformanceCampaign from '../../../libraries/realm/halo/PerformanceCampaign';
import PerformanceAgent from '../../../libraries/realm/halo/PerformanceAgent';

class PerformanceBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    setMode(mode) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.props
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          {mode==2 && <PerformanceCampaign screenWidth={screenWidth} screenHeight={screenHeight} />}
          {mode==3 && <PerformanceAgent screenWidth={screenWidth} screenHeight={screenHeight} />}
        </div>
      )
    }
}

export default PerformanceBody
