export default function billingReducer (state = {}, action) {
  switch (action.type) {
    case 'BILLING_RESET': {
        return({
            ...state,
            getBilling: {},
            updateBilling: {},
            createBilling: {},
        })
    }

    case 'BILLING_BROWSE_PENDING': {
      return({...state, browseBilling: {progress: 0}})
    }
    case 'BILLING_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseBilling: {...api.browseBilling.response, progress: 1, payload: action.payload}})
            return({...state, browseBilling: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseBilling: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BILLING_BROWSE_REJECTED': {
        return({...state, browseBilling: {progress: 2, payload: action.payload}})
    }
    
    case 'BILLING_GET_PENDING': {
        return({...state, getBilling: {progress: 0}})
    }
    case 'BILLING_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getBilling: {...api.getBilling.response, progress: 1, payload: action.payload}})
            return({...state, getBilling: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getBilling: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BILLING_GET_REJECTED': {
        return({...state, getBilling: {progress: 2, payload: action.payload}})
    }

    case 'BILLING_STATUS_PENDING': {
      return({...state, statusBilling: {progress: 0}})
    }
    case 'BILLING_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusBilling: {...api.statusBilling.response, progress: 1, payload: action.payload}})
            return({...state, statusBilling: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusBilling: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BILLING_STATUS_REJECTED': {
        return({...state, statusBilling: {progress: 2, payload: action.payload}})
    }

    case 'BILLING_CREATE_PENDING': {
      return({...state, createBilling: {progress: 0}})
    }
    case 'BILLING_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createBilling: {...api.createBilling.response, progress: 1, payload: action.payload}})
            return({...state, createBilling: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createBilling: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BILLING_CREATE_REJECTED': {
        return({...state, createBilling: {progress: 2, payload: action.payload}})
    }

    case 'BILLING_UPDATE_PENDING': {
      return({...state, updateBilling: {progress: 0}})
    }
    case 'BILLING_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateBilling: {...api.updateBilling.response, progress: 1, payload: action.payload}})
            return({...state, updateBilling: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateBilling: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BILLING_UPDATE_REJECTED': {
        return({...state, updateBilling: {progress: 2, payload: action.payload}})
    }

    case 'BILLING_REMOVE_PENDING': {
      return({...state, removeBilling: {progress: 0}})
    }
    case 'BILLING_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeBilling: {...api.removeBilling.response, progress: 1, payload: action.payload}})
            return({...state, removeBilling: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeBilling: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BILLING_REMOVE_REJECTED': {
        return({...state, removeBilling: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}