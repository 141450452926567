import React, { Component } from 'react';
import { Accordion, Form, Menu, Icon, Segment, Divider, Label, Button, Header } from 'semantic-ui-react'

import { Slider } from "react-semantic-ui-range";
import numeral from 'numeral'

class SliderProgress extends Component {
  constructor(props) {
    super(props);
    if (this.props.mode==1) { //range
      this.state= {
        currentValue: this.props.defaultValue,
        currentMinValue: this.props.defaultValue[0],
        currentMaxValue: this.props.defaultValue[1],
      }
    } else {
      this.state= {
        currentValue: this.props.defaultValue,
        currentMinValue: 0,
        currentMaxValue: this.props.defaultValue,
      }
    }
  }

  onChange(value) {
    const {minValue, maxValue, defaultValue, mode}= this.props

    // console.log(value)
    this.props.onChange(value)

    if (mode==1) { //range
      this.setState({
        currentMinValue: value[0],
        currentMaxValue: value[1]
      })
    } else {
      this.setState({
        currentValue: value
      })
    }
  }
  
  render() {
    const { step, minValue, maxValue, defaultValue, mode }= this.props
    const { currentValue, currentMinValue, currentMaxValue }= this.state

    const settings = {
      start: 10,
      min: 0,
      max: 100,
      step: 5,
      // onChange: value => {
      //   setValue(value);
      // }
      onChange: this.onChange.bind(this)
    };
  
    return (
      <div>
        <div style={{display: 'flex'}}>
          <div style={{marginTop: '.5em'}}>
            <Icon size='large' name='percent' color='grey' />
          </div>
          <div style={{flex: 1, marginRight: '1em', marginTop: '.08em'}}>
            <Slider style={{track: {top: "15px"}, trackFill: { top: "15px" }, thumb: {
              width: "32px",
              height: "32px"
            }}} value={currentValue} color="red" settings={settings} />
          </div>
          <div style={{textAlign: 'right', minWidth: '5em'}}>
            <Header as='h3'>
              <Header.Content>
                {numeral((currentValue)).format('0,0')} %
                <Header.Subheader style={{fontSize: '.4em'}}>
                  COMPLETE
                </Header.Subheader>
              </Header.Content>
            </Header>
          </div>
        </div>

        {/* <div style={{display: 'flex', paddingBottom: '.05em'}}>
          <div style={{flex: 1}}>
            <Label basic pointing='right'>{numeral((mode==1 ? currentMinValue : minValue)).format('0,0')}</Label>
          </div>
          <div style={{flex: 1, textAlign: 'right'}}>
            <Label basic pointing='left'>{numeral((mode==1 ? currentMaxValue : currentValue)).format('0,0')}</Label>
          </div>
        </div>
        {(mode==undefined || mode==0) && <Slider
          step={step}
          min={minValue} max={maxValue} defaultValue={defaultValue} 
          onChange={this.onChange.bind(this)}
        />}

        {mode==1 && <Range
          step={step}
          min={minValue} max={maxValue} defaultValue={defaultValue} 
          onChange={this.onChange.bind(this)}
        />} */}
      </div>
    );
  }
}

export default SliderProgress;