import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import { competencyOptions, tunnelOptions, genderOptions,educationOptions,protoOptions }  from '../../common/StaticMasterData'
import DismissableMessage from '../../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField, MaskedField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'
import { connect } from "react-redux"
import { resetTunnel, getTunnel,updateTunnel, createTunnel } from "../../../actions/talk/tunnelAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    tunnelStore: state.tunnelStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetTunnel:(objParam) => dispatch(resetTunnel(objParam)),
    getTunnel:(objParam) => dispatch(getTunnel(objParam)),
    createTunnel:(objParam) => dispatch(createTunnel(objParam)),
    updateTunnel:(objParam) => dispatch(updateTunnel(objParam)),
  }
}

class TunnelForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetTunnel(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getTunnel({tunnelId: this.props.tunnelId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.tunnelStore.updateTunnel!=undefined ? this.props.tunnelStore.updateTunnel : {}) : (this.props.tunnelStore.createTunnel!=undefined ? this.props.tunnelStore.createTunnel : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, tunnelId } = this.props
    mode==2 ? this.props.updateTunnel({...values}) : this.props.createTunnel({...values})
  }
  

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.tunnelStore.updateTunnel!=undefined ? this.props.tunnelStore.updateTunnel : {}) : (this.props.tunnelStore.createTunnel!=undefined ? this.props.tunnelStore.createTunnel : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Tunnel' : 'Insert Tunnel') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.tunnelStore.getTunnel!=undefined && state.tunnelStore.getTunnel.progress==1 ? state.tunnelStore.getTunnel.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'TunnelForm',	// a unique identifier for this form
  })(TunnelForm)
  )
)