import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label, Dropdown } from 'semantic-ui-react'

class TargetRemark extends Component {
  constructor(props) {
    super(props);
    this.state={
    }
  }
    
  render() {
    return (
      <div style={{padding: '2em'}}>
        <Header as='h2'>
          <Header.Content>
            {this.props.data.name}
            <Header.Subheader>
              <b>
                {this.props.data.mobile || this.props.data.whatsapp || this.props.data.officePhone || this.props.data.homePhone || this.props.data.email} / {this.props.data.campaign && this.props.data.campaign.name && this.props.data.campaign.name.length>20 ? this.props.data.campaign.name.substr(0,20) +' ...' : this.props.data.campaign.name}
              </b>
            </Header.Subheader>
          </Header.Content>
        </Header>
        
        {/* <Divider /> */}
        <Label.Group>
          {this.props.data.contractRef && 
            <Label color='olive'>
              <Header as='h5'>
                <Header.Content>
                  {this.props.data.contractRef || '-'}  
                  <Header.Subheader>Contract Ref.</Header.Subheader>
                </Header.Content>
              </Header>
            </Label>
          }
          {this.props.data.custRef && 
            <Label color='yellow'>
              <Header as='h5'>
                <Header.Content>
                  {this.props.data.custRef || '-'}  
                  <Header.Subheader>Customer Ref.</Header.Subheader>
                </Header.Content>
              </Header>
            </Label>
          }
          {this.props.data.merchantCode &&
            <Label color='olive'>
              <Header as='h5'>
                <Header.Content>
                  {this.props.data.merchantCode || '-'} / {this.props.data.merchantName || '-'}  
                  <Header.Subheader>Merchant Code</Header.Subheader>
                </Header.Content>
              </Header>
            </Label>
          }
          {this.props.data.merchantWarehouse &&
            <Label color='green'>
              <Header as='h5'>
                <Header.Content>
                  {this.props.data.merchantWarehouse || '-'}  
                  <Header.Subheader>Merchant Warehouse</Header.Subheader>
                </Header.Content>
              </Header>
            </Label>
          }
        </Label.Group>
        
        <Divider />
        <Header as='h4' color='blue'>
          <Header.Content>
            <Icon name='warning sign' /> CUSTOMER REMARK / FEEDBACK
            <Header.Subheader>Last remark / feedback recently saved  by current Agent</Header.Subheader>
          </Header.Content>
        </Header>

        <Header as='h4'>
          <Header.Content>
            <Header.Subheader><b>REMARK</b></Header.Subheader>
            {this.props.data.remark || '-'}
          </Header.Content>
        </Header>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader><b>REASON / FREE TEXT</b></Header.Subheader>
            {(this.props.data.remarks && this.props.data.remarks.reason) || '-'}
          </Header.Content>
        </Header>
      </div>
    )
  }
}

export default TargetRemark;