export const required = value => (value || typeof value === 'number' ? undefined : 'Required')
export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
export const email = value => value && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) ? 'Invalid email address' :  undefined
export const maxLength = max => value => value && value.length > max ? `Must be ${max} characters or less` : undefined
export const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined
export const minValue = min => value => value && value < min ? `Must be at least ${min}` : undefined
export const maxValue = max => value => value && value > max ? `Must be lower than ${max}` : undefined
export const alphaNumeric = value => value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined
export const comment = value => value && !/^[a-z0-9_.\-]+$/i.test(value) ? 'Only alphanumeric, period and dash characters' : undefined
export const account = value => value && !/^[a-z0-9_.\-]+$/i.test(value) ? 'Only alphanumeric, period and dash characters' : undefined
export const fullName = value => value && !/^[a-zA-Z ]+$/i.test(value) ? 'Require full name' : undefined

export const ipaddress = value => value && !/^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})$/i.test(value) ? 'Only ip address' : undefined
export const base64 = value => value && !/[^a-zA-Z0-9\/\+=]/i.test(value) ? 'Require base64' : undefined
export const url = value => value && !/^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/.test(value) ? 'Require URL' : undefined

export const confirmedPassword = (value,values) => value && !(value==values.userPasswd || value==values.newPasswd) ? 'Must have same password value' : undefined
export const userPassword = (value,values) => value && !(value==values.confirmedPasswd || value==values.confirmPasswd) ? 'Must have same password value' : undefined
export const strongPassword = value => value && !/^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))/.test(value) ? 'Require strong password' : undefined

export const phoneNumber = value => value && !/^[0-9\+]+$/i.test(value) ? 'Invalid phone, number only' : undefined
export const phoneNumberID = value => value && !/^(62|0)[0-9\+]+$/i.test(value) ? 'Invalid phone, indonesia number only' : undefined
export const date = value => value && !/^[0-9\-]+$/i.test(value) ? 'Invalid date value' : undefined
export const tooYoung = value => value && value < 13 ? 'You do not meet the minimum age requirement!' : undefined