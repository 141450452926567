import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image } from 'semantic-ui-react'


import { connect } from "react-redux"
import { socketAgentStatus } from "../../actions/socket/socketAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
  }
}

class MenuLeft extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }

  render() {
    const { module, screenWidth,  screenHeight, mini}= this.props
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data : undefined)
    const { dialings, status }= (agentStatus && agentStatus.payload.data) || {}
    
    if (mini) {
      return(
        <></>
      )
    } else if (module=='inbound') {
      return (
        <div style={{top:'3.2em', position: 'fixed', height: screenHeight, width: '80px', zIndex: 99, background: '#efefef'}}>
          <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '80px', padding: '3.5em 1.2em 0 0'}}>
            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Dashboard' content='Real time monitoring channel, traffic and system heartbeat.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'statistic')}
                  color={this.props.mode=='statistic' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='statistic' ? '600' : '100'}}>
                    <Icon name='chart bar' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='statistic' ? 'blue' : 'grey'}/>
                </Header>
              } />
            </div>

            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Interaction' content='Omni channel conversation console for customer interaction.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'interaction')}
                  color={this.props.mode=='interaction' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='interaction' ? '600' : '100'}}>
                    <Icon name='comments outline' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='interaction' ? 'blue' : 'grey'}/>
                </Header>
              } />

              <Divider style={{margin: '1em  0'}}/>
              <Popup header='Ticket' content='Customer ticket management, real time monitoring for handling and solution.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'ticket')}
                  color={this.props.mode=='ticket' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='ticket' ? '600' : '100'}}>
                    <Icon name='ticket alternate' style={{margin: '0', fontSize: '1.2em'}} color={this.props.mode=='ticket' ? 'blue' : 'grey'}/>
                </Header>
              } />

              {/* <Divider style={{margin: '1em  0'}}/>
              <Popup header='Customer' content='Customer relation management make it simple to mantain rentetions.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'customer')}
                  color={this.props.mode=='customer' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='customer' ? '600' : '100'}}>
                    <Icon name='address card' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='customer' ? 'blue' : 'grey'}/>
                </Header>
              } /> */}
            
              {/* <Divider style={{margin: '1em  0'}}/>
              <Popup header={'Social Media'} content={'Manage multiple social media accounts through single window.'} position='right center' inverted trigger={
                <Header as='h3' inverted icon disabled
                  onClick={this.props.openModal.bind(this,'socmed')}
                  color={this.props.mode=='socmed' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='socmed' ? '600' : '100'}}>
                    <Icon name='life ring outline' style={{margin: '0', fontSize: '1.4em'}} color={this.props.mode=='socmed' ? 'blue' : 'grey'} />
                </Header>
              } /> */}

              {/* <Divider style={{margin: '1em  0'}}/>
              <Popup header='Appointment' content='Saved schedule or reschedule as requested by customer.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'appointment')}
                  color={this.props.mode=='appointment' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='host' ? '600' : '100'}}>
                    <Icon name='calendar alternate outline' style={{margin: '0', fontSize: '1.4em'}} color={this.props.mode=='appointment' ? 'blue' : 'grey'}/>
                </Header>
              } /> */}
            </div>

            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              {/* <Popup header={status=='online' ? 'Dialer Management' : 'Dialer Disabled !'} content={status=='online' ? 'Input contact and use predial to build engagement.' : 'Please start session to use manual dialer feature.'} position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={status=='online' ? this.props.openModal.bind(this,'dialer') : null}
                  color={this.props.mode=='dialer' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='dialer' ? '600' : '100'}}>
                    <Icon name='fax' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='dialer' ? 'blue' : 'grey'} disabled={status!='online'}/>
                </Header>
              } />

              <Divider style={{margin: '1em  0'}}/> */}
              {/* <Popup header='Target / Audience' content='Available contact as locked or floating target.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'target')}
                  color={this.props.mode=='target' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='target' ? '600' : '100'}}>
                    <Icon name='list alternate outline' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='target' ? 'blue' : 'grey'}/>
                </Header>
              } />

              <Divider style={{margin: '1em  0'}}/> */}
              <Popup header='Logger' content='Agent activity and performance log, sign in/out, locked or call dispatch.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'logger')}
                  color={this.props.mode=='logger' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='logger' ? '600' : '100'}}>
                    <Icon name='chart line' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='logger' ? 'blue' : 'grey'}/>
                </Header>
              } />
            </div>

            {/* <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Knowledge Management' content='Share knowledge and competency resources online.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'knowledge')}
                  color={this.props.mode=='knowledge' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='knowledge' ? '600' : '100'}}>
                    <Icon name='book' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='knowledge' ? 'blue' : 'grey'}/>
                </Header>
              } />
            </div> */}
          </div>
        </div>
      );
    } else {
      return (
        <div style={{top:'3.2em', position: 'fixed', height: screenHeight, width: '80px', zIndex: 99, background: '#efefef'}}>
          <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '80px', padding: '3.5em 1.2em 0 0'}}>
            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Dashboard' content='Real time monitoring channel, traffic and system heartbeat.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'statistic')}
                  color={this.props.mode=='statistic' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='statistic' ? '600' : '100'}}>
                    <Icon name='chart bar' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='statistic' ? 'blue' : 'grey'}/>
                </Header>
              } />
            </div>

            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Interaction' content='Omni channel conversation console for customer interaction.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'interaction')}
                  color={this.props.mode=='interaction' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='interaction' ? '600' : '100'}}>
                    <Icon name='comments outline' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='interaction' ? 'blue' : 'grey'}/>
                </Header>
              } />

              <Divider style={{margin: '1em  0'}}/>
              <Popup header='Appointment' content='Saved schedule or reschedule as requested by customer.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'appointment')}
                  color={this.props.mode=='appointment' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='host' ? '600' : '100'}}>
                    <Icon name='calendar alternate outline' style={{margin: '0', fontSize: '1.4em'}} color={this.props.mode=='appointment' ? 'blue' : 'grey'}/>
                </Header>
              } />
            </div>

            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header={status=='online' ? 'Dialer Management' : 'Dialer Disabled !'} content={status=='online' ? 'Input contact and use predial to build engagement.' : 'Please start session to use manual dialer feature.'} position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={status=='online' ? this.props.openModal.bind(this,'dialer') : null}
                  color={this.props.mode=='dialer' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='dialer' ? '600' : '100'}}>
                    <Icon name='fax' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='dialer' ? 'blue' : 'grey'} disabled={status!='online'}/>
                </Header>
              } />
              
              <Divider style={{margin: '1em  0'}}/>
              <Popup header='Target / Audience' content='Available contact as locked or floating target.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'target')}
                  color={this.props.mode=='target' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='target' ? '600' : '100'}}>
                    <Icon name='list alternate outline' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='target' ? 'blue' : 'grey'}/>
                </Header>
              } />

              <Divider style={{margin: '1em  0'}}/>
              <Popup header='Logger' content='Agent activity and performance log, sign in/out, locked or call dispatch.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'logger')}
                  color={this.props.mode=='logger' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='logger' ? '600' : '100'}}>
                    <Icon name='chart line' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='logger' ? 'blue' : 'grey'}/>
                </Header>
              } />
            </div>

            {/* <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Knowledge Management' content='Share knowledge and competency resources online.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'knowledge')}
                  color={this.props.mode=='knowledge' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='knowledge' ? '600' : '100'}}>
                    <Icon name='book' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='knowledge' ? 'blue' : 'grey'}/>
                </Header>
              } />
            </div> */}
          </div>
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuLeft)