import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetMessage(objParam) {
  return {
      type: 'MESSAGE_RESET',
      payload: objParam
  }
}

export function getPromo(objParam) {
  return {
      type: 'MESSAGE_GET',
      payload:
        axios.post(
          urlAPI + 'message/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseMessage(objParam) {
  return {
      type: 'MESSAGE_BROWSE',
      payload:
        axios.post(
          urlAPI + 'message/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createMessage(objParam) {
  return {
      type: 'MESSAGE_CREATE',
      payload:
        axios.post(
          urlAPI + 'message/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function pushMessage(objParam) {
  return {
      type: 'MESSAGE_PUSH',
      payload:
        axios.post(
          urlAPI + 'message/push',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateMessage(objParam) {
  return {
      type: 'MESSAGE_UPDATE',
      payload:
        axios.post(
          urlAPI + 'message/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeMessage(objParam) {
  return {
      type: 'MESSAGE_REMOVE',
      payload:
        axios.post(
          urlAPI + 'message/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
