export default function wacontactReducer (state = {}, action) {
  switch (action.type) {
    case 'WACONTACT_RESET': {
        return({
            ...state,
            getWacontact: {},
            updateWacontact: {},
            createWacontact: {},
        })
    }

    case 'WACONTACT_GET_PENDING': {
        return({...state, getWacontact: {progress: 0}})
    }
    case 'WACONTACT_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getWacontact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getWacontact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACONTACT_GET_REJECTED': {
        return({...state, getWacontact: {progress: 2, payload: action.payload}})
    }

    case 'WACONTACT_STATUS_PENDING': {
      return({...state, statusWacontact: {progress: 0}})
    }
    case 'WACONTACT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusWacontact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusWacontact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACONTACT_STATUS_REJECTED': {
        return({...state, statusWacontact: {progress: 2, payload: action.payload}})
    }

    case 'WACONTACT_BROWSE_PENDING': {
      return({...state, browseWacontact: {progress: 0}})
    }
    case 'WACONTACT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseWacontact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseWacontact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACONTACT_BROWSE_REJECTED': {
        return({...state, browseWacontact: {progress: 2, payload: action.payload}})
    }

    case 'WACONTACT_CREATE_PENDING': {
      return({...state, browseWacontact: {progress: 0}})
    }
    case 'WACONTACT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createWacontact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createWacontact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACONTACT_CREATE_REJECTED': {
        return({...state, createWacontact: {progress: 2, payload: action.payload}})
    }

    case 'WACONTACT_UPDATE_PENDING': {
      return({...state, updateWacontact: {progress: 0}})
    }
    case 'WACONTACT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateWacontact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateWacontact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACONTACT_UPDATE_REJECTED': {
        return({...state, updateWacontact: {progress: 2, payload: action.payload}})
    }

    case 'WACONTACT_REMOVE_PENDING': {
      return({...state, removeWacontact: {progress: 0}})
    }
    case 'WACONTACT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeWacontact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeWacontact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACONTACT_REMOVE_REJECTED': {
        return({...state, removeWacontact: {progress: 2, payload: action.payload}})
    }
    
    default: {
      return state
    }
  }
}