import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed } from 'semantic-ui-react'

export default class VerificationWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em', background: '#000', border: 'none'}}>
        <Header as='h5' inverted>
          <Icon name='bookmark outline' style={{fontSize: '1em'}} />
          <Header.Content>
            Verification
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em', border: 'none', background: '#d63400'}}>
        <Header as='h4' inverted style={{margin: '0'}}>
          <Icon name='thumbs up outline' style={{}} />
          <Header.Content>
            00
            <Header.Subheader>Verified</Header.Subheader>
          </Header.Content>
        </Header>
        <Divider />
        <Header as='h4' inverted style={{margin: '0'}}>
          <Icon name='calendar check outline' style={{}} />
          <Header.Content>
            00
            <Header.Subheader>Reconfirm</Header.Subheader>
          </Header.Content>
        </Header>
        <Divider />
        <Header as='h4' inverted style={{margin: '0'}}>
          <Icon name='calendar alternate outline' style={{}} />
          <Header.Content>
            00
            <Header.Subheader>Reschedule</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      </>
    );
  }
}
