import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetInteractive(objParam) {
  return {
      type: 'INTERACTIVE_RESET',
      payload: objParam
  }
}

export function getInteractive(objParam) {
  return {
      type: 'INTERACTIVE_GET',
      payload:
        axios.post(
          urlAPI + 'interactive/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusInteractive(objParam) {
  return {
      type: 'INTERACTIVE_STATUS',
      payload:
        axios.post(
          urlAPI + 'interactive/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseInteractive(objParam) {
  return {
      type: 'INTERACTIVE_BROWSE',
      payload:
        axios.post(
          urlAPI + 'interactive/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createInteractive(objParam) {
  return {
      type: 'INTERACTIVE_CREATE',
      payload:
        axios.post(
          urlAPI + 'interactive/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateInteractive(objParam) {
  return {
      type: 'INTERACTIVE_UPDATE',
      payload:
        axios.post(
          urlAPI + 'interactive/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeInteractive(objParam) {
  return {
      type: 'INTERACTIVE_REMOVE',
      payload:
        axios.post(
          urlAPI + 'interactive/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
