import React, { Component } from 'react';
import { 
  Menu,
  Icon,
  Header,
  Popup,
  Label,
 } from 'semantic-ui-react'

 const options= [
  {
    key: '1',
    icon: 'user outline',
    caption: 'Monitoring',
  },
  {
    key: '2',
    icon: 'clock outline',
    caption: 'Campaign',
  },
  {
    key: '3',
    icon: 'user circle outline',
    caption: 'Agent',
  },
]

class MenuHorizontal extends Component {
  constructor(props) {
    super(props);
    this.state= {
      activeItem: 1
    }
  }
  
  componentDidMount() {
    this.props.activeItem && this.setState({
      activeItem: this.props.activeItem,
    })
  }

  handleItemClick(key) {
    if (this.props.onClick!=undefined)
      this.props.onClick(key)
    this.setState({ activeItem: key })
  }
  
  render() {
    const items= (this.props.options!=undefined ? this.props.options : options)
    const { activeItem } = this.state
    const { style, color, inverted,attached, simple, screenWidth } = this.props

    //console.log(this.props)
    if (items.length==0) {
      return(<></>)
    } else {
      return (
        <div>
          {items.map((item, idx)=>{
            if (item.hint) {
              return(
                <Popup key={item.key} header={item.hint.header} content={item.hint.content} position= 'bottom left' inverted trigger={
                  <Label as='a' size='large' key={item.key} color={activeItem==item.key ? 'yellow' : null} onClick={this.handleItemClick.bind(this, item.key)} style={{textDecoration: activeItem==item.key ? 'underline' : 'none', fontWeight: activeItem==item.key ? '600' : '400'}}>
                    <Icon name={item.icon} /> {item.caption}
                  </Label>
                } />
              )
            } else {
              return(
                <Label as='a' size='large' key={item.key} color={activeItem==item.key ? 'yellow' : null}  onClick={this.handleItemClick.bind(this, item.key)}  style={{textDecoration: activeItem==item.key ? 'underline' : 'none', fontWeight: activeItem==item.key ? '600' : '400'}}>
                  <Icon name={item.icon} /> {item.caption}
                </Label>
              )
            }
          })}
        </div>
      );
    }
  }
}

export default MenuHorizontal;