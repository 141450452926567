export default function transactReducer (state = {}, action) {
  switch (action.type) {
    case 'TRANSACT_RESET': {
        return({
            ...state,
            getTransact: {},
            updateTransact: {},
            createTransact: {},
        })
    }

    case 'TRANSACT_GET_PENDING': {
        return({...state, getTransact: {progress: 0}})
    }
    case 'TRANSACT_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getTransact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getTransact: {...action.payload.data, data: {...action.payload.data.data, 
                statusOptions: action.payload.data.data.statusOptions!=undefined ? action.payload.data.data.statusOptions.split(',') : [],
                workgroups: action.payload.data.data.workgroups!=undefined ? action.payload.data.data.workgroups.split(',') : [],
                products: action.payload.data.data.products!=undefined ? action.payload.data.data.products.split(',') : [],
                agents: action.payload.data.data.agents!=undefined ? action.payload.data.data.agents.split(',') : [],
                transferGroups: action.payload.data.data.transferGroups!=undefined ? action.payload.data.data.transferGroups.split(',') : [],
                webApiParams: action.payload.data.data.webApiParams!=undefined ? action.payload.data.data.webApiParams.split(',') : [],
            }, progress: 1, payload: action.payload}})
        }
    }
    case 'TRANSACT_GET_REJECTED': {
        return({...state, getTransact: {progress: 2, payload: action.payload}})
    }

    case 'TRANSACT_STATUS_PENDING': {
      return({...state, statusTransact: {progress: 0}})
    }
    case 'TRANSACT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusTransact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusTransact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TRANSACT_STATUS_REJECTED': {
        return({...state, statusTransact: {progress: 2, payload: action.payload}})
    }

    case 'TRANSACT_BROWSE_PENDING': {
      return({...state, browseTransact: {progress: 0}})
    }
    case 'TRANSACT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseTransact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseTransact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TRANSACT_BROWSE_REJECTED': {
        return({...state, browseTransact: {progress: 2, payload: action.payload}})
    }

    case 'TRANSACT_CREATE_PENDING': {
      return({...state, browseTransact: {progress: 0}})
    }
    case 'TRANSACT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createTransact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createTransact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TRANSACT_CREATE_REJECTED': {
        return({...state, createTransact: {progress: 2, payload: action.payload}})
    }

    case 'TRANSACT_UPDATE_PENDING': {
      return({...state, updateTransact: {progress: 0}})
    }
    case 'TRANSACT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateTransact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateTransact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TRANSACT_UPDATE_REJECTED': {
        return({...state, updateTransact: {progress: 2, payload: action.payload}})
    }

    case 'TRANSACT_REMOVE_PENDING': {
      return({...state, removeTransact: {progress: 0}})
    }
    case 'TRANSACT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeTransact: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeTransact: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TRANSACT_REMOVE_REJECTED': {
        return({...state, removeTransact: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}