import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_RESET',
      payload: objParam
  }
}

export function otpMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_OTP',
      payload:
        axios.post(
          urlAPI + 'middleware/otp',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function tokenMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_TOKEN',
      payload:
        axios.post(
          urlAPI + 'middleware/token',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function campaignMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_CAMPAIGN',
      payload:
        axios.post(
          urlAPI + 'middleware/campaign',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function targetMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_TARGET',
      payload:
        axios.post(
          urlAPI + 'middleware/target',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function webhookMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_WEBHOOK',
      payload:
        axios.post(
          urlAPI + 'middleware/webhook',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function agentMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_AGENT',
      payload:
        axios.post(
          urlAPI + 'middleware/agent',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function widgetMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_WIDGET',
      payload:
        axios.post(
          urlAPI + 'middleware/widget',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function commMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_COMM',
      payload:
        axios.post(
          urlAPI + 'middleware/comm',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function interactionMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_INTERACTION',
      payload:
        axios.post(
          urlAPI + 'middleware/interaction',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function reportMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_REPORT',
      payload:
        axios.post(
          urlAPI + 'middleware/report',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_BROWSE',
      payload:
        axios.post(
          urlAPI + 'middleware/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateMiddleware(objParam) {
  return {
      type: 'MIDDLEWARE_UPDATE',
      payload:
        axios.post(
          urlAPI + 'middleware/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function sandboxMiddleware(objParam, token, urlApi) {
  return {
      type: 'MIDDLEWARE_SANDBOX',
      payload:
        axios.post(
          urlApi, //urlAPI + 'middleware/sandbox',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": token, //localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}