import React, { Component } from 'react'
import { Label, Menu, Tab } from 'semantic-ui-react'

const panes = [
  {
    menuItem: { key: '1', icon: 'dot circle outline', content: 'venue' },
    render: () => <Tab.Pane>Tab 1 Provider</Tab.Pane>,
  },
  {
    menuItem: { key: '2', icon: 'lightbulb outline', content: 'addon' },
    render: () => <Tab.Pane>Tab 1 Provider</Tab.Pane>,
  },
  {
    menuItem: { key: '3', icon: 'ticket', content: 'ticket' },
    render: () => <Tab.Pane>Tab 1 Provider</Tab.Pane>,
  },
]

class MenuTabular extends Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }
  
  render() {
    const options= (this.props.panes!=undefined ? this.props.panes : panes)
    return (
      <Tab panes={options} activeIndex={this.props.activeIndex!=undefined ? this.props.activeIndex : undefined} onTabChange={this.props.onTabChange!=undefined ? this.props.onTabChange.bind(this) : undefined} style={ this.props.style }/>
    )
  }
}

export default MenuTabular