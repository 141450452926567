import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import { format, parse } from "date-fns"
import { socialStatus } from "../../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { browseSocial, statusSocial } from "../../../actions/stream/socialAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socialStore: state.socialStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseSocial:(objParam) => dispatch(browseSocial(objParam)),
    statusSocial:(objParam) => dispatch(statusSocial(objParam)),
  }
}
class SocialBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remark: false,
      expand: false,
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.socialStore.browseSocial!==undefined ? this.props.socialStore.browseSocial : {})
    this.props.browseSocial(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.socialStore.browseSocial!==undefined ? this.props.socialStore.browseSocial : {})
    this.props.browseSocial({search: {...search, transactDate: format(new Date(), 'yyyy-MM-dd 00:00:00'), flow: '0', status: null, keyword: null, channelType: null, initiator: null}})
  }

  onChangeStatus(id, status, e) {
    // console.log(id, status, e)
    this.props.statusSocial({socialId:id, isEnabled: status})
  }
  setExpand() {
    const { expand }= this.state
    this.setState({
      expand: !expand
    })
  }
  setRemark() {
    const { remark }= this.state
    this.setState({
      remark: !remark
    })
  }


  expandConversation(id) {
    // console.log(id)
    this.setState({
      socialId: (this.state.socialId==id ? 0 : id),
    })
  }

  render() {
    //console.log(this.props.socialStore.browseSocial)
    const { expand, remark }= this.state
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.socialStore.browseSocial!==undefined ? this.props.socialStore.browseSocial : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', padding: '0'}}>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width={expand ? 3 : 3}>
                  <Header as='h5' color='blue'>TARGET</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={expand ? 3 : 3}>
                  <Header as='h5' color='blue'>METHOD</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={expand ? 3 : 4}>
                  <Header as='h5' color='blue'>CONTENT</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={expand ? 1 : 2}>
                  <Header as='h5' color='blue'>
                    <Icon name={expand ? 'angle double right' : 'angle double left'} color={expand ? 'red' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setExpand.bind(this)}/>
                    <Header.Content>SENT</Header.Content>
                  </Header>
                </Table.HeaderCell>
                {expand && <>
                {/* <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>DELIVERED</Header>
                </Table.HeaderCell> */}
                <Table.HeaderCell width='1' style={{background: '#fffee0'}}>
                  <Header as='h5' color='blue'>VIEW</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='1'>
                  <Header as='h5' color='blue'>CLICK</Header>
                </Table.HeaderCell>
                </>}
                <Table.HeaderCell width={expand ? 4 : 4} style={{background: remark ? '#e7feff' : '#fff2f2'}}>
                  <Header as='h5' color='blue'><Icon name={remark ? 'toggle on' : 'toggle off'} color={remark ? 'blue' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setRemark.bind(this)}/><Header.Content>RESULT</Header.Content></Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={7} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          {item.target!=null && 
                            <Header.Content>
                              <b>{item.target.name || 'No info'}</b>
                              {item.campaign!=null && <Header.Subheader>{item.campaign && item.campaign.name && (item.campaign.name.length>25 ? item.campaign.name.substr(0,25) +' ...' : item.campaign.name)} / {item.client || '-'}</Header.Subheader>}
                            </Header.Content>
                          }
                          {item.target==null && item.visitor!=null &&
                            <Header.Content>
                              {item.visitor.name || 'Guest / Visitor'}
                              <Header.Subheader> {item.visitor.mobile || '-'} / {item.client || '-'}</Header.Subheader>
                            </Header.Content>
                          }
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Icon name={item.flow==0 ? 'chevron circle right' : 'chevron circle left'} color={item.flow==0 ? 'green' : 'orange'} style={{fontSize: '1.2em'}}/>
                          <Header.Content>
                          {item.agent ? <b>{item.agent.name}</b> : 'No Agent'}
                            <Header.Subheader><b>{item.channelType}</b> / {item.flow==0 ? 'Inbound' : 'Outbound'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <div style={{display: 'flex'}}>
                          <div style={{flex: '1'}}>
                            {item.channelType=='Twitter' && 
                              <Header as='h5'>
                                <Popup size='small' style={{background: item.content ? null : '#fafafa', padding: item.content ? '1em' : '0 .5em', borderRadius: '.5em', minHeight: '3em', minWidth: '10em'}} content={<>
                                  {item.content || (item.conversations && 
                                    <div id='wd_chat' style={{padding: '0em'}}>
                                      <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div className='chat' style={{maxHeight: screenHeight, overflowY: 'scroll'}}>
                                          <div>
                                            {item.conversations.map((item, idx)=>{
                                              return(
                                                <div key={idx} style={{margin: '.5em 0', textAlign: item.sender=='agent' ? 'right' : 'left'}}>
                                                  <div style={{display: 'inline-block', background: item.sender=='agent' ? '#d7f5fe' : '#ffebe9', padding: '.5em 1em .2em 1em', margin: item.sender=='agent' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='agent' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                                                    <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                                      <Header.Content>
                                                        <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                                        {item.message}
                                                        <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>
                                                      </Header.Content>
                                                    </Header>
                                                  </div>
                                                </div>
                                              )
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>} 
                                position='left center' inverted={item.content ? true : false}
                                trigger={
                                  <Icon 
                                  name={item.channelType.toLowerCase()}
                                  color='blue'
                                  style={{fontSize: '1.8em', paddingRight: '.5em', cursor: 'pointer'}}/>
                                } />
                                <Header.Content>
                                  {item.terminating}
                                  <Header.Subheader>{(item.content && item.content.length>25 ? item.content.substr(0,25) +' ...' : item.content)}</Header.Subheader>
                                </Header.Content>
                              </Header>
                            }
                            {item.channelType=='Facebook' && 
                              <Header as='h5'>
                                <Popup size='small' style={{background: item.content ? null : '#fafafa', padding: item.content ? '1em' : '0 .5em', borderRadius: '.5em', minHeight: '3em', minWidth: '10em'}} content={<>
                                  {item.conversations && 
                                    <div id='wd_chat' style={{padding: '0em'}}>
                                      <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div className='chat' style={{maxHeight: screenHeight, overflowY: 'scroll'}}>
                                          <div>
                                            {item.conversations.map((item, idx)=>{
                                              return(
                                                <div key={idx} style={{margin: '.5em 0', textAlign: item.sender=='agent' ? 'right' : 'left'}}>
                                                  <div style={{display: 'inline-block', background: item.sender=='agent' ? '#d7f5fe' : '#ffebe9', padding: '.5em 1em .2em 1em', margin: item.sender=='agent' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='agent' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                                                    <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                                      <Header.Content>
                                                        <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                                        {item.message}
                                                        <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>
                                                      </Header.Content>
                                                    </Header>
                                                  </div>
                                                </div>
                                              )
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </>} 
                                position='left center' inverted={item.content ? true : false}
                                trigger={
                                  <Icon 
                                  name={item.channelType.toLowerCase()}
                                  color='blue'
                                  style={{fontSize: '1.8em', paddingRight: '.5em', cursor: 'pointer'}}/>
                                } />
                                <Header.Content>
                                  {item.terminating}
                                  <Header.Subheader>{item.content || '-'}</Header.Subheader>
                                </Header.Content>
                              </Header>
                            }
                            {item.channelType=='Instagram' && 
                              <Header as='h5'>
                                <Popup size='small' style={{background: item.content ? null : '#fafafa', padding: item.content ? '1em' : '0 .5em', borderRadius: '.5em', minHeight: '3em', minWidth: '10em'}} content={<>
                                  {item.content || (item.conversations && 
                                    <div id='wd_chat' style={{padding: '0em'}}>
                                      <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div className='chat' style={{maxHeight: screenHeight, overflowY: 'scroll'}}>
                                          <div>
                                            {item.conversations.map((item, idx)=>{
                                              return(
                                                <div key={idx} style={{margin: '.5em 0', textAlign: item.sender=='agent' ? 'right' : 'left'}}>
                                                  <div style={{display: 'inline-block', background: item.sender=='agent' ? '#d7f5fe' : '#ffebe9', padding: '.5em 1em .2em 1em', margin: item.sender=='agent' ? '0 0 0 2em' : '0 2em 0 0', borderRadius: item.sender=='agent' ? '2.5em 1em 0em 2.5em' : '1em 2.5em 2.5em 0em'}}>
                                                    <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                                      <Header.Content>
                                                        <Header.Subheader style={{fontSize: '.8em'}}><b>{idx}. {item.sender}</b> / {item.stamp}</Header.Subheader>
                                                        {item.message}
                                                        <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}><Icon name={(item.status=='1' && 'check') || (item.status=='-1' && 'times') || 'ellipsis horizontal'} color={(item.status=='1' && 'blue') || (item.status=='-1' && 'red') || 'grey'} style={{margin: '0', padding: '0'}}/></Header.Subheader>
                                                      </Header.Content>
                                                    </Header>
                                                  </div>
                                                </div>
                                              )
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>} 
                                position='left center' inverted={item.content ? true : false}
                                trigger={
                                  <Icon 
                                  name={item.channelType.toLowerCase()}
                                  color='blue'
                                  style={{fontSize: '1.8em', paddingRight: '.5em', cursor: 'pointer'}}/>
                                } />
                                <Header.Content>
                                  {item.terminating}
                                  <Header.Subheader>{item.content || '-'}</Header.Subheader>
                                </Header.Content>
                              </Header>
                            }
                          </div>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.sentStamp!=null && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm:ss')}
                            <Header.Subheader>{item.sentStamp!=null && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {expand && <>
                      {/* <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.deliveredStamp!=null && format(parse(item.deliveredStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.deliveredStamp!=null && format(parse(item.deliveredStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell> */}
                      <Table.Cell style={{background: '#fffee0'}}>
                        <Header as='h5'>
                          <Header.Content>
                            {item.readStamp!=null && format(parse(item.readStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.readStamp!=null && format(parse(item.readStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.clickStamp!=null && format(parse(item.clickStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss')}
                            <Header.Subheader>{item.clickStamp!=null && format(parse(item.clickStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      </>}
                      <Table.Cell style={{background: remark ? '#e7feff' : '#fff2f2'}}>
                        {!remark && <Header as='h5'>
                          <Popup size='small' content={item.reason || '-'} inverted position='left center'
                          trigger={
                            <Icon name={(item.dropStamp && 'exclamation circle') || (item.status<0 && 'times circle outline') || (item.status>0 && 'check circle') || 'dot circle outline'} color={(item.dropStamp && 'orange') || (item.status<0 && 'red') || (item.status>0 && 'blue') || 'teal'} style={{cursor: 'pointer', fontSize: '1.25em'}}/> 
                          } />
                          <Header.Content>
                            {(item.dropStamp && ' Dropped') || socialStatus[item.status] || 'No reason'}
                            <Header.Subheader>{(item.reason && (item.reason.length>25 ? item.reason.substr(0,25) +' ...' : item.reason)) || '-'}</Header.Subheader>
                          </Header.Content>
                        </Header>}
                        {remark && 
                        <Header as='h5'>
                          <div style={{background: (item.status=='1' && '#84dbff') || (item.status=='0' && '#ffb3b3') || '#ddd', textAlign: 'right', display: 'inline-block', width: '4em', padding: '.5em', marginRight: '.5em'}}>{item.progress || '0'} %</div>
                          <Header.Content>
                            {item.remark  || 'No remark'}
                            {item.remark=='Rescheduled' && <Header.Subheader>{item.remarks.appointmentSession}, {item.remarks.appointmentDate!=null && format(parse(item.remarks.appointmentDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd/MMM yyyy')}</Header.Subheader>}
                            {item.remark=='Promised' && <Header.Subheader>{item.remarks.promisedDate!=null && format(parse(item.remarks.promisedDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd/MMM yyyy')}</Header.Subheader>}
                            {item.remark=='Paid' && <Header.Subheader>{item.remarks.paidDate!=null && format(parse(item.remarks.paidDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd/MMM yyyy')}</Header.Subheader>}
                            {item.remark!='Rescheduled' && item.remark!='Promised' && item.remark!='Paid' && <Header.Subheader><i>{(item.expired!=null && ('Exp. on '+ format(parse(item.expired, 'yyyy-MM-dd', new Date()), 'dd/MMM yyyy'))) || '-'}</i></Header.Subheader>}
                          </Header.Content>
                        </Header>}
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialBrowse)
