import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  ModalDescription,
  Modal,
} from 'semantic-ui-react'


class OmniphoneHeader extends Component {
  constructor(props){
    super(props)
  	this.state = {
    }
  }

  render() {
    const { screenWidth, screenHeight, dialMode, phoneState, uiMode, layout, sipAccount, mode, status, responsive }= this.props
    
    if (!responsive) {
      return (
        <Segment attached='top' style={{padding: '1em .5em', 
        background: phoneState=='online' ? '#257892' : (phoneState=='oncall' ? 'linear-gradient(90deg, rgba(255,66,0,1) 0%, rgba(255,115,37,1) 27%, rgba(255,87,0,1) 100%)' : '#000'), 
        
        border: 'none', borderBottom: '1px solid rgba(200,200,200,.2)', cursor: 'grab'}}  className="handle">
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              {status=='locked' &&
              <Header as='h4' inverted style={{padding: '.1em 0em', margin: '0'}}>
                <Icon name='lock' style={{fontSize: '1em'}}/>
                <Header.Content>
                  {mode=='predictive' ? 'Locked' : 'Locked'} / {layout!='floating' ? '...' : sipAccount}
                </Header.Content>
              </Header>}

              {/* {phoneState=='oncall' &&  */}
              {(status=='online' || status=='occupied') &&
              <Header as='h4' inverted style={{padding: '.1em 0em', margin: '0'}}>
                <Icon name='microphone' style={{fontSize: '1em'}}/>
                <Header.Content>
                  {mode=='predictive' ? 'Online' : 'Oncall'} / {layout!='floating' ? '...' : sipAccount}
                </Header.Content>
              </Header>}
              
              {/* {phoneState=='online' &&  */}
              {status=='connect' &&
              <Header as='h4' inverted style={{padding: '.1em 0em', margin: '0'}}>
                <Icon name='plug' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Connected  / {layout!='floating' ? '...' : sipAccount}
                </Header.Content>
              </Header>}

              {/* {phoneState=='online' &&  */}
              {status=='callback' &&
              <Header as='h4' style={{padding: '.1em 0em', margin: '0'}} inverted>
                <Icon name='phone volume' style={{fontSize: '1em', color: '#fff'}}/>
                <Header.Content>
                  Verification  / {layout!='floating' ? '...' : sipAccount}
                </Header.Content>
              </Header>}

              {phoneState=='offline' &&
              <Header as='h4' style={{padding: '.1em 0em', margin: '0'}} color='grey'>
                <Icon name='unlink' style={{fontSize: '1em', color: '#fff'}}/>
                <Header.Content>
                  Offline  / {layout!='floating' ? '...' : sipAccount}
                </Header.Content>
              </Header>}
            </div>
            <div>
              {layout=='floating' && <Icon name='compress' style={{color: '#fff', cursor: 'pointer'}} onClick={this.props.setSkinMode.bind(this, 'mini')} circular inverted/>}
            </div>
          </div>
        </Segment>
      );
    } else {
      return (
        <div style={{height: '2px', background: '#f20'}}></div>
      ) 
    }
  }
}

export default OmniphoneHeader