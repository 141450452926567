export default function postpaidReducer (state = {}, action) {
  switch (action.type) {
    case 'POSTPAID_RESET': {
        return({
            ...state,
            getPostpaid: {},
            updatePostpaid: {},
            createPostpaid: {},
        })
    }

    case 'POSTPAID_GET_PENDING': {
        return({...state, getPostpaid: {progress: 0}})
    }
    case 'POSTPAID_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getPostpaid: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getPostpaid: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'POSTPAID_GET_REJECTED': {
        return({...state, getPostpaid: {progress: 2, payload: action.payload}})
    }

    case 'POSTPAID_STATUS_PENDING': {
      return({...state, statusPostpaid: {progress: 0}})
    }
    case 'POSTPAID_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusPostpaid: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusPostpaid: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'POSTPAID_STATUS_REJECTED': {
        return({...state, statusPostpaid: {progress: 2, payload: action.payload}})
    }

    case 'POSTPAID_BROWSE_PENDING': {
      return({...state, browsePostpaid: {progress: 0}})
    }
    case 'POSTPAID_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browsePostpaid: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browsePostpaid: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'POSTPAID_BROWSE_REJECTED': {
        return({...state, browsePostpaid: {progress: 2, payload: action.payload}})
    }

    case 'POSTPAID_CREATE_PENDING': {
      return({...state, browsePostpaid: {progress: 0}})
    }
    case 'POSTPAID_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createPostpaid: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createPostpaid: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'POSTPAID_CREATE_REJECTED': {
        return({...state, createPostpaid: {progress: 2, payload: action.payload}})
    }

    case 'POSTPAID_UPDATE_PENDING': {
      return({...state, updatePostpaid: {progress: 0}})
    }
    case 'POSTPAID_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updatePostpaid: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updatePostpaid: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'POSTPAID_UPDATE_REJECTED': {
        return({...state, updatePostpaid: {progress: 2, payload: action.payload}})
    }

    case 'POSTPAID_REMOVE_PENDING': {
      return({...state, removePostpaid: {progress: 0}})
    }
    case 'POSTPAID_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removePostpaid: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removePostpaid: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'POSTPAID_REMOVE_REJECTED': {
        return({...state, removePostpaid: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}