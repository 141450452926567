import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Label, Image, Modal, Form, Input, Grid, Card, Icon, Select } from 'semantic-ui-react'

import { channelOptions, valueOptions, resultOptions }  from '../../common/StaticMasterData'
import DismissableMessage from '../../common/DismissableMessage'

import { SubmissionError, Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form'
import { ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, WysiwygField, DatePickerField, TextAreaField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { resetScript, getScript,updateScript, createScript } from "../../../actions/halo/scriptAction"
import { listTemplate, collectionStage, restructuringStage, telesaleStage, verificationStage } from "../../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ScriptForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    scriptStore: state.scriptStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetScript:(objParam) => dispatch(resetScript(objParam)),
    getScript:(objParam) => dispatch(getScript(objParam)),
    createScript:(objParam) => dispatch(createScript(objParam)),
    updateScript:(objParam) => dispatch(updateScript(objParam)),

    listTemplate:(objParam) => dispatch(listTemplate(objParam)),

    collectionStage:(objParam) => dispatch(collectionStage(objParam)),
    restructuringStage:(objParam) => dispatch(restructuringStage(objParam)),
    telesaleStage:(objParam) => dispatch(telesaleStage(objParam)),
    verificationStage:(objParam) => dispatch(verificationStage(objParam)),
  }
}

class ScriptForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetScript(this.props.mode)
    
    if (this.props.mode==2) { //update
      this.props.getScript({scriptId: this.props.scriptId})
    }
    this.props.collectionStage()
    this.props.restructuringStage()
    this.props.telesaleStage()
    this.props.verificationStage()
  }

  componentDidUpdate() {
    const { mode, modal } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.scriptStore.updateScript!=undefined ? this.props.scriptStore.updateScript : {}) : (this.props.scriptStore.createScript!=undefined ? this.props.scriptStore.createScript : {}))
    if (!modal && progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
    if (modal && progress==1 && code=='00') {
      this.props.closeModal('hide');
      this.props.listTemplate({search: {clientId: this.props.clientId || null}})
    }

    if (this.state.affirmation==undefined && this.props.affirmation) {
      this.setState({
        affirmation: this.props.affirmation
      })
    } 
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    mode==2 ? this.props.updateScript({...values, affirmation:  this.state.affirmation ? this.props.affirmation : null}) : this.props.createScript({...values, affirmation:  this.state.affirmation ? this.props.affirmation : null, ...{clientId: this.props.clientId}})
  }
  
  insertVariable(v) {
    // console.log(v)
    if (this.props.media!='Email') {
      this.props.dispatch(change('ScriptForm', 'script', (this.props.script||'') +' '+ v +' '));
    } else {
      navigator.clipboard.writeText(v)
    }
    // e.target.focus()
  }

  onChangeSelect(k, v) {
    this.setState({
      script: v.value
    })
  }

  setAffirmation() {
    this.setState({
      affirmation: !this.state.affirmation
    })
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, modal, screenWidth } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.scriptStore.updateScript!=undefined ? this.props.scriptStore.updateScript : {}) : (this.props.scriptStore.createScript!=undefined ? this.props.scriptStore.createScript : {}))

    const collectionStage= (this.props.masterStore.collectionStage!=undefined && this.props.masterStore.collectionStage.progress=='1' ? this.props.masterStore.collectionStage.data : [])
    const restructuringStage= (this.props.masterStore.restructuringStage!=undefined && this.props.masterStore.restructuringStage.progress=='1' ? this.props.masterStore.restructuringStage.data : [])
    const telesaleStage= (this.props.masterStore.telesaleStage!=undefined && this.props.masterStore.telesaleStage.progress=='1' ? this.props.masterStore.telesaleStage.data : [])
    const verificationStage= (this.props.masterStore.verificationStage!=undefined && this.props.masterStore.verificationStage.progress=='1' ? this.props.masterStore.verificationStage.data : [])
    
    const remarkMap= new Map();
    const remarkOptions = []
    collectionStage && collectionStage.map(item=>{
      remarkMap.set(item.value, item)
    })
    restructuringStage && restructuringStage.map(item=>{
      remarkMap.set(item.value, item)
    })
    telesaleStage && telesaleStage.map(item=>{
      remarkMap.set(item.value, item)
    })
    verificationStage && verificationStage.map(item=>{
      remarkMap.set(item.value, item)
    })

    remarkMap && [...remarkMap.keys()].map((key, i)=>{
      remarkOptions.push(
        { key: i, value: remarkMap.get(key).value, text: remarkMap.get(key).text }
      )
    })

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            {this.props.mode!=3 &&
            <Form.Group widths='16'>
              <Field name='isEnabled' component={CheckboxField}
              width={8}
              toggle
              caption='Enabled'
              label='Enable Now !'
              placeholder='Status enable/disabled' />
              {/* <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' /> */}
            </Form.Group>}
            <Form.Group widths='equal'>
              <Field name='name' component={InputField}
              validate={[required]}
              label='Name'
              placeholder='Script Name' />
              <Field name='media' component={SelectField}
              options={channelOptions()}
              label='Media'
              placeholder='Media Container' />
            </Form.Group>
            {/* <Form.Group widths='equal'>
              <Field name='descript' component={InputField}
              label='Description'
              placeholder='Description' />
            </Form.Group> */}
          </Segment>
          <Segment>
            {/* <Header color='blue' as='h5'>
              <Icon name='code' style={{fontSize: '1em'}}/>
              <Header.Content>Script / Code Snippet</Header.Content>
            </Header> */}
            <div style={{display: (modal && 'block') ||'flex'}}>
              <div className='variable' style={{width: (modal && 'auto') || '220px', marginTop: '.3em', marginRight: !modal && '1.3em', padding: '1em 1.3em', background: '#f5f5f5', border: '1px solid #ddd', textAlign: 'center'}}>
                <Header as='h5'>
                  <Header.Content>
                    Dynamic <b>Variable</b>
                    <Header.Subheader>Applicable Variables to script.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Select style={{marginBottom: '1em'}} label='Variables' fluid onChange={this.onChangeSelect.bind(this)}
                options={[
                  {key: 1, value: 'telesales', text: 'Telesales'},
                  {key: 2, value: 'collection', text: 'Collection'},
                  <Divider key='0' style={{margin: '0'}}/>,
                  {key: 3, value: 'payment', text: 'Payment Method'},
                ]}
                />
                <Label.Group style={{textAlign: 'center'}}>
                  <Popup inverted size='small' header='{stampId}' content='Unique Stamp Id' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{stampId}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Stamp Id</Label> 
                  } />
                  <Popup inverted size='small' header='{custTitle}' content='Customer or target title' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{custTitle}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Cust. Title</Label> 
                  } />
                  <Popup inverted size='small' header='{custName}' content='Customer or target name' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{custName}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Cust. Name</Label> 
                  } />
                  <Popup inverted size='small' header='{custRef}' content='Customer ref. identity' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{custRef}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Cust. Ref. Id</Label>
                  } />
                  <Popup inverted size='small' header='{contractRef}' content='Contract ref. for customer' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{contractRef}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Contract Ref.</Label>
                  } />
                  <Popup inverted size='small' header='{merchantName}' content='Merchant information / name' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{merchantName}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Merchant</Label>
                  } />

                  <Popup inverted size='small' header='{city}' content='City where customer stays' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{city}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>City</Label>
                  } />
                  <Popup inverted size='small' header='{information}' content='General information which important for customers' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{information}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Free Info</Label>
                  } />

                  {this.state.script && <Divider style={{margin: '.5em 0'}} /> }

                  {this.state.script=='collection' && <>
                    <Popup inverted size='small' header='{billAmount}' content='Bill amount which must paid' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{billAmount}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Bill Amount</Label>
                    } />
                    <Popup inverted size='small' header='{overdueAmount}' content='Penalty or fine amount' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{overdueAmount}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Penalty Amt.</Label>
                    } />
                    <Popup inverted size='small' header='{overdueDay}' content='Payment was late in days' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{overdueDay}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Late In Days</Label> 
                    } />
                    <Popup inverted size='small' header='{billDate}' content='Date when billing generated' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{billDate}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Billing Date</Label>
                    } />
                    <Popup inverted size='small' header='{dueDate}' content='Due date when bill should be paid ' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{dueDate}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Due Date</Label>
                    } />
                  </>}

                  {this.state.script=='telesales' && <>
                    <Popup inverted size='small' header='{product}' content='Product information / name' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{product}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Product</Label>
                    } />
                    <Popup inverted size='small' header='{service}' content='Subscription product or service information' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{service}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Subsc. Info</Label>
                    } />
                  </>}

                  {this.state.script=='payment' && <>
                    <Popup inverted size='small' header='{vaBNI}' content='VA BNI' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaBNI}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA BNI</Label>
                    } />
                    <Popup inverted size='small' header='{vaBRI}' content='VA BRI' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaBRI}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA BRI</Label>
                    } />
                    <Popup inverted size='small' header='{vaBSI}' content='VA BSI' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaBSI}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA BSI</Label>
                    } />
                    <Popup inverted size='small' header='{vaBTN}' content='VA BTN' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaBTN}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA BTN</Label>
                    } />
                    <Popup inverted size='small' header='{vaBCA}' content='VA BCA' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaBCA}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA BCA</Label>
                    } />
                    <Popup inverted size='small' header='{vaMandiri}' content='VA Mandiri' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaMandiri}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Mandiri</Label>
                    } />
                    <Popup inverted size='small' header='{vaPermata}' content='VA Permata' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaPermata}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Permata</Label>
                    } />
                    <Popup inverted size='small' header='{vaPanin}' content='VA Panin' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaPanin}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Panin</Label>
                    } />
                    <Popup inverted size='small' header='{vaNiaga}' content='VA Niaga' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaNiaga}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Niaga</Label>
                    } />
                    <Popup inverted size='small' header='{vaMuamalat}' content='VA Muamalat' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaMuamalat}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Muam..</Label>
                    } />
                    <Popup inverted size='small' header='{vaIndomaret}' content='VA Indomaret' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaIndomaret}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Indo..</Label>
                    } />
                    <Popup inverted size='small' header='{vaAlfamart}' content='VA Alfamart' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaAlfamart}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Alfa..</Label>
                    } />
                  </>}
                </Label.Group>
                <Divider style={{margin: '.5em 0'}}/>
                <Header as='h5' style={{margin: '0'}}>
                  <Header.Content>
                    Click to copied and paste your selected variable if not added automaticaly.
                  </Header.Content>
                </Header>
              </div>
              {/* <div className='variable' style={{width: (modal && 'auto') || '250px', marginTop: '1.6em', marginRight: !modal && '1.3em', padding: '1em 1.5em', background: '#f5f5f5', border: '1px solid #ddd'}}>
                <Header as='h3' color='red'>
                  <Header.Content>
                    ! Dynamic Variable
                    <Header.Subheader>List of target's info variable which you can apply to script.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Label.Group>
                  <Popup header='{custName}' content='Customer or target name' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{custName}')}>Customer Name</Label> 
                  } />
                  <Popup header='{billAmount}' content='Bill amount which must paid' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{billAmount}')}>Bill Amount</Label>
                  } />
                  <Popup header='{overdueAmount}' content='Penalty or fine amount' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{overdueAmount}')}>Penalty Amount</Label>
                  } />
                  <Popup header='{overdueDay}' content='Payment was late in days' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{overdueDay}')}>Late In Days</Label> 
                  } />
                  <Popup header='{service}' content='Subscription product or service information' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{service}')}>Subscription Info</Label>
                  } />
                  <Popup header='{billDate}' content='Date when billing generated' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{billDate}')}>Billing Date</Label>
                  } />
                  <Popup header='{dueDate}' content='Due date when bill should be paid ' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{dueDate}')}>Payment Due Date</Label>
                  } />
                  
                  <Popup header='{product}' content='Product information / name' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{product}')}>Product</Label>
                  } />
                  <Popup header='{information}' content='General information which important for customers' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{information}')}>Information</Label>
                  } />
                  <Popup header='{merchantName}' content='Merchant information / name' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{merchantName}')}>Merchant Name</Label>
                  } />
                  <Popup header='{custRef}' content='Customer ref. identity' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{custRef}')}>Cust. Ref. Id</Label>
                  } />
                  <Popup header='{contractRef}' content='Contract ref. for customer' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{contractRef}')}>Cust. Contract</Label>
                  } />
                  <Popup header='{city}' content='City where customer stays' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{city}')}>City</Label>
                  } />
                  
                  <Popup header='{vaBNI}' content='VA BNI' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaBNI}')}>VA BNI</Label>
                  } />
                  <Popup header='{vaBRI}' content='VA BRI' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaBRI}')}>VA BRI</Label>
                  } />
                  <Popup header='{vaBSI}' content='VA BSI' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaBSI}')}>VA BSI</Label>
                  } />
                  <Popup header='{vaBTN}' content='VA BTN' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaBTN}')}>VA BTN</Label>
                  } />
                  <Popup header='{vaBCA}' content='VA BCA' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaBCA}')}>VA BCA</Label>
                  } />
                  <Popup header='{vaMandiri}' content='VA Mandiri' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaMandiri}')}>VA Mandiri</Label>
                  } />
                  <Popup header='{vaPermata}' content='VA Permata' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaPermata}')}>VA Permata</Label>
                  } />
                  <Popup header='{vaPanin}' content='VA Panin' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaPanin}')}>VA Panin</Label>
                  } />
                  <Popup header='{vaNiaga}' content='VA Niaga' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaNiaga}')}>VA Niaga</Label>
                  } />
                  <Popup header='{vaMuamalat}' content='VA Muamalat' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaMuamalat}')}>VA Muamalat</Label>
                  } />
                  <Popup header='{vaIndomaret}' content='VA Indomaret' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaIndomaret}')}>VA Indomaret</Label>
                  } />
                  <Popup header='{vaAlfamart}' content='VA Alfamart' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaAlfamart}')}>VA Alfamart</Label>
                  } />
                </Label.Group>
                <Divider style={{margin: '.5em 0'}}/>
                <Header as='h6' style={{margin: '0'}}>
                  <Header.Content>
                    Click to copied and paste your selected variable if not added automaticaly.
                  </Header.Content>
                </Header>
              </div> */}
              <div style={{flex: '1'}}>
                <div style={{display: 'flex'}}>
                  {this.props.media=='IVR' && 
                  <div style={{paddingRight: '1em'}}>
                    <Label circular size='big' color='yellow'>1</Label>
                  </div>}
                  <div style={{flex: 1}}>
                    <Form.Group widths='16'>
                      {this.props.media!='Email' && 
                      <Field name='script' component={TextAreaField}
                      width='16'
                      rows={this.props.media!='IVR' ? 16 : 12}
                      validate={[required]}
                      label={<b>Content Script</b>} />}

                      {this.props.media=='Email' && 
                      <Field name='script' component={WysiwygField} 
                      modal={modal}
                      screenWidth={screenWidth}
                      width='16'
                      rows='8'
                      validate={[required]}
                      label={<b>Content Script</b>} />}
                    </Form.Group>
                    {(this.props.media=='Email' || this.props.media=='Whatsapp' || this.props.media=='Telegram' || this.props.media=='Facebook' || this.props.media=='Twitter' || this.props.media=='Instagram') && <>
                    <Divider hidden/>
                    <Form.Group widths='equal' style={{marginTop: '2em'}}>
                      <Form.Field>
                        <Field name='image' component={ImageUploadField}
                        // width={420} 
                        height={420} 
                        aspectRatio= '1:1' />
                      </Form.Field>
                    </Form.Group>
                    </>}
                  </div>
                </div>
                <div style={{display: 'flex', marginTop: '1.5em'}}>
                  {this.props.media=='IVR' && <div style={{paddingRight: '1em'}}>
                    <Label circular size='big' color='yellow'>2</Label>
                  </div>}
                  <div style={{flex: 1}}>
                    {this.props.media=='IVR' && <>
                    <div style={{display: 'flex', marginBottom: '.5em'}}>
                      <div style={{flex: 1}}>
                        <Header as='h4' color='blue'>
                          <b>Affirmation Script</b>
                          <Header.Subheader>{!this.state.affirmation ? 'No affirmation / feedback applied' : 'Set affirmation feedback scene'}</Header.Subheader>
                        </Header>
                      </div>
                      <div>
                        <Icon name={this.state.affirmation ? 'toggle on' : 'toggle off'} color={this.state.affirmation ? 'red' : 'grey'} style={{transform: 'rotate(90deg)', cursor: 'pointer', fontSize: '1.6em', margin: '.3em 0 0 0'}} onClick={this.setAffirmation.bind(this)}/>
                      </div>
                    </div>
                    {this.state.affirmation && <>
                      <Form.Group widths='16'>
                        <Field name='affirmation' component={TextAreaField} style={{fontWeight: 'bold'}}
                        width='16'
                        rows='3'
                        validate={[required]}
                        // label={<b>Affirmation Script</b>}
                        placeholder='Closing greeting and proposition for affirmation code/key to collect self remark and feedback.' />
                      </Form.Group> 
                      <Form.Group widths='16'>
                        <Field name='keypad1' component={SelectField}
                        width='2'
                        options={valueOptions('keypad')}
                        validate={[required]}
                        placeholder='Key'
                        label={<b>Key 1</b>} />
                        <Field name='feedback1' component={SelectField}
                        width='6'
                        options={remarkOptions}
                        validate={[required]}
                        placeholder=''
                        label={<b>Opsi 1</b>} />
                        <Field name='keypad2' component={SelectField}
                        width='2'
                        options={valueOptions('keypad')}
                        validate={[required]}
                        placeholder='Key'
                        label={<b>Key 2</b>} />
                        <Field name='feedback2' component={SelectField}
                        width='6'
                        options={remarkOptions}
                        validate={[required]}
                        placeholder='Caption'
                        label={<b>Opsi 2</b>} />
                      </Form.Group>
                    </>}
                  </>}
                  </div>
                </div>
                {this.state.affirmation && <>
                  <div style={{display: 'flex', marginTop: '1.5em'}}>
                    {this.props.media=='IVR' && <div style={{paddingRight: '1em'}}>
                      <Label circular size='big' color='yellow'>3</Label>
                    </div>}
                    <div style={{flex: 1}}>
                      {this.props.media=='IVR' && <>
                        <Form.Group widths='16'>
                          <Field name='thankyou' component={TextAreaField} style={{fontWeight: 'bold'}}
                          width='16'
                          rows='2'
                          validate={[required]}
                          placeholder='Thank you greeting when feedback received.'
                          label={<b>Thank You Script</b>} />
                        </Form.Group>
                        <Form.Group widths='16'>
                          <Field name='nofeedback' component={TextAreaField} style={{fontWeight: 'bold'}}
                          width='16'
                          rows='2'
                          validate={[required]}
                          placeholder='Thank you and reminder greeting when no feedback.'
                          label={<b>No Feedback Script</b>} />
                        </Form.Group>
                      </>}
                    </div>
                  </div>
                </>}
                
                {/* <Form.Group widths='16'>
                  <Field name='video' component={TextAreaField}
                  width='16'
                  label='Video Attachment' />
                </Form.Group> */}
              </div>
            </div>
          </Segment>
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Script' : 'Insert Script') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.scriptStore.getScript!=undefined && state.scriptStore.getScript.progress==1 ? state.scriptStore.getScript.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      media: selector(state, 'media'),
      script: selector(state, 'script'),
      affirmation: selector(state, 'affirmation'),
    }),
  )
  (reduxForm({
    form: 'ScriptForm',	// a unique identifier for this form
  })(ScriptForm)
  )
)