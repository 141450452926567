import { combineReducers } from 'redux'
// import { loadingBarReducer } from 'react-redux-loading-bar'
import { reducer as reduxFormReducer } from 'redux-form';

import authReducer from './auth/authReducer'

import masterReducer from './halo/masterReducer'
import accountReducer from './halo/accountReducer'
import messageReducer from './halo/messageReducer'
import settingReducer from './halo/settingReducer'

//RESOURCE
import agentReducer from './halo/agentReducer'
import workgroupReducer from './halo/workgroupReducer'
import scriptReducer from './halo/scriptReducer'

import billingReducer from './halo/billingReducer'
import clientReducer from './halo/clientReducer'
import contractReducer from './halo/contractReducer'

import customerReducer from './halo/customerReducer'
import visitorReducer from './halo/visitorReducer'
import contactReducer from './halo/contactReducer'

import productReducer from './halo/productReducer'
import appointmentReducer from './halo/appointmentReducer'

//ECOSYSTEM
import syslogReducer from './halo/syslogReducer'

import bloggingReducer from './halo/bloggingReducer'
import documentReducer from './halo/documentReducer'

import voipReducer from './halo/voipReducer'
import trespassingReducer from './halo/trespassingReducer'
import whitelistReducer from './halo/whitelistReducer'
import postpaidReducer from './halo/postpaidReducer'
import recordReducer from './halo/recordReducer'
import prepaidReducer from './halo/prepaidReducer'
import deductReducer from './halo/deductReducer'
import topupReducer from './halo/topupReducer'
import ratesReducer from './halo/ratesReducer'

//WHATSAPP
import waserverReducer from './whatsapp/waserverReducer'
import wachatReducer from './whatsapp/wachatReducer'
import wacontactReducer from './whatsapp/wacontactReducer'
import wacommandReducer from './whatsapp/wacommandReducer'


//MIDDLEWARE
import middlewareReducer from './middleware/middlewareReducer'

//SERVICE
import serviceReducer from './halo/serviceReducer'

// WEBSOCKET
import socketReducer from './socket/socketReducer'
import phoneReducer from './socket/phoneReducer'

//FLOW - OMNI CHANNEL
import omniwidgetReducer from './flow/omniwidgetReducer'
import interactiveReducer from './flow/interactiveReducer'
import omnichannelReducer from './flow/omnichannelReducer'

//STREAM - OUTBOUND CAMPAIGN
import bridgingReducer from './stream/bridgingReducer'
import omniphoneReducer from './stream/omniphoneReducer'

import statisticReducer from './halo/statisticReducer'
import performanceReducer from './halo/performanceReducer'
import reportReducer from './stream/reportReducer'

import campaignReducer from './stream/campaignReducer'
import targetReducer from './stream/targetReducer'
import assignReducer from './stream/assignReducer'
import transactReducer from './stream/transactReducer'

import voiceReducer from './stream/voiceReducer'
import messagingReducer from './stream/messagingReducer'
import socialReducer from './stream/socialReducer'

import dialerReducer from './stream/dialerReducer'

//TALK - IN-APP VOICE
import mauReducer from './talk/mauReducer'
import tunnelReducer from './talk/tunnelReducer'
import occupancyReducer from './talk/occupancyReducer'
import conferenceReducer from './talk/conferenceReducer'
import linkwidgetReducer from './talk/linkwidgetReducer'
import linkReducer from './talk/linkReducer'

// // COLLABORATION
// import taskReducer from './collab/taskReducer'
// import todoReducer from './collab/todoReducer'
// import linkedReducer from './collab/linkedReducer'
// import groupsReducer from './collab/groupsReducer'
// import communityReducer from './collab/communityReducer'
// import summaryReducer from './collab/summaryReducer'
// import whiteboardReducer from './collab/whiteboardReducer'

// // HCM
// import sitesReducer from './hcm/sitesReducer'
// import organizationReducer from './hcm/organizationReducer'

// TICKET
import masterReducerTicket from './service/masterReducer'
import ticketReducer from './service/ticketReducer'
import interactionReducer from './service/interactionReducer'
import bucketReducer from './service/bucketReducer'

import knowledgeReducer from './service/knowledgeReducer'

// import monitoringReducer from './service/monitoringReducer'
// import ticketReducer from './service/ticketReducer'
// import tickethandlingReducer from './service/tickethandlingReducer'
// import handlingReducer from './service/handlingReducer'
// import segmentReducer from './service/segmentReducer'
// import acknowledgeReducer from './service/acknowledgeReducer'

// //CRM PLATFORM
// import dashboardReducer from './crm/dashboardReducer'

// import prospectReducer from './crm/prospectReducer'
// import closingReducer from './crm/closingReducer'
// import purchaseReducer from './crm/purchaseReducer'
// import subscribeReducer from './crm/subscribeReducer'
// import custbillReducer from './crm/custbillReducer'
// import custpaymentReducer from './crm/custpaymentReducer'
// import voucherReducer from './crm/voucherReducer'
// import discountReducer from './crm/discountReducer'
// import reachReducer from './crm/reachReducer'
// import acquisitionReducer from './crm/acquisitionReducer'
// import conversionReducer from './crm/conversionReducer'
// import retentionReducer from './crm/retentionReducer'
// import loyaltyReducer from './crm/loyaltyReducer'

// // COLLECT
// import collectingReducer from './collect/collectingReducer'
// import debtorReducer from './collect/debtorReducer'
// import interactionReducer from './collect/interactionReducer'
// import bucketReducer from './collect/bucketReducer'
// import allocationReducer from './collect/allocationReducer'
// import collectorReducer from './collect/collectorReducer'
// import worksheetReducer from './collect/worksheetReducer'
// import commissionReducer from './collect/commissionReducer'
// import incentiveReducer from './collect/incentiveReducer'
// import achievementReducer from './collect/achievementReducer'
// import inhouseReducer from './collect/inhouseReducer'
// import activityReducer from './collect/activityReducer'
// import repositoryReducer from './collect/repositoryReducer'

//SOCIAL MEDIA
import socmed_accountReducer from './socmed/accountReducer'
import socmed_associateReducer from './socmed/associateReducer'
import socmed_timelineReducer from './socmed/timelineReducer'
import socmed_interactionReducer from './socmed/interactionReducer'
import socmed_profilingReducer from './socmed/profilingReducer'


//SYSTEM
import backendReducer from './system/backendReducer'
import workflowReducer from './system/workflowReducer'
import schedulerReducer from './system/schedulerReducer'
import watchdogReducer from './system/watchdogReducer'
import disasterReducer from './system/disasterReducer'
import errorReducer from './system/errorReducer'
import audittrailReducer from './system/audittrailReducer'
import apilogReducer from './system/apilogReducer'

const reducers = combineReducers({

  authStore: authReducer,

  masterStore: masterReducer,
  accountStore: accountReducer,
  messageStore: messageReducer,
  settingStore: settingReducer,

  //RESOURCE
  agentStore: agentReducer,
  workgroupStore: workgroupReducer,
  scriptStore: scriptReducer,
  contactStore: contactReducer,

  billingStore: billingReducer,
  clientStore: clientReducer,
  contractStore: contractReducer,

  customerStore: customerReducer,
  productStore: productReducer,
  visitorStore: visitorReducer,
  appointmentStore: appointmentReducer,

  //ECOSYSTEM
  syslogStore: syslogReducer,

  bloggingStore: bloggingReducer,
  documentStore: documentReducer,

  voipStore: voipReducer,
  trespassingStore: trespassingReducer,
  whitelistStore: whitelistReducer,
  postpaidStore: postpaidReducer,
  recordStore: recordReducer,
  prepaidStore: prepaidReducer,
  deductStore: deductReducer,
  topupStore: topupReducer,
  ratesStore: ratesReducer,

  middlewareStore: middlewareReducer,

  // WHATSAPP
  waserverStore: waserverReducer,
  wachatStore: wachatReducer,
  wacontactStore: wacontactReducer,
  wacommandStore: wacommandReducer,

  // SERVICE
  serviceStore: serviceReducer,
  
  // WEBSOCKET
  socketStore: socketReducer,
  phoneStore: phoneReducer,

  //FLOW - OMNI CHANNEL
  omniwidgetStore: omniwidgetReducer,
  interactiveStore: interactiveReducer,
  omnichannelStore: omnichannelReducer,

  //STREAM - OUTBOUND CAMPAIGN
  bridgingStore: bridgingReducer,
  omniphoneStore: omniphoneReducer,

  statisticStore: statisticReducer,
  performanceStore: performanceReducer,
  reportStore: reportReducer,

  campaignStore: campaignReducer,
  targetStore: targetReducer,
  assignStore: assignReducer,
  transactStore: transactReducer,
  
  voiceStore: voiceReducer,
  messagingStore: messagingReducer,
  socialStore: socialReducer,

  dialerStore: dialerReducer,
  
  //TALK - IN-APP VOICE
  mauStore: mauReducer,
  tunnelStore: tunnelReducer,
  occupancyStore: occupancyReducer,
  conferenceStore: conferenceReducer,
  linkwidgetStore: linkwidgetReducer,
  linkStore: linkReducer,

  // // COLLABORATION
  // taskStore: taskReducer,
  // todoStore: todoReducer,
  // linkedStore: linkedReducer,
  // groupsStore: groupsReducer,
  // communityStore: communityReducer,
  // summaryStore: summaryReducer,
  // whiteboardStore: whiteboardReducer,

  // // HCM
  // sitesStore: sitesReducer,
  // organizationStore: organizationReducer,

  // TICKET
  masterStoreTicket: masterReducerTicket,
  ticketStore: ticketReducer,
  interactionStore: interactionReducer,
  bucketStore: bucketReducer,
  knowledgeStore: knowledgeReducer,
  
  // monitoringStore: monitoringReducer,
  // ticketStore: ticketReducer,
  // tickethandlingStore: tickethandlingReducer,
  // handlingStore: handlingReducer,
  // segmentStore: segmentReducer,
  // acknowledgeStore: acknowledgeReducer,

  // //CRM PLATFORM
  // dashboardStore: dashboardReducer,
  // prospectStore: prospectReducer,
  // closingStore: closingReducer,
  // purchaseStore: purchaseReducer,
  // subscribeStore: subscribeReducer,
  // custbillStore: custbillReducer,
  // custpaymentStore: custpaymentReducer,
  // voucherStore: voucherReducer,
  // discountStore: discountReducer,
  // reachStore: reachReducer,
  // acquisitionStore: acquisitionReducer,
  // conversionStore: conversionReducer,
  // retentionStore: retentionReducer,
  // loyaltyStore: loyaltyReducer,

  // // COLLECT
  // collectingStore: collectingReducer,
  // debtorStore: debtorReducer,
  // interactionStore: interactionReducer,
  // bucketStore: bucketReducer,
  // allocationStore: allocationReducer,
  // collectorStore: collectorReducer,
  // worksheetStore: worksheetReducer,
  // commissionStore: commissionReducer,
  // incentiveStore: incentiveReducer,
  // achievementStore: achievementReducer,
  // inhouseStore: inhouseReducer,
  // activityStore: activityReducer,
  // repositoryStore: repositoryReducer,

  //SOCIAL MEDIA
  socmed_accountStore: socmed_accountReducer,
  socmed_associateStore: socmed_associateReducer,
  socmed_timelineStore: socmed_timelineReducer,
  socmed_interactionStore: socmed_interactionReducer,
  socmed_profilingStore: socmed_profilingReducer,
  
  //SYSTEM
  backendStore: backendReducer,
  workflowStore: workflowReducer,
  schedulerStore: schedulerReducer,
  watchdogStore: watchdogReducer,
  disasterStore: disasterReducer,
  errorStore: errorReducer,
  audittrailStore: audittrailReducer,
  apilogStore: apilogReducer,

  // loadingBar: loadingBarReducer,
  form: reduxFormReducer,
})

export default reducers
