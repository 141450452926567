export default function wacommandReducer (state = {}, action) {
  switch (action.type) {
    case 'WACOMMAND_RESET': {
        return({
            ...state,
            getWacommand: {},
            synchgroupWacommand: {},
            synchcontactWacommand: {},
        })
    }

    case 'WACOMMAND_SENDMESSAGE_PENDING': {
      return({...state, sendmessageWacommand: {progress: 0}})
    }
    case 'WACOMMAND_SENDMESSAGE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, sendmessageWacommand: {progress: 2, payload: action.payload}})
        } else {
            return({...state, sendmessageWacommand: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACOMMAND_SENDMESSAGE_REJECTED': {
        return({...state, sendmessageWacommand: {progress: 2, payload: action.payload}})
    }

    case 'WACOMMAND_REPLYMESSAGE_PENDING': {
      return({...state, replymessageWacommand: {progress: 0}})
    }
    case 'WACOMMAND_REPLYMESSAGE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, replymessageWacommand: {progress: 2, payload: action.payload}})
        } else {
            return({...state, replymessageWacommand: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACOMMAND_REPLYMESSAGE_REJECTED': {
        return({...state, replymessageWacommand: {progress: 2, payload: action.payload}})
    }

    case 'WACOMMAND_SYNCHCONTACT_PENDING': {
      return({...state, replymessageWacommand: {progress: 0}})
    }
    case 'WACOMMAND_SYNCHCONTACT_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, synchcontactWacommand: {progress: 2, payload: action.payload}})
        } else {
            return({...state, synchcontactWacommand: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACOMMAND_SYNCHCONTACT_REJECTED': {
        return({...state, synchcontactWacommand: {progress: 2, payload: action.payload}})
    }

    case 'WACOMMAND_SYNCHGROUP_PENDING': {
      return({...state, synchgroupWacommand: {progress: 0}})
    }
    case 'WACOMMAND_SYNCHGROUP_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, synchgroupWacommand: {progress: 2, payload: action.payload}})
        } else {
            return({...state, synchgroupWacommand: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACOMMAND_SYNCHGROUP_REJECTED': {
        return({...state, synchgroupWacommand: {progress: 2, payload: action.payload}})
    }

    case 'WACOMMAND_NEWCHAT_PENDING': {
      return({...state, newchatWacommand: {progress: 0}})
    }
    case 'WACOMMAND_NEWCHAT_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, newchatWacommand: {progress: 2, payload: action.payload}})
        } else {
            return({...state, newchatWacommand: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WACOMMAND_NEWCHAT_REJECTED': {
        return({...state, newchatWacommand: {progress: 2, payload: action.payload}})
    }
    
    default: {
      return state
    }
  }
}