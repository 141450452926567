import React, { Component } from 'react'
import { Menu } from 'semantic-ui-react'

export default class AuthenticateMenu extends Component {
  state = {}

  onClick(key) {
    if (this.props.openMenu!==undefined) {
      this.props.openMenu(key)
    }
  } 

  render() {
    const { activeItem } = this.state

    return (
      <Menu style={{background: 'none', display: 'inline-flex', textAlign: 'center', border: 'none',  borderRadius: '0', boxShadow: 'none'}}>
        <Menu.Item
          icon= 'user outline'
          name='signIn'
          active={activeItem === this.props.menu}
          onClick={this.onClick.bind(this,'signin')}
        />
        <Menu.Item
          icon= 'heart outline'
          name='signUp'
          active={activeItem === this.props.menu}
          onClick={this.onClick.bind(this,'signup')}
        />
        <Menu.Item
          icon= 'lock open'
          name='reset'
          active={activeItem === this.props.menu}
          onClick={this.onClick.bind(this,'password')}
        />
      </Menu>
    )
  }
}
