import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'


import CallTunnelWidget from '../../../libraries/widget/CallTunnelWidget';
import MAUWidget from '../../../libraries/widget/MAUWidget';

import { connect } from "react-redux"
import { occupancyTunnel, occupancyMau } from "../../../actions/talk/occupancyAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    occupancyStore: state.occupancyStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    occupancyTunnel:(objParam) => dispatch(occupancyTunnel(objParam)),
    occupancyMau:(objParam) => dispatch(occupancyMau(objParam)),
  }
}


class OccupancyBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  componentDidMount() {
    this.props.occupancyTunnel({search: {}})
    this.props.occupancyMau({search: {voipGroup: 'Customer'}})
  }

  render() {
    const { screenWidth, screenHeight }= this.props

    return (
      <div style={{minHeight: screenHeight}}>
        <div style={{padding: '0 0 1em 0'}}>
          <CallTunnelWidget />
        </div>
        <div style={{padding: '1em 0'}}>
          <MAUWidget screenWidth={screenWidth} screenHeight={screenHeight}/>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OccupancyBrowse)
