import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetAppointment(objParam) {
  return {
      type: 'APPOINTMENT_RESET',
      payload: objParam
  }
}

export function getAppointment(objParam) {
  return {
      type: 'APPOINTMENT_GET',
      payload:
        axios.post(
          urlAPI + 'appointment/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusAppointment(objParam) {
  return {
      type: 'APPOINTMENT_STATUS',
      payload:
        axios.post(
          urlAPI + 'appointment/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseAppointment(objParam) {
  return {
      type: 'APPOINTMENT_BROWSE',
      payload:
        axios.post(
          urlAPI + 'appointment/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createAppointment(objParam) {
  return {
      type: 'APPOINTMENT_CREATE',
      payload:
        axios.post(
          urlAPI + 'appointment/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateAppointment(objParam) {
  return {
      type: 'APPOINTMENT_UPDATE',
      payload:
        axios.post(
          urlAPI + 'appointment/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeAppointment(objParam) {
  return {
      type: 'APPOINTMENT_REMOVE',
      payload:
        axios.post(
          urlAPI + 'appointment/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
