import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetTransact(objParam) {
  return {
      type: 'TRANSACT_RESET',
      payload: objParam
  }
}

export function getTransact(objParam) {
  return {
      type: 'TRANSACT_GET',
      payload:
        axios.post(
          urlAPI + 'transact/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusTransact(objParam) {
  return {
      type: 'TRANSACT_STATUS',
      payload:
        axios.post(
          urlAPI + 'transact/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseTransact(objParam) {
  return {
      type: 'TRANSACT_BROWSE',
      payload:
        axios.post(
          urlAPI + 'transact/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createTransact(objParam) {
  return {
      type: 'TRANSACT_CREATE',
      payload:
        axios.post(
          urlAPI + 'transact/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateTransact(objParam) {
  return {
      type: 'TRANSACT_UPDATE',
      payload:
        axios.post(
          urlAPI + 'transact/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeTransact(objParam) {
  return {
      type: 'TRANSACT_REMOVE',
      payload:
        axios.post(
          urlAPI + 'transact/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
