import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form, Label } from 'semantic-ui-react'

import { connect } from "react-redux"
import { navigationOmniwidget } from "../../actions/flow/omniwidgetAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniwidgetStore: state.omniwidgetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    navigationOmniwidget:(objParam) => dispatch(navigationOmniwidget(objParam)),
  }
}

class OmniwidgetMenu extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        checkedLevel: null,
        navigations: null,
      };
    }

    componentDidMount() {
      const { navigations }= this.state
      const { data }= (this.props.omniwidgetStore.navigationOmniwidget!=undefined && this.props.omniwidgetStore.navigationOmniwidget.progress=='1' ? this.props.omniwidgetStore.navigationOmniwidget : {})
      // console.log(data)
      if (!navigations && data && data.menu) {
        this.setState({
          navigations: data.menu,
        })
      }
    }
    
    setShortcut(level) {
      const { navigations }= this.state
      if (navigations) {
        for (let i=0; i<10; i++) {
          if (navigations[i]) {
            navigations[i].checked= false;
          }
        }
        navigations[level].checked= !navigations[level].checked;
      }

      this.setState({
        navigations: navigations,
        checkedLevel: level,
      })
    }

    render() {
      const { mode, checkedLevel, navigations }= this.state
      const { screenWidth, screenHeight }= this.props

      const { data }= (this.props.omniwidgetStore.navigationOmniwidget!=undefined && this.props.omniwidgetStore.navigationOmniwidget.progress=='1' ? this.props.omniwidgetStore.navigationOmniwidget : {})
      
      const navItems= []
      for (let i=0; i<10; i++) {
        if (navigations && navigations[i]) {
          navItems.push(navigations[i])
        }
      }

      const agent= {
        avatar: 'https://react.semantic-ui.com/images/avatar/large/patrick.png',
        name: 'James',
        descript: 'Expert Solution',
        online: false,
      }
      if (this.props.params && this.props.params.agents && this.props.params.agents.length>0) {
        agent.avatar= this.props.params.agents[0].avatar;
        agent.name= this.props.params.agents[0].name;
        agent.descript= this.props.params.agents[0].descript;
        agent.online= this.props.params.agents[0].bridging && this.props.params.agents[0].bridging.online==1;
      }
      
      return(
        <div style={{margin: '-10em auto 0 auto', background: 'rgba(255, 255, 255, .95)',  borderRadius: '1.5em', overflow: 'hidden'}}>
          <div style={{padding: '1.5em 1em',  borderBottom: '1px solid #ccc', background: agent.online ? '#ffe78b' : '#e7e7e7'}}>
            <Header as='h4' color={agent.online ? 'blue' : 'black'} style={{margin: '0', fontWeight: 'bold'}} disabled={!agent.online}>
              {/* <Icon name='user circle outline' /> */}
              <Image circular src={agent.avatar} />
              <Header.Content>
                <b>{agent.name}</b>
                <Header.Subheader>{agent.descript}</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{padding: '1em .5em',  borderTop: '1px solid #fff', borderBottom: '1px solid #ccc'}}>
            {(!data || !data.menu) && <div style={{padding: '2em 1em', textAlign: 'center'}}>
              <Header as='h3' icon>
                <Icon name='comments outline' />
                <Header.Content>
                  Chat or Call ?
                  <Header.Subheader>Pick communication channel </Header.Subheader>
                </Header.Content>
              </Header>
            </div>}
            {data && data.menu && <div style={{padding: '1em .5em'}}>
              {
                navItems.map((item, idx)=>{
                  return(<div key={idx} style={{background: checkedLevel==item.level ? 'rgba(225, 225, 225, 1)' : 'rgba(230, 230, 230, .4)', padding: '.5em .5em', marginBottom: '.3em', borderRadius: '3em'}}>
                    <Header as='h5' style={{margin: '0', fontWeight: 'bold', cursor: 'pointer'}} onClick={this.setShortcut.bind(this, item.level)} color={checkedLevel==item.level ? 'black' : 'grey'}>
                      <Icon name={checkedLevel==item.level ? 'circle' : 'circle outline'} style={{fontSize: '1.7em'}} color={checkedLevel==item.level ? 'red' : 'grey'}/>
                      <Header.Content>
                        <b>{item.name}</b>
                        <Header.Subheader style={{fontSize: '.7em'}}>Resp. By <b>{item.responsed=='Agent' ? 'Contact Center' : 'Autonomous'}</b></Header.Subheader>
                      </Header.Content>
                    </Header>
                    {/* {idx<(navItems.length-1) && <Divider style={{margin: idx==0 ? '1.5em 0 1.5em 0' : '.75em 0 .75em 0'}}/>} */}
                  </div>)
                })
              }
            </div>}
          </div>
          <div style={{padding: '2em 1em'}}>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Button size='medium' icon color='teal' onClick={this.props.setMode.bind(this, 'chat', checkedLevel, navigations && navigations[checkedLevel])} disabled={checkedLevel==null || (navigations && navigations[checkedLevel].responsed!='Agent')}>
                  <Icon name='comment' /><span style={{borderLeft: '1px solid #fff', marginLeft: '.3em', paddingLeft: '.5em'}}>Chat</span>
                </Button>
              </div>
              <div style={{flex: '1', textAlign: 'right'}}>
                <Button size='medium' icon color='blue' onClick={this.props.setMode.bind(this, 'call', checkedLevel, navigations && navigations[checkedLevel])} disabled={checkedLevel==null}>
                  <Icon name='microphone' /><span style={{borderLeft: '1px solid #fff', marginLeft: '.3em', paddingLeft: '.5em'}}>Talk</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OmniwidgetMenu)
