import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'



class SpyphoneOnline extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    return (
      <>
        <Segment attached={true} style={{padding: '1em .5em', background: '#444', border: 'none'}}>
          <Header as='h5'inverted style={{margin: '1em .5em 0 .5em', display: 'inline'}} color='yellow'>
            <Icon name='linkify' style={{fontSize: '1em', textShadow: '3px 5px 3px #000'}} />
            <Header.Content>
              <b>ONLINE</b>
            </Header.Content>
          </Header>
        </Segment>
      </>
    )
  }
}

export default SpyphoneOnline