import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'


import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"


import { personalOptions, ticketOptions }  from '../../../../modules/service/StaticMaster'

import { connect } from "react-redux"
import { getTicket } from "../../../../actions/service/ticketAction"
import { listUser, listWorkgroup } from "../../../../actions/service/masterAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    ticketStore: state.ticketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listUser:(objParam) => dispatch(listUser(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),
    getTicket:(objParam) => dispatch(getTicket(objParam)),
  }
}

class TicketBox extends Component {
  constructor(props) {
    super(props)
    this.state= {
      isLinkage: false,
      isResource: false,
      isAssignment: false,
    }
  }
  
  componentDidMount() {
    this.props.listUser()
    this.props.listWorkgroup()
  }

  setExpand(v) {
    const state= {};
    state[v]= !this.state[v]
    this.setState({
      ...state,
    })
  }

  formatSeconds(seconds) {
    // console.log('!!! SECONDS', seconds)
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log('!!! DATE', date)
    return ([
      Math.floor(seconds/(24 * 60 * 60)),
      format(date, 'HH'),
      format(date, 'mm'),
    ]);
  }

  render() {
    const { screenWidth, screenHeight, ticket }= this.props
    const { isLinkage, isResource, isAssignment }= this.state;

    const listUser= (this.props.masterStore.listUser!=undefined && this.props.masterStore.listUser.progress=='1' ? this.props.masterStore.listUser.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    
    const users= new Map();
    const workgroups= new Map();

    listUser.map(item=>{
      users.set(item.value, item)
    })
    listWorkgroup.map(item=>{
      workgroups.set(item.value, item)
    })
    const lives= (ticket && ticket.secondLived ? this.formatSeconds(ticket.secondLived) : [])

    const priority= ticketOptions('priority', true);
    const severity= ticketOptions('severity', true);

    return (<div>
      <div style={{display: 'flex'}}>
        <div style={{flex: 1}}>
          <Header as='h5'>
            <Header.Content>
              <b>Ticket</b>
              <Header.Subheader>Ticket Information.</Header.Subheader>
            </Header.Content>
          </Header>
        </div>
        <div style={{padding: '0 0 0 .3em'}}>
          <Popup content='Live Timer DD | HH:MM' position='top center' inverted trigger={
            <div style={{display: 'flex', width: '6em', textAlign: 'center', border: '1px solid #ccc', background: '#f0f0f0', borderRadius: '.3em', fontWeight: 'bold'}}>
              <div style={{flex: 1, padding: '.3em .2em', borderRight: '1px solid #bbb'}}>{lives[0] || '00'}D</div>
              <div style={{flex: 1, padding: '.3em .2em', borderLeft: '1px solid #fff'}}>{lives[1] || '00'}</div>
              <div style={{padding: '.3em 0'}}>:</div>
              <div style={{flex: 1, padding: '.3em .2em'}}>{lives[2] || '00'}</div>
            </div>
          } />
        </div>
        <div style={{padding: '0 0 0 .3em'}}>
          <div style={{display: 'flex', textAlign: 'center', border: '1px solid #ccc', background: '#f0f0f0', borderRadius: '.3em'}}>
            <div style={{padding: '.3em .2em', borderRight: '1px solid #bbb'}}><Icon name='user circle' /></div>
            <div style={{flex: 1, padding: '.3em .3em .3em .2em', borderLeft: '1px solid #fff', fontWeight: 'bold', whiteSpace: 'nowrap'}}>{ticket.assigneeUser && ticket.assigneeUser.name}</div>
          </div>
        </div>
        <div style={{padding: '0 0 0 .3em'}}>
          <Popup position= 'top left' inverted content={(this.props.timeline==0 && 'Show ticket box only.') || (this.props.timeline==1 && 'Show timeline only.') || 'Show all ticket box and timelines.'}
            trigger={
            <Button type='button' style={{height: '2.3em'}} size='small' color={this.props.timeline<=1 ? 'blue' : 'red'} icon onClick={this.props.setTimeline.bind(this)}><Icon name={this.props.timeline<=1 ? 'arrow right' : 'arrow left'} /></Button>
          } />
        </div>
      </div>
      <Segment>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}}>
            <Header as='h5'>
              <Icon name={
                (ticket.channelType=='Voice' && 'microphone') || (ticket.channelType=='IVR' && 'volume down') || (ticket.channelType=='SMS' && 'sticky note outline')
                || (ticket.channelType=='Email' && 'envelope outline') || (ticket.channelType=='Whatsapp' && 'whatsapp') || (ticket.channelType=='Telegram' && 'telegram plane')
                || (ticket.channelType=='Twitter' && 'twitter') || (ticket.channelType=='Facebook' && 'facebook') || (ticket.channelType=='Instagram' && 'instagram') || 'share alternate'
              } />
              <Header.Content>
                From <b>{ticket.name}</b>, as <span style={{textTransform: 'capitalize'}}>{ticket.cluster || 'Public Visitor'}</span>
                <Header.Subheader><b>{ticket.channelType || 'Walk In'} - {ticket.channelMethod || 'Input by Agent'}</b></Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          {ticket.email && (ticket.channelType=='Email' || (ticket.channelSupports && ticket.channelSupports.indexOf('Email')>=0)) && <div style={{padding: '0 0 0 .3em'}}>
            <Popup content='Email Response' position='top left' inverted trigger={
              <Icon color={'grey'} circular inverted size='small' name='envelope' style={{margin: '0', cursor: 'pointer'}} onClick={this.props.setBoxTicket.bind(this, 'response')}/>
            } />
          </div>}
          {ticket.mobile && (ticket.channelType=='Voice' || (ticket.channelSupports && ticket.channelSupports.indexOf('Voice')>=0)) && <div style={{padding: '0 0 0 .3em'}}>
            <Popup content='Call Response' position='top left' inverted trigger={
              <Icon color={'grey'} circular inverted size='small' name='phone' style={{margin: '0', cursor: 'pointer'}} onClick={this.props.setBoxTicket.bind(this, 'response')}/>
            } />
          </div>}
          {ticket.whatsapp && (ticket.channelType=='Whatsapp' || (ticket.channelSupports && ticket.channelSupports.indexOf('Whatsapp')>=0)) && <div style={{padding: '0 0 0 .3em'}}>
            <Popup content='Whatsapp Response' position='top left' inverted trigger={
              <Icon color={'grey'} circular inverted size='small' name='whatsapp' style={{margin: '0', cursor: 'pointer'}} onClick={this.props.setBoxTicket.bind(this, 'response')}/>
            } />
          </div>}
          {ticket.twitter && (ticket.channelType=='Twitter' || (ticket.channelSupports && ticket.channelSupports.indexOf('Twitter')>=0)) && <div style={{padding: '0 0 0 .3em'}}>
            <Popup content='Twitter Response' position='top left' inverted trigger={
              <Icon color={'grey'} circular inverted size='small' name='twitter' style={{margin: '0', cursor: 'pointer'}} onClick={this.props.setBoxTicket.bind(this, 'response')}/>
            } />
          </div>}
          {ticket.facebook && (ticket.channelType=='Facebook' || (ticket.channelSupports && ticket.channelSupports.indexOf('Facebook')>=0)) && <div style={{padding: '0 0 0 .3em'}}>
            <Popup content='Facebook Response' position='top left' inverted trigger={
              <Icon color={'grey'} circular inverted size='small' name='facebook' style={{margin: '0', cursor: 'pointer'}} onClick={this.props.setBoxTicket.bind(this, 'response')}/>
            } />
          </div>}
          {ticket.instagram && (ticket.channelType=='Instagram' || (ticket.channelSupports &&  ticket.channelSupports.indexOf('Instagram')>=0)) && <div style={{padding: '0 0 0 .3em'}}>
            <Popup content='Instagram Response' position='top left' inverted trigger={
              <Icon color={'grey'} circular inverted size='small' name='instagram' style={{margin: '0', cursor: 'pointer'}} onClick={this.props.setBoxTicket.bind(this, 'response')}/>
            } />
          </div>}
        </div>
      </Segment>
      <Segment>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}}>
            <Header as='h4' color='red'>
              <Header.Content>
                <b>{ticket.subject}</b>
                <Header.Subheader><b>{ticket.createStamp!=null && format(parse(ticket.createStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm')}</b> {ticket.createStamp!=null && format(parse(ticket.createStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM yyyy')}</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{padding: '0 0 0 .3em'}}>
            <Popup content='Update Ticket' position='left center' inverted trigger={
              <Icon color={'red'} circular inverted size='small' name='wizard' style={{margin: '0', cursor: 'pointer'}} onClick={this.props.setBoxTicket.bind(this, 'update')}/>
            } />
          </div>
        </div>
        <Divider style={{margin: '.3em 0'}} />
        <div>
          <Header as='h5'>
            <Header.Content>
              {(ticket.classification||{}).name || 'No Classification'}
              <Header.Subheader>Privacy: <b>{ticket.privacy}</b> / Category: <b>{ticket.category}</b></Header.Subheader>
            </Header.Content>
          </Header>
        </div>
        <Divider />
        <div style={{fontSize: '1.2em'}}>
          {ticket.descript}
        </div>
        <Divider />
          <Label size='large' style={{padding: '.5em .5em', fontWeight: 'normal'}} color={'teal'}>
            <Icon name='battery high' style={{transform: 'rotate(-90deg)', margin: '0 0 0 -.3em'}} /> | {(priority.get(ticket.priority)||{}).text || 'Normal'}
          </Label>
          <Label size='large' style={{padding: '.5em .5em', fontWeight: 'normal'}} color={'orange'}>
            <Icon name='fire' style={{margin: '0'}}/> | {(severity.get(ticket.severity)||{}).text || 'Low Impact'}
          </Label>
      </Segment>
      <Segment>
        <div style={{display:'flex'}}>
          <div style={{width: '10em', paddingRight: '1em', borderRight: '1px solid #ccc', textAlign: 'center'}}>
            <Label color={(ticket.remark_ && ticket.remark_.color) || 'black'} size='big' className='fluid'>
              {(ticket.remark) || 'Progress'}
            </Label>
          </div>
          <div style={{flex: 1, paddingLeft: '1em'}}>
            <Header as='h4'>
              <Header.Content>
                {(ticket.resolution && ticket.resolution.name) || 'No Resolution'}
                <Header.Subheader>Progress: <b>{ticket.progress}%</b></Header.Subheader>
              </Header.Content>
            </Header>
          </div>
        </div>
      </Segment>

      {/* <Header as='h5'>
        <Header.Content>
          Requester
          <Header.Subheader>Related interaction and escalation ticket.</Header.Subheader>
        </Header.Content>
      </Header>
      <Segment>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}}>
            <Header as='h5'>
              <Icon name='twitter' />
              <Header.Content>
                By <b>Jack Downes</b>
                <Header.Subheader>Public Visitor on Twitter Direct Message</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{padding: '0 0 0 .3em'}}>
            <Popup content='Call Response' position='top left' inverted trigger={
              <Icon color={'grey'} circular inverted size='small' name='phone' style={{margin: '0', cursor: 'pointer'}} onClick={this.props.setBoxTicket.bind(this, 'response')}/>
            } />
          </div>
          <div style={{padding: '0 0 0 .3em'}}>
            <Popup content='Twitter Response' position='top left' inverted trigger={
              <Icon color={'grey'} circular inverted size='small' name='twitter' style={{margin: '0', cursor: 'pointer'}} onClick={this.props.setBoxTicket.bind(this, 'response')}/>
            } />
          </div>
        </div>
      </Segment> */}
      
      <Divider hidden />
      <div style={{display: 'flex'}}>
        <div style={{flex: 1}}>
          <Header as='h5'>
            <Header.Content>
              Assignment
              <Header.Subheader>People inner ticket circles.</Header.Subheader>
            </Header.Content>
          </Header>
        </div>
          <div style={{padding: '0 0 0 .3em'}}>
            <Popup content='Show / Hide' position='left center' inverted trigger={
              <Icon color={isAssignment ? 'red' : 'grey'} size='large' name={isAssignment ? 'toggle on' : 'toggle off'} style={{margin: '0', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.setExpand.bind(this, 'isAssignment')}/>
            } />
          </div>
      </div>
      {!isAssignment && <Segment style={{background: 'none'}}><Icon name='ellipsis horizontal' /></Segment>}
      {isAssignment && <>
        <Segment>
          <Header as='h5'>
            <Header.Content>
              <b>Assignees</b>
            </Header.Content>
          </Header>
          <Divider />
          <div>
            {ticket.assigneeUserId && <Label as='a' image size='small' >
              <img src='https://react.semantic-ui.com/images/avatar/small/joe.jpg' />
              {ticket.assigneeUser && ticket.assigneeUser.name}
            </Label>}

            {ticket.assigneeWorkgroupId && <Label as='a' image size='small' >
              <img src='https://react.semantic-ui.com/images/avatar/small/joe.jpg' />
              {ticket.assigneeWorkgroup && ticket.assigneeWorkgroup.name}
            </Label>}
          </div>
        </Segment>
        <Segment>
          <Header as='h5'>
            <Header.Content>
              <b>Escalations</b>
            </Header.Content>
          </Header>
          <Divider />
          <div>
            {ticket.escalationUsers && ticket.escalationUsers.map(item=>{
              return(<Label as='a' image size='small' >
                <img src='https://react.semantic-ui.com/images/avatar/small/joe.jpg' />
                {(users.get(item)||{}).text}
              </Label>)
              })
            }
            {ticket.escalationWorkgroups && ticket.escalationWorkgroups.map(item=>{
              return(<Label as='a' image size='small'>
                {/* <img src='https://react.semantic-ui.com/images/avatar/small/joe.jpg' /> */}
                <Icon name='users' style={{margin: '0'}}/> | {(workgroups.get(item)||{}).text}
              </Label>)
              })
            }
          </div>
          {/* assigneeUserId
          assigneeWorkgroupId

          escalationUsers
          escalationWorkgroups

          mentionUsers
          mentionWorkgroups */}
        </Segment>
      </>}

      <Divider hidden />
      <div style={{display: 'flex'}}>
        <div style={{flex: 1}}>
          <Header as='h5'>
            <Header.Content>
              Ticket Linkages
              <Header.Subheader>Related interaction and escalation ticket.</Header.Subheader>
            </Header.Content>
          </Header>
        </div>
          <div style={{padding: '0 0 0 .3em'}}>
            <Popup content='Show / Hide' position='left center' inverted trigger={
              <Icon color={isLinkage ? 'red' : 'grey'} size='large' name={isLinkage ? 'toggle on' : 'toggle off'} style={{margin: '0', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.setExpand.bind(this, 'isLinkage')}/>
            } />
          </div>
      </div>
      {!isLinkage && <Segment style={{background: 'none'}}><Icon name='ellipsis horizontal' /></Segment>}
      {isLinkage && <>
        <Segment>
          <Header as='h5'>
            <Header.Content>
              <b>Interaction</b>
            </Header.Content>
          </Header>
          <Divider />
          <div>
            {ticket.parent && 
              <Popup content={ticket.parent.subject} position='top left' inverted trigger={
                <Label as='a' size='small' >
                  <Icon name='tag' style={{margin: '0'}} /> | {ticket.parent.code}
                </Label>
              } />
            }
            {!ticket.parent && 
              <Popup content={ticket.subject} position='top left' inverted trigger={
                <Label as='a' size='small' >
                  <Icon name='tag' style={{margin: '0'}} /> | {ticket.code}
                </Label>
              } />
            }
          </div>
          
          {ticket.childs && ticket.childs.length>0 && <>
            <Header as='h5'>
              <Header.Content>
                <b>Escalation / Child</b>
              </Header.Content>
            </Header>
            <Divider />
            {ticket.childs.map(item=>{
                return(
                  <Popup content={item.subject} position='top left' inverted trigger={
                    <Label as='a' size='small' >
                      <Icon name='tags' style={{margin: '0'}} /> | {item.code}
                    </Label>
                  } />
                )
              })
            }
          </>}
        </Segment>
        {ticket.isSplit==1 && <Segment>
          <Header as='h5'>
            <Header.Content>
              <b>Split Tickets</b>
              <Header.Subheader>Split into multiple tickets.</Header.Subheader>
            </Header.Content>
          </Header>
          <Divider />
        </Segment>}
        {ticket.isMerge==1 && <>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                <b>Merge Into Ticket</b>
                <Header.Subheader>Merge into single parent ticket.</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />

            <Header as='h5'>
              <Header.Content>
                <b>Merge From Child Tickets</b>
                <Header.Subheader>Merge from multiple child ticket list.</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
          </Segment>
        </>}
      </>}

      <Divider hidden />
      <div style={{display: 'flex'}}>
        <div style={{flex: 1}}>
          <Header as='h5'>
            <Header.Content>
              Asset / Resources
              <Header.Subheader>Supplementary images and documents.</Header.Subheader>
            </Header.Content>
          </Header>
        </div>
          <div style={{padding: '0 0 0 .3em'}}>
            <Popup content='Show / Hide' position='left center' inverted trigger={
              <Icon color={isResource ? 'red' : 'grey'} size='large' name={isResource ? 'toggle on' : 'toggle off'} style={{margin: '0', transform: 'rotate(90deg)', cursor: 'pointer'}} onClick={this.setExpand.bind(this, 'isResource')}/>
            } />
          </div>
      </div>
      
      {!isResource && <Segment style={{background: 'none'}}><Icon name='ellipsis horizontal' /></Segment>}
      {isResource && <>
        <Segment>
          <Header as='h5'>
            <Header.Content>
              <b>Documents</b>
            </Header.Content>
          </Header>
          <Divider />
          {ticket.resources  && <Label.Group>
            {ticket.resources.documents && ticket.resources.documents.length>0 && ticket.resources.documents.map(item=>{
                return(
                  <Popup content={item.name || 'No filename'} position='top left' inverted trigger={
                    <Label as='a' basic>
                      <Icon name='file pdf outline' size='big' style={{margin: '0'}}/>
                    </Label>
                  } />
                )
              })
            }
          </Label.Group>}
          {(!ticket.resources || !ticket.resources.documents) && <>No document available.</>}

          <Header as='h5'>
            <Header.Content>
              <b>Photos / Images</b>
            </Header.Content>
          </Header>
          <Divider />
          {ticket.resources  && <Image.Group size='mini'>
            {ticket.resources.images && ticket.resources.images.length>0 && ticket.resources.images.map(item=>{
                return(
                  <Popup content={item.name || 'No caption'} position='top left' inverted trigger={
                    <Image src={'https://react.semantic-ui.com//images/wireframe/image.png'} />
                  } />
                )
              })
            }
          </Image.Group>}
          {!ticket.resources.images && <>No image / photo available.</>}
        </Segment>
      </>}
    </div>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketBox)
