import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
  Feed,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse, addSeconds, formatDistanceToNow } from "date-fns"


import { connect } from "react-redux"
import { browseInteraction } from "../../../../actions/service/interactionAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    interactionStore: state.interactionStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseInteraction:(objParam) => dispatch(browseInteraction(objParam)),
  }
}

const timelines= [ //create, take, split, merge, communicate, reminding, notify, dispatch, escalate, suspend, transfer, share, suspend,solving, closing
  {
    stamp: '2023-03-20 08:30',
    action: 'create',
    assignee: 'Agent',
    requester: 'Customer',
    params: { //detail params goes here
      ticketId: 1,
      assigneeId: 1,
      requesterId: 1,
      dispatcherId: 1,
    },
    body: {
      subject: 'Problem Pembayaran Online Banking',
      descript: 'Transaksi transfer online banking ke semua bank error !'
    },
    views: [
    ],
    likes: [
    ],
    alerts: [
    ],
    comments: [
    ],
    assets: [
      {
        name: 'Bukti Transaksi',
        type: '',
        url: ''
      }
    ]
  },
  {
    stamp: '2023-03-20 09:30',
    action: 'escalate',
    assignee: 'Mobile Banking Backend',
    requester: 'Agent',
    body: {
      message: 'Mohon Bantuannya segera !',
    },
    likes: [
    ],
    comments: [
    ],
    assets: [
      {
        name: 'Bukti Transaksi',
        type: '',
        url: ''
      }
    ]
  }
];

class TimelinesBox extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }
  
  componentDidMount() {
    // console.log('!! DID MOUNT', this.props.ticket)
    const { search, paging }= (this.props.interactionStore.browseInteraction!==undefined ? this.props.interactionStore.browseInteraction : {})
    this.props.browseInteraction({search: {...search, ticketId: this.props.ticket.ticketId}, paging: paging})
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.interactionStore.browseInteraction!==undefined ? this.props.interactionStore.browseInteraction : {})
    this.props.browseInteraction(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }

  render() {
    const { screenWidth, screenHeight, style }= this.props

    const { data, search, paging }= (this.props.interactionStore.browseInteraction!==undefined ? this.props.interactionStore.browseInteraction : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        <div style={{display: 'flex', marginBottom: '1em'}}>
          <div style={{flex: 1}}>
            <Header as='h5'>
              <Header.Content>
                Interaction <b>Timelines</b>
                <Header.Subheader>Ticket interaction and conversation timelines.</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{padding: '0 0 0 .3em'}}>
            <Popup position= 'top left' inverted content={'Show all ticket box and timelines.'}
              trigger={<div>
              {this.props.timeline==2 && <Button type='button' style={{height: '2.3em'}} size='small' color={this.props.timeline!=2 ? 'blue' : 'red'} icon onClick={this.props.setTimeline.bind(this)}><Icon name={this.props.timeline!=2 ? 'arrow right' : 'arrow left'} /></Button>}
            </div>} />
          </div>
        </div>
      
        <div style={{flex: 1}}>
          <div style={{height: 400, background: '#ffd412', padding: '1em 0', borderRadius: '2em', position: 'fixed', top:'12em', marginLeft: '-1.6em', width: '1em'}}>
            <div style={{display: 'flex', flexDirection: 'column', padding: '0em', textAlign: 'center', height: '100%', width: '2em', marginLeft: '-.5em'}}>
              <div style={{height: '1em', width: '2px', margin: 'auto', borderLeft: '1px solid #999999aa', borderRight: '1px solid #ffffffcc'}} />
              <div>
                <Popup content='Latest Update' position='right center' inverted trigger={
                  <Divider horizontal><Icon name='circle' color='blue' style={{cursor: 'pointer'}} /></Divider>
                } />
              </div>
              <div style={{flex: 1, minHeight: '1em', width: '2px', margin: 'auto', borderLeft: '1px solid #999999aa', borderRight: '1px solid #ffffffcc'}} />
              <div>
                <Popup content='Earliest Update' position='right center' inverted trigger={
                  <Divider horizontal><Icon name='circle' color='red' style={{cursor: 'pointer'}} /></Divider>
                } />
              </div>
              <div style={{height: '1em', width: '2px', margin: 'auto', borderLeft: '1px solid #999999aa', borderRight: '1px solid #ffffffcc'}} />
            </div>
          </div>
          
          <Segment style={{marginTop: '0', minHeight: screenHeight, height: '100%'}}>
            <div>
              <Feed>
                <div style={{display: 'flex', background: '#eaeaea'}}>
                  <div style={{width: '5em', background: '#ffffff55', padding: '2em 1em', textAlign: 'center'}}>
                    <Header as='h4' color='red'>
                      <Header.Content>
                        <b>Now</b>
                        <Header.Subheader style={{fontSize: '.67em'}}>{format(new Date(), 'dd/MMM yyyy')}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{padding: '1.7em 1.2em', textAlign: 'center'}}>
                    <Divider horizontal >
                      <Button.Group color='red' size='small'>
                        <Button icon><Icon name='wizard' /></Button>
                        <Dropdown
                          className='button icon'
                          floating
                          trigger={<></>}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item text='Split' icon='window restore outline' onClick={this.props.setBoxTicket.bind(this, 'split', this.props.ticket)}/>
                            <Dropdown.Item text='Merge' icon='cube' onClick={this.props.setBoxTicket.bind(this, 'merge', this.props.ticket)}/>
                            <Divider style={{margin: '.2em 0'}}/>
                            <Dropdown.Item text='Update' icon='wizard' onClick={this.props.setBoxTicket.bind(this, 'update', this.props.ticket)}/>
                            <Divider style={{margin: '.2em 0'}}/>
                            <Dropdown.Item text='Escalate' icon='user plus' onClick={this.props.setBoxTicket.bind(this, 'escalate', this.props.ticket)}/>
                            <Dropdown.Item text='Transfer' icon='user plus' onClick={this.props.setBoxTicket.bind(this, 'transfer', this.props.ticket)}/>
                            <Divider style={{margin: '.2em 0'}}/>
                            <Dropdown.Item text='Share !' icon='share alternate' onClick={this.props.setBoxTicket.bind(this, 'share', this.props.ticket)}/>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Button.Group>
                    </Divider>
                  </div>
                  <div style={{flex: 1, padding: '2.3em 0 0 0', }}>
                    <Label size='big' pointing='left' basic style={{fontWeight: 300}}>Update here ... !</Label>
                  </div>
                </div>
                <Divider style={{margin: '.5em 0'}}/>
                {data && data.length==0 && <Segment style={{padding: '3em 1em', textAlign: 'center'}}>
                  <Header as='h4' icon>
                    <Icon name='warning sign' style={{fontSize: '2.5em'}}/>
                    <Header.Content>
                      No Data is Available
                      <Header.Subheader>No ticket timeline interaction data is currently available.</Header.Subheader>
                    </Header.Content>
                  </Header>
                  <Divider />
                  <Icon name='ellipsis horizontal' />
                </Segment>}
                {data && data.map((item, i)=>{
                  return(<>
                    <Feed.Event key={i} style={{background: i%2==0 ? '#f7f7f7' : '#cffdff', padding: '0', marginBottom: '1px', borderRadius: '.5em', overflow: 'hidden'}}>
                      <Feed.Label style={{width: '5em', background: '#fafafaaa', padding: '2em 1em', textAlign: 'center'}}>
                        <Header as='h5'>
                          <Header.Content>
                            <b>{item.updatedStamp!=null && format(parse(item.updatedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm')}</b>
                            <Header.Subheader>{item.updatedStamp!=null && format(parse(item.updatedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM yyyy')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                        <Divider horizontal >
                          <Popup content={item.action || 'No Action'} position='right center' inverted trigger={
                            <Icon circular inverted size='tiny' style={{fontSize: '1em'}}
                              color={
                                (item.action=='create' && 'red') || 
                                (item.action=='dispatch' && 'orange') || 
                                (item.action=='assign' && 'orange') || 
                                (item.action=='take' && 'orange') || 

                                (item.action=='escalate' && 'red') || 
                                (item.action=='transfer' && 'teal') || 
                                (item.action=='share' && 'teal') || 

                                (item.action=='update' && 'yellow') || 
                                (item.action=='reopen' && 'blue') || 
                                (item.action=='suspend' && 'red') || 
                                
                                (item.action=='split' && 'teal') || 
                                (item.action=='merge' && 'teal') || 

                                (item.action=='notify' && 'red') || 

                                (item.action=='solve' && 'blue') || 
                                (item.action=='close' && 'black') || 

                                (item.action=='like' && 'grey') || 
                                (item.action=='view' && 'grey') || 
                                (item.action=='comment' && 'grey') || 
                                (item.action=='alert' && 'red') || 
                                
                                'warning'
                              } 
                              name={
                                (item.action=='create' && 'add') || 
                                (item.action=='dispatch' && 'send') || 
                                (item.action=='assign' && 'envelope open outline') || 
                                (item.action=='take' && 'envelope open outline') || 

                                (item.action=='escalate' && 'user plus') || 
                                (item.action=='transfer' && 'user plus') || 
                                (item.action=='share' && 'share alternate') || 

                                (item.action=='update' && 'wizard') || 
                                (item.action=='reopen' && 'add') || 
                                (item.action=='suspend' && 'pause') || 
                                
                                (item.action=='split' && 'window restore outline') || 
                                (item.action=='merge' && 'cube') || 

                                (item.action=='notify' && 'bell') || 

                                (item.action=='solve' && 'calendar check') || 
                                (item.action=='close' && 'calendar check') || 

                                (item.action=='like' && 'heart') || 
                                (item.action=='view' && 'eye') || 
                                (item.action=='comment' && 'comments') || 
                                (item.action=='alert' && 'bell') || 
                                
                                'warning'
                              } 
                            />
                          } />
                        </Divider>
                      </Feed.Label>
                      <Feed.Content style={{padding: '1em 0'}}>
                        <Feed.Summary>
                          <Feed.Date style={{display:'block', margin: '0', color: '#666', textTransform: 'capitalize'}}>{item.updatedStamp && formatDistanceToNow(
                            parse(item.updatedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), {
                              addSuffix: false,
                            })}
                          </Feed.Date>
                          <Popup content={'As '+ (item.owner || 'generated by system')} position='top left' inverted trigger={
                            <span>
                              {
                                ((item.owner=='requester' && item.requester && item.requester.name) || 
                                (item.owner=='dispatcher' && item.dispatcher && item.dispatcher.name) || 
                                (item.owner=='assignee' && item.assignee && item.assignee.name) || 
                                (item.owner=='escalation' && item.escalation && item.escalation.name) || 
                                (item.owner=='follower' && item.user && item.user.name) || 
                                (item.owner=='system' && 'System') || 
                                'System').substring(0,7) +'...'
                              } 
                            </span>
                          } /> <span  onClick={this.props.setBox.bind(this, 'view', item)} style={{background: '#eaeaea', borderRadius: '1em', padding: '0 .5em .2em .5em', color: '#1175d0', fontSize: '1.3em', cursor:   'pointer'}}>{item.action || 'update'}</span> ticket, <b><i>{item.privacy || 'internal'}</i></b>.
                          
                          <Dropdown className='custom' icon='ellipsis vertical' pointing='right' style={{float: 'right', marginTop: '-1em'}}>
                            <Dropdown.Menu>
                              <Dropdown.Item text='View' icon='sticky note outline' onClick={this.props.setBox.bind(this, 'view', item)} />
                              {/* <Dropdown.Item text='Modify' icon='wizard' onClick={this.props.setBox.bind(this, 'modify', item)} /> */}
                              <Divider style={{margin: '.2em 0'}}/>
                              <Dropdown.Item text='Share !' icon='share alternate' onClick={this.props.setBox.bind(this, 'share', item)} />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Feed.Summary>
                        
                        <Divider />
                        {
                          item.resources && item.resources.documents && item.resources.documents.length>0 &&
                          <Label.Group>
                          {
                            (
                              // [{name: 'Data Transaksi', filename: ''}, {name: 'Kuitansi Pembayaran', filename: ''}]
                              // || 
                              item.resources.documents
                            ).map(item=>{
                              return(
                                <Popup content={item.name || 'No Name'} position='top left' inverted trigger={
                                  <Label as='a' basic>
                                    <Icon name='file pdf outline' size='huge' style={{margin: '0'}}/>
                                  </Label>
                                } />
                              )
                            })
                          }
                        </Label.Group>}

                        {
                          item.resources && item.resources.images && item.resources.images.length>0 && 
                          <Feed.Extra images>
                          {
                            (
                              // [{name: 'Data Transaksi', filename: ''}, {name: 'Kuitansi Pembayaran', filename: ''}]
                              // || 
                              item.resources.images
                            ).map(item=>{
                              return(
                                <Popup content={item.name || 'No caption'} position='top left' inverted trigger={
                                  <Image as='a' src={'https://react.semantic-ui.com//images/wireframe/image.png'} />
                                } />
                              )
                            })
                          }
                        </Feed.Extra>}

                        {/* {item.resources && <>
                          <Feed.Extra images>
                            <a>
                              <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                            </a>
                            <a>
                              <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                            </a>
                          </Feed.Extra>
                        </>} */}
                        <Feed Extra text style={{fontSize: '1.2em'}}>
                          {item.activity || ' No activity saved.'}
                        </Feed>
                        
                        <Divider />
                        <Feed.Meta style={{display: 'block'}}>
                          <Popup content={'Total Views'} position='top left' inverted trigger={
                            <Feed.Like>
                              <Icon name='eye' />{item.totView || '0'} Views
                            </Feed.Like>
                          } />
                          <Popup content={'Total Likes'} position='top left' inverted trigger={
                            <Feed.Like>
                              <Icon name='like' />{item.totLike || '0'} Likes
                            </Feed.Like>
                          } />
                          <Popup content={'Leave a Comment'} position='top left' inverted trigger={
                            <Feed.Like onClick={this.props.setBox.bind(this, 'comments', item)}>
                              <Icon name='comments' />{item.totComment || '0'} Comments
                            </Feed.Like>
                          } /> 
                          <Popup content={'Send Alert'} position='top left' inverted trigger={
                            <Feed.Like style={{float: 'right', marginRight: '1em'}}>
                              <Icon name='bell' color='red' />{item.totAlert || '0'} Alert
                            </Feed.Like>
                          } /> 
                        </Feed.Meta>
                      </Feed.Content>
                    </Feed.Event>
                    <Divider key={i+50} style={{margin: '.5em 0'}}/>
                  </>)
                })}
              </Feed>
            </div>
          </Segment>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelinesBox)
