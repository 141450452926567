import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form, Input } from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format } from "date-fns"

import { connect } from "react-redux"
import { bookLinkwidget } from "../../actions/talk/linkwidgetAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    linkwidgetStore: state.linkwidgetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    bookLinkwidget:(objParam) => dispatch(bookLinkwidget(objParam)),
  }
}

class LinkwidgetHome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCalled: false,
    };
  }

  componentDidMount() {
    
  }

  componentDidUpdate() {
    const {params}= this.props
    // console.log('LINK WIDGET HOME MOUNTED', params);

    if (params && this.checkSchedule() && !this.state.isCalled) {
      if (params.joinType=='Push Talk') {
        if (params.channels.includes('Video')) {
          this.submitCall('Video')
        } else if (params.channels.includes('Audio')) {
          this.submitCall('Audio')
        }
      }

      this.setState({
        isCalled: true,
      })
    }
  }

  submitCall(v) {
    this.props.bookLinkwidget({
      // apiKey: '1111-3434-9085-3227',
      // level: level || this.state.level || null,
      // level: 4,
      
      responsed: 'Agent',
      // workgroups: (navigations && navigations.dispatches) || (this.state.navigations && this.state.navigations.dispatches) || null,

      // passcode: '1234',
      channel: v,
    })
  }

  checkSchedule() {
    const {params}= this.props 
    const currDate= format(new Date(), 'yyyy-MM-dd');
    const currTime= format(new Date(), 'HH:mm')

    if (params && params.startDate<=currDate && params.endDate>=currDate && params.startTime<=currTime && params.endTime>=currTime) {
      return true;
    }
    return false;
  }
  
  render() {
    const {screenWidth, screenHeight, params}= this.props
    
    return(<>
    <div style={{padding: '1em 1em', minHeight: screenHeight/2}}>
      {/* <p>
        1. CHECK SCHEDULE
        2. CHECK CAPACITY
        3. CHECK CHANNEL
        4. CHECK JOIN TYPE
        5. CHECK MIC /CAMERA PERMISSION
        5. SEND COMMAND BASED ON CONFERENCE METHOD
      </p> */}
      <div style={{padding: '1.5em', maxWidth: 375, background: '#eee', borderRadius: '1em', margin: '0 auto'}}>
        <div>
          <div style={{textAlign: 'center', padding: '1.5em 0'}}>
            <Header as='h2' icon style={{margin: '0'}}>
            {(params && params.avatar && <Image style={{width: '3.5em', height: '3.5em'}} circular src={params.avatar} />) || <Icon name='user circle' style={{fontSize: '3.2em'}}/>}
              <Header.Content>
                {(params && params.brand) || 'CORPORATE BRAND'}
                <Header.Subheader>{(params && params.descript) || 'Corporate description / tagline'}</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          {/* <Divider /> */}

          {/* {params && params.joinType=='Opt-In Talk' && params.passcode && <>
            <div style={{textAlign: 'center', padding: '1.5em 0'}}>
              <Header as='h5'>
                <Header.Content>
                  Passcode
                  <Header.Subheader>Masukkan kode akses komunikasi.</Header.Subheader>
                </Header.Content>
              </Header>
              <div style={{display: 'inline-block'}}>
                <Input size='small' style={{width: '3em', margin: '0 1px'}} maxLength={1}/>
                <Input size='small' style={{width: '3em', margin: '0 1px'}} maxLength={1}/>
                <Input size='small' style={{width: '3em', margin: '0 1px'}} maxLength={1}/>
                <Input size='small' style={{width: '3em', margin: '0 1px'}} maxLength={1}/>
              </div>
            </div>
            <Divider />
          </>} */}

          {/* {params && params.params && <>
            <div style={{textAlign: 'center', padding: '1.5em 0'}}>
              <Header as='h5'>
                <Header.Content>
                  Input Params
                  <Header.Subheader>Masukkan kode akses komunikasi.</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <Divider />
          </>} */}

          {this.checkSchedule() && params && params.joinType=='Opt-In Talk' && <>
            <div style={{textAlign: 'center', padding: '1em 0'}}>
                <div style={{padding: '1em 1.5em'}}>
                  <Header as='h2'>
                    <Header.Content>
                      Tekan<br /> tombol berikut untuk segera terhubung ke petugas kami.
                    </Header.Content>
                  </Header>
                </div>
            </div>

            <Divider />
            <div style={{textAlign: 'center', padding: '1.5em 0'}}>
              {params.channels.includes('Audio') && !params.channels.includes('Video') &&
                <Button color='blue' type='button' size='massive' icon labelPosition='left' style={{margin: '.5em 1em', borderRadius: '2em', paddingRight: '3em!important'}} onClick={this.submitCall.bind(this, 'Audio')}><Icon name='microphone' /> Hubungi</Button>
              }
              {params.channels.includes('Video') &&
                <Button color='blue' type='button' size='massive' icon labelPosition='left' style={{margin: '.5em 1em', borderRadius: '2em', paddingRight: '2em'}} onClick={this.submitCall.bind(this, 'Video')}><Icon name='video' /> Hubungi</Button>
              }
            </div>
          </>}

          {this.checkSchedule() && params && params.joinType=='Push Talk' && <>
            <div style={{textAlign: 'center', padding: '1em 0'}}>
              <div style={{padding: '1em 1.5em'}}>
                <Header as='h2'>
                  <Header.Content>
                    Panggilan Anda akan terhubung secara otomatis ke petugas kami.
                  </Header.Content>
                </Header>
              </div>
            </div>
            <div style={{textAlign: 'center', padding: '1.5em 2em'}}>
              <Header as='h4' icon>
                <Icon name='phone volume' circular inverted color='blue' />
                <Header.Content>
                  Panggilan Terhubung . . .
                  <Header.Subheader>Mohon ditunggu, panggilan akan segera terhubung ke petugas kami yang tersedia.</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
          </>}

          {!this.checkSchedule() && <>
            <div style={{textAlign: 'center', padding: '1em 0'}}>
              <div style={{padding: '1em 1.5em'}}>
                <Header as='h2'>
                  <Header.Content>
                    Link panggilan ini telah kedaluwarsa.
                  </Header.Content>
                </Header>
              </div>
            </div>

            <Divider />
            <div style={{textAlign: 'center', padding: '1.5em 2em'}}>
              <Header as='h4' icon>
                <Icon name='text telephone' circular inverted color='brown' />
                <Header.Content>
                  Expired Call
                  <Header.Subheader>Kontak layanan pelanggan untuk informasi lebih lanjut.</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
          </>}
        </div>
      </div>
      {/* <p>
        CHECK PERMISSION | VIDEO / AUDIO CONFERENCE | VIDEO SCREEN | HANGUP BUTTON / DIAL BUTTON / INCOMING BUTTON
      </p> */}
    </div>
    </>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkwidgetHome)