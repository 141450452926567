export default function middlewareReducer (state = {}, action) {
  switch (action.type) {
    case 'MIDDLEWARE_RESET': {
        return({
            ...state,
            updateMiddleware: {},
            otpMiddleware: {},
            commMiddleware: {},
            sandboxMiddleware: {},
        })
    }
    
    case 'MIDDLEWARE_OTP_PENDING': {
        return({...state, otpMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_OTP_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, otpMiddleware: {progress: 2, payload: action.payload}})
        } else {
            return({...state, otpMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MIDDLEWARE_OTP_REJECTED': {
        return({...state, otpMiddleware: {progress: 2, payload: action.payload}})
    }

    case 'MIDDLEWARE_TOKEN_PENDING': {
      return({...state, tokenMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_TOKEN_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, tokenMiddleware: {progress: 2, payload: action.payload}})
        } else {
            return({...state, tokenMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MIDDLEWARE_TOKEN_REJECTED': {
        return({...state, tokenMiddleware: {progress: 2, payload: action.payload}})
    }

    case 'MIDDLEWARE_CAMPAIGN_PENDING': {
      return({...state, campaignMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_CAMPAIGN_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, campaignMiddleware: {progress: 2, payload: action.payload}})
        } else {
            return({...state, campaignMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MIDDLEWARE_CAMPAIGN_REJECTED': {
        return({...state, campaignMiddleware: {progress: 2, payload: action.payload}})
    }

    case 'MIDDLEWARE_TARGET_PENDING': {
      return({...state, targetMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_TARGET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, targetMiddleware: {progress: 2, payload: action.payload}})
        } else {
            return({...state, targetMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MIDDLEWARE_TARGET_REJECTED': {
        return({...state, targetMiddleware: {progress: 2, payload: action.payload}})
    }

    case 'MIDDLEWARE_WEBHOOK_PENDING': {
      return({...state, webhookMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_WEBHOOK_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, webhookMiddleware: {progress: 2, payload: action.payload}})
        } else {
            return({...state, webhookMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MIDDLEWARE_WEBHOOK_REJECTED': {
        return({...state, webhookMiddleware: {progress: 2, payload: action.payload}})
    }

    case 'MIDDLEWARE_AGENT_PENDING': {
      return({...state, agentMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_AGENT_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, agentMiddleware: {progress: 2, payload: action.payload}})
        } else {
            return({...state, agentMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MIDDLEWARE_AGENT_REJECTED': {
        return({...state, agentMiddleware: {progress: 2, payload: action.payload}})
    }

    case 'MIDDLEWARE_WIDGET_PENDING': {
      return({...state, widgetMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_WIDGET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, widgetMiddleware: {progress: 2, payload: action.payload}})
        } else {
            return({...state, widgetMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MIDDLEWARE_WIDGET_REJECTED': {
        return({...state, widgetMiddleware: {progress: 2, payload: action.payload}})
    }

    case 'MIDDLEWARE_COMM_PENDING': {
      return({...state, commMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_COMM_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, commMiddleware: {progress: 2, payload: action.payload}})
        } else {
            return({...state, commMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MIDDLEWARE_COMM_REJECTED': {
        return({...state, commMiddleware: {progress: 2, payload: action.payload}})
    }

    case 'MIDDLEWARE_INTERACTION_PENDING': {
      return({...state, interactionMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_INTERACTION_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, interactionMiddleware: {progress: 2, payload: action.payload}})
        } else {
            return({...state, interactionMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MIDDLEWARE_INTERACTION_REJECTED': {
        return({...state, interactionMiddleware: {progress: 2, payload: action.payload}})
    }

    case 'MIDDLEWARE_REPORT_PENDING': {
      return({...state, reportMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_REPORT_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, reportMiddleware: {progress: 2, payload: action.payload}})
        } else {
            return({...state, reportMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MIDDLEWARE_REPORT_REJECTED': {
        return({...state, reportMiddleware: {progress: 2, payload: action.payload}})
    }

    case 'MIDDLEWARE_BROWSE_PENDING': {
      return({...state, browseMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseMiddleware: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MIDDLEWARE_BROWSE_REJECTED': {
        return({...state, browseMiddleware: {progress: 2, payload: action.payload}})
    }

    case 'MIDDLEWARE_UPDATE_PENDING': {
      return({...state, updateMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateMiddleware: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MIDDLEWARE_UPDATE_REJECTED': {
        return({...state, updateMiddleware: {progress: 2, payload: action.payload}})
    }

    case 'MIDDLEWARE_SANDBOX_PENDING': {
      return({...state, sandboxMiddleware: {progress: 0}})
    }
    case 'MIDDLEWARE_SANDBOX_FULFILLED': {
        if (action.payload==undefined) {
            // return({...state, sandboxMiddleware: {progress: 2, payload: action.payload}})
            return({...state, sandboxMiddleware: {progress: 2}})
        } else {
            // return({...state, sandboxMiddleware: {...action.payload.data, progress: 1, payload: action.payload}})
            return({...state, sandboxMiddleware: {...action.payload.data, progress: 1}})
        }
    }
    case 'MIDDLEWARE_SANDBOX_REJECTED': {
        if (action.payload==undefined) {
            // return({...state, sandboxMiddleware: {progress: 2}})
            return({...state, sandboxMiddleware: {progress: 2}})
        } else {
            // return({...state, sandboxMiddleware: {progress: 2, payload: action.payload}})
            return({...state, sandboxMiddleware: {...action.payload.data, progress: 2}})
        }
    }
    
    default: {
      return state
    }
  }
}