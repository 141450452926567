import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'

import MenuLeft from '../../libraries/supervisor/MenuLeft'
import ModalContainer from '../../libraries/common/ModalContainer';

import ProfileBody from './ProfileBody'
import AccountBody from './AccountBody'
import MessageBody from './MessageBody'
import SettingBody from './SettingBody'

import DashboardBody from './DashboardBody'
import ConversationBody from './ConversationBody'
import ActivityBody from './ActivityBody'
import EventBody from './EventBody'
import ResourceBody from './ResourceBody'

import DeductBody from './DeductBody';
import TopupBody from './TopupBody';
import RecordBody from './RecordBody';

import CampaignStatisticBody from './CampaignStatisticBody';

import OnboardingBody from './OnboardingBody';
import RecordingBody from './RecordingBody';
import TicketingBody from './TicketingBody';
import InteractiveBody from './InteractiveBody';
import EngagementBody from './EngagementBody';

import { connect } from "react-redux"
import { checkToken } from "../../actions/auth/authAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
  }
}

class SupervisorBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      mode: 'dashboard',
      
      onboarding: false,
      topupalert: false,
    }
  }

  componentDidMount() {
    this.props.checkToken();
  }

  componentDidUpdate() {
    const { code, data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}
    data && code=='00' && data.isConfigured=='0' && this.state.onboarding==false && this.setState({
      onboarding: true,
    })

    data && code=='00' && data.billingType=='prepaid' && parseFloat(data.depositCredit)<=0 && this.state.topupalert==false && this.setState({
      topupalert: true,
    })
  }

  closeModal(params) {
    this.props.closeModal(params)
  }
  
  openModal(value, params) {
    this.props.openModal(value, params)
  }

  openWindow(mode) {
    if (mode=='dashboard' || mode==='conversation' || mode==='activity' || mode==='interactive' || mode==='ticketing' || mode==='event' || mode==='resource' || mode==='private' || mode==='setting' || mode==='account') {
      this.setState({
        openDrawer: false,
      })
    }
    this.props.openWindow(mode)
  }

  render() {
    const { data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}

    const { onboarding, topupalert }= this.state
    const { mode, module, screenWidth, screenHeight, mini, modal }= this.props
    
    // console.log('CHECKTOKEN', code, data)
    return (
      <div>
        <div style={{marginTop: '2.8em'}}>
            <div style={{display: 'flex'}}>
              <div style={{background: '#ccc', minHeight: screenHeight-300, borderRight: '1px solid #fff'}}>
                <MenuLeft openWindow={this.openWindow.bind(this)} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} mini={mini} setModule={this.props.setModule.bind(this)}/>
              </div>
              {/* <div style={{background: 'linear-gradient(90deg, rgb(250, 180, 0) 0%, rgb(255 215 21) 25%, rgb(175, 218, 5) 100%)', minHeight: screenHeight, borderRight: '1px solid #fff'}}>
                <MenuLeft openWindow={this.openWindow.bind(this)} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} mini={mini}/>
              </div> */}
              <div style={{flex: '1', marginLeft: mini ? '2px' : '80px', marginRight: '2px', padding: '.85em .5em'}}>
                {mode==='dashboard' && <DashboardBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
                {mode==='conversation' && <ConversationBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} checkToken={data} />}
                {mode==='activity' && <ActivityBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module}  checkToken={data} />}
                
                {mode==='ticketing' && <TicketingBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} />}
                {mode==='interactive' && <InteractiveBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} />}

                {mode==='event' && <EventBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} params={this.props.params} />}
                {mode==='resource' && <ResourceBody openWindow={this.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} />}

                {modal==='account' && <ModalContainer size='small' content={<AccountBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
                {modal==='setting' && <ModalContainer size='small' content={<SettingBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
                {modal==='profile' && <ModalContainer size='small' content={<ProfileBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
                {modal==='message' && <ModalContainer size='small' content={<MessageBody screenWidth={screenWidth} screenHeight={screenHeight} />} closeModal={this.closeModal.bind(this)} /> }
                
                {modal==='deduct' && <ModalContainer size='small' content={<DeductBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
                {modal==='topup' && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }

                {modal==='record' && <ModalContainer size='small' content={<RecordBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }

                {modal==='campaign_statistic' && <ModalContainer size='large' content={<CampaignStatisticBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)}  params={this.props.params} />} closeModal={this.closeModal.bind(this)} /> }
                {modal==='recording' && <ModalContainer size='small' content={<RecordingBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)}  params={this.props.params} />} closeModal={this.closeModal.bind(this)} /> }

                {onboarding && <OnboardingBody dialog />}
                {!onboarding && topupalert && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} />} closeModal={this.props.closeModal.bind(this)} /> }
                {modal==='engagement' && <ModalContainer size='small' content={<EngagementBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)}  params={this.props.params} />} closeModal={this.closeModal.bind(this)} /> }
              </div>
            </div>
        </div>
      </div>
    )
  }
}

// export default SupervisorBody
export default connect(mapStateToProps, mapDispatchToProps)(SupervisorBody)