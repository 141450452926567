import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { format, parse } from "date-fns";
//import "react-datepicker/dist/react-datepicker.css";

class InputDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state= {
    }
  }

  onChange(value) {
    // console.log('!!! ONCHANGE:', value, ' | ', format(new Date(value), 'yyyy-MM-dd'))
    if (value!=null) {
      // this.props.onChange(format(new Date(value), 'yyyy-MM-dd 00:00:00'))
      this.props.onChange(format(new Date(value), (this.props.showTimeSelect ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd')))
    } else {
      this.props.onChange(null)
    }
  }

  onKeyDown = (e) => {
    // console.log('!!! ON KEYDOWN: ',e.key, e.target.value, new Date(e.target.value))
    if (e.target.value!=null) {
      this.props.onChange(format(new Date(e.target.value), (this.props.showTimeSelect ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd')))
    } else {
      this.props.onChange(null)
    }
    return;
  };

  onSelected(value) {
    // console.log('!!! ON SELECTED: ',value,' | ', new Date(value))
    if (new Date(value)!='Invalid Date') {
      return new Date(value)
    }
    return null
  } 

  onSelect(value) {
    // console.log('!!! ONSELECT:', value, ' | ', format(new Date(value), 'yyyy-MM-dd'))
    if (value!=null) {
      // this.props.onChange(format(new Date(value), 'yyyy-MM-dd 00:00:00'))
      this.props.onChange(format(new Date(value), (this.props.showTimeSelect ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd')))
    } else {
      this.props.onChange(null)
    }
  } 

  onBlur(e) {
    this.props.onBlur();
  }


  render() {
    // const props= {...this.props, 
    //   selected: this.onSelected.bind(this),
    //   onChange: this.onChange.bind(this),
    //   showTimeSelect: this.props.showTimeSelect,
    //   timeFormat: this.props.timeFormat,
    //   timeIntervals: this.props.timeIntervals,
    //   timeCaption: this.props.timeCaption,
    //   dateFormat: this.props.dateFormat || 'dd/MMM yyyy', //'dd/MMMM, yyyy h:mm aa'
    //   placeholderText: this.props.placeholder,
    //   //value: new Date(this.props.value)
    // }
    //console.log({...props})
    return (
      <DatePicker
      style={{height: '2.8em'}}
        // {...props} ERROR IN SETTING VALUE
        // selected={new Date(this.props.value)=='Invalid Date'? null : new Date(this.props.value)}
        minDate={new Date(new Date().getMilliseconds - (30 * 3 * 24 * 60 * 60 * 1000))}
        selected={this.onSelected(this.props.value)}
        onChange={this.onChange.bind(this)}
        onBlur={this.onBlur.bind(this)}
        // onSelect={this.onSelect.bind(this)}
        readOnly={this.props.readOnly}
        showTimeSelect={this.props.showTimeSelect}
        showTimeSelectOnly={this.props.showTimeSelectOnly}
        timeFormat={this.props.timeFormat || 'HH:mm'} //"HH:mm"
        timeIntervals={this.props.timeIntervals || 15} //{15}
        timeCaption={this.props.timeCaption || 'Time'}
        dateFormat={this.props.dateFormat || (this.props.showTimeSelect ? 'dd/MMM, yyyy HH:mm aa' : 'dd/MMM, yyyy')} //'dd/MMMM, yyyy h:mm aa'
        placeholderText= {this.props.placeholder}
        onFocus={this.props.onFocus}
        validation={this.props.validation}
      />
    );
  }
}

export default InputDatePicker;