export default function messageReducer (state = {}, action) {
  switch (action.type) {
    case 'MESSAGE_RESET': {
        return({
            ...state,
            getMessage: {},
            updateMessage: {},
            createMessage: {},
        })
    }

    case 'MESSAGE_GET_PENDING': {
        return({...state, getMessage: {progress: 0}})
    }
    case 'MESSAGE_GET_FULFILLED': {
        if (action.payload==undefined) {
            // return({...state, getMessage: {...api.getMessage.response, progress: 1, payload: action.payload}})
            return({...state, getMessage: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getMessage: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGE_GET_REJECTED': {
        return({...state, getMessage: {progress: 2, payload: action.payload}})
    }

    case 'MESSAGE_BROWSE_PENDING': {
      return({...state, browseMessage: {progress: 0}})
    }
    case 'MESSAGE_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            // return({...state, browseMessage: {...api.browseMessage.response, progress: 1, payload: action.payload}})
            return({...state, browseMessage: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseMessage: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGE_BROWSE_REJECTED': {
        return({...state, browseMessage: {progress: 2, payload: action.payload}})
    }

    case 'MESSAGE_CREATE_PENDING': {
      return({...state, createMessage: {progress: 0}})
    }
    case 'MESSAGE_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            // return({...state, createMessage: {...api.createMessage.response, progress: 1, payload: action.payload}})
            return({...state, createMessage: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createMessage: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGE_CREATE_REJECTED': {
        return({...state, createMessage: {progress: 2, payload: action.payload}})
    }

    case 'MESSAGE_UPDATE_PENDING': {
      return({...state, updateMessage: {progress: 0}})
    }
    case 'MESSAGE_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            // return({...state, updateMessage: {...api.updateMessage.response, progress: 1, payload: action.payload}})
            return({...state, updateMessage: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateMessage: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGE_UPDATE_REJECTED': {
        return({...state, updateMessage: {progress: 2, payload: action.payload}})
    }

    case 'MESSAGE_REMOVE_PENDING': {
      return({...state, removeMessage: {progress: 0}})
    }
    case 'MESSAGE_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            // return({...state, removeMessage: {...api.removeMessage.response, progress: 1, payload: action.payload}})
            return({...state, removeMessage: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeMessage: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'MESSAGE_REMOVE_REJECTED': {
        return({...state, removeMessage: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}