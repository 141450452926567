export default function customerReducer (state = {}, action) {
  switch (action.type) {
    case 'CUSTOMER_RESET': {
        return({
            ...state,
            getCustomer: {},
            updateCustomer: {},
            createCustomer: {},
        })
    }

    case 'CUSTOMER_BROWSE_PENDING': {
      return({...state, browseCustomer: {progress: 0}})
    }
    case 'CUSTOMER_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseCustomer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseCustomer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CUSTOMER_BROWSE_REJECTED': {
        return({...state, browseCustomer: {progress: 2, payload: action.payload}})
    }
    
    case 'CUSTOMER_GET_PENDING': {
        return({...state, getCustomer: {progress: 0}})
    }
    case 'CUSTOMER_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getCustomer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getCustomer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CUSTOMER_GET_REJECTED': {
        return({...state, getCustomer: {progress: 2, payload: action.payload}})
    }

    case 'CUSTOMER_STATUS_PENDING': {
      return({...state, statusCustomer: {progress: 0}})
    }
    case 'CUSTOMER_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusCustomer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusCustomer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CUSTOMER_STATUS_REJECTED': {
        return({...state, statusCustomer: {progress: 2, payload: action.payload}})
    }

    case 'CUSTOMER_CREATE_PENDING': {
      return({...state, createCustomer: {progress: 0}})
    }
    case 'CUSTOMER_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createCustomer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createCustomer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CUSTOMER_CREATE_REJECTED': {
        return({...state, createCustomer: {progress: 2, payload: action.payload}})
    }

    case 'CUSTOMER_UPDATE_PENDING': {
      return({...state, updateCustomer: {progress: 0}})
    }
    case 'CUSTOMER_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateCustomer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateCustomer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CUSTOMER_UPDATE_REJECTED': {
        return({...state, updateCustomer: {progress: 2, payload: action.payload}})
    }

    case 'CUSTOMER_REMOVE_PENDING': {
      return({...state, removeCustomer: {progress: 0}})
    }
    case 'CUSTOMER_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeCustomer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeCustomer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'CUSTOMER_REMOVE_REJECTED': {
        return({...state, removeCustomer: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}