export default function dialerReducer (state = {}, action) {
  switch (action.type) {
    case 'DIALER_RESET': {
        return({
            ...state,
            getDialer: {},
            updateDialer: {},
            createDialer: {},
        })
    }

    case 'DIALER_BROWSE_PENDING': {
      return({...state, browseDialer: {progress: 0}})
    }
    case 'DIALER_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseDialer: {...api.browseDialer.response, progress: 1, payload: action.payload}})
            return({...state, browseDialer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseDialer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DIALER_BROWSE_REJECTED': {
        return({...state, browseDialer: {progress: 2, payload: action.payload}})
    }
    
    case 'DIALER_GET_PENDING': {
        return({...state, getDialer: {progress: 0}})
    }
    case 'DIALER_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getDialer: {...api.getDialer.response, progress: 1, payload: action.payload}})
            return({...state, getDialer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getDialer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DIALER_GET_REJECTED': {
        return({...state, getDialer: {progress: 2, payload: action.payload}})
    }

    case 'DIALER_STATUS_PENDING': {
      return({...state, statusDialer: {progress: 0}})
    }
    case 'DIALER_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusDialer: {...api.statusDialer.response, progress: 1, payload: action.payload}})
            return({...state, statusDialer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusDialer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DIALER_STATUS_REJECTED': {
        return({...state, statusDialer: {progress: 2, payload: action.payload}})
    }

    case 'DIALER_CREATE_PENDING': {
      return({...state, createDialer: {progress: 0}})
    }
    case 'DIALER_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createDialer: {...api.createDialer.response, progress: 1, payload: action.payload}})
            return({...state, createDialer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createDialer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DIALER_CREATE_REJECTED': {
        return({...state, createDialer: {progress: 2, payload: action.payload}})
    }

    case 'DIALER_UPDATE_PENDING': {
      return({...state, updateDialer: {progress: 0}})
    }
    case 'DIALER_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateDialer: {...api.updateDialer.response, progress: 1, payload: action.payload}})
            return({...state, updateDialer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateDialer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DIALER_UPDATE_REJECTED': {
        return({...state, updateDialer: {progress: 2, payload: action.payload}})
    }

    case 'DIALER_REMOVE_PENDING': {
      return({...state, removeDialer: {progress: 0}})
    }
    case 'DIALER_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeDialer: {...api.removeDialer.response, progress: 1, payload: action.payload}})
            return({...state, removeDialer: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeDialer: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'DIALER_REMOVE_REJECTED': {
        return({...state, removeDialer: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}