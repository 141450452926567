import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider  } from 'semantic-ui-react'

import MonitoringRealtime from '../../libraries/realm/halo/MonitoringRealtime';
import PerformanceCampaign from '../../libraries/realm/halo/PerformanceCampaign';
import PerformanceAgent from '../../libraries/realm/halo/PerformanceAgent';

const options= [
  {
    key: '1',
    icon: 'chart line',
    caption: 'MONITORING',
  },
  {
    key: '2',
    icon: 'calendar check outline',
    caption: 'CAMPAIGN',
  },
  {
    key: '3',
    icon: 'chart bar',
    caption: 'AGENT / DUNNER',
  },
]

class PerformanceBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1,
      };
    }

    setMode(mode) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.props
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          {mode==2 && <PerformanceCampaign screenWidth={screenWidth} screenHeight={screenHeight} />}
          {mode==3 && <PerformanceAgent screenWidth={screenWidth} screenHeight={screenHeight} />}
        </Container>
      )
    }
}

export default PerformanceBody
