import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import RatesForm from '../../../libraries/realm/halo/RatesForm'


class RatesBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, ratesId: id})
    }

    render() {
      const {mode, ratesId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div>
            <div>
              <RatesForm mode={2} ratesId={ratesId} setMode={this.setMode.bind(this)} closeModal= {this.props.closeModal}/>
            </div>
          </div>
        </Container>
      )
    }
}

export default RatesBody
