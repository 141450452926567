import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'



class OmniwidgetVideo extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  componentDidMount() {
    const localView = document.getElementById('localView');
    const remoteView = document.getElementById('remoteView');
    
    if (remoteView) {
      remoteView.srcObject = (this.props.remoteVideoStream);
    }
    if (localView) {
      localView.srcObject = (this.props.localVideoStream);
    }
  }

  render() {
    return (
      <div style={{background: '#eee', border: 'none'}}>
        <div style={{position: 'absolute', right: 0, bottom: 0, width: this.props.width/4 || 80, height: this.props.height/4 || 60, overflow: 'hidden', background: 'rgba(255, 255, 0, 0)', margin: '.5em .5em'}}>
          <video id="localView" autoPlay muted={true} width={this.props.width/4 || 80} height={this.props.height/4 || 60}></video>
        </div>
        <div style={{textAlign: 'center', margin: '-.5em 0 -1em 0', width: this.props.width || 320, height: this.props.height || 240, overflow: 'hidden'}}>
          <video id="remoteView" autoPlay  width={this.props.width || 320} height={this.props.height || 240}></video>
        </div>
      </div>
    )
  }
}

export default OmniwidgetVideo