export default function targetReducer (state = {}, action) {
  switch (action.type) {
    case 'TARGET_RESET': {
        return({
            ...state,
            getTarget: {},
            updateTarget: {},
            createTarget: {},
        })
    }

    case 'TARGET_BROWSE_PENDING': {
      return({...state, browseTarget: {progress: 0}})
    }
    case 'TARGET_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseTarget: {...api.browseTarget.response, progress: 1, payload: action.payload}})
            return({...state, browseTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TARGET_BROWSE_REJECTED': {
        return({...state, browseTarget: {progress: 2, payload: action.payload}})
    }
    
    case 'TARGET_GET_PENDING': {
        return({...state, getTarget: {progress: 0}})
    }
    case 'TARGET_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getTarget: {...api.getTarget.response, progress: 1, payload: action.payload}})
            return({...state, getTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TARGET_GET_REJECTED': {
        return({...state, getTarget: {progress: 2, payload: action.payload}})
    }

    case 'TARGET_STATUS_PENDING': {
      return({...state, statusTarget: {progress: 0}})
    }
    case 'TARGET_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusTarget: {...api.statusTarget.response, progress: 1, payload: action.payload}})
            return({...state, statusTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TARGET_STATUS_REJECTED': {
        return({...state, statusTarget: {progress: 2, payload: action.payload}})
    }


    case 'TARGET_REQUEUE_PENDING': {
        return({...state, requeueTarget: {progress: 0}})
    }
    case 'TARGET_REQUEUE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, requeueTarget: {...api.requeueTarget.response, progress: 1, payload: action.payload}})
            return({...state, requeueTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, requeueTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TARGET_REQUEUE_REJECTED': {
        return({...state, requeueTarget: {progress: 2, payload: action.payload}})
    }

    case 'TARGET_CREATE_PENDING': {
      return({...state, createTarget: {progress: 0}})
    }
    case 'TARGET_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createTarget: {...api.createTarget.response, progress: 1, payload: action.payload}})
            return({...state, createTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TARGET_CREATE_REJECTED': {
        return({...state, createTarget: {progress: 2, payload: action.payload}})
    }

    case 'TARGET_UPDATE_PENDING': {
      return({...state, updateTarget: {progress: 0}})
    }
    case 'TARGET_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateTarget: {...api.updateTarget.response, progress: 1, payload: action.payload}})
            return({...state, updateTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TARGET_UPDATE_REJECTED': {
        return({...state, updateTarget: {progress: 2, payload: action.payload}})
    }

    case 'TARGET_REMOVE_PENDING': {
      return({...state, removeTarget: {progress: 0}})
    }
    case 'TARGET_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeTarget: {...api.removeTarget.response, progress: 1, payload: action.payload}})
            return({...state, removeTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TARGET_REMOVE_REJECTED': {
        return({...state, removeTarget: {progress: 2, payload: action.payload}})
    }

    case 'TARGET_ENGAGEMENT_PENDING': {
      return({...state, engagementTarget: {progress: 0}})
    }
    case 'TARGET_ENGAGEMENT_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, engagementTarget: {...api.engagementTarget.response, progress: 1, payload: action.payload}})
            return({...state, engagementTarget: {progress: 2, payload: action.payload}})
        } else {
            return({...state, engagementTarget: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TARGET_ENGAGEMENT_REJECTED': {
        return({...state, engagementTarget: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}