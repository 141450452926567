import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Table, Progress, Label } from 'semantic-ui-react'
import { parse, format, addSeconds } from "date-fns"

import { connect } from "react-redux"
import { occupancyTunnel } from "../../actions/talk/occupancyAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    occupancyStore: state.occupancyStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    occupancyTunnel:(objParam) => dispatch(occupancyTunnel(objParam)),
  }
}


class CallTunnelWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      isExpand: false,
      leftExpand: false,
      rightExpand: false,
    };
  }
  
  setExpand() {
    this.setState({
      isExpand: !this.state.isExpand
    })
  }

  tableExpand(isRight) {
    if (isRight) {
      this.setState({
        rightExpand: !this.state.rightExpand
      })
    } else {
      this.setState({
        leftExpand: !this.state.leftExpand
      })
    }
  }
  
  formatSeconds(seconds) {
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log(date)
    return format(date, 'HH:mm:ss');
  }

  showSpy(item) {
    this.props.showSpy(item);
  }

  render() {
    const { isExpand, leftExpand, rightExpand }= this.state
    const { mode, screenWidth,  screenHeight}= this.props
    const { data, search, paging }= (this.props.occupancyStore.occupancyTunnel!==undefined ? this.props.occupancyStore.occupancyTunnel : {})
    
    let providers=[], seq= 0, totOccupied= 0, callConcurrent= 0, waitingConcurrent= 0;

    data && data.map(item=>{
      callConcurrent+= parseInt(item.callConcurrent ? item.callConcurrent : 0);
      waitingConcurrent+= parseInt(item.waitingConcurrent ? item.waitingConcurrent : 0);
      item.tunnels && item.tunnels.map(tunnel=>{
        totOccupied+= tunnel.occuppied=='1' ? 1 : 0;
      })

      providers.push({
        company: item.company,
        city: item.city,
        
        totOccupied: item.totOccupied || '0',
        callConcurrent: item.callConcurrent || '0',
        waitingConcurrent: item.waitingConcurrent || '0',
      })
    })
    
      return(
        <>
        <Segment attached='top' style={{padding: '1em .5em'}}>
          <Header as='h5'>
            <Icon name='chart bar' style={{fontSize: '1em'}} />
            <Header.Content>
              <b>Call Tunnel Occupancy</b>
              <Header.Subheader></Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
        <Segment attached={isExpand ? true : 'bottom'} style={{padding: '1em'}}>
          <div style={{display: 'flex', textAlign: 'center', margin: '2em 4em 0 4em'}}>
            <div style={{flex: '1'}}>
              <Header as='h2'>
                <Icon color='red' inverted name='chart bar' style={{margin: '0', fontSize: '1em', transform: 'rotate(-90deg)'}} circular />
                <Header.Content>
                  <b>{totOccupied || '0'}</b>
                  <Header.Subheader><b>OCCUPIED</b></Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{flex: '1'}}>
              <Header as='h2'>
                <Icon color='blue' inverted name='chart bar' style={{margin: '0', fontSize: '1em'}} circular />
                <Header.Content>
                  <b>{(callConcurrent - totOccupied) || '0'}</b>
                  <Header.Subheader><b>AVAILABLE</b></Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{}}>
              <Header as='h2'>
                <Icon color='green' inverted name='chart bar' style={{margin: '0', fontSize: '1em', transform: 'rotate(90deg)'}} circular />
                <Header.Content>
                  <b>{callConcurrent || '0'}</b>
                  <Header.Subheader><b>TUNNELS</b></Header.Subheader>
                </Header.Content>
              </Header>
            </div>
          </div>
          <div style={{margin: '1.5em 0'}}>
            <Progress value={totOccupied || 0} total={callConcurrent || 0} indicating active progress size='medium' style={{margin:'0 0 0 0', border: '1px solid rgba(100,100,100,.3)'}} precision={2}/>
          </div>
          {this.props.mode=='admin' && <div style={{textAlign: 'center', marginBottom: '-2em', position: 'absolute', zIndex: '999', left: '49%'}}>
            <Icon name={ isExpand ? 'angle double up' : 'angle double down'} circular inverted color='red' style={{cursor: 'pointer'}} onClick={this.setExpand.bind(this)} />
          </div>}
        </Segment>

        {isExpand && this.props.mode=='admin' && <Segment attached='bottom'>
          <div style={{marginTop: '1em'}}>
            <div style={{display: 'flex', flexDirection: screenWidth<800 ? 'column' : 'row'}}>
              <div style={{flex: '1', paddingRight: '.75em'}}>
                <Table unstackable selectable columns='16' celled striped basic='very' style={{border: 'none'}}>
                  <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                      <Table.HeaderCell width='13'>
                        <Header as='h5' color='blue'>
                          Provider
                        </Header>
                      </Table.HeaderCell>
                      <Table.HeaderCell width='1'>
                        <Popup content='Occupied' trigger={<Icon color='red' name='times circle' style={{margin: '0'}} circular inverted />} />
                      </Table.HeaderCell>
                      <Table.HeaderCell width='1'>
                        <Popup content='Available' trigger={<Icon color='blue' name='check circle' style={{margin: '0'}} circular inverted />} />
                      </Table.HeaderCell>
                      <Table.HeaderCell width='1'>
                        <Popup content='Call Concurrent' trigger={<Icon color='brown' name='pause circle' style={{margin: '0', transform: 'rotate(90deg)'}} circular inverted />} />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {providers.map((item,index)=>{
                      if (index<=providers.length/2) {
                        return(
                          <Table.Row key={++seq} >
                            <Table.Cell><Label>{seq}</Label></Table.Cell>
                            <Table.Cell><b>{item.company}</b></Table.Cell>
                            <Table.Cell textAlign='center' style={{background: '#daefff'}}>{item.totOccupied}</Table.Cell>
                            <Table.Cell textAlign='center' style={{background: '#ffe4e4'}}>{item.callConcurrent-item.totOccupied}</Table.Cell>
                            <Table.Cell textAlign='center' style={{background: '#ffe6cc'}}>{item.callConcurrent}</Table.Cell>
                          </Table.Row>
                        )
                      }
                    })
                  }
                  </Table.Body>
                </Table>
              </div>
              <div style={{flex: '1', paddingLeft: '.75em'}}>
                {providers.length/2>1 &&
                  <Table unstackable selectable columns='16' celled striped basic='very' style={{border: 'none'}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell width='13'>
                          <Header as='h5' color='blue'>
                            Provider
                          </Header>
                        </Table.HeaderCell>
                        <Table.HeaderCell width='1'>
                          <Popup content='Occupied' trigger={<Icon color='red' name='times circle' style={{margin: '0'}} circular inverted />} />
                        </Table.HeaderCell>
                        <Table.HeaderCell width='1'>
                          <Popup content='Available' trigger={<Icon color='blue' name='check circle' style={{margin: '0'}} circular inverted />} />
                        </Table.HeaderCell>
                        <Table.HeaderCell width='1'>
                          <Popup content='Call Concurrent' trigger={<Icon color='brown' name='pause circle' style={{margin: '0', transform: 'rotate(90deg)'}} circular inverted />} />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {providers.map((item,index)=>{
                        if (index>providers.length/2) {
                          return(
                            <Table.Row key={++seq} >
                              <Table.Cell><Label>{seq}</Label></Table.Cell>
                              <Table.Cell><b>{item.company}</b></Table.Cell>
                              <Table.Cell textAlign='center' style={{background: '#daefff'}}>{item.totOccupied}</Table.Cell>
                              <Table.Cell textAlign='center' style={{background: '#ffe4e4'}}>{item.callConcurrent-item.totOccupied}</Table.Cell>
                              <Table.Cell textAlign='center' style={{background: '#ffe6cc'}}>{item.callConcurrent}</Table.Cell>
                            </Table.Row>
                          )
                        }
                      })
                    }
                    </Table.Body>
                  </Table>
                }
              </div>
            </div>
          </div>
        </Segment>}
      </>
      )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CallTunnelWidget)