import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Progress, Grid, Label } from 'semantic-ui-react'

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus, socketSystemMonitoring } from "../../actions/socket/socketAction"

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    // socketRequest:(objParam) => dispatch(socketRequest(objParam)),
    // socketTransmit:(objParam) => dispatch(socketTransmit(objParam)),
    // socketBroadcast:(objParam) => dispatch(socketBroadcast(objParam)),
    // socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
    socketSystemMonitoring:(objParam) => dispatch(socketSystemMonitoring(objParam)),
  }
}

class CredentialWidget extends Component {
  constructor(props){
  	super(props);
    this.state = {};
    this.omnis= null;
  }

  componentDidMount() {
    
  }
  
  render() {
    const { screenWidth,  screenHeight, responsive, tiny}= this.props 

    const {omnis} = (this.props.socketStore.socketSystemMonitoring? this.props.socketStore.socketSystemMonitoring.data.payload.data : {})
    
    if (omnis) {
      this.omnis= omnis;
    }
    
    if (this.omnis) {
      return (
        <>
        {this.omnis.services.svcChannels &&
          <div style={{padding: '1em 0 .5em 0'}}>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Popup header='Voice / Call' content='Voice / call service availability.' style={{cursor: 'pointer'}} trigger={
                    <Header disabled={this.omnis.services.svcChannels.indexOf('Voice')<0} as='h5' style={{cursor: 'pointer'}} color={(this.omnis.services.svcChannels.indexOf('Voice')<0 && 'grey') || 'blue'}>
                      <Header.Content>
                        <Icon name='microphone' /> <span style={{borderLeft: '1px solid #333', paddingLeft: '.8em', fontSize: '.8em'}}>Call</span>
                      </Header.Content>
                    </Header>
                  } />
                </Grid.Column>
                <Grid.Column>
                  <Popup header='IVR / Blast' content='Voice blast service availability.' style={{cursor: 'pointer'}} trigger={
                    <Header disabled={this.omnis.services.svcChannels.indexOf('IVR')<0} as='h5' style={{cursor: 'pointer'}} color={(this.omnis.services.svcChannels.indexOf('IVR')<0 && 'grey') || 'blue'}>
                      <Header.Content>
                        <Icon name='volume down' /> <span style={{borderLeft: '1px solid #333', paddingLeft: '.8em', fontSize: '.8em'}}>IVR</span>
                      </Header.Content>
                    </Header>
                  } />
                </Grid.Column>
                <Grid.Column>
                  <Popup header='SMS' content='SMS blast service availability.' trigger={
                    <Header disabled={this.omnis.services.svcChannels.indexOf('SMS')<0} as='h5' style={{cursor: 'pointer'}} color={(this.omnis.services.svcChannels.indexOf('SMS')<0 && 'grey') || 'blue'}>
                      <Header.Content>
                        <Icon name='sticky note outline' /> <span style={{borderLeft: '1px solid #333', paddingLeft: '.8em', fontSize: '.8em'}}>SMS</span>
                      </Header.Content>
                    </Header>
                  } />
                </Grid.Column>
                {/* <Grid.Column>
                  <Popup header='Online Chat' content='Chat service availability.' trigger={
                    <Header disabled={this.omnis.services.svcChannels.indexOf('Chat')<0} as='h5' style={{cursor: 'pointer'}} color={(this.omnis.services.svcChannels.indexOf('Chat')<0 && 'grey') || 'blue'}>
                      <Header.Content>
                        <Icon name='comments' /> <span style={{borderLeft: '1px solid #333', paddingLeft: '.8em', fontSize: '.8em'}}>Chat</span>
                      </Header.Content>
                    </Header>
                  } />
                </Grid.Column> */}
                <Grid.Column>
                  <Popup header='Email' content='Gmail token valid for one hour, please refresh from setting when expired' trigger={
                    <Header disabled={this.omnis.services.svcChannels.indexOf('Email')<0} as='h5' style={{cursor: 'pointer'}} color={(this.omnis.services.svcChannels.indexOf('Email')<0 && 'grey') || (this.omnis.mailParams && this.omnis.mailParams.expired && this.omnis.mailParams.expired>this.omnis.currentStamp ? 'blue' : 'red')}>
                      <Header.Content>
                        <Icon name='mail' /> <span style={{borderLeft: '1px solid #333', paddingLeft: '.8em', fontSize: '.8em'}}>Email</span>
                      </Header.Content>
                    </Header>
                  } />
                </Grid.Column>
                <Grid.Column>
                  <Popup header='Whatsapp' content='Whatsapp service availability.' trigger={
                    <Header disabled={this.omnis.services.svcChannels.indexOf('Whatsapp')<0} as='h5' style={{cursor: 'pointer'}} color={(this.omnis.services.svcChannels.indexOf('Whatsapp')<0 && 'grey') || (this.omnis.whatsappParams && this.omnis.whatsappParams.expired && this.omnis.whatsappParams.expired>this.omnis.currentStamp ? 'blue' : 'red')}>
                      <Header.Content>
                        <Icon name='whatsapp' /> <span style={{borderLeft: '1px solid #333', paddingLeft: '.8em', fontSize: '.8em'}}>WA</span>
                      </Header.Content>
                    </Header>
                  } />
                </Grid.Column>
                {/* <Grid.Column>
                  <Popup header='Telegram' content='Telegram on queue to be responsed.' trigger={
                    <Header disabled={this.omnis.services.svcChannels.indexOf('Telegram')<0} as='h5' style={{cursor: 'pointer'}} color='blue'>
                      <Header.Content>
                        <Icon name='telegram' /> <span style={{borderLeft: '1px solid #333', paddingLeft: '.8em', fontSize: '.8em'}}>Telegram</span>
                      </Header.Content>
                    </Header>
                  } />
                </Grid.Column> */}
                <Grid.Column>
                  <Popup header='Twitter' content='Twitter token availability.' trigger={
                    <Header disabled={this.omnis.services.svcChannels.indexOf('Twitter')<0} as='h5' style={{cursor: 'pointer'}} color={(this.omnis.services.svcChannels.indexOf('Twitter')<0 && 'grey') || (this.omnis.twitterParams && this.omnis.twitterParams.expired && this.omnis.twitterParams.expired>this.omnis.currentStamp ? 'blue' : 'red')}>
                      <Header.Content>
                        <Icon name='twitter' /> <span style={{borderLeft: '1px solid #333', paddingLeft: '.8em', fontSize: '.8em'}}>Twitter</span>
                      </Header.Content>
                    </Header>
                  } />
                </Grid.Column>
                {/* <Grid.Column>
                  <Popup header='Facebook' content='Facebook token availability.' trigger={
                    <Header disabled={this.omnis.services.svcChannels.indexOf('Facebook')<0} as='h5' style={{cursor: 'pointer'}} color={(this.omnis.services.svcChannels.indexOf('Facebook')<0 && 'grey') || (this.omnis.facebookParams && this.omnis.facebookParams.expired && this.omnis.facebookParams.expired>this.omnis.currentStamp ? 'blue' : 'red')}>
                      <Header.Content>
                        <Icon name='facebook' /> <span style={{borderLeft: '1px solid #333', paddingLeft: '.8em', fontSize: '.8em'}}>FB</span>
                      </Header.Content>
                    </Header>
                  } />
                </Grid.Column>
                <Grid.Column>
                  <Popup header='Instagram' content='Instagram token availability.' trigger={
                    <Header disabled={this.omnis.services.svcChannels.indexOf('Instagram')<0} as='h5' style={{cursor: 'pointer'}} color={(this.omnis.services.svcChannels.indexOf('Instagram')<0 && 'grey') || (this.omnis.instagramParams && this.omnis.instagramParams.expired && this.omnis.instagramParams.expired>this.omnis.currentStamp ? 'blue' : 'red')}>
                      <Header.Content>
                        <Icon name='instagram' /> <span style={{borderLeft: '1px solid #333', paddingLeft: '.8em', fontSize: '.8em'}}>IG</span>
                      </Header.Content>
                    </Header>
                  } />
                </Grid.Column> */}
              </Grid.Row>
            </Grid>
            <Divider  style={{margin: '1.7em 0 .4em 0'}} />
            <div style={{fontSize: '.9em'}}>
              <span style={{marginRight: '1em'}}><Icon name='square full' color='grey' style={{fontSize: '.9em'}}/> Service Disabled</span>
              <span style={{marginRight: '1em'}}><Icon name='square full' color='blue' style={{fontSize: '.9em'}}/> Token Active</span>
              <span style={{marginRight: '1em'}}><Icon name='square full' color='red' style={{fontSize: '.9em'}}/> Token Expired</span>
            </div>
          </div>
        }
        </>
      ) 
    } else {
      return(<></>)
    }
  }
}
CredentialWidget.contextType= WebSocketContext;

export default connect(mapStateToProps, mapDispatchToProps)(CredentialWidget)