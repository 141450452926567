import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ContactBrowse from '../../../libraries/realm/halo/ContactBrowse'
import ContactForm from '../../../libraries/realm/halo/ContactForm'
import ContactSearch from '../../../libraries/realm/halo/ContactSearch'


class ContactBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        contactId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, contactId: id})
    }

    render() {
      const {mode, contactId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
            <ContactSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <ContactBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
              {mode==2 && <ContactForm mode={mode} contactId={contactId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <ContactForm mode={mode} contactId={0} setMode={this.setMode.bind(this)} />}
            </div>
        </Container>
      )
    }
}

export default ContactBody
