import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../../libraries/common/ModalContainer';

import LinkcallBrowse from '../../../libraries/realm/talk/LinkcallBrowse'
import LinkcallWidget from '../../../libraries/realm/talk/LinkcallWidget'
import LinkcallSearch from '../../../libraries/realm/talk/LinkcallSearch'


class LinkcallBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      conferenceId: 0,
    };
  }

  setMode(mode, id, params) {
    this.setState({mode: mode, conferenceId: id, params: params})
  }

  closeModal() {
    this.setState({
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      conferenceId: 0,
      params: null,
    });
  }

  render() {
    const {mode, conferenceId, params}= this.state
    const {screenWidth, screenHeight}= this.props
    
    return(
      <Container fluid>
          <LinkcallSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
          <div>
            <LinkcallBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} conferenceId={conferenceId} />
            {mode==2 && <ModalContainer size='tiny' content={<LinkcallWidget screenWidth={screenWidth} screenHeight={screenHeight-125} closeModal={this.closeModal.bind(this)} conferenceId={conferenceId} params={params} />} closeModal={this.closeModal.bind(this)} /> }
          </div>
      </Container>
    )
  }
}

export default LinkcallBody
