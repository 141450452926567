import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  Popup
} from 'semantic-ui-react'



class PhonewidgetDial extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      keypad: false,
      dtmf: '',
      terminating: '',
      value: '',
      lastChange: null,
    }
  }

  onChangeText(e, v) {
    // const parent= this
    // this.setState({
    //   lastChange: new Date().getTime(),
    //   dtmf: v.value
    // })
    
    // setTimeout(()=>{
    //   if (new Date().getTime()-parent.state.lastChange>=500) {
    //     this.props.dtmfSend(this.state.dtmf);
    //     parent.setState({
    //       dtmf: null,
    //     })
    //   }
    // }, 500)

    if (!this.props.dtmf) {
      this.setState({
        terminating: v.value ,
        value: v.value,
      })
    }
  }

  showKeypad() {
    this.setState({
      keypad: !this.state.keypad
    })
  }

  pressKeypad(v) {
    const terminating= this.state.terminating + v;
    const dtmf= this.state.dtmf + v;
    const value= this.state.value + v;

    console.log('!!! KEYPAD', v, this.props.phoneState, this.props.dtmf)
    if (this.props.phoneState=='oncall' && this.props.dtmf) {
      this.setState({
        lastChange: new Date().getTime(),
        dtmf: dtmf,
        value: dtmf,
      })
      
      setTimeout(()=>{
        if (new Date().getTime()-this.state.lastChange>=1000) {
          console.log('!!! DTMF', dtmf)
          dtmf!='' && this.props.dtmfSend(dtmf);
          this.setState({
            dtmf: '',
            keypad: false,
          })
        }
      }, 1000)
    } else if (this.props.phoneState=='online') {
      this.setState({
        terminating: terminating ,
        value: terminating,
      })
    }
  }

  removeKeypad() {
    if (!this.props.dtmf) {
      const terminating= this.state.terminating.length>1 ? this.state.terminating.substring(0, this.state.terminating.length - 1) : '';
      this.setState({
        terminating: terminating ,
        value: terminating,
      })
    }
  }

  callDial() {
    this.state.terminating!='' && this.props.callDial(this.state.terminating);
  }

  render() {
    return (
      <>
        {!this.props.dtmf && <>
          <div style={{textAlign: 'left'}}>
            <Header as='h3' style={{background: '#fff8c5', borderRadius: '.2em', padding: '.5em', border: '1px solid rgba(150, 150, 150, .3)'}}>
              <Header.Content>
                <Header.Subheader>
                  <b>{(this.props.phoneState && this.props.phoneState.toUpperCase()) || 'DISCONNECTED'}</b>
                </Header.Subheader>
                {this.props.terminating || this.state.terminating || 'Press keypad to dial'}
              </Header.Content>
            </Header>
          </div>

          {/* <Divider /> */}
        </>}

        <div style={{padding: '1em 0 .2em 0', border: 'none', textAlign: 'center'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <Popup position= 'top center' inverted
                trigger={
                    <Input size='small' fluid  style={{fontSize: '1.2em', textAlign: 'left'}} labelPosition='right'
                    readOnly= {!this.props.dtmf && this.props.phoneState=='online'? false : true}
                    placeholder={this.props.dtmf ? 'Send DTMF' : 'Dialed number'}
                    value={this.props.dtmf ? (this.state.dtmf!='' ? this.state.dtmf : this.state.value) : this.state.terminating} className='normal' 
                    label={this.props.dtmf ? null : <Button type='button' onClick={this.removeKeypad.bind(this)}><Icon name='arrow left' style={{margin: '0'}}/></Button>} 
                    onChange={this.onChangeText.bind(this)}
                    /> 
                }
                content={this.props.dtmf ? 'Press number for sending DTMF' : 'Dialed number.'}
                on={['hover']}
              />
            </div>
          </div>
        </div>
        <div style={{background: '#fff', padding: '.4em .4em', border: '1px solid rgba(150, 150, 150, .3)', borderRadius: '.2em'}}>
          {/* <Divider style={{margin: '.3em 0 1.5em 0'}}/> */}
          <div style={{display: 'flex', textAlign: 'center', margin: '1em 0'}}>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '1')}>1</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '2')}>2</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '3')}>3</Button>
            </div>
          </div>
          <div style={{display: 'flex', textAlign: 'center', margin: '1em 0'}}>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '4')}>4</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '5')}>5</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '6')}>6</Button>
            </div>
          </div>
          <div style={{display: 'flex', textAlign: 'center', margin: '1em 0'}}>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '7')}>7</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '8')}>8</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '9')}>9</Button>
            </div>
          </div>
          <div style={{display: 'flex', textAlign: 'center', margin: '1em 0'}}>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '*')}>*</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '0')}>0</Button>
            </div>
            <div style={{flex: '1'}}>
              <Button color='grey' size='large' style={{padding: '.4em 1.3em', width: '3.5em'}} onClick={this.pressKeypad.bind(this, '#')}>#</Button>
            </div>
          </div>
        </div>
        
        {!this.props.dtmf && <>
          <Divider hidden />
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', textAlign: 'center'}}>
              <Button disabled={this.props.phoneState=='offline' ||  this.state.terminating==''} circular icon size='massive' color={this.props.phoneState=='offline' ? 'grey' : 'blue'} style={{margin: '0'}} onClick={this.callDial.bind(this)}><Icon name='phone' /></Button>
            </div>
          </div>
        </>}
      </>
    )
  }
}

export default PhonewidgetDial