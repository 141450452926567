import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, TextArea, Form, Rating, Label } from 'semantic-ui-react'

class LinkwidgetBusy extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
      };
    }
    
    render() {
      const { screenWidth, screenHeight, params }= this.props
      
      return(
        <div style={{padding: '1em 1em', minHeight: screenHeight/2}}>
          <div style={{padding: '1.5em', maxWidth: 375, background: '#eee', borderRadius: '1em', margin: '0 auto'}}>
            <div>
              <div style={{textAlign: 'center', padding: '1.5em 0'}}>
                <Header as='h2' icon style={{margin: '0'}}>
                {(params && params.avatar && <Image style={{width: '3.5em', height: '3.5em'}} circular src={params.avatar} />) || <Icon name='user circle' style={{fontSize: '3.2em'}}/>}
                  <Header.Content>
                    {(params && params.brand) || 'CORPORATE BRAND'}
                    <Header.Subheader>{(params && params.descript) || 'Corporate description / tagline'}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <Divider />

              <div style={{textAlign: 'center', padding: '1.5em 0'}}>
                  <div style={{padding: '2em 1.5em'}}>
                    <Header as='h2'>
                      <Header.Content>
                        Saat ini semua petugas kami sedang sibuk mohon hubungi sesaat lagi.
                      </Header.Content>
                    </Header>
                  </div>
              </div>
              <Divider />

              <div style={{textAlign: 'center', padding: '1.5em 0'}}>
                <Button color='grey' type='button' size='massive' icon labelPosition='left' style={{margin: '.5em 1em', borderRadius: '2em'}} onClick={this.props.setMode.bind(this, 'exit')}><Icon name='arrow left' /> Kembali</Button>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

export default LinkwidgetBusy
