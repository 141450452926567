import React from 'react'
import { Button, Menu, Icon, Label, Divider, Popup } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

class ButtonGroup extends React.Component {
  constructor(props){
  	super(props);
  	this.state = {
      activeItem: ''
    };
  }

  onClick(e, { name }) {
    let { activeItem }= this.state
    if (activeItem!=name) {
      activeItem= name
    } else {
      activeItem= ''
    }
    if (this.props.onClick!=undefined) {
      this.props.onClick(name, activeItem)
    }
    this.setState({ activeItem: activeItem })
  }

  render() {
    let seq= 0
    
    return (
      <Button.Group size='small' icon style={this.props.style==undefined? {paddingLeft: '1em'} : this.props.style}>
        {
          this.props.options.map((option,index)=>{
            return(
              <Popup key={seq++}
              trigger={
              <div>
                <Button type='button' name={option.name} color={this.state.activeItem===option.name ? 'blue' : 'grey'} 
                  style={{height: '2.8em', marginRight: '.25em', borderRadius: '3px', padding: (this.state.activeItem===option.name ? '.0em .35em' : '.0em .35em')}} 
                  onClick={ this.onClick.bind(this) }>
                  <Icon name={option.icon} circular inverted color={(this.state.activeItem===option.name ? 'blue' : 'grey')} style={{margin: '0'}}/>
                </Button>
              </div>
              }
              content={option.tooltip}
              inverted 
              on={['hover', 'click']}
            />
            )
          })
        }
      </Button.Group>
    )
  }
}

export default ButtonGroup
