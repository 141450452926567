import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  Popup,
} from 'semantic-ui-react'


import { connect } from "react-redux"
import { contactOmniphone } from "../../actions/stream/omniphoneAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    contactOmniphone:(objParam) => dispatch(contactOmniphone(objParam)),
  }
}

class OmniphoneOncallMini extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, status, dialings, terminating, responsive, layout, agent }= this.props
    // const { target, customer, visitor, contact }= (this.props.omniphoneStore.contactOmniphone && this.props.omniphoneStore.contactOmniphone.progress=='1' ? this.props.omniphoneStore.contactOmniphone.data : {})

    return (
      <>
        <Segment attached={true} style={{padding: '1em .5em', minHeight: '6em', background: ((status=='locked' && '#b00000e6') || '#11626ce6'), border: 'none'}}>
          {(status=='online' || status=='callback') &&
          <Header as='h3' style={{margin: '1em .5em 0 .5em', display: 'inline'}} inverted>
            {/* <Popup header={'Hangup and Offline'} content={'Click hangup for being offline as agent.'} trigger={
              <Icon name='plus' className='bganimateiconborder' style={{transform: 'rotate(-135deg)', fontSize: '1em', cursor: 'pointer'}} circular inverted color='red' onClick={this.props.callHangup.bind(this)}/>
            } /> */}
            <div style={{display: 'inline-block', width: '2.5em', height: '2.5em', borderRadius: '2.5em', background: '#fff', backgroundImage: agent.avatar ? 'url("'+ agent.avatar +'")' : '', backgroundSize: 'cover', textAlign: 'center'}}>
              {!agent.avatar && <div style={{marginTop: '.45em',color: '#000', fontSize: '1.4em', fontWeight: 'bold'}}>{agent.initial}</div>}
            </div>
            <Header.Content style={{paddingLeft: '.5em'}}>
              {(mode=='predictive' || (mode=='predialed' && dialings && dialings.length==0)) ? 'Online ...' : 'Dialing ...'}
              <Header.Subheader style={{fontSize: '.7em'}}>{mode=='predictive' ? 'Waiting for conversation' : 'Waiting conversation'}</Header.Subheader>
            </Header.Content>
          </Header>}
          {status=='occupied' &&
          <Header as='h3' style={{margin: '1em .5em 0 .5em', display: 'inline'}} inverted>
            {/* <Popup header={'Hangup and Offline'} content={'Click hangup for being offline as agent.'} trigger={
              <Icon name='plus' className='bganimateiconborder' style={{transform: 'rotate(-135deg)', fontSize: '1em', cursor: 'pointer'}} circular inverted color='red' onClick={this.props.callHangup.bind(this)}/>
            } /> */}
            <Header.Content style={{paddingLeft: '.5em'}}>
              {mode=='predictive' ? 'On call ...' : 'On call ...'}
              <Header.Subheader style={{fontSize: '.7em'}}>{mode=='predictive' ? 'Having conversation' : 'Having conversation'}</Header.Subheader>
            </Header.Content>
          </Header>}
          {status=='locked' &&
          <Header as='h3' style={{margin: '1em .5em 0 .5em', display: 'inline'}} inverted >
            {/* <Popup header={'Hangup and Offline'} content={'Click hangup for being offline as agent.'} trigger={
              <Icon name='plus' className='bganimateiconborder' style={{transform: 'rotate(-135deg)', fontSize: '1em', cursor: 'pointer'}} circular inverted color='red' onClick={this.props.callHangup.bind(this)}/>
            } /> */}
            <div style={{display: 'inline-block', width: '2.5em', height: '2.5em', borderRadius: '2.5em', background: '#fff', backgroundImage: agent.avatar ? 'url("'+ agent.avatar +'")' : '', backgroundSize: 'cover', textAlign: 'center'}}>
              {!agent.avatar && <div style={{marginTop: '.45em',color: '#000', fontSize: '1.4em', fontWeight: 'bold'}}>{agent.initial}</div>}
            </div>
            <Header.Content style={{paddingLeft: '.5em'}}>
              {mode=='predictive' ? 'Locked ...' : 'Locked ...'}
              <Header.Subheader style={{fontSize: '.7em'}}>{mode=='predictive' ? 'You are locked / away !' : 'No incoming call receive'}</Header.Subheader>
            </Header.Content>
          </Header>}
        </Segment>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OmniphoneOncallMini)