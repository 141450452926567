import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Icon,
  Statistic,
  Button,
  Modal,
} from 'semantic-ui-react'


class ModalContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      open: true, 
    } 
  }

  handleOpen() {
    this.setState({ open: true })
  }

  handleClose() {
    if (this.props.closeModal!=undefined) {
      this.props.closeModal()
    }
    this.setState({ open: false })
  }
  
  render() {
    const open= this.props.open!=undefined ? this.props.open : this.state.open
    const { provider }= this.props
  
    return (
      <div>
        {provider!=undefined && 
        <Modal
          open={open}
          onClose={this.handleClose.bind(this)}
          size='small'>
          <Modal.Content style={{minHeight: '10em', textAlign: 'center'}}>
            {provider}
          </Modal.Content>
          <Modal.Actions style={{padding: '.5em', background: 'rgba(255,255,255,1)'}}>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1', textAlign: 'left', lineHeight: '2em'}}>
                
              </div>
              <div style={{width: '100px'}}>
                <Button icon onClick={this.handleClose.bind(this)}><Icon name='close' /> Close</Button>
              </div>
            </div>
          </Modal.Actions>
        </Modal>}
      </div>
    )
  }
}

export default ModalContainer