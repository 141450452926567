export default function syslogReducer (state = {}, action) {
  switch (action.type) {
    case 'SYSLOG_RESET': {
        return({
            ...state,
            getSyslog: {},
        })
    }

    case 'SYSLOG_GET_PENDING': {
        return({...state, getSyslog: {progress: 0}})
    }
    case 'SYSLOG_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getSyslog: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getSyslog: {...action.payload.data, 
                //data: action.payload.data.data, 
                progress: 1, payload: action.payload}})
        }
    }
    case 'SYSLOG_GET_REJECTED': {
        return({...state, getSyslog: {progress: 2, payload: action.payload}})
    }

    case 'SYSLOG_BROWSE_PENDING': {
      return({...state, browseSyslog: {progress: 0}})
    }
    case 'SYSLOG_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseSyslog: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseSyslog: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SYSLOG_BROWSE_REJECTED': {
        return({...state, browseSyslog: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}