import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'



class OmniphoneOnlineMini extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { responsive, layout, agent }= this.props
    
    return (
      <>
        <div style={{padding: '1em .5em', minHeight: '6.5em', background: '#444444e6', border: 'none'}}>
          <Header as='h3' inverted style={{margin: '1em .5em 0 .5em', display: 'inline'}}>
            <div style={{display: 'inline-block', width: '2.5em', height: '2.5em', borderRadius: '2.5em', background: '#fff', backgroundImage: agent.avatar ? 'url("'+ agent.avatar +'")' : '', backgroundSize: 'cover', textAlign: 'center'}}>
              {!agent.avatar && <div style={{marginTop: '.45em',color: '#000', fontSize: '1.4em', fontWeight: 'bold'}}>{agent.initial}</div>}
            </div>
            <Header.Content style={{paddingLeft: '.5em'}}>
              Registered !
              <Header.Subheader style={{fontSize: '.7em'}}>
                You are connected.
              </Header.Subheader>
            </Header.Content>
          </Header>
        </div>
      </>
    )
  }
}

export default OmniphoneOnlineMini