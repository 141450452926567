import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Card, Modal, Tab } from 'semantic-ui-react'

import MenuTabular from '../../libraries/common/MenuTabular'
import TelesalesForm from './TelesalesForm'
import CollectionForm from './CollectionForm'
import RestructuringForm from './RestructuringForm'
import VerificationForm from './VerificationForm'
import MessagingForm from './MessagingForm'
import MessagingFeed from './MessagingFeed';

const panes = (props)=>{
  const menus= []
  props.mode=='sms' && menus.push(
    {
      menuItem: { key: '0', content: <Header as='h5'>
        <Icon name='add to cart' color='blue'/>
        <Header.Content>
          Telesales
          <Header.Subheader>Telesales engagement</Header.Subheader>
        </Header.Content>
        </Header> },
      render: () => 
      <Tab.Pane>
        <TelesalesForm />
      </Tab.Pane>,
    }
  )
  props.mode=='wa' && menus.push(
    {
      menuItem: { key: '2', content: <Header as='h5'>
      <Icon name='closed captioning outline' color='blue'/>
      <Header.Content>
        Collection
        <Header.Subheader>Debt collection</Header.Subheader>
      </Header.Content>
      </Header> },
      render: () => 
      <Tab.Pane>
        <CollectionForm />
      </Tab.Pane>
    }
  )
  props.mode=='telegram' && menus.push(
    {
      menuItem: { key: '2', content: <Header as='h5'>
      <Icon name='cubes' color='blue' style={{fontSize: '1.3em'}}/>
      <Header.Content>
        Restructuring
        <Header.Subheader>Debt restructuring</Header.Subheader>
      </Header.Content>
      </Header> },
      render: () => 
      <Tab.Pane>
        <RestructuringForm />
      </Tab.Pane>
    }
  )
  props.mode=='email' && menus.push(
    {
      menuItem: { key: '2', content: <Header as='h5'>
      <Icon name='hotel' color='blue'/>
      <Header.Content>
        Verification
        <Header.Subheader>Customer data verification</Header.Subheader>
      </Header.Content>
      </Header> },
      render: () => 
      <Tab.Pane>
        <VerificationForm />
      </Tab.Pane>
    }
  )
  props.mode=='texting' && menus.push(
    {
      menuItem: { key: '2', content: <Header as='h5'>
      <Icon name='hotel' color='blue'/>
      <Header.Content>
        Tourism
        <Header.Subheader>Traveling and tourism </Header.Subheader>
      </Header.Content>
      </Header> },
      render: () => 
      <Tab.Pane>
        {/* <BookingBody screenWidth={param.screenWidth} screenHeight={param.screenHeight-125} /> */}
      </Tab.Pane>
    }
  )

  return(
    menus
  )
};
export default class MessagingWidget extends Component {
  
  constructor(props){
    super(props);
    this.pos={
      x: 0,
      y: 0,
    }
  	this.state = {
      mode: 'verification',
      skinMode: 'normal',
      windowPos: {x: 0, y: 0},
    };
  }

  handleStart(e, data) {
    this.pos= {
      x: data.x,
      y: data.y,
    }
    // console.log('!!! START DRAG', e,  data)
  }

  handleDrag(e, data) {
    // console.log('!!! ON DRAG', e,  data)
  }

  handleStop(e, data) {
    const { skinMode }= this.state

    if (Math.abs(this.pos.x-data.x)>=200 || Math.abs(this.pos.y-data.y)>=200 || skinMode=='floating') {
      if (skinMode!='floating' ) {
        this.setState({
          skinMode: 'floating',
        })
      }
      this.setState({
        windowPos: {x: data.x, y: data.y}
      })
    }
    
    // console.log('!!! STOP DRAG', e,  data)
  }

  setSkinMode(mode) {
    this.setState({
      skinMode: mode
    })
    // console.log(mode)
  }
  
  render() {
    const { skinMode, windowPos, mode }= this.state
    const { screenWidth,  screenHeight}= this.props

    return (
      <Modal style={{background: '#f5f5f5'}}
        open={skinMode=='normal'}
        // centered={false}
        // onClose={this.handleClose.bind(this)}
        size= 'large'>
        <Segment attached='top' style={{padding: '.5em .5em', background: '#c31', border: 'none'}}  className="handle">
          <div style={{display: 'flex'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' inverted>
                <Icon name='comment alternate outline' style={{fontSize: '1em'}} circular inverted />
                <Header.Content>
                  MESSAGING
                </Header.Content>
              </Header>
            </div>
            <div>
              <Icon name='close' style={{color: '#fff', cursor: 'pointer', padding: '0', marginTop: '.3em', fontSize: '1.5em'}} onClick={this.setSkinMode.bind(this, 'widget')}/>
            </div>
          </div>
        </Segment>
        <Segment attached={true} style={{border: 'none', padding: '0', background: 'rgb(250 250 250)'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', background: 'rgb(75 75 75)'}}>
              <MessagingForm />
            </div>
            <div style={{width: '450px', padding: '1em', background: '#f5f5f5'}}>
              <MessagingFeed />
            </div>
          </div>
        </Segment>
        <Segment attached='bottom' style={{padding: '0em', border: 'none'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', padding: '1em', background: 'rgb(60 60 60)'}}>
              <Header as='h5' inverted color='blue' style={{margin: '0'}}>
                <Header.Content>
                  CONNECTED
                </Header.Content>
              </Header>
            </div>
            <div style={{width: '450px', background: 'rgb(225 225 225)', padding: '1em', textAlign: 'right'}}>
              <Header as='h5'>
                Halo Pagi! Omni Messaging 2.0
              </Header>
            </div>
          </div>
        </Segment>
      </Modal>
    )
  }
}
