import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Pagination,
  Label,
  Table,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { browseTransact, statusTransact } from "../../../actions/stream/transactAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    transactStore: state.transactStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTransact:(objParam) => dispatch(browseTransact(objParam)),
  }
}
class VerificationBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.transactStore.browseTransact!==undefined ? this.props.transactStore.browseTransact : {})
    this.props.browseTransact(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    // this.props.browseTransact({search: {campaignType: 'Verification', transactDate: format(new Date(), 'yyyy-MM-dd')}})
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onChangeStatus(id) {
    this.props.statusTransact({userId:id})
  }

  toggle(status,id) {
    this.props.statusTransact({userId:id, activeStatus: status})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.transactStore.browseTransact!==undefined ? this.props.transactStore.browseTransact : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1'}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                {/* <Table.HeaderCell></Table.HeaderCell> */}
                <Table.HeaderCell width='4'><Header color='blue' as='h5'>TARGET / CAMPAIGN</Header></Table.HeaderCell>
                <Table.HeaderCell width='3'><Header color='blue' as='h5'>AGENT</Header></Table.HeaderCell>
                {/* <Table.HeaderCell width='3'><Header color='blue' as='h5'>CONTACT</Header></Table.HeaderCell> */}
                <Table.HeaderCell width='3'><Header color='blue' as='h5'>RESULT</Header></Table.HeaderCell>
                <Table.HeaderCell width='6' style={{background: '#f1fcfd'}}><Header color='blue' as='h5'>REMARKS</Header></Table.HeaderCell>
                {/* <Table.HeaderCell></Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!=undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={seq}>
                      <Table.Cell>{++seq}</Table.Cell>
                      {/* <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.transactId, item.campaignId, item.clientId)}/>
                      </Table.Cell> */}
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <b>{item.target && item.target.terminating}</b>
                            {/* {(item.target && item.target.name) || '-'} */}
                            <Header.Subheader>
                              <b>{item.target && item.target.name}</b> / {item.campaign && item.campaign.name}
                             {/* (<b>{item.campaign && item.campaign.isEnabled=='1'? <i>Enabled</i> : <i>Disabled</i>}</b>) */}
                             </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <b>{(item.agent && item.agent.name) || "-"}</b>
                            {/* <Header.Subheader>{item.client || 'No company information'}</Header.Subheader> */}
                            <Header.Subheader><i>Trx: {item.transactStamp && format(parse(item.transactStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MMM yyyy')}</i></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {/* <Table.Cell>
                        {item.target &&
                        <Header as='h5'>
                          <Header.Content>
                            {(item.target.mobile || item.target.homePhone || item.target.officePhone) || item.target.whatsapp || item.target.email || item.target.twitter} 
                            <Header.Subheader style={{whiteSpace: 'nowrap'}}>
                              <span style={{marginRight: '.5em'}}><Icon color='teal' name={((item.target.whatsapp||item.target.telegram||item.target.email||item.target.mobile) && 'check square') || 'window close'} style={{marginRight: '.2em'}} />Messaging</span>
                              <span style={{marginRight: '0em'}}><Icon color='teal' name={((item.target.twitter||item.target.instagram||item.target.facebook) && 'check square') || 'window close'} style={{marginRight: '.2em'}} />Social Media</span>
                            </Header.Subheader>
                          </Header.Content>
                        </Header>}
                        </Table.Cell> */}
                      {/* <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.target!=null && item.target.gender} / {item.target!=null && item.target.occupation || 'Job unidentified'}
                            <Header.Subheader>Posted by {(item.target!=null && item.target.owner) || '-'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell> */}
                      <Table.Cell>
                        <Header as='h5'>
                          <div style={{background: (item.status=='1' && '#84dbff') || (item.status=='0' && '#ffb3b3') || '#ddd', textAlign: 'right', display: 'inline-block', width: '4em', padding: '.5em', marginRight: '.5em'}}>{item.progress} %</div>
                          <Header.Content>
                            {item.remark || '-'}
                            <Header.Subheader><i>{(item.promisedDate && format(parse(item.promisedDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd/MMM yyyy')) || (item.paidDate && format(parse(item.paidDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd/MMM yyyy')) || (item.expired && format(parse(item.expired, 'yyyy-MM-dd', new Date()), 'dd/MMM yyyy')) || ''}</i></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell  style={{background: '#f5fdfe'}}>
                        <Header as='h5'>
                          <Header.Content>
                            <b>{item.reason || '-'}</b>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {/* <Table.Cell>
                        <Dropdown pointing='left' direction='left' icon='angle double down' inline>
                          <Dropdown.Menu>
                            <Dropdown.Item><Icon name='phone volume' color='blue'/>Call</Dropdown.Item>
                            <Divider style={{margin: '0'}}/>
                            <Dropdown.Item disabled ><Icon name='comment alternate outline' color='blue'/>Messaging</Dropdown.Item>
                            <Divider style={{margin: '0'}}/>
                            <Dropdown.Item disabled ><Icon name='share alternate' color='blue'/>Social Media</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell> */}
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>

        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerificationBrowse)
