import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'



class OmniphoneOutgoing extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    return (
      <>
        <Segment attached={true} style={{padding: '1em .5em', background: '#444', border: 'none', textAlign: this.props.layout!='mini' ? 'center' : 'left'}}>
          <Header as='h3' color='orange' icon={this.props.layout!='mini'} inverted style={{margin: '1em .5em 0 .5em', display: 'inline'}} >
            {this.props.layout!='mini' &&
            <Icon name='user circle' style={{fontSize: this.props.layout!='mini' ? '5em' : '2.5em'}}/>}
            {this.props.layout=='mini' &&
            <Icon name='phone' style={{transform: 'rotate(-135deg)', fontSize: '1em', cursor: 'pointer'}} circular inverted color='red' onClick={this.props.callHangup.bind(this)}/>}
            <Header.Content>
              Calling (John Doe)
              <Header.Subheader>
                Calling your contact !
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
        {this.props.layout!='mini' &&
        <Segment attached={true} style={{padding: '1em .5em 2em .5em', background: '#444', border: 'none'}}>
          <Divider />
          <div className='bganimateicon' style={{borderRadius: '10em', height: '8em', width: '8em', margin: '2.5em auto 0 auto', textAlign: 'center'}}>
            <Header as='h4' icon inverted style={{margin: '1.5em 0 1em 0'}}>
              <Icon name='phone' style={{transform: 'rotate(-135deg)', fontSize: '1.9em', cursor: 'pointer'}} circular inverted color='red' onClick={this.props.callHangup.bind(this)}/>
              <Header.Content>
                {/* Click to Hangup */}
              </Header.Content>
            </Header>
          </div>
        </Segment>}
      </>
    )
  }
}

export default OmniphoneOutgoing