import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label } from 'semantic-ui-react'

class IOSwidgetBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div style={{minHeight: screenHeight}}>
          <div style={{margin: '1.5em 1em'}}>
            <div style={{display: 'flex', textAlign: 'center'}}>
              <div style={{flex: '1', padding: '2em'}}>
                <Card style={{margin: 'auto'}}>
                  <Card.Content>
                    <Header as='h2' icon>
                      <Header.Content>
                        <Label circular color='olive' style={{fontSize: '1em'}}>1</Label>
                      </Header.Content>
                    </Header>
                    
                    <div style={{minHeight: '6em', padding: '1em', fontSize: '1.3em'}}>
                      Customize widget phone user interface experience, through simply theme setting.
                    </div>

                    <Divider />
                    <Card.Header style={{fontSize: '2em'}}>Widget Theme</Card.Header>
                    <Card.Meta>Customize widget interface and experience from setting / configuratin page.</Card.Meta>
                  </Card.Content>
                </Card>
              </div>
              <div style={{flex: '1', padding: '2em'}}>
                <Card style={{margin: 'auto'}}>
                  <Card.Content>
                    <Header as='h2' icon>
                      <Header.Content>
                        <Label circular color='teal' style={{fontSize: '1em'}}>2</Label>
                      </Header.Content>
                    </Header>
                    
                    <div style={{minHeight: '6em', padding: '1em', fontSize: '1.3em'}}>
                        Makesure your Desk Agent is online and available.
                    </div>

                    <Divider />
                    <Card.Header style={{fontSize: '2em'}}>Desk Agent</Card.Header>
                    <Card.Meta>Start desk Agent  to do call or chat conversation simulation.</Card.Meta>
                  </Card.Content>
                </Card>
              </div>
              <div style={{flex: '1', padding: '2em'}}>
                <Card style={{margin: 'auto'}}>
                  <Card.Content>
                    <Header as='h2' icon>
                      <Header.Content>
                        <Label circular color='yellow' style={{fontSize: '1em'}}>3</Label>
                      </Header.Content>
                    </Header>
                    
                    <div style={{minHeight: '6em', padding: '1em', fontSize: '1.3em'}}>
                      Real time conversation recording from telephony to messaging.
                    </div>

                    <Divider />
                    <Card.Header style={{fontSize: '2em'}}>Conversation</Card.Header>
                    <Card.Meta>Check your conversation from historical telephony or messaging.</Card.Meta>
                  </Card.Content>
                </Card>
              </div>
            </div>

            <Segment>
              <Header as='h3'>
                <Icon name='apple' style={{fontSize: '3em'}} color='grey' />
                <Header.Content>
                  SDK / Library Modules
                  <Header.Subheader style={{fontSize: '1em', color: '#444', padding: '.3em 0 0 0'}}>
                    Sample library and code is available, contact your account executive for implementation.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Segment>
          </div>
        </div>
      )
    }
}

export default IOSwidgetBody
