import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { timeOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'
import IconToggle from '../common/IconToggle'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { contactOmniphone, remarkOmniphone, resetOmniphone } from "../../actions/stream/omniphoneAction"
import { listProduct, listRemark } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('TelesalesForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),
    
    resetOmniphone:(objParam) => dispatch(resetOmniphone(objParam)),
    contactOmniphone:(objParam) => dispatch(contactOmniphone(objParam)),
    remarkOmniphone:(objParam) => dispatch(remarkOmniphone(objParam)),
  }
}

class TelesalesForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    // this.props.listProduct()
    // this.props.listRemark({clientId: this.props.clientId, campaignId: this.props.campaignId})
  }

  componentDidUpdate() {
    const { progress, code, text }= this.props.omniphoneStore.remarkOmniphone!=undefined ? this.props.omniphoneStore.remarkOmniphone : {}

    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.props.resetOmniphone();
        this.props.hasSaved();
      }, 3000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { media } = this.props
    this.props.remarkOmniphone({...values, voices: null, appointments: null });
    
    this.props.endConversation(media, 
      (media=='Chat' && {
        command: 'end_by_agent',
        visitorId: this.props.visitorId,
        agentId: this.props.agentId,
        clientId: this.props.clientId,
      })
    )
  }

  onChangeResult(v) {
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})

    this.props.dispatch(change('TelesalesForm', 'progress', listRemark.progress[v]));
  }

  setMode() {
    this.setState({
      isBilling: !this.state.isBilling
    })
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props

    // const { data }= (this.props.omniphoneStore.contactOmniphone!=undefined && this.props.omniphoneStore.contactOmniphone.progress=='1' ? this.props.omniphoneStore.contactOmniphone : {})
    const { target }= (this.props.omniphoneStore.contactOmniphone!=undefined && this.props.omniphoneStore.contactOmniphone.progress=='1' && this.props.omniphoneStore.contactOmniphone.data ? this.props.omniphoneStore.contactOmniphone.data : {})

    const { progress, code, text }= this.props.omniphoneStore.remarkOmniphone!=undefined ? this.props.omniphoneStore.remarkOmniphone : {}
    const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})
    const columns= [];

    const langEN= {
      product: 'Product',
      valuation: 'Est.  Valuation',
      information: 'Information',
    }
    return (
      <div style={{...style}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div style={{display: 'flex', marginBottom: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h5' inverted style={{padding: '.25em .4em .2em .15em',margin: '0 0 0 -.9em', background: '#db2928', display: 'inline-block'}}>
                <Icon name='angle double down' style={{fontSize: '1em'}}/>
                <Header.Content>
                  REMARKS
                </Header.Content>
              </Header>
            </div>
            <div style={{marginLeft: '1em', width: '15em'}}>
              <Header as='h5' style={{fontWeight: 'bold'}} color='red'>
                <IconToggle active={this.state.isBilling} onClick={this.setMode.bind(this)} />
                <Header.Content>
                  SALES INFO
                  <Header.Subheader>Telesales Summary</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
          </div>

          <Segment>
            <Header as='h5'>
              <Icon name='edit outline' style={{fontSize: '1em'}}/>
              <Header.Content>Telesales Remarks</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='remark' component={SelectField} onChange={this.onChangeResult.bind(this)}
              width={8}
              options={listRemark.options||[]}
              validate={[required]}
              label= 'Result'
              placeholder='Telesales Result' />
              {this.props.remark=='Rescheduled' && <>
              <Field name='appointmentDate' component={DatePickerField}
              width={4}
              label= 'Appointment Date'
              placeholder='Appointment Date' />
              <Field name='appointmentSession' component={SelectField}
              options={timeOptions||[]}
              width={4}
              label= 'Appointment Time'
              placeholder='Appointment Time' />
              </>}
              {this.props.remark!='Rescheduled' && <>
              <Field name='productId' component={SelectField}
              width={8}
              options={listProduct||[]}
              label='Offered Product / Service'
              placeholder='Offered Product / Service' />
              <Field name='value' component={NumberField}
              prefix='Rp. '
              thousandSeparator=','
              width={4}
              label='Amount / Valuation'
              placeholder='Amount / Valuation' /></>}
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='reason' component={InputField}
              width={16}
              label= 'Reason'
              placeholder='Remarks Note / Reason' />  
            </Form.Group>
          </Segment>
          
          {this.state.isBilling && target!=undefined && target.telesales && 
          <Segment style={{marginTop: '-.5em'}}>
            {target.telesales && <>
              <Header as='h5'>
                <Icon name='envelope open outline' style={{fontSize: '1em'}}/>
                <Header.Content>Sales Information</Header.Content>
              </Header>

              <Grid columns='equal' doubling>
                {this.props.popup && this.props.popup.length>0 && this.props.popup.map((item, i)=>{
                  if (!columns[Math.floor(i/4)]) {
                    columns[Math.floor(i/4)]= []
                  }
                  if (item.toLowerCase().includes('amount')) {
                    columns[Math.floor(i/4)].push(
                      <Grid.Column key={i}>
                        <Header as='h5' style={{margin: '0'}}>
                          <Header.Content>
                            <b>{(target.telesales[item] && !isNaN(target.telesales[item])) ? numeral(target.telesales[item]).format('0,0') :  (target.telesales[item] || '-')}</b>
                            <Header.Subheader><b>{langEN[item]}</b></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    )
                  } else {
                    columns[Math.floor(i/4)].push(
                      <Grid.Column key={i}>
                        <Header as='h5' style={{margin: '0'}}>
                          <Header.Content>
                            <b>{target.telesales[item] || '-'}</b>
                            <Header.Subheader><b>{langEN[item]}</b></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    )
                  }
                })}
                {columns.map((data, i)=>{
                  return(
                    <Grid.Row key={i} style={{padding: '.8em 0'}}>
                      {data.map(item=>{
                        return item;
                      })}
                    </Grid.Row>
                  )
                })}
                {(!this.props.popup || this.props.popup.length==0) && 
                  <Grid.Row style={{padding: '.8em 0'}}>
                    <Grid.Column>
                      <Header as='h5' style={{margin: '0'}}>
                        <Header.Content>
                          <b>{target.telesales['product'] || '-'}</b>
                          <Header.Subheader><b>Product / Service</b></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Header as='h5' style={{margin: '0'}}>
                        <Header.Content>
                          <b>{target.telesales['valuation'] || '-'}</b>
                          <Header.Subheader><b>Est. Valuation</b></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Header as='h5' style={{margin: '0'}}>
                        <Header.Content>
                          <b>{target.telesales['information'] || '-'}</b>
                          <Header.Subheader><b>Prospect/Lead Information</b></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>}
              </Grid>
            </>}
            {!target.telesales && <div>
              <Header as='h4' style={{margin: '0'}} >
                <Icon name='warning sign' style={{color: '#aaa', fontSize: '2em', paddingRight: '.5em'}} color='red' />
                <Header.Content>
                  No Sales Information, 
                  <Header.Subheader>Customer's sales data is not uploaded.</Header.Subheader>
                </Header.Content>
              </Header>
            </div>}
          </Segment>}
          
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Transact' : 'Insert Transact') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.omniphoneStore.contactOmniphone!=undefined && state.omniphoneStore.contactOmniphone.progress==1 && state.omniphoneStore.contactOmniphone.data ? state.omniphoneStore.contactOmniphone.data.target : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
      remark: selector(state, 'remark'),
    }),
  )
  (reduxForm({
    form: 'TelesalesForm',	// a unique identifier for this form
  })(TelesalesForm)
  )
)