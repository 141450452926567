import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetAudittrail(objParam) {
  return {
      type: 'AUDITTRAIL_RESET',
      payload: objParam
  }
}

export function loggingAudittrail(objParam) {
  return {
      type: 'AUDITTRAIL_LOGGING',
      payload: objParam
  }
}

export function getAudittrail(objParam) {
  return {
      type: 'AUDITTRAIL_GET',
      payload:
        axios.post(
          urlAPI + 'system/audittrail/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusAudittrail(objParam) {
  return {
      type: 'AUDITTRAIL_STATUS',
      payload:
        axios.post(
          urlAPI + 'system/audittrail/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseAudittrail(objParam) {
  return {
      type: 'AUDITTRAIL_BROWSE',
      payload:
        axios.post(
          urlAPI + 'system/audittrail/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createAudittrail(objParam) {
  return {
      type: 'AUDITTRAIL_CREATE',
      payload:
        axios.post(
          urlAPI + 'system/audittrail/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateAudittrail(objParam) {
  return {
      type: 'AUDITTRAIL_UPDATE',
      payload:
        axios.post(
          urlAPI + 'system/audittrail/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeAudittrail(objParam) {
  return {
      type: 'AUDITTRAIL_REMOVE',
      payload:
        axios.post(
          urlAPI + 'system/audittrail/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
