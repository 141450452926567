import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Dropdown } from 'semantic-ui-react'
import { parse, format, addSeconds } from "date-fns"

import PieRechart from '../../chart/PieRechart';

import { connect } from "react-redux"
import { statWebhook } from "../../../actions/halo/statisticAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    statWebhook:(objParam) => dispatch(statWebhook(objParam)),
  }
}

class WebhookWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      stamp: '',
      pieData: [
        {name: 'OK', value: 0},
        {name: 'NOK', value: 0},
      ],
    };
  }
  
  componentDidUpdate() {
    const { pieData, stamp}= this.state
    const { data, search }= (this.props.statisticStore.statWebhook!==undefined ? this.props.statisticStore.statWebhook : {})

    const totals={
      OK: 0,
      NOK: 0,
    }

    // if (this.state.stamp!=_stamp) {
    if (this.state.stamp=='') {
      if (data && data.statWebhooks && data.statWebhooks[format(new Date(), 'dd')]) {
        Object.entries(data.statWebhooks[format(new Date(), 'dd')]).forEach(([inventory, values]) => {
          totals['OK']+= values.totSucceed
          totals['NOK']+= values.totFailed
        })

        this.setState({
          stamp: '-',
          pieData: [ 
            {name: 'OK', value: totals.OK || 0},
            {name: 'NOK', value: totals.NOK || 0},
          ]
        });
      }
    }
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const { pieData}= this.state
    
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart bar' style={{fontSize: '1em'}} />
          <Header.Content>
            Webhook Logger / {this.state.stamp}
            <Header.Subheader>Webhook response success rate.</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em'}}>
        {/* {data!=undefined && */}
          <PieRechart width={350} height={250} label={true} data={pieData} mode='active' fill='#555'/>
        {/* } */}
      </Segment>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(WebhookWidget)