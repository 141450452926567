import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import MenuHorizontal from '../../libraries/common/MenuHorizontal';

import TelephonyBody from './TelephonyBody'
import MessagingBody from './MessagingBody'
import SocialBody from './SocialBody'

class ConversationBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        // dashboardId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight, style}= this.props

      return(
        <Container fluid style={{...style}}>
          <TelephonyBody openWindow={this.props.openWindow.bind(this)} screenHeight={screenHeight} screenWidth={screenWidth} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />
        </Container>
      )
    }
}

export default ConversationBody
