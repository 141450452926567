import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid, Label } from 'semantic-ui-react'

import numeral from "numeral"
import PieRechart from '../chart/PieRechart';

import { connect } from "react-redux"
import { occupancyMau } from "../../actions/talk/occupancyAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    occupancyStore: state.occupancyStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    occupancyMau:(objParam) => dispatch(occupancyMau(objParam)),
  }
}

class MAUWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }
  
  render() {
    const { screenWidth,  screenHeight, responsive}= this.props
    const { data, search, paging }= (this.props.occupancyStore.occupancyMau!==undefined ? this.props.occupancyStore.occupancyMau : {})

    let pieData, providers=[], seq= 0, totAccount= 0, totAllocated= 0, totAvailable= 0, totReserved=0;

    data && data.map(item=>{
      item.voip_accounts && item.voip_accounts.map(mau=>{
        totAccount+= parseInt(mau.totAccount ? mau.totAccount : 0);
        totAllocated+= parseInt(mau.totAllocated ? mau.totAllocated : 0);
        totAvailable+= parseInt(mau.totAvailable ? mau.totAvailable : 0);
        totReserved+= parseInt(mau.totReserved ? mau.totReserved : 0);
      })

      providers.push({
        company: item.company,
        city: item.city,
        
        totAccount: item.totAccount || '0',
        totAllocated: item.totAllocated || '0',
        totAvailable: item.totAvailable || '0',
        totReserved: item.totReserved || '0',
      })
    })

    pieData= [
      { name: 'Tot. Account', value: totAccount }, 
      { name: 'Tot. Allocated', value: totAllocated },
      { name: 'Tot. Available', value: totAvailable }, 
      { name: 'Tot. Reserved', value: totReserved },
    ];
    
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart bar' style={{fontSize: '1em'}} />
          <Header.Content>
            <b>Monthly Active User</b>
            <Header.Subheader></Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em'}}>
        <Grid columns={screenWidth>1024 ? 4 : 2} stackable style={{margin: '2em 0'}}>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as='h1'>
              {totAvailable>1000 ? numeral(totAvailable).format('0.0a') : numeral(totAvailable).format('0,0')}
            </Header>
            <Header as='h5'>
              AVAILABLE
              <Header.Subheader>Account.</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as='h1'>
            {totAllocated>1000 ? numeral(totAllocated).format('0.0a') : numeral(totAllocated).format('0,0')}
            </Header>
            <Header as='h5'>
              ALLOCATED
              <Header.Subheader>Account.</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as='h1'>
              {totReserved>1000 ? numeral(totReserved).format('0.0a') : numeral(totReserved).format('0,0')}
            </Header>
            <Header as='h5'>
              RESERVED
              <Header.Subheader>Account.</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as='h1'>
              {totAccount>1000 ? numeral(totAccount).format('0.0a') : numeral(totAccount).format('0,0')}
            </Header>
            <Header as='h5'>
              LICENSE
              <Header.Subheader>Account.</Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid>

        <Divider />
        <Grid columns={16} stackable>
          <Grid.Column style={{textAlign: 'center'}} width={7}>
            {pieData &&
            <PieRechart data={pieData} legend width={300} height={300} mode='circle' fill='#555'/>}
            {/* <Header as='h5'>
              PERFORMANCE
              <Header.Subheader>Profiling based on personal</Header.Subheader>
            </Header> */}
          </Grid.Column>
          <Grid.Column width={9}>
            <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%'}}>
              <div style={{flex: '1'}}>
                
              </div>
              <div>
                <Header as='h5'>
                  <Label color='olive' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{(totAccount ? Math.ceil((totAllocated || 0)/totAccount * 100) : 0)}%</Label>
                  <Header.Content>
                    ALLOCATED
                    <Header.Subheader>Account.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Label color='green' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{(totAccount ? Math.ceil(totReserved/totAccount * 100) : 0)}%</Label>
                  <Header.Content>
                    RESERVED
                    <Header.Subheader>Account.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Label color='blue' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{(totAccount ? Math.ceil((totAccount - (totAllocated || 0))/totAccount * 100) : 0)}%</Label>
                  <Header.Content>
                    AVAILABLE
                    <Header.Subheader>Account.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                
              </div>
            </div>
            
          </Grid.Column>
        </Grid>
      </Segment>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MAUWidget)
