import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Label, Progress } from 'semantic-ui-react'

import numeral from "numeral"

import { connect } from "react-redux"
import { statAssignment } from "../../actions/halo/statisticAction"
import { updateBridging } from "../../actions/stream/bridgingAction"
import { socketAgentStatus } from "../../actions/socket/socketAction"

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
    bridgingStore: state.bridgingStore,
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    // socketRequest:(objParam) => dispatch(socketRequest(objParam)),
    // socketTransmit:(objParam) => dispatch(socketTransmit(objParam)),
    // socketBroadcast:(objParam) => dispatch(socketBroadcast(objParam)),
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
    statAssignment:(objParam) => dispatch(statAssignment(objParam)),
    updateBridging:(objParam) => dispatch(updateBridging(objParam)),
  }
}

class AssignmentWidget extends Component {
  constructor(props){
  	super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.statAssignment() 

    // this.props.socketRequest({
    //   event: 'req://agent:hello',
    //   payload: 'PARAM_REQUEST',
    //   socket: this.context.socket,
    // })
    // this.props.socketTransmit({
    //   event: 'send://agent:hello',
    //   payload: 'PARAM_REQUEST',
    //   socket: this.context.socket,
    // })
  }
  
  render() {
    const { screenWidth,  screenHeight, responsive, tiny}= this.props 

    const { data, search, paging }= (this.props.statisticStore.statAssignment!==undefined ? this.props.statisticStore.statAssignment : {})
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data : undefined)
    const { status }= (agentStatus && agentStatus.payload.data) || {status: 'connect'}

    // console.log(agentStatus)

    return (
      <>
      {!tiny && 
        <Segment style={{background: responsive ? '#444' : '#f5f5f5', borderLeft: responsive ? 'none' : '', borderRight: responsive ? 'none' : '', borderBottom: responsive ? 'none' : '', padding: '0'}}>
          <div style={{display: 'flex'}}>
            <div style={{padding: '1em 1em 1em 2em', flex: '1'}}>
              {data!=undefined && data.client==null && <>
                <Header as={responsive ? 'h3' : 'h1'} color='red' style={{fontWeight: '600', textShadow: responsive ? '0px 1px rgba(50, 50, 50,.8)' : '0px 1px rgba(255,255,255,.4)'}}  inverted={responsive}>
                  <Header.Content>
                    <Header.Subheader style={{color: responsive ? '#f31' : '#444', fontSize: responsive ? '.8em' : '.6em'}}>
                      No contract available 
                    </Header.Subheader>
                    Get your payment !
                    {/* Send your application ! */}
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '0'}} color='grey' inverted={responsive}>
                  1. Click <mark>&nbsp;APPLY&nbsp;</mark> button to start.
                </Header>
                {/* <Header as='h5' style={{margin: '.3em 0 0 0'}} color='grey'>
                  2. Record your voice as agent call center.
                </Header> */}
                <Header as='h5' style={{margin: '0'}} color='grey' inverted={responsive}>
                  2. Wait your employer's review or call, then you will start your job.
                </Header>
                <Header as='h5' style={{margin: '.3em 0 0 0'}} color='grey' inverted={responsive}>
                  3. There is no charged to you, payment is transfered to your account.
                </Header>
                <Header as='h5' style={{margin: '0'}} color='grey' inverted={responsive}>
                  4. Be a professional or you got terminated !.
                </Header>
              </>}
              {data!=undefined && data.client!=null && <>
                <div style={{display: 'flex', textAlign: 'center'}}>
                  <div style={{flex: '1'}}>
                    <Header as='h2' icon inverted={responsive ? true : false} style={{margin: '0'}}>
                      <Header.Content>
                        <Label circular>{data && (data.totCampaign>1000 ? numeral(data.totCampaign||0).format('0.00a') : numeral(data.totCampaign).format('0'))}</Label>
                        <Header.Subheader style={{color: responsive ? '#fff' : '#000'}}>Campaigns</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{flex: '1'}}>
                    <Header as='h2' icon inverted={responsive ? true : false} style={{margin: '0'}}>
                      <Header.Content>
                        <Label color='yellow' circular>{data && (((data.totFloating + data.totLocked) || 0)>1000 ? numeral(((data.totFloating + data.totLocked) || 0)).format('0.00a') : numeral(((data.totFloating + data.totLocked) || 0)).format('0'))}</Label>
                        <Header.Subheader style={{color: responsive ? '#fff' : '#000'}}>Contacts</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                </div>
                {/* <div>
                  <Label horizontal color='teal'>Contacted</Label> <Progress size='medium' percent={Math.round(data.totContacted/(data.totFloating + data.totLocked))} indicating progress='percent' style={{margin: '0'}}/>
                </div> */}
              </>}
            </div>
            {!responsive && screenWidth>900 &&
            <div style={{padding: '1.5em', marginLeft: '1em', textShadow: '0 2px 0 #fff', background: '#f1f1f1'}}>
              {data!=undefined && data.client!=null &&
                <Header as='h2'>
                  <Header.Content>
                    <Header.Subheader style={{color: '#000', fontSize: '.5em'}}>PROVIDER</Header.Subheader>
                    {data!=undefined && data.client.name}
                    <Header.Subheader>
                      {data!=undefined && (data.client.duration || 'No contract termination info')}
                      {/* 30 / Juni - 20 Januari 2023 */}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              }
            </div>
          }
          </div>
          <div style={{fontSize: '1.1em', padding: '.5em 0 1em 0', textAlign: 'center', color: responsive ? '#fc2' : '#333', background: '#fafafa'}}>
            Target summary: <b style={{color: '#c31'}}>Locking Target ({data && (data.totLocked||0)})</b> / <b style={{color: '#175'}}>Floating Target ({data && (data.totFloating||0)})</b>
          </div>
        </Segment>
      }
      </>
    );
  }
}
AssignmentWidget.contextType= WebSocketContext;

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentWidget)