import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed } from 'semantic-ui-react'

import BarRechart from '../chart/BarRechart';

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus, socketSystemMonitoring, socketSystemHeartbeat } from "../../actions/socket/socketAction"
import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketSystemHeartbeat:(objParam) => dispatch(socketSystemHeartbeat(objParam)),
  }
}

class OccupancyStatisticWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      stamp: '',
      barData: [
        // {name: '05:02', totAttempt: 1, totDelivered: 5, totDropped: 15, totEngaged: 1, totAchieved: 5}, 
        // {name: '05:03', totAttempt: 2, totDelivered: 6, totDropped: 9, totEngaged: 1, totAchieved: 5}, 
        // {name: '05:04', totAttempt: 3, totDelivered: 7, totDropped: 5, totEngaged: 1, totAchieved: 5}, 
        // {name: '05:05', totAttempt: 4, totDelivered: 8, totDropped: 7, totEngaged: 1, totAchieved: 5}, 
      ],
      barLegend: [
        {id: 'totAttempt', name: 'Tot. Attempt'},
        {id: 'totDelivered', name: 'Tot. Call'},
        {id: 'totDropped', name: 'Tot. Dropped'},
        {id: 'totEngaged', name: 'Tot. Talk'},
        {id: 'totAchieved', name: 'Tot. Achieved'},
      ]
    };
  }
  
  componentDidUpdate() {
    const { barData, stamp}= this.state
    const { data }= this.props.socketStore.socketSystemHeartbeat!=undefined ? this.props.socketStore.socketSystemHeartbeat: {}
    const { period, service, traffic}= (data && data.payload && data.payload.data) || {}

    // console.log(data)
    if (data && period && stamp!=period.substring(11,16)) {
      if (barData.length>10) {
        this.setState({
          barData: [...barData.slice(1), {name: period.substring(11,16), ...traffic}],
          stamp: period.substring(11,16),
        })
      } else {
        this.setState({
          barData: [...barData, {name: period.substring(11,16), ...traffic}],
          stamp: period.substring(11,16),
        })
      }
    }
  }
  
  render() {
    const { dark, screenWidth,  screenHeight}= this.props
    const { barData, barLegend}= this.state
    const { data, search, paging }= (this.props.socketStore.socketSystemHeartbeat!==undefined ? this.props.socketStore.socketSystemHeartbeat : {})
    
    return (
      <>
      <Segment style={{padding: '1em .3em', background: dark ? '#555' : '#fff'}}>
        <Header as='h3' style={{padding: '0 .7em'}} inverted={dark}>
          <Icon name='chart bar' style={{fontSize: '1em'}} />
          <Header.Content>
            OCCUPANCY | TIME BASIS
            {/* <Header.Subheader>Busy, online time basis comparison.</Header.Subheader> */}
          </Header.Content>
        </Header>
        
        {data!=undefined && <>
          <BarRechart mode='shape' height={screenHeight/2.6} yAxis={false} dashed={true} label={true} data={barData} legend={barLegend} />
        </>}
      </Segment>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OccupancyStatisticWidget)