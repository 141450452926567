import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'

import { connect } from "react-redux"
import { browseAgent } from "../../../actions/halo/agentAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    agentStore: state.agentStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseAgent:(objParam) => dispatch(browseAgent(objParam)),
  }
};


class AgentBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.agentStore.browseAgent!==undefined ? this.props.agentStore.browseAgent : {})
    this.props.browseAgent(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.agentStore.browseAgent!==undefined ? this.props.agentStore.browseAgent : {})
    this.props.browseAgent({search: {...search}, paging: paging})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.agentStore.browseAgent!==undefined ? this.props.agentStore.browseAgent : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1'}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>
                    AGENT
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>
                    CONTACT
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>
                    PRIVILEGE
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='8'>
                  <Header as='h5' color='blue'>
                    INFORMATION
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.agentId)}/>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' color={((item.isEnabled=='0' || item.isDeleted=='1') && 'brown') || 'black'}>
                          <Header.Content>
                            <span style={item.isEnabled!='1'? {fontSize:'1.15em', textDecoration: 'line-through', color: '#A5673F'} : {}}>{item.account} / {item.name}</span>
                            <Header.Subheader>{item.client || 'Nobody'}</Header.Subheader>
                            <Header.Subheader>{(item.isEnabled=='0' ? 'Disabled' : '') +' '+ (item.isDeleted=='1' ? 'Deleted' : '')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <Header.Subheader>Contact</Header.Subheader>
                            {item.mobile || item.email}
                          </Header.Content>
                        </Header>
                        {item.voipAccount!=null &&
                        <Header as='h5' style={{margin: '0'}}>
                          <Header.Content>
                            <Header.Subheader>Voip Account</Header.Subheader>
                            {item.voipAccount+'@'+item.voipHost+':'+item.voipPort}
                          </Header.Content>
                        </Header>}
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' style={{margin: '0 0 .5em 0'}}>
                          <Header.Content>
                            <Header.Subheader>Tagger</Header.Subheader>
                            {item.tagger || 'No tagger'}
                          </Header.Content>
                        </Header>
                        <Header as='h5' style={{margin: '0'}}>
                          <Header.Content>
                            <Header.Subheader>Group</Header.Subheader>
                            {item.groupId || 'Not assigned'}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' style={{margin: '0 0 .5em 0'}}>
                          <Header.Content>
                            <Header.Subheader>Personal</Header.Subheader>
                            <i>{item.gender || 'Unknown gender'}</i> from <i>{item.city || item.province || 'Nowhere'}</i>
                          </Header.Content>
                        </Header>
                        <Header as='h5' style={{margin: '0'}}>
                          <Header.Content>
                            <Header.Subheader>Competency</Header.Subheader>
                            <i>{item.educations || 'No education background'}</i>, expert at <i>{item.competencies || 'No competency available'}</i>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        
        {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
               
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentBrowse)
