import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label, Popup, Feed, Pagination } from 'semantic-ui-react'

import { connect } from "react-redux"
import { getAccount, resetAccount } from "../../../actions/socmed/accountAction"
import { browseTimeline, getTimeline, resetTimeline, createTimeline, updateTimeline } from "../../../actions/socmed/timelineAction"
import TwitterPost from './TwitterPost'

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    accountStore: state.socmed_accountStore,
    timelineStore: state.socmed_timelineStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetAccount:(objParam) => dispatch(resetAccount(objParam)),
    getAccount:(objParam) => dispatch(getAccount(objParam)),

    browseTimeline:(objParam) => dispatch(browseTimeline(objParam)),
    resetTimeline:(objParam) => dispatch(resetTimeline(objParam)),
    getTimeline:(objParam) => dispatch(getTimeline(objParam)),
    createTimeline:(objParam) => dispatch(createTimeline(objParam)),
    updateTimeline:(objParam) => dispatch(updateTimeline(objParam)),
  }
}

class TwitterCreation extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 0,
      };
    }

    componentDidMount(){
      this.props.resetAccount()
      this.props.getAccount({accountType: 'Twitter'})
    
      const { search, paging }= (this.props.timelineStore.browseTimeline!==undefined ? this.props.timelineStore.browseTimeline : {})
      this.props.browseTimeline({search: {...search}})
    }

    doPaging(e, page) {
      const { search, paging }= (this.props.timelineStore.browseTimeline!==undefined ? this.props.timelineStore.browseTimeline : {})
      this.props.browseTimeline(
        {
          paging: {...paging, page: page.activePage},
          search: search
        }
      )
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      const account= (this.props.accountStore.getAccount!==undefined ? this.props.accountStore.getAccount.data : null)
    
      const { data, search, paging }= (this.props.timelineStore.browseTimeline!==undefined ? this.props.timelineStore.browseTimeline : {})
      let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
      
      return(
        <div>
          <Grid>
            <Grid.Row width={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    Twitter <b>Profiles</b>
                    <Header.Subheader>Twitter account profile.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment style={{textAlign: 'center'}}>
                  <Image src='https://react.semantic-ui.com/images/wireframe/square-image.png' size='medium' circular centered />
                  <Header as='h5'>
                    <Header.Content>
                      <Header.Subheader>Name</Header.Subheader>
                      {account && account.name || 'No Name'}
                    </Header.Content>
                  </Header>
                  <Header as='h5'>
                    <Header.Content>
                      <Header.Subheader>Account</Header.Subheader>
                      {account && account.accountId || 'No Account'}
                    </Header.Content>
                  </Header>
                  <Header as='h5'>
                    <Header.Content>
                      <Header.Subheader>Description</Header.Subheader>
                      {account && account.descript || 'No Description'}
                    </Header.Content>
                  </Header>
                </Segment>
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    Twitter <b>Creation</b>
                    <Header.Subheader>Recent, post and delete twits.</Header.Subheader>
                  </Header.Content>
                </Header>
                <TwitterPost />
                <Segment>
                  POST CONTENT<br />
                  <b>https://developer.twitter.com/en/docs/twitter-api/v1/tweets/post-and-engage/api-reference/post-statuses-update</b>
                  
                </Segment>
                <Segment>
                  <p>
                    TIMELINES<br />
                    <b>https://developer.twitter.com/en/docs/twitter-api/v1/tweets/timelines/api-reference/get-statuses-home_timeline</b>
                  </p>
                  
                  <Feed>
                    <Feed.Event>
                      <Feed.Label>
                        <img src='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' />
                      </Feed.Label>
                      <Feed.Content>
                        <Feed.Summary>
                          <Feed.User>Elliot Fu</Feed.User> added you as a friend
                          <Feed.Date>1 Hour Ago</Feed.Date>
                        </Feed.Summary>
                        <Feed.Meta>
                          <Feed.Like>
                            <Icon name='like' />4 Likes
                          </Feed.Like>
                        </Feed.Meta>
                      </Feed.Content>
                    </Feed.Event>

                    <Feed.Event>
                      <Feed.Label image='/images/avatar/small/helen.jpg' />
                      <Feed.Content>
                        <Feed.Summary>
                          <a>Helen Troy</a> added <a>2 new illustrations</a>
                          <Feed.Date>4 days ago</Feed.Date>
                        </Feed.Summary>
                        <Feed.Extra images>
                          <a>
                            <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                          </a>
                          <a>
                            <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                          </a>
                        </Feed.Extra>
                        <Feed.Meta>
                          <Feed.Like>
                            <Icon name='like' />1 Like
                          </Feed.Like>
                        </Feed.Meta>
                      </Feed.Content>
                    </Feed.Event>

                    <Feed.Event>
                      <Feed.Label image='/images/avatar/small/jenny.jpg' />
                      <Feed.Content>
                        <Feed.Summary
                          date='2 Days Ago'
                          user='Jenny Hess'
                          content='add you as a friend'
                        />
                        <Feed.Meta>
                          <Feed.Like>
                            <Icon name='like' />8 Likes
                          </Feed.Like>
                        </Feed.Meta>
                      </Feed.Content>
                    </Feed.Event>

                    <Feed.Event>
                      <Feed.Label image='/images/avatar/small/joe.jpg' />
                      <Feed.Content>
                        <Feed.Summary>
                          <a>Joe Henderson</a> posted on his page
                          <Feed.Date>3 days ago</Feed.Date>
                        </Feed.Summary>
                        <Feed.Extra text>
                          Ours is a life of constant reruns. We're always circling back to where
                          we'd we started, then starting all over again. Even if we don't run
                          extra laps that day, we surely will come back for more of the same
                          another day soon.
                        </Feed.Extra>
                        <Feed.Meta>
                          <Feed.Like>
                            <Icon name='like' />5 Likes
                          </Feed.Like>
                        </Feed.Meta>
                      </Feed.Content>
                    </Feed.Event>

                    <Feed.Event>
                      <Feed.Label image='/images/avatar/small/justen.jpg' />
                      <Feed.Content>
                        <Feed.Summary>
                          <a>Justen Kitsune</a> added <a>2 new photos</a> of you
                          <Feed.Date>4 days ago</Feed.Date>
                        </Feed.Summary>
                        <Feed.Extra images>
                          <a>
                            <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                          </a>
                          <a>
                            <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                          </a>
                        </Feed.Extra>
                        <Feed.Meta>
                          <Feed.Like>
                            <Icon name='like' />
                            41 Likes
                          </Feed.Like>
                        </Feed.Meta>
                      </Feed.Content>
                    </Feed.Event>
                  </Feed>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
        </div>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TwitterCreation)