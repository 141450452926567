import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid, Label } from 'semantic-ui-react'

import numeral from "numeral"

import { connect } from "react-redux"
import { statCampaign } from "../../actions/halo/statisticAction"
import PieRechart from '../chart/PieRechart';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    statCampaign:(objParam) => dispatch(statCampaign(objParam)),
  }
}

class ProgressWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }

  componentDidMount() {
    this.props.statCampaign() 
  }

  componentDidUpdate() {
    const { data, search, paging }= (this.props.statisticStore.statCampaign!==undefined ? this.props.statisticStore.statCampaign : {})
    const pie= [];
    if (data && !this.state.pie) {
      pie.push({ name: 'Target', value: parseInt(data.totTarget || '0') })
      pie.push({ name: 'Attempt', value: parseInt(data.totAttempt || '0') })
      pie.push({ name: 'Delivered', value: parseInt(data.totDelivered || '0') })
      pie.push({ name: 'Engaged', value: parseInt(data.totEngaged || '0') })
      pie.push({ name: 'Achieved', value: parseInt(data.totAchieved || '0') })

      this.setState({
        pie: pie,
      })
    }

    // { name: 'Reject', value: parseInt(item.totReject) },
    // { name: 'Ignored', value: parseInt(item.totIgnored) },
    // { name: 'Invalid', value: parseInt(item.totInvalid) },
    // { name: 'Mailbox', value: parseInt(item.totMailbox) },

    // { name: 'Complete', value: parseInt(item.totComplete) },

    // { name: 'Contacted', value: parseInt(item.totContacted) },
    // { name: 'Nofeedback', value: parseInt(item.totNofeedback) },
    // { name: 'Promised', value: parseInt(item.totPromised) },
    // { name: 'Paid', value: parseInt(item.totPaid) },
    // { name: 'Rescheduled', value: parseInt(item.totRescheduled) },
    // { name: 'Restructured', value: parseInt(item.totRestructured) },
    // { name: 'Verified', value: parseInt(item.totVerified) },
    // { name: 'Interested', value: parseInt(item.totInterested) },
    // { name: 'Ordered', value: parseInt(item.totOrdered) },
    // { name: 'Confirmed', value: parseInt(item.totConfirmed) },

    // { name: 'uqAttempt', value: parseInt(item.uqAttempt) },
    // { name: 'uqDelivered', value: parseInt(item.uqDelivered) },
    // { name: 'uqEngaged', value: parseInt(item.uqEngaged) },
    // { name: 'uqAchieved', value: parseInt(item.uqAchieved) },
    // { name: 'uqReject', value: parseInt(item.uqReject) },
    // { name: 'uqIgnored', value: parseInt(item.uqIgnored) },
    // { name: 'uqInvalid', value: parseInt(item.uqInvalid) },
    // { name: 'uqMailbox', value: parseInt(item.uqMailbox) },

    // { name: 'uqContacted', value: parseInt(item.uqContacted) },
    // { name: 'uqNofeedback', value: parseInt(item.uqNofeedback) },
    // { name: 'uqPromised', value: parseInt(item.uqPromised) },
    // { name: 'uqPaid', value: parseInt(item.uqPaid) },
    // { name: 'uqRescheduled', value: parseInt(item.uqRescheduled) },
    // { name: 'uqRestructured', value: parseInt(item.uqRestructured) },
    // { name: 'uqVerified', value: parseInt(item.uqVerified) },
    // { name: 'uqInterested', value: parseInt(item.uqInterested) },
    // { name: 'uqOrdered', value: parseInt(item.uqOrdered) },
    // { name: 'uqConfirmed', value: parseInt(item.uqConfirmed) },
  }
  
  render() {
    const { screenWidth,  screenHeight, responsive}= this.props
    const { data, search, paging }= (this.props.statisticStore.statCampaign!==undefined ? this.props.statisticStore.statCampaign : {})

    return (<>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart bar' style={{fontSize: '1em'}} />
          <Header.Content>
            Campaign Progress
            <Header.Subheader></Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em'}}>
        <Grid columns={screenWidth>1024 ? 4 : 2} stackable style={{margin: '2em 0'}}>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as='h1'>
              {data && (data.totTarget>1000 ? numeral(data.totTarget).format('0.00a') : numeral(data.totTarget).format('0,0'))}
            </Header>
            <Header as='h5'>
              TARGET
              <Header.Subheader>Target Campaign</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as='h1'>
              {data && (data.totAttempt>1000 ? numeral(data.totAttempt).format('0.00a') : numeral(data.totAttempt).format('0,0'))}
            </Header>
            <Header as='h5'>
              ATTEMPT
              <Header.Subheader>Contact's Attempt</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as='h1'>
              {data && (data.totDelivered>1000 ? numeral(data.totDelivered).format('0.00a') : numeral(data.totDelivered).format('0,0'))}
            </Header>
            <Header as='h5'>
              DELIVERED
              <Header.Subheader>Delivered Content</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column style={{textAlign: 'center'}}>
            <Header as='h1'>
              {data && (data.totEngaged>1000 ? numeral(data.totEngaged).format('0.00a') : numeral(data.totEngaged).format('0,0'))}
            </Header>
            <Header as='h5'>
              CONTACTED
              <Header.Subheader>Interaction Interaction</Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid>

        <Divider />
        <Grid columns={16} stackable>
          <Grid.Column style={{textAlign: 'center'}} width={7}>
            {this.state.pie && screenWidth>800 && 
            <PieRechart data={this.state.pie} legend width={300} height={300} mode='circle' fill='#555'/>}
            {/* <Header as='h5'>
              PERFORMANCE
              <Header.Subheader>Profiling based on personal</Header.Subheader>
            </Header> */}
          </Grid.Column>
          <Grid.Column width={9}>
            <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%'}}>
              <div style={{flex: '1'}}>
                
              </div>
              <div>
                <div style={{marginBottom: '3em'}}>
                  <Header as='h1' color='red' style={{fontWeight: 'bold'}}>
                    <Icon name='calendar alternate outline' style={{fontSize: '1.3em'}}/>
                    <Header.Content>
                      {data && (data.totCampaign>1000 ? numeral(data.totCampaign).format('0.00a') : numeral(data.totCampaign).format('0,0'))}
                      <Header.Subheader style={{fontSize: '.5em'}}>ACTIVE CAMPAIGN</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <Header as='h5'>
                  <Label color='yellow' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{data && (data.totTarget ? Math.ceil(data.totAttempt/data.totTarget * 100) : 0)}%</Label>
                  <Header.Content>
                    ATTEMPTED
                    <Header.Subheader>Attempt of target.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Label color='olive' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{data && (data.totTarget ? Math.ceil(data.totDelivered/data.totTarget * 100) : 0)}%</Label>
                  <Header.Content>
                    DELIVERED
                    <Header.Subheader>Delivery of target.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Label color='green' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{data && (data.totTarget ? Math.ceil(data.totEngaged/data.totTarget * 100) : 0)}%</Label>
                  <Header.Content>
                    CONTACTED
                    <Header.Subheader>Engagement of target.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Label color='blue' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>{data && (data.totTarget ? Math.ceil(data.totAchieved/data.totTarget * 100) : 0)}%</Label>
                  <Header.Content>
                    ACHIEVED
                    <Header.Subheader>Completed of target.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                
              </div>
            </div>
            
          </Grid.Column>
        </Grid>
      </Segment>
    </>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressWidget)
