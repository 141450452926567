import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'

import CommServiceForm from '../../../libraries/realm/middleware/CommServiceForm'


class CommServiceBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 2, //1: browse, 2: edit, 3: insert, 4: delete
        middlewareId: 1,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, middlewareId: id})
    }

    render() {
      const {mode, middlewareId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
              <div style={{margin: '0 0 0 0', padding: '1em 1em', background: 'rgba(240, 240, 240, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
                <Header as='h4' style={{marginTop: '.25em'}}>
                  <Icon name='phone volume' style={{fontSize: '1em'}} />
                  <Header.Content>
                    Comm Service / Sandbox
                  </Header.Content>
                </Header>
              </div>
              <div style={{margin: '1em 1em 1em 1em'}}>
                <CommServiceForm mode={mode} screenWidth={screenWidth} screenHeight={screenHeight} middlewareId={middlewareId} setMode={this.setMode.bind(this)} />
              </div>
        </Container>
      )
    }
}

export default CommServiceBody
