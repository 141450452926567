export default function agentReducer (state = {}, action) {
  switch (action.type) {
    case 'AGENT_RESET': {
        return({
            ...state,
            getAgent: {},
            updateAgent: {},
            createAgent: {},
        })
    }

    case 'AGENT_GET_PENDING': {
        return({...state, getAgent: {progress: 0}})
    }
    case 'AGENT_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getAgent: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getAgent: {...action.payload.data, 
                //data: action.payload.data.data, 
                progress: 1, payload: action.payload}})
        }
    }
    case 'AGENT_GET_REJECTED': {
        return({...state, getAgent: {progress: 2, payload: action.payload}})
    }

    case 'AGENT_STATUS_PENDING': {
      return({...state, statusAgent: {progress: 0}})
    }
    case 'AGENT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusAgent: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusAgent: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AGENT_STATUS_REJECTED': {
        return({...state, statusAgent: {progress: 2, payload: action.payload}})
    }

    case 'AGENT_BROWSE_PENDING': {
      return({...state, browseAgent: {progress: 0}})
    }
    case 'AGENT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseAgent: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseAgent: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AGENT_BROWSE_REJECTED': {
        return({...state, browseAgent: {progress: 2, payload: action.payload}})
    }

    case 'AGENT_CREATE_PENDING': {
      return({...state, browseAgent: {progress: 0}})
    }
    case 'AGENT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createAgent: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createAgent: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AGENT_CREATE_REJECTED': {
        return({...state, createAgent: {progress: 2, payload: action.payload}})
    }

    case 'AGENT_UPDATE_PENDING': {
      return({...state, updateAgent: {progress: 0}})
    }
    case 'AGENT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateAgent: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateAgent: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AGENT_UPDATE_REJECTED': {
        return({...state, updateAgent: {progress: 2, payload: action.payload}})
    }

    case 'AGENT_REMOVE_PENDING': {
      return({...state, removeAgent: {progress: 0}})
    }
    case 'AGENT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeAgent: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeAgent: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AGENT_REMOVE_REJECTED': {
        return({...state, removeAgent: {progress: 2, payload: action.payload}})
    }

    case 'AGENT_REALM_PENDING': {
      return({...state, realmAgent: {progress: 0}})
    }
    case 'AGENT_REALM_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, realmAgent: {progress: 2, payload: action.payload}})
        } else {
            localStorage.setItem('tokenAuth', (action.payload.data.data.tokenAuth==undefined ? '' : action.payload.data.data.tokenAuth))
            return({...state, realmAgent: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'AGENT_REALM_REJECTED': {
        return({...state, realmAgent: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}