import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  ModalDescription,
  Modal,
} from 'semantic-ui-react'


class OmniphoneFooter extends Component {
  constructor(props){
    super(props)
  	this.state = {
    }
  }

  render() {
    const { responsive, layout, phoneState }= this.props
    if (!responsive) 
      return (
        <Segment attached='bottom' style={{padding: '1em .5em', background: '#333', border: 'none', borderTop: '1px solid rgba(200,200,200,.2)'}}>
          <Header as='h5' color='yellow' inverted style={{margin: '0'}}>
            <Header.Content>
              {/* {phoneState.toUpperCase()} */}
              <Header.Subheader style={{color: '#777'}}>Halo Pagi! Omniphone 2.0</Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
      );
    else 
      return(<></>)
  }
}

export default OmniphoneFooter