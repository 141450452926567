export default function trespassingReducer (state = {}, action) {
  switch (action.type) {
    case 'TRESPASSING_RESET': {
        return({
            ...state,
            getTrespassing: {},
            updateTrespassing: {},
            createTrespassing: {},
        })
    }

    case 'TRESPASSING_GET_PENDING': {
        return({...state, getTrespassing: {progress: 0}})
    }
    case 'TRESPASSING_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getTrespassing: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getTrespassing: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TRESPASSING_GET_REJECTED': {
        return({...state, getTrespassing: {progress: 2, payload: action.payload}})
    }

    case 'TRESPASSING_STATUS_PENDING': {
      return({...state, statusTrespassing: {progress: 0}})
    }
    case 'TRESPASSING_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusTrespassing: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusTrespassing: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TRESPASSING_STATUS_REJECTED': {
        return({...state, statusTrespassing: {progress: 2, payload: action.payload}})
    }

    case 'TRESPASSING_BROWSE_PENDING': {
      return({...state, browseTrespassing: {progress: 0}})
    }
    case 'TRESPASSING_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseTrespassing: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseTrespassing: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TRESPASSING_BROWSE_REJECTED': {
        return({...state, browseTrespassing: {progress: 2, payload: action.payload}})
    }

    case 'TRESPASSING_CREATE_PENDING': {
      return({...state, browseTrespassing: {progress: 0}})
    }
    case 'TRESPASSING_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createTrespassing: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createTrespassing: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TRESPASSING_CREATE_REJECTED': {
        return({...state, createTrespassing: {progress: 2, payload: action.payload}})
    }

    case 'TRESPASSING_UPDATE_PENDING': {
      return({...state, updateTrespassing: {progress: 0}})
    }
    case 'TRESPASSING_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateTrespassing: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateTrespassing: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TRESPASSING_UPDATE_REJECTED': {
        return({...state, updateTrespassing: {progress: 2, payload: action.payload}})
    }

    case 'TRESPASSING_REMOVE_PENDING': {
      return({...state, removeTrespassing: {progress: 0}})
    }
    case 'TRESPASSING_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeTrespassing: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeTrespassing: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TRESPASSING_REMOVE_REJECTED': {
        return({...state, removeTrespassing: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}