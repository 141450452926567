import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Label, Header, Image, Segment, Feed, Progress } from 'semantic-ui-react'

import { format, parse } from "date-fns"
import secToFormat from 'sec-to-format';


import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus } from "../../actions/socket/socketAction"

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
  }
}

class SessionWidget extends Component {
  constructor(props){
  	super(props);
    this.state = {};
  }

  render() {
    const { screenWidth,  screenHeight, responsive }= this.props
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data : undefined)
    const { timer }= (agentStatus && agentStatus.payload.data) || {status: 'connect'}

    const setters = {
      '%H': hour => (hour < 10 ? `0${hour}` : hour),
      '%m': min => (min < 10 ? `0${min}` : min),
      '%s': sec => (sec < 10 ? `0${sec}` : sec),
    };
    
    // console.log(agentStatus)

    return (
      <>
      {/* {data!=undefined && data.client==null &&  */}
      {true &&  
        <Segment style={{background: responsive ? '#b8f5f5' : '#eee'}}>
          {screenWidth<1024 && <>
            <div style={{display: 'flex', textAlign: 'center', marginBottom: '1em'}}>
              <div style={{flex: '1', borderRight: '1px solid #aaa'}}>
                <Header as='h4'>
                  <Header.Content>
                    {timer && timer.signinStamp!=null ? format(new Date(timer.signinStamp), 'HH:mm:ss') : '-'}
                    <Header.Subheader>Start <b>Sign In</b>, {timer && timer.signinStamp!=null ? format(new Date(timer.signinStamp), 'dd/MMM') : '-'}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1', borderLeft: '1px solid #fff'}}>
                <Header as='h4'>
                  <Header.Content>
                    {timer && timer.signoutStamp!=null ? format(new Date(timer.signoutStamp), 'HH:mm:ss') : '-'}
                    <Header.Subheader>Last Sign Out, {timer && timer.signoutStamp!=null ? format(new Date(timer.signoutStamp), 'dd/MMM') : '-'}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
            <div style={{display: 'flex', marginTop: '1em'}}>
              <div style={{flex: '1', textAlign: 'center', borderRight: '1px solid #aaa'}}>
                <Header as='h4'>
                  <Header.Content>
                    {secToFormat(timer && timer.secondOnline, '%H:%m:%s', { setters })}
                    <Header.Subheader>Online Duration</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1', textAlign: 'center', borderLeft: '1px solid #fff', borderRight: '1px solid #aaa'}}>
                <Header as='h4'>
                  <Header.Content>
                    {secToFormat(timer && timer.secondOncall, '%H:%m:%s', { setters })}
                    <Header.Subheader>On Call Duration</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1', textAlign: 'center', borderLeft: '1px solid #fff'}}>
                <Header as='h4'>
                  <Header.Content>
                    {secToFormat(timer && timer.secondLocked, '%H:%m:%s', { setters })}
                    <Header.Subheader>Locked Duration</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </>}
          {screenWidth>=1024 && <>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1', borderRight: '1px solid #aaa'}}>
                <Header as='h3'>
                  <Header.Content style={{fontWeight: 'bold', textShadown: '0 1px 1px #fff'}}>
                    <Label color='blue' style={{padding: '.3em .5em', margin: '0 0 .2em 0', borderRadius: '.2em'}}>
                      {timer && timer.signinStamp!=null ? format(new Date(timer.signinStamp), 'HH:mm:ss') : '-'}
                    </Label>
                    <Header.Subheader>Start <b style={{color: '#0463ab'}}>Sign In</b>, {timer && timer.signinStamp!=null ? format(new Date(timer.signinStamp), 'dd/MMM') : '-'}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1', borderLeft: '1px solid #fff', paddingLeft: '1.5em'}}>
                <Header as='h3'>
                  <Header.Content style={{fontWeight: 'bold', textShadown: '0 1px 1px #fff'}}>
                    <Label color='red' style={{padding: '.3em .5em', margin: '0 0 .2em 0', borderRadius: '.2em'}}>
                      {timer && timer.signoutStamp!=null ? format(new Date(timer.signoutStamp), 'HH:mm:ss') : '-'}
                    </Label>
                    <Header.Subheader>Last <b style={{color: '#c31'}}>Sign Out</b>, {timer && timer.signoutStamp!=null ? format(new Date(timer.signoutStamp), 'dd/MMM') : '-'}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1', textAlign: 'center', borderRight: '1px solid #aaa'}}>
                <Header as='h4' color='red'>
                  <Header.Content style={{fontWeight: 'bold', textShadown: '0 1px 1px #fff'}}>
                    {secToFormat(timer && timer.secondOnline, '%H:%m:%s', { setters })}
                    <Header.Subheader>Online Duration</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1', textAlign: 'center', borderLeft: '1px solid #fff', borderRight: '1px solid #aaa'}}>
                <Header as='h4' color='blue'>
                  <Header.Content style={{fontWeight: 'bold', textShadown: '0 1px 1px #fff'}}>
                    {secToFormat(timer && timer.secondOncall, '%H:%m:%s', { setters })}
                    <Header.Subheader>On Call Duration</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1', textAlign: 'center', borderLeft: '1px solid #fff'}}>
                <Header as='h4' color='red'>
                  <Header.Content style={{fontWeight: 'bold', textShadown: '0 1px 1px #fff'}}>
                    {secToFormat(timer && timer.secondLocked, '%H:%m:%s', { setters })}
                    <Header.Subheader>Locked Duration</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </>}
        </Segment>}
      </>
    );
  }
}

SessionWidget.contextType= WebSocketContext;

export default connect(mapStateToProps, mapDispatchToProps)(SessionWidget)