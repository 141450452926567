import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ModalContainer from '../../../libraries/common/ModalContainer';

import APIInventoryBrowse from '../../../libraries/realm/middleware/APIInventoryBrowse'
import APIInventoryForm from '../../../libraries/realm/middleware/APIInventoryForm'
import APIInventorySearch from '../../../libraries/realm/middleware/APIInventorySearch'


class APIInventoryBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        settingId: 0,
      };
    }

    setMode(mode, id, params) {
      this.setState({mode: mode, settingId: id, params: params})
    }

    closeModal() {
      this.setState({
        mode: 1,
        settingId: 0,
        params: {}
      });
      this.props.closeModal() 
    }

    render() {
      const {mode, settingId, params}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div>
            <APIInventorySearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {/* {mode==1 && <APIInventoryBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} />} */}
              {<APIInventoryBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} />}
              {/* {mode==2 && <APIInventoryForm mode={mode} settingId={settingId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <APIInventoryForm mode={mode} settingId={0} setMode={this.setMode.bind(this)} />} */}
              {mode==2 && <ModalContainer size='large' content={<><APIInventorySearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} /><APIInventoryForm data={params} mode={mode} settingId={settingId} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} /></>} closeModal={this.closeModal.bind(this)} /> }
              {mode==3 && <ModalContainer size='large' content={<><APIInventorySearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} /><APIInventoryForm mode={mode} settingId={0} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} /></>} closeModal={this.closeModal.bind(this)} /> }
            </div>
          </div>
        </Container>
      )
    }
}

export default APIInventoryBody
