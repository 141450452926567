import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import CustomerBrowse from '../../libraries/realm/halo/CustomerBrowse'
import CustomerForm from '../../libraries/realm/halo/CustomerForm'
import CustomerSearch from '../../libraries/realm/halo/CustomerSearch'


class CustomerBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        customerId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, customerId: id})
    }

    render() {
      const {mode, customerId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
            <CustomerSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <CustomerBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />}
              {mode==2 && <CustomerForm mode={mode} customerId={customerId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <CustomerForm mode={mode} customerId={0} setMode={this.setMode.bind(this)} />}
            </div>
        </Container>
      )
    }
}

export default CustomerBody
