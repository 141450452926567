import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import { channelOptions }  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, TextAreaField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetAppointment, getAppointment,updateAppointment, createAppointment } from "../../actions/halo/appointmentAction"
import { listTemplate } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    appointmentStore: state.appointmentStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listTemplate:(objParam) => dispatch(listTemplate(objParam)),

    resetAppointment:(objParam) => dispatch(resetAppointment(objParam)),
    getAppointment:(objParam) => dispatch(getAppointment(objParam)),
    createAppointment:(objParam) => dispatch(createAppointment(objParam)),
    updateAppointment:(objParam) => dispatch(updateAppointment(objParam)),
  }
}

class AppointmentForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      showPasswd: false,
    }
  }

  componentDidMount(){
    this.props.resetAppointment(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getAppointment({appointmentId: this.props.appointmentId})
    }

    this.props.listTemplate()
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.appointmentStore.updateAppointment!=undefined ? this.props.appointmentStore.updateAppointment : {}) : (this.props.appointmentStore.createAppointment!=undefined ? this.props.appointmentStore.createAppointment : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateAppointment({...values}) : this.props.createAppointment({...values})
    
  }

  render() {
    const { showPasswd }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.appointmentStore.updateAppointment!=undefined ? this.props.appointmentStore.updateAppointment : {}) : (this.props.appointmentStore.createAppointment!=undefined ? this.props.appointmentStore.createAppointment : {}))

    const listTemplate= (this.props.masterStore.listTemplate!=undefined && this.props.masterStore.listTemplate.progress=='1' ? this.props.masterStore.listTemplate.data : [])
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Header as='h5'>
              <Icon name='file code outline' style={{fontSize: '1em'}} />
              <Header.Content>
                Template
              </Header.Content>
            </Header>
            {/* <Form.Group widths='16'>
              <Form.Field width={8} />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group> */}
            <Form.Group widths='16'>
              <Field name='scriptId' component={SelectField}
              width={8}
              options={listTemplate}
              label= 'Document Template'
              placeholder='Document Template' />
              <Field name='channelType' component={SelectField}
              width={4}
              options={channelOptions()}
              label= 'Channel Media'
              placeholder='Channel Media' />
              <Field name='target' component={InputField}
              readOnly
              width={4}
              label= 'Target'
              placeholder='Target Prospect' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='map marker alternate' style={{fontSize: '1em'}} />
              <Header.Content>
                Event / Location
              </Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='topic' component={InputField}
              width={8}
              label= 'Topic'
              placeholder='Topic' />
              <Field name='startEvent' component={InputField}
              width={4}
              label= 'Event Date'
              placeholder='Event Date' />
              <Field name='session' component={InputField}
              width={4}
              label= 'Event Session'
              placeholder='Event Session' />
              {/* <Field name='startTime' component={InputField}
              width={4}
              label= 'Event Time'
              placeholder='Event Time' /> */}
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='venue' component={InputField}
              label= 'Venue'
              placeholder='Venue' />
            </Form.Group>
          </Segment>
          <Segment attached='top'>
            <Header as='h5'>
              <Icon name='user circle' style={{fontSize: '1em'}} />
              <Header.Content>
                Invitee / Discussion
              </Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='participants' component={InputField}
              label= 'Participants'
              placeholder='Participants' />
            </Form.Group>
          </Segment>
          <Segment attached='bottom'>
            <Form.Group widths='equal'>
              <Field name='content' component={TextAreaField}
              rows={10}
              label= 'Content Discussion'
              placeholder='Discussion Content' />
            </Form.Group>
          </Segment>
          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Appointment' : 'Insert Appointment') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.appointmentStore.getAppointment!=undefined && state.appointmentStore.getAppointment.progress==1 ? state.appointmentStore.getAppointment.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: getAppointment }
  )
  (reduxForm({
    form: 'AppointmentForm',	// a unique identifier for this form
  })(AppointmentForm)
  )
)
