import React, { Component } from 'react'
import { Divider, Segment, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Label, Icon, Table } from 'semantic-ui-react'

import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { browseMessage } from "../../actions/halo/messageAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    messageStore: state.messageStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseMessage:(objParam) => dispatch(browseMessage(objParam)),
  }
}

class MessageBrowse extends Component {

  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount() {
    const { search, paging }= (this.props.messageStore.browseMessage!==undefined ? this.props.messageStore.browseMessage : {})
    this.props.browseMessage({search: {...search, stamp: null}, paging: paging})
  }
  
  render() {
    const { progress, data }= (this.props.messageStore.browseMessage!=undefined ? this.props.messageStore.browseMessage : {})
    let seq= 0

    return (
      <div style={{background: '#fff', fontSize: '1.25em', borderRadius: '.5em', border: '1px solid rgba(100,100,100,.2)', minHeight: '100%', padding: '1em', maxWidth: '100%'}}>
        <Table basic='very' style={{border: 'none'}}>
          <Table.Body>
            {data!=undefined  ?  data.map((item,key)=>{
                return(
                  <Table.Row key={++seq}>
                    <Table.Cell verticalAlign='top'>
                      <Icon name='bell outline' style={{fontSize: '1.5em', marginTop: '.25em', color: '#777'}}/>
                    </Table.Cell>
                    <Table.Cell width='16'>
                      <Header as='h5' style={{margin: '0'}}>
                        <Header.Content>
                          <Header.Subheader><Label color='teal' style={{margin: '0 0', padding: '.5em .3em', fontSize: '.9em'}}>{format(parse(item.stamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'hh:mm - dd/MMM yyyy')}</Label></Header.Subheader>
                        </Header.Content>
                      </Header>
                      <Header as='h5' style={{margin: '.5em 0'}}>
                        <Header.Content>
                          <Header.Subheader>{item.title}</Header.Subheader>
                          {item.content}
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                  </Table.Row>
                )
              }) : <Table.Row><Table.Cell><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
            }
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBrowse)