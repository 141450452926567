import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label, Popup, Pagination } from 'semantic-ui-react'

import { connect } from "react-redux"
import { browseAssociate, resetAssociate } from "../../../actions/socmed/associateAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    associateStore: state.socmed_associateStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetAssociate:(objParam) => dispatch(resetAssociate(objParam)),
    browseAssociate:(objParam) => dispatch(browseAssociate(objParam)),
  }
}

class TwitterNetwork extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 0,
    };
  }

  componentDidMount(){
    this.props.resetAssociate()
    this.props.browseAssociate({associateType: 'Twitter'})
  }

  setMode(mode, id) {
    this.setState({mode: mode, coachId: id})
  }
  
  render() {
    const {mode}= this.state
    const {screenWidth, screenHeight}= this.props
    const { data, search, paging }= (this.props.associateStore.browseAssociate!==undefined ? this.props.associateStore.browseAssociate : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return(
      <div>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Header as='h5'>
                <Header.Content>
                  Twitter <b>Follower</b>
                  <Header.Subheader>Associate in your networks.</Header.Subheader>
                </Header.Content>
              </Header>
              <Segment style={{minHeight: screenHeight}}>
                <Card.Group itemsPerRow={1}>
                  { data!==undefined ? data.map((item,i)=>{
                      if (i<20) {
                        return(<Card as='a'>
                          <Card.Content style={{padding: '.5em'}}>
                            <Header as='h5'>
                              <Image circular src={item.avatar} />
                              <Header.Content>
                                {item.name}
                                <Header.Subheader>{item.descript}</Header.Subheader>
                              </Header.Content>
                            </Header>
                          </Card.Content>
                        </Card>)
                      }
                    }): <div style={{padding: '.5em 0'}}><Icon name='circle notch' loading />loading ...</div>
                  }
                </Card.Group>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Header as='h5'>
                <Header.Content>
                  Twitter <b>Follower</b>
                  <Header.Subheader>Associate in your networks.</Header.Subheader>
                </Header.Content>
              </Header>
              <Segment style={{minHeight: screenHeight}}>
                <Card.Group itemsPerRow={1}>
                  { data && data.map((item,i)=>{
                      if (i>=20 && i<40) {
                        return(<Card as='a'>
                          <Card.Content style={{padding: '.5em'}}>
                            <Header as='h5'>
                              <Image circular src={item.avatar} />
                              <Header.Content>
                                {item.name}
                                <Header.Subheader>{item.descript}</Header.Subheader>
                              </Header.Content>
                            </Header>
                          </Card.Content>
                        </Card>)
                      }
                    })
                  }
                </Card.Group>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Header as='h5'>
                <Header.Content>
                  Twitter <b>Follower</b>
                  <Header.Subheader>Associate in your networks.</Header.Subheader>
                </Header.Content>
              </Header>
              <Segment style={{minHeight: screenHeight}}>
                <Card.Group itemsPerRow={1}>
                  { data && data.map((item,i)=>{
                      if (i>=40 && i<60) {
                        return(<Card as='a'>
                          <Card.Content style={{padding: '.5em'}}>
                            <Header as='h5'>
                              <Image circular src={item.avatar} />
                              <Header.Content>
                                {item.name}
                                <Header.Subheader>{item.descript}</Header.Subheader>
                              </Header.Content>
                            </Header>
                          </Card.Content>
                        </Card>)
                      }
                    })
                  }
                </Card.Group>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
        <b>https://developer.twitter.com/en/docs/twitter-api/v1/associates-and-users/follow-search-get-users/api-reference/get-followers-list</b>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TwitterNetwork)