import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import MenuHorizontal from '../../libraries/common/MenuHorizontal';

import TelephonyBody from './TelephonyBody'
import MessagingBody from './MessagingBody'
import SocialBody from './SocialBody'

const options=(props)=> {
  const menu= [];

  if (props && (props.isVoice=='1' || props.isIVR=='1') && (props.isSMS=='1' || props.isEmail=='1' || props.isWhatsapp=='1' || props.isChat=='1') && (props.isTwitter=='1' || props.isFacebook=='1' || props.isInstagram=='1')) {
    menu.push({
      key: '1',
      icon: 'call',
      caption: 'Telephony',
      hint: {
        header: 'Voice Call',
        content: 'All your call transaction which available filtered by certain period.',
      }
    })
  }
  if (props && (props.isSMS=='1' || props.isEmail=='1' || props.isWhatsapp=='1' || props.isChat=='1')) {
    menu.push({
      key: '2',
      disabled: true,
      icon: 'comment alternate outline',
      caption: 'Messaging',
      hint: {
        header: 'Messaging Delivery',
        content: 'All messaging transaction is recorded and available for filtered view based on certain period.',
      }
    })
  }
  if (props && (props.isTwitter=='1' || props.isFacebook=='1' || props.isInstagram=='1')) {
    menu.push({
      key: '3',
      icon: 'share alternate',
      caption: 'Social Media',
      hint: {
        header: 'Social Pipeline',
        content: 'Interaction through social media is available for monitoring and filtered by certain date.',
      }
    })
  }

  return(menu);
  // return(
  //   [
  //     {
  //       key: '1',
  //       icon: 'call',
  //       caption: 'Telephony',
  //       hint: {
  //         header: 'Voice Call',
  //         content: 'All your call transaction which available filtered by certain period.',
  //       }
  //     },
  //     {
  //       key: '2',
  //       icon: 'comment alternate outline',
  //       caption: 'Messaging',
  //       hint: {
  //         header: 'Messaging Delivery',
  //         content: 'All messaging transaction is recorded and available for filtered view based on certain period.',
  //       }
  //     },
  //     {
  //       key: '3',
  //       icon: 'share alternate',
  //       caption: 'Social Media',
  //       hint: {
  //         header: 'Social Pipeline',
  //         content: 'Interaction through social media is available for monitoring and filtered by certain date.',
  //       }
  //     },
  //   ]
  // );
}

class ConversationBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        // dashboardId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props

      return(
        <Container fluid>
          <div style={{margin: '0em 1em 0 1em'}}>
            <MenuHorizontal onClick={this.setMode.bind(this)} options= {options(this.props.checkToken)} color='red'/>
          </div>
          <div>
            {mode==1 && <TelephonyBody openWindow={this.props.openWindow.bind(this)} screenHeight={screenHeight} screenWidth={screenWidth} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
            {mode==2 && <MessagingBody openWindow={this.props.openWindow.bind(this)} screenHeight={screenHeight} screenWidth={screenWidth} />}
            {mode==3 && <SocialBody openWindow={this.props.openWindow.bind(this)} screenHeight={screenHeight} screenWidth={screenWidth} />}
          </div>
        </Container>
      )
    }
}

export default ConversationBody
