import React, { Component } from 'react'

class AgentFooter extends Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }
  
  render() {
    return (
      <div>
        
      </div>
    )
  }
}

export default AgentFooter