import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Pagination,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import { connect } from "react-redux"
import { browseTarget, statusTarget } from "../../actions/stream/targetAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    targetStore: state.targetStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTarget:(objParam) => dispatch(browseTarget(objParam)),
    statusTarget:(objParam) => dispatch(statusTarget(objParam)),
  }
};


class TargetBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    this.props.browseTarget(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    this.props.browseTarget({search: search, paging: paging})
  }

  onChangeStatus(id, status) {
    this.props.statusTarget({targetId:id, isEnabled: status})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1'}}>
        <Card.Group itemsPerRow={1}>
            { 
              data && data.map((item,key)=>{
                return(
                  <Card fluid>
                    <Card.Content style={{padding: '1em .7em'}}>
                      <div style={{display: 'flex'}}>
                        <div>
                          <Label color='teal' style={{textAlign:'center', padding: '.7em .5em', margin: '0 .5em 0 0', width: '2.5em'}}>{++seq}.</Label>
                          <Divider hidden style={{margin: '1.3em 0'}}/>
                          <Icon name={item.isEnabled ? 'toggle on' : 'toggle off'} color={item.isEnabled ? 'teal' : 'grey'} style={{fontSize: '1.5em'}}/>
                        </div>
                        <div style={{flex: '1'}}>
                          <Header as='h4' style={{margin: '0'}} color='blue'>
                            <Header.Content>
                              {item.name}
                              {item.campaign!=null && <Header.Subheader>{item.campaign.name} ({item.campaign.isEnabled=='1'? <i>Enabled</i> : <i>Disabled</i>})</Header.Subheader>}
                            </Header.Content>
                          </Header>
                          <Divider />
                          {/* <Header as='h5' style={{margin: '0 0 .5em 0'}}>
                            <Header.Content>
                              {item.mobile || item.homePhone || item.officePhone || 'No phone'} 
                              <Header.Subheader>
                                <span style={{marginRight: '2em'}}>{item.whatsapp||item.telegram||item.email||item.mobile ? <><Icon name='check' />Messaging</> : <><Icon name='close' />Messaging</>}</span>
                                <span style={{marginRight: '2em'}}>{item.twitter||item.instagram||item.facebook ? <><Icon name='check' />Socmed</> : <><Icon name='close' />Socmed</>}</span></Header.Subheader>
                            </Header.Content>
                          </Header> */}
                          <Header as='h5' style={{margin: '0'}} color='red'>
                            <Header.Content>
                              {item.gender} / {item.occupation || 'Job unidentified'}
                              <Header.Subheader>Posted by {item.client || '-'}</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </div>
                      </div>
                    </Card.Content>
                  </Card>
                )
              })
            }
          </Card.Group>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination size='mini' onPageChange={this.doPaging.bind(this)}
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetBrowse)
