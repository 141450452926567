import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetVoice(objParam, path) {
  return {
      type: 'VOICE_RESET',
      payload: objParam
  }
}

export function getVoice(objParam, path) {
  return {
      type: 'VOICE_GET',
      payload:
        axios.post(
          (path ? urlAPI + path : urlAPI) + 'voice/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusVoice(objParam, path) {
  return {
      type: 'VOICE_STATUS',
      payload:
        axios.post(
          (path ? urlAPI + path : urlAPI) +'voice/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseVoice(objParam, path) {
  return {
      type: 'VOICE_BROWSE',
      payload:
        axios.post(
          (path ? urlAPI + path : urlAPI) +'voice/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createVoice(objParam, path) {
  return {
      type: 'VOICE_CREATE',
      payload:
        axios.post(
          (path ? urlAPI + path : urlAPI) +'voice/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateVoice(objParam, path) {
  return {
      type: 'VOICE_UPDATE',
      payload:
        axios.post(
          (path ? urlAPI + path : urlAPI) +'voice/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeVoice(objParam, path) {
  return {
      type: 'VOICE_REMOVE',
      payload:
        axios.post(
          (path ? urlAPI + path : urlAPI) +'voice/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
