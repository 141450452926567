import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider  } from 'semantic-ui-react'

import ModalContainer from '../../common/ModalContainer';
import MenuHorizontal from '../../common/MenuHorizontal';

import MonitoringSpy from '../../realm/halo/MonitoringSpy';

import MonitoringWidget from '../../widget/MonitoringWidget'
import OccupancyWidget from '../../widget/OccupancyWidget'
import HeartbeatWidget from '../../widget/HeartbeatWidget'
import EngagementRateWidget from '../../widget/EngagementRateWidget'

class MonitoringRealtime extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        userId: 0,

        spyModal: false,
        spyItem: null,
      };
    }

    showSpy(item) {
      this.setState({
        spyModal: true,
        spyItem: item,
      })
    }

    closeModal() {
      this.setState({
        spyModal: false,
        spyItem: null,
      })
      // this.props.closeModal();
    }

    render() {
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div>
          <div style={{padding: '.5em 1em'}}>
            <MonitoringWidget mode='realm' screenWidth={screenWidth} showSpy={this.showSpy.bind(this)}/>
          </div>
          {/* <div style={{padding: '1em'}}>
            <OccupancyWidget />
          </div>
          <div style={{padding: '1em'}}>
            <HeartbeatWidget />
          </div> */}
          <div style={{padding: '.5em 1em'}}>
            <EngagementRateWidget screenWidth={screenWidth} screenHeight={screenHeight} />
          </div>

          {this.state.spyModal && this.state.spyItem && <ModalContainer size='mini' content={
            <MonitoringSpy oncall={true || this.state.spyItem && this.state.spyItem.occupied=='1'} data={this.state.spyItem} state={this.state} onWhisper={()=>{}}  onSpying={()=>{}}/>
          } closeModal={this.closeModal.bind(this)} /> }
        </div>
      )
    }
}

export default MonitoringRealtime
