import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import EngagementBrowse from '../../../libraries/realm/halo/EngagementBrowse'

class EngagementBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        // dashboardId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props

      return(
        <Container fluid>
          <EngagementBrowse params={this.props.params}/>
        </Container>
      )
    }
}

export default EngagementBody
