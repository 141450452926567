import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetApilog(objParam) {
  return {
      type: 'APILOG_RESET',
      payload: objParam
  }
}

export function getApilog(objParam) {
  return {
      type: 'APILOG_GET',
      payload:
        axios.post(
          urlAPI + 'system/apilog/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusApilog(objParam) {
  return {
      type: 'APILOG_STATUS',
      payload:
        axios.post(
          urlAPI + 'system/apilog/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseApilog(objParam) {
  return {
      type: 'APILOG_BROWSE',
      payload:
        axios.post(
          urlAPI + 'system/apilog/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createApilog(objParam) {
  return {
      type: 'APILOG_CREATE',
      payload:
        axios.post(
          urlAPI + 'system/apilog/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateApilog(objParam) {
  return {
      type: 'APILOG_UPDATE',
      payload:
        axios.post(
          urlAPI + 'system/apilog/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeApilog(objParam) {
  return {
      type: 'APILOG_REMOVE',
      payload:
        axios.post(
          urlAPI + 'system/apilog/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
