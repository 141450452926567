export default function authReducer (state = {}, action) {
  switch (action.type) {
    case 'TOKEN_CHECK_PENDING': {
        return({...state, checkToken: {progress: 0}})
    }
    case 'TOKEN_CHECK_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, checkToken: {progress: 2, payload: action.payload}})
        } else {
            return({...state, checkToken: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TOKEN_CHECK_REJECTED': {
        return({...state, checkToken: {progress: 2, payload: action.payload}})
    }
    

    case 'ONBOARDING_SET_PENDING': {
        return({...state, setOnboarding: {progress: 0}})
    }
    case 'ONBOARDING_SET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, setOnboarding: {progress: 2, payload: action.payload}})
        } else {
            return({...state, setOnboarding: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ONBOARDING_SET_REJECTED': {
        return({...state, setOnboarding: {progress: 2, payload: action.payload}})
    }

    case 'TOKEN_GET_PENDING': {
        return({...state, tokenGet: {progress: 0}})
    }
    case 'TOKEN_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, tokenGet: {progress: 2, payload: action.payload}})
        } else {
            return({...state, tokenGet: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TOKEN_GET_REJECTED': {
        return({...state, tokenGet: {progress: 2, payload: action.payload}})
    }

    case 'SIGNIN_PENDING': {
        return({...state, signIn: {progress: 0}})
    }
    case 'SIGNIN_FULFILLED': {
        if (action.payload==undefined) {
            //localStorage.setItem('tokenAuth', (api.signIn.response.data.tokenAuth==undefined ? '' : api.signIn.response.data.tokenAuth))
            return({signIn: {progress: 2, payload: action.payload}})
        } else {
            //console.log('TOKEN AUTH RESP: ', action.payload.data.data.tokenAuth)
            localStorage.setItem('tokenAuth', (action.payload.data.data.tokenAuth==undefined ? '' : action.payload.data.data.tokenAuth))
            return({signIn: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SIGNIN_REJECTED': {
        return({...state, signIn: {progress: 2, payload: action.payload}})
    }

    case 'SIGNUP_PENDING': {
      return({...state, signUp: {progress: 0}})
    }
    case 'SIGNUP_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, signUp: {progress: 2, payload: action.payload}})
        } else {
            return({...state, signUp: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SIGNUP_REJECTED': {
        return({...state, signUp: {progress: 2, payload: action.payload}})
    }

    case 'SIGNOUT_PENDING': {
        return({signOut: {progress: 0}})
    }
    case 'SIGNOUT_FULFILLED': {
        setTimeout(()=>{
            localStorage.setItem('tokenAuth', '');
            window.location.reload();
        }, 1000)
        if (action.payload==undefined) {
            return({signOut: {progress: 2, payload: action.payload}})
        } else {
            return({signOut: {}})
        }
    }
    case 'SIGNOUT_REJECTED': {
        localStorage.setItem('tokenAuth', '');
        return({signIn: {progress: 2, payload: action.payload}})
    }

    //PASSWORD
    case 'PASSWD_RST_PENDING': {
      return({...state, resetPasswd: {progress: 0}})
    }
    case 'PASSWD_RST_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, resetPasswd: {progress: 2, payload: action.payload}})
        } else {
            return({...state, resetPasswd: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PASSWD_RST_REJECTED': {
        return({...state, resetPasswd: {progress: 2, payload: action.payload}})
    }
    
    case 'PASSWD_UPD_PENDING': {
      return({...state, profile: {progress: 0}})
    }
    case 'PASSWD_UPD_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, uppaddepwd: {progress: 2, payload: action.payload}})
        } else {
            return({...state, uppaddepwd: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PASSWD_UPD_REJECTED': {
        return({...state, uppaddepwd: {progress: 2, payload: action.payload}})
    }

    case 'PROFILE_GET_PENDING': {
      return({...state, getProfile: {progress: 0}})
    }
    case 'PROFILE_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getProfile: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getProfile: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PROFILE_GET_REJECTED': {
        return({...state, getProfile: {progress: 2, payload: action.payload}})
    }

    case 'PROFILE_UPD_PENDING': {
      return({...state, updateProfile: {progress: 0}})
    }
    case 'PROFILE_UPD_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateProfile: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateProfile: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'PROFILE_UPD_REJECTED': {
        return({...state, updateProfile: {progress: 2, payload: action.payload}})
    }

    case 'BILLING_INFO_PENDING': {
      return({...state, billingInfo: {progress: 0}})
    }
    case 'BILLING_INFO_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, billingInfo: {progress: 2, payload: action.payload}})
        } else {
            return({...state, billingInfo: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'BILLING_INFO_REJECTED': {
        return({...state, billingInfo: {progress: 2, payload: action.payload}})
    }

    //NOTIFICATION
    case 'NOTIFICATION_BROWSE_PENDING': {
        return({...state, browseNotification: {progress: 0}})
    }
    case 'NOTIFICATION_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseNotification: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseNotification: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'NOTIFICATION_BROWSE_REJECTED': {
        return({...state, browseNotification: {progress: 2, payload: action.payload}})
    }

    // //ACTIVITY  USER 
    // case 'ACTIVITY_BROWSE_PENDING': {
    //     return({...state, browseActivity: {progress: 0}})
    // }
    // case 'ACTIVITY_BROWSE_FULFILLED': {
    //     if (action.payload==undefined) {
    //         return({...state, browseActivity: {progress: 2, payload: action.payload}})
    //     } else {
    //         return({...state, browseActivity: {...action.payload.data, progress: 1, payload: action.payload}})
    //     }
    // }
    // case 'ACTIVITY_BROWSE_REJECTED': {
    //     return({...state, browseActivity: {progress: 2, payload: action.payload}})
    // }

    //RESET SIGN IN/UP FORM
    case 'RESET': {
        if (action.payload=='signIn') return({...state, signIn: undefined})
        if (action.payload=='resetPasswd') return({...state, resetPasswd: undefined})
        if (action.payload=='signUp') return({...state, signUp: undefined})
        if (action.payload=='setOnboarding') return({...state, setOnboarding: undefined})
        if (action.payload=='updateProfile') return({...state, updateProfile: undefined})
        if (action.payload=='echoWhatsapp') return({...state, echoWhatsapp: undefined})
        break;
    }
    
    case 'ECHO_WHATSAPP_PENDING': {
      return({...state, echoWhatsapp: {progress: 0}})
    }
    case 'ECHO_WHATSAPP_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, echoWhatsapp: {progress: 2, payload: action.payload}})
        } else {
            return({...state, echoWhatsapp: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'ECHO_WHATSAPP_REJECTED': {
        return({...state, echoWhatsapp: {progress: 2, payload: action.payload}})
    }

    default: {
        return state
    }

  }
  
}