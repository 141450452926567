import axios from "axios"
import { urlAdmin } from "../_api"

//TOKEN AUTH
export function checkToken(objParam) {
  return {
      type: 'TOKEN_CHECK',
      payload:
        axios.post(
          urlAdmin + 'auth/checktoken',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function setOnboarding(objParam) {
  return {
      type: 'ONBOARDING_SET',
      payload:
        axios.post(
          urlAdmin + 'auth/setonboarding',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function getToken(objParam) {
  return {
      type: 'TOKEN_GET',
      payload:
        axios.post(
          urlAdmin + 'auth/gettoken',
          JSON.stringify(objParam),
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

//SIGN IN/OUT/UP
export function signIn(objParam) {
  return {
      type: 'SIGNIN',
      payload:
        axios.post(
          urlAdmin + 'auth/signin',
          JSON.stringify(objParam),
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function signUp(objParam) {
  return {
      type: 'SIGNUP',
      payload:
        axios.post(
          urlAdmin + 'auth/signup',
          JSON.stringify(objParam),
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function signOut(objParam) {
  return {
      type: 'SIGNOUT',
      payload:
        axios.post(
          urlAdmin + 'auth/signout',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function resetPasswd(objParam) {
  return {
      type: 'PASSWD_RST', 
      payload:
        axios.post(
          urlAdmin + 'auth/resetpasswd',
          JSON.stringify(objParam),
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updatePassword(objParam) {
  return {
      type: 'PASSWD_UPD',
      payload:
        axios.post(
          urlAdmin + 'auth/updatepasswd',
          JSON.stringify(objParam),
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}


//ACCOUNT
export function getProfile(objParam) {
  return {
      type: 'PROFILE_GET',
      payload:
        axios.post(
          urlAdmin + 'auth/getprofile',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateProfile(objParam) {
  return {
      type: 'PROFILE_UPD',
      payload:
        axios.post(
          urlAdmin + 'auth/updateprofile',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function billingInfo(objParam) {
  return {
      type: 'BILLING_INFO',
      payload:
        axios.post(
          urlAdmin + 'auth/billinginfo',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseNotification(objParam) {
  return {
      type: 'NOTIFICATION_BROWSE',
      payload:
        axios.post(
          urlAdmin + 'auth/browsenotification',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

// export function browseActivity(objParam) {
//   return {
//       type: 'ACTIVITY_BROWSE',
//       payload:
//         axios.post(
//           urlAdmin + 'auth/browseactivity',
//           JSON.stringify(objParam),
//           {
//             headers: {
//               "Authorization": localStorage.getItem('tokenAuth'),
//               "Content-Type": "application/json",
//               "Accept": "*/*",
//             }
//           }
//       ).catch(function(error){ //handle network error, return axios always 1
//         // console.log('ERROR: ', error)
//       })
//   }
// }

export function echoWhatsapp(objParam) {
  return {
      type: 'ECHO_WHATSAPP',
      payload:
        axios.post(
          urlAdmin + 'auth/echowhatsapp',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

//FORM SIGN IN / UP / RESET
export function resetForm(objParam) {
  return {
    type: 'RESET',
    payload: objParam
  }
}