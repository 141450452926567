import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse, addSeconds } from "date-fns"



import { connect } from "react-redux"
import { browseTicket, statusTicket } from "../../../../actions/service/ticketAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    ticketStore: state.ticketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTicket:(objParam) => dispatch(browseTicket(objParam)),
    statusTicket:(objParam) => dispatch(statusTicket(objParam)),
  }
}

class InteractionBox extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.ticketStore.browseTicket!==undefined ? this.props.ticketStore.browseTicket : {})
    this.props.browseTicket(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.ticketStore.browseTicket!==undefined ? this.props.ticketStore.browseTicket : {})
    this.props.browseTicket({search: {...search}, paging: paging})
  }

  render() {
    //console.log(this.props.ticketStore.browseTicket)
    const { screenWidth, screenHeight, style }= this.props
    const { data, search, paging }= (this.props.ticketStore.browseTicket!==undefined ? this.props.ticketStore.browseTicket : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', flexDirection: 'column', height: '100%', ...style}}>
        <div style={{flex: '1', padding: '0'}}>
          <Header as='h5'>
            <Header.Content>
              Interaction Timelines
              <Header.Subheader>Ticket interaction and conversation timelines.</Header.Subheader>
            </Header.Content>
          </Header>
          <Segment style={{height: '100%'}}>
          </Segment>  
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InteractionBox)
