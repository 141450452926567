import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import { voiceOptions, channelOptions, resultOptions }  from '../common/StaticMasterData'
import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { browseVoice } from "../../actions/stream/voiceAction"
import { collectionStage, restructuringStage, telesaleStage, verificationStage } from "../../actions/halo/masterAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    voiceStore: state.voiceStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseVoice:(objParam) => dispatch(browseVoice(objParam)),

    // collectionStage:(objParam) => dispatch(collectionStage(objParam)),
    // restructuringStage:(objParam) => dispatch(restructuringStage(objParam)),
    // telesaleStage:(objParam) => dispatch(telesaleStage(objParam)),
    // verificationStage:(objParam) => dispatch(verificationStage(objParam)),
  }
}


class VoiceSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
    }
  }

  componentDidMount() {
    // this.props.collectionStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    // this.props.restructuringStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    // this.props.telesaleStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
    // this.props.verificationStage({clientId: this.props.clientId!=undefined ? this.props.clientId : null})
  }

  submitForm(values) {
    const { search }= this.state
    this.props.browseVoice({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    const { search }= (this.props.voiceStore.browseVoice!==undefined ? this.props.voiceStore.browseVoice : {})
    var values= []
    values[key]= v
    this.props.browseVoice({search: {...search, ...values}})
  }

  onChangeInput(e, v) {
    const { search }= (this.props.voiceStore.browseVoice!==undefined ? this.props.voiceStore.browseVoice : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.browseVoice({search: {...search, keyword: v}})
      }
    }, 500)
  }

  onChangeSelect(k, e, v) {
    const { search }= (this.props.voiceStore.browseVoice!==undefined ? this.props.voiceStore.browseVoice : {})
    if (k=='status') {
      this.props.browseVoice({search: {...search, status: (v=='' ? null : v)}})
    } else if (k=='channelType') {
      this.props.browseVoice({search: {...search, channelType: (v=='' ? null : v)}})
    } else if (k=='remark') {
      this.props.browseVoice({search: {...search, remark: (v=='' ? null : v)}})
    }
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props
    
    // const collectionStage= (this.props.masterStore.collectionStage!=undefined && this.props.masterStore.collectionStage.progress=='1' ? this.props.masterStore.collectionStage.data : [])
    // const restructuringStage= (this.props.masterStore.restructuringStage!=undefined && this.props.masterStore.restructuringStage.progress=='1' ? this.props.masterStore.restructuringStage.data : [])
    // const telesaleStage= (this.props.masterStore.telesaleStage!=undefined && this.props.masterStore.telesaleStage.progress=='1' ? this.props.masterStore.telesaleStage.data : [])
    // const verificationStage= (this.props.masterStore.verificationStage!=undefined && this.props.masterStore.verificationStage.progress=='1' ? this.props.masterStore.verificationStage.data : [])
    
    // const remarkMap= new Map();
    // const remarkOptions = []
    // collectionStage && collectionStage.map(item=>{
    //   remarkMap.set(item.value)
    // })
    // restructuringStage && restructuringStage.map(item=>{
    //   remarkMap.set(item.value)
    // })
    // telesaleStage && telesaleStage.map(item=>{
    //   remarkMap.set(item.value)
    // })
    // verificationStage && verificationStage.map(item=>{
    //   remarkMap.set(item.value)
    // })

    // remarkMap && [...remarkMap.keys()].map((item, i)=>{
    //   remarkOptions.push(
    //     { key: i, value: item, text: item }
    //   )
    // })
    
    return (
      <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
        <div style={{padding: '1em 1em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{marginTop: '.25em'}}>
                <Icon name='phone' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Call / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
                </Header.Content>
              </Header>
            </div>
            <div>
              {mode !=1 &&
              <Button size='mini' color='blue' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='arrow left' /></Button>}
              {mode !=2 && mode !=3 &&
              <Button size='mini' color='red' onClick={this.props.setMode.bind(this,3)} icon style={{padding: '.5em'}}><Icon name='plus' /></Button>}
              {mode ==1 &&
              <Button size='mini' color='grey' onClick={this.showSearch.bind(this)} icon style={{padding: '.5em'}}><Icon name={searchMode==true? 'angle up' : 'angle down'} /></Button>}
            </div>
          </div>

          {mode==1 && searchMode && 
          <div style={{margin: '1em 0 0 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='16'>
                    <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                    width={4} 
                    placeholder= 'Search call ... <ENTER>' />
                    <Field name='remark' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'remark')}
                    // options={remarkOptions}
                    options={resultOptions('all')}
                    width={3} 
                    placeholder= 'Cust Remark' />
                    <Field name='channelType' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'channelType')}
                    options={channelOptions('voice')}
                    width={3} 
                    placeholder= 'Channel Type' />
                    <Field name='status' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'status')}
                    options={voiceOptions()}
                    width={3} 
                    placeholder= 'Call Status' />
                    <Field name='transactDate' component={DatePickerField} onChange= {this.onChangeDate.bind(this, 'transactDate')}
                    width={3} 
                    type='date'
                    placeholder= 'Trx. Date' />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.voiceStore.browseVoice!=undefined && state.voiceStore.browseVoice.progress==1 ? state.voiceStore.browseVoice.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'VoiceSearch',	// a unique identifier for this form
  })(VoiceSearch)
  )
)