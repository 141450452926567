import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetRecord(objParam) {
  return {
      type: 'RECORD_RESET',
      payload: objParam
  }
}

export function getRecord(objParam) {
  return {
      type: 'RECORD_GET',
      payload:
        axios.post(
          urlAPI + 'record/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusRecord(objParam) {
  return {
      type: 'RECORD_STATUS',
      payload:
        axios.post(
          urlAPI + 'record/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseRecord(objParam) {
  return {
      type: 'RECORD_BROWSE',
      payload:
        axios.post(
          urlAPI + 'record/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createRecord(objParam) {
  return {
      type: 'RECORD_CREATE',
      payload:
        axios.post(
          urlAPI + 'record/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateRecord(objParam) {
  return {
      type: 'RECORD_UPDATE',
      payload:
        axios.post(
          urlAPI + 'record/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeRecord(objParam) {
  return {
      type: 'RECORD_REMOVE',
      payload:
        axios.post(
          urlAPI + 'record/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
