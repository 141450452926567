import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import DismissableMessage from '../../common/DismissableMessage'
import { assignmenOptions }  from '../../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import IconToggle from '../../../libraries/common/IconToggle'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, DropdownField, NumberField } from '../../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../../validation/validation'
import { listCampaign, listWorkgroup, summaryCampaign } from "../../../actions/halo/masterAction"

import { connect } from "react-redux"
import { resetAssign, getAssign,updateAssign, createAssign, agentAssign, workgroupAssign } from "../../../actions/stream/assignAction"

//CONNECT REDUX STORE
const selector = formValueSelector('AssignForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    assignStore: state.assignStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    summaryCampaign:(objParam) => dispatch(summaryCampaign(objParam)),
    listCampaign:(objParam) => dispatch(listCampaign(objParam)),
    listWorkgroup:(objParam) => dispatch(listWorkgroup(objParam)),

    resetAssign:(objParam) => dispatch(resetAssign(objParam)),
    getAssign:(objParam) => dispatch(getAssign(objParam)),
    createAssign:(objParam) => dispatch(createAssign(objParam)),
    updateAssign:(objParam) => dispatch(updateAssign(objParam)),

    agentAssign:(objParam) => dispatch(agentAssign(objParam)),
    workgroupAssign:(objParam) => dispatch(workgroupAssign(objParam)),
  }
}

class AssignForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      workgroupAssigned: -1,
      agentAssigned: -1,
      summarized: -1,
    }
  }

  componentDidMount(){
    this.props.resetAssign(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getAssign({assignId: this.props.assignId})
      this.props.summaryCampaign({assignId: this.props.assignId, campaignId: this.props.refcampaignId})
    }

    this.props.listCampaign()
    this.props.listWorkgroup()
  }

  componentDidUpdate() {
    const { mode, assignId } = this.props
    const { workgroupAssigned, agentAssigned, summarized }= this.state
    
    const { progress, code, text }= (mode==2 ? (this.props.assignStore.updateAssign!=undefined ? this.props.assignStore.updateAssign : {}) : (this.props.assignStore.createAssign!=undefined ? this.props.assignStore.createAssign : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }

    const agentAssign= (this.props.assignStore.agentAssign!=undefined && this.props.assignStore.agentAssign.progress=='1' ? this.props.assignStore.agentAssign.data : undefined)
    const workgroupAssign= (this.props.assignStore.workgroupAssign!=undefined && this.props.assignStore.workgroupAssign.progress=='1' ? this.props.assignStore.workgroupAssign.data : undefined)
    const summaryCampaign= (this.props.masterStore.summaryCampaign!=undefined && this.props.masterStore.summaryCampaign.progress=='1' ? this.props.masterStore.summaryCampaign.data : undefined)
    
    if (mode==2) {
      if (this.props.byWorkgroup=='1' && workgroupAssigned!=assignId && workgroupAssign==undefined ) {
        this.setState({
          workgroupAssigned: this.props.campaignId
        })
        this.props.workgroupAssign({search: {workgroups: this.props.workgroups}})
      }
      if (this.props.byWorkgroup=='0' && agentAssigned!=assignId && agentAssign==undefined ) {
        this.setState({
          agentAssigned: this.props.campaignId
        })
        this.props.agentAssign({search: {workgroups: this.props.workgroups}})
      }
    }

    if(mode==3) {
      if (summaryCampaign && summarized==this.props.campaignId) {
        this.props.dispatch(change('AssignForm', 'totFloating', summaryCampaign.totFloating||0 ));
        this.props.dispatch(change('AssignForm', 'totAssigned', summaryCampaign.totAssigned||0 ));
        this.props.dispatch(change('AssignForm', 'totAvailable', summaryCampaign.totAvailable||0 ));
        this.props.dispatch(change('AssignForm', 'workgroups', summaryCampaign.workgroups||[] ));

        if (workgroupAssigned!=this.props.campaignId) {
          this.setState({
            workgroupAssigned: this.props.campaignId
          })
          summaryCampaign.workgroups.length>0 && this.props.workgroupAssign({search: {workgroups: summaryCampaign.workgroups}})
        }
      }
    }
  }

  setMode(key) {
    if (this.props.byWorkgroup=='0') {
      this.props.dispatch(change('AssignForm', 'byWorkgroup','1' ));
      // console.log('GET WORKGROUP LIST')
      this.props.workgroupAssign({search: {workgroups: this.props.workgroups}})
    } else {
      // console.log('GET AGENT LIST')
      this.props.dispatch(change('AssignForm', 'byWorkgroup','0' ));
      this.props.agentAssign({search: {workgroups: this.props.workgroups}})
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    mode==2 ? this.props.updateAssign({...values}) : this.props.createAssign({...values})
    
  }

  onChangeSelect(e, v) {
    const { summarized }= this.state

    this.setState({
      lastChange: new Date().getTime(),
      summarized: v,
    })
    
    summarized!=v && this.props.summaryCampaign({assignId: this.props.assignId, campaignId: v});
  }

  drawAllocation() {
    const {mode}= this.props 
    const agentAssign= (this.props.assignStore.agentAssign!=undefined && this.props.assignStore.agentAssign.progress=='1' ? this.props.assignStore.agentAssign.data : [])
    const workgroupAssign= (this.props.assignStore.workgroupAssign!=undefined && this.props.assignStore.workgroupAssign.progress=='1' ? this.props.assignStore.workgroupAssign.data : [])
    const agents= [];

    let qtyAlloc= 0, totAlloc= 0;

    if (this.props.byWorkgroup=='1' || (!this.props.byWorkgroup &&mode==3)) {
      mode==3 && this.props.dispatch(change('AssignForm', 'byWorkgroup', '1'));

      if (this.props.method=='Evenly') {
        qtyAlloc= Math.round(this.props.totFloating / (workgroupAssign.length||1));
      }

      if (workgroupAssign.length>0) {
        return(
          workgroupAssign.map((item,i)=>{
            if (this.props.method=='Evenly') {
              if (this.props.totFloating-totAlloc<qtyAlloc*2 || qtyAlloc==0) {
                qtyAlloc= this.props.totFloating-totAlloc;
              }
              this.props.dispatch(change('AssignForm', 'workgroup_'+ item.workgroupId, qtyAlloc));
              totAlloc+= qtyAlloc
            }

            return(
              <Table.Row key={i}>
                <Table.Cell>{i+1}</Table.Cell>
                <Table.Cell>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      <Header as='h5'>
                        {item.name}
                        <Header.Subheader>{item.descript || 'No description is available'}</Header.Subheader>
                      </Header>
                    </div>
                    <div style={{flex: '1'}}>
                      <Header as='h5'>
                        {item.totAgent}
                        <Header.Subheader>Agent in Group</Header.Subheader>
                      </Header>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <Form.Group widths='equal'>
                    <Field name={'workgroup_'+ item.workgroupId} component={NumberField}
                      readOnly={this.props.method=='Evenly'}
                      fluid />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
            )
          })
        )
      } else {
        return(
          <Table.Row key={0}>
            <Table.Cell></Table.Cell>
            <Table.Cell colSpan={2}>
            </Table.Cell>
          </Table.Row>
        )
      }
    } else {
      mode==3 && this.props.dispatch(change('AssignForm', 'byWorkgroup', '0'));

      if (this.props.method=='Evenly') {
        qtyAlloc= Math.round(this.props.totFloating / (agentAssign.length||1));
      }

      if (agentAssign.length>0) {
        return(
          agentAssign.map((item,i)=>{
            if (this.props.method=='Evenly') {
              if (this.props.totFloating-totAlloc<qtyAlloc*2 || qtyAlloc==0) {
                qtyAlloc= this.props.totFloating-totAlloc;
              }
              this.props.dispatch(change('AssignForm', 'agent_'+ item.agentId, qtyAlloc));
              totAlloc+= qtyAlloc
            }
            
            agents.push(item.agentId)
            if (i==(agentAssign.length-1)) {
              this.props.dispatch(change('AssignForm', 'agents', agents));
            }
            
            return(
              <Table.Row key={i}>
                <Table.Cell>{i+1}</Table.Cell>
                <Table.Cell>
                  <Header as='h5'>
                    {item.name}
                    <Header.Subheader>{item.company || 'No company information'}</Header.Subheader>
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Form.Group widths='equal'>
                    <Field name={'agent_'+ item.agentId} component={NumberField}
                      readOnly={this.props.method=='Evenly'}
                      fluid />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
            )
          })
        )
      } else {
        return(
          <Table.Row key={0}>
            <Table.Cell></Table.Cell>
            <Table.Cell colSpan={2}>
            </Table.Cell>
          </Table.Row>
        )
      }
    }
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const {data}= (this.props.assignStore.getAssign!=undefined && this.props.assignStore.getAssign.progress=='1' ? this.props.assignStore.getAssign : {})
    const { progress, code, text }= (mode==2 ? (this.props.assignStore.updateAssign!=undefined ? this.props.assignStore.updateAssign : {}) : (this.props.assignStore.createAssign!=undefined ? this.props.assignStore.createAssign : {}))
    
    const listCampaign= (this.props.masterStore.listCampaign!=undefined && this.props.masterStore.listCampaign.progress=='1' ? this.props.masterStore.listCampaign.data : [])
    const listWorkgroup= (this.props.masterStore.listWorkgroup!=undefined && this.props.masterStore.listWorkgroup.progress=='1' ? this.props.masterStore.listWorkgroup.data : [])
    
    // console.log('!!! BY WORKGROUP', this.props.byWorkgroup)
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            {/* <Form.Group widths='16'>
              <Form.Field width={8}></Form.Field>
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group> */}
            <Form.Group widths='16'>
              <Field name='campaignId' component={SelectField} onChange={this.onChangeSelect.bind(this)}
              width={8}
              options={listCampaign||[]}
              readOnly={mode==2}
              label= 'Campaign'
              placeholder='Campaign' />
              <Field name='totFloating' component={NumberField}
              width={2}
              readOnly={true}
              label= 'Tot. Floating'
              placeholder='Tot. Floating' />
              <Field name='totAssigned' component={NumberField}
              width={2}
              readOnly={true}
              label= 'Tot. Assigned'
              placeholder='Tot. Assigned' />
              <Field name='totAvailable' component={NumberField}
              width={4}
              readOnly={true}
              label= 'Tot. Available'
              placeholder='Tot. Available' />
            </Form.Group>
          </Segment>
          <Segment attached='top'>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h5' style={{margin: '0'}}>
                  <Icon name='random' style={{fontSize: '1em'}}/>
                  <Header.Content>Target Distribution</Header.Content>
                </Header>
              </div>
              <div style={{width: '11em'}}>
                <Header as='h5' style={{margin: '0'}}>
                  {(this.props.byWorkgroup||mode==3) && <IconToggle active={this.props.byWorkgroup=='1'||mode==3} onClick={this.setMode.bind(this)}/>}
                  <Header.Content>
                    WORKGROUP
                  <Header.Subheader>Distribution scope</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
            <Form.Group widths='16'>
              <Field name='method' component={SelectField}
                            width={4}
              validate={[required]}
              options={assignmenOptions||[]}
              label= 'Distribution'
              placeholder='Method' />
              <Field name='workgroups' component={SelectField}
              width={12}
              multiple
              readOnly
              options={listWorkgroup||[]}
              label= 'Workgroups'
              placeholder='Workgroups' />
            </Form.Group>
            <Form.Group widths='equal' style={{marginTop: '2em'}}>
              <Form.Field>
                <Header as='h4' color='red'>
                  <Icon name='warning sign' />
                  <Header.Content>
                    {this.props.method=='Evenly' ? 'Evenly Distribution' : 'Manual Distribution'}
                    <Header.Subheader>{this.props.method=='Evenly' ? 'Auto distribution will ignore any number you have been set, system will auto assign of any groups or agents.' : 'System will allocate target on any groups or agents based on your allocation.'}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Form.Field>
            </Form.Group>
          </Segment>
          <Segment attached='bottom'>
            <Header as='h5' style={{margin: '0'}}>
              <Icon name='columns' style={{fontSize: '1em'}}/>
              <Header.Content>{this.props.byWorkgroup=='0' ? 'Agent Allocation' : 'Workgroup Allocation'}</Header.Content>
            </Header>
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width='15'>
                    <Header as='h5' color='blue'>
                    {this.props.byWorkgroup=='1'||mode==3 ? 'WORKGROUP' : 'AGENT'}
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      ALLOCATION
                    </Header>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data && this.drawAllocation()}
              </Table.Body>
            </Table>
          </Segment>
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Assign' : 'Insert Assign') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.assignStore.getAssign!=undefined && state.assignStore.getAssign.progress==1 ? {workgroups: [], ...state.assignStore.getAssign.data} : {workgroups: []}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      workgroups: selector(state, 'workgroups'),
      campaignId: selector(state, 'campaignId'),
      agents: selector(state, 'agents'),
      byWorkgroup: selector(state, 'byWorkgroup'),
      method: selector(state, 'method'),
      totFloating: selector(state, 'totFloating'),
    }),
    { load: getAssign }
  )
  (reduxForm({
    form: 'AssignForm',	// a unique identifier for this form
  })(AssignForm)
  )
)
