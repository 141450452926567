import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup,
} from 'semantic-ui-react'

import { scheduleOptions}  from '../../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'

import { connect } from "react-redux"
import { browseCampaign } from "../../../actions/stream/campaignAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    campaignStore: state.campaignStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseCampaign:(objParam) => dispatch(browseCampaign(objParam)),
  }
}


class CampaignSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
      lastChange: null,
    }
  }

  componentDidMount() {
  }

  submitForm(values) {
    const { search }= this.state
    this.props.browseCampaign({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    const { search }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    var values= []
    values[key]= v
    this.props.browseCampaign({search: {...search, ...values}})
  }

  onChangeInput(e, v) {
    const { search }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.browseCampaign({search: {...search, keyword: v}})
      }
    }, 500)
  }
  
  onChangeSelect(e, v) {
    const { search }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    this.props.browseCampaign({search: {...search, availability: v}})
  }

  autoCampaign() {
    const { search }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    this.props.browseCampaign({search: {...search, showAuto: search && search.showAuto=='0' ? '1' : '0'}})
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props
    const { search }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    
    return (
      <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
        <div style={{padding: '1em 1em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{marginTop: '.25em'}}>
                <Icon name='calendar check outline' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Campaign / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
                </Header.Content>
              </Header>
            </div>
            <div>
              {mode !=1 &&
              <Button size='mini' color='blue' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='arrow left' /></Button>}
              {mode !=2 && mode !=3 &&
              <Button size='mini' color='red' onClick={this.props.setMode.bind(this,3)} icon style={{padding: '.5em'}}><Icon name='plus' /></Button>}
              {mode ==1 &&
              <Button size='mini' color='grey' onClick={this.showSearch.bind(this)} icon style={{padding: '.5em'}}><Icon name={searchMode==true? 'angle up' : 'angle down'} /></Button>}
            </div>
          </div>

          {mode==1 && searchMode && 
          <div style={{margin: '1em 0 0 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: 'flex'}}>
                <div>
                  <Popup position= 'bottom left' 
                    trigger={
                      <Label style={{padding: '.3em .5em .4em  .5em', marginRight: '1.2em'}}>
                        <Icon name={(search && search.showAuto==1 && 'toggle on') || 'toggle off'} color={(search && search.showAuto==1 && 'blue') || 'grey'} style={{fontSize: '2.1em', margin: '0', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.autoCampaign.bind(this)} />
                        <Label.Detail style={{padding: '.8em 0 0 0', fontWeight: 'bold', color: '#333'}}>SHOW ALL !</Label.Detail>
                      </Label>
                    }
                    content='Show all campaigns including system generated.'
                    inverted
                    on={['hover']}
                  />
                </div>
                <div style={{flex: '1'}}>
                  <Form.Group widths='equal'>
                    {/* <Form.Field width={1} >
                      <Popup position= 'bottom left' 
                        trigger={
                          <Label style={{padding: '.3em .5em .4em  .5em'}}>
                            <Icon name={(search && search.showAuto==1 && 'toggle on') || 'toggle off'} color={(search && search.showAuto==1 && 'blue') || 'grey'} style={{fontSize: '2.1em', margin: '0', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.autoCampaign.bind(this)} />
                            SHOW !
                          </Label>
                        }
                        content='Show all campaigns including system generated.'
                        inverted
                        on={['hover']}
                      />
                    </Form.Field> */}
                    <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                    width={8} 
                    placeholder= 'Search campaign ... <ENTER>' />
                    <Field name='availability' component={SelectField} onChange= {this.onChangeSelect.bind(this)}
                    options= {scheduleOptions()}
                    width={4} 
                    placeholder= 'Availability' />
                    <Field name='eventDate' component={DatePickerField} onChange= {this.onChangeDate.bind(this, 'eventDate')}
                    width={4} 
                    type='date'
                    placeholder= 'Event Date' />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.campaignStore.browseCampaign!=undefined && state.campaignStore.browseCampaign.progress==1 ? state.campaignStore.browseCampaign.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'CampaignSearch',	// a unique identifier for this form
  })(CampaignSearch)
  )
)