import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label, Popup } from 'semantic-ui-react'

import { connect } from "react-redux"
import { getAccount, resetAccount } from "../../../actions/socmed/accountAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    accountStore: state.socmed_accountStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetAccount:(objParam) => dispatch(resetAccount(objParam)),
    getAccount:(objParam) => dispatch(getAccount(objParam)),
  }
}

class TwitterProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 0,
    };
  }

  componentDidMount(){
    this.props.resetAccount()
    this.props.getAccount({accountType: 'Twitter'})
  }

  setMode(mode, id) {
    this.setState({mode: mode, accountId: id})
  }
  
  render() {
    const {mode}= this.state
    const {screenWidth, screenHeight}= this.props
    const { data }= (this.props.accountStore.getAccount!==undefined ? this.props.accountStore.getAccount : {})

    return(
      <div>
        <Grid>
          <Grid.Row width={16}>
            <Grid.Column width={6}>
              <Segment style={{textAlign: 'center'}}>
                <Image src={(data && data.avatar) || 'https://react.semantic-ui.com/images/wireframe/square-image.png'} size='medium' circular centered />
              </Segment>
            </Grid.Column>
            <Grid.Column width={10}>
              <Segment style={{minHeight: screenHeight/2}}>
                <Header as='h5'>
                  <Header.Content>
                    <Header.Subheader>Name</Header.Subheader>
                    {data && data.name || 'No Name'}
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Header.Content>
                    <Header.Subheader>Account</Header.Subheader>
                    {data && data.accountId || 'No Account'}
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Header.Content>
                    <Header.Subheader>Location</Header.Subheader>
                    {data && data.location || 'No Location'}
                  </Header.Content>
                </Header>
                <Header as='h5'>
                  <Header.Content>
                    <Header.Subheader>Description</Header.Subheader>
                    {data && data.descript || 'No Description'}
                  </Header.Content>
                </Header>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TwitterProfile)