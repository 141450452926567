import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetKnowledge(objParam) {
  return {
      type: 'KNOWLEDGE_RESET',
      payload: objParam
  }
}

export function getKnowledge(objParam) {
  return {
      type: 'KNOWLEDGE_GET',
      payload:
        axios.post(
          urlAPI + 'service/knowledge/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusKnowledge(objParam) {
  return {
      type: 'KNOWLEDGE_STATUS',
      payload:
        axios.post(
          urlAPI + 'service/knowledge/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseKnowledge(objParam) {
  return {
      type: 'KNOWLEDGE_BROWSE',
      payload:
        axios.post(
          urlAPI + 'service/knowledge/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createKnowledge(objParam) {
  return {
      type: 'KNOWLEDGE_CREATE',
      payload:
        axios.post(
          urlAPI + 'service/knowledge/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateKnowledge(objParam) {
  return {
      type: 'KNOWLEDGE_UPDATE',
      payload:
        axios.post(
          urlAPI + 'service/knowledge/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeKnowledge(objParam) {
  return {
      type: 'KNOWLEDGE_REMOVE',
      payload:
        axios.post(
          urlAPI + 'service/knowledge/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function topicKnowledge(objParam) {
  return {
      type: 'KNOWLEDGE_TOPIC',
      payload:
        axios.post(
          urlAPI + 'service/knowledge/topic',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function contentKnowledge(objParam) {
  return {
      type: 'KNOWLEDGE_CONTENT',
      payload:
        axios.post(
          urlAPI + 'service/knowledge/content',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
