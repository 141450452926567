import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Progress } from 'semantic-ui-react'

import AchievementWidget from '../../libraries/widget/AchievementWidget'
import RecentWidget from '../../libraries/widget/RecentWidget'
import BillingWidget from '../../libraries/widget/BillingWidget'

class StatisticBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, screenWidth, screenHeight }= this.props

    return (
      <div style={{padding: '1em'}}>
        {/* <BillingWidget screenWidth={screenWidth} screenHeight={screenHeight}/> */}
        <AchievementWidget screenWidth={screenWidth} screenHeight={screenHeight}/>
      </div>
    )
  }
}

export default StatisticBody