import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid, Label, Progress, Input, Dropdown, Card, Checkbox, Form } from 'semantic-ui-react'

import numeral from "numeral"

import IconToggle from '../common/IconToggle'
import PieRechart from '../chart/PieRechart';

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus } from "../../actions/socket/socketAction"

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),
  }
}

class OmniTimelinesWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
    };
  }

  setMode() {

  }

  render() {
    const { mode, screenWidth,  screenHeight, responsive }= this.props
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data : undefined)
    const { performance }= (agentStatus && agentStatus.payload.data) || {status: 'connect'}
    
    const options = [
      { key: '0', text: 'Ticket', value: '.com', icon: 'ticket' },
      <Divider key='1' />,
      { key: '2', text: 'Phone', value: '.net', icon: 'phone' },
      { key: '3', text: 'Chat', value: '.net', icon: 'comment alternate' },
      <Divider key='4' />,
      { key: '5', text: 'Chat', value: '.net', icon: 'comment alternate' },
      { key: '6', text: 'Email', value: '.net', icon: 'mail' },
      { key: '7', text: 'Whatsapp', value: '.org', icon: 'whatsapp'},
      { key: '8', text: 'facebook', value: '.org', icon: 'facebook'},
      { key: '9', text: 'instagram', value: '.org', icon: 'instagram'},
      { key: '10', text: 'twitter', value: '.org', icon: 'twitter'},
    ]

    return (
      <>
      <Segment style={{padding: '1em 1.5em .7em 1.5em'}}>
        {/* <div style={{display: 'flex', marginBottom: '1em'}}>
          <div style={{flex: '1'}}>
            <Label color='orange' style={{borderRadius: '2em', padding: '.1em 1em .5em 1em'}}>
              <Header as='h4' style={{marginTop: '.25em'}} inverted>
                <Icon name='comments' style={{fontSize: '1em'}} />
                <Header.Content style={{fontWeight: 'bold'}}>
                  <i>Timelines!</i> Conversation
                </Header.Content>
              </Header>
            </Label>
          </div>
          <div style={{marginLeft: '1em'}}>
          </div>
        </div> */}
        <div style={{display: 'flex', marginBottom: '1em'}}>
          <div style={{marginRight: '2em', width: '16em'}}>
            <Input
              fluid
              label={<Dropdown defaultValue='.com' options={options} />}
              // icon={{ name: 'search', circular: true, link: true }}
              placeholder='Search...'
            />
            <Divider />
            <Feed>
              <Feed.Event>
                <Feed.Label>
                  <img src='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' />
                </Feed.Label>
                <Feed.Content>
                  <Feed.Label>Ticket</Feed.Label>
                  <Feed.Summary>
                    <Feed.User>Elliot Fu</Feed.User> added you as a friend
                    <Feed.Date>1 Hour Ago</Feed.Date>
                  </Feed.Summary>
                  <Feed.Meta>
                    <Icon name='instagram' /><b>Instagram</b>, 
                    <Feed.Like>
                      <Icon name='like' />4 Likes
                    </Feed.Like>
                  </Feed.Meta>
                </Feed.Content>
              </Feed.Event>

              <Feed.Event>
                <Feed.Label image='https://react.semantic-ui.com/images/avatar/small/helen.jpg' />
                <Feed.Content>
                  <Feed.Label>Sales</Feed.Label>
                  <Feed.Summary>
                    <a>Helen Troy</a> added <a>2 new illustrations</a>
                    <Feed.Date>4 days ago</Feed.Date>
                  </Feed.Summary>
                  <Feed.Extra images>
                    <a>
                      <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                    </a>
                    <a>
                      <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                    </a>
                  </Feed.Extra>
                  <Feed.Meta>
                    <Feed.Like>
                      <Icon name='like' />1 Like
                    </Feed.Like>
                  </Feed.Meta>
                </Feed.Content>
              </Feed.Event>

              <Feed.Event>
                <Feed.Label image='https://react.semantic-ui.com/images/avatar/small/joe.jpg' />
                <Feed.Content>
                  <Feed.Summary>
                    <a>Joe Henderson</a> posted on his page
                    <Feed.Date>3 days ago</Feed.Date>
                  </Feed.Summary>
                  <Feed.Extra text>
                    Ours is a life of constant reruns. We're always circling back to where
                    we'd we started, then starting all over again.
                  </Feed.Extra>
                  <Feed.Meta>
                    <Feed.Like>
                      <Icon name='like' />5 Likes
                    </Feed.Like>
                  </Feed.Meta>
                </Feed.Content>
              </Feed.Event>

              <Feed.Event>
                <Feed.Label image='https://react.semantic-ui.com/images/avatar/small/justen.jpg' />
                <Feed.Content>
                  <Feed.Summary>
                    <a>Justen Kitsune</a> added <a>2 new photos</a> of you
                    <Feed.Date>4 days ago</Feed.Date>
                  </Feed.Summary>
                  <Feed.Extra images>
                    <a>
                      <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                    </a>
                    <a>
                      <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                    </a>
                  </Feed.Extra>
                  <Feed.Meta>
                    <Feed.Like>
                      <Icon name='like' />
                      41 Likes
                    </Feed.Like>
                  </Feed.Meta>
                </Feed.Content>
              </Feed.Event>
            </Feed>
          </div>
          <div style={{flex: '1'}}>
            <Form>
              <Form.Group widths='16'>
                <Form.Field width={8}>
                  <Input
                    fluid
                    label={<Dropdown defaultValue='.com' options={options} />}
                    // icon={{ name: 'search', circular: true, link: true }}
                    placeholder='Segmentation'
                  />
                </Form.Field>
                <Form.Field>
                <Checkbox label='Open' />
                </Form.Field>
                <Form.Field>
                <Checkbox label='Progress' />
                </Form.Field>
                <Form.Field>
                <Checkbox label='Pending' />
                </Form.Field>
                <Form.Field>
                <Checkbox label='Closed' />
                </Form.Field>
              </Form.Group>
            </Form>
            <Divider />
            <Grid columns={(screenWidth<1024 && 1) || (screenWidth<1400 && 2) || (screenWidth<1600 && 3) || 4} style={{margin: '0 0 0 0'}}>
              <Grid.Column>
                <Card fluid as='a'>
                  <Card.Content extra>
                    <Icon name='circle' color='blue' /> 2 hours
                    <div style={{float: 'right'}}>
                      <Icon name='instagram' /> Instagram
                    </div>
                  </Card.Content>
                  <Card.Content>
                    <Image
                      floated='right'
                      size='mini'
                      src='https://react.semantic-ui.com/images/avatar/large/steve.jpg'
                    />
                    <Card.Meta>Ticket</Card.Meta>
                    <Card.Header>Task / Project Title </Card.Header>
                    <Card.Description>
                      Project / task description as customer's asked <strong>best friends</strong>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content>
                    <Card.Meta>Today 14:05</Card.Meta>
                    <Card.Meta><strong>John</strong>, Technical Support</Card.Meta>
                    <Card.Description>
                      Ours is a life of constant reruns. We're always circling back to where we'd we started, then starting all over again.
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid>
          </div>
        </div>
      </Segment>
      </>
    );
  }
}

OmniTimelinesWidget.contextType= WebSocketContext;

export default connect(mapStateToProps, mapDispatchToProps)(OmniTimelinesWidget)
