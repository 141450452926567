import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import { loggerOptions, yesnoOptions }  from '../../common/StaticMasterData'
import DismissableMessage from '../../common/DismissableMessage'

import { SubmissionMiddleware, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField, TextAreaField, LabelNumberField, LabelSelectField } from '../../validation/componentrsui'
import { required, number, minValue, email } from '../../validation/validation'
import { connect } from "react-redux"
import { browseMiddleware, resetMiddleware, updateMiddleware } from "../../../actions/middleware/middlewareAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    middlewareStore: state.middlewareStore
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseMiddleware:(objParam) => dispatch(browseMiddleware(objParam)),

    resetMiddleware:(objParam) => dispatch(resetMiddleware(objParam)),
    updateMiddleware:(objParam) => dispatch(updateMiddleware(objParam)),
  }
}

class APIInventoryForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetMiddleware(this.props.mode)
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= this.props.middlewareStore.updateMiddleware!=undefined ? this.props.middlewareStore.updateMiddleware : {}
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  componentWillUnmount() {
    const { mode } = this.props
    const { progress, code, text }= this.props.middlewareStore.updateMiddleware!=undefined ? this.props.middlewareStore.updateMiddleware : {}
    if (progress==1 && code=='00') {
      const { search, paging }= (this.props.middlewareStore.browseMiddleware!==undefined ? this.props.middlewareStore.browseMiddleware : {})
      this.props.browseMiddleware({search: {...search}, paging: paging})
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, loggerId } = this.props
    this.props.updateMiddleware({...values})
  }
  

  render() {
    const { middleware, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= this.props.middlewareStore.updateMiddleware!=undefined ? this.props.middlewareStore.updateMiddleware : {}

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths={16}>
              <Field name='company' component={InputField}
              width={8}
              readOnly
              label='Company'
              placeholder='Company' />
              <Field name='city' component={InputField}
              width={4}
              readOnly
              label='City'
              placeholder='City' />
              <Field name='province' component={InputField}
              width={4}
              readOnly
              label='Province'
              placeholder='Province' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='check' style={{fontSize: '1em'}} />
              <Header.Content>
                Enable API Service
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            <Form.Group widths='equal'>
              <Field name='isCampaign' component={LabelSelectField}
              options={yesnoOptions()}
              label='Enabled'
              caption='API Campaign'
              placeholder='API Campaign' />
              <Field name='isTarget' component={LabelSelectField}
              options={yesnoOptions()}
              label='Enabled'
              caption='API Target'
              placeholder='API Target' />
              <Field name='isAgent' component={LabelSelectField}
              options={yesnoOptions()}
              label='Enabled'
              caption='API Agent'
              placeholder='API Agent' />
              <Field name='isWidget' component={LabelSelectField}
              options={yesnoOptions()}
              label='Enabled'
              caption='API Widget'
              placeholder='API Widget' />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group widths='equal'>
              <Field name='isWebhook' component={LabelSelectField}
              options={yesnoOptions()}
              label='Enabled'
              caption='API Webhook'
              placeholder='API Webhook' />
              <Field name='isReport' component={LabelSelectField}
              options={yesnoOptions()}
              label='Enabled'
              caption='API Report'
              placeholder='API Report' />
              <Field name='isInteraction' component={LabelSelectField}
              options={yesnoOptions()}
              label='Enabled'
              caption='API Interaction'
              placeholder='API Interaction' />
              <Field name='isCommunication' component={LabelSelectField}
              options={yesnoOptions()}
              label='Enabled'
              caption='API Communication'
              placeholder='API Communication' />
            </Form.Group>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Middleware' : 'Insert Middleware') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    (state, props) => ({
      // initialValues: (state.middlewareStore.getMiddleware!=undefined && state.middlewareStore.getMiddleware.progress==1 ? state.middlewareStore.getMiddleware.data : {}),
      initialValues: (props.data),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'APIInventoryForm',	// a unique identifier for this form
  })(APIInventoryForm)
  )
)