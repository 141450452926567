import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import { competencyOptions, groupOptions, genderOptions,educationOptions,protoOptions }  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, DatePickerField, NumberField, SelectField } from '../validation/componentrsui'
import { required, strongPassword, confirmedPassword, userPassword, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { getProfile,updateProfile, resetForm } from "../../actions/auth/authAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetForm:(objParam) => dispatch(resetForm(objParam)),
    getProfile:(objParam) => dispatch(getProfile(objParam)),
    updateProfile:(objParam) => dispatch(updateProfile(objParam)),
  }
}

class UserPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  render() {
    return (
      <div>
          <Form.Group widths='16'>
            <Field name='userPasswd' component={InputField}
            width={8}
            autocomplete="off"
            icon='unlock alternate'
            type='password'
            label='Current Password'
            placeholder='Current Password'  
            validate={required} />
            <Form.Field width={8} />
          </Form.Group>
          <Form.Group widths='16'>
            <Field name='newPasswd' component={InputField}
            complexity
            icon='unlock alternate'
            width= '8'
            type='password'
            label='Input New Password'
            placeholder='New Password'  
            validate={[required, strongPassword, userPassword]} />
            <Field name='confirmPasswd' component={InputField}
            complexity
            icon='unlock alternate'
            width= '8'
            type='password'
            label='Confirm New Password'
            placeholder='Retype Password'  
            validate={[required, strongPassword, confirmedPassword]} />
          </Form.Group>
      </div>
    )
  }
}

class ProfileForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: -1 ,
      recordState: null,
      audioData: null,
    } 
  }
  
  componentDidMount() {
    //console.log('!!! DID MOUNT PROPS: ', this.props)
    this.props.resetForm('updateProfile');
    this.props.getProfile({})
  }
  
  componentDidUpdate() {
    const { progress, code, text }= this.props.authStore.updateProfile!=undefined ? this.props.authStore.updateProfile : {}

    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.props.closeModal()
      }, 1000)
    }
  }

  resetForm() {
    const { reset, mode } = this.props
    reset();
  }

  onSubmit(values) {
    const { mode } = this.props
  
    if(mode==2) { //UPDATE
      this.props.updateProfile({...values})
    }
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }
  // _onRecordingComplete = (blob) => {
  //   blob && blobToBuffer(blob, (err, buffer) => {
  //     if (err) {
  //       // console.error(err)
  //       return
  //     }
  //     // console.log('recording', blob)
  //     if (this.state.audioURL) {
  //       window.URL.revokeObjectURL(this.state.audioURL)
  //     }
  //     this.setState({
  //       audioURL: window.URL.createObjectURL(blob)
  //     })
  //   })
  // }
  // _onRecordingError = (err) => {
  //   // console.log('error recording', err)
  //   if (this.state.audioURL) {
  //     window.URL.revokeObjectURL(this.state.audioURL)
  //   }
  //   this.setState({ audioURL: null })
  // }

  render() {
    const { activeIndex, recordState, audioData } = this.state
    const { screenWidth, agent, error, handleSubmit, pristine, reset, submitting, mode }=this.props
    const { progress, code, text }= (this.props.authStore.updateProfile!=undefined ? this.props.authStore.updateProfile : {})

    return (
      <div>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <div style={{display: screenWidth>900 ? 'flex' : 'block'}}>
              <div style={{flex: '1'}}>
                <Form.Group widths='16' style={{margin: '1em 0'}}>
                  <Form.Field width='16'>
                    <Field name='avatar' component={ImageUploadField}
                    width={250} 
                    height={300} 
                    resizeWidth={300} 
                    resizeHeight={300} 
                    aspectRatio= '3:3'
                    />
                  </Form.Field>
                </Form.Group>
              </div>
              {/* {agent && <div style={{flex: '1'}}>
                <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', height: '100%'}}>
                  <div>
                    <Header as='h4' color='blue'>
                      <Header.Content>
                        RECORD YOUR VOICE !
                        <Header.Subheader>Your voice would be valuable review for your candidate employer.</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{flex: '1', padding: '2em 0 1.5em 0', background: '#ffffff'}}>
                    <div style={{textAlign: 'center'}}>
                      <Recorder
                        onRecordingComplete={this._onRecordingComplete}
                        onRecordingError={this._onRecordingError}
                      />
                    </div>
                    <Header as='h4' style={{margin: '0 0 2em 0', textAlign: 'center'}}>
                      Push To Record
                      <Header.Subheader>Please, push button while talking....</Header.Subheader>
                    </Header>
                    <Divider />
                    {this.state.audioURL &&
                    <audio
                      id='audio'
                      controls
                      src={this.state.audioURL ? this.state.audioURL : null}
                    ></audio>}
                  </div>
                </div>
              </div>} */}
            </div>
          </Segment>

          <Segment>
            <Header as='h5'>
              <Icon name='user outline' style={{fontSize: '1em'}}/>
              <Header.Content>Personal</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='name' component={InputField}
              validate={[required]}
              label='Name'
              placeholder='Full Name' />
              <Field name='quote' component={InputField}
              label='Tagline'
              placeholder='Quote / Tagline' />
            </Form.Group>
          </Segment>

          {!agent && <Segment>
            <Header as='h5'>
              <Icon name='building outline' style={{fontSize: '1em'}}/>
              <Header.Content>Corporate</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='company' component={InputField}
                            width={8}
              validate={[required]}
              label='Name'
              placeholder='Company' />
              <Field name='brand' component={InputField}
                            width={4}
              validate={[required]}
              label='Brand / Code'
              placeholder='Brand / Code' />
              <Field name='business' component={InputField}
                            width={4}
              validate={[required]}
              label='Business Stream'
              placeholder='Business Stream' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='address' component={InputField}
                            label='Address'
              placeholder='Address' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='city' component={InputField}
              width={6}
              placeholder='City' />
              <Field name='province' component={InputField}
              width={6}
              placeholder='Province' />
              <Field name='country' component={InputField}
              width={4}
              placeholder='Country' />
            </Form.Group>
          </Segment>}

          <Segment attached='top'>
            <Header as='h5'>
              <Icon name='user outline' style={{fontSize: '1em'}}/>
              <Header.Content>Credential</Header.Content>
            </Header>
            {/* <Form.Group widths='equal'>
              <Field name='name' component={InputField}
              label= 'Full Name'
              placeholder='Full Name'
              validate={[required]} />
            </Form.Group> */}
            <Form.Group widths='equal'>
              <Field name='account' component={InputField}
              readOnly
              label= 'Account'
              placeholder='Account / User'
              validate={[required]} />
              <Field name='groupId' component={InputField}
              readOnly
              label= 'User Group'
              placeholder='User Group'
              validate={[required]} />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='email' component={InputField}
              width= '8'
              readOnly
              placeholder='Email'
              validate={[required]} />
              <Field name='mobile' component={InputField}
              width= '8'
              readOnly
              placeholder='Mobile'
              validate={[required]} />
            </Form.Group>
          </Segment>
          <Segment attached='bottom'>
            <Accordion>
              <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
              <span style={{fontSize: '1.1em', color: '#d31', fontWeight: 'bold'}}><Icon name='envelope open outline' />Update Password</span> {activeIndex != 0 && <Icon name='arrow alternate circle right outline' />} {activeIndex === 0 && <Icon name='arrow alternate circle down outline' />}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                {activeIndex === 0 && <UserPasswordForm pristine= {pristine} submitting= {submitting} resetForm= {this.resetForm.bind(this)}/>}
              </Accordion.Content>
            </Accordion>
          </Segment>

          {agent && <>
            <Segment>
              <Form.Group widths='equal'>
                <Field name='name' component={InputField}
                label='Full Name'
                placeholder='Full Name' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='monthlyRate' component={NumberField}
                                label='Monthly Rate'
                placeholder='Rp. / Month' />
                <Field name='quote' component={InputField}
                label='Tagline'
                placeholder='Quote / Tagline' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='gender' component={SelectField}
                                label='Gender'
                options={genderOptions}
                placeholder='L/P' />
                <Field name='birthPlace' component={InputField}
                label='Birth Place'
                placeholder='Birth Place' />
                <Field name='birthDate' component={DatePickerField}
                label='Birth Date'
                validate={[required]}
                placeholder='Birth Date' />
              </Form.Group>
            </Segment>
            
            <Segment>
              <Header as='h5'>
                <Icon name='map marker alternate' style={{fontSize: '1em'}}/>
                <Header.Content>Location</Header.Content>
              </Header>
              <Form.Group widths='equal'>
                <Field name='address' component={InputField}
                                label='Address'
                placeholder='Address' />
              </Form.Group>
              <Form.Group widths='16'>
                <Field name='city' component={InputField}
                width={8}
                placeholder='City' />
                <Field name='province' component={InputField}
                width={4}
                placeholder='Province' />
                <Field name='country' component={InputField}
                width={4}
                placeholder='Country' />
              </Form.Group>
            </Segment>
            
            <Segment>
              <Header as='h5'>
                <Icon name='image outline' style={{fontSize: '1em'}}/>
                <Header.Content>Expertise</Header.Content>
              </Header>
              <Form.Group widths='16'>
                <Field name='educationInstitute' component={InputField}
                width={8}
                label='School / Institute / University' />
                <Field name='educationDepartment' component={InputField}
                width={4}
                label='Major / Department' />
                <Field name='educationLevel' component={SelectField}
                                width={4}
                label='Level'
                options={educationOptions}
                placeholder='S1 / S2 / S3' />
              </Form.Group>
              <Form.Group widths='16'>
                <Field name='competencies' component={SelectField}
                width={16}
                multiple
                search
                options={competencyOptions}
                label='Professional Competency' />
              </Form.Group>
            </Segment>
          </>}

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage hide='auto' style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Account' : 'Insert Account') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button size='small' type='reset' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button size='small' type='submit' content='Update' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.authStore.getProfile!=undefined && state.authStore.getProfile.progress==1 ? state.authStore.getProfile.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: getProfile }
  )
  (reduxForm({
    form: 'ProfileForm',	// a unique identifier for this form
  })(ProfileForm)
  )
)