export default function waserverReducer (state = {}, action) {
  switch (action.type) {
    case 'WASERVER_RESET': {
        return({
            ...state,
            qrcodeWaserver: {},
            getWaserver: {},
            updateWaserver: {},
            createWaserver: {},
        })
    }

    case 'WASERVER_GET_PENDING': {
        return({...state, getWaserver: {progress: 0}})
    }
    case 'WASERVER_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getWaserver: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getWaserver: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WASERVER_GET_REJECTED': {
        return({...state, getWaserver: {progress: 2, payload: action.payload}})
    }

    case 'WASERVER_STATUS_PENDING': {
      return({...state, statusWaserver: {progress: 0}})
    }
    case 'WASERVER_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusWaserver: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusWaserver: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WASERVER_STATUS_REJECTED': {
        return({...state, statusWaserver: {progress: 2, payload: action.payload}})
    }

    case 'WASERVER_BROWSE_PENDING': {
      return({...state, browseWaserver: {progress: 0}})
    }
    case 'WASERVER_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseWaserver: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseWaserver: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WASERVER_BROWSE_REJECTED': {
        return({...state, browseWaserver: {progress: 2, payload: action.payload}})
    }

    case 'WASERVER_CREATE_PENDING': {
      return({...state, browseWaserver: {progress: 0}})
    }
    case 'WASERVER_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createWaserver: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createWaserver: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WASERVER_CREATE_REJECTED': {
        return({...state, createWaserver: {progress: 2, payload: action.payload}})
    }

    case 'WASERVER_UPDATE_PENDING': {
      return({...state, updateWaserver: {progress: 0}})
    }
    case 'WASERVER_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateWaserver: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateWaserver: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WASERVER_UPDATE_REJECTED': {
        return({...state, updateWaserver: {progress: 2, payload: action.payload}})
    }

    case 'WASERVER_REMOVE_PENDING': {
      return({...state, removeWaserver: {progress: 0}})
    }
    case 'WASERVER_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeWaserver: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeWaserver: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WASERVER_REMOVE_REJECTED': {
        return({...state, removeWaserver: {progress: 2, payload: action.payload}})
    }

    case 'WASERVER_QRCODE_PENDING': {
        return({...state, qrcodeWaserver: {progress: 0}})
    }
    case 'WASERVER_QRCODE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, qrcodeWaserver: {progress: 2, payload: action.payload}})
        } else {
            return({...state, qrcodeWaserver: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WASERVER_QRCODE_REJECTED': {
        return({...state, qrcodeWaserver: {progress: 2, payload: action.payload}})
    }

    case 'WASERVER_COMMAND_PENDING': {
        return({...state, commandWaserver: {progress: 0}})
    }
    case 'WASERVER_COMMAND_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, commandWaserver: {progress: 2, payload: action.payload}})
        } else {
            return({...state, commandWaserver: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'WASERVER_COMMAND_REJECTED': {
        return({...state, commandWaserver: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}