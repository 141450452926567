export default function topupReducer (state = {}, action) {
  switch (action.type) {
    case 'TOPUP_RESET': {
        return({
            ...state,
            getTopup: {},
            updateTopup: {},
            createTopup: {},
        })
    }

    case 'TOPUP_GET_PENDING': {
        return({...state, getTopup: {progress: 0}})
    }
    case 'TOPUP_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getTopup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getTopup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TOPUP_GET_REJECTED': {
        return({...state, getTopup: {progress: 2, payload: action.payload}})
    }

    case 'TOPUP_STATUS_PENDING': {
      return({...state, statusTopup: {progress: 0}})
    }
    case 'TOPUP_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusTopup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusTopup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TOPUP_STATUS_REJECTED': {
        return({...state, statusTopup: {progress: 2, payload: action.payload}})
    }

    case 'TOPUP_BROWSE_PENDING': {
      return({...state, browseTopup: {progress: 0}})
    }
    case 'TOPUP_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseTopup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseTopup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TOPUP_BROWSE_REJECTED': {
        return({...state, browseTopup: {progress: 2, payload: action.payload}})
    }

    case 'TOPUP_CREATE_PENDING': {
      return({...state, browseTopup: {progress: 0}})
    }
    case 'TOPUP_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createTopup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createTopup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TOPUP_CREATE_REJECTED': {
        return({...state, createTopup: {progress: 2, payload: action.payload}})
    }

    case 'TOPUP_UPDATE_PENDING': {
      return({...state, updateTopup: {progress: 0}})
    }
    case 'TOPUP_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateTopup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateTopup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TOPUP_UPDATE_REJECTED': {
        return({...state, updateTopup: {progress: 2, payload: action.payload}})
    }

    case 'TOPUP_REMOVE_PENDING': {
      return({...state, removeTopup: {progress: 0}})
    }
    case 'TOPUP_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeTopup: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeTopup: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'TOPUP_REMOVE_REJECTED': {
        return({...state, removeTopup: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}