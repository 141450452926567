import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  Popup,
} from 'semantic-ui-react'


import OmniphoneDial from './OmniphoneDial';
import OmniphoneVideo from './OmniphoneVideo';

import { connect } from "react-redux"
import { loggingError } from "../../actions/system/errorAction"
import { contactOmniphone, dtmfOmniphone } from "../../actions/stream/omniphoneAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniphoneStore: state.omniphoneStore,
    errorStore: state.errorStor
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    contactOmniphone:(objParam) => dispatch(contactOmniphone(objParam)),
    dtmfOmniphone:(objParam) => dispatch(dtmfOmniphone(objParam)),
    loggingError:(objParam) => dispatch(loggingError(objParam)),
  }
}

const langEN= {
  project: 'Project',
  custTagger: 'Tagger',
  dataOrigin: 'Data Source',
  dataMaturity: 'Data Maturity',
  
  name: 'Name', //=> BY DEFAULT IS VISIBLE
  descript: 'Information',
  
  custRef: 'Customer Ref.',
  contractRef: 'Contract Ref.',
  custStatus: 'Status',
  gender: 'Gender',
  birthDate: 'Birth Date',
  marital: 'Marital',
  children: 'Children',
  address: 'Address',
  city: 'City',
  province: 'Province',
  country: 'Country',
  zipcode: 'Zipcode',

  occupation: 'Occupation',
  jobTitle: 'Job Title',
  jobDepartment: 'Job Department',
  jobCompany: 'Job Company',
  homestay: 'Home Status',
  vehicle: 'Vehicle',
  revenue: 'Revenue',
  expense: 'Expensee',
  creditcard: 'Creditcard',
}

class OmniphoneOncall extends Component {
  constructor(props){
  	super(props)
    this.omniphoneVideo= null;
  	this.state = {
      dialpad: false,
      video: null,
    }
  }

  componentDidMount() {
    this.omniphoneVideo= document.getElementById('omniphoneVideo');
  }

  componentDidUpdate() {
    if (this.props.remoteVideoStream && this.state.video==null && this.props.channel=='Video') {
      this.setState({
        video: true,
      })
    }
  }

  showDialpad() {
    this.setState({
      dialpad: !this.state.dialpad,
    })
  }

  sendDTMF(v) {
    this.props.dtmfOmniphone({
      dtmf: v,
    })
  }

  showVideo() {
    this.setState({
      video: !this.state.video,
    })
  }

  render() {
    const { mode, status, dialings, terminating, responsive, layout }= this.props
    const { target, customer, visitor, contact, setting }= (this.props.omniphoneStore.contactOmniphone && this.props.omniphoneStore.contactOmniphone.progress=='1' ? this.props.omniphoneStore.contactOmniphone.data : {})
    
    const columns= [];
    //setting.popup=> popupCampaign, popupPersonal, popupMerchant
    try {
      return (
        <>
          <Segment style={{padding: '.5em .5em', minHeight: '20em', background: '#444', border: 'none', textAlign: responsive  ? 'center' : 'left'}}>
            {status=='online' &&
            <Header as='h3' color='yellow' icon={!responsive} inverted style={{margin: '1em .5em 0 .5em', display: 'inline'}} >
              {responsive && 
                <>
                  <Popup header={status=='occupied' ? 'Hangup Customer' : 'Hangup and Offline'} content={status=='occupied' ? 'Click to hangup conversation between you and current customer.' : 'Click hangup for being offline as agent.'} trigger={
                    <Icon name='phone' className='bganimateiconborder' style={{transform: 'rotate(-135deg)', fontSize: '1em', cursor: 'pointer'}} circular inverted color='red' onClick={this.props.callHangup.bind(this)}/>
                  } />
                  <Header.Content style={{paddingLeft: '.5em'}}>
                    {(mode=='predictive' || (mode=='predialed' && dialings && dialings.length==0)) ? 'Online ...' : 'Dialing ...'}
                    <Header.Subheader>{(mode=='predictive' || (mode=='predialed' && dialings && dialings.length==0)) ? 'Waiting for conversation' : 'Waiting conversation'}</Header.Subheader>
                  </Header.Content>
                </>
              }
              {!responsive && <>
                <Icon name='rocket' style={{fontSize: '5em', textShadow: '3px 5px 3px #000'}} color='teal' inverted/>
                <Header.Content style={{padding: '2em 1em'}}>
                  Waiting Call
                  <Header.Subheader style={{fontSize: '.8em'}}>
                    Now you are in waiting dispatched call mode, to call manually please minimize omniphone and click <mark><b>&nbsp;Call !&nbsp;</b></mark> on target number list.
                  </Header.Subheader>
                </Header.Content>
              </>}
            </Header>}
            {status=='occupied' && <div>
              <div style={{display: 'flex', position: 'fixed', zIndex: 999999, width: 320}}>
                <div style={{flex: '1'}}>
                  <Popup  position='bottom left' content={'Show profile or video'}
                    trigger={
                      <Button color='grey' style={{padding: '.2em'}} size='small' type='button' icon onClick={this.showVideo.bind(this)}><Icon color='yellow' name={this.state.video ? 'video' : 'photo'} /></Button>
                    }
                  />
                </div>
                {this.state.video &&
                  <div>
                    <Popup  position='bottom right' content={'Click to maximize video'}
                      trigger={
                        <Button color='grey' style={{padding: '.2em'}} size='small' type='button' icon onClick={this.showVideo.bind(this)}><Icon color='yellow' name={'window maximize outline'} /></Button>    
                      }
                    />
                    <Popup  position='bottom right' content={'Change stream layout'}
                      trigger={
                        <Button color='grey' style={{padding: '.2em'}} size='small' type='button' icon onClick={this.showVideo.bind(this)}><Icon color='yellow' name={'columns'} /></Button>
                      }
                    />
                  </div>
                }
              </div>
              <div style={{background: '#444'}}>
                {this.state.video && this.props.channel=='Video' && <div style={{minHeight: 240}}>
                  <div style={{height: 180, width: 222}}>
                    <div style={{position: 'fixed'}}>
                      <OmniphoneVideo width={320} height={240} remoteVideoStream= {this.props.remoteVideoStream} localVideoStream={this.props.localVideoStream} /> 
                    </div>
                  </div>
                </div>}

                <Header as='h3' color='yellow' icon={!responsive} inverted style={{margin: '.2em .5em 0 .5em', display: 'inline'}} >
                  {responsive && <>
                    <Icon name='phone' className='bganimateiconborder' style={{transform: 'rotate(-135deg)', fontSize: '1em', cursor: 'pointer'}} circular inverted color='red' onClick={this.props.endConversation.bind(this, 'Voice')}/>
                    <Header.Content style={{paddingLeft: '.5em'}}>
                      {mode=='predictive' ? 'On call ...' : 'On call ...'}
                      <Header.Subheader>{mode=='predictive' ? 'Having conversation' : 'Having conversation'}</Header.Subheader>
                    </Header.Content>
                  </>}
                  {!responsive && <>
                    {!this.state.video && <Icon name='user outline' style={{fontSize: '5em', textShadow: '3px 5px 3px #000'}} color='orange' inverted/>}
                    <Header.Content style={{paddingTop: '.5em'}}>
                      <Header.Subheader style={{fontSize: '.8em'}}>
                        {(target && (target.calleePhone || target.mobile || target.officePhone || target.homePhone)) || 'No Terminating Contact'}
                      </Header.Subheader>
                      {(target && (target.calleeName +' ('+ target.calleeRelationship +')')) || (customer && customer.name) || 'Public Visitor / Guest'} 
                      <Header.Subheader style={{fontSize: '.8em'}}>
                        <b>{target && target.campaignType}</b> - {(target && target.campaign) || 'No campaign information'}
                      </Header.Subheader>
                    </Header.Content>
                  </>}
                </Header>
              </div>
              

              {target && <div style={{padding: '2em 1.5em', background: '#3e3e3e', margin: '1em -.4em -.8em -.4em'}}>
                <Header as='h4' inverted color='blue'>
                  <Header.Content>
                    {target.name || '-'}
                    <Header.Subheader>Customer Name</Header.Subheader>
                  </Header.Content>
                </Header>

                <Grid columns='equal' doubling>
                  {setting && setting.popup && setting.popup.popupPersonal && setting.popup.popupPersonal.length>0 && setting.popup.popupPersonal.map((item, i)=>{
                    if (!columns[Math.floor(i/2)]) {
                      columns[Math.floor(i/2)]= []
                    }
                    columns[Math.floor(i/2)].push(
                    <Grid.Column key={i}>
                      <Header as='h5' style={{margin: '0'}} inverted>
                        <Header.Content>
                          <b>{target[item] || '-'}</b>
                          <Header.Subheader><b>{langEN[item]}</b></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>)
                  })}
                  {columns.map((data, i)=>{
                    return(
                      <Grid.Row key={i} style={{padding: '.5em 0'}}>
                        {data.map(item=>{
                          return item;
                        })}
                      </Grid.Row>
                    )
                  })}
                
                  {(!setting || !setting.popup || !setting.popup.popupPersonal) && <>
                    <Grid.Row style={{padding: '.5em 0'}}>
                      <Grid.Column>
                        <Header as='h5' inverted>
                          <Header.Content>
                            {target.contractRef || '-'}
                            <Header.Subheader>Contract Ref.</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h5' inverted>
                          <Header.Content>
                            {target.custRef || '-'}
                            <Header.Subheader>Customer Ref.</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{padding: '.5em 0'}}>
                      <Grid.Column>
                        <Header as='h5' inverted>
                          <Header.Content>
                            {target.gender || '-'}
                            <Header.Subheader>Gender</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h5' inverted>
                          <Header.Content>
                            {target.birthDate || '-'}
                            <Header.Subheader>Birth Date</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{padding: '.5em 0'}}>
                      <Grid.Column>
                        <Header as='h5' inverted>
                          <Header.Content>
                            {target.jobCompany || '-'}
                            <Header.Subheader>Office</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h5' inverted>
                          <Header.Content>
                            {target.dataOrigin || '-'}
                            <Header.Subheader>Data Source</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{padding: '.5em 0'}}>
                      <Grid.Column>
                        <Header as='h5' inverted>
                          <Header.Content>
                            {target.address || '-'}
                            <Header.Subheader>Address</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </>}
                </Grid>
              </div>}

              {target && (target.project || target.currentAov || target.merchantCode || target.merchantName) && 
                <div style={{padding: '2em 1.5em', background: '#3e3e3e', margin: '1em -.4em -.8em -.4em'}}>
                  <Header as='h5' inverted>
                    <Header.Content>
                      Merchant Information
                    </Header.Content>
                  </Header>
                  
                  <Divider />
                  <Grid columns='equal' doubling>
                    <Grid.Row style={{padding: '.5em 0'}}>
                      <Grid.Column>
                        <Header as='h4' inverted color='blue'>
                          <Header.Content>
                            {target.project || 'No Ref. Project'}
                            <Header.Subheader>Project</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{padding: '.5em 0'}}>
                      <Grid.Column>
                        <Header as='h5' inverted>
                          <Header.Content>
                            {target.currentAov || '-'} / {target.currentFreqOrder || '-'}
                            <Header.Subheader>Current AOV</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h5' inverted>
                          <Header.Content>
                            {target.previousAov || '-'} / {target.previousFreqOrder || '-'}
                            <Header.Subheader>Previous AOV</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    
                    <Header as='h4' inverted color='blue'>
                      <Header.Content>
                        {target.merchantWarehouse || 'No Origin Warehouse'}
                        <Header.Subheader>Warehouse</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Grid.Row style={{padding: '.5em 0'}}>
                      <Grid.Column>
                        <Header as='h5' inverted>
                          <Header.Content>
                            {target.merchantName || '-'}
                            <Header.Subheader>Name</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h5' inverted>
                          <Header.Content>
                            {target.merchantCode || '-'}
                            <Header.Subheader>Code</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{padding: '.5em 0'}}>
                      <Grid.Column>
                        <Header as='h5' inverted>
                          <Header.Content>
                            {target.retentionGmvStatus || '-'}
                            <Header.Subheader>GMV</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h5' inverted>
                          <Header.Content>
                            {target.dataMaturity || '-'}
                            <Header.Subheader>Data</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              }
            </div>}
            {status=='locked' &&
            <Header as='h3' color='yellow' icon={!responsive} inverted style={{margin: '1em .5em 0 .5em', display: 'inline'}} >
              {responsive && <>
                <Icon name='phone' className='bganimateiconborder' style={{transform: 'rotate(-135deg)', fontSize: '1em', cursor: 'pointer'}} circular inverted color='red' onClick={this.props.callHangup.bind(this)}/>
                <Header.Content style={{paddingLeft: '.5em'}}>
                  {mode=='predictive' ? 'Locked ...' : 'Locked ...'}
                  <Header.Subheader>{mode=='predictive' ? 'You will not have any calls' : 'No incoming call receive'}</Header.Subheader>
                </Header.Content>
              </>}
              {!responsive && <>
                <Icon name='lock' style={{fontSize: '5em', textShadow: '3px 5px 3px #000'}} color='red'/>
                <Header.Content style={{paddingTop: '.5em'}}>
                  LOCKED !
                  <Header.Subheader style={{fontSize: '.8em'}}>
                    Now you are in locked mode, please click <mark><b>&nbsp;Unlock !&nbsp;</b></mark> to receive further calls.
                  </Header.Subheader>
                </Header.Content>
              </>}
            </Header>}
          </Segment>
          
          {status=='occupied' && this.state.dialpad && 
          <Segment attached={true} style={{padding: '1em .5em 1em .5em', background: '#444', border: 'none', borderTop: '1px solid #666',}}>
            <OmniphoneDial responsive={responsive} mode={mode||'predictive'} sendDTMF={this.sendDTMF.bind(this)}
            // onChangeText={this.onChangeText.bind(this)} callDial={this.callDial.bind(this)} 
            />
          </Segment>}
          
          {status=='occupied' && !responsive && <>
            <Divider horizontal style={{margin: '.5em'}}>
              <Icon name={this.state.dialpad ? 'chevron circle down' : 'chevron circle up'} color={this.state.dialpad ? 'teal' : 'yellow'} style={{fontSize: '1.8em',cursor: 'pointer' }} onClick={this.showDialpad.bind(this)}/>
            </Divider>
            
            <Segment attached={true} style={{padding: '1em .5em 2em .5em', background: '#444', border: 'none'}}>
              <Popup header={'Hangup Customer'} content={'Click to hangup conversation between you and current customer.'} trigger={
                <div className='bganimateicon' style={{borderRadius: '10em', height: '8em', width: '8em', margin: '1em auto 0 auto', textAlign: 'center'}}>
                  <Header as='h4' icon inverted style={{margin: '1.5em 0 1em 0'}}>
                    <Icon name='phone' style={{transform: 'rotate(-135deg)', fontSize: '1.9em', cursor: 'pointer'}} circular inverted color='red' onClick={this.props.endConversation.bind(this, 'Voice')}/>
                    {/* <Header.Content>Click to Hangup</Header.Content> */}
                  </Header>
                </div>
              } />
            </Segment>
          </>}
        </>
      )
    } catch(err) {
      this.props.loggingError({level: 'error', actor: 'APP SIP', scope: 'OmniphoneOnCall', event: 'On render', message: err });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OmniphoneOncall)