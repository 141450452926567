import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetAccount(objParam) {
  return {
      type: 'SOCMED_ACCOUNT_RESET',
      payload: objParam
  }
}

export function getAccount(objParam) {
  return {
    type: 'SOCMED_ACCOUNT_GET',
    payload:
      axios.post(
        urlAPI + 'socmed/account/get',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusAccount(objParam) {
  return {
    type: 'SOCMED_ACCOUNT_STATUS',
    payload:
      axios.post(
        urlAPI + 'socmed/account/status',
        JSON.stringify(objParam),
        {
          headers: {
            "Authorization": localStorage.getItem('tokenAuth'),
            "Content-Type": "application/json",
            "Accept": "*/*",
          }
        }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseAccount(objParam) {
  return {
      type: 'SOCMED_ACCOUNT_BROWSE',
      payload:
        axios.post(
          urlAPI + 'socmed/account/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
        ).catch(function(error){ //handle network error, return axios always 1
          // console.log('ERROR: ', error)
        })
  }
}

export function createAccount(objParam) {
  return {
      type: 'SOCMED_ACCOUNT_CREATE',
      payload:
        axios.post(
          urlAPI + 'socmed/account/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateAccount(objParam) {
  return {
      type: 'SOCMED_ACCOUNT_UPDATE',
      payload:
        axios.post(
          urlAPI + 'socmed/account/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeAccount(objParam) {
  return {
      type: 'SOCMED_ACCOUNT_REMOVE',
      payload:
        axios.post(
          urlAPI + 'socmed/account/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
