export default function serviceReducer (state = {}, action) {
  switch (action.type) {
    case 'SERVICE_BROWSE_PENDING': {
      return({...state, browseService: {progress: 0}})
    }
    case 'SERVICE_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, browseService: {...api.browseService.response, progress: 1, payload: action.payload}})
            return({...state, browseService: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseService: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SERVICE_BROWSE_REJECTED': {
        return({...state, browseService: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}