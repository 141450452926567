import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import { statusOptions }  from '../common/StaticMasterData'
import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { browseTarget } from "../../actions/stream/targetAction"
import { collectionStage, restructuringStage, telesaleStage, verificationStage } from "../../actions/halo/masterAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    targetStore: state.targetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTarget:(objParam) => dispatch(browseTarget(objParam)),

    collectionStage:(objParam) => dispatch(collectionStage(objParam)),
    restructuringStage:(objParam) => dispatch(restructuringStage(objParam)),
    telesaleStage:(objParam) => dispatch(telesaleStage(objParam)),
    verificationStage:(objParam) => dispatch(verificationStage(objParam)),
  }
}


class TargetSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
      lastChange: null,
    }
  }

  componentDidMount() {
    this.props.collectionStage()
    this.props.restructuringStage()
    this.props.telesaleStage()
    this.props.verificationStage()
  }

  submitForm(values) {
    const { search }= this.state
    this.props.browseTarget({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeInput(e, v) {
    const { search }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.browseTarget({search: {...search, keyword: v}})
      }
    }, 500)
  }

  onChangeSelect(k, e, v) {
    const { search }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    if (k=='status') {
      this.props.browseTarget({search: {...search, status: (v=='' ? null : v)}})
    } else if (k=='remark') {
      this.props.browseTarget({search: {...search, remark: (v=='' ? null : v)}})
    }
  }

  onRefresh() {
    const { search }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    this.props.browseTarget({search: {...search}})
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props

    const collectionStage= (this.props.masterStore.collectionStage!=undefined && this.props.masterStore.collectionStage.progress=='1' ? this.props.masterStore.collectionStage.data : [])
    const restructuringStage= (this.props.masterStore.restructuringStage!=undefined && this.props.masterStore.restructuringStage.progress=='1' ? this.props.masterStore.restructuringStage.data : [])
    const telesaleStage= (this.props.masterStore.telesaleStage!=undefined && this.props.masterStore.telesaleStage.progress=='1' ? this.props.masterStore.telesaleStage.data : [])
    const verificationStage= (this.props.masterStore.verificationStage!=undefined && this.props.masterStore.verificationStage.progress=='1' ? this.props.masterStore.verificationStage.data : [])
    
    const remarkMap= new Map();
    const remarkOptions = []
    collectionStage && collectionStage.map(item=>{
      remarkMap.set(item.value)
    })
    restructuringStage && restructuringStage.map(item=>{
      remarkMap.set(item.value)
    })
    telesaleStage && telesaleStage.map(item=>{
      remarkMap.set(item.value)
    })
    verificationStage && verificationStage.map(item=>{
      remarkMap.set(item.value)
    })

    remarkMap && [...remarkMap.keys()].map((item, i)=>{
      remarkOptions.push(
        { key: i, value: item, text: item }
      )
    })
    
    return (
      <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
        <div style={{padding: '1em 1em', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: '1'}}>
              <Label color='red' style={{borderRadius: '2em', padding: '.1em 1em .5em 1em'}}>
                <Header as='h4' style={{marginTop: '.25em'}} inverted>
                  <Icon name='user circle' style={{fontSize: '1em'}}/>
                  <Header.Content style={{fontWeight: 'bold'}}>
                    Target / {mode=='2' ? 'Update ' : (mode=='3' ? 'Insert ' : 'Browse ')}
                  </Header.Content>
                </Header>
              </Label>
            </div>
            <div>
            <Button as='div' labelPosition='right' onClick={this.onRefresh.bind(this)}>
              {/* <Button icon color='red' style={{padding: '.5em'}}>
                <Icon name='refresh' />
              </Button> */}
              {/* <Label as='a' basic pointing='left' color='red' style={{padding: '.5em'}}>
                Refresh
              </Label> */}
            </Button>
              {mode !=1 &&
              <Button size='mini' color='blue' onClick={this.props.setMode.bind(this,1)} icon style={{padding: '.5em'}}><Icon name='arrow left' /></Button>}
              {/* {mode !=2 && mode !=3 &&
              <Button size='mini' color='red' onClick={this.props.setMode.bind(this,3)} icon style={{padding: '.5em'}}><Icon name='plus' /></Button>} */}
              {mode ==1 &&
              <Button size='mini'  color='grey' onClick={this.showSearch.bind(this)} icon style={{padding: '.5em'}}><Icon name={searchMode==true? 'angle up' : 'angle down'} /></Button>}
            </div>
          </div>

          {mode==1 && searchMode && 
          <div style={{margin: '1em 0 0 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='equal'>
                    <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                    width={8} 
                    placeholder= 'Search target ... <ENTER>' />
                    <Field name='remark' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'remark')}
                    options={remarkOptions}
                    width={4} 
                    placeholder= 'Cust Remark' />
                    <Field name='status' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'status')}
                    options={statusOptions}
                    width={4} 
                    placeholder= 'Status' />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.targetStore.browseTarget!=undefined && state.targetStore.browseTarget.progress==1 ? state.targetStore.browseTarget.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'TargetSearch',	// a unique identifier for this form
  })(TargetSearch)
  )
)