import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format } from "date-fns"

import ApilogWidget from '../../widget/middleware/ApilogWidget';
import WebhookWidget from '../../widget/middleware/WebhookWidget';
import ApiResponseWidget from '../../widget/middleware/ApiResponseWidget';


import { connect } from "react-redux"
import { statApi, statMiddleware, statWebhook } from "../../../actions/halo/statisticAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    statApi:(objParam) => dispatch(statApi(objParam)),
    statMiddleware:(objParam) => dispatch(statMiddleware(objParam)),
    statWebhook:(objParam) => dispatch(statWebhook(objParam)),
  }
};


class StatisticDatafeeding extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.statisticStore.statMiddleware!==undefined ? this.props.statisticStore.statMiddleware : {})

    this.props.statApi({search: {...search}, paging: paging})
    this.props.statMiddleware({search: {...search}, paging: paging})
    this.props.statWebhook({search: {...search}, paging: paging})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.statisticStore.statMiddleware!==undefined ? this.props.statisticStore.statMiddleware : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div>
        <ApiResponseWidget height={350} />

        <div style={{display: 'flex', marginTop: '2em'}}>
          <div style={{flex: '1', marginRight: '1em'}}>
            <ApilogWidget />
          </div>
          <div style={{flex: '1', marginLeft: '1em'}}>
            <WebhookWidget />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticDatafeeding)
