import React,{Component} from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Checkbox,
  TextArea,
  Popup,
} from 'semantic-ui-react'


import { connect } from "react-redux"
import { contactOmniphone } from "../../actions/stream/omniphoneAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    contactOmniphone:(objParam) => dispatch(contactOmniphone(objParam)),
  }
}

class OmniphoneOncallFloating extends Component {
  constructor(props){
  	super(props)
  	this.state = {
    }
  }

  render() {
    const { mode, status, terminating, responsive, layout }= this.props
    const { target, customer, visitor, contact }= (this.props.omniphoneStore.contactOmniphone && this.props.omniphoneStore.contactOmniphone.progress=='1' ? this.props.omniphoneStore.contactOmniphone.data : {})

    return (
      <>
        <Segment attached={true} style={{padding: '1em .5em', minHeight: '20em', background: layout=='normal' ? '#444' : '#940079', border: 'none', textAlign: 'center'}}>
          {status=='online' &&
          <Header as='h3' color='yellow' icon inverted style={{margin: '1em .5em 0 .5em', display: 'inline'}} >
              <Icon name='rocket' style={{fontSize: '5em', textShadow: '3px 5px 3px #000'}} color='teal' inverted/>
              <Header.Content style={{padding: '2em 1em'}}>
                Waiting Call
                <Header.Subheader style={{fontSize: '.8em'}}>
                  Now you are in waiting dispatched call mode, to call manually please minimize omniphone and click <mark><b>&nbsp;Call !&nbsp;</b></mark> on target number list.
                </Header.Subheader>
              </Header.Content>
          </Header>}
          {status=='occupied' &&
          <Header as='h3' color='yellow' icon inverted style={{margin: '1em .5em 0 .5em', display: 'inline'}} >
            <Icon name='user outline' style={{fontSize: '5em', textShadow: '3px 5px 3px #000'}} color='orange' inverted/>
            <Header.Content style={{paddingTop: '.5em'}}>
              {(target && target.name) || (customer && customer.name) || 'Public Visitor / Guest'} 
              <Header.Subheader style={{fontSize: '.8em'}}>
                {(target && target.campaign) || 'No campaign information'}
              </Header.Subheader>
            </Header.Content>
          </Header>}
          {status=='locked' &&
          <Header as='h3' color='yellow' icon inverted style={{margin: '1em .5em 0 .5em', display: 'inline'}} >
              <Icon name='lock' style={{fontSize: '5em', textShadow: '3px 5px 3px #000'}} color='red'/>
              <Header.Content style={{paddingTop: '.5em'}}>
                LOCKED !
                <Header.Subheader style={{fontSize: '.8em'}}>
                  Now you are in locked mode, please click <mark><b>&nbsp;Unlock !&nbsp;</b></mark> to receive further calls.
                </Header.Subheader>
              </Header.Content>
          </Header>}
        </Segment>
        {status=='occupied' &&
        <Segment attached={true} style={{padding: '1em .5em 2em .5em', background: '#444', border: 'none'}}>
          <Divider />
          <Popup header={'Hangup Customer'} content={'Click to hangup conversation between you and current customer.'} trigger={
            <div className='bganimateicon' style={{borderRadius: '10em', height: '8em', width: '8em', margin: '2.5em auto 0 auto', textAlign: 'center'}}>
              <Header as='h4' icon inverted style={{margin: '1.5em 0 1em 0'}}>
                <Icon name='phone' style={{transform: 'rotate(-135deg)', fontSize: '1.9em', cursor: 'pointer'}} circular inverted color='red' onClick={this.props.endConversation.bind(this, 'Voice')}/>
              </Header>
            </div>
          } />
        </Segment>}
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OmniphoneOncallFloating)