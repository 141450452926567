import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import InteractiveBrowse from '../../../libraries/realm/flow/InteractiveBrowse'
import InteractiveContent from '../../../libraries/realm/flow/InteractiveContent'
import InteractiveForm from '../../../libraries/realm/flow/InteractiveForm'
// import InteractiveSearch from '../../../libraries/realm/flow/InteractiveSearch'

import ModalContainer from '../../../libraries/common/ModalContainer';
import InteractiveSearch from '../../../libraries/realm/flow/InteractiveSearch'
import InteractiveNavigationForm from '../../../libraries/realm/flow/InteractiveNavigationForm'
import InteractiveGreetingForm from '../../../libraries/realm/flow/InteractiveGreetingForm'


class InteractiveBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        interactiveId: 0,
      };
    }

    // setMode(mode, interactiveId, clientId, params) {
    //   this.setState({mode: mode, interactiveId: interactiveId, clientId: clientId, params: params})
    // }
    setMode(mode, params) {
      this.setState({mode: mode, params: params})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        interactiveId: 0,
      });
    }

    render() {
      const {mode, interactiveId, clientId, params}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          {/* <InteractiveSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} /> */}
          <div>
            <InteractiveBrowse mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
            {/* {mode==2 && <InteractiveForm mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} params={params} />}
            {mode==3 && <InteractiveForm mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />} */}

            {mode==22 && <ModalContainer size='large' content={<InteractiveNavigationForm mode={mode} params={params} setMode={this.setMode.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
            {mode==222 && <ModalContainer size='large' content={<InteractiveGreetingForm mode={mode} params={params} setMode={this.setMode.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
            {mode==33 && <ModalContainer size='large' content={<InteractiveNavigationForm mode={mode} params={params} setMode={this.setMode.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
            {mode==44 && <ModalContainer size='small' content={<InteractiveNavigationForm mode={mode} params={params} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
          </div>
        </Container>
      )
    }
}

export default InteractiveBody
