export default function accountReducer (state = {}, action) {
  switch (action.type) {
    case 'SOCMED_ACCOUNT_RESET': {
        return({
            ...state,
            getAccount: {},
            updateAccount: {},
            createAccount: {},
        })
    }

    case 'SOCMED_ACCOUNT_BROWSE_PENDING': {
        return({...state, browseAccount: {progress: 0}})
    }
    case 'SOCMED_ACCOUNT_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseAccount: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseAccount: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_ACCOUNT_BROWSE_REJECTED': {
        return({...state, browseAccount: {progress: 2, payload: action.payload}})
    }
    
    case 'SOCMED_ACCOUNT_GET_PENDING': {
        return({...state, getAccount: {progress: 0}})
    }
    case 'SOCMED_ACCOUNT_GET_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, getAccount: {...api.getAccount.response, progress: 1, payload: action.payload}})
            return({...state, getAccount: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getAccount: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_ACCOUNT_GET_REJECTED': {
        return({...state, getAccount: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_ACCOUNT_STATUS_PENDING': {
      return({...state, statusAccount: {progress: 0}})
    }
    case 'SOCMED_ACCOUNT_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, statusAccount: {...api.statusAccount.response, progress: 1, payload: action.payload}})
            return({...state, statusAccount: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusAccount: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_ACCOUNT_STATUS_REJECTED': {
        return({...state, statusAccount: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_ACCOUNT_CREATE_PENDING': {
      return({...state, createAccount: {progress: 0}})
    }
    case 'SOCMED_ACCOUNT_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, createAccount: {...api.createAccount.response, progress: 1, payload: action.payload}})
            return({...state, createAccount: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createAccount: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_ACCOUNT_CREATE_REJECTED': {
        return({...state, createAccount: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_ACCOUNT_UPDATE_PENDING': {
      return({...state, updateAccount: {progress: 0}})
    }
    case 'SOCMED_ACCOUNT_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, updateAccount: {...api.updateAccount.response, progress: 1, payload: action.payload}})
            return({...state, updateAccount: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateAccount: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_ACCOUNT_UPDATE_REJECTED': {
        return({...state, updateAccount: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_ACCOUNT_REMOVE_PENDING': {
      return({...state, removeAccount: {progress: 0}})
    }
    case 'SOCMED_ACCOUNT_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            //return({...state, removeAccount: {...api.removeAccount.response, progress: 1, payload: action.payload}})
            return({...state, removeAccount: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeAccount: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_ACCOUNT_REMOVE_REJECTED': {
        return({...state, removeAccount: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}