export default function timelineReducer (state = {}, action) {
  switch (action.type) {
    case 'SOCMED_TIMELINE_RESET': {
        return({
            ...state,
            getTimeline: {},
            updateTimeline: {},
            createTimeline: {},
        })
    }

    case 'SOCMED_TIMELINE_BROWSE_PENDING': {
        return({...state, browseTimeline: {progress: 0}})
    }
    case 'SOCMED_TIMELINE_BROWSE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, browseTimeline: {progress: 2, payload: action.payload}})
        } else {
            return({...state, browseTimeline: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_TIMELINE_BROWSE_REJECTED': {
        return({...state, browseTimeline: {progress: 2, payload: action.payload}})
    }
    
    case 'SOCMED_TIMELINE_GET_PENDING': {
        return({...state, getTimeline: {progress: 0}})
    }
    case 'SOCMED_TIMELINE_GET_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, getTimeline: {progress: 2, payload: action.payload}})
        } else {
            return({...state, getTimeline: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_TIMELINE_GET_REJECTED': {
        return({...state, getTimeline: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_TIMELINE_STATUS_PENDING': {
      return({...state, statusTimeline: {progress: 0}})
    }
    case 'SOCMED_TIMELINE_STATUS_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, statusTimeline: {progress: 2, payload: action.payload}})
        } else {
            return({...state, statusTimeline: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_TIMELINE_STATUS_REJECTED': {
        return({...state, statusTimeline: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_TIMELINE_CREATE_PENDING': {
      return({...state, createTimeline: {progress: 0}})
    }
    case 'SOCMED_TIMELINE_CREATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, createTimeline: {progress: 2, payload: action.payload}})
        } else {
            return({...state, createTimeline: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_TIMELINE_CREATE_REJECTED': {
        return({...state, createTimeline: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_TIMELINE_UPDATE_PENDING': {
      return({...state, updateTimeline: {progress: 0}})
    }
    case 'SOCMED_TIMELINE_UPDATE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, updateTimeline: {progress: 2, payload: action.payload}})
        } else {
            return({...state, updateTimeline: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_TIMELINE_UPDATE_REJECTED': {
        return({...state, updateTimeline: {progress: 2, payload: action.payload}})
    }

    case 'SOCMED_TIMELINE_REMOVE_PENDING': {
      return({...state, removeTimeline: {progress: 0}})
    }
    case 'SOCMED_TIMELINE_REMOVE_FULFILLED': {
        if (action.payload==undefined) {
            return({...state, removeTimeline: {progress: 2, payload: action.payload}})
        } else {
            return({...state, removeTimeline: {...action.payload.data, progress: 1, payload: action.payload}})
        }
    }
    case 'SOCMED_TIMELINE_REMOVE_REJECTED': {
        return({...state, removeTimeline: {progress: 2, payload: action.payload}})
    }

    default: {
      return state
    }
  }
}